import React from 'react';
import { withRouter } from 'react-router-dom';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import SimplyPowesome from '../components/SimplyPowesome';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';
import CheckboxString from '../components/CheckboxString';
import KasperDog from '../components/KasperDog';

const kasperDogs = [
  {
    image: '/assets/images/kasper-dog1.png',
    text: 'Full of beans',
  },
  {
    image: '/assets/images/kasper-dog2.png',
    text: 'Content',
  },
  {
    image: '/assets/images/kasper-dog3.png',
    text: 'Bright eyed and bushy tailed',
  },
  {
    image: '/assets/images/kasper-dog4.png',
    text: 'Trim',
  },
  {
    image: '/assets/images/kasper-dog5.png',
    text: 'And healthy!',
  },
];

const txtList = {
  best: [
    'Dynamically adjusted portions + blend',
    'Calorie controlled meals',
    'Hand blended for each dog',
    'Tasty premium ingredients',
    'High single source protein',
    'Optimum vitamins and minerals',
    'Fullfilling your dog\'s needs-every day',
  ],
  promise: [
    'Only pay for what your dog really needs',
    'Outstanding value for money',
    'Great customer service',
    'Carefully chosen quality ingredients',
    'Transparent recipes',
    'Our own vet - here to help',
    'Made in the UK and EU',
  ],
};

class MoreThanFood1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { windowWidth: window.innerWidth };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/mtf1-banner.jpg)" heading="Our tech" subtext="a diet at unique as your pet" />
        <div className="row ourtech-container">
          <div className="col-2" />
          <div className="col-md-8 mobile-spacing">
            <p className="heading">Our technology today makes your dog healthier tomorrow</p>
            <p className="subj">We deliver - a complete, healthy, calorie controlled diet specifically for your dog.</p>
            <p className="desc">The Fitkolar activity tracker works with the free K&amp;K App to tell us exactly what their lifestyle is really like. No guessing.</p>
            <p className="desc mb-0">So their bespoke food recipe blend and portions are made just for them.</p>
            <p className="desc">Each month.</p>
            <p className="desc">Every new tasty delivery is dynamically adjusted to contain the optimum nutritional value, including high quality single source protein and vitamins. All made with only the best ingredients.</p>
            <p className="desc">And that's not where it ends. We only ever recommend treats that match your dog's exact dietary requirements and complement their bespoke diet to help minimise the risk of allergies - and keep calories under control.</p>
          </div>
          <div className="col-2" />
        </div>
        <div className="row weight-container">
          <div className="col-md-2" />
          <div className="col-md-8">
            <p className="heading">Weight loss or gain, digestive issues, allergies, fertility problems or repeated injuries can all point at one thing. Unsuitable nutrition.</p>
            <p className="subj">We could rely on a nutritional consultation to recommend your dog's bespoke diet. But we won't.</p>
          </div>
          <div className="col-md-2" />
        </div>
        <div className="kasperdogs-container">
          <p className="heading">A kasper dog is...</p>
          <div className="row">
            <div className="col-md-1" />
            {kasperDogs.map((kasper, index) => (<div key={index} className="col-md-2">
              <KasperDog image={kasper.image} text={kasper.text} />
            </div>))}
            <div className="col-md-1" />
          </div>
        </div>
        <div className="bespokefood-container">
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-10">
              <p className="heading text-center">What makes K&amp;K bespoke food and treats so good for your dog?</p>
            </div>
            <div className="col-md-1" />
          </div>
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-3 mobile-spacing mobile-bottom-spacing">
              <p className="subj">Only the best</p>
              {txtList.best.map((text, index) => <CheckboxString key={index} text={text} />)}
            </div>
            <div className="col-md-4">
              <img src="/assets/images/kasper-food.png" className="kasper-food" />
            </div>
            <div className="col-md-3 mobile-spacing">
              <p className="subj">The K&amp;K promise</p>
              {txtList.promise.map((text, index) => <CheckboxString key={index} text={text} />)}
            </div>
            <div className="col-md-1" />
          </div>
        </div>
        <div className="row freesamples-container">
          <div className="col-md-3" />
          <div className="col-md-6">
            <p className="heading">Free samples</p>
            <p className="subj">Let your pet decide! We would love to send you a sample bag so your dog can start enjoying Kasper &amp; Kitty's complete dry food!</p>
            <button
              onClick={() => {
                scrollTo(0,0);
                this.props.history.push('/shop');
              }}
              type="button"
              className="btn btn-orange"
            >Order sample</button>
          </div>
          <div className="col-md-3" />
        </div>
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}

export default withRouter(MoreThanFood1);
