import React from 'react';

export default class TitleBox extends React.Component {
  render() {
    const { title } = this.props;

    return (
      <div className="heading-box-container">
        <div className="heading-container">
          <p className="heading">{title}</p>
        </div>
        <div className="children">
          {this.props.children}
        </div>
      </div>
    );
  }
}
