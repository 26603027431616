import React from 'react';
import classNames from 'classnames';

const PreviousNext = ({
  onNext,
  onPrevious,
  showPrevious,
  showNext,
  loading,
  next,
}) => (
  <div className="row mx-0 mt-4">
    <div
      className={classNames('col-12', 'previous-next-container', {
        single: showPrevious === false || showNext === false,
      })}
    >
      {showPrevious && (
        <button
          className="button"
          onClick={onPrevious}
          disabled={loading}
        >
          { loading ? 'Loading...' : 'Previous' }
        </button>
      )}
      {showNext && (
        <button
          className="button"
          onClick={onNext}
          disabled={loading}
        >
          { loading ? 'Loading...' : next }
        </button>
      )}
    </div>
  </div>
);

export default PreviousNext;
