import * as actionTypes from './actionTypes';

export const deliveryUpdate = delivery => ({
  type: actionTypes.DELIVERY_UPDATE,
  delivery,
});

export const deleteAllIntoDelivery = () => ({
  type: actionTypes.DELETE_ALL_INTO_DELIVERY,
});

export const billingAddressUpdate = billingAddress => ({
  type: actionTypes.BILLING_ADDRESS_UPDATE,
  billingAddress,
});

export const promocodeUpdate = promocode => ({
  type: actionTypes.PROMOCODE_UPDATE,
  promocode,
});
