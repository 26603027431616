import { fetchApi } from '../../services/api';

const endPoints = {
  orders: '/orders',
  create: '/orders/create',
  pay: '/orders/pay',

  update_card: '/orders/update',
  promocode: '/orders/promo',
  autoPromo: '/orders/promo/fetch-autoapplied',
  referPromo: '/orders/promo/refer-a-friend/get-all',
  applyPromo: '/orders/promo/refer-a-friend/apply-promo',
  pause: '/subscriptions/pause',
  cancel: '/subscriptions/cancel',
  subscriptions: '/subscriptions',
  order_items: '/orders/order_items',

  order_delay: '/orders/set-date-req',
  order_cancel: '/orders/cancel',
  referral_credit: '/orders/my-referral-credit',
  kibble_order: '/orders/subscription/my-latest-sub-order',
  update_suborder: '/orders/subscription/update-sub-order-items',

  cancel_questionnaire: '/orders/questionnaire-response',
  reactivate_order: '/orders/reactivate_order',
  incomplete: '/orders/incomplete_order',
  approve_order: '/orders/approve_order',
};

export const getShopDeliveryCost = () => fetchApi('/orders/shop-delivery-cost', {}, 'get');

export const fetchCurrentIncomplete = () => fetchApi('/orders/get-incomplete-order', {}, 'post');
export const updateIncompleteOrder = (order) => fetchApi('/orders/update-items', order, 'post');
export const deleteIncomplete = () => fetchApi('/orders/delete-incomplete', {}, 'post');
export const orders = () => fetchApi(endPoints.orders, {}, 'get');
export const order = (id) => fetchApi(`${endPoints.orders}/${id}`, { id }, 'get');
export const ordernew = (payload) => fetchApi(endPoints.create, payload, 'post');
export const orderpay = (payload) => fetchApi(endPoints.pay, payload, 'post');
export const updateCard = (payload) => fetchApi(endPoints.update_card, payload, 'post');
export const approveFailedOrder = (order_id) => fetchApi(endPoints.approve_order, { order_id }, 'post');

// intents
export const intentSecret = (payload) => fetchApi('/orders/secret', payload, 'post');
export const paymentIntent = (orderId) => fetchApi(`/orders/${orderId}/paymentIntent`, {}, 'get');

export const delay = (payload) => fetchApi(endPoints.order_delay, payload, 'post');
export const cancelled = (payload) => fetchApi(endPoints.order_cancel, payload, 'post');

export const referralLink = () => fetchApi('/orders/referral-link/get', {}, 'get');
export const promocode = (code) => fetchApi(`${endPoints.promocode}/${code}`, {}, 'get');
export const autoPromo = () => fetchApi(`${endPoints.autoPromo}`, {}, 'post');
export const referPromo = () => fetchApi(`${endPoints.referPromo}`, {}, 'get');
export const applyReferPromo = (order_id, code) => fetchApi(`${endPoints.applyPromo}`, { order_id, code }, 'post');
export const pause = (pause_at, resume_at, dog_id) =>
  fetchApi(`${endPoints.pause}`, { pause_at, resume_at, dog_id }, 'post');
export const cancel = () => fetchApi(endPoints.cancel, {}, 'post');
export const subscriptions = (dog_id) => fetchApi(`${endPoints.subscriptions}`, { dog_id }, 'get');
export const referralCredit = () => fetchApi(`${endPoints.referral_credit}`, {}, 'get');
export const updateSubscriptionOrder = (order_id, order_items) =>
  fetchApi(`${endPoints.update_suborder}`, { order_id, order_items }, 'post');
export const kibbleOrder = (dog_id) => fetchApi(`${endPoints.kibble_order}`, { dog_id }, 'post');

export const cancelQuestionnaire = (questionnaire) =>
  fetchApi(`${endPoints.cancel_questionnaire}`, { questionnaire }, 'post');
export const reactivateOrder = (orderID) => fetchApi(`${endPoints.reactivate_order}`, { order_id: orderID }, 'post');

export const getIncompleteOrder = (dog_id) => fetchApi(endPoints.incomplete, { dog_id }, 'post');
