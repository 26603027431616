import * as actionTypes from './actionTypes';

const initialState = {
  streetAddressLine1: '',
  streetAddressLine2: '',
  city: '',
  county: '',
  country: 'GB',
  zipPostalCode: '',
  selectedAddressOption: actionTypes.addressOptions.SAME,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BILLING_ADDRESS_UPDATE:
      return {
        ...action.billingAddress,
      };
    default:
      return state;
  }
};
