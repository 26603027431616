import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button';

import CloseIcon from '../../assets/images/wizard/review-close.png';

import * as actionTypes from '../../data/exports';
import * as selectors from '../../data/dogs/selectors';
import * as dogsActionCreators from '../../data/dogs/actions';

class HowWeightTip extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { title, isImage, image, target, isButton, btnLabel, btnFn } = this.props;
    return (
      <div className="modal custom-modal animated fadeIn" id={target} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <img src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid px-0">
                <div className="row justify-content-center">
                  <div className={`${isImage ? 'col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12' : 'col-12'}`}>
                    <h5 className="modal-title text-left text-justify py-1">{title}</h5>
                    <div className="modal-description text-left text-justify py-1">
                      <ul className={"pl-2"} style={{ listStyle: 'none' }}>
                        <li className={"py-1"}>1. Weigh yourself</li>
                        <li className={"py-1"}>2. Pick up your dog carefully, consider 2 people for dogs over 20kg</li>
                        <li className={"py-1"}>3. Weigh yourself while holding the dog</li>
                        <li className={"py-1"}>4. Subtract the two numbers</li>
                      </ul>
                    </div>

                    {isButton
                      ?
                      <Button
                        onClick={btnFn}
                        data-toggle="modal"
                        data-target={`#${target}`}
                        classes="button-primary fill red"
                        label={btnLabel}
                      />
                      :
                      ''
                    }

                  </div>
                  {isImage ?
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 text-center pl-0">
                      <img src={image} alt="tip-dog-image" />
                    </div>
                    :
                    ''
                  }
                </div>
              </div>
            </div>
            <div className="modal-footer row mx-0 justify-content-start">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dogs: state.data.dogs,
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});

const mapDispatchToProps = dispatch => ({
  setDog: dog => dispatch(dogsActionCreators.set(dog)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HowWeightTip);
