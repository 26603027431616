import React from 'react';

import Navigation from '../components/Navigation';
import MainImageBanner from '../components/MainImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';

const categories = [
  {
    title: 'Top FAQs',
    value: 'top',
  },
  {
    title: 'Food-Payment-Delivery',
    value: 'delivery',
  },
  {
    title: 'Kasper & Kitty App',
    value: 'app',
  },
  {
    title: 'Kasper & Kitty Website',
    value: 'web',
  },
  {
    title: 'FitKolar',
    value: 'fitkolar',
  },
  {
    title: 'General',
    value: 'general',
  },
];

const faqs = {
  top: [
    {
      topic: "Delivery",
      subTopic: "Info",
      question: "How long will it take for my order to arrive?",
      answer: "We aim to have your dogs dinner wat your front door within 1-3 working days. Please bear in mind that this.",
    },
    {
      topic: "Delivery",
      subTopic: "My profile",
      question: "Can I change my delivery date?",
      answer: "You can change your delivery details through your account online or through the K&K App.",
    },
    {
      topic: "Delivery",
      subTopic: "My profile",
      question: "Can I change my delivery address?",
      answer: "You can change your delivery address details through your account online or through the K&K App.",
    },
    {
      topic: "Delivery",
      subTopic: "My profile",
      question: "My order is incorrect.",
      answer: "Please contact our Customer Services Team who will be happy to assist via email: friends@kasperandkitty.com.",
    },
    {
      topic: "Feeding my dog",
      subTopic: "Info",
      question: "What are the health benefits feeding my pet the K&K menu?",
      answer: "Our complete calorie-controlled diet allows you to successfully manage your pets nutritional requirements with the essential blend of protein, carbohydrate and fat etc. These will vary depending on your pets breeds, age and exercise routine. It allows us to safely manage their weight whilst still supporting all of the bodies energetic and nutritional requirements.",
    },
    {
      topic: "Getting Started",
      subTopic: "About",
      question: "What will it cost me to purchase a K&K plan for my dog?",
      answer: "Our subscription service starts from just £18.99 per month. This may increase dependant on your dogs specific dietary requirements and the information your input into their profile.",
    },
    {
      topic: "Getting Started",
      subTopic: "About",
      question: "Do I have to sign up and subscribe to buy K&K food?",
      answer: "You have to sign up for K&K bespoke food, but you can buy our FitKolar, leads, accessories and scrummy treats from our shop without a subscription.",
    },
    {
      topic: "Getting Started",
      subTopic: "About",
      question: "Do I have to purchase a Fitkolar to subscribe to the food?",
      answer: "You can order the food on a subscription basis, but we recommend for optimum results you use in conjunction with the FitKolar.",
    },
    {
      topic: "My Account",
      subTopic: "My Profile",
      question: "I've forgotten my email address?",
      answer: "You can retrieve your password by following this link: www.kasperandkitty.com/password-reset.",
    },
    {
      topic: "My Account",
      subTopic: "My Profile",
      question: "I've forgotten my password?",
      answer: "You can retrieve your password by following this link: www.kasperandkitty.com/ LINK NOT LIVE YET - CODESIGNED.",
    },
    {
      topic: "Mobile App",
      subTopic: "Dogs Profile",
      question: "Can I add more than one dog?",
      answer: "Yes you can! On the app you will find the menu has a section 'Add another pet', click here to create as many doggy profiles as you wish. You will need a separate FitKolar for each dog.",
    },
    {
      topic: "Mobile App",
      subTopic: "Getting Started",
      question: "Do I have to download the Kasper & Kitty app to use with the FitKolar?",
      answer: "We have developed the FitKolar for use with our app to show your dogs daily and weekly activity stats. We strongly recommend that you download our free app to access the full range of information available.",
    },
    {
      topic: "Mobile App",
      subTopic: "Getting Started",
      question: "How do I install the Kasper and Kitty app?",
      answer: "Go to the Apple store or Google Play and search for Kasper and Kitty. Download the app to your phone and wait for it to install. Then just tap to open and follow the on screen instructions to get started.",
    },
    {
      topic: "Mobile App",
      subTopic: "Getting Started",
      question: "How do I create an account for Kasper and Kitty?",
      answer: "Once you have downloaded our K&K app you can then set up your dogs profile and your user profile following the easy sign up instructions via the Kasper and Kitty app.",
    },
    {
      topic: "FitKolar",
      subTopic: "Charging & Battery",
      question: "How do I charge my FitKolar?",
      answer: "Charge your FitKolar using the USB charger provided in your purchase and connect to a standard USB charging plug. Ensuring the 3 gold pins on the charger connect with the 3 gold dots on the back of the FitKolar.",
    },
    {
      topic: "FitKolar",
      subTopic: "Getting Started",
      question: "Is there a monthly cost with the FitKolar and app?",
      answer: "No, the Fitkolar is not subscription based so there are no additional monthly fees.",
    },
    {
      topic: "FitKolar",
      subTopic: "My Account",
      question: "How do I change my dog’s activity goal?",
      answer: "You can change their activity goal on the app by going to your dogs dashboard and selecting the edit option..",
    },
  ],
  delivery: [
    {
      topic: "Delivery",
      subtopic: "Info",
      question: "How does my food arrive and what's the weight?",
      answer: "Your delivery will arrive in 8kg bags and the treats will be separate. The food and treats will be contained in a cardboard box to protect them during delivery. Please be careful when lifting heavy packages.",
    },
    {
      topic: "Delivery",
      subtopic: "Info",
      question: "How long will it take for my order to arrive?",
      answer: "We aim to have your dogs dinner wat your front door within 1-3 working days. Please bear in mind that this may be slightly longer during busy periods.",
    },
    {
      topic: "Delivery",
      subtopic: "Info",
      question: "What happens if I'm not at home when the delivery arrives?",
      answer: "You can choose a 'safe place' to leave your delivery or 'leave with a neighbour'",
    },
    {
      topic: "Delivery",
      subtopic: "Info",
      question: "Where do you deliver to?",
      answer: "We deliver to the UK mainland free of charge for food subscriptions. *charges apply for NI, Scottish Highlands and Channel Islands",
    },
    {
      topic: "Delivery",
      subtopic: "Info",
      question: "Do you deliver outside the UK?",
      answer: "We don't currently  deliver outside of the UK",
    },
    {
      topic: "Delivery",
      subtopic: "Info",
      question: "How much does delivery cost?",
      answer: "All food subscription delivery costs are free. Trial foods are subject to a £2 delivery fee.  *Additional items under £29.99 are subject to a £3.95 delivery fee.   *Not including dry or wet food subscriptions                                                     ",
    },
    {
      topic: "Delivery",
      subtopic: "My profile",
      question: "Can I change my delivery date?",
      answer: "You can change your delivery details through your account online or through the K&K App",
    },
    {
      topic: "Delivery",
      subtopic: "My profile",
      question: "Can I change my delivery address?",
      answer: "You can change your delivery address details through your account online or through the K&K App",
    },
    {
      topic: "Delivery",
      subtopic: "My profile",
      question: "I missed my delivery, now what?",
      answer: "You can re-schedule your delivery up to 3 times free of charge if it has not been delivered to a safe place or to your neighbour",
    },
    {
      topic: "Delivery",
      subtopic: "My profile",
      question: "Can I track my delivery?",
      answer: "You can track your delivery using the link in your order confirmation email.",
    },
    {
      topic: "Delivery",
      subtopic: "My profile",
      question: "My order is incorrect",
      answer: "Please contact our Customer Services Team who will be happy to assist via email: friends@kasperandkitty.com ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "How do I change my dogs current diet to a K&K diet?",
      answer: " When changing your dogs diet, it is very important to do this gradually over 10 days. We recommend starting with 80% of your current dogs food with 20% K&K food for 3 days, then moving to 60% current dogs food with 40% K&K for 3 days, then moving to 40% current dogs food with 60% K&K for 3 days and so on until 100% K&K. Vets most commonly see tummy upsets when owners change their pets food too quickly. If your pet has a persistent tummy upset, we recommend consulting your veterinary surgeon.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "How much food should I give my dog?",
      answer: "Your food subscription will arrive with recommend feeding guidelines tailored to your dogs needs. ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "How much food should I give my puppy?",
      answer: "Your food subscription will arrive with recommend feeding guidelines tailored to your puppy's needs. ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "My dog has become ill after eating the K&K food - what should I do?",
      answer: "If you have strictly followed our transitional instructions and your pet becomes ill afterwards, like any illness it will be dependent on the severity of their symptoms. If your animal is bright and happy with intestinal upsets, these may pass naturally, however if your animal is lethargic and having persistent vomiting and diarrhoea, we recommend consulting your veterinary surgeon. They can then ascertain that their illness is food related or otherwise.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "My dog doesn’t like the taste of the food, what should I do?",
      answer: "We offer a 30 day free trial to ensure your best friend loves their new diet. You can also change their meat and flavour preferences by updating their food profile on your account.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "Can I feed K&K to my dog with allergies?",
      answer: "Yes, you can choose various ingredient options to omit from your dogs diet dependant on what causes their allergies. We don't use any eggs in our ingredients.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "Should I consult my vet before feeding my dog K&K bespoke diet?",
      answer: "You are very welcome to consult your vet before changing their diet for peace of mind. We don’t feel it would be necessary unless your animal has diagnosed conditions that may be affected with a change in their food.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "Do I need to cook K&K food?",
      answer: "No, your K&K food does not need to be cooked",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "What if my dog's dietary requirements change?",
      answer: "You can update your dogs menu preferences at any time to suit. The change must be made before your order cut off date for the next months order.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "Do you have a K&K diet for pregnant dogs?",
      answer: "Yes, we can nutritionally tailor a diet for your pregnant dog.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "When and how much should I feed my dog?",
      answer: "In your K&K subscription we offer a menu card along with feeding guidelines. ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "Can I change the food to suit my dogs change in preferences?",
      answer: "You can update your dogs menu preferences at any time to suit. The change must be made before your order cut off date for the next months order.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "I have extra food leftover or don't have enough, what should I do?",
      answer: "You can easily change the amount you feed your dog and can add extra dry food if needed. This may affect your monthly subscription pricing.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "Can I change to flavour of my dogs K&K diet?",
      answer: "You can update your dogs menu preferences at any time to suit. The change must be made before your order cut off date for the next months order.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "Can all of my dogs eat the same blend?",
      answer: "Each blend is unique to your dog, so we don't recommend feeding the same blend if each dog has different requirements, allergens, activity levels, height and weight.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Feeding Guidelines",
      question: "Is K&K suitable for senior dogs?",
      answer: "Yes, we cater for our older dogs too and have senior blends to help give them the best nutrition at all life stages.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "How would I know if my dog has any allergies?",
      answer: "If your dog has particularly itchy, red skin, or they chew their feet or scratch their ears more often than normal or have lots of upset tummies, then we recommend seeking advice from your veterinary surgeon. They will be able to diagnose possible allergies or intolerances.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "My dog is overweight, can you help?",
      answer: "If your pet is overweight, we strongly recommend a calorie controlled diet. Overweight animals are at greater risk of heart disease, degenerative joint disease and other endocrine conditions. Weight loss can be achieved by controlled feeding with regular exercise.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "My dog is underweight, can you help?",
      answer: " If your pet is underweight, we strongly recommend a calorie controlled diet. Underweight animals are at greater risk of endocrine conditions and ill thrift. Weight gain can be achieved by controlled feeding. We advise any underweight animals be checked by their veterinary surgeon before starting on K&K diet.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "My dog scratches a lot and has itchy / sensitive skin, can I still feed him K&K?",
      answer: "If your pet has itchy or sensitive skin. Consult your veterinary surgeon before starting a K&K diet. It could be that they have underlying allergies or intolerances which need addressing. We can then tailor their diet accordingly.  ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "My dog has joint issues can a K&K diet help?",
      answer: "If your pet has diagnosed joint issues. K&K diets are supplemented with chondroitin and glucosamine to match your pets supplemental requirements. These support their joints to allow them to feel more comfortable and live a more fulfilled lifestyle. ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "My dog has pancreatitis can a K&K diet help?",
      answer: "If your pet has diagnosed pancreatitis. K&K diets will be matched accordingly. We know that certain breeds are more prone to pancreatitis than others, although it can affect any breed. Overweight animals fed on a fatty diet are more likely to develop pancreatitis than others. Our diets are made to strictly manage the fat levels that they contain. ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "My dog has diabetes can a K&K diet help?",
      answer: "If your pet has diagnosed diabetes. Diabetes is where the pancreas doesn’t produce the hormone insulin, which in turn controls sugar glucose levels. K&K diets will be matched accordingly to reduce the carbohydrate levels in their food. ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "My dog has colitis can a K&K diet help?",
      answer: "We can alter the Omega-3 and fibre in your dogs diet but ultimately we recommend seeking your vets advice for colitis.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "My dog has a heart condition, can a K&K diet help?",
      answer: "If your pet has a diagnosed heart condition. Salt is the main ingredient that would need to be managed in your dogs diet. A K&K diet will be matched to reduce this. ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "My dog needs a high fibre diet - can you help?",
      answer: "Your dogs profile will highlight if they need more fibre and this can be added into your K&K subscription",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "I have a Dalmatian, can they eat a K&K diet?",
      answer: "K&K can create a diet suitable for Dalmatians by tailoring their purine intake. If your Dalmatian has kidney stones then we recommend you speak to a vet before putting them on a K&K diet.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "Would high or low protein levels affect my dog?",
      answer: "Possibly. Protein is an important component of your dogs diet. Too much and you can cause weight gain and the health issues associated with that. Too little and you can cause malnutrition, muscle wasting and lethargy.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "What do I do if my dog is eating his own stools?",
      answer: "This is very often seen in puppies and is known as Coprophagia. If you dog does this, it is advised that you move them away from their stools immediately and praisE them for not eating their stools. It is a bad habit that dogs grow out of if managed accordingly.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Health",
      question: "Is it normal for my dog to have a lot of flatulence?",
      answer: "Yes. Flatulence occurs because of a build up gas in the colon. The gas is produced by bacteria in the gut breaking down food sources. Too much of an imbalance of gut bacteria can cause excess gas. In excess this can lead to discomfort as the wind gets trapped in the intestines.  ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "How do I weigh my dog?",
      answer: "Step 1 - weigh yourself. Step 2 - weigh yourself holding the dog. Step 3 - subtract the 1st weight recording from the second weight recording, this will give you your dogs weight",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "How do I measure my dogs height?",
      answer: "Step 1: Stand your dog straight on a flat solid surface. Move your hand down from his head to his neck you will find his shoulder joints (Withers). Step 2: Using a tape measure, measure the distance from his withers to the bottom of his feet. Top tips: *Do not measure from his head * For smaller dogs it may help to stand on a table – supervised of course! ",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "How will a K&K diet improve my dogs health?",
      answer: "Like humans, we are what we eat and our pets are no different. We can be certain that a complete calorie controlled diet that is matched correctly to your pets requirements, will ultimately improve their wellbeing. Unless they have any underlying conditions that have been undiagnosed.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "How is K&K food sourced and made?",
      answer: "Our food is sourced from the UK and bespokely blended in the Midlands for your dog.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "What are the health benefits feeding my pet the K&K menu?",
      answer: "Our complete calorie-controlled diet allows you to successfully manage your pets nutritional requirements with the essential blend of protein, carbohydrate and fat etc. These will vary depending on your pets breeds, age and exercise routine. It allows us to safely manage their weight whilst still supporting all of the bodies energetic and nutritional requirements.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "Where are your ingredients sourced from? ",
      answer: "Wherever possible we try and source our ingredients from the UK",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "How do I clean my dog's teeth?",
      answer: "Some dogs love having their teeth cleaned, whilst others don’t. Be sure to know if your pet does or doesn’t. We recommend using a dog toothpaste with a toothbrush or finger brush. Never use human toothpaste. Apply the toothpaste to the brush and gently rub the outer surface of your dogs teeth. Be careful not to rub too hard or fast or over the gums.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "My dog is picky and choosy with their food, can K&K help?",
      answer: "We offer a free 2 week trial to ensure that your dog loves their new K&K menu. You can also change your dogs flavours by updating their food preferences",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "Do you offer food for cats?",
      answer: "Not at the moment but watch this space!",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "Do you test on animals?",
      answer: "We have a  group of chief doggie testers who love to try our tasty dinners and scrummy treats to help us deliver the best flavours for your dog.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Info",
      question: "What do you mean by 'Blend' ?",
      answer: "Our kibbles are hand mixed to ensure your dogs diet has exactly the right amount of nutrients, vitamins and minerals. Each portion is carefully weighed out and 'blended' to give your dog the correct amount each month.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "What are the key ingredients in your food?",
      answer: "We use meat and fish plus carbohydrates and fats.  With the benfitt of no added sugars or salts.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "Do you  offer pure raw food?",
      answer: "We do not offer pure raw food at this time",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "How do you know what nutrients and quantities my dog needs? ",
      answer: "Based on your dogs profile we can work out exactly what nutrients they need based on their age, weight and activity levels when paired with our FitKolar",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "What is your protein content? ",
      answer: "Our menus vary with protein content - for more information on your dogs bespoke blend please contact friends@kasperandkitty.com",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "How much ash is in your food? ",
      answer: "Our menus vary with ash content - for more information on your dogs bespoke blend please contact friends@kasperandkitty.com",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "Do you offer grain-free food?",
      answer: "Yes we offer grain free food as well as cereal free food.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "Senior kibbles are in my dogs blend, why?",
      answer: "Your dogs dietary needs will change with age and therefore we introduce senior kibble when appropriate for your dogs diet. Ensuring that they get the correct blend of nutrients at all life stages.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "Is a K&K diet nutritionally balanced for all life stages?",
      answer: "Our complete calorie-controlled diet allows you to successfully manage your pets nutritional requirements with the essential blend of proteins, carbohydrates and fats. These will vary depending on your pets breeds, age and exercise routine. It allows us to safely manage their weight whilst still supporting all of the bodies energetic and nutritional requirements for all life stages.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "Can I exclude certain ingredients from a K&K diet?",
      answer: "You can choose to exclude grains and cereals  and also flavours by updating your dogs food profile",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "Can I exclude grain from a K&K diet?",
      answer: "Yes you can exclude grain",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "Is K&K food hypoallergenic?",
      answer: "We don't offer hypoallergenic food",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "Do you use any artificial flavours or colours?",
      answer: "No, not at all. We wouldn't like them in our food so we don't put them in your dogs food!",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "Can you provide vegetarian and vegan diets?",
      answer: "We don't offer vegetarian or vegan diets at this time",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "What meat sources do you use?",
      answer: "We use a tasty range of Lamb, Chicken, Salmon and Turkey",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "What are the glucosamine and chondroitin levels in your food?",
      answer: "Each menu is bespoke to your dog and may contain different levels. We suggest you email our team for further help and guidance with your dogs bespoke blend: friends@kasperandkitty.com",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "How much fat is in the K&K diet?",
      answer: "Each menu is bespoke to your dog and may contain different levels. We suggest you email our team for further help and guidance with your dogs bespoke blend: friends@kasperandkitty.com",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "Why should I feed my dog wet food?",
      answer: "Wet food has a higher protein content which can be beneficial for your dog as well as giving them extra tasty meats to excite their taste buds.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "What are the benefits of feeding my dog the K&K dental sticks?",
      answer: "Our dental sticks contain no added sugar and zeolite to help keep their teeth clean and healthy. Our unique 8 pronged stick helps them to really clean those hard to reach areas as they chew the dental stick around their mouth.",
    },
    {
      topic: "Feeding my dog",
      subtopic: "Nutrition",
      question: "How many treats should I feed per day?",
      answer: "Once you have created your dogs profile we can then suggest the correct amount of treats which should be given to them on a daily basis. We normally recommend 10% of their daily calories on treats and titbits.",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "What is Kasper and Kitty about?",
      answer: "We use our FitKolar activity monitor and expertly blended and portioned bespoke food  for your dog to create their personal calorie-controlled meals. This in conjunction with the K&K App, promotes e healthy weight and overall wellbeing.",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "Do you offer free samples of K&K food?",
      answer: "Sign up through our Kasper and Kitty app or online to receive a 2 week free trial of K&K food. (does not include a FitKolar). Sign up and subscribe for 6 months within 30 days of 1st order to receive free FitKolar (*first 200 customers, no cash alternative)",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "What do you mean by calorie controlled diet?",
      answer: "A complete calorie-controlled diet allows you to successfully manage your pets nutritional requirements with the essential blend of proteins, carbohydrates and fats. These will vary depending on your pets breeds, age and exercise routine. It allows us to safely manage their weight whilst still supporting all of the bodies energetic and nutritional requirements. ",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "How is a K&K diet bespoke?",
      answer: "Developed by our team using your dogs details you enter, it  scientifically blends vitamins, proteins, carbohydrates and fats so your dog gets just the right mix - every day.",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "What are the benefits of purchasing from the K&K menu?",
      answer: "We don't just guess, we also use real time data from your dog! Used in conjunction with our exclusive FitKolar to monitor your dogs daily activity levels, we can be sure that they are getting exactly the right amount of food and treats each month.",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "What subscription packages do you offer?",
      answer: "You can choose our premium package which includes the FitKolar activity tracker along without bespoke K&K food and treats delivered monthly. Alternatively you can just order the food on a subscription basis, but we recommend for optimum results you use in conjunction with the FitKolar.",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "How can I track my pet's health and weight?",
      answer: "With our unique FitKolar you can track your dogs activity levels against what they are eating daily. You can manually weigh your dog and update their data accordingly",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "what will it cost me to purchase a K&K plan for my dog?",
      answer: "Our subscription service starts from just £18.99 per month. This may increase dependant on your dogs specific dietary requirements and the information your input into their profile.",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "Do I have to sign up and subscribe to buy K&K food?",
      answer: "You have to sign up for K&K bespoke food, but you can buy our FitKolar, leads, accessories and scrummy treats from our shop without a subscription.",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "Where can I purchase K&K food from?",
      answer: "We sell our products online only at www.kasperandkitty.com",
    },
    {
      topic: "Getting Started",
      subtopic: "About",
      question: "Do I have to purchase a Fitkolar to subscribe to the food?",
      answer: " You can order the food on a subscription basis, but we recommend for optimum results you use in conjunction with the FitKolar",
    },
    {
      topic: "My Account",
      subtopic: "Dogs Profile",
      question: "I don't know my dogs age - will this make a difference?",
      answer: "We suggest you either speak to your vet or give us an estimate so that we can tailor your dogs food accordingly",
    },
    {
      topic: "My Account",
      subtopic: "Dogs Profile",
      question: "How can I add an additional dog or cat? ",
      answer: "You can add a dog profile either through your account online or through the K&K app. Please note that we currently only provide the FitKolar and bespoke food for dogs at this time. Watch this space for news on cats soon!",
    },
    {
      topic: "My Account",
      subtopic: "Dogs Profile",
      question: "How do I change my dog's profile?",
      answer: "You can change your dogs profile through your account online or through the K&K App",
    },
    {
      topic: "My Account",
      subtopic: "Dogs Profile",
      question: "How do I upload my dog's photo?",
      answer: "Once on your dogs profile page, click edit photo - then take a picture or upload a picture from your pc/phone and click save",
    },
    {
      topic: "My Account",
      subtopic: "My Profile",
      question: "I've forgotten my email address? ",
      answer: "You can retrieve your password by following this link: www.kasperandkitty.com/password-reset",
    },
    {
      topic: "My Account",
      subtopic: "My Profile",
      question: "I've forgotten my password? ",
      answer: "You can retrieve your password by following this link: www.kasperandkitty.com/ LINK NOT LIVE YET - CODESIGNED",
    },
    {
      topic: "My Account",
      subtopic: "My Profile",
      question: "How do I update my contact details?",
      answer: "You can change your details through your account online or through the K&K App",
    },
    {
      topic: "My Account",
      subtopic: "My Profile",
      question: "How is my personal information used? ",
      answer: "To find out how we use your personal data please follow the following links - Link to:  GDPR statement, Privacy & Cookie Policy - CoDesigned",
    },
    {
      topic: "My Account",
      subtopic: "Subscription",
      question: "How do I cancel my subscription?",
      answer: "You are free to cancel your subscription at any time. Please email our Customer Service team at: friends@kasperandkitty.com. Please note that we need notice of any cancellations before the date on your order confirmation email. If cancellation is made on or after that date, you will be charged for the following months food",
    },
    {
      topic: "My Account",
      subtopic: "Subscription",
      question: "How do I manage my subscription when I go on holiday or if I want to pause delivery?",
      answer: "You can pause or change your delivery dates each month either through your account online or through the K&K App",
    },
    {
      topic: "My Account - Payment",
      subtopic: "Payment",
      question: "How do I change my card details?",
      answer: "You can change your payment details through your account online or through the K&K App",
    },
    {
      topic: "Pricing / Payment",
      subtopic: "My Subscription",
      question: "Can I cancel my K&K subscription?",
      answer: "You are free to cancel your subscription at any time. Please email our Customer Service team at friends@kasperandkitty.com. Please note that we need notice of any cancellations before the date on your order confirmation email. If cancellation is made on or after that date, you will be charged for the following months food",
    },
    {
      topic: "Pricing / Payment",
      subtopic: "Payment",
      question: "How are my payments taken?",
      answer: "Your payments will be taken every 30 days on a rolling subscription. Your card details will be used for each payment unless you want to update your card details on your account by logging into your account online or through the app under 'Change Payment Details'",
    },
    {
      topic: "Pricing / Payment",
      subtopic: "Payment",
      question: "How much does a K&K diet cost?",
      answer: "Our subscription service starts from just £18.99 per month. This may increase dependant on your dogs specific dietary requirements and the information your input into their profile.",
    },
    {
      topic: "Pricing / Payment",
      subtopic: "Payment",
      question: "How often are payments taken?",
      answer: "Your payments will be taken every 30 days on a rolling subscription. Your card details will be used for each payment unless you want to update your card details on your account by logging into your account online or through the app under 'Change Payment Details'",
    },
    {
      topic: "Pricing / Payment",
      subtopic: "Payment",
      question: "Do you accept all types of payment?",
      answer: "We accept Mastercard, Visa Debit and Visa Credit cards.",
    },
    {
      topic: "Pricing / Payment",
      subtopic: "Payment",
      question: "What is the cost for delivery?",
      answer: "All food subscription delivery costs are free. Trial foods are subject to a £2 delivery fee.  *Additional items under £29.99 are subject to a £3.95 delivery fee.   *Not including dry or wet food subscriptions                                                     ",
    },
    {
      topic: "Pricing / Payment",
      subtopic: "Payment",
      question: "My payment has changed this month, why?",
      answer: "Your payment may increase dependant on your dogs feeding profile. If you choose a more expensive ingredient for your dog then this price will be reflected in your monthly payment. We will always advise you via email of any price increases and ask you to confirm.",
    },
    {
      topic: "Pricing / Payment",
      subtopic: "Returns",
      question: "Can I return my FitKolar?",
      answer: "Yes if you are unhappy you can return to us within 30 days of purchase for a replacement if faulty. *Please note no refunds can be given on free FitKolars",
    },
    {
      topic: "Storage & Packaging",
      subtopic: "Packaging",
      question: "Do you use recyclable packaging?",
      answer: "Our cardboard boxes are fully recyclable. At this time our food and treats bags are not currently recyclable. Sourcing responsible packaging takes time - check back soon for when we can offer fully recyclable packaging.",
    },
    {
      topic: "Storage & Packaging",
      subtopic: "Packaging",
      question: "What packaging do you use?",
      answer: "Your delivery will arrive in 8kg bags and the treats will be separate. They will arrive in a cardboard box to protect them during delivery. The weight may vary up to 8kg per bag dependant on your dogs calculated monthly food amount. Please be careful when lifting heavy items.",
    },
    {
      topic: "Storage & Packaging",
      subtopic: "Storage",
      question: "Do I need to refrigerate my K&K food?",
      answer: "Your K&K dry food does not need refrigeration. Your K&K wet food does not require refrigeration if sealed. Any open wet food we recommend keeping in the fridge for 5- 7 days only.",
    },
    {
      topic: "Storage & Packaging",
      subtopic: "Storage",
      question: "How long will K&K food stay fresh for?",
      answer: "All food has a 2 year shelf life when kept unopened in cool, dry conditions",
    },
    {
      topic: "Storage & Packaging",
      subtopic: "Storage",
      question: "Can I freeze the wet food?",
      answer: "Your dogs wet food should not be frozen.",
    },
    {
      topic: "Storage & Packaging",
      subtopic: "Storage",
      question: "How do I store K&K wet food?",
      answer: "Any unopened wet food trays can be kept in a dry area - (kitchen cupboard). We recommend refrigerating open wet food trays for 5-7 days only",
    },
  ],
  app: [
    {
      topic: "Mobile App",
      subtopic: "Dogs Profile",
      question: "Can I add more than one dog?",
      answer: "Yes you can! On the app you will find the menu has a section 'Add another pet', click here to create as many doggy profiles as you wish. You will need a separate FitKolar for each dog.",
    },
    {
      topic: "Mobile App",
      subtopic: "Dogs Profile",
      question: "I have more than 1 dog, can I use the same FitKolar?",
      answer: "Because our FitKolar uses specific algorithms and data exclusive to your dog, you will need 1 x FitKolar per dog for the data to be accurate each month. This is especially important when paired with our scrummy K&K bespoke food",
    },
    {
      topic: "Mobile App",
      subtopic: "Dogs Profile",
      question: "How do I change my dog’s activity goal?",
      answer: "You can change their activity goal on the app by going to your dogs dashboard and selecting the edit option.",
    },
    {
      topic: "Mobile App",
      subtopic: "Dogs Profile",
      question: "How can I delete my dogs profile?",
      answer: "Log in to the K&K app and go to Account settings then 'Your Pets profile'. On your dogs profile page click 'edit' and you will see a link to 'delete profile'. Click this link and then 'confirm' you want to delete the profile. Please note that once deleted the information will not be retrievable. CoDesigned to Create asap",
    },
    {
      topic: "Mobile App",
      subtopic: "Dogs Profile",
      question: "How do I change my dog’s profile picture?",
      answer: "You can edit your dogs details at any time in the app by logging into your account - Account settings - Your Pets Profile and selecting the Edit button where you can change your dogs picture.  Remember to click 'Save' to save your changes.",
    },
    {
      topic: "Mobile App",
      subtopic: "Dogs Profile",
      question: "How can I change my dogs details including neutered,  height and weight or micro chip ID?",
      answer: "You can edit your dogs details at any time in the app by logging into your account - Account settings - Your Pets Profile and selecting the Edit button where you can change your dogs details if required. Remember to click 'Save' to save your changes.",
    },
    {
      topic: "Mobile App",
      subtopic: "Getting Started",
      question: "Do I have to download the Kasper & Kitty app to use with the FitKolar",
      answer: "We have developed the FitKolar for use with our app to show your dogs daily and weekly activity stats. We strongly recommend that you download our free app to access the full range of information available.",
    },
    {
      topic: "Mobile App",
      subtopic: "Getting Started",
      question: "How do I install the Kasper and Kitty app?",
      answer: "Go to the Apple store or Google Play and search for Kasper and Kitty. Download the app to your phone and wait for it to install. Then just tap to open and follow the on screen instructions to get started.",
    },
    {
      topic: "Mobile App",
      subtopic: "Getting Started",
      question: "How do I create an account for Kasper and Kitty?",
      answer: "Once you have downloaded our K&K app you can then set up your dogs profile and your user profile following the easy sign up instructions via the Kasper and Kitty app.",
    },
    {
      topic: "Mobile App",
      subtopic: "Getting Started",
      question: "How do I add a dog?",
      answer: "On the app you will find the menu has a section 'Add another pet', click here to create as many doggy profiles as you wish.",
    },
    {
      topic: "Mobile App",
      subtopic: "Getting Started",
      question: "Is there a monthly cost with the FitKolar?",
      answer: "No, the Fitkolar alone is not subscription based so there are no additional monthly fees.",
    },
    {
      topic: "Mobile App",
      subtopic: "Getting Started",
      question: "Why should I use the K&K app?",
      answer: "Our exclusive free K&K app gives you access to your dogs activity levels when paired with our FitKolar. You can also update your dogs profile and feeding preferences, order your food and treats and purchase accessories.",
    },
    {
      topic: "Mobile App",
      subtopic: "My Account",
      question: "Can I change or reset my password?",
      answer: "Yes, on the app go to 'Your Account' and select 'Lost Password' to guide you through the reset process or to change your password.",
    },
    {
      topic: "Mobile App",
      subtopic: "Technical",
      question: "What devices can I use with the FitKolar and Kasper & Kitty App?",
      answer: "Our FitKolar and app are compatible  with Android 4.3 and iOS 8.3 or above",
    },
    {
      topic: "Mobile App",
      subtopic: "Technical",
      question: "How do I sync the FitKolar to the app",
      answer: "Your app will sync with the FitKolar when you  manually refresh the app whenever Bluetooth is available. Simply press the refresh button or pull down the screen to refresh. Please remember to sync with the app every 7 days to maintain data integrity.",
    },
    {
      topic: "Mobile App",
      subtopic: "Technical",
      question: "What is the range for syncing the FitKolar and app?",
      answer: "Your FitKolar and mobile device must be within a 10m range and Bluetooth enabled.",
    },
    {
      topic: "Mobile App",
      subtopic: "Technical",
      question: "My app and FitKolar won't connect.",
      answer: "Close your app and switch off your Bluetooth for 10 seconds. Re-open your app and switch Bluetooth back on. Your FitKolar should now reconnect. Please ensure your FitKolar has sufficient battery power - you can check this on the LED display. If the display is blank even after pressing the button - then it more than likely needs its battery to be recharged.",
    },
    {
      topic: "Mobile App",
      subtopic: "Technical",
      question: "How can I connect or login from different mobiles or tablet computers? Do I need to synch the Fitkolar with each of them?",
      answer: "You can log into your account from different devices. Please ensure that you log out of one device before logging in on a different device. Your device will automatically sync with the paired FitKolar for your account.",
    },
    {
      topic: "Mobile App",
      subtopic: "Technical",
      question: "How do I access and use the dashboard and data without a mobile or tablet?",
      answer: "You can log into your account from  a PC or laptop if required and you dogs details and dashboard will link to your dogs account.",
    },
  ],
  web: [
    {
      topic: "Website",
      subtopic: "Technical",
      question: "Kasper goes Treating game is not working?",
      answer: "Please refresh your browser and try again",
    },
    {
      topic: "Website",
      subtopic: "My Account",
      question: "How do I log into the dashboard for Kasper and Kitty?",
      answer: "Simply click here: www.kasperandkitty.com/login you can either log in if you have already created an account or you can easily set up an account if you are new to Kasper & Kitty.",
    },
    {
      topic: "Website",
      subtopic: "Technical",
      question: "Do I need to use a mobile device to view my web dashboard?",
      answer: "You can log into your dashboard using your laptop or PC. As long as your FitKolar is synced via Bluetooth, the data from your dogs activities will be shown. You can log into the browser from your phone - but it would be more beneficial to you to download our exclusive Kasper and Kitty App.",
    },
    {
      topic: "Website",
      subtopic: "Dogs Profile",
      question: "How can I change my dogs activity goals?",
      answer: "You can log into your account and select 'Your dogs profile', there is an EDIT option you can use to change their goals.",
    },
    {
      topic: "Website",
      subtopic: "Technical",
      question: "How can I connect or login from different mobiles or tablet computers? Do I need to sync the Fitkolar with each of them?",
      answer: "You can log into your account from different devices. Please ensure that you log out of one device before logging in on a different device. Your device will automatically sync with the paired FitKolar for your account.",
    },
    {
      topic: "Website",
      subtopic: "Dogs Profile",
      question: "How can I delete my dogs profile?",
      answer: "Log in to the K&K app and go to Account settings then 'Your Pets profile'. On your dogs profile page click 'edit' and you will see a link to 'delete profile'. Click this link and then 'confirm' you want to delete the profile. Please note that once deleted the information will not be retrievable. CoDesigned to add this feature asap",
    },
    {
      topic: "Website",
      subtopic: "My Account",
      question: "Can I change or reset my password?",
      answer: "Yes, on the app go to 'Your Account' and select 'Lost Password' to guide you through the reset process or to change your password.",
    },
    {
      topic: "Website",
      subtopic: "Dogs Profile",
      question: "Can I change my dogs profile picture?",
      answer: "You can edit your dogs details at any time in the app by logging into your account - Account settings - Your Pets Profile and selecting the Edit button where you can change your dogs picture.  Remember to click 'Save' to save your changes.",
    },
    {
      topic: "Website",
      subtopic: "Dogs Profile",
      question: "How can I change my dogs details including neutered,  height and weight or micro chip ID?",
      answer: "You can edit your dogs details at any time in the app by logging into your account - Account settings - Your Pets Profile and selecting the Edit button where you can change your dogs details if required. Remember to click 'Save' to save your changes.",
    },
  ],
  fitkolar: [
    {
      topic: "FitKolar",
      subtopic: "Getting Started",
      question: "How do I switch FitKolar on?",
      answer: " Activate the FitKolar by pressing the ‘Navigation Button' for up to 20 seconds. Your FitKolar will now switch on. If this doen't work the battery may need charging before you start. Connect the FitKolar to its charger and a suitable power source.",
    },
    {
      topic: "FitKolar",
      subtopic: "Getting Started",
      question: "Where on the collar should I place the FitKolar?",
      answer: "The FitKolar can sit anywhere on your dogs collar. If using a Kasper and Kitty collar designed for the FitKolar, it will sit between the Velcro tabs.",
    },
    {
      topic: "FitKolar",
      subtopic: "Getting Started",
      question: "Is there a monthly cost with the FitKolar and app?",
      answer: "No, the Fitkolar is not subscription based so there are no additional monthly fees",
    },
    {
      topic: "FitKolar",
      subtopic: "Getting Started",
      question: "How accurate is the FitKolar?",
      answer: "FitKolar Activity Monitor for dogs is intended to encourage an active and healthy lifestyle for your dog. The FitKolar is equipped with sensors to track your dogs movements and various other metrics. The data from these is intended to be a close estimation only of your dogs activity and additional measures such as distance, goals, rest and calories. Please note there may be a degree of inaccuracy in these metrics. The activity monitor is not intended for medical/veterinary use and we recommend consulting your dogs vet should you have any concerns about their health or wellbeing.",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "Can I change or reset my password?",
      answer: "Yes, on the app go to 'Your Account' and select 'Lost Password' to guide you through the reset process or to change your password.",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "I have a new phone, can I still access my account?",
      answer: "You will need to download the free Kasper & Kitty app and then you can log into your account from a new device. Please ensure that you log out of one device before logging in on a different device. Your device will automatically sync with the paired FitKolar for your account.",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "I have forgotten my password",
      answer: "Please  go to 'Your Account' and select 'Lost Password' to guide you through the reset process or to change your password.",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "Can I change the time-zone on the FitKolar?",
      answer: "The time automatically syncs to the time on your phone.",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "How do I change my dog’s activity goal?",
      answer: "You can change their activity goal on the app by going to your dogs dashboard and selecting the edit option.",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "How is my dogs daily activity and distance calculated by the FitKolar?",
      answer: "The FitKolar uses specific algorithms which work in conjunction with your dogs profile to calculate their daily activity and distance travelled which convert into their Kasper Points",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "Can I manually add exercise or daily activities?",
      answer: "There is currently no option for you to add manual exercise or daily activities.",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "How can my dogs weight be controlled through the app and the FitKolar?",
      answer: "Once all the correct data has been inputted into your dogs profile, we can then work out using his or hers activity data, the correct portion size for their bespoke diet and treats. We have even got clever calculations to include if titbits are fed. If you are over feeding or underfeeding outside of our guidelines then results for your dog may vary",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "Can I access real-time data from my dog's activities and exercise?",
      answer: "Yes, you can view this information on the FitKolars LED screen or once the FitKolar has been synced with your app.",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "How does the Fitkolar estimate how many calories my dog has burnt?",
      answer: "Our unique algorithm in conjunction with your dogs profile calculates their calorie expenditure throughout the day (24hrs)",
    },
    {
      topic: "FitKolar",
      subtopic: "My Account",
      question: "How do I track food intake and calories with Fitkolar and the app?",
      answer: "Using our unique Kalorometer 'Calorie Counter', you can add various food that has been fed to your dog over the day. If your dog exceeds the limits, we'll send you a gentle reminder!",
    },
    {
      topic: "FitKolar",
      subtopic: "Returns",
      question: "Can I return my FitKolar?",
      answer: "Yes if you are unhappy you can return to us within 30 days of purchase for a replacement if faulty. *Please note no refunds can be given on free FitKolars",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "My FitKolar won't sync, what should I do?",
      answer: "Firstly ensure the battery is charged by plugging into a USB charging port with the charger supplied to you with the FitKolar. Ensure you have logged into your account. Once the FitKolar is paired the data should automatically sync, or if on the app, simply pull the screen down to refresh",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "Will the battery in the FitKolar heat up?",
      answer: "The battery does not heat up during use.",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "Is radiation given off by the FitKolar which could harm my dog?",
      answer: "Standard Bluetooth® technology is used in the FitKolar which is perfectly safe for humans and pets.",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "What is the warranty on the FitKolar?",
      answer: "Your FitKolar offers a one-year limited warranty valid from the date of purchase. All defects which arise from faults in material or functionality are fixed free of charge. The warranty does not apply to consumable parts, such as batteries or the rubber clips or cover damage caused by accident, abuse, misuse or any similar cause. Kasper & Kitty Limited does not warrant that the operation of the FitKolar will be uninterrupted or error-free. The Limited Warranty is not applicable to: (I) normal wear and tear; (ii) defects or damage caused by misuse, failure to follow instructions, accident, neglect, abuse, alteration, unusual stress, modification, improper or unauthorised repair, improper storage, water if the product has been opened, use with unsuitable devices, software or services; (iii) use for medical, healthcare or treatment purposes; (iv) software and services provided by Kasper & Kitty to owners of the FitKolar; (v) loss and security of data; (vi) used or resold FitKolar's. Any attempt to open or take apart the device will void the warranty. Rubber Clips, collars, leads and other accessories are not covered under the warranty.",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "How can I get support or technical questions answered?",
      answer: "Please contact our Customer Services Team who will be happy to assist via email: friends@kasperandkitty.com ",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "What devices can I use with the FitKolar and Kasper & Kitty App?",
      answer: "Our FitKolar and app are compatible with Android 4.3 and iOS 8.3 or above",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "How do I sync my FitKolar?",
      answer: "Firstly ensure the battery is charged by plugging into a USB charging port with the charger supplied to you with the FitKolar. Ensure you have logged into your account. Once the FitKolar is paired the data should automatically sync, or if on the app, simply pull the screen down to refresh. Please remember to sync with the app every 7 days to maintain data integrity.",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "Is the FitKolar waterproof?",
      answer: "Yes, the FitKolar has the waterproof and dust proof rating IP67. This means the FitKolar can withstand immersion in water up to one meter (3.3 feet) for 30 minutes.",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "What does the FitKolar measure?",
      answer: "The FitKolar measures Kasper Points which is an overall measure of your dogs activity. It measures goals distance, calories and rest.",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "How close does the FitKolar have to be to my device to sync?",
      answer: "The FitKolar links via Bluetooth 4.0 and we recommend that to sync your FitKolar it needs to be within a 10m range to connect",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "Is FitKolar a GPS device?",
      answer: "The FitKolar is not a GPS device. It monitors activity levels only.",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "Does the FitKolar have to be in a certain temperature range?",
      answer: "The Fitkolar temperature range is -20c to 40c",
    },
    {
      topic: "FitKolar",
      subtopic: "Technical",
      question: "Is there a heart rate monitor on the FitKolar?",
      answer: "The FitKolar does not have a heart rate monitor",
    },
    {
      topic: "FitKolar",
      subtopic: "Troubleshooting",
      question: "I can't get the FitKolar to switch on",
      answer: "The FitKolar will automatically go into sleep mode when not in use. There is no physical 'Off' switch. If the battery has run down, then the FitKolar will shut off and will only wake once re-connected to a power source via the USB charger",
    },
    {
      topic: "FitKolar",
      subtopic: "Troubleshooting",
      question: "I have lost my FitKolar?",
      answer: "We are sorry to hear that you have lost your FitKolar. To purchase a new FitKolar please visit our Kasper & Kitty shop",
    },
    {
      topic: "FitKolar",
      subtopic: "Troubleshooting",
      question: "My clips have broken for the FitKolar, can I order new ones?",
      answer: "To order replacement clips please contact our Customer Services Team who will be happy to assist via email at friends@kasperandkitty.com. To purchase clips please visit our shop at www.kasperandkitty.com/shop",
    },
    {
      topic: "FitKolar",
      subtopic: "Troubleshooting",
      question: "What happens is my dog has chewed the FitKolar?",
      answer: "If the FitKolar is correctly attached to your dogs collar then they should not be able to access it to chew on. If you remove the FitKolar please store out of reach along with its charger and cables to avoid chewing by your dog(s). Please note: the FitKolar is NOT a toy.  For further guidance please contact our Customer Care Team who will be happy to assist via email at friends@kasperandkitty.com",
    },
    {
      topic: "FitKolar",
      subtopic: "Troubleshooting",
      question: "My FitKolar isn't working after my dog got it wet.",
      answer: "Your FitKolar can be submerged up to 1m water depth up to a MAXIMUM of 30 minutes. You have a 1 year warranty from date of purchase / delivery on your FitKolar. If you still require help please contact our Customer Services Team who will be happy to assist via email at friends@kasperandkitty.com. To purchase a new FitKolar please visit our shop at www.kasperandkitty.com/shop.                                                                      We don't recommend that your dog swims with the FitKolar on for over 30 minutes.",
    },
    {
      topic: "FitKolar",
      subtopic: "Troubleshooting",
      question: "I'm not getting any notifications on my mobile device, what should I do?",
      answer: "In the first instance, check you have accepted notification settings. If these setting have been accepted and you still don't receive notifications, please uninstall the app and re-install. This should solve the issue.",
    },
  ],
  general: [
    {
      topic: "General",
      subtopic: "General",
      question: "How is my personal data used?",
      answer: "Please refer to our Privacy Policy for full details Live link from CoDesigned",
    },
    {
      topic: "T&Cs",
      subtopic: "T&Cs",
      question: "DISCLAIMER FOR FAQS AND T&Cs FitKolar",
      answer: "FitKolar Activity Monitor for dogs is intended to encourage an active and healthy lifestyles for your dog. The FitKolar is equipped with sensors to track your dogs movements and various other metrics. The data from these is intended to be a close estimation only of your dogs activity and additional measures such as distance, goals, rest and calories. Please note there may be a degree of inaccuracy in these metrics. The activity monitor is not intended for medical/veterinary use and we recommend consulting your dogs vet should you have any concerns about their health or wellbeing.",
    },
    {
      topic: "T&Cs",
      subtopic: "T&Cs",
      question: "Disclaimer for food",
      answer: "Kasper & Kitty food is not a veterinary supplement nore a prescription diet. By choosing this diet for your dog you agree that you have taken ",
    },
    {
      topic: "T&Cs",
      subtopic: "T&Cs",
      question: "Disclaimer for App & Website",
      answer: "Owner is responsible for the input information and should this be false, we take no responsibility",
    },
  ],
};

export default class Faqs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: categories[0].value,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(value) {
    this.setState({ category: value });
  }

  render() {
    const { category } = this.state;
    return (
      <div className="container">
        <Navigation
          absolute
          white
          textColor="white-text"
        />
        <MainImageBanner
          image="url(/assets/images/faqs.jpeg)"
          heading="FAQS"
          subtext=""
        />
        <div className="terms-container faqs-container">
          <div className="col-md-3">
            {categories.map(category => (
              <div className="category" key={`faq-${category.title}`} onClick={()=>this.handleClick(category.value)}>
                {category.title}
              </div>
            ))}
          </div>
          <div className="col-md-9">
            {faqs[category].map((item, index) => (
              <div key={`faqItem-question-${index}`} className="heading">
                {`${++index}\u00A0.\u00A0\u00A0${item.question}`}
                <div key={`faqItem-answer-${index}`} className="desc">
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
