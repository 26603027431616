import React from 'react';

const Button = props => (
        <>
            <button {...props} className={props.classes}>
                {props.label}
            </button>
        </>
);

export default Button;
