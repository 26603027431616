/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-return-assign */
import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import createBrowserHistory from 'history/createBrowserHistory';
import { connect } from 'react-redux';

// tracking modules (google analytics, hotjar, facebook pixel)
//import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
//import { hotjar } from 'react-hotjar';
//import ReactPixel from 'react-facebook-pixel';

import { StripeProvider } from 'react-stripe-elements';

import apiConfig from './services/api/config';

import LoggedInRoute from './components/LoggedInRoute';

import Start from './scenes/start';

// authorization
import SignUp from './scenes/authorization/SignUp';
import SignIn from './scenes/authorization/SignIn';
import StayInTouch from './scenes/stayInTouch';

// password reset
import ForgottenPassword from './scenes/password/forgottenPassword';
import ResetPassword from './scenes/password/resetPassword';
import NewPassword from './scenes/password/newPassword';
import PasswordReset from './scenes/password/passwordReset';
import ResetPasswordCruft from './scenes/password/resetPasswordCruft';

// wizzard steps
import AddDog from './scenes/wizard/addDog';
import Gender from './scenes/wizard/gender';
import Neutered from './scenes/wizard/neutered';
import Spayed from './scenes/wizard/spayed';
import Pregnant from './scenes/wizard/pregnant';
import Age from './scenes/wizard/age';
import Breed from './scenes/wizard/breed';
import Weight from './scenes/wizard/weight';
import Height from './scenes/wizard/height';
import BodyCondition from './scenes/wizard/bodyCondition';
import Activity from './scenes/wizard/activity';
import Health from './scenes/wizard/health-details';
import Allergy from './scenes/wizard/allergy';
import Ingredients from './scenes/wizard/ingredients';
import FoodPrefer from './scenes/wizard/food-preferences';
import Titbits from './scenes/wizard/titbits';
import Flavours from './scenes/wizard/flavours';
import OrderConfirmed from './scenes/wizard/orderConfirmed';


// Dashboard screens
import Dashboard from './scenes/dashboard/dashboard';
import PaymentHistory from './scenes/dashboard/paymentHistory';
import LiveOrders from './scenes/dashboard/liveOrders';
import YourDogsFood from './scenes/dashboard/yourDogsFood';
import Order from './scenes/dashboard/order';
import YourDogsGoals from './scenes/dashboard/yourDogsGoals';
import Activities from './scenes/dashboard/activities';
import DogProfile from './scenes/dashboard/dogProfile';
import DogProfileNew from './scenes/dashboard/dogProfileNew';
import YourPets from './scenes/dashboard/yourPets';
import YourDetails from './scenes/dashboard/yourDetails';
import CardDetails from './scenes/dashboard/cardDetails';
import BillingDetails from './scenes/dashboard/billingDetails';
import DeliveryDetails from './scenes/dashboard/deliveryDetails';
import FitkolarSettings from './scenes/dashboard/fitkolarSettings';
import LiveOrderCruft from './scenes/dashboard/liveOrderCruft';

import Shop from './scenes/shop';
import Product from './scenes/product';
import Subscription from './scenes/subscription';
import CheckoutToBuy from './scenes/checkoutToBuy';
import OrderConfirmation from './scenes/orderConfirmation';
import Basket from './scenes/basket';
import CheckoutBasketPage from './components/checkout/basket/CheckoutBasketPage';
import CheckoutDeliveryDetailsPage from './components/checkout/delivery/CheckoutDeliveryDetailsPage';
import CheckoutPaymentPage from './components/checkout/payment/CheckoutPaymentPage';
import ThankYouPage from './components/checkout/ThankYouPageNew';

import CartPayment from './scenes/payment';
import TakeLead from './scenes/takeLead';
import NotFood from './scenes/notFood';
import MeetKasper from './scenes/meetKasper';
import YourFriends from './scenes/yourFriends';
import Whistle from './scenes/whistle';
import Terms from './scenes/terms';
import Privacy from './scenes/privacy';
import Cookie from './scenes/cookie';
import IntroOffer from './scenes/introOffer';
import Offer from './scenes/offer';
import MoreThanFood1 from './scenes/moreThanFood1';
import MoreThanFood2 from './scenes/moreThanFood2';
import MoreThanFood3 from './scenes/moreThanFood3';
import MoreThanFood4 from './scenes/moreThanFood4';
import MoreThanFood5 from './scenes/moreThanFood5';
import MoreThanFood6 from './scenes/moreThanFood6';
import MoreThanFood7 from './scenes/moreThanFood7';
import MoreThanFood8 from './scenes/moreThanFood8';
import Logout from './scenes/logout';
import Game from './scenes/game';
import HealthIssues from './scenes/healthIssues';
import DeliveryReturn from './scenes/deliveryReturn';
import News from './scenes/news';
import ComingSoon from './scenes/comingSoon';
import Reviews from './scenes/reviews';
import Faqs from './scenes/faqs';

import GuestCheckout from './scenes/guestCheckout';

import PageNotFound from './scenes/PageNotFound';

import Onboarding from './scenes/onboarding/onboarding';

import { isLoggedIn } from './services/session';
import * as sessionSelectors from './services/session/selectors';
import CookieShield from './assets/images/shield.png';

import ThankYou from './scenes/thankYou';
import ReferAFriend from './scenes/referAFriend';


const history = createBrowserHistory();

// google analytics tracking code
const tagManagerArgs = {
    gtmId: 'GTM-WZM2SJF'
}

TagManager.initialize(tagManagerArgs)

//ReactGA.initialize('UA-146642323-3');
//history.listen(location => ReactGA.pageview(location.pathname));

// // hotjar tracking code
//hotjar.initialize(1536834, 6);

// facebook pixel code
//ReactPixel.init('603784460368726');
//ReactPixel.pageView();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (isLoggedIn() ? (
        <LoggedInRoute component={Component} />
      ) : (
          <Redirect to={{
            pathname: '/login',
            state: { from: props.location },
          }}
          />
        ))
    }
  />
);
const CruftRoute = ({ component: Component, path, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isLoggedIn()) {
        if (!sessionSelectors.getUser().cruft || (path !== '/reset-password-cruft' && path !== '/live-order-cruft')) {
          return (
            <Redirect to={{
              pathname: '/dashboard',
              state: { from: props.location },
            }}
            />
          );
        }
        return <LoggedInRoute component={Component} />;
      }
      return (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location },
        }}
        />
      );
    }}
  />
);


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptedCookie: Cookies.get('accepted_cookies'),
    };
  }

  render() {
    const { acceptedCookie } = this.state;
    return (
      <StripeProvider apiKey={apiConfig.stripeKey}>
        <Router history={history}>
          <Route
            render={({ location }) => (
              <div>
                {!acceptedCookie &&
                  <div className="cookie-container">
                    <img className="cookie-image" src={CookieShield} alt="tip-dog" />
                    <p className="cookie-explanation">
                      We use cookies to give you a better experience at kasperandkitty.com.
                      By continuing to use our site, you are agreeing to the use of cookies as set in our&nbsp;
                      <a href="https://www.kasperandkitty.com/privacy-policy">Cookie Policy</a>
                      .
                    </p>
                    <p className="cookie-accept-link-container">
                      <button
                        className="cookie-accept-link"
                        onClick={(e) => {
                          e.preventDefault();
                          Cookies.set('accepted_cookies', true);
                          this.setState({
                            acceptedCookie: true,
                          });
                        }}
                      >
                        ACCEPT
                      </button>
                    </p>
                  </div>
                }
                <Route location={location} key={location.key}>
                  <Switch>
                    {/* <Route location={location} exact path="/login" component={Login} /> */}
                    <Route location={location} exact path="/password-reset" component={ForgottenPassword} />
                    <Route location={location} exact path="/reset-password" component={ResetPassword} />
                    <Route location={location} exact path="/password-new/:code" component={NewPassword} />
                    <Route location={location} exact path="/forgotten-password" component={PasswordReset} />

                    {/* <Route location={location} exact path="/" component={Start} /> */}
                    <Route location={location} exact path="/" render={() => <Redirect to="/dashboard" />} />
                    <PrivateRoute location={location} exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute location={location} exact path="/your-dogs-food" component={YourDogsFood} />
                    <PrivateRoute location={location} exact path="/payment-history" component={PaymentHistory} />
                    <PrivateRoute location={location} path="/payment-history/:order_id" component={Order} />
                    <PrivateRoute location={location} exact path="/live-orders" component={LiveOrders} />
                    <PrivateRoute location={location} exact path="/your-dogs-goals" component={YourDogsGoals} />
                    <PrivateRoute location={location} exact path="/activities" component={Activities} />
                    <PrivateRoute location={location} exact path="/dog-profile" component={DogProfile} />
                    {/* <PrivateRoute location={location} exact path="/dog-profile-2" component={DogProfileNew} /> */}
                    <PrivateRoute location={location} exact path="/your-pets" component={YourPets} />
                    <PrivateRoute location={location} exact path="/your-details" component={YourDetails} />
                    <PrivateRoute location={location} exact path="/card-details" component={CardDetails} />
                    <PrivateRoute location={location} exact path="/billing-details" component={BillingDetails} />
                    <PrivateRoute location={location} exact path="/delivery-details" component={DeliveryDetails} />
                    <PrivateRoute location={location} exact path="/fitkolar-settings" component={FitkolarSettings} />

                    <CruftRoute location={location} exact path="/reset-password-cruft" component={ResetPasswordCruft} />
                    <CruftRoute location={location} exact path="/live-order-cruft" component={LiveOrderCruft} />

                    <PrivateRoute location={location} path="/welcome" component={Onboarding} />
                    <Route location={location} exact path="/sign-up" component={SignUp} />
                    <Route location={location} exact path="/login" component={SignIn} />
                    <Route location={location} exact path="/stay-in-touch" component={StayInTouch} />
                    <Route location={location} exact path="/add-dog" component={AddDog} />
                    <Route location={location} exact path="/gender" component={Gender} />
                    <Route location={location} exact path="/neutered" component={Neutered} />
                    <Route location={location} exact path="/spayed" component={Spayed} />
                    <Route location={location} exact path="/pregnant" component={Pregnant} />
                    <Route location={location} exact path="/age" component={Age} />
                    <Route location={location} exact path="/breed" component={Breed} />
                    <Route location={location} exact path="/weight" component={Weight} />
                    <Route location={location} exact path="/height" component={Height} />
                    <Route location={location} exact path="/body-condition" component={BodyCondition} />
                    <Route location={location} exact path="/activity" component={Activity} />
                    <Route location={location} exact path="/health-details" component={Health} />
                    <Route location={location} exact path="/allergy" component={Allergy} />
                    <Route location={location} exact path="/ingredients" component={Ingredients} />
                    <Route location={location} exact path="/food-preferences" component={FoodPrefer} />
                    <Route location={location} exact path="/titbits" component={Titbits} />
                    <Route location={location} exact path="/flavours" component={Flavours} />
                    <Route location={location} exact path="/order-confirmed" component={OrderConfirmed} />
                    <Route location={location} exact path="/thank-you" component={ThankYou} />

                    <Route location={location} exact path="/shop" component={Shop} />
                    <Route location={location} exact path="/subscription" component={Subscription} />
                    <PrivateRoute location={location} exact path="/product/:id" component={Product} />
                    {/* new checkout and order-confirmation */}
                    <Route location={location} exact path="/checkout" component={CheckoutToBuy} />
                    <Route location={location} exact path="/order-confirmation/:id" component={OrderConfirmation} />
                    <PrivateRoute location={location} exact path="/cart" component={CheckoutBasketPage} />
                    <PrivateRoute location={location} exact path="/checkout/delivery" component={CheckoutDeliveryDetailsPage} />
                    <PrivateRoute location={location} exact path="/checkout/payment" component={CheckoutPaymentPage} />
                    <PrivateRoute location={location} exact path="/thanks" component={ThankYouPage} />
                    <PrivateRoute location={location} exact path="/refer-a-friend" component={ReferAFriend} />
                    <Route location={location} exact path="/payment/:id" component={CartPayment} />

                    <Route location={location} exact path="/shop-cart" component={Basket} />

                    <Route location={location} exact path="/how-it-works" component={TakeLead} />
                    <Route location={location} exact path="/app" component={MoreThanFood8} />

                    <Route location={location} exact path="/food" component={NotFood} />
                    <Route location={location} exact path="/dry-food" component={MoreThanFood2} />
                    <Route location={location} exact path="/other-food" component={MoreThanFood4} />

                    <Route location={location} exact path="/tech" component={MoreThanFood1} />
                    <Route location={location} exact path="/fitkolar" component={MoreThanFood7} />

                    <Route location={location} exact path="/blog" component={YourFriends} />

                    <Route location={location} exact path="/about-us" component={MeetKasper} />

                    <Route location={location} exact path="/whistle" component={Whistle} />
                    <Route location={location} exact path="/terms-and-conditions" component={Terms} />
                    <Route location={location} exact path="/privacy-policy" component={Privacy} />
                    <Route location={location} exact path="/cookie-policy" component={Cookie} />
                    <Route location={location} exact path="/intro-offer" component={IntroOffer} />
                    <Route location={location} exact path="/offer" component={Offer} />
                    <Route location={location} exact path="/more-than-food3" component={MoreThanFood3} />
                    <Route location={location} exact path="/more-than-food5" component={MoreThanFood5} />
                    <Route location={location} exact path="/more-than-food6" component={MoreThanFood6} />

                    <Route location={location} exact path="/game" component={Game} />
                    <Route location={location} exact path="/guest-checkout" component={GuestCheckout} />

                    {/* empty pages */}
                    <Route location={location} exact path="/faqs" component={Faqs} />
                    <Route location={location} exact path="/reviews" component={Reviews} />
                    <Route location={location} exact path="/coming-soon" component={ComingSoon} />
                    <Route location={location} exact path="/news" component={News} />
                    <Route location={location} exact path="/health-issues" component={HealthIssues} />
                    <Route location={location} exact path="/delivery-and-return" component={DeliveryReturn} />

                    {/* External social media pages */}
                    <Route path="/facebook" component={() => window.location = 'https://www.facebook.com/kasperandkitty/'} />
                    <Route path="/twitter" component={() => window.location = 'https://twitter.com/KasperandKitty'} />
                    <Route path="/instagram" component={() => window.location = 'https://www.instagram.com/kasperandkitty/'} />
                    <Route path="/linkedin" component={() => window.location = 'https://www.linkedin.com/company/kasper-kitty/'} />

                    <PrivateRoute location={location} exact path="/logout" component={Logout} />
                    <Route location={location} exact path="/404" component={PageNotFound} />
                    <Redirect from="*" to="/404" />
                  </Switch>
                </Route>
              </div>
            )}
          />
        </Router>
      </StripeProvider>
    );
  }
}


// const AppCoWork = () => (
//   <Router history={history}>
//     <Route
//       render={({ location }) => (
//         <div>

//           {!Cookies.get('accepted_cookies') &&
//           <div className="cookie-container">
//             <p className="cookie-explanation">
//               We use cookies to give you a better experience at kasperandkitty.com.
//               By continuing to use our site, you are agreeing to the use of cookies as set in our
//               <a href="/cookie-policy"> Cookie Policy. </a>
//             </p>
//             <p className="cookie-accept-link">
//               <a href="#" onClick={() => { Cookies.set('accepted_cookies', true); }} > Accept and Close</a>
//             </p>
//           </div>
//           }
//           <Route location={location} key={location.key}>
//             <Switch>
//               <Route location={location} exact path="/login" component={Login} />
//               <Route location={location} exact path="/forgotten-password" component={ForgottenPassword} />
//               <Route location={location} exact path="/password-reset" component={PasswordReset} />
//               {/*<Route location={location} exact path="/password-new" component={NewPassword} />*/}
//               <Route location={location} exact path="/reset-password" component={ResetPassword} />
//               <Route location={location} exact path="/password-new/:code" component={NewPassword} />

//               <Route location={location} exact path="/shop" component={Shop} />
//               <Route location={location} exact path="/product/:id" component={Product} />
//               <PrivateRoute location={location} exact path="/cart" component={CheckoutBasketPage} />
//               <PrivateRoute location={location} exact path="/checkout/delivery" component={CheckoutDeliveryDetailsPage} />
//               <PrivateRoute location={location} exact path="/checkout/payment" component={CheckoutPaymentPage} />

//               <Route location={location} exact path="/payment/:id" component={CartPayment} />

//               <Route location={location} exact path="/" component={Start} />
//               <PrivateRoute location={location} exact path="/dashboard" component={Dashboard} />
//               <PrivateRoute location={location} exact path="/your-dogs-food" component={YourDogsFood} />
//               <PrivateRoute location={location} exact path="/payment-history" component={PaymentHistory} />
//               <PrivateRoute location={location} path="/payment-history/:order_id" component={Order} />
//               <PrivateRoute location={location} exact path="/live-orders" component={LiveOrders} />
//               <PrivateRoute location={location} exact path="/your-dogs-goals" component={YourDogsGoals} />
//               <PrivateRoute location={location} exact path="/activities" component={Activities} />
//               <PrivateRoute location={location} exact path="/dog-profile" component={DogProfile} />
//               <PrivateRoute location={location} exact path="/your-pets" component={YourPets} />
//               <PrivateRoute location={location} exact path="/your-details" component={YourDetails} />
//               <PrivateRoute location={location} exact path="/billing-details" component={BillingDetails} />
//               <PrivateRoute location={location} exact path="/delivery-details" component={DeliveryDetails} />
//               <PrivateRoute location={location} exact path="/fitkolar-settings" component={FitkolarSettings} />

//               <PrivateRoute location={location} path="/welcome" component={Onboarding} />
//               <Route location={location} exact path="/sign-up" component={SignUp} />
//               <Route location={location} exact path="/sign-in" component={SignIn} />
//               <Route location={location} exact path="/stay-in-touch" component={StayInTouch} />
//               <Route location={location} exact path="/add-dog" component={AddDog} />
//               <Route location={location} exact path="/gender" component={Gender} />
//               <Route location={location} exact path="/neutered" component={Neutered} />
//               <Route location={location} exact path="/spayed" component={Spayed} />
//               <Route location={location} exact path="/pregnant" component={Pregnant} />
//               <Route location={location} exact path="/age" component={Age} />
//               <Route location={location} exact path="/breed" component={Breed} />
//               <Route location={location} exact path="/weight" component={Weight} />
//               <Route location={location} exact path="/height" component={Height} />
//               <Route location={location} exact path="/body-condition" component={BodyCondition} />
//               <Route location={location} exact path="/activity" component={Activity} />
//               <Route location={location} exact path="/health-details" component={Health} />
//               <Route location={location} exact path="/allergy" component={Allergy} />
//               <Route location={location} exact path="/ingredients" component={Ingredients} />
//               <Route location={location} exact path="/food-preferences" component={FoodPrefer} />
//               <Route location={location} exact path="/titbits" component={Titbits} />
//               <Route location={location} exact path="/flavours" component={Flavours} />
//               <Route location={location} exact path="/order-confirmed" component={OrderConfirmed} />
//               <Route location={location} exact path="/thank-you" component={ThankYou} />

//               <Route location={location} exact path="/how-it-works" component={TakeLead} />
//               <Route location={location} exact path="/app" component={MoreThanFood8} />

//               <Route location={location} exact path="/food" component={NotFood} />
//               <Route location={location} exact path="/dry-food" component={MoreThanFood2} />
//               <Route location={location} exact path="/other-food" component={MoreThanFood4} />

//               <Route location={location} exact path="/tech" component={MoreThanFood1} />
//               <Route location={location} exact path="/fitkolar" component={MoreThanFood7} />

//               <Route location={location} exact path="/blog" component={YourFriends} />

//               <Route location={location} exact path="/about-us" component={MeetKasper} />

//               <Route location={location} exact path="/whistle" component={Whistle} />
//               <Route location={location} exact path="/terms-and-conditions" component={Terms} />
//               <Route location={location} exact path="/privacy-policy" component={Privacy} />
//               <Route location={location} exact path="/cookie-policy" component={Cookie} />
//               <Route location={location} exact path="/intro-offer" component={IntroOffer} />
//               <Route location={location} exact path="/offer" component={Offer} />
//               <Route location={location} exact path="/more-than-food3" component={MoreThanFood3} />
//               <Route location={location} exact path="/more-than-food5" component={MoreThanFood5} />
//               <Route location={location} exact path="/more-than-food6" component={MoreThanFood6} />

//               <Route location={location} exact path="/game" component={Game} />
//               <Route location={location} exact path="/guest-checkout" component={GuestCheckout} />

//               {/* empty pages */}
//               <Route location={location} exact path="/faqs" component={Faqs} />
//               <Route location={location} exact path="/reviews" component={Reviews} />
//               <Route location={location} exact path="/coming-soon" component={ComingSoon} />
//               <Route location={location} exact path="/news" component={News} />
//               <Route location={location} exact path="/health-issues" component={HealthIssues} />
//               <Route location={location} exact path="/delivery-and-return" component={DeliveryReturn} />

//               {/* External social media pages */}
//               <Route path="/facebook" component={() => window.location = 'https://www.facebook.com/kasperandkitty/'} />
//               <Route path="/twitter" component={() => window.location = 'https://twitter.com/KasperandKitty'} />
//               <Route path="/instagram" component={() => window.location = 'https://www.instagram.com/kasperandkitty/'} />
//               <Route path="/linkedin" component={() => window.location = 'https://www.linkedin.com/company/kasper-kitty/'} />

//               <PrivateRoute location={location} exact path="/logout" component={Logout} />
//               <Route location={location} exact path="/404" component={PageNotFound} />
//               <Redirect from="*" to="/404" />
//             </Switch>
//           </Route>
//         </div>
//       )}
//     />
//   </Router>
// );

export default connect(() => ({
  loggedIn: isLoggedIn(),
}))(App);
