import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as session from '../services/session';
import Button from '../components/Button';
import Input from '../components/Input';
import FormErrors from '../components/FormErrors';
// import logo from '../assets/images/auth-logo.png';
import logo from '../assets/images/new_logo.png';
import * as Sentry from '@sentry/browser';

export default class FormSignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPassword: true,
      passwordHint: true,
      signInEmail: '',
      signInPassword: '',
      loading: false,
      errors: [],
      redirectToDashboard: false,
    };

    this.togglePassword = () => {
      this.setState({
        isPassword: !this.state.isPassword,
      });
    };

    this.handleChange = (state, event) => {
      this.setState({ [state]: event.target.value });
      if (state === 'signInPassword' && event.target.value === '') {
        this.setState({
          passwordHint: true,
        });
      } else {
        this.setState({
          passwordHint: false,
        });
      }
    };

    this.attemptLogin = (e) => {
      e.preventDefault();
      if (this.state.loading) return;

      const { signInEmail, signInPassword } = this.state;
      this.setState({ signInPassword: '', loading: true, errors: [] });

      session.authenticate({ email: signInEmail, password: signInPassword })
        .then(() => {
          this.setState({ loading: false, redirectToDashboard: true });
        })
        .catch((err) => {
          this.setState({ loading: false });

          if (err.response && err.response.message) {
						this.setState({ errors: [`${err.response.message}.`] });
            Sentry.setTag('error-type', 'user-login-error');
						Sentry.setUser({ email: this.state.signInEmail });
            Sentry.setContext("server response", err);
            Sentry.captureException(new Error('Unable to login'))
          } else {
            this.setState({ errors: ['An error occurred.'] });
          }
        });
    };
  }


  render() {
    if (this.state.redirectToDashboard) {
      return <Redirect to="/dashboard" />;
    }
    const logoSize = {
        maxWidth:'110px',
        maxHeight:'55px',
    };
    return (
      <div className="col-xl-5 col-lg-5 col-md-12 row mx-0 justify-content-center animated fadeIn">
        <div className="col-xl-10 col-lg-11 col-md-12 text-center pt-4 form-auth">
          <div className="row justify-content-center pb-4">
            <img style={logoSize} src={logo} alt="" className="logo animated fadeInDown" />
          </div>
          <div className="row justify-content-center text-center">
            <h5 className="font-weight-bold text-uppercase animated fadeInDown">Welcome back!</h5>
            <span className="a-random-test-class"></span>
            <p className="subtitle px-5 animated fadeInUp">
                You missed a lot while you were gone. Find out what updates and news await!
            </p>
          </div>
          <div className="row justify-content-center sign-up-form">
            <div className="col-xl-9 col-lg-9 col-md-9">
              <FormErrors errors={this.state.errors} />
              <div className="input-container">
                <Input
                  type="text"
                  placeholder="Email*"
                  value={this.state.signInEmail}
                  onChangeText={event => this.handleChange('signInEmail', event)}
                />
                <span className="icon fa fa-envelope" />
              </div>
              <div className="input-container">
                <Input
                  placeholder="Password*"
                  value={this.state.signInPassword}
                  type={`${this.state.isPassword ? 'password' : 'text'}`}
                  onChangeText={event => this.handleChange('signInPassword', event)}
                />
                <span className={`password-hint ${this.state.passwordHint ? '' : 'hidden'}`}>8 characters or more</span>
                <span
                  onClick={this.togglePassword}
                  className={`icon password fa ${this.state.isPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                />
                <p className="text-left  w-100">* Required fields <Link to="/password-reset" className="float-right text-underline forgot-password">Forgot password?</Link></p>
              </div>

              <Button
                onClick={event => this.attemptLogin(event)}
                classes="button-primary fill red mt-5 animated fadeInUp mb-2"
                label="LOG IN"
              />
              <Link to="/sign-up" title="Already a Member? Login!" className="already-member">Not a Member Yet? Sign Up!</Link>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
