import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as dogsActionCreators from '../../data/dogs/actions';

const dietPreferences = ['Grain-free', 'Cereal-free', 'Hypoallergenic'];
const breedNames = {
  'Unknown-giant': 'Giant',
  'Unknown-large': 'Large',
  'Unknown-med': 'Medium',
  'Unknown-small': 'Small',
  'Unknown-toy': 'Toy',
};

class ReviewDogInfo extends React.Component {
  constructor(props) {
    super(props);
    this.editDog = (step) => {
      this.props.toggleDog(this.props.index);
      const modalBackground = document.getElementsByClassName('modal-backdrop ');
      modalBackground[0].remove();
      this.props.history.push(step);
    };
  }

  render() {
    const { dog } = this.props;

    const healthIssues = dog.health_issues
      .filter(issue => issue.selected === true)
      .map(issue => issue.label);

    const dogFlavours = dog.flavours
      .filter(item => item.selected)
      .map(item => item.name);

    let breed = 'Not filled';
    if (dog.breedTab === 'crossbreed'
        && dog.crossBreed
        && Array.isArray(dog.crossBreed)
        && dog.crossBreed.length > 0) {
      breed = dog.crossBreed.map(b => !b ? '' : b.name).join(', ');
    } else if (dog.breedTab === 'unknown') {
      breed = breedNames[dog.breed.name] || breed;
    } else if (dog.breedTab === 'purebred' && dog.breed) {
      breed = dog.breed.name;
    }

    const reproductionStatus =
          dog.gender === 'male' && dog.neutered !== null && dog.neutered
            ? 'Neutered'
            : dog.gender === 'male' && dog.neutered !== null && !dog.neutered
              ? 'Not neutered'
              : dog.gender === 'female' && dog.spayed !== null && dog.spayed
                ? 'Spayed'
                : dog.gender === 'female' && dog.spayed !== null && !dog.spayed
                  ? 'Not spayed'
                  : 'Not filled';

    const age = dog.yearAge === null && dog.months === null
      ? 'Not filled'
      : dog.yearAge !== null && dog.months !== null
        ? `${dog.yearAge}y ${dog.months}m`
        : dog.yearAge !== null && dog.months === null
          ? `${dog.yearAge}y`
          : `${dog.months}m`;


    return (
      <div className="container-fluid px-0">
        <div className="row justify-content-xl-between justify-content-lg-between justify-content-md-between justify-content-sm-center justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12 review-dog-info py-4 px-4 my-1">
            <h4 className="text-left text-uppercase review-dog-info__card-title">
                  general Information
              <span
                role="button"
                tabIndex={0}
                onClick={() => this.editDog('/gender')}
                onKeyPress={() => this.editDog('/gender')}
                className="float-right pt-1 fa fa-edit"
              />
            </h4>
            <div className="row">
              <div className="col-6 py-1 text-left review-dog-info__card-property">Name</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">{dog.name}</div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Gender</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value text-capitalize">{dog.gender !== '' ? dog.gender : 'Not filled'}</div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Age</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">{age}</div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Reproduction Status</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">{reproductionStatus}</div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Breed</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">{breed}</div>
            </div>
          </div>
          <div style={{ margin: 5 }} className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12 review-dog-info py-4 px-4 my-1">
            <h4 className="text-left text-uppercase review-dog-info__card-title">
                body condition
              <span
                role="button"
                tabIndex={0}
                onClick={() => this.editDog('/weight')}
                onKeyPress={() => this.editDog('/weight')}
                className="float-right pt-1 fa fa-edit"
              />
            </h4>
            <div className="row">
              <div className="col-6 py-1 text-left review-dog-info__card-property">Weight</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">
                {dog.weight !== 0 ? `${dog.weight} ${dog.weightUnits}` : 'Not filled'}
              </div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Height</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">
                {dog.height !== 0 ? `${dog.height} ${dog.heightUnits}` : 'Not filled'}
              </div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Body Condition</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value text-capitalize">{dog.shapeName !== '' ? dog.shapeName : 'Not filled'}</div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Activity Level</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value text-capitalize">{dog.activityName !== '' ? dog.activityName : 'Not filled'}</div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12 review-dog-info py-4 px-4 my-1">
            <h4 className="text-left text-uppercase review-dog-info__card-title">
              Health Details
              <span
                role="button"
                tabIndex={0}
                onClick={() => this.editDog('/health-details')}
                onKeyPress={() => this.editDog('/health-details')}
                className="float-right pt-1 fa fa-edit"
              />
            </h4>
            <div className="row">
              <div className="col-6 py-1 text-left review-dog-info__card-property">Health Issues</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">{healthIssues.length !== 0 ? healthIssues.join(', ') : 'Not filled'}</div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Allergies</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">
                {dog.allergy !== null && dog.allergy ? 'Yes' : dog.allergy !== null && !dog.allergy ? 'No' : 'Not filled'}
              </div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Diet preferences</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value text-capitalize">
                {dog.dietary_preferences !== '' ? dietPreferences[dog.dietary_preferences - 1] : 'Not filled' }
              </div>
            </div>
          </div>
          <div style={{ margin: 5 }} className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12 review-dog-info py-4 px-4  my-1">
            <h4 className="text-left text-uppercase review-dog-info__card-title">
              food details
              <span
                role="button"
                tabIndex={0}
                onClick={() => this.editDog('/food-preferences')}
                onKeyPress={() => this.editDog('/food-preferences')}
                className="float-right pt-1 fa fa-edit"
              />
            </h4>
            <div className="row">
              <div className="col-6 py-1 text-left review-dog-info__card-property">Food Preferences</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">
                {dog.current_wet_food !== '' || dog.current_dry_food !== '' ? `${dog.current_wet_food} ${dog.current_dry_food}` : 'Not filled'}
              </div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Food Habits</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">
                {dog.titbits !== null && dog.titbits ? 'Yes' : dog.titbits !== null && !dog.titbits ? 'No' : 'Not filled'}
              </div>
              <div className="col-6 py-1 text-left review-dog-info__card-property">Food Flavours</div>
              <div className="col-6 py-1 text-left review-dog-info__card-value">{dogFlavours.length !== 0 ? dogFlavours.join(', ') : 'Not filled'}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
});
const mapDispatchToProps = dispatch => ({
  toggleDog: index => dispatch(dogsActionCreators.toggleDog(index)),
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewDogInfo));
