import React, { Component } from 'react';

export default class CheckboxString extends Component {
  render() {
    return (
      <div className="checkboxstring-container">
        <img src="/assets/images/checkbox.png" />
        <p className="desc">{this.props.text}</p>
      </div>
    );
  }
}
