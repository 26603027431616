import React from 'react';
import { connect } from 'react-redux';
import postcode from 'postcode-validator';
import * as sessionSelectors from '../../services/session/selectors';
import * as session from '../../services/session';

import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import Row from '../../components/Row';
import HalfColumn from '../../components/HalfColumn';
import FullColumn from '../../components/FullColumn';
import Checkbox from '../../components/Checkbox';
import InputField from '../../components/InputField';
import { validateMandatory } from '../../utils';
import FormErrors from '../../components/FormErrors';


class AboutYou extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: props.user.first_name,
      surname: props.user.surname,
      mobileNumber: props.user.mobile_number,
      landline: undefined,
      email: props.user.email,
      billingPostcode: '',
      billingCountry: 'United Kingdom',
      billingAddress1: '',
      billingAddress2: '',
      billingTownCity: '',
      billingCounty: '',
      deliveryEqualsBilling: false,
      deliveryPostcode: '',
      deliveryCountry: 'United Kingdom',
      deliveryAddress1: '',
      deliveryAddress2: '',
      deliveryTownCity: '',
      deliveryCounty: '',
      AboutYou: true,
      errors: [],
    };

  }

  componentDidMount() {
    if (this.props.stepsState.AboutYou) {
      this.setState({
        AboutYou: this.props.stepsState.AboutYou,
        firstName: this.props.stepsState.aboutYoufirstName,
        surname: this.props.stepsState.aboutYousurname,
        mobileNumber: this.props.stepsState.aboutYoumobileNumber,
        landline: this.props.stepsState.aboutYoulandline,
        email: this.props.stepsState.aboutYouemail,
        billingPostcode: this.props.stepsState.aboutYoubillingPostcode,
        billingCountry: this.props.stepsState.aboutYoubillingCountry,
        billingAddress1: this.props.stepsState.aboutYoubillingAddress1,
        billingAddress2: this.props.stepsState.aboutYoubillingAddress2,
        billingTownCity: this.props.stepsState.aboutYoubillingTownCity,
        billingCounty: this.props.stepsState.aboutYoubillingCounty,
        deliveryEqualsBilling: this.props.stepsState.aboutYoudeliveryEqualsBilling,
        deliveryPostcode: this.props.stepsState.aboutYoudeliveryPostcode,
        deliveryCountry: this.props.stepsState.aboutYoudeliveryCountry,
        deliveryAddress1: this.props.stepsState.aboutYoudeliveryAddress1,
        deliveryAddress2: this.props.stepsState.aboutYoudeliveryAddress2,
        deliveryTownCity: this.props.stepsState.aboutYoudeliveryTownCity,
        deliveryCounty: this.props.stepsState.aboutYoudeliveryCounty,
      });
    } else {
    }
  }

  onNext() {
    return new Promise((resolve, reject) => {
      const mandatoryFields = [
        'firstName', 'surname', 'mobileNumber', 'email',
        'billingPostcode', 'billingCountry', 'billingAddress1', 'billingTownCity', 'billingCounty',
        'deliveryPostcode', 'deliveryCountry', 'deliveryAddress1', 'deliveryTownCity', 'deliveryCounty',
      ];

      const errors = validateMandatory(this.state, mandatoryFields);

      if (errors.length > 0) {
        this.setState({ errors });
        reject();
      }

      if (!postcode.validate(this.state.billingPostcode, 'UK')) {
        errors.push('Postcode is invalid for UK');
        this.setState({ errors });
        reject();
      }

      this.props.onUpdateState({
        AboutYou: this.state.AboutYou,
        aboutYoufirstName: this.state.firstName,
        aboutYousurname: this.state.surname,
        aboutYoumobileNumber: this.state.mobileNumber,
        aboutYoulandline: this.state.landline,
        aboutYouemail: this.state.email,
        aboutYoubillingPostcode: this.state.billingPostcode,
        aboutYoubillingCountry: this.state.billingCountry,
        aboutYoubillingAddress1: this.state.billingAddress1,
        aboutYoubillingAddress2: this.state.billingAddress2,
        aboutYoubillingTownCity: this.state.billingTownCity,
        aboutYoubillingCounty: this.state.billingCounty,
        aboutYoudeliveryEqualsBilling: this.state.deliveryEqualsBilling,
        aboutYoudeliveryPostcode: this.state.deliveryPostcode,
        aboutYoudeliveryCountry: this.state.deliveryCountry,
        aboutYoudeliveryAddress1: this.state.deliveryAddress1,
        aboutYoudeliveryAddress2: this.state.deliveryAddress2,
        aboutYoudeliveryTownCity: this.state.deliveryTownCity,
        aboutYoudeliveryCounty: this.state.deliveryCounty,
      });
      resolve();

      const billing_address = {
        postcode: this.state.billingPostcode,
        country: this.state.billingCountry,
        address1: this.state.billingAddress1,
        address2: this.state.billingAddress2,
        town: this.state.billingTownCity,
        county: this.state.billingCounty,
      };

      const delivery_address = {
        postcode: this.state.deliveryPostcode,
        country: this.state.deliveryCountry,
        address1: this.state.deliveryAddress1,
        address2: this.state.deliveryAddress2,
        town: this.state.deliveryTownCity,
        county: this.state.deliveryCounty,
      };

      session.updateUser({
        delivery_address,
        billing_address,
      }).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
  }

  render() {
    return (
      <div>
        <OrangeTextHeading text="Just a few steps and you'll be ready to get started" fontSize={30} centered marginBottom />
        <form>
        <FormErrors errors={this.state.errors} />
          <Row>
            <FullColumn noPaddingBottom>
              <GreyText text="Your name" fontSize={20} />
            </FullColumn>
          </Row>
          <Row>
            <HalfColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="First name*"
                value={this.state.firstName}
                onChangeText={event => this.setState({ firstName: event.target.value })}
                noMarginBoottom
              />
            </HalfColumn>
            <HalfColumn>
              <InputField
                type="text"
                placeholder="Surname*"
                value={this.state.surname}
                onChangeText={event => this.setState({ surname: event.target.value })}
                noMarginBoottom
              />
            </HalfColumn>
          </Row>
          <Row>
            <FullColumn noPaddingBottom>
              <GreyText text="Contact details" fontSize={20} />
            </FullColumn>
          </Row>
          <Row>
            <HalfColumn noPaddingBottom>
              <InputField
                type="number"
                placeholder="Mobile number*"
                value={this.state.mobileNumber}
                onChangeText={event => this.setState({ mobileNumber: event.target.value })}
                noMarginBoottom
              />
            </HalfColumn>
            <HalfColumn noPaddingBottom>
              <InputField
                type="number"
                placeholder="Landline"
                value={this.state.landline}
                onChangeText={event => this.setState({ landline: event.target.value })}
                noMarginBoottom
              />
            </HalfColumn>
          </Row>
          <div className="row no-margin">
            <HalfColumn>
              <InputField
                type="email"
                placeholder="Email*"
                value={this.state.email}
                onChangeText={event => this.setState({ email: event.target.value })}
                noMarginBoottom
              />
            </HalfColumn>
          </div>
          <Row>
            <FullColumn noPaddingBottom>
              <GreyText text="Billing address" fontSize={20} />
            </FullColumn>
          </Row>
          <Row>
            <HalfColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="Postcode*"
                value={this.state.billingPostcode}
                onChangeText={(event) => {
                  if (this.state.deliveryEqualsBilling) {
                    this.setState({
                      billingPostcode: event.target.value,
                      deliveryPostcode: event.target.value,
                    });
                  } else {
                    this.setState({
                      billingPostcode: event.target.value,
                    });
                  }
                }}
                noMarginBoottom
              />
            </HalfColumn>
            <HalfColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="Country*"
                value={this.state.billingCountry}
                onChangeText={(event) => {
                  if (this.state.deliveryEqualsBilling) {
                    this.setState({
                      billingCountry: event.target.value,
                      deliveryCountry: event.target.value,
                    });
                  } else {
                    this.setState({
                      billingCountry: event.target.value,
                    });
                  }
                }}
                disable={true}
                noMarginBoottom
              />
            </HalfColumn>
          </Row>
          <Row>
            <FullColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="Address line 1*"
                value={this.state.billingAddress1}
                onChangeText={(event) => {
                  if (this.state.deliveryEqualsBilling) {
                    this.setState({
                      billingAddress1: event.target.value,
                      deliveryAddress1: event.target.value,
                    });
                  } else {
                    this.setState({
                      billingAddress1: event.target.value,
                    });
                  }
                }}
                noMarginBoottom
              />
            </FullColumn>
          </Row>
          <Row>
            <FullColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="Address line 2"
                value={this.state.billingAddress2}
                onChangeText={(event) => {
                  if (this.state.deliveryEqualsBilling) {
                    this.setState({
                      billingAddress2: event.target.value,
                      deliveryAddress2: event.target.value,
                    });
                  } else {
                    this.setState({
                      billingAddress2: event.target.value,
                    });
                  }
                }}
                noMarginBoottom
              />
            </FullColumn>
          </Row>
          <Row>
            <HalfColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="Town/City*"
                value={this.state.billingTownCity}
                onChangeText={(event) => {
                  if (this.state.deliveryEqualsBilling) {
                    this.setState({
                      billingTownCity: event.target.value,
                      deliveryTownCity: event.target.value,
                    });
                  } else {
                    this.setState({
                      billingTownCity: event.target.value,
                    });
                  }
                }}
                noMarginBoottom
              />
            </HalfColumn>
            <HalfColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="County*"
                value={this.state.billingCounty}
                onChangeText={(event) => {
                  if (this.state.deliveryEqualsBilling) {
                    this.setState({
                      billingCounty: event.target.value,
                      deliveryCounty: event.target.value,
                    });
                  } else {
                    this.setState({
                      billingCounty: event.target.value,
                    });
                  }
                }}
                noMarginBoottom
              />
            </HalfColumn>
          </Row>
          <div className="row no-margin">
            <HalfColumn noPaddingBottom>
              <Checkbox
                label="My delivery address is the same as my billing address"
                active={this.state.deliveryEqualsBilling}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    deliveryEqualsBilling: !this.state.deliveryEqualsBilling,
                  });

                  if (this.state.deliveryEqualsBilling) {
                    this.setState({
                      deliveryPostcode: '',
                      deliveryAddress1: '',
                      deliveryAddress2: '',
                      deliveryTownCity: '',
                      deliveryCounty: '',
                    });
                  } else {
                    this.setState({
                      deliveryPostcode: this.state.billingPostcode,
                      deliveryAddress1: this.state.billingAddress1,
                      deliveryAddress2: this.state.billingAddress2,
                      deliveryTownCity: this.state.billingTownCity,
                      deliveryCounty: this.state.billingCounty,
                    });
                  }
                }}
              />
            </HalfColumn>
          </div>
          <Row>
            <FullColumn noPaddingBottom>
              <GreyText text="Delivery address" fontSize={20} />
            </FullColumn>
          </Row>
          <Row>
            <HalfColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="Postcode*"
                value={this.state.deliveryPostcode}
                onChangeText={event => this.setState({ deliveryPostcode: event.target.value })}
                disable={this.state.deliveryEqualsBilling}
                noMarginBoottom
              />
            </HalfColumn>
            <HalfColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="Country*"
                value={this.state.deliveryCountry}
                onChangeText={event => this.setState({ deliveryCountry: event.target.value })}
                disable={true}
                noMarginBoottom
              />
            </HalfColumn>
          </Row>
          <Row>
            <FullColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="Address line 1*"
                value={this.state.deliveryAddress1}
                onChangeText={event => this.setState({ deliveryAddress1: event.target.value })}
                disable={this.state.deliveryEqualsBilling}
                noMarginBoottom
              />
            </FullColumn>
          </Row>
          <Row>
            <FullColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="Address line 2"
                value={this.state.deliveryAddress2}
                onChangeText={event => this.setState({ deliveryAddress2: event.target.value })}
                disable={this.state.deliveryEqualsBilling}
                noMarginBoottom
              />
            </FullColumn>
          </Row>
          <Row>
            <HalfColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="Town/City*"
                value={this.state.deliveryTownCity}
                onChangeText={event => this.setState({ deliveryTownCity: event.target.value })}
                disable={this.state.deliveryEqualsBilling}
                noMarginBoottom
              />
            </HalfColumn>
            <HalfColumn noPaddingBottom>
              <InputField
                type="text"
                placeholder="County*"
                value={this.state.deliveryCounty}
                onChangeText={event => this.setState({ deliveryCounty: event.target.value })}
                disable={this.state.deliveryEqualsBilling}
                noMarginBoottom
              />
            </HalfColumn>
          </Row>
        </form>
      </div>
    );
  }
}

export default connect(() => ({
  user: sessionSelectors.getUser(),
}), dispatch => ({}), null, { withRef: true })(AboutYou);
