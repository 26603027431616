import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import Scroll from 'react-scroll';

import TitleBox from './TitleBox';
import RegistrationStep from './RegistrationStep';
import PreviousNext from './PreviousNext';

class StepWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: this.getCurrentStep(),
      scrolling: false,
      loading: false,
      stepsState: {},
    };

    // Get the state from localStorage
    const saved = window.localStorage.getItem(this.props.pathPrefix);

    if (saved !== undefined && saved !== 'undefined' && saved !== null) {
      this.state.stepsState = JSON.parse(saved);
    }

    this.forceStep = this.forceStep.bind(this);
  }

  componentDidMount() {
    if (this.props.onChangeStep !== undefined) {
      this.props.onChangeStep(this.state.currentStep);
    }

    Scroll.Events.scrollEvent.register('end', () => {
      this.setState({ scrolling: false });
    });

    // Check isn't too high
    const index = window.localStorage.getItem(`${this.props.pathPrefix}-highest`);
    const { pathPrefix, steps, history } = this.props;

    if (index !== null) {
      if (this.getStepIndex() > parseInt(index, 10)) {
        history.push(`${pathPrefix}${steps[parseInt(index, 10)].path}`);
      }
    } else if (this.getStepIndex() !== 0) {
      history.push(`${pathPrefix}${steps[0].path}`);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentStep.path !== this.state.currentStep.path) {
      if (this.props.onChangeStep !== undefined) {
        this.props.onChangeStep(this.state.currentStep);
      }
    }
  }

  componentWillUnmount() {
    Scroll.Events.scrollEvent.remove('end');
  }

  onPrevious() {
    // const success = this.scroll();
    // if (success === false) return;

    setTimeout(() => {
      const index = this.getStepIndex();
      const {
        history,
        pathPrefix,
        steps,
      } = this.props;

      history.push(`${pathPrefix}${steps[index - 1].path}`);
    }, 500);
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.canGoNext()) {
      this.onNext();
    }
  }

  onNext() {
    this.setState({ loading: true });

    // Do onNext on the current step first
    let result;

    if (this.step.onNext) {
      result = this.step.onNext();
    }

    if (this.step.getWrappedInstance) {
      let wrappedInstance = this.step;
      while (wrappedInstance.getWrappedInstance) {
        wrappedInstance = wrappedInstance.getWrappedInstance();
      }

      result = wrappedInstance.onNext();
    }

    result
      .then(() => {
        this.setState({ loading: false });
        // const success = this.scroll();
        // if (success === false) return;

        setTimeout(() => {
          const index = this.getStepIndex();
          const {
            history,
            pathPrefix,
            steps,
          } = this.props;

          window.localStorage.setItem(`${this.props.pathPrefix}-highest`, index + 1);

          history.push(`${pathPrefix}${steps[index + 1].path}`);
        }, 500);
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.scroll(); // This will scroll to the top of the page to show that errors are being displayed.
      });
  }

  scroll() {
    if (this.state.scrolling) return false;

    this.setState({ scrolling: true });

    Scroll.scroller.scrollTo('topStep', {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -50,
    });

    return true;
  }

  getStepIndex() {
    const { steps } = this.props;
    const { currentStep } = this.state;

    return steps.findIndex(item => item.path === currentStep.path);
  }

  getCurrentStep() {
    const {
      steps,
      location,
      pathPrefix,
    } = this.props;

    // Remove the path prefix from the path name
    const path = location.pathname.replace(pathPrefix, '');

    for (const step of steps) {
      if (step.path === path) {
        return step;
      }
    }

    return undefined;
  }

  canGoPrevious() {
    return this.getStepIndex() > 0;
  }

  canGoNext() {
    const index = this.getStepIndex();
    return this.props.steps.length > (index + 1);
  }

  forceStep(s) {
    if (s) {
      this.setState({ currentStep: s });
      this.onNext();
    }
  }

  render() {
    const {
      steps,
      pathPrefix,
      forceStep,
    } = this.props;

    const step = this.getCurrentStep();

    if (step === undefined) {
      return (
        <Redirect
          to={{ pathname: `${pathPrefix}${steps[0].path}` }}
        />
      );
    }

    if (forceStep !== undefined) {
      return (
        <Redirect
          to={{ pathname: `${pathPrefix}${steps[forceStep].path}` }}
        />
      );
    }

    const Component = step.component;

    return (
      <div
        style={{
          opacity: this.state.scrolling ? 0.6 : 1,
          transitionProperty: 'opacity',
          transitionDuration: '0.5s',
        }}
      >
        <Scroll.Element name="topStep" />
        <TitleBox title={step.title}>
          <RegistrationStep stepCount={steps.length} activeStep={this.getStepIndex() + 1} />
          <Component
            ref={(ref) => { this.step = ref; }}
            onSubmit={e => this.onSubmit(e)}
            forceStep={this.forceStep}
            stepsState={this.state.stepsState}
            onUpdateState={(newState) => {
              const stepsState = {
                ...this.state.stepsState,
                ...newState,
              };
              this.setState({
                stepsState,
              });

              window.localStorage.setItem(this.props.pathPrefix, JSON.stringify(stepsState));
            }}
          />
          {step.noControls !== true && (
            <PreviousNext
              onPrevious={() => this.onPrevious()}
              showPrevious={this.canGoPrevious()}
              onNext={() => this.onNext()}
              showNext={step.next !== null && this.canGoNext()}
              loading={this.state.loading}
              next={step.next !== undefined ? step.next : 'Next'}
            />
          )}
        </TitleBox>
      </div>
    );
  }
}

export default withRouter(StepWizard);
