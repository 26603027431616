import React from 'react';
import CrossIcon from '../../icons/CrossIcon';
import ChooseOption from './ChooseOption';
import ChooseDog from './ChooseDog';

class ExtraModal extends React.Component {
  state = {
    step: 1,
  };

  nextStep = () => {
    this.setState(prevState => ({
      step: prevState.step + 1,
    }));
  };

  render() {
    const { closeModal } = this.props;
    return (
      <div className="mui-modal extra-modal">
        <CrossIcon clickHandler={closeModal} classes="cross-modal" />
        {
          this.state.step === 1 ?
            <ChooseOption nextStep={this.nextStep} closeModal={closeModal} /> :
            <ChooseDog closeModal={closeModal} />
        }
      </div>
    );
  }
}

export default ExtraModal;
