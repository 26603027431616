import {
  fetchApi,
} from '../../services/api';

const endPoints = {
  products: '/products/products',
  productFitkolar: '/products/products/fitkolar',
  bespoke: '/products/bespoke',
  notification: '/products/fitkolar_notifications',
};

export const products = () => fetchApi(endPoints.products, {}, 'get');
export const productFitkolar = () => fetchApi(endPoints.productFitkolar, {}, 'get');
export const bespoke = id => fetchApi(endPoints.bespoke, {
  id,
}, 'get');
export const notification = () => fetchApi(endPoints.notification, {}, 'get');