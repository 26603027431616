import React from 'react';

import { connect } from 'react-redux';

// import moment from 'moment';
import * as dogsSelectors from '../data/dogs/selectors';

import Navigation from '../components/Navigation';
import SubNavigation from '../components/SubNavigation';
import Row from '../components/Row';
import Footer from '../components/Footer';
import SubscriptionBox from '../components/SubscriptionBox';

class Subscription extends React.Component {
  constructor(props) {
    super(props);

    const storage = localStorage.getItem('productCart');
    this.state = {
      shop: storage !== null ? JSON.parse(storage) : []
    };
  }

  render() {
    return (
      <div className="subscription-container container">
        <Navigation
          white
          textColor="white-text"
          productCount={this.state.shop.reduce((acc, cur) => acc + cur.quantity, 0)}
        />
        <SubNavigation />

        <div className="inner-container">

          <div className="col-12">
            <div className="col-12">
              <div className="menu-title">
                <img className="title-image" src="../assets/images/dashboard-nav-icon.png" alt="#" />
                {this.props.dog.name.toUpperCase()}&apos; SHOP
              </div>
            </div>
            <Row>
              <div className="col-12">
                <SubscriptionBox dogID={this.props.dog ? this.props.dog.ID : ''} />
                <p className="remove-item-hint">
                  To remove or change quantity of existing items, please go to your orders.
                </p>
              </div>
            </Row>

          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(() => ({
  dog: dogsSelectors.getCurrentDog(),
}), null, null, { withRef: true })(Subscription);
