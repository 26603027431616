import * as actionTypes from './actionTypes';

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: {
      return { ...state, [action.product.ID]: action.product };
    }
    case actionTypes.UPDATE: {
      const _state = { ...state };
      action.products.forEach((product) => {
        _state[product.ID] = product;
      });
      return _state;
    }
    case actionTypes.CLEAR:
      return {};
    default:
      return state;
  }
};
