import React from 'react';
import { withRouter } from 'react-router-dom';
import GreyText from '../../components/GreyText';
import Row from '../../components/Row';
import * as orderApi from '../../data/orders/index';

class Congratulations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryDate: null,
    };
  }

  componentDidMount() {
    const { orderID } = this.props.stepsState;
    orderApi.getOrder(orderID)
      .then((order) => {
        const deliveryDateObj = new Date(order.date_required);
        const deliveryDate = deliveryDateObj.toLocaleDateString('en-GB', {
          weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
        });
        this.setState({ deliveryDate });
      })
      .catch((err) => {
        console.log('An error occured. ', err);
      });
  }

  render() {
    const { deliveryDate } = this.state;
    return (
      <Row>
        <div className="col-12 col-md-10 col-lg-8">
          <GreyText
            text="Many thanks for registering with Kasper &amp; Kitty"
            fontSize={20}
            centered
            bold
            marginBottom
          />
          {deliveryDate && <GreyText
            text={`You've completed your account and your food is on it's way and will be with you by ${deliveryDate}.`}
            fontSize={20}
            centered
            marginBottom
          />}
          <GreyText
            text="Please get in touch with any queries or comments you may have, we would love to hear from you!"
            fontSize={20}
            centered
            marginBottom
          />
          <GreyText
            text="Kasper &amp; Kitty"
            fontSize={20}
            centered
            bold
          />
          <GreyText
            text="friends@kasperandkitty.com"
            fontSize={20}
            centered
            marginBottom
          />
          <Row>
            <div className="col-12 col-sm-4">
              <button
                className="grey-button"
                onClick={() => {
                  const { onUpdateState } = this.props;

                  onUpdateState({
                    dog: {
                      breed_id: -1,
                      neutered: false,
                      microchip_id: '',
                      age: 1,
                      gender: 'Male',
                      name: '',
                      dob: '',
                      weight: '',
                      height: '',
                      weightShow: '',
                      heightShow: '',
                    },
                  });
                  setTimeout(() => {
                    window.location.href = '/welcome/your-dog';
                  });
                }}
              >
                Add another dog
              </button>
            </div>
            <div className="col-12 col-sm-4">
              <button
                className="grey-button"
                onClick={() => {
                  localStorage.removeItem('/welcome', '');

                  this.props.history.push('/dashboard');
                }}
              >
                Go to dashboard
              </button>
            </div>
            <div className="col-12 col-sm-4">
              <button
                className="grey-button"
                onClick={() => {
                  localStorage.removeItem('/welcome', '');

                  this.props.history.push('/shop');
                }}
              >
                Shop
              </button>
            </div>
          </Row>
        </div>
      </Row>
    );
  }
}

export default withRouter(Congratulations);
