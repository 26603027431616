import * as actionTypes from './actionTypes';

export const initialState = {
  progress: 0,
  isNavigation: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET:
      return {
        ...state,
        progress: action.progress,
      };
    case actionTypes.TOGGLE:
      return {
        ...state,
        isNavigation: action.isNavigation,
      };
    default:
      return state;
  }
};
