import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import * as actionTypes from '../../data/exports';
import * as dogsActionCreators from '../../data/dogs/actions';
import WizardLayout from '../../components/wizard/WizardLayout';
import Button from '../../components/Button';
import BodyConditionOption from '../../components/wizard/BodyConditionOption';
import verythin from '../../assets/images/wizard/body-condition/verythin.png';
import verythinbot from '../../assets/images/wizard/body-condition/verythinbot.png';
import thin from '../../assets/images/wizard/body-condition/thin.png';
import thinbot from '../../assets/images/wizard/body-condition/thinbot.png';
import average from '../../assets/images/wizard/body-condition/average.png';
import averagebot from '../../assets/images/wizard/body-condition/averagebot.png';
import chubby from '../../assets/images/wizard/body-condition/chubby.png';
import chubbybot from '../../assets/images/wizard/body-condition/chubbybot.png';
import overweight from '../../assets/images/wizard/body-condition/overweight.png';
import overweightbot from '../../assets/images/wizard/body-condition/overweightbot.png';
import * as selectors from '../../data/dogs/selectors';

class BodyCondition extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shapes: [],
      conditions: {
        veryThin: {
          ID: 1,
          name: 'veryThin',
          fat: '5%',
          image: verythin,
          bottomImage: verythinbot,
          selected: false,
          descriptions: ['Ribs & spine very visible (protruding)', 'Prominent pelvic bones with no fat cover'],
        },
        thin: {
          ID: 2,
          name: 'thin',
          fat: '10%',
          image: thin,
          bottomImage: thinbot,
          selected: false,
          descriptions: ['Ribs & spine visible', 'Visible pelvic bones with slight fat cover'],
        },
        average: {
          ID: 3,
          name: 'average',
          fat: '15%',
          image: average,
          bottomImage: averagebot,
          selected: false,
          descriptions: ['Outline of ribs & spine are felt without pressing hard', 'Waist & abdominal tuck evident', 'Pelvic bones has smooth contour with slight fat cover'],
        },
        chubby: {
          ID: 4,
          name: 'chubby',
          fat: '20%',
          image: chubby,
          bottomImage: chubbybot,
          selected: false,
          descriptions: [' Ribs & spine are difficult to feel by touch', 'Pelvic bones are can be felt but not visible'],
        },
        overweight: {
          ID: 5,
          name: 'overweight',
          fat: '25%',
          image: overweight,
          bottomImage: overweightbot,
          selected: false,
          descriptions: [' Ribs & spine are extremely difficult to find by touch', 'Pelvic bones difficult to feel under thick fat cover'],
        },
      },
    };

    this.handleCheckbox = (key) => {
      const dogIndex = this.props.getIndex;
      Object.keys(this.state.conditions).map(item => this.state.conditions[item].selected = false);
      const selectedCond = this.state.conditions[key];
      selectedCond.selected = !selectedCond.selected;
      this.setState({
        conditions: {
          ...this.state.conditions,
        },
      });
      this.props.updateCurrentDog('shape', dogIndex, selectedCond.ID);
      this.props.updateCurrentDog('shapeName', dogIndex, selectedCond.name);
    };

    this.handleStep = (step, e) => {
      e.preventDefault();
      this.props.history.push(step);
    };
  }

  componentDidMount() {
    dogsActionCreators.getShapes()
      .then((response) => {
        const s = [];

        for (let r in response) {
          r = response[r];

          s.push({
            key: r.ID,
            label: r.name,
            text: r.text,
            imageSource: (r.image.Files) ? `http:${r.image.Files[0].Url}` : '/assets/images/dog-2.png',
            activeImageSource: (r.imageActive.Files) ? `http:${r.imageActive.Files[0].Url}` : '/assets/images/dog-2.png',
          });
        }

        this.setState({ shapes: s.sort((a, b) => a.key - b.key) });
      });
    const dogIndex = this.props.getIndex;
    const { pathname } = this.props.location;
    this.props.updateCurrentDog('step', dogIndex, pathname);

    if (this.props.currentDog.shapeName === '') {
      this.props.updateCurrentDog('progress', dogIndex, 60);
      this.props.updateCurrentDog('shapeName', dogIndex, 'Average');
    }

    this.props.toggleDogNav(true);
  }

  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      slidesToShow: 8,
      slidesToScroll: 1,
      autoplay: true,
      centerMode: false,
      speed: 500,
      autoplaySpeed: 3500,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1366,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const conditionList = Object.keys(this.state.conditions).map(condition => (
      <BodyConditionOption
        animation="fadeIn"
        image={this.state.conditions[condition].image}
        bottomImage={this.state.conditions[condition].bottomImage}
        inputLabel={this.state.conditions[condition].name === 'veryThin' ? 'very thin' : this.state.conditions[condition].name}
        fat={this.state.conditions[condition].fat}
        descriptions={this.state.conditions[condition].descriptions}
        active={this.state.conditions[condition].selected || this.state.conditions[condition].ID === this.props.currentDog.shape}
        onClick={() => this.handleCheckbox(this.state.conditions[condition].name)}
        key={this.state.conditions[condition].ID}
      />
    ));
    return (
      <div className="container-fluid" style={{ overflow: 'auto' }}>
        <WizardLayout>
          <div className="col-12 row justify-content-center">
            <div className="col-12 row justify-content-center">
              <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                {this.props.currentDog.name}'s Body Condition
              </h5>
            </div>
          </div>
        </WizardLayout>
        <div className="row justify-content-center">
          <div className="col-12 row justify-content-center">
            <div className="col-12 px-0 row justify-content-center shape-list animated fadeIn">
              {conditionList}
            </div>
            <div className="col-12 px-0 shape-list-slider py-4 animated fadeIn">
              <Slider {...settings}>
                {conditionList}
              </Slider>
            </div>
          </div>

          <div style={{ maxWidth: '390px' }} className="col-xl-3 col-lg-5 col-md-5 col-sm-6 px-0 pb-4 row justify-content-center">
            <div className="col-6 pl-xl-2 pl-lg-2 pl-md-0 pt-3 text-center">
              <Button
                onClick={e => this.handleStep('/height', e)}
                classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                label="BACK"
              />
            </div>
            <div className="col-6 pr-xl-2 pr-lg-2 pr-md-0 pt-3 text-center">
              <Button
                onClick={e => this.handleStep('/activity', e)}
                classes="button-primary fill orange animated fadeInRight delay-_2s"
                label="NEXT"
              />
            </div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => ({
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BodyCondition));
