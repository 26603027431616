import React from 'react';
import { Redirect } from 'react-router-dom';

import * as session from '../../services/session';

import Navigation from '../../components/Navigation';
import ImageBanner from '../../components/ImageBanner';
import TitleBox from '../../components/TitleBox';
import Footer from '../../components/Footer';
import FullColumn from '../../components/FullColumn';
import HalfColumn from '../../components/HalfColumn';
import Row from '../../components/Row';
import InputField from '../../components/InputField';
import SubmitButton from '../../components/SubmitButton';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import FormErrors from '../../components/FormErrors';
import * as sessionSelectors from '../../services/session/selectors';

export default class ResetPasswordCruft extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirm: '',
      redirect: false,
      errors: [],
    };

    this.setPassword = this.setPassword.bind(this);
  }

  setPassword(e) {
    e.preventDefault();
    this.setState({ errors: [] });
    const { password, passwordConfirm } = this.state;

    if (password === passwordConfirm) {
      session.resetPasswordCruft({ user_id: sessionSelectors.getUser().ID, password }).then(() => {
        this.setState({ redirect: true });
      });
    } else {
      setTimeout(() => {
        this.setState({ errors: ['Password not matching.'] });
      });
    }
  }

  handleChange(state, event) {
    this.setState({ [state]: event.target.value });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <ImageBanner heading="Password reset" subtext="Fear not, we'll get you sorted in no time!" />
        <TitleBox title="Forgotten Password">
          <Row>
            <FullColumn>
              <OrangeTextHeading text="Reset your password" fontSize={30} centered />
              <GreyText text="You have requested to reset the password." fontSize={20} marginBottom centered />
              <HalfColumn centerHorizontally>
                <form onSubmit={this.setPassword}>
                  <FormErrors errors={this.state.errors} />
                  <InputField
                    type="password"
                    value={this.state.password}
                    placeholder="New Password*"
                    onChangeText={event => this.handleChange('password', event)}
                  />
                  <InputField
                    type="password"
                    value={this.state.passwordConfirm}
                    placeholder="Retype new Password*"
                    onChangeText={event => this.handleChange('passwordConfirm', event)}
                  />
                  <SubmitButton value="Reset" centered marginBottom />
                </form>
                {this.state.redirect && (<Redirect to="/live-order-cruft" />)}
              </HalfColumn>
            </FullColumn>
          </Row>
        </TitleBox>
        <Footer />
      </div>
    );
  }
}
