import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { Grid, Hidden, Modal } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import Cart from './Cart';

import * as cartActionCreators from '../../data/cart/actions';
import { getProducts } from '../../data/products/actions';
import { getCartItemsByDog } from '../../data/cart/utils';
import ProductDescription from './ProductDescription';

import * as Sentry from '@sentry/browser';

class CartGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      descriptionShown: false,
      itemToShow: null,
    };
  }

  componentDidMount() {
    if (this.state.loading) {
      this.props.getProducts()
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
		}
		
		const { items, dog } = getCartItemsByDog(this.props.dogs, this.props.products, this.props.cart);
		if (items.length === 0) {
			const dogID = dog ? dog.ID : '';
			Sentry.captureException(new Error("Empty cart at registration, dog id: "+dogID));
		}
  }

  showItemDescription = (event, item) => {
    this.setState({
      descriptionShown: true,
      itemToShow: item,
    });
  };

  hideItemDescription = () => {
    this.setState({
      descriptionShown: false,
      itemToShow: null,
    });
  };

  render() {
    if (this.state.loading) {
      return <CircularProgress />;
    }

    const {
      items,
      dog,
    } = getCartItemsByDog(this.props.dogs, this.props.products, this.props.cart);

    const cartComponent = items.length ? (
      <div>
        <Cart
          dog={dog}
          items={items}
          quantityUp={this.props.quantityUp}
          quantityDown={this.props.quantityDown}
          deleteAll={dogId => this.props.deleteAll(dogId)}
          deleteItem={this.props.deleteItem}
          showItemDescription={this.showItemDescription}
          key={dog.ID + items[0].ID}
        />
      </div>
    ) : <span>Cart is empty</span>;

    return (
      <Grid container>
        <Grid item xs={12}>
          {cartComponent}
        </Grid>

        <Hidden mdUp>
          <Modal hideBackdrop open={this.state.descriptionShown}>
            <ProductDescription
              item={this.state.itemToShow}
              quantityChange={this.props.quantityChange}
              closeModal={this.hideItemDescription}
            />
          </Modal>
        </Hidden>
      </Grid>
    );
  }
}


const mapStateToProps = state => ({
  dogs: state.data.dogs.dogs,
  products: state.data.products,
  cart: state.data.cart,
});
const mapDispatchToProps = dispatch => ({
  quantityUp: (dogId, productId) => dispatch(cartActionCreators.quantityUp(dogId, productId)),
  quantityDown: (dogId, productId) => dispatch(cartActionCreators.quantityDown(dogId, productId)),
  quantityChange:
    (dogId, productId, quantity) =>
      dispatch(cartActionCreators.quantityChange(dogId, productId, quantity)),
  deleteAll: dogId => dispatch(cartActionCreators.deleteAllForDog(dogId)),
  deleteItem: (dogId, productId) => dispatch(cartActionCreators.deleteItem(dogId, productId)),
  getProducts: bindActionCreators(getProducts, dispatch),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartGroup));
