import * as api from '../data/orders/api';

export class PayHandler {
  constructor(props) {
    this.props = props;
  }

  async setup(paymentInfo) {
    const { stripe, elements, user } = this.props;
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement('cardNumber');

    let intentSecret;
    try {
      intentSecret = await api.intentSecret(paymentInfo);
    } catch (e) {
      console.error(e);
      throw e;
    }

    if (!intentSecret || !intentSecret.client_secret) {
      throw new Error('unable to setup the card');
    }

    const payment_method = {
      type: 'card',
      card: cardElement,
    };

    payment_method.billing_details = {
      name: user.first_name + ' ' + user.surname,
      email: user.email,
      address: {
        city: user.billing_address.city,
        country: user.billing_address.country,
        line1: user.billing_address.address1,
        line2: user.billing_address.address2,
        postal_code: user.billing_address.postcode,
        state: user.billing_address.town,
      },
    };
    user.mobile_number.length > 0 ? (payment_method.billing_details.phone = user.mobile_number) : '';

    let result;
    try {
      result = await stripe.confirmCardSetup(intentSecret.client_secret, {
        payment_method,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
    if (result && result.error) {
      throw result.error;
    }
    const intentObject = result.paymentIntent || result.setupIntent;
    if (intentObject && intentObject.status === 'succeeded') {
      return;
    }
    console.info(result);
    throw new Error('unable to setup the card');
  }

  async charge(paymentInfo) {
    const { stripe, elements, user } = this.props;
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement('cardNumber');

    let intentSecret;
    try {
      intentSecret = await api.intentSecret(paymentInfo);
    } catch (e) {
      console.error(e);
      throw e;
    }

    if (!intentSecret || !intentSecret.client_secret) {
      throw new Error('unable to setup the card');
    }

    const payment_method = {
      type: 'card',
      card: cardElement,
    };

    if (paymentInfo.address_line1 && paymentInfo.address_country) {
      payment_method.billing_details = {
        name: user.first_name + ' ' + user.surname,
        email: user.email,
        address: {
          city: paymentInfo.address_city,
          country: paymentInfo.address_country,
          line1: paymentInfo.address_line1,
          line2: paymentInfo.address_line2,
          postal_code: paymentInfo.address_zip,
          state: paymentInfo.address_state,
        },
      };
      user.mobile_number.length > 0 ? (payment_method.billing_details.phone = user.mobile_number) : '';
    }
    let result;
    if (!paymentInfo.payment_method_id) {
      try {
        result = await stripe.confirmCardSetup(intentSecret.client_secret, {
          payment_method,
        });
        paymentInfo.payment_method_id = result?.setupIntent?.payment_method;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
    if (Object.values(paymentInfo).length) {
      try {
        result = await api.orderpay(paymentInfo);
        if (result && result.error) {
          console.error(result.error);
          throw result.error;
        }
        const confirmationRequired = ['requires_payment_method', 'requires_confirmation', 'requires_action'];
        if (result && result.client_secret && result.status && confirmationRequired.includes(result.status)) {
          result = await stripe.confirmCardPayment(result.client_secret, {
            setup_future_usage: 'off_session',
            payment_method: paymentInfo.payment_method_id || payment_method,
          });
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
    if (result.last_payment_error) {
      console.error(result.last_payment_error?.message);
      throw result.last_payment_error;
    }
    if (result && result.error) {
      console.error(result.error.message);
      throw result.error;
    }
    if (result && result.status === 'succeeded') {
      return;
    }
    const intentObject = result.paymentIntent || result.setupIntent;
    if (intentObject && intentObject.status === 'succeeded') {
      return;
    }
    throw new Error(intentObject.status);
  }

  async checkPaymentIntent(order) {
    const { stripe, user } = this.props;

    if (!stripe) {
      return;
    }
    let intent;
    try {
      intent = await api.paymentIntent(order.ID);
    } catch (e) {
      console.error(e);
      throw e;
    }
    if (!intent || !intent.client_secret) {
      return;
    }

    let paymentResponse;
    try {
      paymentResponse = await stripe.confirmCardPayment(intent.client_secret, {
        // payment_method: intent.last_payment_error?.payment_method.id || intent.payment_method.id
        payment_method: user?.bank_card?.card_id,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
    if (paymentResponse && paymentResponse.error) {
      console.log(paymentResponse.error.message);
      throw paymentResponse.error;
    }
    if (paymentResponse.paymentIntent.status === 'succeeded') {
      console.log(paymentResponse.paymentIntent.status);

      if (order.status === 'Failed' && order.subscription_cycle >= 1) {
        try {
          await api.approveFailedOrder(order.ID);
        } catch (e) {
          throw e;
        }
      }
      return paymentResponse.paymentIntent.status;
    }
    throw new Error(paymentResponse.paymentIntent.status);
  }
}
