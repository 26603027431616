import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const getItemLabel = (item) => {
  const possibleKeys = ['label', 'name'];

  for (const key of possibleKeys) {
    if (item[key] !== undefined) {
      return item[key];
    }
  }

  return item;
};

const getItemValue = (item) => {
  const possibleKeys = ['ID', 'id', 'name'];

  for (const key of possibleKeys) {
    if (item[key] !== undefined) {
      return item[key];
    }
  }

  return item;
};

const Dropdown = ({
  activeOption,
  onChangeOption,
  options,
  small,
  placeholder,
  // dirty
  brands,
  profile,
  isMultiple,
  initialValue,

  isCreateble,
  handleCreate,
  isLoading,
}) => {
  const selectOptions = options.map((item) => {
    if (brands === undefined) {
      if (item[1] === undefined) {
        return {
          value: getItemValue(item),
          label: getItemLabel(item),
        };
      }

      return {
        value: getItemValue(item[0]),
        label: `${getItemLabel(item[0])} ${item[1]}`,
      };
    }

    return {
      value: item,
      label: item,
    };
  });

  let selectedOption;

  if (isMultiple) {
    if (activeOption.length > 0) {
      selectedOption = selectOptions.filter(item => (activeOption.indexOf(item.value) !== -1));
    }
  } else {
    selectedOption = selectOptions.find(item => item.value === activeOption);
  }

  if (isCreateble !== undefined && isCreateble) {
    return (
      <CreatableSelect
        name="form-field-name"
        className={`${small ? 'smaller ' : ''}${profile ? 'profile ' : ''}dropdown select`}
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={onChangeOption}
        onCreateOption={handleCreate}
        options={selectOptions}
        value={selectedOption}
        placeholder={placeholder}
        closeMenuOnSelect
        isMulti={isMultiple}
        defaultValue={initialValue}
      />
    );
  }
  return (
    <Select
      isSearchable
      className={`${small ? 'smaller ' : ''}${profile ? 'profile ' : ''}dropdown select`}
      name="form-field-name"
      value={selectedOption}
      onChange={onChangeOption}
      options={selectOptions}
      placeholder={placeholder}
      closeMenuOnSelect
      isMulti={isMultiple}
      defaultValue={initialValue}
    />
  );
};

export default Dropdown;
