import * as actionTypes from './actionTypes';
import * as api from './api';

export const update = state => ({
  type: actionTypes.UPDATE,
  state,
});

export const clear = () => ({
  type: actionTypes.CLEAR,
});


export const clearStoreDogs = () => ({
  type: actionTypes.CLEAR_STORE_DOGS,
});

export const clearDogs = () => (dispatch) => {
  dispatch(clearStoreDogs());
};

export const select = id => ({
  type: actionTypes.SELECT_DOG,
  id,
});

export const set = dog => ({
  type: actionTypes.SET_DOG,
  dog,
});

export const setDog = dog => (dispatch) => {
  dispatch(set(dog));
};

export const updateCurrentDog = (key, index, value) => ({
  type: actionTypes.UPDATE_DOG,
  key,
  index,
  value,
});

export const updateStoreCurrentDog = (key, index, value) => (dispatch) => {
  dispatch(updateCurrentDog(key, index, value));
};

export const removeCurrentDog = index => ({
  type: actionTypes.REMOVE_DOG,
  index,
});

export const removeStoreCurrentDog = index => (dispatch) => {
  dispatch(removeCurrentDog(index));
};

/** * toggle current dog in dog navigation ** */

export const toggleCurrentDog = index => ({
  type: actionTypes.TOGGLE_DOG,
  index,
});

export const toggleDog = index => (dispatch) => {
  dispatch(toggleCurrentDog(index));
};

export const list = () => dispatch => (
  new Promise((resolve) => {
    dispatch(clear());
    api.list().then((dogs) => {
      dispatch(update({ dogs }));
      resolve(dogs);
    });
  })
);

export const fetchBreeds = () => dispatch => (
  new Promise((resolve) => {
    api.breeds().then((breeds) => {
      dispatch(update({ breeds }));
      resolve(breeds);
    });
  })
);

export const fetchAllergens = () => dispatch => (
  new Promise((resolve) => {
    api.allergens().then((allergens) => {
      dispatch(update({ allergens }));
      resolve(allergens);
    });
  })
);

export const fetchFlavours = () => dispatch => (
  new Promise((resolve) => {
    api.flavours().then((flavours) => {
      dispatch(update({ flavours }));
      resolve(flavours);
    });
  })
);

export const fetchConditions = () => dispatch => (
  new Promise((resolve) => {
    api.conditions().then((conditions) => {
      dispatch(update({ conditions }));
      resolve(conditions);
    });
  })
);

export const fetchDiets = () => dispatch => (
  new Promise((resolve) => {
    api.diets().then((diets) => {
      dispatch(update({ diets }));
      resolve(diets);
    });
  })
);

export const create = dog => dispatch => (
  new Promise((resolve, reject) => {
    api.create(dog).then((response) => {
      if (response.dog) {
        dispatch(update({ dogs: [response.dog] }));
        resolve(response.dog);
      } else {
        reject(response);
      }
    });
  })
);

export const updateDog = dog => dispatch => (
  new Promise((resolve) => {
    api.update(dog).then((response) => {
      dispatch(update({ dog: response.dog }));
      resolve(response);
    });
  })
);


/**
 * Get breeds
 */
export const getBreeds = () => (
  new Promise((resolve, reject) => {
    api.breeds()
      .then(resp => resolve(resp))
      .catch(() => reject());
  })
);

/**
 * Get goals
 */
export const getCalories = id => (
  new Promise((resolve) => {
    api.calories(id).then((response) => {
      resolve(response);
    });
  })
);

/**
 * Get kibble shares
 */
export const getKibbleShares = dog => (
  new Promise((resolve) => {
    api.kibbles(dog).then((response) => {
      resolve(response);
    });
  })
);

/**
 * Get brands
 */
export const getBrands = () => (
  new Promise((resolve) => {
    api.brands().then((response) => {
      resolve(response);
    });
  })
);


/**
 * Get goals
 */
export const getGoals = () => (
  new Promise((resolve, reject) => {
    api.goals()
      .then(resp => resolve(resp))
      .catch(() => reject());
  })
);

/**
 * Get shapes
 */
export const getShapes = () => (
  new Promise((resolve, reject) => {
    api.shapes()
      .then(resp => resolve(resp))
      .catch(() => reject());
  })
);


/**
 * Get flavours
 */
export const getFlavours = () => (
  new Promise((resolve) => {
    api.flavours().then((response) => {
      resolve(response);
    });
  })
);


/**
 * Get days
 */
export const getDays = dogID => (
  new Promise((resolve, reject) => {
    api.days(dogID)
      .then(resp => resolve(resp))
      .catch(() => reject());
  })
);

/**
 * Get Next Delivery Date
 */
export const getNextDeliveryDate = dogID => (
  new Promise((resolve, reject) => {
    api.getNextDate(dogID)
      .then(response => resolve(response))
      .catch(() => reject());
  })
);

