import React from 'react';
import CheckboxInput from '../../components/CheckboxInput';

export default class ActivityOption extends React.Component {
  render() {
    const {
      image,
      label,
      description,
      animation,
      active,
      onClick,
    } = this.props;
    return (
      <div className={`activity-option-container text-center ${active ? 'active' : '' } ${animation}`} onClick={onClick}>
        <div className="checkbox pt-3">
          <CheckboxInput
            color="orange"
            active={true}
            onClick={onClick}
          />
          <span className="label text-uppercase">{ label }</span>
        </div>
        <div className={"description px-2 pt-1"}>{description}</div>

            <div className={"image"}>
                <img src={image} alt=""/>
            </div>
      </div>
    );
  }
}
