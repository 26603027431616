import { fetchApi } from '../../services/api';

const endPoints = {
  list: '/dogs',
  calories: '/dogs/calories',
  create: '/dogs/create',
  breeds: '/dogs/breeds',
  allergens: '/dogs/allergens',
  flavours: '/dogs/flavours',
  food: '/dogs/food',
  conditions: '/dogs/conditions',
  diets: '/dogs/diets',
  goals: '/dogs/goals',
  shapes: '/dogs/shapes',
  kibbles: '/dogs/kibbles',
  brands: '/dogs/brands',
};

export const list = () => fetchApi(endPoints.list, {}, 'get');

export const calories = id => fetchApi(endPoints.calories, { id }, 'get');
export const kibbles = id => fetchApi(endPoints.kibbles, { id }, 'get');
export const brands = () => fetchApi(endPoints.brands, {}, 'get');

export const create = dog => fetchApi(endPoints.create, dog, 'post');
export const breeds = () => fetchApi(endPoints.breeds, {}, 'get');
export const allergens = () => fetchApi(endPoints.allergens, {}, 'get');
export const flavours = () => fetchApi(endPoints.flavours, {}, 'get');
export const food = () => fetchApi(endPoints.food, {}, 'get');
export const conditions = () => fetchApi(endPoints.conditions, {}, 'get');
export const diets = () => fetchApi(endPoints.diets, {}, 'get');
export const goals = () => fetchApi(endPoints.goals, 'get');
export const shapes = () => fetchApi(endPoints.shapes, 'get');
export const days = id => fetchApi(`/dogs/${id}/data`, {}, 'get');
export const update = dog => fetchApi(`${endPoints.list}/${dog.ID}`, dog, 'put');
export const getData = (id, date) => fetchApi(`/dogs/${id}/data/${date}`, {}, 'get');
export const getOrders = () => fetchApi('/orders', {}, 'get');
export const getPortions = id => fetchApi('/dogs/portion', { dog_id: id }, 'get');
export const getNextDate = dogID => fetchApi('/subscriptions/nextDelivery', { dog_id: dogID }, 'get');
