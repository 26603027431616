import React from 'react';
import CloseIcon from '../../assets/images/wizard/review-close.png';
import Food1 from '../../assets/images/wizard/tips/ingredients/dog-food1.svg'
import Food2 from '../../assets/images/wizard/tips/ingredients/dog-food2.svg'
import Food3 from '../../assets/images/wizard/tips/ingredients/dog-food3.svg'
import Foods from '../../assets/images/wizard/tips/ingredients/dog-foods.png'
import Tablet from '../../assets/images/wizard/tips/ingredients/ingredient-tip-tablet.png'


const IngredietnsTip = () => (
    <div className="modal custom-modal animated fadeIn" id="ingredients" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered row justify-content-center" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title w-100 pl-5 text-center">
                        What are the types of diet preferences?
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <img src={CloseIcon} alt="" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="container-fluid px-0">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-12 col-12 text-left">
                                <img src={Food1} alt=""/>
                                <h6>
                                    Hypoallergenic
                                </h6>
                                <p className={"pb-4"}>
                                    To ensure we can cater for dogs with a wide range of allergies, we have created a special dry food kibble mix that is made using proteins chemically “split” or hydrolysed into basic amino acid building blocks. This makes the diet significantly less likely to cause an allergic irritation.
                                </p>
                                <ul>
                                    <li>Helps food allergy suffering.</li>
                                    <li>Improves digestive problems.</li>
                                    <li>Low in allergens with right balance of nutrients and vitamins.</li>
                                    <li>Reduces risk of obesity.</li>
                                    <li>Helps with itchy and dry skin or dermatitis.</li>
                                </ul>
                                <img className={"desktop"} src={Foods} alt=""/>
                                <img className={"tablet pb-5"} src={Tablet} alt=""/>
                                <div className={"divider mb-5"}></div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-left">
                                <img src={Food2} alt=""/>
                                <h6>
                                    Grain-Free
                                </h6>
                                <p>
                                    Many dogs have shown to be allergic to grain but there are also  many health benefits associated with a grain-free diet. All our grain-free recipes are made with potato-based carbohydrates and contain no traces of added wheat or grains.
                                </p>
                                <ul>
                                    <li>Helps keep dogs fuller longer resulting in eating less frequently (good news because grain–free/high-protein foods can be more expensive).</li>
                                    <li>May reduce  allergies.</li>
                                    <li>More energy.</li>
                                    <li>Fewer and smaller stools.</li>
                                    <li>Healthier skin.</li>
                                    <li>Shinier coat.</li>
                                    <li>Less shedding.</li>
                                    <li>Better breath.</li>
                                </ul>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-left">
                                <img src={Food3} alt=""/>
                                <h6>
                                    Cereal-Free
                                </h6>
                                <p>
                                    Cereal-free diets are similar to grain-free diets except that our cereal-free recipes are rice-based. Brown rice is an easily digestible carbohydrate along with white rice. Rice based recipes are also great for senior dogs.
                                </p>
                                <ul>
                                    <li>Provides Energy.</li>
                                    <li>Low Percentage of Fat.</li>
                                    <li>Low in Sodium.</li>
                                    <li>Rich in Vitamin D.</li>
                                    <li>Great Source of Niacin, Calcium, Iron, Thiamine & Riboflavin.</li>
                                    <li>Improves Bowel Movements.</li>
                                    <li>Helps Lower Cholesterol.</li>
                                    <li>Cure Stomach Ailments.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default IngredietnsTip