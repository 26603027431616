export default {
  //live server config values
  url: 'https://api.kasperandkitty.com/api',
  stripeKey: "pk_live_CuhfURnmkmPXefUhoa08itfp",
  environment: "live",

  //dev server config values
  // url: 'https://api-dev.kasperandkitty.com/api',
  // stripeKey: 'pk_test_94pPyTA70DITUzw75oVR1orD',
  // environment: 'devserver',


  // local development config values
  // url: 'http://localhost:8080/api',
  // stripeKey: 'pk_test_51Gs8JOGqjA9f5eElKJdQMhyCySBACcNwObWG7l21alRse0Lvdm63gLWFsqOORiqFZHKaqh8mYRElcnDevnjrdkaW00EPk6CS7R',
  // environment: 'localhost',
};
