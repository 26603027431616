import React from 'react';
import Row from '../components/Row';
import FormSignIn from './FormSignIn';
import AuthorizationBanner from './AuthorizationBanner';
import dogImage from '../assets/images/signIn_dog.png';
import wordImage from '../assets/images/Hello.png';
import wordImageTablet from '../assets/images/sign-in--word_tablet.png';

export default class SignInLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { activeStep } = this.props;
    return (
      <Row>
        <FormSignIn />
        <AuthorizationBanner
            bannerColor={this.props.bannerColor}
            dogImage={dogImage}
            wordImage={wordImage}
            tabletWord={wordImageTablet}
        />
      </Row>
    );
  }
}
