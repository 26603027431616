import React from 'react';

const InfoIcon = () => (
  <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.400000006">
      <g id="Step-3:-Payment" transform="translate(-418.000000, -457.000000)" fill="#898B8D">
        <g id="Group-4" transform="translate(86.000000, 216.000000)">
          <g id="icon-/info" transform="translate(332.000000, 241.000000)">
            <path d="M7,0 C3.14015625,0 0,3.14015625 0,7 C0,10.8598437 3.14015625,14 7,14 C10.8598437,14 14,10.8598437 14,7 C14,3.14015625 10.8598438,0 7,0 Z M7,13.125 C3.62271875,13.125 0.875,10.3772813 0.875,7 C0.875,3.62271875 3.6225,0.875 7,0.875 C10.3772813,0.875 13.125,3.62271875 13.125,7 C13.125,10.3772813 10.3772813,13.125 7,13.125 Z" id="Shape" fillRule="nonzero" />
            <path d="M7.67790625,3.486875 C7.5193125,3.32565625 7.3255,3.2449375 7.0958125,3.2449375 C6.8691875,3.2449375 6.67603125,3.32565625 6.51590625,3.486875 C6.356,3.64809375 6.27571875,3.8416875 6.27571875,4.0674375 C6.27571875,4.290125 6.3564375,4.48196875 6.51809375,4.6431875 C6.67975,4.80440625 6.8726875,4.885125 7.0958125,4.885125 C7.322,4.885125 7.515375,4.8055 7.6755,4.64559375 C7.835625,4.486125 7.9156875,4.29340625 7.9156875,4.0674375 C7.9156875,3.84146875 7.8365,3.64809375 7.67790625,3.486875 Z" id="Path" />
            <polygon id="Path" points="7.82840625 9.02934375 7.82840625 5.4140625 5.61575 5.4140625 5.61575 6.496 6.32909375 6.496 6.32909375 9.02934375 5.61575 9.02934375 5.61575 10.1112812 8.52709375 10.1112812 8.52709375 9.02934375"/>
          </g>
        </g>
      </g>
    </g>
  </svg>

);
export default InfoIcon;
