import React from 'react';
import Slider from 'react-slick';

const InstagramCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1500,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-activity-container">
      <div className="slider-container">
        <div className="content-wrapper">
          <Slider {...settings}>
            <div className="slider-image-container">
              <div className="image" style={{ backgroundImage: 'url(/assets/images/yellow-dog-sedentry.png)' }} />
              <row className="label-text">Sedentary</row>
            </div>
            <div className="slider-image-container">
              <div className="lazy-image" style={{ backgroundImage: 'url(/assets/images/yellow-dog-lazy.png)' }} />
              <row className="label-text">Lazy</row>
            </div>
            <div className="slider-image-container">
              <div className="image" style={{ backgroundImage: 'url(/assets/images/yellow-dog-playful.png)' }} />
              <row className="label-text">Playful</row>
            </div>
            <div className="slider-image-container">
              <div className="turbo-image" style={{ backgroundImage: 'url(/assets/images/orange-dog-turbo-paws.png)' }} />
              <row className="label-text">Turbo paws</row>
            </div>
            <div className="slider-image-container">
              <div className="olympic-image" style={{ backgroundImage: 'url(/assets/images/yellow-dog-olympic.png)' }} />
              <row className="label-text">Olympic</row>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default InstagramCarousel;
