import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slider from 'react-rangeslider';

import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import BackgroundContainer from '../../components/BackgroundContainer';
import Row from '../../components/Row';
import FullColumn from '../../components/FullColumn';
import HalfColumn from '../../components/HalfColumn';
import RadioButtons from '../../components/RadioButtons';
import FormErrors from '../../components/FormErrors';
import CheckboxGroup from '../../components/CheckboxGroup';
import Dropdown from '../../components/Dropdown';

import * as dogsActionCreators from '../../data/dogs/actions';
import * as dogsSelectors from '../../data/dogs/selectors';
import { validateMandatory } from '../../utils';

const getImage = (item) => {
  if (item.image && item.image.Files && item.image.Files.length > 0) {
    return item.image.Files[0].Url;
  }

  return `/assets/images/${item.name.toLowerCase()}.png`;
};

const ImageItem = ({
  selected, onClick, imageSource, label, diePreferences
}) => {
  return (
    <div className="col-6 col-sm-3 col-lg-2">
      <a
        href
        className={`${selected ? 'active ' : ''}image-item`}
        onClick={onClick}
      >
        <img src={imageSource} alt="Item" />
        <p>{label}</p>
      </a>
    </div>
  );
};

const getCarouselItemClass = (key, state) => {
  if (state === 1 || state === 9) {
    if (state === (key - 2) || state === (key + 2)) {
      return ' displayed ';
    }
  }

  if (state === (key - 1) || state === (key + 1)) {
    return ' expanded ';
  }

  return '';
};

const InfoCheckbox = ({
  title, subtext, onClick, currentState,
}) => (
  <div className="info-checkbox-container">
    <p className="orange-text">
      {title}
      <a
        href
        className="info-checkbox"
        onClick={onClick}
      >
        <img
          alt=""
          src="/assets/images/tick.png"
          className={`${currentState ? ' active ' : ''}info-tick`}
        />
      </a>
    </p>
    <p className="subtext">{subtext}</p>
  </div>
);

class DogShape extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shapes: [],
      errors: [],
      dog: {
        shape: 1,
        current_dry_food: '',
        current_wet_food: '',
        allergens: [],
        diets: [],
        flavours: [],
        titbits: false,
        prescription_diet: false,
        working_dog: false,
      },
      wetFoods: [],
      dryFoods: [],
      // redirectToNextStep: false,
      num: 5,

      diePreferences: undefined,
    };
  }

  componentDidMount() {
    console.log('about to request brands');
    const brands = dogsActionCreators.getBrands()
      .then((res) => {
        this.setState({ wetFoods: res.WetFoods, dryFoods: res.DryFoods });
      });

    dogsActionCreators.getShapes()
      .then((response) => {
        const shapes = response.sort((a, b) => a.code - b.code).map(r => ({
          key: r.code,
          label: r.name,
          text: r.text,
          imageSource: (r.image.Files) ? `http:${r.image.Files[0].Url}` : '/assets/images/dog-2.png',
          activeImageSource: (r.imageActive.Files) ? `http:${r.imageActive.Files[0].Url}` : '/assets/images/dog-2.png',
        }));

        this.setState({ shapes, num: shapes.length });
      });

    this.props.actions.dogs.fetchFlavours();
    this.props.actions.dogs.fetchAllergens();
    this.props.actions.dogs.fetchDiets();

    if (this.props.stepsState.dog) {
      this.setState({
        dog: Object.assign(
          {},
          this.state.dog,
          this.props.stepsState.dog,
        ),
      });
    }

    if (this.props.stepsState.dogImage) {
      this.setState({
        dogImage: this.props.stepsState.dogImage,
      });
    }

    if (this.props.dog !== undefined) {
      console.log('Dog: ', this.props.dog);

      const wholeDog = this.props.dog;

      const newDog = {
        shape: this.props.dog.shape,
        current_dry_food: this.props.dog.current_dry_food,
        current_wet_food: this.props.dog.current_wet_food,
        allergens: this.props.dog.allergens !== null ? this.props.dog.allergens : [],
        diets: this.props.dog.diets !== null ? this.props.dog.diets : [],
        flavours: this.props.dog.flavours !== null ? this.props.dog.flavours : [],
        titbits: this.props.dog.titbits,
        prescription_diet: this.props.dog.prescription_diet,
        working_dog: this.props.dog.working_dog,
      };

      this.setState({
        dog: newDog,
      });
    }
  }

  onChangeHandler(key) {
    return (event) => {
      let value = event;

      if (event.target) {
        value = event.target.value;
      }

      if (event.value) {
        value = event.value;
      }

      let formattedValue = value;

      const numericKeys = ['shape'];

      if (numericKeys.indexOf(key) !== -1) {
        if (value.length > 0) {
          formattedValue = parseFloat(value);
          if (Number.isNaN(formattedValue)) {
            formattedValue = value;
          }
        }
      }

      if (key === 'diets') {
        const diets = formattedValue.map(item => item.name.toLowerCase());

        if (diets.indexOf('hypoallergenic') !== -1) {
          this.setState({
            diePreferences: 'hypoallergenic',
          });
        } else if (diets.indexOf('grain free') !== -1) {
          this.setState({
            diePreferences: 'grain free',
          });
        } else if (diets.indexOf('cereal free') !== -1) {
          this.setState({
            diePreferences: 'cereal free',
          });
        } else {
          this.setState({
            diePreferences: undefined,
          });
        }
      }

      const dog = Object.assign({}, this.state.dog, {
        [key]: formattedValue,
      });

      this.setState({ dog });
    };
  }

  onNext() {
    return new Promise((resolve, reject) => {
      const mandatoryFields = [];

      const errors = validateMandatory(this.state.dog, mandatoryFields);

      if (errors.length > 0) {
        this.setState({ errors });
        reject();
      }

      this.props.onUpdateState({ dog: this.state.dog });

      const fullDog = this.state.dog;

      if (this.state.dogImage) {
        fullDog.image_string = this.state.dogImage[0];
      }

      if (this.props.dog === undefined) {
        this.props.actions.dogs.create(fullDog)
          .then((resp) => {
            if (resp.errors && resp.errors.length > 0) {
              this.setState({ errors: resp.errors });
              reject();
            } else {
              const dog = resp;
              this.props.onUpdateState({ dog: undefined });
              this.props.actions.dogs.select(dog.ID);
              resolve();
            }
          })
          .catch(() => reject());
      } else {
        this.handleUpdate(resolve, reject);
      }
    });
  }

  handleUpdate(resolve, reject) {
    const wholeDog = this.props.dog;

    wholeDog.shape = this.state.dog.shape;
    wholeDog.current_dry_food = this.state.dog.current_dry_food;
    wholeDog.current_wet_food = this.state.dog.current_wet_food;
    wholeDog.allergens = this.state.dog.allergens;
    wholeDog.diets = this.state.dog.diets;
    wholeDog.flavours = this.state.dog.flavours;
    wholeDog.titbits = this.state.dog.titbits;
    wholeDog.prescription_diet = this.state.dog.prescription_diet;

    this.props.actions.dogs.updateDog(wholeDog)
      .then((dog) => {
        if (dog.errors) {
          for (const err of dog.errors) {
            this.state.errors.push(err);
          }
          reject();

          return;
        }

        resolve();
      })
      .catch((err) => {
        console.log('An error has occured: ', err);
      });
  }

  render() {
    const {
      allergens, flavours, diets, stepsState,
    } = this.props;
    const { shapes } = this.state;

    if (stepsState.dog === undefined) {
      if (this.props.dog === undefined) {
        return <p>There seems to be a problem with this action. Please try going to your dashboard. If problems still persist please contact us.</p>;
      }
    }

    let name;
    if (this.props.dog !== undefined) {
      name = this.props.dog.name;
    } else {
      name = stepsState.dog.name;
    }

    return (
      <div>
        <OrangeTextHeading
          text={`Let's get to know ${name}`}
          fontSize={30}
          centered
          marginBottom
        />
        <FormErrors errors={this.state.errors} />
        <Row>
          <FullColumn noPaddingBottom>
            <BackgroundContainer marginBottom>
              <Row>
                <div className="col-12 col-md-10">
                  <OrangeTextHeading text={`What shape is ${name}?`} fontSize={30} centered />
                  <GreyText text="Please move the slider or click the number that best reflects your dog's shape to help assess whether they are a healthy weight. Hover the icons for an expanded view." fontSize={20} centered marginBottom />
                </div>
              </Row>
              <div className="activity-carousel-container">
                <div className="line" />
                {shapes.map((item, index) => (
                  <a
                    key={item.key}
                    className={`${(this.state.dog.shape === item.key ? ' active ' : '') + (getCarouselItemClass(item.key, this.state.dog.shape))}activity-carousel-item`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.onChangeHandler('shape')(item.key);
                    }}
                  >
                    {this.state.dog.shape === item.key ? (
                      <img src={item.activeImageSource} />
                    ) : (
                      <img src={item.imageSource} />
                    )}
                    <p className="key">{index + 1}</p>
                  </a>
                ))}
              </div>
              <div className="activity-text-container">
                {shapes.map((item, index) => (
                  <div
                    key={item.key}
                    className={`${(this.state.dog.shape === item.key ? ' active ' : '') + (getCarouselItemClass(item.key, this.state.dog.shape))}activity-text-item`}
                  >
                    <p>
                      {item.label}
                    </p>
                  </div>
                  ))}
              </div>
              <Slider
                min={1}
                // 3: 6; 4: 5.67; 5: 5.25
                // 1: 20; 2: 6.7
                max={this.state.num}
                step={1}
                value={this.state.dog.shape}
                orientation="horizontal"
                reverse={false}
                tooltip={false}
                handleLabel={this.state.dog.shape.toString()}
                onChange={this.onChangeHandler('shape')}
              />
              <div className="carousel-text-container">
                {shapes.map(item => (
                  <div
                    key={item.key}
                    className={`${this.state.dog.shape === item.key ? ' active ' : ''}carousel-text`}
                  >
                    <GreyText
                      text={item.text}
                      fontSize={26}
                      centered
                      bold
                    />
                  </div>
                ))}
              </div>
            </BackgroundContainer>
            <BackgroundContainer marginBottom>
              <OrangeTextHeading text={`${name}'s eating habits`} fontSize={30} centered />
              <GreyText text={`What does ${name}'s current food diet consist of?`} fontSize={20} centered marginBottom />
              <Row>
                <FullColumn noPaddingBottom>
                  <Dropdown
                    brands
                    activeOption={this.state.dog.current_dry_food}
                    onChangeOption={this.onChangeHandler('current_dry_food')}
                    options={this.state.dog.current_dry_food === '' ? this.state.dryFoods : [...this.state.dryFoods, this.state.dog.current_dry_food]}
                    placeholder="Current dry food (Please specify)"
                  />
                </FullColumn>
              </Row>
              <Row>
                <FullColumn noPaddingBottom>
                  <Dropdown
                    brands
                    activeOption={this.state.dog.current_wet_food}
                    onChangeOption={this.onChangeHandler('current_wet_food')}
                    options={ this.state.dog.current_wet_food === '' ? this.state.wetFoods : [...this.state.wetFoods, this.state.dog.current_wet_food] }
                    placeholder="Current wet food (Please specify)"
                  />
                </FullColumn>
              </Row>
              <Row>
                <FullColumn noPaddingBottom>
                  <RadioButtons
                    white
                    context="Do you feed your dog any titbits?"
                    leftLabel="Yes"
                    leftValue
                    rightLabel="No"
                    rightValue={false}
                    activeValue={this.state.dog.titbits}
                    onChange={this.onChangeHandler('titbits')}
                  />
                </FullColumn>
              </Row>
              <Row>
                <FullColumn>
                  <GreyText
                    text="Food allergens (select all that apply)"
                    fontSize={24}
                    bold
                  />
                </FullColumn>
              </Row>
              <div className="row no-margin">
                <CheckboxGroup
                  options={allergens}
                  onChange={this.onChangeHandler('allergens')}
                  value={this.state.dog.allergens}
                  component={({ option, onClick, checked }) => (
                    <ImageItem
                      key={option.ID}
                      imageSource={getImage(option)}
                      label={option.name}
                      selected={checked}
                      onClick={onClick}
                      diePreferences={this.state.diePreferences}
                    />
                  )}
                />
              </div>
              <Row>
                <FullColumn>
                  <GreyText
                    text="Diet preferences (select all that apply)"
                    fontSize={24}
                    bold
                  />
                </FullColumn>
              </Row>
              <div className="row no-margin">
                <CheckboxGroup
                  options={diets}
                  onChange={this.onChangeHandler('diets')}
                  value={this.state.dog.diets}
                  component={({ option, onClick, checked }) => (
                    <ImageItem
                      key={option.ID}
                      imageSource={getImage(option)}
                      label={option.name}
                      selected={checked}
                      onClick={onClick}
                    />
                  )}
                />
              </div>
              <Row>
                <FullColumn>
                  <GreyText
                    text="Favourite flavours (select all that apply)"
                    fontSize={24}
                    bold
                  />
                </FullColumn>
              </Row>
              <div className="row no-margin">
                <CheckboxGroup
                  options={flavours}
                  onChange={this.onChangeHandler('flavours')}
                  value={this.state.dog.flavours}
                  component={({ option, onClick, checked }) => (
                    <ImageItem
                      key={option.ID}
                      imageSource={getImage(option)}
                      label={option.name}
                      selected={checked}
                      onClick={onClick}
                    />
                  )}
                />
              </div>
            </BackgroundContainer>
          </FullColumn>
        </Row>
        <Row>
          <FullColumn noPaddingBottom>
            <InfoCheckbox
              currentState={this.state.dog.prescription_diet}
              title="Looking for a prescription diet? Other health issues?"
              subtext="Your dog's wellbeing is a priority to us. We don't offer anything but the best and recommended consulting your vet for prescription diets - despite what others may be offering, conditions like pancreatitis and serious allergies (hypoallergenic) need proper treatment and consultation for nutrition."
              onClick={(e) => {
                e.preventDefault();
                this.onChangeHandler('prescription_diet')(!this.state.dog.prescription_diet);
              }}
            />
          </FullColumn>
        </Row>
        <Row>
          <HalfColumn>
            <GreyText
              text="Certain health issues such as vomiting and ear infections may be indicative of more serious conditions. Please consult your vet to arrange suitable dietary plans."
              fontSize={20}
              marginBottom
            />
            <InfoCheckbox
              currentState={this.state.dog.working_dog}
              title="Working dog?"
              subtext="The Olympic athletes of the dog world have unique dietary needs allowing them to perform at their best. We currently don't cater to working dogs because we believe it is in your dog's best interest to consult the vet regularly on nutrition."
              onClick={(e) => {
                e.preventDefault();
                this.onChangeHandler('working_dog')(!this.state.dog.working_dog);
              }}
            />
          </HalfColumn>
          <HalfColumn centerVertically>
            <img
              className="kasper-eating"
              src="/assets/images/kasper-eating.png"
            />
          </HalfColumn>
        </Row>
      </div>
    );
  }
}

export default connect(() => ({
  flavours: dogsSelectors.getAllFlavours(),
  allergens: dogsSelectors.getAllAllergens(),
  diets: dogsSelectors.getAllDiets(),
}), dispatch => ({
  actions: {
    dogs: bindActionCreators(dogsActionCreators, dispatch),
  },
}), null, { withRef: true })(DogShape);
