import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FormControl from '@material-ui/core/es/FormControl/FormControl';
import RadioGroup from '@material-ui/core/es/RadioGroup/RadioGroup';
import FormControlLabel from '@material-ui/core/es/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/es/Radio/Radio';

import * as actionTypes from '../../data/exports';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as selectors from '../../data/dogs/selectors';
import WizardLayout from '../../components/wizard/WizardLayout';
// import RadioInput from '../../components/wizard/RadioInput';
// import FoodDropdown from '../../components/wizard/FoodDropdown';
import Dropdown from '../../components/wizard/Dropdown';
import Button from '../../components/Button';
import CloseIcon from '../../assets/images/wizard/close-icon.png';


class FoodPrefer extends React.Component {
  constructor(props) {
    super(props);

    const currentFoodTab = this.props.currentDog.activeFoodTab;
    this.state = {
      activeTab: currentFoodTab === undefined ? 'dry' : currentFoodTab,
      wetFood: [],
      dryFood: [],
      current_wet_food: this.props.currentDog.current_wet_food !== '' ? this.props.currentDog.current_wet_food : [],
      current_dry_food: this.props.currentDog.current_dry_food !== '' ? this.props.currentDog.current_dry_food : [],
      isError: false,
    };

    this.handleStep = (step, e) => {
      e.preventDefault();
      // const { current_wet_food: wetFood, current_dry_food: dryFood, activeTab } = this.state;
      // let isError = false;
      // if (activeTab === 'dry' && dryFood.length === 0) isError = true;
      // else if (activeTab === 'wet' && wetFood.length === 0) isError = true;
      // else if (activeTab === 'mixed' && dryFood.length + wetFood.length === 0) isError = true;

      

      // if (!isError) {
      this.props.history.push(step);
      // } else {
      //   this.setState({ isError });
      // }
    };

    this.handleBackStep = (step, e) => {
      e.preventDefault();
      this.props.history.push(step);
    };

    this.handleRadioInput = (e) => {
      this.setState({ activeTab: e.target.value });
      // Save radio food option (wet, dry, mixed) to current dog, only so it persists when clicking back
      this.props.updateCurrentDog('activeFoodTab', this.props.getIndex, e.target.value);
    };

    this.toggleFood = async (state, item) => {
      const dogIndex = this.props.getIndex;
      this.state[state].push(item.name);
      await this.props.updateCurrentDog(state, dogIndex, this.state[state]);

      if (this.props.currentDog.current_wet_food !== '' || this.props.currentDog.current_dry_food !== '') {
        this.setState({ isError: false });
      }
    };

    this.removeSelectedFood = (state, food) => {
      const dogIndex = this.props.getIndex;
      const filteredState = this.state[state].filter(item => item !== food);
      this.setState({ [state]: filteredState });

      this.props.updateCurrentDog(state, dogIndex, filteredState);
    };

    this.modifyBrands = (...arr) => arr.map(item => ({
      name: item,
      value: item,
    }));
  }

  componentDidMount() {
    dogsActionCreators.getBrands()
      .then((res) => {
        const modifiedWetBrands = this.modifyBrands(...res.WetFoods);
        const modifiedDryBrands = this.modifyBrands(...res.DryFoods);

        this.setState({
          wetFood: modifiedWetBrands,
          dryFood: modifiedDryBrands,
        });
      }).catch(err => console.log(err));

    const dogIndex = this.props.getIndex;
    const { pathname } = this.props.location;

    if (this.props.currentDog.progress < 90) {
      this.props.updateCurrentDog('progress', dogIndex, 90);
    }

    this.props.updateCurrentDog('step', dogIndex, pathname);
    this.props.toggleDogNav(true);
  }

  render() {
    const wetFoodList = this.state.current_wet_food.map(food => (
      <div className="my-2 food-list-item animated fadeInUp" key={food}>
        {food}
        <img
          className="close-icon"
          src={CloseIcon}
          alt={food}
          onClick={() => this.removeSelectedFood('current_wet_food', food)}
        />
      </div>
    ));

    const dryFoodList = this.state.current_dry_food.map(food => (
      <div className="my-2 food-list-item animated fadeInUp" key={food}>
        {food}
        <img
          className="close-icon"
          src={CloseIcon}
          alt={food}
          onClick={() => this.removeSelectedFood('current_dry_food', food)}
        />
      </div>
    ));
    const radioStyles = {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    };
    return (
      <div className="container-fluid">
        <WizardLayout>
          <div className="col-12 row justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-12 row justify-content-center weight-form px-0">
              <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                What food does {this.props.currentDog.name} prefer?
              </h5>
              <form className="col-11 row justify-content-center">
                <FormControl component="fieldset" className="material-radio-buttons" style={{ width: '100%' }}>
                  <RadioGroup
                    style={radioStyles}
                    aria-label="food"
                    name="food"
                    value={this.state.activeTab}
                    onChange={this.handleRadioInput}
                  >
                    <FormControlLabel
                      value="wet"
                      control={<Radio color="primary" />}
                      label="WET"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="dry"
                      control={<Radio color="primary" />}
                      label="DRY"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="mixed"
                      control={<Radio color="primary" />}
                      label="MIXED"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </form>
            </div>
          </div>
        </WizardLayout>
        <div className="row justify-content-center pt-4 mt-3 food-preferences-container">
          <div className="col-12 row justify-content-center px-xl-3 px-lg-3 px-md-3 px-sm-0 px-0">
            <div className={`food-dropdown animated fadeIn col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12 px-0 mx-xl-5 mx-lg-5 mx-md-4 mx-sm-3 mx-0
            ${this.state.activeTab === 'wet' ||
                this.state.activeTab === 'mixed' ? 'active' : ''}`}
            >
              <h6 className="w-100 title pb-2 mt-3 text-center text-uppercase animated fadeInDown delay-_1s">
                Brand of wet food your dog currently eats?*
              </h6>
              <Dropdown
                value={this.props.currentDog.current_wet_food !== '' ? this.props.currentDog.current_wet_food[this.props.currentDog.current_wet_food.length - 1] : 'e.g. Acana'}
                placeholder="e.g. Acana"
                options={this.state.wetFood}
                toggleItem={item => this.toggleFood('current_wet_food', item)}
                onCreateOption={foodName => ({ name: foodName })}
              />
              <div className="tip pl-1">*can accept multiple options</div>
              <div className="w-100 pt-2 text-center">
                {wetFoodList}
              </div>
            </div>
            <div className={`food-dropdown animated fadeIn col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12 px-0 mx-xl-5 mx-lg-5 mx-md-4 mx-sm-3 mx-0
            ${this.state.activeTab === 'dry' ||
                this.state.activeTab === 'mixed' ? 'active' : ''}`}
            >
              <h6 className="w-100 title pb-2 mt-3 text-center text-uppercase animated fadeInDown delay-_1s">
                Brand of dry food your dog currently eats?*
              </h6>
              <Dropdown
                value={this.props.currentDog.current_dry_food !== '' ? this.props.currentDog.current_dry_food[this.props.currentDog.current_dry_food.length - 1] : 'e.g. Acana'}
                placeholder="e.g. Acana"
                options={this.state.dryFood}
                toggleItem={item => this.toggleFood('current_dry_food', item)}
                onCreateOption={foodName => ({ name: foodName })}
              />
              <div className="tip pl-1">*can accept multiple options</div>
              <div className="w-100 pt-2 text-center">
                {dryFoodList}
              </div>
            </div>


          </div>

          <div style={{ maxWidth: '390px' }} className="col-xl-4 col-lg-4 col-md-6 pb-4 row justify-content-center px-3">
            {this.state.isError
              ?
              <div className="animated fadeIn col-12 pt-3 text-center error-message">
                Please select brand of food for your {this.props.currentDog.name}.
                              </div>
              :
              ''
            }
            <div className="col-6 pl-xl-4 pl-lg-3 pl-md-2 pr-1 pt-3 text-center">
              <Button
                onClick={e => this.handleBackStep('/ingredients', e)}
                classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                label="BACK"
              />
            </div>
            <div className="col-6 pr-xl-4 pr-lg-3 pr-md-2 pl-1 pt-3 text-center">
              <Button
                onClick={e => this.handleStep('/titbits', e)}
                classes="button-primary fill orange animated fadeInRight delay-_2s"
                label="NEXT"
              />
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FoodPrefer));

