import React from 'react';
import { Checkbox } from '@material-ui/core';
import CheckedIcon from '../../icons/CheckedIcon';
import UncheckedIcon from '../../icons/UncheckedIcon';

export default class DogCheckbox extends React.PureComponent {
  render() {
    const { item, changeSelection } = this.props;
    const { dog, selected } = item;
    const avatarSrc = dog.image.Url ? `https://${dog.image.Url}` : '/assets/images/kasper-image-white.png';
    return (
      <label className={`dog-checkbox-wrapper ${selected ? 'selected' : ''}`}>
        <div className="dog-checkbox">
          <div>
            <img
              alt={dog.name}
              src={avatarSrc}
              className="dog-avatar rounded-circle"
            />
          </div>
          <div className="dog-name">
            {dog.name}
          </div>
        </div>
        <Checkbox
          checked={selected}
          color="secondary"
          checkedIcon={<CheckedIcon />}
          icon={<UncheckedIcon />}
          className="p-4 p-md-3"
          onChange={changeSelection(item)}
        />
      </label>
    );
  }
}
