import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as session from '../../services/session';

import Navigation from '../../components/Navigation';
import ImageBanner from '../../components/ImageBanner';
import TitleBox from '../../components/TitleBox';
import Footer from '../../components/Footer';
import FullColumn from '../../components/FullColumn';
import HalfColumn from '../../components/HalfColumn';
import Row from '../../components/Row';
import InputField from '../../components/InputField';
import SubmitButton from '../../components/SubmitButton';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import Button from '../../components/Button';
import Input from '../../components/Input';
import ForgotPasswordCard from '../../components/ForgotPasswordCard';
import FormErrors from '../../components/FormErrors';
import dogImage from '../../assets/images/new_password_dog.png';

export default class NewPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      password: '',
      passwordConfirm: '',
      passwordHint: true,
      isPassword: true,
      isConfirmPassword: true,
      redirect: false,
      errors: [],
      // password levels
      tooWeak: false,
      weak: false,
      medium: false,
      strong: false,
    };

    this.togglePassword = (state) => {
      if (state === 'password') {
        this.setState({
          isPassword: !this.state.isPassword,
        });
      } else {
        this.setState({
          isConfirmPassword: !this.state.isConfirmPassword,
        });
      }
    };

    this.setPassword = this.setPassword.bind(this);

    this.handleChange = (state, event) => {
      this.setState({ [state]: event.target.value });
      if (state === 'password') {
        this.passwordChange(event);
      }
    };

    this.passwordChange = (event) => {
      const value = event.target.value;

      // reset levels
      this.setState({
        tooWeak: false,
        weak: false,
        medium: false,
        strong: false,
      });

      const hasMixed = value => new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
      const hasNumber = value => new RegExp(/[0-9]/).test(value);
      const hasSpecial = value => new RegExp(/[!#@$%^&*)(+=._-]/).test(value);

      if (value === '') {
        this.setState({
          passwordHint: true,
        });
      } else {
        this.setState({ passwordHint: false });
        if (value.length < 1) {
          this.setState({ tooWeak: false });
        } else {
          this.setState({ tooWeak: true });
          if (value.length >= 8) {
            if (hasMixed(value) || hasNumber(value) || hasSpecial(value)) {
              this.setState({ weak: true });
            }
            if (hasMixed(value) && hasNumber(value) || hasMixed(value) && hasSpecial(value) || hasNumber(value) && hasSpecial(value)) {
              this.setState({ medium: true });
            }
            if (hasMixed(value) && hasNumber(value) && hasSpecial(value)) {
              this.setState({ strong: true });
            }
          }
        }
      }
    };

    this.displayError = (error) => {
      this.setState({
        errors: [error],
      });
    };
  }

  componentDidMount() {
    if (this.props.match.params.code !== undefined) {
      this.setState({ code: this.props.match.params.code });
    } else {
      this.setState({ errors: ['Invalid code'] });
    }
  }

  setPassword(e) {
    e.preventDefault();
    this.setState({ errors: [] });
    const { code, password, passwordConfirm } = this.state;


    if (this.state.tooWeak && !this.state.weak) {
      this.displayError("Please, don't use too weak password");
      return;
    }

    if (password === passwordConfirm) {
      session.resetPassword({ code, password })
        .then((resp) => {
          if (resp.success === true) {
            this.setState({ redirect: true });
          } else {
            this.setState({ errors: [resp.message] });
          }
        });
    } else {
      setTimeout(() => {
        this.setState({ errors: ['Password not matching.'] });
      });
    }
  }

  render() {
    const errors = this.state.errors;
    return (
      <div className="container-fluid forgotten-password-container">
        <div className="row justify-content-center h-100">
          <div className="col-xl-5 col-lg-7 col-md-12 col-12 row justify-content-center align-items-center">
            <div className="col-xl-9 col-lg-9 col-md-8">
              <div className="forgot-card-container">
                <ForgotPasswordCard
                  title="New Password"
                  description="Choose a strong new password"
                >
                  <div className="row justify-content-center py-3">
                    <div className="col-xl-9 col-lg-9 col-md-10">
                      <FormErrors errors={errors} />
                      <div className="input-container">

                        <Input
                              placeholder="New Password*"
                              value={this.state.password}
                              onChangeText={event => this.handleChange('password', event)}
                              type={`${this.state.isPassword ? 'password' : 'text'}`}
                            />

                        <span
                              className={`password-hint ${this.state.passwordHint ? '' : 'hidden'}`}
                            >8 characters or more
                            </span>

                        <span
                              onClick={() => this.togglePassword('password')}
                              className={`icon password fa ${this.state.isPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                            />
                      </div>
                      <div className="input-container">

                        <Input
                              placeholder="Confirm Password*"
                              value={this.state.passwordConfirm}
                              onChangeText={event => this.handleChange('passwordConfirm', event)}
                              type={`${this.state.isConfirmPassword ? 'password' : 'text'}`}
                            />

                        <div className="password-levels w-100">
                              {this.state.tooWeak ? <span
                                  className="animated fadeInUp password-level too-weak"
                                /> : ''}
                              {this.state.weak ?
                                  <span className="animated fadeInUp password-level weak" /> : ''}
                              {this.state.medium ? <span
                                  className="animated fadeInUp password-level medium"
                                /> : ''}
                              {this.state.strong ? <span
                                  className="animated fadeInUp password-level strong"
                                /> : ''}
                            </div>


                        <span
                              onClick={() => this.togglePassword('confirmPassword')}
                              className={`icon password fa ${this.state.isConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                            />
                      </div>
                      <Button
                        onClick={event => this.setPassword(event)}
                        classes="button-primary fill red mb-3 animated fadeInUp delay-_5s"
                        label="CONFIRM"
                      />
                    </div>
                  </div>
                </ForgotPasswordCard>
                {this.state.redirect && (<Redirect to="/reset-password" />)}
              </div>

            </div>

          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 forgotten-password-banner new-password">
            <div className="forgotten-password-image-container">
              <img className="dog-image reset-pass animated fadeInRight" src={dogImage} alt="" />
            </div>
          </div>

        </div>

      </div>
    );
  }
}
