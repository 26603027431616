import React from "react";
import dateFns from "date-fns";
import SelectSearch from 'react-select-search';
import PrevArrow from '../../assets/images/wizard/datepicker/datepicker-prev-arrow.svg';
import NextArrow from '../../assets/images/wizard/datepicker/datepicker-next-arrow.svg';

class Calendar extends React.Component {
    state = {
        currentMonth: this.props.dogDate === '' ? new Date() : new Date(this.props.dogDate),
        selectedDate: new Date(),
        calendarView: 'days',
        months: [
            {
                number: 0,
                name: 'Jan',
            },
            {
                number: 1,
                name: 'Feb',
            },
            {
                number: 2,
                name: 'Mar',
            },
            {
                number: 3,
                name: 'Apr',
            },
            {
                number: 4,
                name: 'May',
            },
            {
                number: 5,
                name: 'Jun',
            },
            {
                number: 6,
                name: 'Jul',
            },
            {
                number: 7,
                name: 'Aug',
            },
            {
                number: 8,
                name: 'Sep',
            },
            {
                number: 9,
                name: 'Oct',
            },
            {
                number: 10,
                name: 'Nov',
            },
            {
                number: 11,
                name: 'Dec',
            },
        ]
    };
    getYears = startYear => {
        let currentYear = new Date().getFullYear(), years = [];
        startYear = startYear || 1980;

        while (startYear <= currentYear) {
            years.push(startYear++);
        }

        years = years.map(year => ({
            label: year,
            value: year,
            name: year
        })).reverse();

        return years;
    };

    changeView = view => {
        this.setState({calendarView: view})
    };

    renderHeader = () => {
        switch (this.state.calendarView) {
            case 'days':
                const dateFormat = "MMMM YYYY";

                return (
                    <div className="row header mx-0">
                        <div className="col-2 text-center">
                            {/*<div className="icon" onClick={this.prevMonth}>chevron_left</div>*/}
                            <div className="icon" onClick={this.prevMonth}>
                                <img src={PrevArrow} alt=""/>
                            </div>
                        </div>
                        <div className="col-8 col-center text-center px-0" onClick={() => this.changeView('months')}>
                            <span className={"dropdown-label"}>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
                        </div>
                        <div className="col-2 text-center col-end">
                            {/*<div className="icon" onClick={this.nextMonth}>chevron_right</div>*/}
                            <div className="icon" onClick={this.nextMonth}>
                                <img src={NextArrow} alt=""/>
                            </div>
                        </div>
                    </div>
                );
            case 'months':
                const monthsDateFormat = "YYYY";
                return (
                    <div className="header row mx-0">
                        <div className="col-3 text-center d-none">
                            <div className="icon" onClick={this.prevMonth}>
                                chevron_left
                            </div>
                        </div>
                        <div className="col-12 col-center text-center px-0" onClick={() => this.changeView('years')}>
                            <span
                                className={"dropdown-label"}>{dateFns.format(this.state.currentMonth, monthsDateFormat)}</span>
                        </div>
                        <div className="col-3 text-center d-none" onClick={this.nextMonth}>
                            <div className="icon">chevron_right</div>
                        </div>
                    </div>
                );
            case 'years':
                const yearsDateFormat = "YYYY";
                return (
                    <div className="header row mx-0">
                        <div className="col-3 text-center d-none">
                            <div className="icon" onClick={this.prevMonth}>
                                chevron_left
                            </div>
                        </div>
                        <div className="col-12 col-center text-center px-0" onClick={() => this.changeView('days')}>
                            <span
                                className={"dropdown-label"}>{dateFns.format(this.state.currentMonth, yearsDateFormat)}</span>
                        </div>
                        <div className="col-3 text-center d-none" onClick={this.nextMonth}>
                            <div className="icon">chevron_right</div>
                        </div>
                    </div>
                )
        }

    };

    renderDays = () => {
        if (this.state.calendarView === 'days') {
            const dateFormat = "dd";
            const days = [];

            let startDate = dateFns.startOfWeek(this.state.currentMonth);

            for (let i = 0; i < 7; i++) {
                days.push(
                    <div className="col col-center text-uppercase" key={i}>
                        {dateFns.format(dateFns.addDays(startDate, i), dateFormat).slice(0, -1)}
                    </div>
                );
            }

            return <div className="days row">{days}</div>;
        }

    };

    renderCells = () => {
        switch (this.state.calendarView) {
            case 'days':
                const {currentMonth} = this.state;
                const monthStart = dateFns.startOfMonth(currentMonth);
                const monthEnd = dateFns.endOfMonth(monthStart);
                const startDate = dateFns.startOfWeek(monthStart);
                const endDate = dateFns.endOfWeek(monthEnd);

                const dateFormat = "D";
                const rows = [];

                let days = [];
                let day = startDate;
                let formattedDate = "";

                while (day <= endDate) {
                    for (let i = 0; i < 7; i++) {
                        formattedDate = dateFns.format(day, dateFormat);
                        const cloneDay = day;
                        days.push(
                            <div
                                className={`col cell ${
                                    !dateFns.isSameMonth(day, monthStart)
                                        ? "disabled"
                                        : (this.props.startDate != undefined && dateFns.isBefore(day, this.props.startDate)) || (this.props.endDate != undefined && dateFns.isAfter(day, this.props.endDate))
                                        ? "disabled"
                                        :
                                        dateFns.isSameDay(day, new Date(this.props.dogDate)) ? "selected" : ""
                                    }`}
                                key={day}
                                onClick={() => this.setDate(dateFns.parse(cloneDay))}
                            >
                                <span className="number">{formattedDate}</span>
                            </div>
                        );
                        day = dateFns.addDays(day, 1);
                    }
                    rows.push(
                        <div className="row" key={day}>
                            {days}
                        </div>
                    );
                    days = [];
                }
                return <div className="body">{rows}</div>;
            case 'months':
                const currentStateMonth = dateFns.getMonth(this.props.dogDate);
                const months = this.state.months.map(month => {
                    return (

                        <div
                            className={`month-col col-3 text-center py-2 my-1
                            ${month.number === currentStateMonth ? 'selected' : ''}`}
                            key={month.number}
                            onClick={() => this.setMonth(month.number)}>
                            {month.name}
                        </div>
                    )
                });
                return (
                    <div className={"row justify-content-center py-3 px-4"}>
                        {months}
                    </div>
                );
            case 'years':
                const years = this.getYears(1950);
                return (
                    <SelectSearch
                        autofocus={true}
                        options={years}
                        onChange={(year) => this.setYear(year.value)}
                    />
                )
        }

    };

    nextMonth = () => {
        this.setState({
            currentMonth: dateFns.addMonths(this.state.currentMonth, 1)
        });
    };

    prevMonth = () => {
        this.setState({
            currentMonth: dateFns.subMonths(this.state.currentMonth, 1)
        });
    };

    setMonth = month => {
        this.setState({
            currentMonth: dateFns.setMonth(this.state.currentMonth, month),
            calendarView: 'years'
        })
    };

    setYear = year => {
        this.setState({
            currentMonth: dateFns.setYear(this.state.currentMonth, year),
            calendarView: 'days'
        })
    };

    setDate = date => {
        this.setState({selectedDate: date});
        this.props.onChange(date)
    };

    render() {
        return (
            <div className="calendar">
                {this.renderHeader()}
                {this.renderDays()}
                {this.renderCells()}
                {this.state.calendarView !== 'years'
                ?
                <div className={"clear-dob text-center text-capitalize py-2 mx-auto"}
                     onClick={this.props.clearDob}
                >
                    Clear All
                </div>
                :
                ''
                }

            </div>
        );
    }
}

export default Calendar;