import React from 'react';

import Navigation from '../components/Navigation';
import MainImageBanner from '../components/MainImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';

export default class Game extends React.Component {
  render() {
    return (
      <div className="container">
        <Navigation
          absolute
          white
          textColor="white-text"
        />
        <MainImageBanner
          image="url(/assets/images/take-the-lead.jpg)"
          heading="Game"
          subtext=""
        />
        <iframe src="https://client.teamcooper.co.uk/kasperandkitty/flyer/" height="600" width="100%" />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
