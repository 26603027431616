import React from 'react';

const PadlockIcon = () => (
  <svg width="13px" height="17px" viewBox="0 0 13 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Step-3:-Payment" transform="translate(-715.000000, -171.000000)" fill="#FAB522" fillRule="nonzero">
        <g id="Secure-PAyment" transform="translate(715.000000, 169.000000)">
          <g id="locked-padlock" transform="translate(0.000000, 2.000000)">
            <path d="M12.05984,6.86154209 L11.7310613,6.86154209 L11.7310613,5.31206366 C11.7310613,2.4461848 9.47249333,0.0521519507 6.649552,0.00104722793 C6.57241867,-0.000349075975 6.41829067,-0.000349075975 6.341192,0.00104722793 C3.51818133,0.0521519507 1.259648,2.4461848 1.259648,5.31206366 L1.259648,6.86154209 L0.930834667,6.86154209 C0.424146667,6.86154209 0.0108506667,7.38801848 0.0108506667,8.0382423 L0.0108506667,15.8097906 C0.0108506667,16.4592813 0.424146667,16.9906797 0.930869333,16.9906797 L12.05984,16.9906797 C12.566528,16.9906797 12.9798587,16.4592813 12.9798587,15.8097906 L12.9798587,8.0382423 C12.9798587,7.38805339 12.5665627,6.86154209 12.05984,6.86154209 Z M7.53798933,11.9161273 L7.53798933,14.2643963 C7.53798933,14.5332895 7.314424,14.7608522 7.04717867,14.7608522 L5.9436,14.7608522 C5.67632,14.7608522 5.45275467,14.5332895 5.45275467,14.2643963 L5.45275467,11.9161273 C5.193552,11.6592074 5.042648,11.3039528 5.042648,10.910963 C5.042648,10.1662444 5.614336,9.52631828 6.34122667,9.49675154 C6.418256,9.49360986 6.57255733,9.49360986 6.64958667,9.49675154 C7.37647733,9.52631828 7.94816533,10.1662444 7.94816533,10.910963 C7.948096,11.3039528 7.797192,11.6592074 7.53798933,11.9161273 Z M9.56408267,6.86154209 L6.649552,6.86154209 L6.341192,6.86154209 L3.426696,6.86154209 L3.426696,5.31206366 C3.426696,3.6049076 4.804488,2.19334908 6.49535467,2.19334908 C8.18622133,2.19334908 9.56408267,3.6049076 9.56408267,5.31206366 L9.56408267,6.86154209 L9.56408267,6.86154209 Z" id="Shape" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default PadlockIcon;
