import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as dogsActionCreators from '../../data/dogs/actions';

import WizardLayout from '../../components/wizard/WizardLayout';
import Button from '../../components/Button';
import RadioInput from '../../components/wizard/RadioInput';
import * as actionTypes from '../../data/exports';
import * as selectors from "../../data/dogs/selectors";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import RadioGroup from "@material-ui/core/es/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/es/Radio/Radio";

class Titbits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dog: this.props.currentDog,
        };

        this.handleStep = (step, e) => {
            e.preventDefault();
            this.props.history.push(step);
        };

        this.handleRadioInput = (e) => {
            const dogIndex = this.props.getIndex;
            this.props.updateCurrentDog('titbits', dogIndex, Boolean(Number(e.target.value)));
            this.props.history.push('/flavours');
        };
    }

    componentDidMount() {
        const dogIndex = this.props.getIndex;
        const {pathname} = this.props.location;

        if(this.props.currentDog.titbits === null){
            this.props.updateCurrentDog('progress', dogIndex, 95);
        }

        this.props.updateCurrentDog('step', dogIndex, pathname);
        this.props.toggleDogNav(true);
    }

    render() {
        const radioStyles = {
            flexDirection: 'row',
            justifyContent: 'space-evenly'
        };
        return (
            <WizardLayout
                title=""
                wizardProgress={this.props.progress}
            >
                <form className="col-12 row pb-4 justify-content-center px-0">
                    <h5 className="w-100 pb-4 px-3 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                        Do you feed {this.props.currentDog.name} Titbits or Leftovers from the table,
                        or add anything else to their diet?
                    </h5>
                    <FormControl component="fieldset" className={"material-radio-buttons"} style={{width: '65%'}}>
                        <RadioGroup
                            style={radioStyles}
                            aria-label="titbits"
                            name="titbits"
                            value={this.props.currentDog.titbits !== null ? this.props.currentDog.titbits ? '1' : '0' : null }
                            onChange={this.handleRadioInput}
                        >
                            <FormControlLabel
                                value="1"
                                control={<Radio color="primary"  />}
                                label="Yes"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="0"
                                control={<Radio color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </form>
                <div style={{maxWidth:'390px'}} className="col-xl-11 col-lg-11 col-md-10 col-12 px-2 row justify-content-center">
                    <div className="col-6 pl-xl-4 pl-lg-4 pl-md-3 pr-1 text-center">
                        <Button
                            onClick={e => this.handleStep('/food-preferences', e)}
                            classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                            label="BACK"
                        />
                    </div>
                    <div className="col-6 pr-xl-4 pr-lg-4 pr-md-3 pl-1 text-center">
                        <Button
                            disabled={this.props.currentDog.titbits === null}
                            onClick={e => this.handleStep('/flavours', e)}
                            classes="button-primary fill orange animated fadeInRight delay-_2s"
                            label="NEXT"
                        />
                    </div>
                </div>
            </WizardLayout>
        );
    }
}

const mapStateToProps = state => ({
    storeDogs: state.data.dogs.storeDogs,
    currentDog: state.data.dogs.currentDog,
    getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
    updateCurrentDog: (key, index, value) =>
        dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
    toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Titbits));
