import React from 'react';
import { Link } from 'react-router-dom';
import Row from '../components/Row';
import Button from '../components/Button';
import LogoImage from '../assets/images/new_logo.png';
import { revoke } from '../services/session';

export default class ThankYou extends React.Component {
    componentDidMount() {
        localStorage.clear();
        revoke();
    }
    render() {
        return (
            <div className="container-fluid m-0 p-0 thank-you">
                <Link to="/sign-up" title="Already a Member? Login!" className="already-member">
                    <img className="logo-wizard animated fadeInDown" src={LogoImage} alt="" />
                </Link>
                <Row>
                    <h1>THANK YOU</h1>
                    <h2>FOR REGISTERING</h2>
                    <p>Now find a member of staff to <br/>
                    get your <strong>FREE goody bag</strong> and<br/>
                    confirm your registration<br/></p>
                </Row>
                <Button
                        onClick={e => this.handleStep('/titbits', e)}
                        classes="button-primary fill orange animated fadeInRight delay-_2s"
                        label="RESET"
                    />
                {setTimeout(() => this.props.history.push('/sign-up'), 20000)}
            </div>
        )
    }
}