import 'main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

import { store, persistor } from './store';
import customTheme from './theme';
import App from './app';
import apiConfig from './services/api/config';

Sentry.init({
  environment: apiConfig.environment,
  dsn: "https://febef4367c53456785b952b4405bfb26@o418139.ingest.sentry.io/5320277", 
});

if (window.location.pathname === '/thank-you') {

  console.log('thanks');
  persistor.purge();
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <MuiThemeProvider theme={customTheme}>
        <App />
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('app'),
);
