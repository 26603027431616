import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

import * as dogsSelectors from '../../data/dogs/selectors';
import * as ordersActionCreators from '../../data/orders/actions';

import CloseIcon from '../../assets/images/wizard/review-close.png';
import CheckMarkIcon from '../../assets/images/checked-white-icon.png';

class Future extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      future: '',
      online: '',
    };

    this.nextStep = this.nextStep.bind(this);
  }

  futureChange = (e) => {
    this.setState({
      future: e.target.value,
    });
  };

  onlineChange = (e) => {
    this.setState({
      online: e.target.value,
    });
  };

  styledLabel = (value) => {
    return (
      <span
        style={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 14,
        }}>
        {value}
      </span>
    );
  };

  nextStep() {
    if (this.state.future === '') {
      window.alert('Please select one of options about future service.');
      return
    }

    if (this.state.online === '') {
      window.alert('Please select one of options about previous online services for your dog');
      return
    }

    this.props.updateQuestionnaire({
      future_usage: this.state.future,
      online_service: this.state.online,
    });

    this.props.nextStep();
  }

  render() {
    const dogName = dogsSelectors.getCurrentDog().name;
    return (
      <div
        className="modal animated fadeIn future-modal"
        id="future"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered row justify-content-center"
          role="document">
          <div className="modal-content">
            <div className="modal-header px-0 pt-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Cancel {dogName}&apos;s subscription
              </h5>
              <button
                type="button"
                className="close text-right"
                onClick={this.props.cancelStep}
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">
                  <img src={CloseIcon} alt="" />
                </span>
              </button>
            </div>

            <div className="modal-body px-0 pb-0">
              <div style={{ width: '95%' }}>
                <div className="step-area">
                  <div className="inner-circle-1">
                    <img src={CheckMarkIcon} />
                  </div>
                  <div className="divider-1"></div>
                  <div className="outer-circle">
                    <div className="inner-circle">2</div>
                  </div>
                  <div className="divider-2"></div>
                  <div className="inner-circle">3</div>
                  <div className="divider-3"></div>
                  <div className="inner-circle">4</div>
                </div>

                <div className="future-area">
                  <div className="future-title">
                    <div className="future-usage">Would you use our product in future?</div>
                    <div className="select">*You may select one</div>
                  </div>

                  <div className="future-list">
                    <RadioGroup
                      aria-label="future"
                      name="future"
                      value={this.state.future}
                      onChange={this.futureChange}>
                      <FormControlLabel
                        value="def"
                        control={<Radio style={{ padding: '6px 12px' }} />}
                        label={this.styledLabel('Definitely')}
                      />
                      <FormControlLabel
                        value="prob"
                        control={<Radio style={{ padding: '6px 12px' }} />}
                        label={this.styledLabel('Probably')}
                      />
                      <FormControlLabel
                        value="notsure"
                        control={<Radio style={{ padding: '6px 12px' }} />}
                        label={this.styledLabel('Not sure')}
                      />
                      <FormControlLabel
                        value="notprob"
                        control={<Radio style={{ padding: '6px 12px' }} />}
                        label={this.styledLabel('Probably Not')}
                      />
                      <FormControlLabel
                        value="notdef"
                        control={<Radio style={{ padding: '6px 12px' }} />}
                        label={this.styledLabel('Definitely Not')}
                      />
                    </RadioGroup>
                  </div>

                  <div className="future-title">
                    <div className="online-service">
                      Have you used an online service for your dog food before?
                    </div>
                  </div>
                  <div className="future-list">
                    <RadioGroup
                      aria-label="online"
                      name="online"
                      value={this.state.online}
                      onChange={this.onlineChange}>
                      <FormControlLabel
                        value="yes"
                        control={<Radio style={{ padding: '6px 12px' }} />}
                        label={this.styledLabel('Yes')}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio style={{ padding: '6px 12px' }} />}
                        label={this.styledLabel('No')}
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button className="cancel-btn" onClick={this.props.cancelStep}>
                Cancel
              </button>
              <button className="next-btn" onClick={this.nextStep}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questionnaire: state.data.orders.questionnaire,
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestionnaire: (payload) =>
    dispatch(ordersActionCreators.updateQuestionnaire(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Future));
