import React from 'react';

export default class ImageBanner extends React.Component {
  render() {
    const { heading, subtext } = this.props;

    return (
      <div className="image-banner grey">
        <div className="background-image">
          <p className="heading">{heading}</p>
          <p className="subtext">{subtext}</p>
        </div>
      </div>
    );
  }
}
