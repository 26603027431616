import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RadioGroup, Radio } from 'react-radio-group';

import * as session from '../services/session';

import * as sessionSelectors from '../services/session/selectors';
import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import SubNavigation from '../components/SubNavigation';
import SimplyPowesome from '../components/SimplyPowesome';
import GreyText from '../components/GreyText';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';
import GoogleMap from '../components/GoogleMap';
import { validateMandatory } from '../utils';
import FormErrors from '../components/FormErrors';

const hears = [
  [
    {
      image: '/assets/images/support-phone-icon.png',
      subj: '0800 888 6554',
      desc: 'Monday to Saturday 9am to 5pm',
    },
    {
      image: '/assets/images/message-us-icon.png',
      subj: 'Message Us',
      desc: 'Use our web form to send us a note',
      webform: true,
    },
    {
      image: '/assets/images/community-icon.png',
      subj: 'Frequently Asked Questions',
      desc: 'Find answers right now',
      source: '/faqs',
    },
  ],
  [
    {
      image: '/assets/images/facebook-icon.png',
      subj: '@kasperandkitty',
      desc: 'Visit our facebook account',
      source: '/facebook',
    },
    {
      image: '/assets/images/twitter-icon.png',
      subj: '@kasperandkitty',
      desc: 'Visit our twitter account',
      source: '/twitter',
    },
    {
      image: '/assets/images/instagram-icon.png',
      subj: '@kasperandkitty',
      desc: 'Visit our instagram account',
      source: '/instagram',
    },
  ],
];

class Whistle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
      contactme: 'phone',
      lat: 52.919972,
      lon: -1.499181,
      mouseOverClass: 'col call-card',
      name: "",
      email: "",
      phone: "",
      customerID: 0,
      messageInfo: "",
      errors: [],
      errorType: 'error',
      emailSent: false,
    };

    this.attemptContactEmail = this.attemptContactEmail.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.contactmeChanged = this.contactmeChanged.bind(this);
    this.handleMessageSubmit = this.handleMessageSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
    if(this.props.user != null){
      this.state.name = this.props.user.first_name + " " + this.props.user.surname;
      this.state.email = this.props.user.email;
      this.state.phone = this.props.user.mobile_number;
      this.state.customerID = this.props.user.ID;
    }
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  contactmeChanged(value) {
    this.setState({
      contactme: value,
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  attemptContactEmail(event) {
    event.preventDefault();
    
    const {
      name,
      email,
      phone,
      messageInfo,
      contactme,
      customerID,
    } = this.state;

    const userMessage = {
      name,
      email,
      phone,
      messageInfo,
      contactme,
      customerID,
    };

    console.log(userMessage)
    session.contactEmail(userMessage)
      .then(() => {
        console.log('Success');
        this.setState({ emailSent: true });
      })
      .catch(() => console.log('Error occurred'));
  }

  handleMessageSubmit(e) {
    const mandatoryFields = [
      'name',
      'email',
    ];

    const errors = validateMandatory(this.state, mandatoryFields);

    if (errors.length > 0) {
      this.setState({ errors });
      e.preventDefault();
      return;
    } else {
      //Add message handler
      //If succeed in sending message
      const errors = ["Thanks for your message, we'll be in contact with you shortly."];
      e.preventDefault();
      this.setState({ errors, errorType: 'success' });

      setTimeout( ()  => {
        this.contactForm.submit();
      }, 3000);
    }
  }

  render() {
    const { name, email, phone, messageInfo, errors, errorType } = this.state;
    return (
      <div className="container">
        <Navigation />
        {/* <CommonImageBanner image="url(/assets/images/whistle-banner.jpg)" heading="Support" subtext="get in touch" /> */}
        <SubNavigation />
        <div className="kk-hear-container">
          <p className="heading">Kasper &amp; Kitty would love to hear from you and your best friend</p>
          {hears.map((row, rindex) => (<div key={rindex} className="row">
            {row.map((col, cindex) => (<div key={cindex} className={this.state.mouseOverClass}
            onClick={() => {
              if(col.source !== undefined){
                window.scrollTo(0, 0);
                this.props.history.push(col.source);
              } else if (col.webform != undefined){
                window.scrollTo(0, 1450);
              }}}
              onMouseOver={() => {
                if(col.source !== undefined || col.webform !== undefined){
                  this.setState({mouseOverClass:'col call-card active-cursor'});
                } else {
                  this.setState({mouseOverClass:'col call-card'});
                }
              }}>
              <div className="image-container">
               <img src={col.image}/>
                {col.image2 !== undefined && (
                  <img src={col.image2}/>
                )}
              </div>
              <p className="subj">{col.subj}</p>
              <p className="desc">{col.desc}</p>
            </div>))}
          </div>))}
        </div>
        <div className="row contact-container">
          <div className="col">
            <form onSubmit={this.handleMessageSubmit} ref={ref => this.contactForm = ref}>
              <p className="heading">Message us</p>
              <div className="row row-input-group mb-2">
                <div className="col-sm-3"><strong>Name*</strong></div>
                <div className="col-sm-8">
                  <input type="text" className="form-control" placeholder="name" name="name" value={name} onChange={this.handleChange}/>
                </div>
                <div className="col-sm-1" />
              </div>
              <div className="row row-input-group mb-2">
                <div className="col-sm-3"><strong>Email*</strong></div>
                <div className="col-sm-8">
                  <input type="text" className="form-control" placeholder="email" name="email" value={email} onChange={this.handleChange}/>
                </div>
                <div className="col-sm-1" />
              </div>
              <div className="row row-input-group mb-3">
                <div className="col-sm-3"><strong>Phone Number</strong></div>
                <div className="col-sm-8">
                  <input type="text" className="form-control" placeholder="phone number"  name="phone" value={phone} onChange={this.handleChange}/>
                </div>
                <div className="col-sm-1" />
              </div>
              <div className="row row-input-group mb-5">
                <div className="col-sm-3"><strong>Contact me</strong></div>
                <div className="col-sm-8">
                  <RadioGroup name="contactme" selectedValue={this.state.contactme} onChange={this.contactmeChanged}>
                    <div className="row">
                      <div className="col">
                        <Radio className="radio-button" value="phone" />&nbsp;By phone
                      </div>
                      <div className="col">
                        <Radio className="radio-button" value="email" />&nbsp;By email
                      </div>
                      <div className="col notice">We'll use this method to contact you in the future</div>
                    </div>
                  </RadioGroup>
                </div>
                <div className="col-sm-1" />
              </div>
              <div className="row">
                <div className="col-12 mb-1"><strong>Comment/Question</strong></div>
              </div>
              <div className="row comment-block mb-4">
                <div className="col-sm-11">
                  <textarea rows="10" placeholder="Please type here your comment..." name="messageInfo" value={messageInfo} onChange={this.handleChange}/>
                </div>
                <div className="col-sm-1" />
              </div>
              <FormErrors errors={errors} type={errorType} style={{ width: '92%' }} />
              {this.state.emailSent !== true ? (
                <button type="submit" className="btn btn-orange send-width" onClick={this.attemptContactEmail}>Send message </button>
              ) : (
                <GreyText
                      text="Your message has been sent!"
                      fontSize={18}
                      marginBottom
                    />
              )}
            </form>
          </div>
          <div className="col">
            <p className="heading">Where we are</p>
            <div className="row text-center mb-4">
              <div className="col-sm-6">
                <p className="mb-1"><strong>Address</strong></p>
                <p className="mb-1">Kasper &amp; Kitty Ltd.</p>
                <p className="mb-1">Unit 4, Empire Business Park</p>
                <p className="mb-1">Parcel Terrace</p>
                <p className="mb-1">Derby</p>
                <p className="mb-1">DE1 1LY</p>
                <p className="mb-1">United Kingdom</p>
              </div>
              <div className="col-sm-6">
                <p className="mb-1"><strong>Email</strong></p>
                <p className="mb-1">friends@kasperandkitty.co.uk</p>
                <p className="mb-1">&nbsp;</p>
                <p className="mb-1"><strong>Opening Hours</strong></p>
                <p className="mb-1">Monday to Friday</p>
                <p className="mb-1">9am to 5pm</p>
              </div>
            </div>
            <div className="row map">
              <GoogleMap lon={this.state.lon} lat={this.state.lat} />
            </div>
          </div>
        </div>
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}

export default connect(() => ({
  user: sessionSelectors.getUser(),
}), dispatch => ({
  actions: {
    user: bindActionCreators(sessionSelectors, dispatch),
  },
}))(Whistle);
