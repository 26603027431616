import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as dogsSelectors from '../../data/dogs/selectors';
import * as ordersActionCreators from '../../data/orders/actions';

import CloseIcon from '../../assets/images/wizard/review-close.png';
import CheckMarkIcon from '../../assets/images/checked-white-icon.png';

class Feedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mark: 0,
      improveNote: '',
    };

    this.nextStep = this.nextStep.bind(this);
  }

  onClick = (e) => {
    this.setState({
      mark: parseInt(e.target.value),
    });
  };

  feedbackButtonList = () => {
    var buttonList = [];
    for (var i = 1; i <= 10; i++) {
      buttonList.push(
        <button
          className={
            this.state.mark === i ? 'feedback-btn-highlighted' : 'feedback-btn'
          }
          value={i}
          key={i}
          onClick={this.onClick}>
          {i}
        </button>,
      );
    }

    return buttonList;
  };

  nextStep() {
    this.props.updateQuestionnaire({
      improve_note: this.state.improveNote,
      feedback_mark: this.state.mark,
    });

    this.props.nextStep();
  }

  render() {
    const dogName = dogsSelectors.getCurrentDog().name;

    return (
      <div
        className="modal animated fadeIn feedback-modal"
        id="feedback"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered row justify-content-center"
          role="document">
          <div className="modal-content">
            <div className="modal-header py-0 px-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Cancel {dogName}&apos;s subscription
              </h5>
              <button
                type="button"
                className="close text-right"
                onClick={this.props.cancelStep}
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">
                  <img src={CloseIcon} alt="" />
                </span>
              </button>
            </div>

            <div className="modal-body px-0 pb-0">
              <div className="step-area">
                <div className="inner-circle-1">
                  <img src={CheckMarkIcon} />
                </div>
                <div className="divider-1"></div>
                <div className="inner-circle-1">
                  <img src={CheckMarkIcon} />
                </div>
                <div className="divider-2"></div>
                <div className="outer-circle">
                  <div className="inner-circle">3</div>
                </div>
                <div className="divider-3"></div>
                <div className="inner-circle">4</div>
              </div>

              <div className="feedback-area">
                <div className="feedback-title">
                  What do you think we can improve on?
                </div>
                <div className="feedback-content">
                  <textarea
                    cols="50"
                    value={this.state.improveNote}
                    onChange={(e) =>
                      this.setState({
                        improveNote: e.target.value,
                      })
                    }
                    placeholder="Type your reason here..."
                  />
                </div>
                <div className="feedback-title-1">
                  How likely are you to recommend Kasper and Kitty to a friend
                  or colleague?
                </div>
                <div className="feedback-mark">
                  <div className="feedback-label">Not at all</div>
                  {this.feedbackButtonList()}
                  <div className="feedback-label">100%</div>
                </div>
                <div className="feedback-percent">
                  <div>Not at all</div>
                  <div>100%</div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button className="cancel-btn" onClick={this.props.cancelStep}>
                Cancel
              </button>
              <button className="next-btn" onClick={this.nextStep}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questionnaire: state.data.orders.questionnaire,
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestionnaire: (payload) =>
    dispatch(ordersActionCreators.updateQuestionnaire(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Feedback),
);
