import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actionTypes from '../../data/exports';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as selectors from '../../data/dogs/selectors';
import WizardLayout from '../../components/wizard/WizardLayout';
import HealthOption from '../../components/wizard/HealthOption';
import Button from '../../components/Button';
import PopUpIcon from '../../assets/images/wizard/health-option-icon.png';
import Tip from '../../components/wizard/Tip';
import Skin from '../../assets/images/wizard/tips/skin.svg';
import Joints from '../../assets/images/wizard/tips/joints.svg';
import Pancreatic from '../../assets/images/wizard/tips/pancreatic.svg';
import Digestion from '../../assets/images/wizard/tips/digestion.svg';
import Diet from '../../assets/images/wizard/tips/diet.svg';
import * as session from '../../services/session';


class Health extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            issues: [
                {
                    ID: 3,
                    value: 'skin',
                    label: 'Skin & Coat',
                    selected: false,
                },
                {
                    ID: 1,
                    value: 'joints',
                    label: 'Joints & Movement',
                    selected: false,
                },
                {
                    ID: 4,
                    value: 'pancreatitis',
                    label: 'Pancreatitis',
                    selected: false,
                },
                {
                    ID: 2,
                    value: 'digestion',
                    label: 'Digestion',
                    selected: false,
                },
            ],
            isError: false,
        };

        this.handleStep = (step, e) => {
            e.preventDefault();

            // const selectedConditions = this.props.currentDog.health_issues.some(item => item.selected);
            //
            // if (!selectedConditions){
            //     this.setState({isError: true})
            // }else{
                this.props.history.push(step);
            // }
        };

        this.handleBackStep = (step, e) => {
            e.preventDefault();
            this.props.history.push(step);
        };

        this.setErrorToDog = () => {
            session.updateUser({
                prescription_dog: true,
            }).then(() => {
                const dogIndex = this.props.getIndex;
                this.props.updateCurrentDog('error', dogIndex, 'health-details');
                this.props.history.push('/add-dog');
            })
            .catch(() => {
                alert("Unable to save, please try again!");
            });            
        }

        this.toggleOption = (issue) => {
            const dogIndex = this.props.getIndex;
            const issuesList = this.props.currentDog.health_issues.map(item => ({
                        ...item,
                        selected: item.value === issue.value ? !item.selected : item.selected,
                    }));
            this.props.updateCurrentDog('health_issues', dogIndex, issuesList);
            this.setState({isError: false});
        };

        this.handleModal = (modal) => {
            $(`#${modal}`).modal('show');
        };

    }

    componentDidMount() {
        const dogIndex = this.props.getIndex;
        const {pathname} = this.props.location;

        if(this.props.currentDog.health_issues.length === 0){
            this.props.updateCurrentDog('progress', dogIndex, 75);
            this.props.updateCurrentDog('health_issues', dogIndex, this.state.issues);
        }

        this.props.updateCurrentDog('step', dogIndex, pathname);
        this.props.toggleDogNav(true);
    }

    render() {
        const issues = this.props.currentDog.health_issues.map(issue => (
            <div className="col-6 row justify-content-center health-option-wrapper px-xl-0 px-lg-0 px-md-0 px-sm-1 px-1 my-1" key={issue.value}>
                <HealthOption
                    label={issue.label}
                    value={issue.value}
                    active={issue.selected}
                    toggle={() => this.toggleOption(issue)}
                />
                <span className="pop-up-link" onClick={() => this.handleModal(issue.value)}>
                    <a><img src={PopUpIcon} alt="" /></a>
                </span>
            </div>
        ));
        return (
            <WizardLayout>
                <div className="col-12 row justify-content-center px-xl-3 px-lg-3 px-md-3 px-sm-2 px-1">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 row justify-content-center weight-from px-0">
                        <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                            Does {this.props.currentDog.name} have any health issues?
                        </h5>
                        <div style={{maxWidth: '480px'}} className="col-xl-12 col-lg-12 col-12 row justify-content-center justify-content-xl-center justify-content-lg-center justify-content-around px-xl-3 px-lg-3 px-md-3 px-sm-2 px-0">
                            {issues}
                        </div>
                        <a className={"col-12 text-center underline pop-up-link pt-4 pb-2"} data-toggle="modal" href={"#prescription-diet"}>Looking for prescription diet?</a>

                        {this.state.isError
                            ?
                            <div className="animated fadeIn col-12 pt-3 text-center error-message">
                                Please select at least one option.
                            </div>
                            :
                            ''
                        }

                        <div className="col-6 pl-xl-4 pl-lg-3 pl-md-4 pl-sm-5 pr-1 pt-4 text-right">
                            <Button
                                onClick={e => this.handleBackStep('/activity', e)}
                                classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                                label="BACK"
                            />
                        </div>
                        <div className="col-6 pr-xl-4 pr-lg-3 pr-md-4 pl-1 pr-sm-5 pt-4 text-left">
                            <Button
                                onClick={e => this.handleStep('/allergy', e)}
                                classes="button-primary fill orange animated fadeInRight delay-_2s"
                                label="NEXT"
                            />
                        </div>

                    </div>
                </div>
                <Tip
                    title={"Skin & Coat"}
                    description={`The condition of your dog’s coat is indicative of its overall health.
It should be strong, shiny and smooth. If your dog is not getting enough nutrients to maintain this, we can help supplement his/her food with omega-6 and omega-3 fatty acids and other important vitamins. `}
                    target="skin"
                    isImage={true}
                    image={Skin}
                    isButton={false}
                    btnLabel=""
                />
                <Tip
                    title={"Pancreatitis"}
                    description={`Common symptoms include loss of appetite, vomiting and belly pain for more than a day or frequently. Please consult your vet before treating this condition yourself. K&K has created a unique blend low in fat with high quality mono protein to help your dog's condition. `}
                    target="pancreatitis"
                    isImage={true}
                    image={Pancreatic}
                    isButton={false}
                    btnLabel=""
                />
                <Tip
                    title={"Joints & Movement"}
                    description={`Maintaining healthy bones and joints requires proper nutrition. Common signs of joint problems include stiffness and limping. By letting us know, we can help adapt his/her nutrition to include supplements like glucosamine to preserve cartilage keeping your dog mobile and active even in the later years. `}
                    target="joints"
                    isImage={true}
                    image={Joints}
                    isButton={false}
                    btnLabel=""
                />
                <Tip
                    title={"Digestion"}
                    description={`Checking your dog’s stool might be a private matter but it’s the best indicator to finding out what’s going on inside his/her digestion. A healthy stool is firm and moist, and has a mild odour. So look out for frequency, consistency, size, color, or smell that’s out of the ordinary. Among other things, our blend is perfect for digestive issue by increasing the level of fiber.`}
                    target="digestion"
                    isImage={true}
                    image={Digestion}
                    isButton={false}
                    btnLabel=""
                />

                <Tip
                    title={"We're sorry, we don't offer prescription diets yet."}
                    description={`Let us know if you want us to update when we are ready to launch our prescription food.`}
                    target="prescription-diet"
                    isImage={true}
                    image={Diet}
                    isButton={true}
                    btnFn={() => this.setErrorToDog()}
                    btnLabel="UPDATE ME"
                />
            </WizardLayout>
        );
    }
}

const mapStateToProps = state => ({
    storeDogs: state.data.dogs.storeDogs,
    currentDog: state.data.dogs.currentDog,
    getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
    updateCurrentDog: (key, index, value) =>
        dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
    toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Health));
