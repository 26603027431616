import React from 'react';

export const FITKOLAR_PRICE_PRE_VAT = 33.33;
export const MAINTENANCE_COST = 9.20;

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const validateMandatory = (data, requiredFields) => {
  const errors = [];

  for (const field of requiredFields) {
    const value = data[field];

    if (value <= -1 || value.toString().length === 0) {
      errors.push(`${field} cannot be blank or negative`);
    }

    if (field === 'age' && value > 15 * 12) {
      console.log('value', value, field);

      errors.push(`${field} cannot be bigger than 15 years. If you want to input more than 15 years, please use DOB.`);
    }
  }

  return errors;
};

// discount, discountMode, discountFitkolarOnly
export const getCheckoutTotal = (personalisedShop, fitkolars, deliveryDelay, promo, deliveryCost) => {
  const deliveryCutoff = 11; // orders made after 11 are delivered in 3, not 2, days

  let total = personalisedShop.reduce(
    (t, item) => t + parseFloat(item.price * item.quantity),
    0,
  ); // items
  total += fitkolars * FITKOLAR_PRICE_PRE_VAT * 1.2;

  let newDeliveryDelay = deliveryDelay; // deliveryFree ? 2 : deliveryDelay; I'm fairly sure this is not needed anymore

  let firstDelivery = new Date();
  if ((new Date()).getHours() > deliveryCutoff) {
    newDeliveryDelay += 1;
  }

  firstDelivery.setDate(firstDelivery.getDate() + newDeliveryDelay);
  firstDelivery = `Your Delivery will arrive by ${firstDelivery.toLocaleDateString('en-GB', {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
  })}`;

  let discountableTotal = 0;

  if (promo) {
    if (promo.fitkolar_discount > 0) {
      discountableTotal += fitkolars * FITKOLAR_PRICE_PRE_VAT * 1.2 * promo.fitkolar_discount / 100;
    }
    if (promo.kibble_discount) {
      const [bespoke] = personalisedShop.filter(p => p.isBespoke);
      if (bespoke) {
        discountableTotal += bespoke.price * bespoke.quantity * promo.kibble_discount / 100;
      }
    }
    // TODO should something happen here if promo.isTrial is true?
  }

  total -= discountableTotal;
  total = Math.round(total * 100, 10) / 100;
  const totalDelivery = total + deliveryCost;

  return {
    total, totalDelivery, deliveryCost, firstDelivery,
  };
};

export const getAgeFromDOB = (dob) => {
  const today = new Date();
  const thatDay = new Date(dob);
  let months = (today.getFullYear() - thatDay.getFullYear()) * 12;
  months += today.getMonth() - thatDay.getMonth();

  if (today.getDate() < thatDay.getDate()) {
    months--;
  }

  if (months <= 0) months = 1;

  return months;
};

export const round = (value) => {
  return Math.round(value*100)/100;
}

export const applyPromotionToKibble = (price, promo) => {
  if(promo && promo.kibble_discount > 0){
    return price - (price / 100 * promo.kibble_discount);
  }
  return price;
}

export const applyPromotionToFitkolar = (price, promo) => {
  if(promo && promo.fitkolar_discount > 0){
    return price - (price / 100 * promo.fitkolar_discount);
  }
  return price;
}

export const dateCommonFormat = (date) => {
  var ret = new Date(date);
  var year = ret.getFullYear();
  var month = ret.getMonth() + 1;
  var day = ret.getUTCDate();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;

  return day + "-" + month + "-" + year;
}

export const dateFormat = (date) => {
  var ret = new Date(date);
  var year = ret.getFullYear();
  var month = ret.getMonth();
  var day = ret.getUTCDate();

  var suffix = "th"
  switch (day) {
    case 1:
    case 21:
    case 31:
      suffix = "st";
      break;
    case 2:
    case 22:
      suffix = "nd";
      break;
    case 3:
    case 23:
      suffix = "rd";
      break;
    default:
      suffix = "th";
  }

  if (month < 10) month = months[month];

  return day + suffix + " " + month + " " + year;
}

export const getOriginalAndDiscountedTotal = (order, user) => {
  if (!order || !user) {
    return null;
  }
  const { total_discount } = user;
  const { total, release } = order;
  if (!total_discount || total_discount === 0 || release) {
    return `£${total.toFixed(2)}`;
  }
  const newTotal = ((100 - total_discount)/100) * total;
  return <span><s>£{total.toFixed(2)}</s> £{newTotal.toFixed(2)}</span>
}