import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import InputField from '../../components/InputField';
import Row from '../../components/Row';
import FullColumn from '../../components/FullColumn';
import HalfColumn from '../../components/HalfColumn';
import FormErrors from '../../components/FormErrors';
import * as ordersActionCreators from '../../data/orders/actions';
import * as productsSelectors from '../../data/products/selectors';
import * as dogsSelectors from '../../data/dogs/selectors';
import * as productsActions from '../../data/products/actions';
import * as sessionSelectors from '../../services/session/selectors';
import * as utils from '../../utils';

const TableRow = ({
  product, quantity, price, heading,
}) => (
  <Row>
    <div className="col-6 col-sm-8 no-padding">
      <p className={`${heading ? ' heading ' : ''}table-row product`}>{product}</p>
    </div>
    <div className="col-3 col-sm-2 no-padding">
      <p className={`${heading ? ' heading ' : ''}table-row quantity`}>{quantity}</p>
    </div>
    <div className="col-3 col-sm-2 no-padding">
      <p className={`${heading ? ' heading ' : ''}table-row price`}>{price}</p>
    </div>
  </Row>
);

const SummaryRow = ({ label, price }) => (
  <Row>
    <div className="col no-padding">
      <p className="summary-text">{label}</p>
    </div>
    <div className="col-3 col-sm-2 no-padding">
      <p className="summary-price">{price}</p>
    </div>
  </Row>
);

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      delivery: true,
      promoCode: '',
      promocodeApplied: false,
      promocodeAppliedValue: '',
      promocodeInvalid: false,
      deliveryInstructions: '',
      personalisedShop: [],
      fitkolar: 0,
      promo: false,
      redirectToNextStep: false,
      errors: [],
      submitting: false,
    };

    this.handleOrder = this.handleOrder.bind(this);
    this.checkPromo = this.checkPromo.bind(this);
  }

  componentDidMount() {
    if (this.props.stepsState.personalisedShop) {
      productsActions.getBespoke({ id: this.props.dog.ID })
        .then((bespokeProd) => {
          this.props.stepsState.personalisedShop.push({
            key: bespokeProd.ID,
            product: bespokeProd.name,
            quantity: 1,
            price: bespokeProd.price,
            isBespoke: true,
          });

          let items = this.props.stepsState.personalisedShop;
          // if the dog has pancreatitis and is allergic to chicken, remove bespoke food from order items
          let isPanAndChickenAllergen = false;
          if (this.props.dog.medical_conditions !== null) {
            isPanAndChickenAllergen =
              this.props.dog.medical_conditions.map(c => c.name).includes('Pancreatitis') &&
              this.props.dog.allergens.map(a => a.name).includes('Chicken');
          }
          if (isPanAndChickenAllergen) {
            items = this.props.stepsState.personalisedShop.filter(item => item.product != 'Bespoke Dry Food Blend');
          } else {
            items = this.props.stepsState.personalisedShop.map((item) => {
              if (item.product === 'Bespoke Dry Food Blend') {
                console.log('item ', item);
                item.price += (utils.MAINTENANCE_COST * 1.20); // Post VAT maintenance cost
              }

              return item;
            });
          }
          this.setState({
            personalisedShop: items,
            fitkolar: this.props.stepsState.fitkolar,
            promoCode: 'JUNESPECIAL20',
            deliveryInstructions: this.props.stepsState.deliveryInstructions,
          });
          this.checkPromo();
        });
    }
  }

  onNext() {
    if (this.state.errors.length > 0) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.props.onUpdateState({
        promoCode: this.state.promoCode,
        deliveryInstructions: this.state.deliveryInstructions,
        orderID: this.state.order_id,
      });
      resolve();
    });
  }

  handleOrder(e) {
    e.preventDefault();

    const { submitting } = this.state;
    if (submitting) return;

    for (let i = 0; i < this.state.personalisedShop.length; i += 1) {
      this.state.personalisedShop[i].quantity = parseInt(this.state.personalisedShop[i].quantity, 10);
    }

    const payload = {
      dog_id: this.props.dog.ID,
      delivery_address: {
        address1: this.props.stepsState.aboutYoudeliveryAddress1,
        address2: this.props.stepsState.aboutYoudeliveryAddress2,
        town: this.props.stepsState.aboutYoudeliveryTownCity,
        county: this.props.stepsState.aboutYoudeliveryCounty,
        postcode: this.props.stepsState.aboutYoudeliveryPostcode,
        country: this.props.stepsState.aboutYoudeliveryCountry,
      },
      billing_address: {
        address1: this.props.stepsState.aboutYoubillingAddress1,
        address2: this.props.stepsState.aboutYoubillingAddress2,
        town: this.props.stepsState.aboutYoubillingTownCity,
        county: this.props.stepsState.aboutYoubillingCounty,
        postcode: this.props.stepsState.aboutYoubillingPostcode,
        country: this.props.stepsState.aboutYoubillingCountry,
      },
      order_items: this.state.personalisedShop,
      fitkolars: parseInt(this.state.fitkolar, 10),
      promotion_id: this.state.promoCode,
      delivery_method: this.state.delivery ? 'Free' : 'Standard',
      delivery_instructions: this.state.deliveryInstructions,
      delivery_cost: 2.00,
      maintenance_cost: utils.MAINTENANCE_COST,
    };

    this.setState({ submitting: true });

    this.props.actions.orders.createOrder(payload)
      .then((order) => {
        this.props.onUpdateState({
          personalisedShop: [],
          fitkolar: 1,
        });

        if (order.errors !== undefined && order.errors.length > 0) {
          this.setState({ errors: order.errors, submitting: false });
        } else {
          this.setState({
            order_id: order,
            redirectToNextStep: true,
          });
        }
      })
      .catch((err) => {
        window.alert('An error occured while placing your order.');
        this.setState({ submitting: false });
      });
  }

  checkPromo() {
    this.setState({
      promocodeInvalid: false, discount: 0, discountMode: '', promocodeAppliedTrial: false,
    });

    if (this.state.promoCode && this.state.promoCode !== '') {
      this.props.actions.orders.getPromocode(this.state.promoCode)
        .then((promo) => {
          if (promo.ID > 0) {
            if (promo.fitkolarOnly && this.state.fitkolar === 0) {
              this.setState({
                promocodeInvalid: true,
                promocodeApplied: false,
                promocodeAppliedValue: 'Sorry! This code can only be used with fitkolar purchases.',
              });
            } else {
              this.setState({
                promocodeInvalid: false,
                promocodeApplied: true,
                promocodeAppliedValue: `${(promo.mode === 'percent') ? `${promo.discount}%` : `${promo.discount}`} off!`,
                promo,
              });
            }
          } else {
            this.setState({ promocodeApplied: false, promocodeInvalid: true, promo: false });
          }
        });
    } else {
      this.setState({ promocodeInvalid: true });
    }
  }

  render() {
    const {
      total,
      totalDelivery,
      deliveryCost,
      firstDelivery,
    } = utils.getCheckoutTotal(
      this.state.personalisedShop,
      this.state.fitkolar,
      2,
      this.state.promo,
      2.00, // as of now: delivery at registration stage can only be 2£
    );

    if (this.state.redirectToNextStep) {
      this.props.forceStep(7);
      this.setState({ redirectToNextStep: false });
      return <Redirect to="/welcome/about-you" />;
    }

    const { submitting } = this.state;

    let fitkolarTotal = utils.applyPromotionToFitkolar(utils.FITKOLAR_PRICE_PRE_VAT, this.state.promo);
    fitkolarTotal = (fitkolarTotal * 1.20 * this.state.fitkolar);
    fitkolarTotal = Math.floor(fitkolarTotal * 100) / 100;
    return (
      <div>
        <OrangeTextHeading
          text={`${this.props.dog.name}'s Order`}
          fontSize={30}
          centered
          marginBottom
        />
        <FormErrors errors={this.state.errors} />
        <Row>
          <FullColumn>
            <TableRow product="Product name" quantity="Qty" price="Price" heading />
            {this.state.personalisedShop.map(item => (
              <TableRow
                product={item.product}
                quantity={item.quantity}
                price={`£${this.calculateItemPrice(item)}`}
              />
            ))}
            {this.state.fitkolar > 0 &&
              <TableRow
                product="Fitkolar"
                quantity={this.state.fitkolar}
                price={`£${fitkolarTotal.toFixed(2)}`}
              />
            }
            <SummaryRow label="Sub total" price={`£${total.toFixed(2)}`} />
            <SummaryRow label="Delivery" price={`£${deliveryCost.toFixed(2)}`} />
            <SummaryRow label="Total to pay" price={`£${totalDelivery.toFixed(2)}`} />
            <div className="row">
              <HalfColumn noPadding>
                <GreyText
                  text="Enter your promotional code"
                  fontSize={20}
                  bold
                  marginBottom
                />
                <Row>
                  <div className="col no-padding">
                    <InputField
                      type="text"
                      placeholder="Promo code"
                      value={this.state.promoCode}
                      onChangeText={event => this.setState({ promoCode: event.target.value })}
                    />
                  </div>
                  <a
                    className="apply-button"
                    onClick={this.checkPromo}
                  >
                    Apply
                  </a>
                </Row>
                {this.state.promocodeAppliedTrial && <GreyText bold text="Code applied, 2 weeks of bespoke food free of charge!" fontSize={16} />}
                {!this.state.promocodeAppliedTrial && this.state.promocodeApplied && <GreyText bold text={`Code applied, ${this.state.promocodeAppliedValue}`} fontSize={16} />}
                {this.state.promocodeInvalid && <GreyText bold text="This promocode is not valid" fontSize={16} />}
                {/* <GreyText */}
                {/* text="APRILKK50 - 50% off Fitkolar &amp; First Delivery of Food" */}
                {/* fontSize={20} */}
                {/* marginBottom */}
                {/* /> */}
              </HalfColumn>
            </div>
            <Row>
              <div className="col-12 col-md-6 align-self-center no-padding">
                <GreyText
                  text="First monthly delivery"
                  fontSize={20}
                  bold
                  marginBottom
                />
                <GreyText
                  text={firstDelivery}
                  fontSize={20}
                  marginBottom
                />
                <GreyText
                  text="Delivery options"
                  fontSize={20}
                  bold
                  marginBottom
                />
                <div className="delivery-radio-container">
                  <a
                    className="selector-container"
                    onClick={() => this.setState({ deliveryCost: !this.state.delivery })}
                  >
                    <div className="radio">
                      <div className={`${this.state.delivery ? ' active ' : ''}fill`} />
                    </div>
                    <p className="grey-text">Free - 48hr standard delivery (UK mainland)</p>
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-6 align-self-center no-padding">
                <img
                  className="checkout-van"
                  src="/assets/images/checkout-van.png"
                />
              </div>
            </Row>
            <Row>
              <div className="col-12 col-md-6 no-padding">
                <GreyText
                  text="Delivery instructions"
                  fontSize={20}
                  bold
                  marginBottom
                />
                <textarea
                  className="text-area"
                  value={this.state.deliveryInstructions}
                  onChange={event => this.setState({ deliveryInstructions: event.target.value })}
                />
              </div>
              <div className="col-12 col-md-6 no-padding">
                <div className="place-order-container">
                  <a
                    className="place-order-button"
                    onClick={this.handleOrder}
                    disabled={submitting}
                  >
                    Place Order
                  </a>
                </div>
              </div>
            </Row>
          </FullColumn>
        </Row>
        <Row>
          <FullColumn>
            <p className="grey-text center">By placing your order you agree to our <Link to="/terms-and-conditions" className="underline" target="_blank">Terms and Conditions</Link>, <Link to="/privacy-policy" className="underline" target="_blank">Privacy Policy</Link> and <Link to="/delivery-and-return" className="underline" target="_blank">Returns Policies</Link></p>
          </FullColumn>
        </Row>
      </div>
    );
  }

  calculateItemPrice(item) {
    let basePrice = item.price * item.quantity;
    if (item.isBespoke && this.state.promocodeAppliedTrial) {
      basePrice -= (basePrice * this.state.promo.kibble_discount / 100);
    }
    return basePrice.toFixed(2);
  }
}

export default connect(() => ({
  dog: dogsSelectors.getCurrentDog(),
  products: productsSelectors.getProducts(),
  user: sessionSelectors.getUser(),
}), dispatch => ({
  actions: {
    orders: bindActionCreators(ordersActionCreators, dispatch),
  },
}), null, { withRef: true })(Checkout);
