import React from 'react';
import { Link } from 'react-router-dom';

import SubNavigation from '../../components/SubNavigation';
import Row from '../../components/Row';
import FullColumn from '../../components/FullColumn';
import BackgroundContainer from '../../components/BackgroundContainer';
import Navigation from '../../components/Navigation';

import MainImageBanner from '../../components/MainImageBanner';
import InstagramCarousel from '../../components/InstagramCarousel';
import Pdsa from '../../components/Pdsa';
import Footer from '../../components/Footer';
import AccountSidebar from '../../components/AccountSidebar';
import AccountDetails from '../../components/AccountDetails';


import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';


export default class BillingDetails extends React.Component {

  render() {

    const information = [
      {db: 'address1',
      descr: "Address line 1",
      value: ""},
      {db: 'address2',
      descr: "Address line 2",
      value: ""},
      {db: 'town',
      descr: "Town",
      value: ""},
      {db: 'county',
      descr: "County",
      value: ""},
      {db: 'country',
      descr: "Country",
      value: ""},
      {db: 'postcode',
      descr: "Postcode",
      value: ""},
    ];

    const title = "BILLING DETAILS";

    return (
      <div className="container">
        <Navigation
          // absolute
          white
          textColor="white-text"
        />
        {/* <MainImageBanner
          image="url(/assets/images/your-account.jpg)"
          heading="Your Account"
          subtext="Billing address"
        /> */}
        <SubNavigation />

        <div className="account-container">
        <KaushanScriptHeading title={'Your account'} />

        <Row>
          <div className="col-md-4">
            <AccountSidebar />
          </div>
          <div className="col-md-7">
            <AccountDetails data={information} title={title} page={3}/>
          </div>
          <div className="col-md-1"/>
        </Row>

        </div>

        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
