import React from 'react';
import { Link } from 'react-router-dom';

import Day from '../components/Day';
import Row from '../components/Row';
import Dropdown from '../components/Dropdown';

const months = [
  {
    key: 0,
    month: 'JANUARY',
  },
  {
    key: 1,
    month: 'FEBRUARY',
  },
  {
    key: 2,
    month: 'MARCH',
  },
  {
    key: 3,
    month: 'APRIL',
  },
  {
    key: 4,
    month: 'MAY',
  },
  {
    key: 5,
    month: 'JUNE',
  },
  {
    key: 6,
    month: 'JULY',
  },
  {
    key: 7,
    month: 'AUGUST',
  },
  {
    key: 8,
    month: 'SEPTEMBER',
  },
  {
    key: 9,
    month: 'OCTOBER',
  },
  {
    key: 10,
    month: 'NOVEMBER',
  },
  {
    key: 11,
    month: 'DECEMBER',
  },
];

const days = [
  {
    key: 1,
    day: 'M',
  },
  {
    key: 2,
    day: 'T',
  },
  {
    key: 3,
    day: 'W',
  },
  {
    key: 4,
    day: 'T',
  },
  {
    key: 5,
    day: 'F',
  },
  {
    key: 6,
    day: 'S',
  },
  {
    key: 7,
    day: 'S',
  },
];

const getYears = () => {
  const years = [];

  const todaysDate = new Date();

  const thisYear = todaysDate.getFullYear();

  const fiveMore = parseInt(thisYear, 10) + 5;

  const twentyLess = parseInt(thisYear, 10) - 20;

  for (let i = twentyLess; i < fiveMore; i += 1) {
    years.push(i);
  }

  return years;
};

const years = getYears();

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);

    const newDate = new Date();
    const currentDate = newDate.getDate();
    const currentMonth = newDate.getMonth();
    const currentYear = newDate.getFullYear();
    this.state = {
      today: new Date(currentYear, currentMonth, currentDate), // Todays date (yyyy/mm/dd)
      days: undefined, // Number of days in the current selected month
      date: undefined, // Selected date of the month
      month: undefined, // Selected month
      year: undefined, // Selected year
      offset: undefined, // Number to offset the start of the calendar by
      windowWidth: undefined, // Gets the size of the window to make the calendar respond correctly
    };

    this.updateCalendar = this.updateCalendar.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Enable parent component to call this child component' methods.
    this.props.onRef(this);
    window.addEventListener('resize', this.updateWindowDimensions);

    // Prevents the initial date from being passed through to the parent
    if (!this.props.preventInitialSetState) {
      const newDate = new Date();
      // Passes the initial date to the parent on mount
      this.select({
        date: newDate.getDate(),
        month: newDate.getMonth(),
        year: newDate.getFullYear(),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  updateCalendar(month, year) {
    const numberOfDays = new Date(year, month + 1, 0).getDate(); // Number of days in the month

    let firstDayOfTheMonth = new Date(year, month, 1).getDay();

    // Sunday is classed as 0 in America
    if (firstDayOfTheMonth === 0) {
      firstDayOfTheMonth = 7;
    }

    const offset = firstDayOfTheMonth - 1;

    this.setState({
      days: numberOfDays,
      month,
      year,
      offset,
    });
  }

  // Passes the selected date to the onChange property to be used in the parent to set the state.
  select(date) {
    if (this.props.onDateSelect !== undefined) {
      const selectedDate = new Date(date.year, date.month, date.date);
      const maxDeliveryDate = new Date();
      maxDeliveryDate.setDate(maxDeliveryDate.getDate() + 60);

      if (selectedDate > maxDeliveryDate) {
        window.alert(
          'If you would like to move your next delivery date more than 60 days from the next delivery date, please contact customer services.',
        );
        return;
      }
      this.props.onDateSelect(date);
    }
  }

  // Returns the text for the current month
  renderMonthText(value) {
    if (this.state.windowWidth < 576) {
      return value.substr(0, 3);
    }

    return value;
  }

  render() {
    const {
      futureDateSelectedHandler,
      orderDelayOffset,
      popup,
      currentDate, // Used to set active class on the current date
      currentMonth, // Used to set the active class on the current date
      currentYear, // Used to set the active class on the current date
    } = this.props;

    const { month, year } = this.state;

    const dayList = []; // List of values to map through that will be displayed as a day of the month
    const offsetList = []; // List of values to map through to offset the start of the calander with empty "Day" components
    const completeList = []; // List of values to map through to complete the end of the calander with empty "Day" components

    for (let i = 1; i < this.state.days + 1; i += 1) {
      dayList.push(i);
    }

    for (let i = 0; i < this.state.offset; i += 1) {
      offsetList.push(i);
    }

    // Can either have 4 rows of 7, 5 rows of 7 or 6 rows of 7.
    // Checks which one is true to calculate how many blocks are needed to complete the calendar.
    if (dayList.length + offsetList.length === 28) {
      for (let i = 0; i < 28 - (dayList.length + offsetList.length); i += 1) {
        completeList.push(i);
      }
    } else if (dayList.length + offsetList.length > 28 && dayList.length + offsetList.length <= 35) {
      for (let i = 0; i < 35 - (dayList.length + offsetList.length); i += 1) {
        completeList.push(i);
      }
    } else {
      for (let i = 0; i < 42 - (dayList.length + offsetList.length); i += 1) {
        completeList.push(i);
      }
    }

    return (
      <div
        role="button"
        tabIndex={0}
        className={`${popup ? ' popup ' : ' calander-border '}calander-container`}
        onClick={(e) => {
          e.stopPropagation();
          e.cancelBubble = true;
        }}>
        {popup !== undefined && (
          <div className="dropdown-selectors">
            <div className="dropdown-selectors-container left">
              <Dropdown
                small
                activeOption={months[month || this.state.today.getMonth()].month}
                onChangeOption={(event) => {
                  const selection = months.map((item) => item.month).indexOf(event.value.trim());

                  this.updateCalendar(selection, this.state.year);
                }}
                options={months.map((item) => [item.month, ''])}
              />
            </div>
            <div className="dropdown-selectors-container right">
              <Dropdown
                small
                activeOption={year || currentYear || this.state.today.getFullYear()}
                onChangeOption={(event) => {
                  this.updateCalendar(this.state.month, event.value);
                }}
                options={years}
              />
            </div>
          </div>
        )}
        <div className="month-header">
          <Link
            href
            className="link"
            to="#"
            onClick={(e) => {
              e.preventDefault();

              if (this.state.month >= 1) {
                this.updateCalendar(this.state.month - 1, this.state.year);
              } else {
                this.updateCalendar(11, this.state.year - 1);
              }
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              this.interval = setInterval(() => {
                if (this.state.month >= 1) {
                  this.updateCalendar(this.state.month - 1, this.state.year);
                } else {
                  this.updateCalendar(11, this.state.year - 1);
                }
              }, 200);
            }}
            onMouseUp={(e) => {
              e.preventDefault();
              clearInterval(this.interval);
            }}
            onTouchStart={(e) => {
              e.preventDefault();
              this.interval = setInterval(() => {
                if (this.state.month >= 1) {
                  this.updateCalendar(this.state.month - 1, this.state.year);
                } else {
                  this.updateCalendar(11, this.state.year - 1);
                }
              }, 200);
            }}
            onTouchEnd={(e) => {
              e.preventDefault();
              clearInterval(this.interval);
            }}>
            <img alt="" className="calendar-arrow" src="/assets/images/left-arrow-orange.png" />
          </Link>
          {months.map((item) => (
            <p
              key={item.key}
              // style={[item.key === this.state.month ? styles.monthYear : styles.inactive]}
              className={item.key === this.state.month ? 'calander-heading-text' : ' inactive '}>
              {this.renderMonthText(item.month)} {this.state.year}
            </p>
          ))}
          <Link
            href
            className="link"
            to="#"
            onClick={(e) => {
              e.preventDefault();
              if (this.state.month <= 10) {
                this.updateCalendar(this.state.month + 1, this.state.year);
              } else {
                this.updateCalendar(0, this.state.year + 1);
              }
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              this.interval = setInterval(() => {
                if (this.state.month <= 10) {
                  this.updateCalendar(this.state.month + 1, this.state.year);
                } else {
                  this.updateCalendar(0, this.state.year + 1);
                }
              }, 200);
            }}
            onMouseUp={(e) => {
              e.preventDefault();
              clearInterval(this.interval);
            }}
            onTouchStart={(e) => {
              e.preventDefault();
              this.interval = setInterval(() => {
                if (this.state.month <= 10) {
                  this.updateCalendar(this.state.month + 1, this.state.year);
                } else {
                  this.updateCalendar(0, this.state.year + 1);
                }
              }, 200);
            }}
            onTouchEnd={(e) => {
              e.preventDefault();
              clearInterval(this.interval);
            }}>
            <img alt="" className="calendar-arrow" src="/assets/images/right-arrow-orange.png" />
          </Link>
        </div>
        <Row>
          {days.map((item) => (
            <div key={item.key} className="col no-padding">
              <p className="calendar-day-text">{item.day}</p>
            </div>
          ))}
        </Row>
        <div className="calander-days-container">
          {offsetList.map((item) => (
            <Day popup={popup} key={item} onSelect={(e) => e.preventDefault()} empty />
          ))}
          {dayList.map((item) => (
            <Day
              key={item}
              popup={popup}
              currentDay={year === currentYear && month === currentMonth && item === currentDate}
              goalAchieved={false}
              date={item}
              onSelect={(e) => {
                e.preventDefault();
                const newDate = new Date(this.state.year, this.state.month, item);
                const startDate28 = new Date();
                if (this.props.preventInitialSetState) {
                  const orderStartDate28 = new Date(this.props.orderYear, this.props.orderMonth, this.props.orderDate);
                  startDate28.setFullYear(orderStartDate28.getFullYear());
                  startDate28.setMonth(orderStartDate28.getMonth());
                  startDate28.setDate(orderStartDate28.getDate() + 28);
                } else {
                  startDate28.setDate(startDate28.getDate() + 28);
                }

                const todaysDate3 = new Date();
                todaysDate3.setDate(todaysDate3.getDate() + 3);

                const today = new Date();
                today.setHours(0, 0, 0, 0);

                if (orderDelayOffset && newDate < today) {
                  window.alert('You can not select previous date than today.');
                } else if (orderDelayOffset && newDate.getTime() === today.getTime()) {
                  window.alert('You can not select today as the delay date.');
                } else if (orderDelayOffset && newDate < todaysDate3) {
                  window.alert('You can not select date within 3 days from today as the delay date.');
                } else if (this.state.today >= newDate) {
                  this.select({
                    date: item,
                    month: this.state.month,
                    year: this.state.year,
                  });
                } else if (futureDateSelectedHandler) {
                  futureDateSelectedHandler(
                    this.select({ date: item, month: this.state.month, year: this.state.year }),
                  );
                } else {
                  window.alert(
                    `This date is ahead of the current time. Please select a date equal to or before ${this.state.today}`,
                  );
                }
              }}
            />
          ))}
          {completeList.map((item) => (
            <Day popup={popup} key={item} onSelect={(e) => e.preventDefault()} empty />
          ))}
        </div>
      </div>
    );
  }
}
