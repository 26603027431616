import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as actionTypes from '../../data/exports';
import * as selectors from '../../data/dogs/selectors';
import WizardLayout from '../../components/wizard/WizardLayout';
import Button from '../../components/Button';
import RadioInput from '../../components/wizard/RadioInput';
import PopUpIcon from '../../assets/images/wizard/health-option-icon.png';
import Tip from '../../components/wizard/Tip';
import TooOld from '../../assets/images/wizard/tips/age_too_old.png';
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import RadioGroup from "@material-ui/core/es/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/es/Radio/Radio";

class Gender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dog: this.props.currentDog,
            nextRoute: '',
            isError: false,
        };

        this.handleStep = (step, e) => {
            e.preventDefault();

            if (this.props.currentDog.gender === ''){
                this.setState({isError: true});
            }else {
                this.props.history.push(step);
            }
        };

        this.handleBackStep = (step, e) => {
            e.preventDefault();
            this.props.history.push(step);
        };

        this.handleGenderInput = (e) => {
            const dogIndex = this.props.getIndex;
            this.props.updateCurrentDog('gender', dogIndex, e.target.value);
            if (e.target.value === 'male') {
                this.setState({nextRoute: '/neutered'});
                this.props.history.push('/neutered');
            } else {
                this.setState({nextRoute: '/spayed'});
                this.props.history.push('/spayed');
            }
        };

        this.handleModal = () => {
            $('#gender').modal('show');
        };
    }

    componentDidMount() {
        const dogIndex = this.props.getIndex;
        const {pathname} = this.props.location;
        if(this.props.currentDog.gender === ''){
            this.props.updateCurrentDog('progress', dogIndex, 6);
        }
        this.props.updateCurrentDog('step', dogIndex, pathname);
        this.props.toggleDogNav(true);
        if (this.props.currentDog.gender === 'male') {
            this.setState({nextRoute: '/neutered'});
        } else {
            this.setState({nextRoute: '/spayed'});
        }
    }

    render() {
        const radioStyles = {
            flexDirection: 'row',
            justifyContent: 'space-evenly'
        };
        return (
            <WizardLayout
                title=""
                wizardProgress={this.props.progress}
            >
                <form className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 row pb-4 justify-content-center px-xl-5 px-lg-5 px-md-5 px-sm-3">
                    <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                        is {this.props.currentDog.name} male or female?
                        <span className="pop-up-link" onClick={this.handleModal}>
                            <a><img className="ml-2 mb-1" src={PopUpIcon}/></a>
                        </span>
                    </h5>
                    <FormControl component="fieldset" className={"material-radio-buttons"} style={{width: '80%'}}>
                        <RadioGroup
                            style={radioStyles}
                            aria-label="gender"
                            name="gender2"
                            value={this.props.currentDog.gender}
                            onChange={this.handleGenderInput}
                        >
                            <FormControlLabel
                                value="male"
                                control={<Radio color="primary"  />}
                                label="MALE"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="female"
                                control={<Radio color="primary" />}
                                label="FEMALE"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </form>
                <div style={{maxWidth:'390px'}} className="col-xl-10 col-lg-11 col-md-12 col-12 px-2 row justify-content-center">

                    {this.state.isError
                        ?
                        <div className="animated fadeIn col-12 pb-1 text-center error-message">
                            Please choose one option.
                        </div>
                        :
                        ''
                    }

                    <div className="col-6 pl-xl-4 pl-lg-4 pl-md-3 pr-1 text-center">
                        <Button
                            onClick={e => this.handleBackStep('/add-dog', e)}
                            classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                            label="BACK"
                        />
                    </div>
                    <div className="col-6 pr-xl-4 pr-lg-4 pr-md-3 pl-1 text-center">
                        <Button
                            onClick={e => this.handleStep(this.state.nextRoute, e)}
                            classes="button-primary fill orange animated fadeInRight delay-_2s"
                            label="NEXT"
                        />
                    </div>
                </div>
                <Tip
                    isButton={false}
                    title=""
                    description={`Your pet's gender will affect their nutritional and calorie intake needs`}
                    target="gender"
                    isImage={true}
                    image={TooOld}
                    btnLabel=""
                />
            </WizardLayout>
        );
    }
}

const mapStateToProps = state => ({
    storeDogs: state.data.dogs.storeDogs,
    currentDog: state.data.dogs.currentDog,
    getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
    updateCurrentDog: (key, index, value) =>
        dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
    toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Gender));
