import * as api from './api';

/**
 * Pass a limited set of dog fields, and it'll just update those fields
 */
export const updateDog = dog => (
  new Promise((resolve, reject) => {
    api.update(dog)
      .then((response) => {
        resolve(response);
      })
      .catch(err => reject(err));
  })
);
