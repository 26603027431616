import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import Row from '../../components/Row';
import HalfColumn from '../../components/HalfColumn';
import FullColumn from '../../components/FullColumn';
import InputField from '../../components/InputField';
import RadioButtons from '../../components/RadioButtons';
import Dropdown from '../../components/Dropdown';
import FormErrors from '../../components/FormErrors';
import ImperialCheckbox from '../../components/ImperialCheckbox';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as dogsSelectors from '../../data/dogs/selectors';
import { validateMandatory, getAgeFromDOB } from '../../utils';
import { fetchApi } from '../../services/api/index';
import DateSelectionBanner from '../../components/DateSelectionBanner';


const unknownBreedsList = [
  { id: 1, name: 'TOY' },
  { id: 2, name: 'SMALL' },
  { id: 3, name: 'MEDIUM' },
  { id: 4, name: 'LARGE' },
  { id: 5, name: 'GIANT' },
];

const createBreed = breedName =>
  fetchApi('/dogs/breeds', { breed_name: breedName }, 'post');

class DogInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dogImage: '',
      imageWidth: undefined,
      errors: [],
      years: '',
      months: '',
      dog: {
        breeds_id: [],
        breed_id: 0,
        unKnownBreedID: -1,
        unknown_breed: false,
        neutered: false,
        microchip_id: '',
        age: 1,
        gender: 'male',
        name: '',
        dob: '',
        weight: '',
        height: '',
        weightShow: '',
        heightShow: '',
      },
      isLb: true,
      isInch: true,

      isLoading: false, // This is an indicating for create select isLoading.
      breedOptions: [],
      today: new Date(),
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleYears = this.handleYears.bind(this);
    this.handleMonths = this.handleMonths.bind(this);
    this.setAge = this.setAge.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.stepsState.dog);
    this.updateDimensions();

    window.addEventListener('resize', this.updateDimensions);

    console.log('this.props.stepsState.dog:', this.props.stepsState.dog);
    if (this.props.stepsState.dog) {
      const {
        weight, weightShow, height, heightShow, dob,
      } = this.props.stepsState.dog;

      const ageMonths = getAgeFromDOB(dob);

      this.setState({
        dog: Object.assign(
          {},
          this.state.dog,
          this.props.stepsState.dog,
        ),

        isLb: weightShow === weight,
        isInch: height === heightShow,
        years: parseInt(ageMonths / 12, 10),
        months: ageMonths % 12,
      });
    }

    if (this.props.stepsState.dogImage) {
      this.setState({
        dogImage: this.props.stepsState.dogImage,
      });
    }

    this.props.actions.dogs.fetchBreeds();

    if (this.props.dog !== undefined) {
      const newDog = {
        name: this.props.dog.name !== null ? this.props.dog.name : 'no_name',
        breeds_id: this.props.dog.breeds_id !== null ? this.props.dog.breeds_id : [],
        unKnownBreedID: this.props.dog.unKnownBreedID !== null ? this.props.dog.unKnownBreedID : -1,
        unknown_breed: this.props.dog.unknown_breed !== null ? this.props.dog.unknown_breed : false,
        neutered: this.props.dog.neutered !== null ? this.props.dog.neutered : false,
        microchip_id: this.props.dog.microchip_id !== null ? this.props.dog.microchip_id : '',
        age: this.props.dog.age !== null ? this.props.dog.age : '',
        gender: this.props.dog.gender !== null ? this.props.dog.gender : 'male',
        dob: this.props.dog.dob !== null ? `${this.props.dog.dob.substr(0, 10)}T00:00:00Z` : '',
        weight: this.props.dog.weight !== null ? this.props.dog.weight : '',
        height: this.props.dog.height !== null ? this.props.dog.height : '',
      };

      this.setState({ dog: newDog });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  handleMonths(e) {
    const months = parseInt(e.target.value, 10);

    if (months > 12) {
      this.setState({ errors: ['Age(months) cannot be bigger than 12.'] });
      e.preventDefault();
      return;
    }
    this.setState({ errors: [] });


    this.setState({ months }, this.setAge);
  }

  handleYears(e) {
    const years = parseInt(e.target.value, 10);
    const data = { age: years * 12 };
    const mandatoryFields = [
      'age',
    ];

    const errors = validateMandatory(data, mandatoryFields);
    if (errors.length > 0) {
      this.setState({ errors });
      e.preventDefault();
      return;
    }
    this.setState({ errors: [] });


    this.setState({ years }, this.setAge);
  }

  handleCreate(inputValue) {
    const input = inputValue.replace(/[^\w\s]/gi, '');
    this.setState({ isLoading: true });
    setTimeout(() => {
      createBreed(input)
        .then((response) => {
          if (response) {
            const dog = Object.assign({}, this.state.dog, {
              breeds_id: [...this.state.dog.breeds_id, response.ID],
            });

            let { breedOptions } = this.state;
            if (breedOptions.length <= 0) {
              breedOptions = [...this.props.breeds, response];
            } else {
              breedOptions = [...breedOptions, response];
            }

            this.setState({ isLoading: false, breedOptions, dog });
          } else {
            this.setState({ isLoading: false });
          }
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
        });
    }, 1000);
  }

  onChangeHandler(key) {
    return (event) => {
      let value = event;
      if (event.target) {
        value = event.target.value;
      }

      if (event.value) {
        value = event.value;
      }

      console.log('event:', event);

      if (event.values) {
        value = event.map(item => item.value);
      }

      let formattedValue = value;

      const numericKeys = ['age', 'weight', 'height'];
      if (numericKeys.indexOf(key) !== -1) {
        if (value.length > 0) {
          formattedValue = parseFloat(value);

          if (Number.isNaN(formattedValue)) {
            formattedValue = value;
          }
        }
      }

      if (key === 'dob') {
        const age = getAgeFromDOB(value);
        const dog = Object.assign({}, this.state.dog, {
          [key]: formattedValue, age,
        });

        this.setState({ dog });
      } else if (key === 'breeds_id') {
        formattedValue = formattedValue.map((item) => {
          if (typeof item === 'string') {
            const input = item.replace(/[^\w\s]/gi, '');

            this.setState({ isLoading: true });
            createBreed(input)
              .then((response) => {
                let { breedOptions } = this.state;
                if (breedOptions.length <= 0) {
                  breedOptions = [...this.props.breeds, response];
                } else {
                  breedOptions = [...breedOptions, response];
                }

                this.setState({ isLoading: false, breedOptions });
                return response.ID;
              })
              .catch(() => {
                this.setState({
                  isLoading: false,
                });
              });
          }
          return item;
        });

        console.log('formatted:', formattedValue);

        const dog = Object.assign({}, this.state.dog, {
          [key]: formattedValue,
        });

        this.setState({ dog });
      } else {
        const dog = Object.assign({}, this.state.dog, {
          [key]: formattedValue,
        });

        this.setState({ dog });
      }
    };
  }

  setAge() {
    const age = parseInt((this.state.years * 12) + this.state.months, 10);
    const dog = Object.assign({}, this.state.dog, {
      age,
    });

    this.setState({ dog });
  }

  updateDimensions() {
    const imageWidth = this.image.offsetWidth;

    this.setState({ imageWidth });
  }

  updateWithUnits(key) {
    // key is either weight or height
    return (event) => {
      let value = event;
      if (event.target) {
        value = event.target.value;
      }
      if (event.value) {
        value = event.value;
      }
      value = parseFloat(value);
      const toMetric = {
        weight: 0.45,
        height: 2.54,
      };
      const obj = {};
      obj[key] = Math.round(value
        * (this.state[key === 'weight' ? 'isLb' : 'isInch'] ? toMetric[key] : 1));
      obj[`${key}Show`] = value.toString();
      this.setState({ dog: Object.assign({}, this.state.dog, obj) });
    };
  }

  updateWithUnitsCheckbox(key) {
    // key is either weight or height
    let value = 0;
    if (key == 'weight'){
      value = this.state.dog.weightShow;
    }
    else{
      value = this.state.dog.heightShow;
    }
    console.log("value raw: ", value)
    const toMetric = {
      weight: 0.45,
      height: 2.54,
    };
    const obj = {};
    obj[key] = Math.round(value * (this.state[key === 'weight' ? 'isLb' : 'isInch'] ? 1:toMetric[key]));
    obj[`${key}Show`] = value.toString();
    this.setState({ dog: Object.assign({}, this.state.dog, obj) });
  }

  onNext() {
    return new Promise((resolve, reject) => {
      const mandatoryFields = [
        this.state.dog.unknown_breed ? 'unknown_breed' : 'breeds_id',
        'neutered',
        'age',
        'dob',
        'weight',
        'height',
      ];

      this.state.dog.breeds_id = this.state.dog.unknown_breed ? [] : this.state.dog.breeds_id;
      this.state.dog.breed_id = this.state.dog.breeds_id[0];

      const errors = validateMandatory(this.state.dog, mandatoryFields);

      if (errors.length > 0) {
        this.setState({ errors });
        console.log(errors);
        reject();
      }

      this.props.onUpdateState({ dog: this.state.dog });

      if (this.props.dog !== undefined) {
        this.handleUpdate(reject);
      }

      resolve();
    });
  }

  handleUpdate(reject) {
    const mandatoryFields = [
      this.state.dog.unknown_breed ? 'unknown_breed' : 'breeds_id',
      'neutered',
      'age',
      'dob',
      'weight',
      'height',
    ];

    this.state.dog.breeds_id = this.state.dog.unknown_breed ? [] : this.state.dog.breeds_id;

    const errors = validateMandatory(this.state.dog, mandatoryFields);

    if (errors.length > 0) {
      console.log('Error', errors);
      this.setState({ errors });
      reject();

      return;
    }

    const wholeDog = this.props.dog;
    wholeDog.breeds_id = this.state.dog.unknown_breed ? [] : this.state.dog.breeds_id;
    wholeDog.neutered = this.state.dog.neutered;
    wholeDog.unknown_breed = this.state.dog.unknown_breed;
    wholeDog.microchip_id = this.state.dog.microchip_id;
    wholeDog.age = this.state.dog.age;
    wholeDog.gender = this.state.dog.gender;
    wholeDog.dob = this.state.dog.dob;
    wholeDog.weight = this.state.dog.weight;
    wholeDog.height = this.state.dog.height;
    console.log('Error');
    // uses updateDog to get a response of the full updated dog from the api and then uses this response to update redux
    this.props.actions.dogs.updateDog(wholeDog)
      .then((response) => {
        if (response.errors) {
          for (const err of response.errors) {
            this.state.errors.push(err);
          }
          console.log('Error', response.errors);
          return;
        }
        console.log('Response: ', response);
      })
      .catch((err) => {
        console.log('An error has occured: ', err);
      });
  }

  render() {
    const { stepsState, onSubmit } = this.props;

    let name;

    if (this.state.dog === undefined && this.props.dog === undefined) {
      return null;
    }

    if (this.state.dog !== undefined) {
      name = this.state.dog.name;
    } else {
      name = stepsState.name;
    }

    const today = new Date();

    return (
      <div>
        <OrangeTextHeading text={`Let's get to know ${name}!`} fontSize={30} centered marginBottom />
        <Row>
          <HalfColumn>
            <GreyText text={`To help us create ${name}'s bespoke diet, please complete their unique profile so we understand a little more about them.`} fontSize={20} centered />
          </HalfColumn>
        </Row>
        <Row>
          <FullColumn noPaddingBottom>
            <GreyText text={`Information about ${name}`} />
          </FullColumn>
        </Row>
        <Row>
          <HalfColumn centerVertically>
            <form onSubmit={onSubmit}>
              <FormErrors errors={this.state.errors} />
              <Row>
                <FullColumn noPaddingBottom>
                  <RadioButtons
                    context="Gender*"
                    leftLabel="Male"
                    leftValue="male"
                    rightLabel="Female"
                    rightValue="female"
                    activeValue={this.state.dog.gender}
                    onChange={this.onChangeHandler('gender')}
                  />
                </FullColumn>
              </Row>
              <Row>
                <FullColumn noPaddingBottom>
                  <RadioButtons
                    context="Breed*"
                    leftLabel="Known"
                    leftValue={false}
                    rightLabel="Unknown"
                    rightValue
                    activeValue={this.state.dog.unknown_breed}
                    onChange={this.onChangeHandler('unknown_breed')}
                  />
                </FullColumn>
              </Row>
              {!this.state.dog.unknown_breed &&
                <Row>
                  <FullColumn noPaddingBottom>
                    <Dropdown
                      activeOption={this.state.dog.breeds_id}
                      onChangeOption={this.onChangeHandler('breeds_id')}
                      options={this.state.breedOptions.length === 0 ? this.props.breeds : this.state.breedOptions}
                      placeholder="Breed*"
                      isMultiple
                      isCreateble
                      isLoading={this.state.isLoading}
                      handleCreate={this.handleCreate}
                    />
                  </FullColumn>
                </Row>
              }
              {this.state.dog.unknown_breed &&
                <Row>
                  <FullColumn noPaddingBottom>
                    <Dropdown
                      activeOption={this.state.dog.unKnownBreedID}
                      onChangeOption={this.onChangeHandler('unKnownBreedID')}
                      options={unknownBreedsList}
                      placeholder="Size*"
                    />
                  </FullColumn>
                </Row>
              }
              <Row>
                <FullColumn noPaddingBottom>
                  <div className="registration-date-selector">
                    <div className="date-text">{this.state.dog.dob !== '' ? `${this.state.dog.dob.substr(0, 10).split('-')[2]}-${this.state.dog.dob.substr(0, 10).split('-')[1]}-${this.state.dog.dob.substr(0, 10).split('-')[0]}` : 'Date of birth'}</div>
                    <DateSelectionBanner
                      justIcon
                      notRenderText
                      onUpdateDate={(val) => {
                        let { date } = val;
                        let month = val.month + 1;

                        if (date <= 9) {
                          date = `0${val.date}`;
                        }

                        if (month <= 9) {
                          month = `0${val.month + 1}`;
                        }

                        const formatted = `${val.year}-${month}-${date}T00:00:00Z`;
                    
                        this.onChangeHandler('dob')(formatted);

                        
                        this.setState({
                          calendarVisible: false,
                          years: ((today.getMonth() - val.month) < 0) ? today.getFullYear() - val.year -1 : today.getFullYear() - val.year,
                          months: ((today.getMonth() - val.month) < 0) ? 12 + today.getMonth() - val.month : today.getMonth() - val.month,
                        });
                      }}
                      currentDate={this.state.dog.dob !== '' ? parseInt(this.state.dog.dob.substr(0, 10).split('-')[2], 10) : today.getDate()}
                      currentMonth={this.state.dog.dob !== '' ? parseInt(this.state.dog.dob.substr(0, 10).split('-')[1], 10) - 1 : today.getMonth()}
                      currentYear={this.state.dog.dob !== '' ? parseInt(this.state.dog.dob.substr(0, 10).split('-')[0], 10) : today.getFullYear()}
                    />
                  </div>
                </FullColumn>
              </Row>
              <Row>
                <HalfColumn noPaddingBottom>
                  <InputField
                    type="number"
                    placeholder={`Age (years)${this.state.dog.dob === '' ? '*' : ''}`}
                    value={this.state.years}
                    onChangeText={this.handleYears}
                    noMarginBottom
                  />
                </HalfColumn>
                <HalfColumn noPaddingBottom>
                  <InputField
                    type="number"
                    placeholder={`Age (months)${this.state.dog.dob === '' ? '*' : ''}`}
                    value={this.state.months}
                    onChangeText={this.handleMonths}
                    noMarginBottom
                  />
                </HalfColumn>
              </Row>
              <Row>
                <FullColumn noPaddingBottom>
                  <RadioButtons
                    context="Neutered*"
                    leftLabel="Yes"
                    leftValue
                    rightLabel="No"
                    rightValue={false}
                    activeValue={this.state.dog.neutered}
                    onChange={this.onChangeHandler('neutered')}
                  />
                </FullColumn>
              </Row>
              <Row>
                <FullColumn noPaddingBottom>
                  <InputField
                    type="text"
                    placeholder="Microchip ID"
                    value={this.state.dog.microchip_id}
                    onChangeText={this.onChangeHandler('microchip_id')}
                    noMarginBottom
                  />
                </FullColumn>
              </Row>
              <Row>
                <HalfColumn noPaddingBottom>
                  <div className="flex-row-center">
                    <InputField
                      type="number"
                      placeholder={`Weight (${this.state.isLb ? 'Lb' : 'Kg'})*`}
                      value={this.state.dog.weightShow}
                      onChangeText={this.updateWithUnits('weight')}
                      style={{ width: '80%' }}
                      noMarginBottom
                    />
                    <ImperialCheckbox
                      data="Kg"
                      onClick={() => {
                        const { dog } = this.state;
                        this.setState({ isLb: !this.state.isLb, dog });
                        this.updateWithUnitsCheckbox('weight')
                      }}
                      active={this.state.isLb}
                    />
                  </div>
                </HalfColumn>
                <HalfColumn noPaddingBottom>
                  <div className="flex-row-center">
                    <InputField
                      type="number"
                      placeholder={`Height (${this.state.isInch ? 'In' : 'Cm'})*`}
                      value={this.state.dog.heightShow}
                      onChangeText={this.updateWithUnits('height')}
                      style={{ width: '80%' }}
                      noMarginBottom
                    />
                    <ImperialCheckbox
                      data="Cm"
                      onClick={() => {
                        const { dog } = this.state;
                        this.setState({ isInch: !this.state.isInch, dog });
                        this.updateWithUnitsCheckbox('height')
                      }}
                      active={this.state.isInch}
                    />
                  </div>
                </HalfColumn>
              </Row>
            </form>
          </HalfColumn>
          <HalfColumn centerVertically>
            <div
              className="dog-image-border"
            >
              <img
                alt=""
                className="dog-image-frame"
                src="/assets/images/dog-image-frame.png"
              />
              <div
                ref={(image) => {
                  this.image = image;
                }}
                className="dog-image-container"
                style={{ height: this.state.imageWidth, backgroundImage: this.state.dogImage !== '' ? `url(${this.state.dogImage})` : 'url(/assets/images/kaspermeter-head.png)' }}
              />
            </div>
          </HalfColumn>
        </Row>
      </div>
    );
  }
}

export default connect(() => ({
  breeds: dogsSelectors.getAllBreeds(),
}), dispatch => ({
  actions: {
    dogs: bindActionCreators(dogsActionCreators, dispatch),
  },
}), null, { withRef: true })(DogInformation);
