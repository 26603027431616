import React from 'react';
import Row from '../components/Row';
import FormSignUp from './FormSignUp';
import AuthorizationBanner from './AuthorizationBanner';
import dogImage from '../assets/images/signUp_dog.png';
import wordImageTablet from '../assets/images/sign-up-word_tablet.png';
import wordImage from '../assets/images/Hello.png';

export default class SignUpLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { activeStep } = this.props;
    return (
      <Row>
        <FormSignUp />
        <AuthorizationBanner
          bannerColor={this.props.bannerColor}
          dogImage={dogImage}
          wordImage={wordImage}
          tabletWord={wordImageTablet}
        />
      </Row>
    );
  }
}
