import React from 'react';

export default class MainImageBanner extends React.Component {
  render() {
    const {
      image,
      heading,
      subtext,
      height,
    } = this.props;

    let style = {};
    if (height !== undefined) {
      style = { minHeight: `${height}px` };
    }

    return (
      <div className="image-banner large" style={style}>
        <div className="image full" style={{ backgroundImage: image }} />
        <div className="gradient none">
          <p className="heading">{heading}</p>
          <p className="subtext">{subtext}</p>
        </div>
      </div>
    );
  }
}
