import React from 'react';

import Navigation from '../components/Navigation';
import MainImageBanner from '../components/MainImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';

export default class Reviews extends React.Component {
  render() {
    return (
      <div className="container">
        <Navigation
          absolute
          white
          textColor="white-text"
        />
        <MainImageBanner
          image="url(/assets/images/reviews.jpeg)"
          heading="Reviews"
          subtext="Awaiting content"
        />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
