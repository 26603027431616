import React from 'react';
import PropTypes from 'prop-types';
import OrderSummary from './OrderSummary';
import InfoBlock from './Infoblock';
import CrossIcon from '../icons/CrossIcon';

class OrderSummaryContainer extends React.Component {
  render() {
    const {
      step, isModal, closeModal, submitSummary, paymentLoading, detailsValid,
    } = this.props;
    return (
      <div className={`order-summary-${isModal ? 'modal mui-modal' : 'static'}`}>
        {isModal ? <CrossIcon clickHandler={closeModal} classes="cross-modal" /> : null}
        <OrderSummary
          step={step}
          paymentLoading={paymentLoading}
          detailsValid={detailsValid}
          submitSummary={submitSummary}
        />
        <InfoBlock />
      </div>
    );
  }
}

OrderSummaryContainer.defaultProps = {
  isModal: false,
};

OrderSummaryContainer.propTypes = {
  isModal: PropTypes.bool,
  step: PropTypes.oneOf([1, 2, 3]).isRequired,
};

export default OrderSummaryContainer;
