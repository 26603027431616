import React from 'react';

import { Link } from 'react-router-dom';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import SimplyPowesome from '../components/SimplyPowesome';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';
import DentalCard from '../components/DentalCard';
import SgSection from '../components/SgSectoin';

const dentals = [
  {
    image: '/assets/images/grey-circle.png',
    subj: 'Premium Dental',
    text: 'Training treats for younger dogs',
  },
  {
    image: '/assets/images/grey-circle.png',
    subj: 'Cereal-Free Dental',
    text: 'Training treats for younger dogs',
  },
  {
    image: '/assets/images/grey-circle.png',
    subj: 'Puppy Dental',
    text: 'Training treats for younger dogs',
  },
];

export default class MoreThanFood3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { windowWidth: window.innerWidth };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/mtf3-banner.jpg)" heading="more than food" subtext="a diet at unique as your pet" />
        <div className="dental-essentials-container">
          <div className="row">
            <div className="col-1" />
            <div className="col-5">
              <h1 className="mb-4">Dental essentials</h1>
              <h6 className="color-orange fw-bold mb-4">Don't miss out on these love licks on the check from your pet!</h6>
              <p className="mb-4">Stock up on life's small essentials to keep their breath fresh and help with tartar control!</p>
              <p className="desc">Our recommended dental treats have been designed to keep your dog's toothy pegs in top shape, help reduce tartar build up and help keep their jaws strong!</p>
            </div>
            <div className="col-5">
              <img src="/assets/images/toothbrush-dog.png" />
            </div>
            <div className="col-1" />
          </div>
        </div>
        <div className="dental-treats-container">
          <h1 className="mb-4">Dental treats</h1>
          <div className="row">
            {dentals.map((dental, index) => (<div key={index} className="col-md-4">
              <DentalCard image={dental.image} subj={dental.subj} text={dental.text} />
                                             </div>))}
            <div className="col" />
            <div className="col" />
          </div>
        </div>
        <SgSection />
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
