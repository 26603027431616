import React from 'react';
import { NavLink } from 'react-router-dom';

const CheckoutSteps = ({ step }) => (
  <div id="checkout-nav">
    <span className="checkout-text">Checkout</span>
    <div className={`steps current-step-${step}`}>
      <div className="rail">
        <NavLink
          to="/cart"
          className={`checkout-step ${step >= 1 ? 'passed' : ''}`}
          aria-current="step"
          onClick={(event) => { event.preventDefault(); }}
        >
          Step 1: Shopping Basket
        </NavLink>
        <NavLink
          to="/checkout/delivery"
          className={`checkout-step ${step >= 2 ? 'passed' : ''}`}
          aria-current="step"
          onClick={(event) => { event.preventDefault(); }}
        >
          Step 2: Delivery & Details
        </NavLink>
        <NavLink
          to="/checkout/payment"
          className={`checkout-step ${step >= 3 ? 'passed' : ''}`}
          aria-current="step"
          onClick={(event) => { event.preventDefault(); }}
        >
          Step 3: Payment
        </NavLink>
      </div>
    </div>
  </div>
);

export default CheckoutSteps;
