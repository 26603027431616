import React, { Component } from 'react';

export default class Pdsa extends Component {
  render() {
    return ( null
      //<div className="pdsa-container">
      //  <p className="heading">Helping less fortunate pets - For every 10 subscriptions we'll make a &pound;00.00 donation to the PDSA</p>
      //  <p className="desc">The PDSA offers reduced cost and free veterinary care for pets in need</p>
      //</div>
    );
  }
}
