import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import ReactLoading from 'react-loading';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  injectStripe,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';
import * as Sentry from '@sentry/browser';

import Grid from '@material-ui/core/Grid/Grid';
import Textfield from '../../Textfield';
import CheckoutLayout from '../CheckoutLayout';
import DeliveryMethod from './DeliveryMethod';
import Promocode from './Promocode';
import BillingAddress from './BillingAddress';
import PaymentPlan from './PaymentPlan';
import HowCVCTip from '../../../components/checkout/HowCVCTip';

import { createOrder } from '../../../data/orders/actions';
import { addressOptions } from '../../../data/checkout/actionTypes';
import { getCartItemsByDog } from '../../../data/cart/utils';
import { fetchApi } from '../../../services/api';
import { getOrders } from '../../../data/dogs/api';

import * as api from '../../../data/orders/api';
import * as dogsSelectors from '../../../data/dogs/selectors';
import * as sessionSelectors from '../../../services/session/selectors';
import * as actionCreators from '../../../data/checkout/actions';
import * as cartActionCreators from '../../../data/cart/actions';
import * as dogsActionCreators from '../../../data/dogs/actions';
import * as session from '../../../services/session';

import ContainedButton from '../../ContainedButton';
import OutlinedButton from '../../OutlinedButton';
import InfoIcon from '../../icons/InfoIcon';
import { PayHandler } from '../../../services/PayHandler';

const BackLink = (props) => <Link to='/checkout/delivery' {...props} />;

class CheckoutPaymentPage extends React.Component {
  constructor(props) {
    super(props);

    this.captcha = null;

    this.state = {
      promocode: localStorage.getItem('autoPromo')
        ? localStorage.getItem('autoPromo')
        : '',
      promocodeValid: false,
      billingAddress: { ...props.billingAddress },
      addressFieldsDisabled: true,
      isSubmitted: false,
      order_id: -1,
      orderPlaced: false,
      paymentLoading: false,
      cardNumberComplete: false,
      cardExpiryComplete: false,
      cardCVCComplete: false,
      recapToken: '',
      ccName: '',
    };
  }

  componentDidMount() {
    api.autoPromo()
      .then(res => res.ID && !localStorage.getItem('autoPromo') ? this.setState({ promocode: res.code }) : '')
      .then(() => this.promocodeCheck());

    getOrders().then((orders) => {
      let currentDog = dogsSelectors.getCurrentDog();

      let currentDogBespokeOrders = orders.filter(
        (item) =>
          item.dog_id === currentDog.ID &&
          item.order_items.filter((orderItem) => orderItem.is_kibble).length > 1
      );

      let unpaidOrders = currentDogBespokeOrders.filter(
        (item) => item.awaiting_payment
      );

      let paidOrders = currentDogBespokeOrders.filter(
        (item) => item.awaiting_payment === false
      );

      if (unpaidOrders.length > 0) {
        this.setState({ orderPlaced: true, order_id: unpaidOrders[0].ID });
      }

      if (paidOrders.length > 0) {
        session.updateUser({});
        this.props.promocodeUpdate({});
        this.props.deleteAll();
        this.props.clearDogs();
        this.props.history.push('/thanks');
      }
    });

    Sentry.setUser({ email: this.props.user.email });

    this.setState({
      billingAddress: {
        streetAddressLine1: this.props.sameAddress.streetAddressLine1,
        streetAddressLine2: this.props.sameAddress.streetAddressLine2,
        city: this.props.sameAddress.city,
        county: this.props.sameAddress.county,
        country: this.props.sameAddress.country,
        zipPostalCode: this.props.sameAddress.zipPostalCode,
        selectedAddressOption: this.props.billingAddress.selectedAddressOption,
      },
    });

    this.props.billingAddressUpdate(this.state.billingAddress);
    this.payHandler = new PayHandler(this.props);
  }

  isPromocodeValid = (promocode) => fetchApi(`/orders/promo/${promocode}`);

  handleChangePromocode = (field) => (event) => {
    this.isPromocodeValid(event.target.value).then((r) => {
      if (r !== null) {
        this.setState({ promocodeValid: !r.used });
      } else {
        this.setState({ promocodeValid: false });
      }
    });

    this.setState({
      [field]: event.target.value,
    });
  };

  handleChangeBillingAddress = (field) => (event) => {
    this.setState({
      billingAddress: {
        ...this.state.billingAddress,
        [field]: event.target.value,
      },
    });
  };

  promocodeCheck = () => {
    api.promocode(this.state.promocode).then((response) => {
      if (response !== null) {
        if (response.used) {
          this.setState({ promocodeValid: false });
        } else {
          this.setState({ promocodeValid: true });
        }
        this.props.promocodeUpdate(response);
      } else {
        this.setState({ promocodeValid: false });
        this.props.promocodeUpdate(this.state.promocode);
      }
    });
  };

  payAndGoToUrl = (url) => (event) => {
    if (!this.state.promocodeValid && this.state.promocode !== '') {
      alert('This code has already been used. Please use another code. Thank you.');
      return;
    }

    this.setState({ paymentLoading: true });

    if (!this.state.orderPlaced) {
      this.handleOrder(event, url);
    } else {
      this.pay(this.state.order_id, url);
    }
  };

  pay(orderID, goToUrl) {
    const paymentInfo = {
      order_id: this.state.order_id,
      address_line1: this.state.billingAddress.streetAddressLine1,
      address_line2: this.state.billingAddress.streetAddressLine2,
      address_city: this.state.billingAddress.city,
      address_state: this.state.billingAddress.county,
      address_zip: this.state.billingAddress.zipPostalCode,
      address_country: this.state.billingAddress.country,
      recapcha_token: this.state.recapToken,
    };
    return this.payHandler.charge(paymentInfo)
      .then(() => {
        // Refresh user information
        session.updateUser({});
        this.props.promocodeUpdate({});
        this.props.deleteAll();
        this.props.clearDogs();
        this.props.history.push(goToUrl);
      })
      .catch((error) => {
        this.captcha?.reset()
        Sentry.captureException(error);
        this.setState({ paymentLoading: false, recapToken: '' });
        let message = '';
        if (error.message) {
          message = error.message;
        }
        alert(`An error occured while processing your payment: ${message}`);
      });
  }

  handleOrder(event, url) {
    const isSame = this.state.addressFieldsDisabled;
    this.setState({ isSubmitted: true });

    if (
      !this.state.addressFieldsDisabled &&
      (!this.state.billingAddress.streetAddressLine1 ||
        !this.state.billingAddress.city ||
        !this.state.billingAddress.county ||
        !this.state.billingAddress.country ||
        !this.state.billingAddress.zipPostalCode)
    ) {
      this.setState({ paymentLoading: false });
      event.preventDefault();
    } else {
      this.props.billingAddressUpdate({
        streetAddressLine1: this.state.billingAddress.streetAddressLine1,
        streetAddressLine2: this.state.billingAddress.streetAddressLine2,
        city: this.state.billingAddress.city,
        county: this.state.billingAddress.county,
        country: this.state.billingAddress.country,
        zipPostalCode: this.state.billingAddress.zipPostalCode,
        selectedAddressOption: this.state.billingAddress.selectedAddressOption,
      });

      session.updateUser({
        delivery_address: {
          company: this.props.sameAddress.company,
          address1: this.props.sameAddress.streetAddressLine1,
          address2: this.props.sameAddress.streetAddressLine2,
          country: this.props.sameAddress.country,
          county: this.props.sameAddress.county,
          postcode: this.props.sameAddress.zipPostalCode,
          town: this.props.sameAddress.city,
        },
        first_delivery_date: this.props.sameAddress.deliveryDate,
        billing_address: {
          company: isSame ? this.props.sameAddress.company : '',
          address1: isSame
            ? this.props.sameAddress.streetAddressLine1
            : this.state.billingAddress.streetAddressLine1,
          address2: isSame
            ? this.props.sameAddress.streetAddressLine2
            : this.state.billingAddress.streetAddressLine2,
          country: isSame
            ? this.props.sameAddress.country
            : this.state.billingAddress.country,
          county: isSame
            ? this.props.sameAddress.county
            : this.state.billingAddress.county,
          postcode: isSame
            ? this.props.sameAddress.zipPostalCode
            : this.state.billingAddress.zipPostalCode,
          town: isSame
            ? this.props.sameAddress.city
            : this.state.billingAddress.city,
        },
      });
      const { items, dog } = getCartItemsByDog(
        this.props.dogs,
        this.props.products,
        this.props.cart
      );

      let fitkolarCount = 0;
      items.filter((item) => {
        if (item.is_fitkolar) {
          fitkolarCount += 1;
        }
      });

      const itemsNoFitkolar = items.filter((item) => !item.is_fitkolar);
      const order = {
        dog_id: dog.ID,
        delivery_address: {
          company: this.props.sameAddress.company,
          address1: this.props.sameAddress.streetAddressLine1,
          address2: this.props.sameAddress.streetAddressLine2,
          town: this.props.sameAddress.city,
          county: this.props.sameAddress.county,
          postcode: this.props.sameAddress.zipPostalCode,
          country: this.props.sameAddress.country,
        },
        order_items: itemsNoFitkolar.map((item) => ({
          key: item.ID,
          price: item.price,
          name: item.name,
          quantity: item.quantity,
        })),
        fitkolars: fitkolarCount,
        promotion_id: this.props.promocode.code,
        delivery_method: '48hr delivery',
        // here
        delivery_cost: this.props.promocode.TrialPnP,
        delivery_instructions: this.props.sameAddress.note,
      };

      order.billing_address = this.state.addressFieldsDisabled
        ? order.delivery_address
        : {
          address1: this.state.billingAddress.streetAddressLine1,
          address2: this.state.billingAddress.streetAddressLine2,
          town: this.state.billingAddress.city,
          county: this.state.billingAddress.county,
          postcode: this.state.billingAddress.zipPostalCode,
          country: this.state.billingAddress.country,
        };

      api
        .ordernew(order)
        .then((orderResponse) => {
          if (orderResponse.success) {
            this.setState({
              order_id: orderResponse.order_id,
              orderPlaced: true,
            });
            return {
              orderID: orderResponse.order_id,
              goToUrl: url,
            };
          }

          alert('Order was not placed, please try again');
          Sentry.captureException(new Error(orderResponse.errors));

          this.setState({
            orderPlaced: false,
            paymentLoading: false,
          });
        })
        .then(({ orderID, goToUrl }) => {
          this.pay(orderID, goToUrl);
        })
        .catch((error) => {
          console.error(error)
          alert('Order was not placed, please try again');
          Sentry.captureException(new Error(error));
          this.setState({
            orderPlaced: false,
            paymentLoading: false,
          });
        });
    }
  }

  addressOptionChange = (event) => {
    const selectedAddressOption = event.target.value;

    this.setState({
      billingAddress: { ...this.state.billingAddress, selectedAddressOption },
      addressFieldsDisabled: selectedAddressOption === addressOptions.SAME,
    });
  };

  render() {
    const { items, dog } = getCartItemsByDog(
      this.props.dogs,
      this.props.products,
      this.props.cart
    );
    const bespokeCost = items.filter((item) => item.isBespoke);
    const billingAddress = this.state.addressFieldsDisabled
      ? this.props.sameAddress
      : this.state.billingAddress;

    return (
      <CheckoutLayout
        step={3}
        title='Payment'
        classes='checkout-payment-content'
        paymentLoading={this.state.paymentLoading}
        detailsValid={
          !this.state.cardCVCComplete ||
          !this.state.cardExpiryComplete ||
          !this.state.cardNumberComplete ||
          this.state.recapToken === '' ||
          this.state.ccName === ''
        }
        submitSummary={this.payAndGoToUrl('/thanks')}
        productCount={items.length}
      >
        <Grid container>
          <Grid item className='payment-info' md={6} xs={12}>
            <Grid container className='border-element h-100'>
              <Grid container className='border-bottom'>
                <h3 className='header-main'>Payment information*</h3>
              </Grid>

              <Grid item className='border-body checkout' xs={12}>
                <Grid item xs={11}>
                  <Textfield
                    name='cc-name'
                    label='Name on Card'
                    margin='dense'
                    fullWidth
                    value={this.state.ccName}
                    onChange={(e) => this.setState({ ccName: e.target.value })}
                  />
                </Grid>

                <Grid item xs={11}>
                  <CardNumberElement id="cardNumber"
                    className='input-field-new'
                    placeholder='Card Number'
                    onChange={(event) => {
                      this.setState({ cardNumberComplete: event.complete });
                    }}
                    style={{
                      base: {
                        fontSize: '14px',
                        letterSpacing: '0.4px',
                        fontWeight: 300,
                      },
                    }}
                  />
                </Grid>

                <Grid container spacing={24}>
                  <Grid item xs={6}>
                    <CardExpiryElement
                      className='input-field-new bottom'
                      placeholder='MM / YY'
                      onChange={(event) => {
                        this.setState({ cardExpiryComplete: event.complete });
                      }}
                      style={{
                        base: {
                          fontSize: '14px',
                          letterSpacing: '0.4px',
                          fontWeight: 300,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CardCVCElement
                      className='input-field-new bottom'
                      placeholder='CVV'
                      onChange={(event) => {
                        this.setState({ cardCVCComplete: event.complete });
                      }}
                      style={{
                        base: {
                          fontSize: '14px',
                          letterSpacing: '0.4px',
                          fontWeight: 300,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <a
                      className='input-field-info'
                      data-toggle='modal'
                      href='#how-cvc'
                    >
                      <InfoIcon />
                    </a>
                  </Grid>

                  <ReCAPTCHA
                    ref={(r) => this.captcha = r}
                    sitekey='6LfNZekUAAAAADOMAInYTkY3SQAYzKxSt9MfLdKr'
                    className='d-sm-none'
                    onChange={(token) => {
                      this.setState({ recapToken: token });
                    }}
                  />
                </Grid>

                <Grid item={11} className='captcha d-xs-none'>
                  <ReCAPTCHA
                    ref={(r) => this.captcha = r}
                    sitekey='6LfNZekUAAAAADOMAInYTkY3SQAYzKxSt9MfLdKr'
                    onChange={(token) => {
                      this.setState({ recapToken: token });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className="d-md-none">
            {this.state.paymentLoading ? (
              <div className='loading-wrapper'>
                <ReactLoading
                  type='spinningBubbles'
                  color='grey'
                  height={45}
                  width={45}
                />
              </div>
            ) : (
                <div
                  style={{ marginBottom: '20px' }}
                >
                  <ContainedButton
                    style={{ marginBottom: '10px' }}
                    onClick={this.payAndGoToUrl('/thanks')}
                    title='Ready for food? Finish here.'
                    disabled={
                      !this.state.cardCVCComplete ||
                      !this.state.cardExpiryComplete ||
                      !this.state.cardNumberComplete ||
                      this.state.recapToken === '' ||
                      this.state.ccName === ''
                    }
                  />
                  <div className="deserve-text" align='center'>
                    Because your best friend deserves the best.
                  </div>
                </div>
              )}
          </Grid>

          <Grid item className='payment-promocode-delivery-method' md={6} xs={12}>
            <DeliveryMethod deliveryCost={this.props.promocode.TrialPnP} />
            <Promocode
              promocodeValid={this.state.promocodeValid}
              promocode={this.state.promocode}
              handleChange={this.handleChangePromocode('promocode')}
              promocodeCheck={this.promocodeCheck}
            />
          </Grid>

          <Grid item className='payment-billing' xs={12}>
            <BillingAddress
              addressOptions={addressOptions}
              selectedAddressOption={
                this.state.billingAddress.selectedAddressOption
              }
              addressOptionChange={this.addressOptionChange}
              addressFieldsDisabled={this.state.addressFieldsDisabled}
              billingAddress={billingAddress}
              handleChange={this.handleChangeBillingAddress}
              isSubmitted={this.state.isSubmitted}
              isSameAddress={this.state.addressFieldsDisabled}
            />
          </Grid>
        </Grid>

        <Grid container>
          <PaymentPlan
            bespokeCost={bespokeCost[0] !== undefined ? ((bespokeCost[0].price)).toFixed(2) : "error"}
            numDays={this.props.promocode.NumDaysTilNextOrder}
          />
        </Grid>

        <Grid container className='buttons-wrapper'>
          <Grid item md='auto' xs={12}>
            <OutlinedButton title='Back to delivery' component={BackLink} />
          </Grid>

          <Grid item md='auto' xs={12}>
            {this.state.paymentLoading ? (
              <div className='loading-wrapper'>
                <ReactLoading
                  type='spinningBubbles'
                  color='grey'
                  height={45}
                  width={45}
                />
              </div>
            ) : (
                <div>
                  <ContainedButton
                    style={{ marginBottom: '10px' }}
                    onClick={this.payAndGoToUrl('/thanks')}
                    title='Ready for food? Finish here.'
                    disabled={
                      !this.state.cardCVCComplete ||
                      !this.state.cardExpiryComplete ||
                      !this.state.cardNumberComplete ||
                      this.state.recapToken === '' ||
                      this.state.ccName === ''
                    }
                  />
                  <div className="d-md-none deserve-text" align='center'>
                    Because your best friend deserves the best.
                  </div>
                </div>
              )}
          </Grid>
        </Grid>

        <HowCVCTip
          title='Where to find your CVV?'
          description=''
          target='how-cvc'
          isImage={false}
          isButton={false}
          btnLabel=''
        />
      </CheckoutLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.data.cart,
  products: state.data.products,
  dogs: state.data.dogs.dogs,
  sameAddress: state.data.delivery,
  promocode: state.data.promocode,
  billingAddress: state.data.billingAddress,
  user: sessionSelectors.getUser(),
});

const mapDispatchToProps = (dispatch) => ({
  createOrder: bindActionCreators(createOrder, dispatch),
  billingAddressUpdate: (billingAddress) => dispatch(actionCreators.billingAddressUpdate(billingAddress)),
  promocodeUpdate: (promocode) => dispatch(actionCreators.promocodeUpdate(promocode)),
  deleteAll: () => dispatch(cartActionCreators.deleteAll()),
  clearDogs: bindActionCreators(dogsActionCreators.clearDogs, dispatch),
});

const PaymentWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectStripe(CheckoutPaymentPage)));

export default ({ props }) => (
  <Elements>
    <PaymentWrapped {...props} />
  </Elements>
);
