import React from 'react';
import CheckboxInput from '../components/CheckboxInput';

export default class StayInTouchVariant extends React.Component {
  render() {
    return (
      <div className={`col-xl-11 col-lg-12 col-md-12 col-sm-12 col-12 row pt-4 pb-2 px-xl-3 px-lg-2 px-md-2 px-sm-0 px-0 animated fadeInUp ${this.props.animation}`}>
        {/* <div className="col-1 row align-items-center variant-checkbox">
          {this.props.active
                      ?
                        <CheckboxInput
                          color="red"
                          active={this.props.active}
                          onClick={this.props.onClick}
                        />
                      :
                      ''
                  }

        </div> */}
        <div className="col-12 ">
          <div
            className={`stay-in-touch-variant p-3 ${this.props.active ? 'active' : ''}`}
            onClick={this.props.onClick}
          >
            <h5
              className="text-uppercase variant-title text-left"
            >
              {this.props.title}
            </h5>
            <p className="variant-description text-left">
              {this.props.description}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
