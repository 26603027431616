import React from 'react';

const UncheckedIcon = () => (
  <svg className="unchecked-icon" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Step-3:-Payment" transform="translate(-331.000000, -618.000000)" fill="#CDE5DD" fillRule="nonzero">
        <g id="Group-3-Copy" transform="translate(86.000000, 546.000000)">
          <g id="Default" transform="translate(20.000000, 69.000000)">
            <g id="icon-/-check-box-copy-7" transform="translate(225.000000, 3.000000)">
              <g id="Group-6">
                <path className="check-border" d="M13.2222222,14.2222222 L2.77777778,14.2222222 C2.22549303,14.2222222 1.77777778,13.774507 1.77777778,13.2222222 L1.77777778,2.77777778 C1.77777778,2.22549303 2.22549303,1.77777778 2.77777778,1.77777778 L9.77777778,1.77777778 C10.2686976,1.77777778 10.6666667,1.37980867 10.6666667,0.888888889 L10.6666667,0.888888889 C10.6666667,0.397969111 10.2686976,1.35310943e-15 9.77777778,1.44328993e-15 L1.77777778,0 C0.8,0 0,0.8 0,1.77777778 L0,14.2222222 C0,15.2 0.8,16 1.77777778,16 L14.2222222,16 C15.2,16 16,15.2 16,14.2222222 L16,8 C16,7.50908022 15.6020309,7.11111111 15.1111111,7.11111111 L15.1111111,7.11111111 C14.6201913,7.11111111 14.2222222,7.50908022 14.2222222,8 L14.2222222,13.2222222 C14.2222222,13.774507 13.774507,14.2222222 13.2222222,14.2222222 Z" id="Path" />
                <path className="check-border" d="M13.2222222,14.2222222 L2.77777778,14.2222222 C2.22549303,14.2222222 1.77777778,13.774507 1.77777778,13.2222222 L1.77777778,2.77777778 C1.77777778,2.22549303 2.22549303,1.77777778 2.77777778,1.77777778 L9.77777778,1.77777778 C10.2686976,1.77777778 10.6666667,1.37980867 10.6666667,0.888888889 L10.6666667,0.888888889 C10.6666667,0.397969111 10.2686976,1.35310943e-15 9.77777778,1.44328993e-15 L1.77777778,0 C0.8,0 0,0.8 0,1.77777778 L0,14.2222222 C0,15.2 0.8,16 1.77777778,16 L14.2222222,16 C15.2,16 16,15.2 16,14.2222222 L16,8 C16,7.50908022 15.6020309,7.11111111 15.1111111,7.11111111 L15.1111111,7.11111111 C14.6201913,7.11111111 14.2222222,7.50908022 14.2222222,8 L14.2222222,13.2222222 C14.2222222,13.774507 13.774507,14.2222222 13.2222222,14.2222222 Z" id="Path-Copy" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default UncheckedIcon;
