import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actionTypes from '../../data/exports';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as selectors from "../../data/dogs/selectors";
import WizardLayout from '../../components/wizard/WizardLayout';
import Review from '../../components/wizard/Review';
import IngredientOption from '../../components/wizard/IngredientOption';
import Button from '../../components/Button';
import PopUpIcon from '../../assets/images/wizard/health-option-icon.png';
import Tip from '../../components/wizard/Tip';
import Diet from '../../assets/images/wizard/tips/diet.svg';

// ingredietns icons
import Chicken from '../../assets/images/wizard/ingredients/chiken.png';
import Lamb from '../../assets/images/wizard/ingredients/lamb.png';
import Turkey from '../../assets/images/wizard/ingredients/turkey.png';
import Salmon from '../../assets/images/wizard/ingredients/salmon.png';

class Flavours extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flavours: [],
            isAll: false,
            isComplete: false,
            isError: false
        };

        this.addSelectedProperty = (...arr) => arr.map(item => item.selected = false);

        this.handleStep = (step, e) => {
            e.preventDefault();
            this.props.history.push(step);
        };


        this.toggleAllOption = (value) => {
            const dogIndex = this.props.getIndex;
            const isAllFlavours = this.state[value];
            let _flavours = this.props.currentDog.flavours;
            this.setState({[value]: !isAllFlavours});
            if (!isAllFlavours) {
                _flavours.map(item => item.selected = true)
            } else {
                _flavours.map(item => item.selected = false)
            }
            this.props.updateCurrentDog('flavours', dogIndex, [..._flavours]);
        };

        this.toggleIngredientOption = (id) => {
            const dogIndex = this.props.getIndex;
            let _flavours = this.props.currentDog.flavours;
            _flavours = _flavours
                .map(item => ({
                    ...item,
                    selected: item.ID === id ? !item.selected : item.selected
                }));
            this.props.updateCurrentDog('flavours', dogIndex, [..._flavours]);

            const selectedFlavours = this.props.currentDog.flavours.filter(item=>{
                return item.selected
            });

            this.setState({isAll: selectedFlavours.length === 4})

            if (selectedFlavours.length !== 0){
                this.setState({isError: false});
            }

        };

        this.handleModal = (modal) => {
            $(`#${modal}`).modal('show');
        };
    }

    async componentDidMount() {
        const dogIndex = this.props.getIndex;
        this.props.updateCurrentDog('progress', dogIndex, 100);
        if(this.props.currentDog.flavours.length === 0){
            await dogsActionCreators.getFlavours()
                .then((res) => {
                    res.map((item) => {
                        switch (item.name) {
                            case 'Lamb':
                                item.icon = Lamb;
                                break;
                            case 'Chicken':
                                item.icon = Chicken;
                                break;
                            case 'Turkey':
                                item.icon = Turkey;
                                break;
                            case 'Salmon':
                                item.icon = Salmon;
                                break;
                        }
                        this.addSelectedProperty(...res);
                        this.props.updateCurrentDog('flavours', dogIndex, [...res]);
                    });
                }).catch(err => console.log(err));
        }

        const selectedFlavours = this.props.currentDog.flavours.every(item => item.selected);
        this.setState({isAll: selectedFlavours});

        const {pathname} = this.props.location;
        this.props.updateCurrentDog('step', dogIndex, pathname);
        this.props.toggleDogNav(true);

        const completedDog = this.props.storeDogs.some(dog => dog.progress === 100);
        this.setState({isComplete: completedDog })
    }

    render() {
        const flavourList = !this.props.currentDog ? null : this.props.currentDog.flavours.map(item => (
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 px-1 my-1" key={item.ID}>
                <IngredientOption
                    isIcon
                    key={item.ID}
                    icon={item.icon}
                    label={item.name}
                    value={item.ID}
                    active={item.selected}
                    toggle={value => this.toggleIngredientOption(value)}
                />
            </div>
        ));

        return (
            <WizardLayout>
                <div className="col-12 row justify-content-center">
                    <div className="col-12 row justify-content-center weight-from px-0">
                        <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                            What are {this.props.currentDog.name}'s favourite flavours?
                            <span className="pop-up-link" onClick={()=>this.handleModal('flavours')}>
                                <a><img className="ml-2 mb-2" src={PopUpIcon}/></a>
                            </span>
                        </h5>
                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12 row justify-content-center py-4 px-xl-4 px-lg-2 px-md-0 px-0">
                            {flavourList}
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 px-1 my-1">
                                <IngredientOption
                                    isIcon={false}
                                    key="allFlavours"
                                    label="All"
                                    value="isAll"
                                    active={this.state.isAll}
                                    toggle={value => this.toggleAllOption(value)}
                                />
                            </div>
                        </div>
                        <div style={{maxWidth:'390px'}} className="col-xl-7 col-lg-7 col-md-8 col-sm-10 col-12 px-xl-5 px-lg-4 px-md-4 py-4 row justify-content-center">

                            {this.state.isError
                                ?
                                <div className="animated fadeIn col-12 py-2 text-center error-message">
                                    Please select at least one option.
                                </div>
                                :
                                ''
                            }

                            <div className="col-6 text-center pl-0">
                                <Button
                                    onClick={e => this.handleStep('/titbits', e)}
                                    classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                                    label="BACK"
                                />
                            </div>
                            <div className="col-6 text-center pr-0">
                                <button
                                    onClick={() => this.handleModal('review')}
                                    className={"button-primary fill orange animated fadeInRight delay-_2s"}
                                    // data-toggle="modal"
                                    // data-target="#review"
                                >
                                    REVIEW
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <Review
                    isError={!this.state.isComplete}
                />
                <Tip
                    title=""
                    description={`Like humans, our four-legged friends can be very picky when it comes to food! We want to cater to each and every one of their desires especially when it comes to their protein and meats. Protein is the most essential nutrient for dogs and as such we only offer single source protein with at least 21% meat content. All of this and we can guarantee we source responsibly from farmers in the UK and EU.`}
                    target="flavours"
                    isImage={false}
                    isButton={false}
                    btnLabel=""
                >
                    <div className="col-12 text-center">
                        <img src={Diet} alt=""/>
                    </div>
                </Tip>
            </WizardLayout>
        );
    }
}

const mapStateToProps = state => ({
    storeDogs: state.data.dogs.storeDogs,
    currentDog: state.data.dogs.currentDog,
    getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
    updateCurrentDog: (key, index, value) =>
        dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
    toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Flavours));
