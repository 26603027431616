import React from 'react';
import { Grid, FormControlLabel, RadioGroup, Checkbox, MenuItem } from '@material-ui/core';
import Textfield from '../../Textfield';
import UncheckedIcon from '../../icons/UncheckedIcon';
import CheckedIcon from '../../icons/CheckedIcon';
import countries from '../../../data/countries';

const BillingAddress = ({
  addressOptions,
  selectedAddressOption,
  addressOptionChange,
  addressFieldsDisabled,
  handleChange,
  billingAddress,
  isSubmitted,
  isSameAddress,
}) => (
  <Grid container className="border-element">
    <Grid container className="border-bottom">
      <h3 className="header-main">Billing Address</h3>
    </Grid>
    <Grid container className="border-body">
      <Grid item xs={12} className="address-option">
        <RadioGroup
          row
          name="address-option"
          value={selectedAddressOption}
          onChange={addressOptionChange}
        >
          <FormControlLabel
            value={addressOptions.SAME}
            control={
              <Checkbox color="secondary" checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />} />
            }
            className="mr-md-5"
            label="Same as Delivery Address"
          />
          <FormControlLabel
            value={addressOptions.DIFFERENT}
            control={
              <Checkbox color="secondary" checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />} />
            }
            label="Different as Delivery Address"
          />
        </RadioGroup>
      </Grid>
      <Grid item lg={6} xs={12} className="address-column">
        <Grid container>
          <Grid item xs={12}>
            <Textfield
              name="address-1"
              label="Address Line 1"
              margin="normal"
              fullWidth
              disabled={addressFieldsDisabled}
              value={billingAddress.streetAddressLine1}
              onChange={handleChange('streetAddressLine1')}
              error={isSubmitted && !isSameAddress && billingAddress.streetAddressLine1 === ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              name="address-2"
              label="Address Line 2"
              margin="normal"
              fullWidth
              disabled={addressFieldsDisabled}
              value={billingAddress.streetAddressLine2}
              onChange={handleChange('streetAddressLine2')}
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              name="city"
              label="City"
              margin="normal"
              fullWidth
              disabled={addressFieldsDisabled}
              value={billingAddress.city}
              onChange={handleChange('city')}
              error={isSubmitted && !isSameAddress && billingAddress.city === ''}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} xs={12} className="address-column">
        <Grid container>
          <Grid item xs={12}>
            <Textfield
              name="county"
              label="County"
              margin="normal"
              fullWidth
              disabled={addressFieldsDisabled}
              value={billingAddress.county}
              onChange={handleChange('county')}
              error={isSubmitted && !isSameAddress && billingAddress.county === ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              select
              name="country"
              label="Country"
              margin="normal"
              fullWidth
              disabled={addressFieldsDisabled}
              value={billingAddress.country}
              onChange={handleChange('country')}
              error={isSubmitted && !isSameAddress && billingAddress.country === ''}
            >
              {countries.map(item => (
                <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
              ))}
            </Textfield>
          </Grid>
          <Grid item xs={12}>
            <Textfield
              name="postcode"
              label="Postcode"
              margin="normal"
              fullWidth
              disabled={addressFieldsDisabled}
              value={billingAddress.zipPostalCode}
              onChange={handleChange('zipPostalCode')}
              error={isSubmitted && !isSameAddress && billingAddress.zipPostalCode === ''}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default BillingAddress;
