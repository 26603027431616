import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actionTypes from '../../data/exports';
import * as dogsActionCreators from '../../data/dogs/actions';
import WizardLayout from '../../components/wizard/WizardLayout';
import IngredientOption from '../../components/wizard/IngredientOption';
import Button from '../../components/Button';
import * as selectors from "../../data/dogs/selectors";
import IngredientsTip from '../../components/wizard/IngredientsTip';
import PopUpIcon from '../../assets/images/wizard/health-option-icon.png';

// ingredietns icons
import Beef from '../../assets/images/wizard/ingredients/beef.png';
import Chicken from '../../assets/images/wizard/ingredients/chiken.png';
import Barley from '../../assets/images/wizard/ingredients/barley.png';
import Pork from '../../assets/images/wizard/ingredients/pork.png';
import Rice from '../../assets/images/wizard/ingredients/rice.png';
import Lamb from '../../assets/images/wizard/ingredients/lamb.png';
import Wheat from '../../assets/images/wizard/ingredients/wheat.png';
import Turkey from '../../assets/images/wizard/ingredients/turkey.png';
import Maize from '../../assets/images/wizard/ingredients/maize.png';
import Dairy from '../../assets/images/wizard/ingredients/milk.png';
import Rabbit from '../../assets/images/wizard/ingredients/rabbit.png';
import Salmon from '../../assets/images/wizard/ingredients/salmon.png';

import { fetchApi } from '../../services/api';

class Ingredients extends React.Component {
    constructor(props) {
        super(props);

        // diets and allergens get fetched from qor on mount
        this.state = {
            diets: [],
            allergens: [],
            isError: false,
        };

        this.handleStep = (step, e) => {
            e.preventDefault();
            this.setState({isError: false})
            //This searches instead of using their exact ids for futureproofing (in case ids change)
            let pancreatitis = this.props.currentDog.health_issues.filter(item => item.value === "pancreatitis");
            let pancreatitisSelected = pancreatitis.length > 0 ? pancreatitis[0].selected : false;
            let allergenArray = Object.keys(this.props.currentDog.allergens).map(id => this.props.currentDog.allergens[id]);
            let chickenSelected = allergenArray.filter(item => item.name === "Chicken").length > 0;
            if (chickenSelected && pancreatitisSelected &&  step  === '/food-preferences'){
                this.setState({isError: true});
            }
            else {
                this.props.history.push(step);
            }
        };

        this.handleModal = (modal) => {
            $(`#${modal}`).modal('show');
        };
    }

    componentDidMount() {
        const dogIndex = this.props.getIndex;
        const {pathname} = this.props.location;

        // fetch state data from qor
        fetchApi("/dogs/diets")
            .then( qorDiets => this.setState({diets: qorDiets}) );
        fetchApi("/dogs/allergens")
            .then( qorAllergens => this.setState({allergens: qorAllergens}) );

        if(this.props.currentDog.progress < 80){
            this.props.updateCurrentDog('progress', dogIndex, 80);
        }
    }

    render() {
        let dogIndex = this.props.getIndex;

        return (
            <WizardLayout>
                <div className="col-12 row justify-content-center">
                    <div className="col-12 row justify-content-center weight-from px-0">
                        <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                            Any dietary preferences?
                            <span className="pop-up-link" onClick={()=>this.handleModal('ingredients')}>
                                <a><img className="ml-2 mb-2" src={PopUpIcon}/></a>
                            </span>
                        </h5>
                        <div className="col-12 row justify-content-center pb-5">
                            {this.state.diets.map( diet => 

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 px-2 my-1" key={diet.ID}>
                                    <IngredientOption
                                        isIcon={false}
                                        label={diet.name}
                                        value={diet.ID}
                                        active={this.props.currentDog.dietary_preferences === diet.ID}
                                        toggle={(id) => this.props.updateCurrentDog("dietary_preferences", dogIndex, id)}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="col-12 row justify-content-center py-4 px-0">
                            <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                                Choose ingredients you’d like to leave out:
                            </h5>
                            {this.state.allergens.map( allergen => {
                                // checks if image has been uploaded for the allergen on qor
                                let iconUrl = allergen.image.Files !== undefined ? 
                                    allergen.image.Files[0].Url :
                                    ""; 

                                let currentAllergens = this.props.currentDog.allergens;

                                let isDogAllergicToIt = 
                                    // the keys are IDs of the allergens (todo: currentDog.allergens should be a list really)
                                    Object.keys(currentAllergens).find(id => parseInt(id) === allergen.ID);
                                
                                
                                return <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 px-1 my-1" key={allergen.ID}>
                                        <IngredientOption
                                            isIcon={iconUrl !== ""}
                                            icon={iconUrl}
                                            label={allergen.name}
                                            active={isDogAllergicToIt}
                                            toggle={() => {
                                                if (isDogAllergicToIt) {
                                                    delete currentAllergens[allergen.ID];
                                                } else {
                                                    currentAllergens[allergen.ID] = allergen;
                                                }
                                                this.props.updateCurrentDog("allergens", dogIndex, currentAllergens);
                                            }}
                                        />
                                    </div>;
                            })}
                        </div>
                        {this.state.isError
                            ?
                            <div className="animated fadeIn col-12 pt-5 text-center error-message">
                                We can't currently provide a bespoke blend for dogs with Pancreatitis and Chicken left out. Sorry!
                            </div>
                            :
                            ''
                        }
                        <div style={{maxWidth:'390px'}} className="col-xl-7 col-lg-7 col-md-7 col-sm-10 col-12 px-xl-5 px-lg-5 px-md-4 px-sm-3 px-1 py-4 row justify-content-center">
                            <div className="col-6 text-center pl-0">
                                <Button
                                    onClick={e => this.handleStep('/allergy', e)}
                                    classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                                    label="BACK"
                                />
                            </div>
                            <div className="col-6 text-center pr-0">
                                <Button
                                    onClick={e => this.handleStep('/food-preferences', e)}
                                    classes="button-primary fill orange animated fadeInRight delay-_2s"
                                    label="NEXT"
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <IngredientsTip/>
            </WizardLayout>
        );
    }
}

const mapStateToProps = state => ({
    storeDogs: state.data.dogs.storeDogs,
    currentDog: state.data.dogs.currentDog,
    getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
    updateCurrentDog: (key, index, value) =>
        dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
    toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ingredients));
