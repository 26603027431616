import React from 'react';

export default class OpenSansHeading extends React.Component {
    render() {
        const { title } = this.props;
        return (
            <div className="heading_div">
                <img className="heading_icon" src="../assets/images/dashboard-nav-icon.png" alt="" />
                <p className="opensans_heading">{title}</p>
            </div>
        )
    }
}
