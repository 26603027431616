import * as actionTypes from './actionTypes';

export const initialState = {
  api_token: null,
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      return {
        ...state,
        ...action.session,
      };
    default:
      return state;
  }
};
