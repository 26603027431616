import { store } from '../../store';

import * as api from './api';
import * as actionCreators from './actions';

const clearSession = () => {
  store.dispatch({
    type: 'USER_LOGOUT',
  });
};

const onRequestSuccess = (response) => {
  // Update
  const session = {};

  if (response.token) {
    session.api_token = response.token;
  }

  if (response.user) {
    session.user = response.user;
  }

  store.dispatch(actionCreators.update(session));
};

const onRequestFailed = (exception) => {
  clearSession();
  throw exception;
};

export const authenticate = email =>
  api.authenticate(email)
    .then(onRequestSuccess)
    .catch(onRequestFailed);

export const register = user => new Promise((resolve, reject) => {
  api.register(user)
    .then((response) => {
      onRequestSuccess(response);
      resolve(response);
    }, (response) => {
      response.json().then((resp) => {
        reject(resp);
      });
    });
});

export const revoke = () => {
  clearSession();
};

export const isLoggedIn = () => store.getState().services.session.api_token !== null;

/**
 * Send request to send the reset password email
 */
export const forgottenPassword = ({ email }) => new Promise((resolve, reject) => {
  api.forgotten({ email })
    .then(() => resolve())
    .catch(() => reject());
});

export const contactEmail =  userMessage  => new Promise((resolve, reject) => {
  api.contact( userMessage )
    .then(() => resolve())
    .catch(() => reject());
});

/**
 * Send request to reset password
 */
export const resetPassword = ({ code, password }) => new Promise((resolve, reject) => {
  api.reset({ code, password })
    .then((resp) => resolve(resp))
    .catch(() => reject());
});

/**
 * Send request to reset cruft user password
 */
export const resetPasswordCruft = ({ user_id: ID, password }) => new Promise((resolve, reject) => {
  api.resetCruft({ user_id: ID, password })
    .then(() => resolve())
    .catch(() => reject());
});

/**
 * Pass a limited set of user fields, and it'll just update those fields
 */
export const updateUser = user => (
  new Promise((resolve, reject) => {
    api.update(user)
      .then((response) => {
        onRequestSuccess(response);
        resolve(response);
      })
      .catch(err => reject(err));
  })
);

export const getUserInfo = () => (
  new Promise((resolve, reject) => {
    api.userInfo()
      .then((response) => {
        onRequestSuccess(response);
        resolve(response);
      })
      .catch(err => reject(err));
  })
);