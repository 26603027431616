import React from 'react';

import Layout from '../components/Layout';
import MainImageBanner from '../components/MainImageBanner';
import KausanScriptHeading from '../components/KaushanScriptHeading';
import GreyText from '../components/GreyText';

export default class PageNotFound extends React.PureComponent {
	render() {
		return (
			<Layout>
				<MainImageBanner
					image="url(/assets/images/dog-in-field.jpg)"
					heading="404"
					subtext="Page not found"
				/>
				<div className="inner-container">
					<KausanScriptHeading title="This page was not found" />
					<GreyText
						text="Click the following link to go back to the home page or use the navigation menu above."
						fontSize={18}
						centered
					/>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<a className="standard-link orange-text" href="/">Home</a>
					</div>
				</div>
			</Layout>
		)
	}
}