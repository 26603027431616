import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid/Grid';

import CheckoutLayout from '../CheckoutLayout';
import DeliveryForm from './DeliveryForm';
import DeliveryNote from './DeliveryNote';
import ContainedButton from '../../ContainedButton';
import OutlinedButton from '../../OutlinedButton';

import * as deliveryActionCreators from '../../../data/checkout/actions';
import * as sessionSelectors from '../../../services/session/selectors';
import * as session from '../../../services/session';

import { getCartItemsByDog } from '../../../data/cart/utils';

const BackToCartLink = props => <Link to="/cart" {...props} />;

class CheckoutDeliveryDetailsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitted: false,
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      note: '',
      deliveryMethod: '',
      deliveryDate: '',
      streetAddressLine1: '',
      streetAddressLine2: '',
      city: '',
      zipPostalCode: '',
      county: '',
      country: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    session.getUserInfo()
      .then(() => {
        this.generateDefaultDeliveryData();
      })
  }

  generateDefaultDeliveryData = () => {
    const { user, delivery } = this.props;

    console.log('user', user, delivery)
    this.setState({
      firstName: user.first_name || '',
      lastName: user.surname || '',
      phone: user.mobile_number || '',
      note: user.delivery_instructions || '',
      deliveryMethod: delivery.deliveryMethod || '',
      deliveryDate: delivery.deliveryDate || '',
    });

    const sessionData = user.delivery_address || {};

    this.setState({
      streetAddressLine1: sessionData.address1 || '',
      streetAddressLine2: sessionData.address2 || '',
      company: sessionData.company || '',
      city: sessionData.town,
      zipPostalCode: sessionData.postcode || '',
      county: sessionData.county || '',
      country: 'GB',
    });

    let defaultData;
    return defaultData;
  };

  handleChange = field => (event) => {
    if (field === 'deliveryDate') {
      const year = event.target.value.split('-')[0];
      if (year.length !== 4) {
        return;
      }
    }
    this.setState({
      [field]: event.target.value,
    });
  };

  deliveryChange(date) {
    this.setState({ deliveryDate: date })
  }

  handleSubmit = (event) => {
    this.setState({ isSubmitted: true });

    if (!this.state.firstName || !this.state.lastName ||
      !this.state.streetAddressLine1 || !this.state.zipPostalCode) {
      event.preventDefault();
    } else {
      session.updateUser({
        first_name: this.state.firstName,
        surname: this.state.lastName,
        mobile_number: this.state.phone,
        delivery_instructions: this.state.note,
        delivery_address: {
          company: this.state.company,
          address1: this.state.streetAddressLine1,
          address2: this.state.streetAddressLine2,
          country: this.state.country,
          county: this.state.county,
          postcode: this.state.zipPostalCode,
          town: this.state.city,
        },
      })
        .then((resp) => {
          if (resp.success) {
            this.props.deliveryUpdate({
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              company: this.state.company,
              phone: this.state.phone,
              streetAddressLine1: this.state.streetAddressLine1,
              streetAddressLine2: this.state.streetAddressLine2,
              city: this.state.city,
              zipPostalCode: this.state.zipPostalCode,
              county: this.state.county,
              country: this.state.country,
              note: this.state.note,
              deliveryMethod: this.state.deliveryMethod,
              deliveryDate: this.state.deliveryDate,
            });
            this.props.history.push('/checkout/payment');
          }
          else {
            alert('An error occured while saving your delivery details: ' + resp.errors[0]);
          }
        })
    }
  };

  render() {
    const {
      items,
      dog
    } = getCartItemsByDog(this.props.dogs, this.props.products, this.props.cart);

    return (
      <CheckoutLayout
        step={2}
        title="Delivery & Details"
        id="delivery-details-page"
        classes="checkout-delivery-content"
        submitSummary={this.handleSubmit}
        productCount={items.length}
      >
        <Grid container>
          <Grid item className="delivery-address" xs={12} md={6}>
            <Grid container className="h-100">
              <DeliveryForm
                handleChange={this.handleChange}
                isSubmitted={this.state.isSubmitted}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                company={this.state.company}
                phone={this.state.phone}
                streetAddressLine1={this.state.streetAddressLine1}
                streetAddressLine2={this.state.streetAddressLine2}
                city={this.state.city}
                zipPostalCode={this.state.zipPostalCode}
                county={this.state.county}
                country={this.state.country}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item className="delivery-note" xs={12}>
                <DeliveryNote
                  note={this.state.note}
                  handleChange={this.handleChange('note')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="buttons-wrapper">
          <Grid item md="auto" xs={12}>
            <OutlinedButton title="Back to cart" component={BackToCartLink} />
          </Grid>
          <Grid item md="auto" xs={12}>
            <ContainedButton onClick={this.handleSubmit} title="Proceed to Checkout" />
          </Grid>
        </Grid>
      </CheckoutLayout>
    );
  }
}

const mapStateToProps = state => ({
  promocode: state.data.promocode,
  delivery: state.data.delivery,
  user: sessionSelectors.getUser(),
  dogs: state.data.dogs.dogs,
  products: state.data.products,
  cart: state.data.cart,
});

const mapDispatchToProps = dispatch => ({
  deliveryUpdate: delivery => dispatch(deliveryActionCreators.deliveryUpdate(delivery)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliveryDetailsPage));
