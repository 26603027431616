import React from 'react';

import CloseIcon from '../../assets/images/wizard/review-close.png';
import SadDog from '../../assets/images/sad-dog.png';
import Comeback from '../../assets/images/come-back.png';

class Confirm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className="modal animated fadeIn confirm-modal"
        id="confirm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered row justify-content-center"
          role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                We're sad to see you go :(
              </h5>
              <button
                type="button"
                className="close text-right"
                onClick={this.props.cancelStep}
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">
                  <img src={CloseIcon} alt="" />
                </span>
              </button>
            </div>

            <div className="modal-body">
              <div className="description-area">
                <h5 className="title" id="exampleModalLabel">
                  We're sad to see you go :(
                </h5>
                <div className="description">
                  You have successfully cancelled your subscription which will
                  take effect immediately. If you have an order in transit, this
                  will be your last order.
                </div>
                <div className="btn-area">
                  <button
                    className="reactivate-btn"
                    onClick={this.props.reactivate}>
                    Reactivate
                  </button>
                </div>
              </div>
              <div className="img-area">
                <div className="welcome">
                  <img src={Comeback} alt="comeback"></img>
                </div>
                <div className="sad">
                  <img src={SadDog} alt="sad"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Confirm;
