import React from 'react';
import CheckboxInput from '../../components/CheckboxInput';

export default class BodyConditionOption extends React.Component {
  render() {
    const {
      image, bottomImage, inputLabel, fat, descriptions, animation, active, onClick,
    } = this.props;
    return (
      <div className={`variant-container body-condition position-relative text-center ${inputLabel} animated ${animation} ${active ? 'active' : ''}`} onClick={this.props.onClick}>
        <div className="unknown-variant_checkbox pt-3">
          <CheckboxInput
            color="orange"
            active
            onClick={onClick}
          />
          <span className="unknown-variant_label text-uppercase">{ inputLabel }</span>
        </div>
        <div className="unknown-variant_weight py-2">{fat} fat</div>
        <ul className="description-list">
          {descriptions.map(item => (
            <li className="description-list_item" key={item}>{item}</li>
              ))}
        </ul>
        <div className="image-container">
          <div>
            <img className="unknown-variant_image" src={image} alt="" />
          </div>
          <div>
            <img className="unknown-variant_image bottom" src={bottomImage} alt="" />
          </div>
        </div>
      </div>
    );
  }
}
