import React from 'react';

import Navigation from '../components/Navigation';
import MainImageBanner from '../components/MainImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';

const returnPolicyTerms = [
  {
    title: "Returns - Food, Treats and accessories",
    desc: [
      "If you are unhappy with your goods for a legitimate reason such as: missing or damaged contents, or the box did not arrive, we will offer a full refund as long as it can be shown the box product you were charged for was not provided as it should have been.",
      "It is important to Kasper and Kitty that you are satisfied with our products and services. If you are not satisfied or you wish to return a product, please see how to do so below:",
    ],
    steps: [
      "Email us at friends@kasperandkitty.com from the email address associated with your user account or send us a message directly from our website.",
      "Please include details of the product that you wish to return and a reason for return. Please do this within 30  days of receiving your product from us.",
      "If we require the goods to be returned to us, please take reasonable care of the goods whilst in your possession prior to any return. We will inspect the goods on arrival.",
      "We may request further information from you regarding your return.",
      "Customers are liable for return postage costs.",
      "If you are returning an item over £50, you should consider using a trackable returns service. We don’t guarantee that we will receive your returned item.",
      "Once your return is received and inspected, we will send you an email to notify you that we have received your returned item.",
      "Refunds will be processed, and a credit will automatically be applied to your original credit or debit card used for payment, within 10 days.",
      "If you haven’t received a refund yet, please contact us at friends@kasperandkitty.com.",
      "Only full priced items may be refunded, unfortunately Offers, Discounts and Sale items cannot be refunded.",
      "If you are a consumer, you will always have legal rights in relation to Products that are faulty or not as described. These legal rights and your right to cancel the Contract are not affected by the Returns Policy in this Claus  or these Terms. Advice about your legal rights is available from your local Citizens' Advice Bureau or Trading Standards office. Please note bespoke food is not included in Your Right to Cancel.",
    ],
  },
  {
    title: "Returns Policy – FitKolar",
    desc: [
      "Your FitKolar purchase offers a one-year limited warranty valid from the date of purchase.",
      "If you are unhappy with your goods for a legitimate reason such as: missing or damaged contents, or the box did not arrive, we will offer a full refund as long as it can be shown the box product you were charged for was not provided as it should have been.",
      "It is important to Kasper and Kitty that you are satisfied with our products and services. If you are not satisfied or you wish to return a product, please see how to do so below:",
    ],
    steps: [
      "Email us at friends@kasperandkitty.com from the email address associated with your user account or send us a message directly from our website.",
      "Please include details of the product that you wish to return and a reason for return.",
      "If we require the goods to be returned to us, please take reasonable care of the goods whilst in your possession prior to any return. We will inspect the goods on arrival.",
      "We may request further information from you regarding your return.",
      "Customers are liable for return postage costs.",
      "All defects which arise from faults in material or functionality are fixed free of charge. The warranty does not apply to consumable parts, such as batteries, clips, collars or leads sold by Kasper & Kitty or cover damage caused by accident, abuse, misuse or any similar cause.",
      "Kasper & Kitty Limited does not warrant that the operation of the FitKolar will be uninterrupted or error-free.",
      "The Limited Warranty is not applicable to: (i) normal wear and tear; (ii) defects or damage caused by misuse, failure to follow instructions, accident, neglect, abuse, alteration, unusual stress, modification, improper or unauthorised repair, improper storage, water if the product has been opened, use with unsuitable devices, software or services; (iii) use for medical, healthcare or treatment purposes; (iv) software and services provided by Kasper & Kitty to owners of the FitKolar; (v) loss and security of data; (vi) used or resold FitKolars",
      "Any attempt to open or take apart the device will void the warranty.",
      "If you are returning an item over £50, you should consider using a trackable returns service. We don’t guarantee that we will receive your returned item.",
      "Once your return is received and inspected, we will send you an email to notify you that we have received your returned item.",
      "Refunds will be processed, and a credit will automatically be applied to your original credit or debit card used for payment, within 10 days.",
      "If you haven’t received a refund yet, please contact us at friends@kasperandkitty.com.",
      "Only full priced items may be refunded, unfortunately Offers, Discounts and Sale items cannot be refunded.",
      "If you are a consumer, you will always have legal rights in relation to Products that are faulty or not as described. These legal rights and your right to cancel the Contract under Clause 10 are not affected by the Returns Policy in this Clause 11 or these Terms. Advice about your legal rights is available from your local Citizens' Advice Bureau or Trading Standards office. Please note bespoke food is not included in Your Right to Cancel.",
    ],
  },
  {
    title: "Right to Cancel",
    desc: [
      "All customers have the right to cancel their orders under the Distance Selling Regulations which gives consumers extra protection when buying online. Specific legislation here that applies is regulation of The Distance Selling Regulations.",
      "You have the right to cancel the purchase of a good without having to give a reason at any time within the 'cooling off period' of seven working days, beginning on the day after you receive the goods.",
      "If you are in possession of the goods you are under the duty to retain them and take reasonable care of them. You must send the goods back to us to our contact address at your own cost (unless we delivered the item to you in error or the item is damaged or defective) as soon as possible once you have cancelled the contract.",
      "We reserve the right to make a charge not exceeding our direct costs of recovering the goods if you do not return the goods or return them at our expense.",
      "Once you have notified us that you wish to cancel the contract, any sum debited to us will be refunded to you as soon as possible and in any event within 30 days of your cancellation.",
      "You will not have any right to cancel a purchase for the supply of any of the following goods:",
    ],
    steps: [
      "Bespoke Food created specifically for your dog(s)",
    ],
  },
  {
    title: "Cancellation by us",
    desc: [
      "We reserve the right to cancel the contract between us if:",
      "we have insufficient stock to deliver the goods you have ordered;",
      "we do not deliver to your area;",
      "one or more of the goods you ordered was listed at an incorrect price due to a typographical error or an error in the pricing information received by us from our suppliers; or",
      "we consider you in breach of our terms and conditions;",
      "If we do cancel your contract, we will notify you by e-mail and will refund charges for any outstanding orders as soon as possible but in any event within 30 days of your order.",
    ],
    steps: [
    ],
  },
];

export default class HealthIssues extends React.Component {
  componentDidMount() {
    scrollTo(0,0);
  }

  render() {
    return (
      <div className="container">
        <Navigation
          absolute
          white
          textColor="white-text"
        />
        <MainImageBanner
          image="url(/assets/images/healthissues.jpeg)"
          heading="Delivery and return"
          subtext=""
        />
        <div className="terms-container">
          {returnPolicyTerms.map((term, tindex) => (<div key={tindex} className="heading">{`\u00A0\u00A0${term.title}`}
            {term.desc.map((description, dindex) => (<div key={dindex} className="desc">
              <div className="privacy-desc">{`\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${description}`}</div>
            </div>))}
            {term.steps.map((content, cindex) => (<div key={cindex} className="desc">
              <div className="desc-content">{`\u2022\u00A0\u00A0\u00A0\u00A0${content}`}</div>
            </div>))}
          </div>))}
        </div>
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
