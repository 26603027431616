import React from 'react';

import { Link } from 'react-router-dom';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import SimplyPowesome from '../components/SimplyPowesome';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';
import DentalCard from '../components/DentalCard';
import SgSection from '../components/SgSectoin';

const wetfoods = [
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Grain Free Duck Tray',
      text: 'Training treats for younger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Grain Free Lamb Tray',
      text: 'Training treats for younger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Cereal Free Chicken',
      text: 'Training treats for younger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Cereal Free White Fish',
      text: 'Training treats for younger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Turkey Cereal Free Weight Control',
      text: 'Training treats for younger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Lamb Cereal Free Weight Control',
      text: 'Training treats for younger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Duck Cereal Free Weight Control',
      text: 'Training treats for younger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Cereal Free Puppy',
      text: 'Training treats for younger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: '??????',
      text: 'Training treats for younger dogs',
    },
];

export default class MoreThanFood5 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { windowWidth: window.innerWidth };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/mtf5-banner.jpg)" heading="more than food" subtext="a diet at unique as your pet" />
        <div className="row cereal-free-container">
          <div className="col-md-2" />
          <div className="col-md-5">
            <h1>Cereal-free</h1>
            <h6 className="color-orange fw-bold lh-2 mb-4">Kasper &amp; Kitty's nutritious, cereal-free wet dog food recipes focus on your dog's nutritional needs.</h6>
            <p className="lh-2">Formulated for optimal digestion with 75% premium mono protein, and with choices including chicken and white fish, as well as vegetables, they help to supply your dog with the essential vitamins and minerals they need. We recommend slowly introducing the wet-food to your pet so their digestive system can adjust.</p>
          </div>
          <div className="col-md-2 my-auto">
            <img src="/assets/images/cereal-free.png" />
          </div>
          <div className="col-md-3" />
        </div>
        <div className="row grain-free-container">
          <div className="col-md-2" />
          <div className="col-md-2 my-auto">
            <img src="/assets/images/cereal-free.png" />
          </div>
          <div className="col-md-1" />
          <div className="col-md-5">
            <h1>Grain-free</h1>
            <h6 className="color-orange fw-bold lh-2 mb-4">Kasper &amp; Kitty's nutritious, grain-free wet dog food recipes are formulated with your dog's digestive health in mind.</h6>
            <p className="lh-2">Formulated from the highest quality ingredients and 75% mono proteins, with choices ranging from duck to lamb, they aim to deliver optimal nutrition for your dog including specially selected essential vitamins and minerals to promote digestive and overall canine health.</p>
          </div>
          <div className="col-md-2" />
        </div>
        <div className="inner-container">
          <div className="wetfood-container">
            <h1>Wet food</h1>
            <div className="row justify-content-center">
              {wetfoods.map((col, cindex) => (
                <div key={cindex} className="col-6 col-sm-4">
                  <DentalCard image={col.image} subj={col.subj} text={col.text} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <SgSection />
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
