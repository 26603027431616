import React from 'react';
import ReactLoading from 'react-loading';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Grid, Input, MenuItem } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { CardCVCElement, CardExpiryElement, CardNumberElement, Elements, injectStripe, } from 'react-stripe-elements';
import ReCAPTCHA from 'react-google-recaptcha';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import CheckoutSteps from '../components/checkout/CheckoutSteps';
import Textfield from '../components/Textfield';
import countries from '../data/countries';
import HowCVCTip from '../components/checkout/HowCVCTip';
import ContainedButton from '../components/ContainedButton';
import Promocode from '../components/checkout/payment/Promocode';
import InfoIcon from '../components/icons/InfoIcon';

import { createOrder, getIncompleteOrder } from '../data/orders/actions';
import * as ordersSelectors from '../data/orders/selectors';
import * as orderActionCreators from '../data/orders/actions';
import * as sessionSelectors from '../services/session/selectors';
import * as actionCreators from '../data/checkout/actions';
import * as productsActions from '../data/products/actions';
import * as dogsSelectors from '../data/dogs/selectors';
import * as session from '../services/session';
import * as api from '../data/orders/api';

import { fetchApi } from '../services/api';
import { PayHandler } from "../services/PayHandler";

class CheckoutToBuy extends React.Component {
  constructor(props) {
    super(props);

    // const storage = localStorage.getItem('productCart');

    this.state = {
      shop: this.props.incompleteOrder,
      deliveryAddressFieldsDisabled: true,
      deliveryAddress: props.user.delivery_address,
      billingAddressFieldsDisabled: true,
      billingAddress: props.user.billing_address,
      bankCardFieldDisabled: true,
      paymentLoading: false,
      promocode: props.promocode !== null ? props.promocode.code : '',
      promocodeValid: false,
      orderID: -1,
      orderPlaced: false,
      deliveryInstructions: props.user.delivery_instructions,
      deliveryFirstName: props.user.first_name,
      deliverySurname: props.user.surname,
      billingFirstName: props.user.first_name,
      billingSurname: props.user.surname,
      summaryBoxHeight: 0,
      summaryBoxMarginTop: 0,
      cardNumberCompleted: false,
      cardExpiryCompleted: false,
      cardCVCCompleted: false,
      nameOnCard: `${props.user.first_name} ${props.user.surname}`,
      recapToken: '',
      showFitkolarAddedMessage: false,
      deliveryCost: 3.95,
    };
  }

  componentDidMount() {
    session.getUserInfo();
    Sentry.setUser({ email: this.props.user.email });

    this.promocodeCheck(true);
    this.initBuyNowBoxHeight();

    document.addEventListener('scroll', this.trackScrolling);

    api.getShopDeliveryCost().then(res => this.setState({ deliveryCost: res.DeliveryCost }));
    this.payHandler = new PayHandler(this.props);
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.incompleteOrder !== prevProps.incompleteOrder) {
      this.setState({ shop: this.props.incompleteOrder ? this.props.incompleteOrder : [] });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('order-summary-box');
    const checkoutElement = document.getElementById('checkout-main');
    const widthCheckout = checkoutElement.offsetWidth;

    if (window.pageYOffset > wrappedElement.offsetTop) {
      wrappedElement.classList.add('order-summary-box-scroll');
      wrappedElement.setAttribute('style', `width: ${widthCheckout * 33.333 / 100}px`)
    } else {
      wrappedElement.classList.remove('order-summary-box-scroll');
      wrappedElement.setAttribute('style', `width: auto`)
    }
  };

  initBuyNowBoxHeight = () => {
    const summaryBox = document.querySelector('#order-summary-box');

    this.setState({
      summaryBoxMarginTop: 0,
      summaryBoxHeight: summaryBox.offsetTop,
    });
  };

  addFitkolarToCart = async (silentCheck = true) => {
    const { shop } = this.state;
    const checkExisted = shop.find(val => val.isFitkolar);

    if (checkExisted) return this.setState({ showFitkolarAddedMessage: true });

    let shouldAdd = true;

    if (!silentCheck) {
      shouldAdd = confirm('You can use this voucher to have 50% Fitkolar product discount, we added Fitkolar product to your cart. Press OK to continue or Cancel to remove the Fitkolar product');
    }

    if (!shouldAdd) return;

    const res = await productsActions.getProductFitkolar();

    if (res && res.ID) {
      shop.push({
        key: res.ID,
        product: res.name,
        quantity: 1,
        price: res.price,
        isFitkolar: true,
        imageUrl: res.image.Url,
      });

      localStorage.setItem('productCart', JSON.stringify(shop));

      this.setState({
        shop,
        showFitkolarAddedMessage: true,
      });

    }
  }

  promocodeCheck = (silentCheck = true) => {
    if (!this.state.promocode) {
      return;
    }

    api.promocode(this.state.promocode).then((response) => {
      if (response !== null) {
        if (response.used) {
          this.setState({ promocodeValid: false });
        } else {
          this.setState({ promocodeValid: true });
        }

        if (response.fitkolar_discount > 0) {
          this.addFitkolarToCart(silentCheck);
        }
        this.props.promocodeUpdate(response);
      } else {
        this.setState({ promocodeValid: false });
        this.props.promocodeUpdate(this.state.promocode);
      }
    });
  };

  isPromocodeValid = (promocode) => fetchApi(`/orders/promo/${promocode}`);

  handleChangePromocode = (field) => (event) => {
    this.isPromocodeValid(event.target.value)
      .then((r) => {
        if (r !== null) {
          this.setState({ promocodeValid: !r.used });
        } else {
          this.setState({ promocodeValid: false });
        }
      })
      .catch(() => this.setState({ promocodeValid: false }));

    this.setState({
      [field]: event.target.value,
    });
  };

  handleChangeDeliveryAddress = (field) => (event) => {
    this.setState({
      deliveryAddress: {
        ...this.state.deliveryAddress,
        [field]: event.target.value,
      },
    });
  };

  handleChangeBillingAddress = (field) => (event) => {
    this.setState({
      billingAddress: {
        ...this.state.billingAddress,
        [field]: event.target.value,
      },
    });
  };

  handleChange = (field) => (event) => {
    this.setState({
      [field]: event.target.value,
    });
  };

  payAndGoToUrl = (url) => (event) => {
    const {
      billingAddress,
      deliveryAddress,
      shop,
      deliveryInstructions,
      deliveryFirstName,
      deliverySurname,
      billingFirstName,
      billingSurname,
      cardNumberCompleted,
      cardExpiryCompleted,
      cardCVCCompleted,
      nameOnCard,
    } = this.state;

    this.setState({ paymentLoading: true });

    if (!this.state.orderPlaced) {
      if (
        billingAddress.address1 === '' ||
        billingAddress.town === '' ||
        billingAddress.county === '' ||
        billingAddress.country === '' ||
        billingAddress.country === '' ||
        deliveryAddress.address1 === '' ||
        deliveryAddress.town === '' ||
        deliveryAddress.county === '' ||
        deliveryAddress.country === '' ||
        deliveryAddress.country === '' ||
        billingFirstName === '' ||
        billingSurname === '' ||
        deliveryFirstName === '' ||
        deliverySurname === '' ||
        (cardNumberCompleted &&
          cardExpiryCompleted &&
          cardCVCCompleted &&
          nameOnCard === '')
      ) {
        this.setState({ paymentLoading: false });
        event.preventDefault();
      } else {
        const itemsWithNoFitkolar = shop.filter((item) => !item.isFitkolar);
        let fitkolarCount = 0;

        shop.forEach((item) => {
          if (item.isFitkolar) {
            fitkolarCount += 1;
          }
        });

        session.updateUser({
          delivery_instructions: deliveryInstructions,
          first_name: deliveryFirstName,
          surname: deliverySurname,
          delivery_address: {
            company: deliveryAddress.company,
            address1: deliveryAddress.address1,
            address2: deliveryAddress.address2,
            country: deliveryAddress.country,
            county: deliveryAddress.county,
            postcode: deliveryAddress.postcode,
            town: deliveryAddress.town,
          },
          billing_address: {
            company: billingAddress.company,
            address1: billingAddress.address1,
            address2: billingAddress.address2,
            country: billingAddress.country,
            county: billingAddress.county,
            postcode: billingAddress.postcode,
            town: billingAddress.town,
          },
        });

        // const order = {
        //   dog_id: this.props.dog.ID,
        //   delivery_instructions: deliveryInstructions,
        //   delivery_address: {
        //     company: deliveryAddress.company,
        //     address1: deliveryAddress.address1,
        //     address2: deliveryAddress.address2,
        //     country: deliveryAddress.country,
        //     county: deliveryAddress.county,
        //     postcode: deliveryAddress.postcode,
        //     town: deliveryAddress.town,
        //   },
        //   billing_address: {
        //     company: billingAddress.company,
        //     address1: billingAddress.address1,
        //     address2: billingAddress.address2,
        //     country: billingAddress.country,
        //     county: billingAddress.county,
        //     postcode: billingAddress.postcode,
        //     town: billingAddress.town,
        //   },
        //   order_items: itemsWithNoFitkolar,
        //   fitkolars: fitkolarCount,
        //   promotion_id: this.props.promocode ? this.props.promocode.code : '',
        //   delivery_method: '48hr delivery',
        //   // TODO: used here as indicator that it's a shop order, actual delivery cost may be different; fix
        //   delivery_cost: 3.95,
        // };

        this.pay(url);

        // api
        //   .ordernew(order)
        //   .then((orderResponse) => {
        //     if (orderResponse.success) {
        //       this.setState({
        //         orderID: orderResponse.order_id,
        //         orderPlaced: true,
        //       });
        //     } else {
        //       Sentry.captureException(new Error(orderResponse.errors));
        //       this.setState({
        //         orderPlaced: false,
        //         paymentLoading: false,
        //       });
        //     }
        //   })
        //   .catch((error) => {
        //     console.log(`error: ${error}`);
        //     window.alert('Order was not placed, please try again');
        //     Sentry.captureException(new Error(error));
        //     this.setState({
        //       orderPlaced: false,
        //       paymentLoading: false,
        //     });
        //   });
      }
    } else {
      this.pay(url);
    }
  };

  pay(url) {
    const orderID = this.props.incompleteOrder[0].order_id
    let payment_method_id = this.props.user.bank_card && this.props.user.bank_card.card_id;
    if (this.state.cardNumberCompleted) {
      payment_method_id = null;
    }
    const paymentInfo = {
      payment_method_id: payment_method_id,
      order_id: orderID,
      address_line1: this.state.billingAddress.address1,
      address_line2: this.state.billingAddress.address2,
      address_city: this.state.billingAddress.town,
      address_state: this.state.billingAddress.county,
      address_zip: this.state.billingAddress.postcode,
      address_country: this.state.billingAddress.country,
      recapcha_token: this.state.recapToken,
    };
    this.payHandler.charge(paymentInfo, url)
      .then(() => {
        this.props.promocodeUpdate({});
        // localStorage.removeItem('productCart');
        // api.deleteIncomplete();
        // Refresh user informaton
        session.updateUser({});
        this.props.history.push(`${url}/${orderID}`);
      })
      .catch((error) => {
        Sentry.captureException(error);
        this.setState({ paymentLoading: false });
        let message = '';
        if (error.message) {
          message = error.message;
        }
        alert(`An error occured while processing your payment: ${message}`);
      });
  }

  updateIncompleteOrder(list) {
    const incompleteOrder = {
      dog_id: this.props.dog.ID,
      order_items: list,
      order_id: this.props.incompleteOrder.length > 0 ? this.props.incompleteOrder[0].order_id : 0,
      status: 'Incomplete',
      delivery_cost: 3.95,
    };

    api.updateIncompleteOrder(incompleteOrder)
      .then(res => {
        if (!res.success && res.message === 'No incomplete orders for user') {
          api.ordernew(incompleteOrder)
            .then((newRes) => {
              this.props.updateIncompleteOrder(newRes.order)
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          this.props.updateIncompleteOrder(res.order)
        }
      })
      .catch(err => console.log('error trying to update incomplete order', err));
  }

  render() {
    const {
      shop,
      deliveryAddressFieldsDisabled,
      deliveryAddress,
      billingAddressFieldsDisabled,
      billingAddress,
      deliveryInstructions,
      deliveryFirstName,
      deliverySurname,
      billingFirstName,
      billingSurname,
      summaryBoxMarginTop,
      bankCardFieldDisabled,
      nameOnCard,
      deliveryCost,
    } = this.state;

    const { user, promocode } = this.props;

    let totalFitkolar = 0;
    let total = 0;
    let totalItems = 0;

    shop.forEach((item) => {
      total += item.price * item.quantity;
      if (item.isFitkolar) {
        totalFitkolar += item.price * item.quantity;
      } else {
        totalItems += item.price * item.quantity;
      }
    });

    let discountsMessage = '';
    let fitkolarDiscountsApplied = 0;
    let totalDiscountsApplied = 0;

    if (promocode !== null && shop.length > 0) {
      if (promocode.fitkolar_discount > 0) {
        fitkolarDiscountsApplied =
          totalFitkolar * (promocode.fitkolar_discount / 100);
      }

      if (promocode.total_discount > 0) {
        if (totalItems >= promocode.total_discount) {
          totalDiscountsApplied = promocode.total_discount;
          discountsMessage = '';
        } else {
          discountsMessage = `Please make sure your order is over £${promocode.total_discount} to use your redeemable code.`;
        }
      }
    }

    const discountsApplied =
      Math.round((fitkolarDiscountsApplied + totalDiscountsApplied) * 100) /
      100;
    const totalAfterDiscounts =
      Math.round((total - discountsApplied + deliveryCost) * 100) / 100;

    const deliveryDate = new Date();
    deliveryDate.setDate(deliveryDate.getDate() + 2);

    return (
      <div className='product-container container'>

        <Navigation white textColor='white-text' />

        <div className='inner-container'>
          <div className='checkout-nav'>
            <div className='back-to-shop'>
              <Link to='/shop'>Back to shop</Link>
            </div>

            <CheckoutSteps step='3' />
          </div>

          <div className='menu-title'>
            <img
              className='title-image'
              src='../assets/images/dashboard-nav-icon.png'
              alt='#'
            />
            CHECKOUT
          </div>

          <Grid container className='checkout-main' id="checkout-main">
            <Grid item xs={8} className='checkout-left'>
              <Grid
                item
                xs={12}
                className='mb-4 order-summary'
                style={{ marginTop: summaryBoxMarginTop, marginBottom: 0 }}
              >
                {shop.length > 0 && (
                  <Grid
                    container
                    alignItems='center'
                    justify='center'
                    className='mb-4'
                  >
                    {this.state.paymentLoading ? (
                      <Grid item md='auto' xs={12}>
                        <ReactLoading
                          type='spinningBubbles'
                          justifyContent='center'
                          color='grey'
                          height={45}
                          width={45}
                        />
                      </Grid>
                    ) : (
                        <Grid item xs={12}>
                          <div className='buy-now-box'>
                            <p className='buy-now-checkout'>
                              By placing your order you agree to{' '}
                              <a href='https://www.kasperandkitty.com/index.php/terms-conditions/'>
                                Terms & Conditions
                              </a>
                              . Please see our{' '}
                              <a href='https://www.kasperandkitty.com/index.php/privacy-policy/'>
                                Privacy Policy
                              </a>{' '}
                              and{' '}
                              <a href='https://www.kasperandkitty.com/index.php/cookie-policy/'>
                                Cookie Policy
                              </a>
                              .
                            </p>
                            <div className='buy-now-container'>
                              <ContainedButton
                                className='buy-now-button'
                                onClick={this.payAndGoToUrl('/order-confirmation')}
                                title='Buy Now'
                                disabled={this.state.recapToken === ''}
                              />
                              <div className='recap-button'>
                                <ReCAPTCHA
                                  sitekey='6LfNZekUAAAAADOMAInYTkY3SQAYzKxSt9MfLdKr'
                                  onChange={(token) => {
                                    this.setState({ recapToken: token });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Grid>
                      )}
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  className='checkout-order-summary border-element'
                >
                  <Grid className='header-main'>ORDER SUMMARY</Grid>

                  <Grid container className='border-top summary-details'>
                    <Grid item xs={12}>
                      <div className='price-details'>
                        <p className='price-text'>
                          <span className='price-title'>Items</span>
                          <span className='specific-price'>
                            £{Math.round(total * 100) / 100}
                          </span>
                        </p>

                        <p className='price-text'>
                          <span className='price-title'>Delivery</span>
                          <span className='specific-price'>
                            £{deliveryCost}
                          </span>
                        </p>

                        <p className='price-text'>
                          <span className='price-title'>Discounts applied</span>
                          <span className='specific-price'>
                            £{discountsApplied}
                          </span>
                        </p>

                        {discountsMessage.length > 0 && (
                          <p className='error-text'>
                            <span className='error-detail'>
                              {discountsMessage}
                            </span>
                          </p>
                        )}

                        <p className='price-text'>
                          <span className='price-title'>Total</span>
                          <span className='specific-price'>
                            £{totalAfterDiscounts}
                          </span>
                        </p>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className='price-details price-details-delivery'>
                        <p className='price-text delivery-date'>
                          <span className='price-title'>Delivery Date</span>
                          <span className='specific-price'>
                            {deliveryDate.toDateString()}
                          </span>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className='each-part review-order border-element'
              >
                <Grid className='header-main' style={{ padding: 0 }}>
                  1. REVIEW ORDER
                </Grid>

                {shop.map((item, index) => (
                  <Grid container className='border-top items-counts-price' key={index}>
                    <Grid container alignItems='center'>
                      <Grid item md={3} xs={4}>
                        <img
                          src={item.imageUrl}
                          className='item-image'
                          alt='item'
                        />
                      </Grid>

                      <Grid item md={9} xs={8}>
                        <div className='product-name'>{item.product}</div>

                        <div className='counts-price'>
                          <span className='items-price d-inline-block d-sm-none'>
                            £{item.price}
                          </span>

                          <div>
                            <div className='increment-decrease'>
                              <div
                                role='button'
                                onKeyPress={() => { }}
                                tabIndex='0'
                                className='item remove-item'
                                onClick={() => {
                                  const list = shop;
                                  list[index].quantity -= 1;
                                  if (list[index].quantity < 1) {
                                    list.splice(index, 1);
                                  }
                                  // localStorage.setItem(
                                  //   'productCart',
                                  //   JSON.stringify(list)
                                  // );
                                  this.updateIncompleteOrder(list);
                                  // this.setState({ shop: list });
                                }}
                              >
                                <span>−</span>
                              </div>

                              <div className='item count-item'>
                                {item.quantity}
                              </div>

                              <div
                                role='button'
                                onKeyPress={() => { }}
                                tabIndex='0'
                                className='item add-item'
                                onClick={() => {
                                  const list = shop;
                                  list[index].quantity += 1;
                                  if (list[index].quantity > 99) {
                                    list[index].quantity = 99;
                                  }
                                  // localStorage.setItem(
                                  //   'productCart',
                                  //   JSON.stringify(list)
                                  // );
                                  this.updateIncompleteOrder(list);
                                  // this.setState({ shop: list });
                                }}
                              >
                                <span>+</span>
                              </div>
                            </div>
                          </div>

                          <span className='items-price d-none d-sm-inline-block'>
                            £{item.price}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid
                item
                xs={12}
                className='each-part delivery-address border-element'
              >
                <Grid className='header-main border-bottom' style={{ padding: 0 }}>
                  2. DELIVERY ADDRESS
                  <span
                    role='button'
                    tabIndex='0'
                    onKeyPress={() => { }}
                    onClick={() =>
                      this.setState({
                        deliveryAddressFieldsDisabled: !deliveryAddressFieldsDisabled,
                      })
                    }
                  >
                    <img
                      className='float-right edit-icon'
                      src='/assets/images/big-edit.png'
                      alt='edit'
                    />
                  </span>
                </Grid>

                <Grid item lg={6} xs={12} className='address-column'>
                  <Grid container>
                    <Grid item xs={12}>
                      {deliveryAddressFieldsDisabled ? (
                        deliveryFirstName && <Input
                          value={deliveryFirstName}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='firstName'
                            label='First Name'
                            margin='normal'
                            fullWidth
                            value={deliveryFirstName}
                            onChange={this.handleChange('deliveryFirstName')}
                            error={deliveryFirstName === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {deliveryAddressFieldsDisabled ? (
                        deliverySurname && <Input
                          value={deliverySurname}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='surname'
                            label='Surname'
                            margin='normal'
                            fullWidth
                            value={deliverySurname}
                            onChange={this.handleChange('deliverySurname')}
                            error={deliverySurname === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {deliveryAddressFieldsDisabled ? (
                        deliveryAddress.company && <Input
                          value={deliveryAddress.company}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='company'
                            label='Company'
                            margin='normal'
                            fullWidth
                            value={deliveryAddress.company}
                            onChange={this.handleChangeDeliveryAddress('company')}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {deliveryAddressFieldsDisabled ? (
                        deliveryAddress.address1 && <Input
                          value={deliveryAddress.address1}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='address-1'
                            label='Address Line 1'
                            margin='normal'
                            fullWidth
                            value={deliveryAddress.address1}
                            onChange={this.handleChangeDeliveryAddress(
                              'address1'
                            )}
                            error={deliveryAddress.address1 === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {deliveryAddressFieldsDisabled ? (
                        deliveryAddress.address2 && <Input
                          value={deliveryAddress.address2}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='address-2'
                            label='Address Line 2'
                            margin='normal'
                            fullWidth
                            value={deliveryAddress.address2}
                            onChange={this.handleChangeDeliveryAddress(
                              'address2'
                            )}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {deliveryAddressFieldsDisabled ? (
                        deliveryAddress.town && <Input
                          value={deliveryAddress.town}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='city'
                            label='City'
                            margin='normal'
                            fullWidth
                            value={deliveryAddress.town}
                            onChange={this.handleChangeDeliveryAddress('town')}
                            error={deliveryAddress.town === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {deliveryAddressFieldsDisabled ? (
                        deliveryAddress.county && <Input
                          value={deliveryAddress.county}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='county'
                            label='County'
                            margin='normal'
                            fullWidth
                            value={deliveryAddress.county}
                            onChange={this.handleChangeDeliveryAddress('county')}
                            error={deliveryAddress.county === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {deliveryAddressFieldsDisabled ? (
                        deliveryAddress.postcode && <Input
                          value={deliveryAddress.postcode}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='postcode'
                            label='Postcode'
                            margin='normal'
                            fullWidth
                            value={deliveryAddress.postcode}
                            onChange={this.handleChangeDeliveryAddress(
                              'postcode'
                            )}
                            error={deliveryAddress.postcode === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {deliveryAddressFieldsDisabled ? (
                        deliveryAddress.country && <Input
                          value={deliveryAddress.country}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            select
                            name='country'
                            label='Country'
                            margin='normal'
                            fullWidth
                            value={deliveryAddress.country}
                            onChange={this.handleChangeDeliveryAddress('country')}
                            error={deliveryAddress.country === ''}
                          >
                            {countries.map((item) => (
                              <MenuItem key={item.code} value={item.code}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Textfield>
                        )}
                    </Grid>

                    <Grid className='deliveryInstructionDiv' item xs={12}>
                      <span className='deliveryInstructions'>
                        Delivery Instructions:{' '}
                      </span>
                      {deliveryAddressFieldsDisabled ? (
                        deliveryInstructions && <Input
                          value={deliveryInstructions}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='deliveryInstructions'
                            label=''
                            margin='normal'
                            fullWidth
                            value={deliveryInstructions}
                            onChange={this.handleChange('deliveryInstructions')}
                          />
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className='each-part billing-address border-element'
              >
                <Grid className='header-main border-bottom' style={{ padding: 0 }}>
                  3. BILLING ADDRESS
                  <span
                    role='button'
                    tabIndex='0'
                    onKeyPress={() => { }}
                    onClick={() =>
                      this.setState({
                        billingAddressFieldsDisabled: !billingAddressFieldsDisabled,
                      })
                    }
                  >
                    <img
                      className='float-right edit-icon'
                      src='/assets/images/big-edit.png'
                      alt='edit'
                    />
                  </span>
                </Grid>

                <Grid item lg={6} xs={12} className='address-column'>
                  <Grid container>
                    <Grid item xs={12}>
                      {billingAddressFieldsDisabled ? (
                        billingFirstName && <Input
                          value={billingFirstName}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='firstName'
                            label='First Name'
                            margin='normal'
                            fullWidth
                            value={billingFirstName}
                            onChange={this.handleChange('billingFirstName')}
                            error={billingFirstName === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {billingAddressFieldsDisabled ? (
                        billingSurname && <Input
                          value={billingSurname}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='surname'
                            label='Surname'
                            margin='normal'
                            fullWidth
                            value={billingSurname}
                            onChange={this.handleChange('billingSurname')}
                            error={billingSurname === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {billingAddressFieldsDisabled ? (
                        billingAddress.company && <Input
                          value={billingAddress.company}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='company'
                            label='Company'
                            margin='normal'
                            fullWidth
                            value={billingAddress.company}
                            onChange={this.handleChangeBillingAddress('company')}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {billingAddressFieldsDisabled ? (
                        billingAddress.address1 && <Input
                          value={billingAddress.address1}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='address-1'
                            label='Address Line 1'
                            margin='normal'
                            fullWidth
                            value={billingAddress.address1}
                            onChange={this.handleChangeBillingAddress('address1')}
                            error={billingAddress.address1 === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {billingAddressFieldsDisabled ? (
                        billingAddress.address2 && <Input
                          value={billingAddress.address2}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='address-2'
                            label='Address Line 2'
                            margin='normal'
                            fullWidth
                            value={billingAddress.address2}
                            onChange={this.handleChangeBillingAddress('address2')}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {billingAddressFieldsDisabled ? (
                        billingAddress.town && <Input
                          value={billingAddress.town}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='city'
                            label='City'
                            margin='normal'
                            fullWidth
                            value={billingAddress.town}
                            onChange={this.handleChangeBillingAddress('town')}
                            error={billingAddress.town === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {billingAddressFieldsDisabled ? (
                        billingAddress.county && <Input
                          value={billingAddress.county}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='county'
                            label='County'
                            margin='normal'
                            fullWidth
                            value={billingAddress.county}
                            onChange={this.handleChangeBillingAddress('county')}
                            error={billingAddress.county === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {billingAddressFieldsDisabled ? (
                        billingAddress.postcode && <Input
                          value={billingAddress.postcode}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            name='postcode'
                            label='Postcode'
                            margin='normal'
                            fullWidth
                            value={billingAddress.postcode}
                            onChange={this.handleChangeBillingAddress('postcode')}
                            error={billingAddress.postcode === ''}
                          />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {billingAddressFieldsDisabled ? (
                        billingAddress.country && <Input
                          value={billingAddress.country}
                          disabled
                          disableUnderline
                        />
                      ) : (
                          <Textfield
                            select
                            name='country'
                            label='Country'
                            margin='normal'
                            fullWidth
                            value={billingAddress.country}
                            onChange={this.handleChangeBillingAddress('country')}
                            error={billingAddress.country === ''}
                          >
                            {countries.map((item) => (
                              <MenuItem key={item.code} value={item.code}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Textfield>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className='each-part payment-information border-element'
              >
                <Grid className='header-main border-bottom' style={{ padding: 0 }}>
                  4. PAYMENT INFORMATION*
                  <span
                    role='button'
                    tabIndex='0'
                    onKeyPress={() => { }}
                    onClick={() =>
                      this.setState({
                        bankCardFieldDisabled: !bankCardFieldDisabled,
                      })
                    }
                  >
                    <img
                      className='float-right edit-icon'
                      src='/assets/images/big-edit.png'
                      alt='edit'
                    />
                  </span>
                </Grid>

                <Grid item lg={6} xs={12} className='address-column'>

                  {bankCardFieldDisabled &&
                    user.bank_card !== undefined &&
                    user.bank_card !== null ? (
                      <Grid container alignItems='center' justify='center'>
                        <Grid item xs={2}>
                          <img
                            src={`/assets/images/card/${user.bank_card.brand}.png`}
                            alt='card-brand'
                            style={{
                              width: '70px',
                              maxWidth: '100%',
                            }}
                          />
                        </Grid>

                        <Grid item xs={10}>
                          <span
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            <strong>{user.bank_card.brand}</strong> ending in{' '}
                            {user.bank_card.last4}
                          </span>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Grid item xs={6}>
                          <Textfield
                            name='nameOnCard'
                            label='Name on Card'
                            margin='dense'
                            fullWidth
                            value={nameOnCard}
                            onChange={this.handleChange('nameOnCard')}
                            error={nameOnCard === ''}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <CardNumberElement id="cardNumber"
                            onChange={(changeObject) =>
                              this.setState({
                                cardNumberCompleted: changeObject.complete,
                              })
                            }
                            className='input-field-new'
                            placeholder='Card Number'
                            style={{
                              base: {
                                fontSize: '14px',
                                letterSpacing: '0.4px',
                                fontWeight: 300,
                              },
                            }}
                          />
                        </Grid>

                        <Grid container spacing={24}>
                          <Grid item xs={6}>
                            <CardExpiryElement
                              onChange={(changeObject) =>
                                this.setState({
                                  cardExpiryCompleted: changeObject.complete,
                                })
                              }
                              className='input-field-new bottom'
                              placeholder='MM / YY'
                              style={{
                                base: {
                                  fontSize: '14px',
                                  letterSpacing: '0.4px',
                                  fontWeight: 300,
                                },
                              }}
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <CardCVCElement
                              onChange={(changeObject) =>
                                this.setState({
                                  cardCVCCompleted: changeObject.complete,
                                })
                              }
                              className='input-field-new bottom'
                              placeholder='CVV'
                              style={{
                                base: {
                                  fontSize: '14px',
                                  letterSpacing: '0.4px',
                                  fontWeight: 300,
                                },
                              }}
                            />
                            <HowCVCTip
                              title='Where to find your CVV?'
                              description=''
                              target='how-cvc'
                              isImage={false}
                              isButton={false}
                              btnLabel=''
                            />
                          </Grid>

                          <Grid item xs={1}>
                            <a
                              className='input-field-info'
                              data-toggle='modal'
                              href='#how-cvc'
                            >
                              <InfoIcon />
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>

              <Grid item xs={12} className='payment-voucher'>
                <Promocode
                  promocodeValid={this.state.promocodeValid}
                  promocode={this.state.promocode}
                  handleChange={this.handleChangePromocode('promocode')}
                  promocodeCheck={() => this.promocodeCheck(false)}
                  header='5. VOUCHER CODE'
                  showFitkolarAddedMessage={this.state.showFitkolarAddedMessage}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              className='checkout-right order-summary'
              id='order-summary-box'
              style={{ marginTop: summaryBoxMarginTop, marginBottom: 0 }}
            >
              <Grid
                item
                xs={12}
                className='checkout-order-summary border-element'
              >
                <Grid className='header-main'>ORDER SUMMARY</Grid>

                <Grid container className='border-top summary-details'>
                  <Grid item xs={12}>
                    <div className='price-details'>
                      <p className='price-text'>
                        <span className='price-title'>Items</span>
                        <span className='specific-price'>
                          £{Math.round(total * 100) / 100}
                        </span>
                      </p>

                      <p className='price-text'>
                        <span className='price-title'>Delivery</span>
                        <span className='specific-price'>£{deliveryCost}</span>
                      </p>

                      <p className='price-text'>
                        <span className='price-title'>Discounts applied</span>
                        <span className='specific-price'>
                          £{discountsApplied}
                        </span>
                      </p>

                      {discountsMessage.length > 0 && (
                        <p className='error-text'>
                          <span className='error-detail'>
                            {discountsMessage}
                          </span>
                        </p>
                      )}

                      <p className='price-text price-text-total'>
                        <span className='price-title'>Total</span>
                        <span className='specific-price'>
                          £{totalAfterDiscounts}
                        </span>
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className='price-details'>
                      <p className='price-text delivery-date'>
                        <span className='price-title'>Delivery Date</span>
                        <span className='specific-price'>
                          {deliveryDate.toDateString()}
                        </span>
                      </p>
                    </div>

                    {shop.length > 0 && (
                      <Grid container alignItems='center' justify='center'>
                        {this.state.paymentLoading ? (
                          <Grid item md='auto' xs={12} className="checkout-spin-loading">
                            <ReactLoading
                              type='spinningBubbles'
                              justifyContent='center'
                              color='grey'
                              height={45}
                              width={45}
                            />
                          </Grid>
                        ) : (
                            <Grid item xs={12}>
                              <div className='buy-now-box'>
                                <div className='recap-button'>
                                  <ReCAPTCHA
                                    sitekey='6LfNZekUAAAAADOMAInYTkY3SQAYzKxSt9MfLdKr'
                                    onChange={(token) => {
                                      this.setState({ recapToken: token });
                                    }}
                                  />
                                </div>
                                <ContainedButton
                                  className='buy-now-button'
                                  onClick={this.payAndGoToUrl(
                                    '/order-confirmation'
                                  )}
                                  title='Buy Now'
                                  disabled={this.state.recapToken === ''}
                                />
                                <p className='buy-now-checkout'>
                                  By placing your order you agree to{' '}
                                  <a href='https://www.kasperandkitty.com/index.php/terms-conditions/'>
                                    Terms & Conditions
                                  </a>
                                  . Please see our{' '}
                                  <a href='https://www.kasperandkitty.com/index.php/privacy-policy/'>
                                    Privacy Policy
                                  </a>{' '}
                                  and{' '}
                                  <a href='https://www.kasperandkitty.com/index.php/cookie-policy/'>
                                    Cookie Policy
                                  </a>
                                  .
                                </p>
                              </div>
                            </Grid>
                          )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <div className='d-block d-sm-none'>
                <Link to='/shop'>
                  <button className='back-to-shop'>BACK TO SHOP</button>
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: sessionSelectors.getUser(),
  dog: dogsSelectors.getCurrentDog(),
  orders: ordersSelectors.getOrders(),
  incompleteOrder: state.data.orders.incompleteOrder,
  promocode: state.data.promocode,
});

const mapDispatchToProps = (dispatch) => ({
  createOrder: bindActionCreators(createOrder, dispatch),
  getIncompleteOrder: bindActionCreators(getIncompleteOrder, dispatch),
  updateIncompleteOrder: (order) => dispatch(orderActionCreators.updateIncompleteOrder(order)),
  promocodeUpdate: (promocode) =>
    dispatch(actionCreators.promocodeUpdate(promocode)),
});

const CheckOutToBuyWraped = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectStripe(CheckoutToBuy)));

export default ({ props }) => (
  <Elements>
    <CheckOutToBuyWraped {...props} />
  </Elements>
);
