import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class DentalCard extends Component {
  render() {
    return (
      <div className="dentalcard-container full-height">
        <a href="/shop"><img src={this.props.image} className="mb-4" /></a>
        <h4 className="font-script color-orange mb-3">{this.props.subj}</h4>
        <p className="fw-bold mb-4">{this.props.text}</p>
        <Link to={`/product/${this.props.id}`} className="button">
          <button type="button" className="btn btn-orange px-4">Buy now</button>
        </Link>
      </div>
    );
  }
}
