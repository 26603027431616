import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Grid } from '@material-ui/core';
import * as cartActionCreators from '../data/cart/actions';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: this.props.quantity || 1,
    };
    this.addToCart = this.addToCart.bind(this);
  }

  upQuantity = () => (
    this.setState(prevState => ({
      quantity: prevState.quantity + 1,
    }))
  );
  downQuantity = () => {
    this.setState(prevState => (
      prevState.quantity > 1 ? {
        quantity: prevState.quantity - 1,
      } : prevState
    ));
  };

  addToCart = () => {
    this.props.addToCart(this.props.currentDog, this.props.product.ID, this.state.quantity);
  };

  render() {
    const {
      product, key,
    } = this.props;
    return (
      <div key={key} className="product border-element mx-sm-2">
        <Grid container direction="column" className="pt-3">
          <Grid item className="product-image px-4">
            <img src={window.location.protocol + product.image.Url} alt={product.name} />
          </Grid>
          <Grid item>
            <Grid container direction="column" className="product-info">
              <Grid item>
                <h3 className="product-name px-4">{product.name}</h3>
              </Grid>
              <Grid item className="pb-2 px-4">
                <p className="product-description">{product.description}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="pb-2 px-4">
            <p className="product-price">£{(product.price * this.state.quantity).toFixed(2)}</p>
          </Grid>
        </Grid>
        <Grid container className="product-operations">
          <Grid item xs={4} className="border-right">
            <Grid container className="h-100" justify="center" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <button
                    className="quantity-change"
                    onClick={this.downQuantity}
                  >−
                  </button>
                  <span className="px-2">{this.state.quantity}</span>
                  <button
                    className="quantity-change"
                    onClick={this.upQuantity}
                  >+
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <button
              className="product-confirm"
              onClick={this.addToCart}
            >add to trial
            </button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentDog: state.data.dogs.currentDog,
});

const mapDispatchToProps = dispatch => ({
  addToCart: (dogId, productId, quantity) => dispatch(cartActionCreators.addToCart(dogId, productId, quantity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
