import React from 'react';
import BackgroundContainer from './BackgroundContainer';
import Row from './Row';
import GreyText from './GreyText';

import * as dogsActionCreators from '../data/dogs/actions';

export default class MonthlyMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calories: 0,
    }
  }

  componentDidMount() {
    dogsActionCreators.getCalories(this.props.dog.ID)
      .then((resp) => {
        this.setState({ calories: resp.calories });
      });
  }

  renderCircle(dog, c, w, extraCircleClass) {
    if (c) {
      return (
        <div className={`dialy-menu-image ${extraCircleClass}`}>
          <img
            src="/assets/images/calories-container.png"
            alt="Calories"
          />
          <div className="calories-text">
            <p className="calories">{this.state.calories}</p>
            <p className="text"><span>calories</span><br />per day</p>
          </div>
        </div>
      );
    }

    return (
      <div
        className="dog-image-border small"
        style={{
          position: 'relative',
        }}
      >
        <img
          className="dog-image-frame"
          src="/assets/images/dog-image-frame.png"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
        <div
          className="dog-image-container"
          style={{
            height: w,
            backgroundImage: dog.image.Url !== '' ? `url(http:${dog.image.Url})` : 'url(/assets/images/kasper-image-white.png)',
            zIndex: 0,
          }}
        />
      </div>
    );
  }

  render() {
    const {
      title,
      dog,
      imageWidth,
      items,
      calories,
      portionTitle,
      caloriesTitle,
      extraCircleClass,
    } = this.props;

    return (
      <BackgroundContainer equalPadding>
        <Row>
          <div className="col-12 no-padding padding-bottom">
            <GreyText
              text={`${dog.name}'s ${title}`}
              fontSize="22"
              centered
              marginBottom
              bold
            />
          </div>
        </Row>
        <Row>
          <div className="col-6 col-sm-8 col-md-6 col-lg-3 align-self-center padding-right medium">
            {this.renderCircle(dog, calories, imageWidth, extraCircleClass)}
          </div>
          <div className="col-12 col-sm-12 col-lg-9 padding-left medium">
            <table className="dashboard-daily-menu-table">
              <tbody>
                <tr>
                  <th />
                  <th>{portionTitle}</th>
                  <th>{caloriesTitle}</th>
                </tr>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td className="label">{item.label}</td>
                    <td className="information">{item.portion}</td>
                    <td className="information">{item.calories}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Row>
      </BackgroundContainer>
    );
  }
}
