import React from 'react';

import { revoke } from '../services/session';

export default class Logout extends React.Component {
  componentDidMount() {
    localStorage.clear();
    revoke();
  }

  render() {
    return (
      <div />
    );
  }
}
