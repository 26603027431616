import * as api from './api';

/**
 * Get order
 */
export const getOrder = id => (
  new Promise((resolve, reject) => {
    api.order(id)
      .then(resp => resolve(resp))
      .catch(err => reject(err));
  })
);

/**
 * Delay order
 */
export const orderDelay = payload => (
  new Promise((resolve, reject) => {
    api.delay(payload)
      .then(resp => resolve(resp))
      .catch(err => reject(err));
  })
);

/**
 * Cancel order
 */
export const orderCancel = payload => (
  new Promise((resolve, reject) => {
    api.cancelled(payload)
      .then(resp => resolve(resp))
      .catch(err => reject(err));
  })
);

/**
 * Get subscriptions
 */
export const getSubscriptions = dogID => (
  new Promise((resolve, reject) => {
    api.subscriptions(dogID)
      .then(resp => resolve(resp))
      .catch(err => reject(err));
  })
);

export const questionnaire = payload => {
  return new Promise((resolve, reject) => {
    api.cancelQuestionnaire(payload)
      .then(resp => resolve(resp))
      .catch(err => reject(err))
  })
};

/**
 * Reactivate order
 */
export const reactivateOrder = (oderID) => {
  return new Promise((resolve, reject) => {
    api.reactivateOrder(oderID)
      .then(resp => resolve(resp))
      .catch(err => reject(err))
  })
}
