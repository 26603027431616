import React from 'react';
import { Grid, Radio, FormControlLabel } from '@material-ui/core';
import moment from 'moment';

const DeliveryMethod = ({ deliveryCost }) => {
  const cutOffTime = 10
  const today = moment();

  let deliveryDate;
  let daysToAdd = 0;
  switch (today.isoWeekday()) {
    //monday
    case 1:
      if (today.hour() > cutOffTime) {
        daysToAdd += 3
      } else {
        daysToAdd += 2
      }
      break;
    //tuesday
    case 2:
      if (today.hour() > cutOffTime) {
        daysToAdd += 3
      } else {
        daysToAdd += 2
      }
      break;
    //wednesday
    case 3:
      if (today.hour() > cutOffTime) {
        daysToAdd += 5
      } else {
        daysToAdd += 2
      }
      break;
    //thursday
    case 4:
      daysToAdd += 4
      break;
    //friday
    case 5:
      if (today.hour() > cutOffTime) {
        daysToAdd += 5
      } else {
        daysToAdd += 3
      }
      break;
    //saturday
    case 6:
      daysToAdd += 4
      break;
    //sunday
    case 7:
      daysToAdd += 3
      break;
    default:
  }
  deliveryDate = today.add(daysToAdd, 'days');

  return (
    <Grid className="delivery-method border-element">
      <Grid container className="border-bottom">
        <h3 className="header-main">Delivery Method</h3>
      </Grid>
      <Grid container className="delivery-method-border">
        <Grid item lg={12}>
          <FormControlLabel
            value=""
            control={<Radio color="primary" checked />}
            label={
              <div>
                <p className="info-text bold">2 Working Days Delivery (£{deliveryCost})</p>
                <p className="info-text">Get it by {deliveryDate.format('dddd, MMMM D')}</p>
              </div>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeliveryMethod;
