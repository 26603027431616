import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import WizardInput from './WizardInput';
import UploadFile from './UploadFile';
import * as dogsActionCreators from '../../data/dogs/actions';

class Dog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editRoute: '',
    };

    this.updateCurrentDog = (key, value) => {
      this.props.updateCurrentDog(key, this.props.index, value);
    };

    this.removeCurrentDog = async () => {
      await this.props.removeCurrentDog(this.props.index);
      await this.props.onRemove();
    };

    this.editDog = () => {
      this.props.toggleDog(this.props.index);
      if (this.props.step === '/add-dog') {
        this.props.history.push('/gender');
      } else {
        this.props.history.push(this.props.step);
      }
    };

    this.handleEdit = (route) => {
      this.props.history.push(route);
    };

    this.switchDogError = (error) => {
      switch (error) {
        case 'pregnant':
          return (
            <span
              style={{ position: 'absolute', bottom: '-25px' }}
              className="error-message edit w-100 text-left"
            >
            When {this.props.dog.name} is not pregnant anymore, you can continue with registration.
            To continue now, please
              <span
                role="button"
                tabIndex="0"
                onKeyPress={() => this.handleEdit('/pregnant')}
                onClick={() => this.handleEdit('/pregnant')}
                className="underline pb-3"
              >
              edit this setting.
              </span>
            </span>
          );
        case 'activity':
          return (
            <span
              style={{ position: 'absolute', bottom: '-25px' }}
              className="error-message edit w-100 text-left"
            >
              {this.props.dog.name} will be notified when are ready with our special working dog diet!
                  To continue now, please
              <span
                role="button"
                tabIndex="0"
                onKeyPress={() => this.handleEdit('/activity')}
                onClick={() => this.handleEdit('/activity')}
                className="underline pb-3"
              >
                edit this setting.
              </span>
            </span>
          );
        case 'health-details':
          return (
            <span
              style={{ position: 'absolute', bottom: '-25px' }}
              className="error-message edit w-100 text-left"
            >
              {this.props.dog.name} will be notified when we are ready with our special prescription dog diet!
                  To continue now, please
              <span
                role="button"
                tabIndex="0"
                onKeyPress={() => this.handleEdit('/health-details')}
                onClick={() => this.handleEdit('/health-details')}
                className="underline pb-3"
              >
                    edit this setting.
              </span>
            </span>);
        case 'age':
          return (
            <span
              style={{ position: 'absolute', bottom: '-25px' }}
              className="error-message edit w-100 text-left"
            >
            When {this.props.dog.name} is over 6 weeks old, you can continue with registration.
            To continue now,  please
              <span
                role="button"
                tabIndex="0"
                onKeyPress={() => this.handleEdit('/age')}
                onClick={() => this.handleEdit('/age')}
                className="underline pb-3"
              >
              edit this setting.
              </span>
            </span>
          );
        default: return '';
      }
    };
  }
  render() {
    const {
      dog,
      isAvatar,
    } = this.props;


    return (
      <div className="col-12 row  justify-content-center py-3" id={this.props.index}>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-8 pl-xl-0 pl-lg-0 pl-md-0 pl-sm-3 pl-3 pr-xl-4 pr-lg-4 pr-md-4 pr-sm-3 pr-3 text-center animated  dog-avatar">
          <span
            className="d-xl-none d-lg-none d-md-none d-sm-block d-block dog-list-icon pr-2 fa fa-edit"
            onClick={this.editDog}
            onKeyPress={this.editDog}
            tabIndex="0"
            role="button"
          />
          { isAvatar
                ?
                  <img
                    className="avatar stored mx-xl-0 mx-lg-0 mx-md-0 mx-sm-3 mx-4"
                    src={dog.image}
                    alt="dog"
                  />
                :
                  <div>
                    <div className="dog-default-avatar mx-auto row align-items-center justify-content-center">
                      {dog.name.slice(0, 1).toUpperCase()}
                    </div>
                    <div className="upload-text px-1 pt-2 ">
                      Don{"'"}t forget to upload the picture :)
                    </div>
                  </div>
            }


          <UploadFile
            onFileLoaded={imageFile => this.updateCurrentDog('image', imageFile)}
          />
          <span
            className="d-xl-none d-lg-none d-md-none d-sm-block d-block dog-list-icon fa fa-trash-o"
            onClick={this.removeCurrentDog}
            tabIndex="0"
            onKeyPress={this.removeCurrentDog}
            role="button"
          />
        </div>
        <div className={`col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 pl-xl-0 pl-lg-0 pl-md-0 pl-sm-3 pl-3 dog-field ${dog.progress === 100 ? 'complete' : ''}`}>
          <WizardInput
            autofocus
            animation=""
            value={dog.name}
            error={dog.error !== ''}
            max="10"
            type="text"
            placeholder="Name or Nickname*"
            tip="up to 10 characters"
            addDogInput
            keyTip=""
            handleBlur={false}
            onChangeText={e => this.updateCurrentDog('name', e.target.value)}
            onKeyPress={e => this.updateCurrentDog('name', e.target.value)}
          />
          {dog.error !== ''
            ?
              <span>{this.switchDogError(dog.error)}</span>
            :
              <span className="animated fadeIn dog-progress">{dog.progress}% registration complete</span>
            }
        </div>
        <div className="col-2 d-xl-flex d-lg-flex d-md-flex d-sm-none d-none row align-items-center">
          <span
            className="dog-list-icon pr-2 fa fa-edit"
            onClick={this.editDog}
            tabIndex="0"
            onKeyPress={this.editDog}
            role="button"
          />
          <span
            className="dog-list-icon fa fa-trash-o"
            onClick={this.removeCurrentDog}
            tabIndex="0"
            onKeyPress={this.removeCurrentDog}
            role="button"
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  dogs: state.data.dogs,
  storeDogs: state.data.dogs.storeDogs,
});
const mapDispatchToProps = dispatch => ({
  updateCurrentDog: (key, index, value) => dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
  removeCurrentDog: index => dispatch(dogsActionCreators.removeStoreCurrentDog(index)),
  toggleDog: index => dispatch(dogsActionCreators.toggleDog(index)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dog));
