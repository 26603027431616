import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import { isLoggedIn } from '../services/session';

const menus = [
  {
    name: 'My Pets',
    link: '/your-pets',
    className: '',
  },
  {
    name: 'Your Details',
    link: '/your-details',
    className: '',
  },
  {
    name: 'Support',
    link: 'https://www.kasperandkitty.com/support/',
    className: '',
  },
  {
    name: 'Refer a friend',
    link: '/refer-a-friend',
    className: 'refer-a-friend',
    path: '../assets/images/mobile-nav-icons/trust.png'
  },
  {
    name: 'Logout',
    link: '/logout',
    className: 'logout',
    path: '../assets/images/logout.png'
  },
];

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    const storage = localStorage.getItem('productCart');

    this.state = {
      expanded: false,
      productsInCart: storage !== null ? JSON.parse(storage) : 0,
      windowWidth: undefined,
      activeLink: this.props.location.pathname,
    };

    this.menuClick = this.menuClick.bind(this);
    this.menuOutsideClick = this.menuOutsideClick.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  menuClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  menuOutsideClick() {
    this.setState({ expanded: false });
  }

  updateDimensions() {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 768) {
      this.setState({
        visibleDropdown: '',
      });
    }

    this.setState({ windowWidth });
  }

  render() {
    const {
      loggedIn,
      absolute,
      checkout,
    } = this.props;

    const { activeLink } = this.state;

    return (
      <div className="header w-100">
        <div className={`${absolute ? ' absolute ' : ''}navigation content-container d-none d-sm-flex`}>
          <div className="logo">
            <img className="logo-img" src="../assets/images/new_logo.png" alt="Dexter &amp; Willow" />
          </div>

          <div className="header-icons">
            <OutsideClickHandler
              onOutsideClick={this.menuOutsideClick}
            >
              <div className="my-account" onClick={this.menuClick}>
                <img src="../assets/images/navigation-bar-icons/icon-my-account.png" alt="" />
                <span>My Account</span>
                <img src="../assets/images/navigation-bar-icons/drop-down.png" alt="" />
              </div>

              {this.state.expanded &&
                <div className="my-account-dropdown">
                  {menus.map((menu, i) => {
                    if (menu.name === 'Logout') {
                      return (
                        <a
                          key={i}
                          className={`${menu.link.indexOf(`/${window.location.pathname.split('/')[1]}`) !== -1 ? ' active ' : ''} my-account-dropdown-option`}
                          onClick={() => {
                            if (localStorage.getItem('isEdited') === 'true') {
                              const result = window.confirm('You haven\'t confirm your subscription changes! Are you leaving without confirming?');
                              if (result) {
                                this.props.history.push('/logout');
                              } else {
                                e.preventDefault();
                              }
                            } else {
                              this.props.history.push('/logout');
                            }
                          }}
                        >
                          {menu.path && <img src={menu.path} className={menu.className} alt="" />}
                          {menu.name}
                        </a>
                      )
                    } else {
                      return (
                        <a
                          key={i}
                          href={menu.link}
                          className={`${menu.link.indexOf(`/${window.location.pathname.split('/')[1]}`) !== -1 ? ' active ' : ''} my-account-dropdown-option`}
                        >
                          {menu.path && <img src={menu.path} className={menu.className} alt="" />}
                          {menu.name}
                        </a>
                      )
                    }
                  })}
                </div>
              }
            </OutsideClickHandler>

            <div className="cart">
              <img className="shopping-cart" src="../assets/images/navigation-bar-icons/shopping-cart.png" alt="" />
              <div className="cart-count"><span className="count-number">{this.props.productCount ? this.props.productCount : 0}</span></div>
              <span>Cart</span>
            </div>
          </div>
        </div>

        <nav className="navbar nav-mobile d-sm-none">
          <div className="main-nav-mobile">
            {checkout ?
              <a href="/cart">
                <img className="logo-img" src="../assets/images/logo-mobile-secondary.png" alt="Dexter &amp; Willow" />
              </a>
              :
              <a href="/">
                <img className="logo-img" src="../assets/images/logo-mobile-secondary.png" alt="Dexter &amp; Willow" />
              </a>
            }

            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbar-mobile" aria-controls="navbar-mobile" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fa fa-bars" />
              <i className="fa fa-times" />
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbar-mobile">
            {isLoggedIn &&
              <ul className="mobile-links">
                <li className={activeLink === '/dashboard' ? 'active' : ''}>
                  <a href="/dashboard" title="Dashboard">
                    <img className="shopping-cart" src="../assets/images/mobile-nav-icons/dashboard.png" alt="" />
                    <span className="nav-name">Dashboard</span>
                  </a>
                </li>
              </ul>
            }

            {isLoggedIn && !checkout &&
              <ul className="mobile-links">
                <li className={activeLink === '/your-dogs-food' ? 'active' : ''}>
                  <a href="/your-dogs-food" title="Food">
                    <img src="../assets/images/mobile-nav-icons/bowl.png" alt="" />
                    <span className="nav-name">Food Management Page</span>
                  </a>
                </li>
              </ul>
            }

            {isLoggedIn && !checkout &&
              <ul className="mobile-links">
                <li className={activeLink === '/dog-profile' ? 'active' : ''}>
                  <a href="/dog-profile" title="Dog Profile">
                    <img src="../assets/images/mobile-nav-icons/paw.png" alt="" />
                    <span className="nav-name">Dog Profile</span>
                  </a>
                </li>
              </ul>
            }

            {isLoggedIn && !checkout &&
              <ul className="mobile-links">
                <li className={activeLink === '/shop' ? 'active' : ''}>
                  <a href="/shop" title="Shop">
                    <img src="../assets/images/mobile-nav-icons/supermarket.png" alt="" />
                    <span className="nav-name">Shop</span>
                  </a>
                </li>
              </ul>
            }

            {isLoggedIn && !checkout &&
              <ul className="mobile-links">
                <li className={activeLink === '/live-orders' ? 'active' : ''}>
                  <a href="/live-orders" title="My Orders">
                    <img src="../assets/images/mobile-nav-icons/box.png" alt="my order" />
                    <span className="nav-name">My Orders</span>
                  </a>
                </li>
              </ul>
            }

            {isLoggedIn && !checkout &&
            <ul className="mobile-links">
              <li className={activeLink === '/refer-a-friend' ? 'active' : ''}>
                <a href="/refer-a-friend" title="Refer a friend">
                  <img src="../assets/images/mobile-nav-icons/trust.png" alt="" />
                  <span className="nav-name">Refer a Friend</span>
                </a>
              </li>
            </ul>
            }

            {isLoggedIn &&
              <ul className="mobile-links">
                <li className={activeLink === '/your-details' ? 'active' : ''}>
                  <a href="/your-details" title="My Account">
                    <img src="../assets/images/mobile-nav-icons/account.png" alt="" />
                    <span className="nav-name">My Account</span>
                  </a>
                </li>
              </ul>
            }

            {isLoggedIn &&
              <ul className="mobile-links">
                <li>
                  <a href="https://www.kasperandkitty.com/support/" title="Support">
                    <img
                      src="../assets/images/mobile-nav-icons/support.png"
                      alt="support"
                      style={{
                        marginLeft: '-5px',
                        marginRight: '5px',
                        width: '30px',
                      }}
                    />
                    <span className="nav-name">Support</span>
                  </a>
                </li>
              </ul>
            }

            <ul className="mobile-links">
              <li>
                {loggedIn === false &&
                  <a href="/login" className="login">
                    <i className="fa fa-sign-in" aria-hidden="true" style={{ paddingRight: '10px' }} />
                    <span className="nav-name">Login/Register</span>
                  </a>
                }
                {loggedIn === true &&
                  <a
                    className="logout"
                    onClick={() => {
                      if (localStorage.getItem('isEdited') === 'true') {
                        const result = window.confirm('You haven\'t confirm your subscription changes! Are you leaving without confirming?');
                        if (result) {
                          this.props.history.push('/logout');
                        } else {
                          e.preventDefault();
                        }
                      } else {
                        this.props.history.push('/logout');
                      }
                    }}
                  >
                    <i className="fa fa-user fa-lg" aria-hidden="true" style={{ paddingRight: '10px' }} />
                    <span className="nav-name">Logout</span>
                  </a>
                }
              </li>
            </ul>

            <ul className="mobile-site-info">
              <li>
                <img src="../assets/images/navigation-bar-icons/clock.png" alt="" />
                <span>Open hours: Monday-Friday, 9am-5pm</span>
                <span className="cutting-line" />
              </li>
              <li>
                <img src="../assets/images/navigation-bar-icons/call-answer.png" alt="" />
                <a href="tel:+44-0333-006-8266"><span>0333 006 8266</span></a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default withRouter(connect(() => ({
  loggedIn: isLoggedIn(),
}))(Navigation));
