import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navigation from '../../components/Navigation';
import SubNavigation from '../../components/SubNavigation';
import DateSelectionBanner from '../../components/DateSelectionBanner';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';
import BackgroundContainer from '../../components/BackgroundContainer';
import Row from '../../components/Row';
import GreyText from '../../components/GreyText';
import Footer from '../../components/Footer';

import Feedback from '../cancellation/feedback';
import Final from '../cancellation/final';
import Future from '../cancellation/future';
import Reason from '../cancellation/reason';
import CancelOrderConfirm from '../cancellation/confirm';

import * as sessionSelectors from '../../services/session/selectors';
import * as ordersSelectors from '../../data/orders/selectors';
import * as ordersApi from '../../data/orders';
import * as dogsSelectors from '../../data/dogs/selectors';
import * as ordersActionCreators from '../../data/orders/actions';
import { Elements, injectStripe } from 'react-stripe-elements';
import { PayHandler } from '../../services/PayHandler';
import * as Sentry from '@sentry/browser';

const dateRearrange = (originalDate) => {
  // Date has to be as a 10 character long string (YEAR-MONTH-DAY)
  const year = originalDate.substr(0, 4);
  const month = originalDate.substr(5, 2);
  const day = originalDate.substr(8, 2);
  const newDate = `${day}-${month}-${year}`;
  return newDate;
};

const cancellationStep = ['reason', 'future', 'feedback', 'final', 'confirm'];

class LiveOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],

      // Every state below has been added for new order delay system
      orderDelayed: false,
      orderCancelled: false,
      delayDate: new Date().getDate(),
      delayMonth: new Date().getMonth(),
      delayYear: new Date().getFullYear(),

      orderDate: 12,
      orderMonth: 6,
      orderYear: 2019,

      dogIDToDelay: undefined,
      cancelStep: 0,
      cancelledOrderID: null,
    };

    this.delayOrder = this.delayOrder.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);

    this.renderOrders = this.renderOrders.bind(this);
    this.renderLiveOrders = this.renderLiveOrders.bind(this);
    this.renderShippedOrders = this.renderShippedOrders.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.cancelStep = this.cancelStep.bind(this);
    this.submitQuestionnare = this.submitQuestionnare.bind(this);
    this.reactivate = this.reactivate.bind(this);
    this.getOriginalAndDiscountedTotal = this.getOriginalAndDiscountedTotal.bind(this);

    this.delayPreviousDogIfCurrentIncomplete = this.delayPreviousDogIfCurrentIncomplete.bind(this);
  }

  componentDidMount() {
    this.payHandler = new PayHandler(this.props);
    this.renderOrders();
  }

  getOriginalAndDiscountedTotal(originalTotal, isOrderReleased) {
    const { total_discount } = this.props.user;
    if (!total_discount || total_discount === 0 || isOrderReleased) {
      return `£${originalTotal.toFixed(2)}`;
    }
    const newTotal = ((100 - total_discount) / 100) * originalTotal;
    return (
      <span>
        <s>£{originalTotal.toFixed(2)}</s> £{newTotal.toFixed(2)}
      </span>
    );
  }

  approve(orderId) {
    const selectedOrder = this.state.orders.find((o) => o.ID === orderId);
    this.payHandler
      .checkPaymentIntent(selectedOrder)
      .then((status) => {
        if (status === 'succeeded') {
          this.props.history.push('/thanks');
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        this.setState({ paymentLoading: false });
        let message = '';
        if (error.message) {
          message = error.message;
        }
        alert(`An error occured while processing your payment: ${message}`);
        this.props.history.push('/live-orders');
      });
  }

  renderOrders() {
    this.props.actions.orders
      .getOrders()
      .then((orders) => {
        if (orders.length > 0) {
          this.setState({ orders });

          // Get date of latest, non-shop, unreleased order. There will be only one.
          // put it in calendar
          const sortedOrders = orders.filter((order) => {
            const kibbles = order.order_items && order.order_items.filter((item) => item.is_kibble);
            return kibbles !== undefined && !order.release;
          });

          sortedOrders.sort((a, b) => new Date(b.date_required) - new Date(a.date_required));

          const lastOrder = sortedOrders[0];
          if (lastOrder) {
            const d = new Date(lastOrder.date_required);
            this.setState({
              orderYear: d.getFullYear(),
              orderMonth: d.getMonth(),
              orderDate: d.getDate(),
            });
          }
        }
      })
      .then(this.delayPreviousDogIfCurrentIncomplete);
  }

  delayPreviousDogIfCurrentIncomplete() {
    const currentID = dogsSelectors.getCurrentDog().ID;
    const anyOrders = this.state.orders.find((order) => order.dog_id === currentID);
    if (!anyOrders) {
      const latestDogOrder = this.state.orders.sort((a, b) => (a.CreatedAt > b.CreatedAt ? -1 : 1))[0];
      if (latestDogOrder !== undefined) {
        this.setState({ dogIDToDelay: latestDogOrder.dog_id });
      } else {
        console.log('selectPreviousDogIfCurrentIncomplete: couldnt find order with latest dog');
      }
    }
  }

  delayOrder() {
    const currentUTC = new Date(this.state.delayYear, this.state.delayMonth, this.state.delayDate);
    const dateMinusOne = new Date(currentUTC.getTime() - currentUTC.getTimezoneOffset() * 60000);

    const todaysDate3 = new Date();
    todaysDate3.setDate(todaysDate3.getDate() + 3);

    if (currentUTC <= todaysDate3) {
      window.alert('Before delaying your order please select a date from the calendar');
      return;
    }

    if (Number.isNaN(dateMinusOne.getTime())) {
      window.alert('Please select a date first');
      return;
    }

    const payload = {
      dog_id: this.state.dogIDToDelay ? this.state.dogIDToDelay : dogsSelectors.getCurrentDog().ID,
      date_req: dateMinusOne,
    };

    this.renderOrders();
    ordersApi
      .orderDelay(payload)
      .then(() => {
        this.setState({ orderDelayed: true });
      })
      .catch(() => {
        window.alert('The order is currently being processed and no more changes can be made');
      });
  }

  cancelOrder() {
    this.renderOrders();
    const payload = {
      dog_id: dogsSelectors.getCurrentDog().ID,
    };

    ordersApi
      .orderCancel(payload)
      .then((resp) => {
        if (resp.success === false) {
          window.alert(
            'Your order cannot be cancelled as it is currently being prepared. Please contact support for further help.',
          );
        } else {
          this.nextStep();
          this.setState({
            orderCancelled: true,
            cancelledOrderID: resp.order_id,
          });
        }
      })
      .catch(() => {
        window.alert('The order is currently being processed and no more changes can be made');
      });
  }

  renderLiveOrders() {
    const ordersFiltered = this.state.orders.filter((order) => !order.shipped && order.status !== 'Incomplete');

    if (ordersFiltered.length > 0) {
      return ordersFiltered.map((item, index) => (
        <tr key={index}>
          <td>{dateRearrange(item.date_required.substr(0, 10))}</td>
          <td>
            <Link href to={`/payment-history/${item.ID}`}>
              {item.ID}
            </Link>
          </td>
          <td>{dogsSelectors.get(item.dog_id) !== undefined ? dogsSelectors.get(item.dog_id).name : 'N/A'}</td>
          <td>{item.status}</td>
          <td>{this.getOriginalAndDiscountedTotal(item.total, item.release)}</td>
          <td>
            {item.awaiting_approve ? (
              <button style={{ margin: 0, padding: 0, width: '88px' }} onClick={() => this.approve(item.ID)}>
                Approve
              </button>
            ) : (
              ''
            )}
          </td>
        </tr>
      ));
    }
    return (
      <tr>
        <td>You currently have not got any live orders.</td>
      </tr>
    );
  }

  renderShippedOrders() {
    const shippedOrders = this.state.orders.filter((item) => item.shipped);

    if (shippedOrders.length > 0) {
      return shippedOrders.map((item, index) => (
        <tr key={index}>
          <td>{dateRearrange(item.date_shipped.substr(0, 10))}</td>
          <td>
            <Link href to={`/payment-history/${item.ID}`}>
              {item.ID}
            </Link>
          </td>
          <td>{dogsSelectors.get(item.dog_id) !== undefined ? dogsSelectors.get(item.dog_id).name : 'N/A'}</td>
          <td>{item.status}</td>
          <td>£{item.total.toFixed(2)}</td>
          <td></td>
        </tr>
      ));
    }
    return (
      <tr>
        <td>No shipped orders were found.</td>
      </tr>
    );
  }

  nextStep() {
    // hide current modal
    if (this.state.cancelStep >= 1) {
      $(`#${cancellationStep[this.state.cancelStep - 1]}`).modal('hide');
    }

    // show next modal
    $(`#${cancellationStep[this.state.cancelStep]}`).modal('show');
    this.setState({ cancelStep: this.state.cancelStep + 1 });
  }

  cancelStep() {
    $(`#${cancellationStep[this.state.cancelStep - 1]}`).modal('hide');
    this.setState({ cancelStep: 0 });
  }

  submitQuestionnare() {
    const conf = window.confirm(`Are you sure you want to cancel ${dogsSelectors.getCurrentDog().name}'s order?`);

    if (!conf) {
      return;
    }

    let questionnaire = this.props.questionnaire;
    questionnaire.user_id = this.props.user.ID;
    questionnaire.dog_id = dogsSelectors.getCurrentDog()?.ID;

    ordersApi
      .questionnaire(questionnaire)
      .then((resp) => {
        if (resp.success === false) {
          window.alert('Your cancel request cannot be delivered. Please contact support for further help');
        } else {
          this.cancelOrder();
        }
      })
      .catch(() => {
        window.alert('Your cancel request cannot be delivered. Please contact support for further help.');
      });
  }

  reactivate() {
    if (this.state.cancelledOrderID === null) {
      return;
    }

    ordersApi
      .reactivateOrder(this.state.cancelledOrderID)
      .then((resp) => {
        if (resp.success === false) {
          window.alert('Your order cannot be reactivated. Please contact support for further help.');
        } else {
          window.alert('Your order has been reactivated successfully.');
          this.setState({ orderCancelled: false });
          this.cancelStep();
          this.renderOrders();
        }
      })
      .catch(() => {
        window.alert('Your order cannot be reactivated. Please contact support for further help.');
      });
  }

  render() {
    const reoccuringOrders = this.props.orders.filter((o) => o.is_reoccurring);
    const canDelay = reoccuringOrders.length > 0 ? reoccuringOrders.find((o) => o.status.includes('New')) : false;
    const canCancel =
      reoccuringOrders.length > 0
        ? reoccuringOrders.find((o) => o.status.includes('New') || o.status.includes('Failed'))
        : false;

    return (
      <div className="container">
        <Navigation />

        <SubNavigation />

        <div className="inner-container">
          <KaushanScriptHeading title="Orders - Live and shipped" />
          <Row>
            <div className="col-12 col-md-8 col-lg-9">
              <div className="table-container">
                <table className="table">
                  <tbody>
                    <tr className="heading">
                      <th>Due date</th>
                      <th>Order ID</th>
                      <th>Food for</th>
                      <th>Status</th>
                      <th>Cost</th>
                      <th></th>
                    </tr>
                    {this.renderLiveOrders()}
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr className="heading">
                      <th>Date shipped</th>
                      <th>Order ID</th>
                      <th>Food for</th>
                      <th>Status</th>
                      <th>Cost</th>
                      <th></th>
                    </tr>
                    {this.renderShippedOrders()}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-sm-8 col-md-4 col-lg-3">
              <BackgroundContainer>
                <div className="center bold grey-text" style={{ fontSize: 18, lineHeight: '22px', marginBottom: 5 }}>
                  Need to delay or cancel your order?
                </div>
                <div className="center grey-text" style={{ fontSize: 14, lineHeight: '18px' }}>
                  No problem! Just select the date you wish to delay to below
                </div>
                <div className="date-selection-box">
                  <p>Delay next delivery:</p>
                  <DateSelectionBanner
                    justIcon
                    currentDate={this.state.delayDate || this.state.orderDate}
                    currentMonth={this.state.delayMonth || this.state.orderMonth}
                    currentYear={this.state.delayYear || this.state.orderYear}
                    orderDate={this.state.orderDate}
                    orderMonth={this.state.orderMonth}
                    orderYear={this.state.orderYear}
                    orderDelayOffset
                    preventInitialSetState
                    futureDateSelectedHandler={(val) => {
                      if (!val) return;
                      this.setState({
                        delayDate: val.date,
                        delayMonth: val.month,
                        delayYear: val.year,
                      });
                    }}
                    onUpdateDate={(val) => {
                      if (!val) return;
                      this.setState({
                        delayDate: val.date,
                        delayMonth: val.month,
                        delayYear: val.year,
                      });
                    }}
                  />
                </div>
                <div>
                  <Row>
                    {this.state.orderDelayed ? (
                      <GreyText text="Order delayed!" fontSize={20} centered marginBottom />
                    ) : (
                      <button
                        type="submit"
                        style={canDelay ? {} : { opacity: 0.2, pointerEvents: 'none' }}
                        className="btn btn-orange send-width"
                        onClick={this.delayOrder}>
                        Delay Bespoke Order{' '}
                      </button>
                    )}
                    {canDelay ? (
                      ''
                    ) : (
                      <GreyText
                        text="Current subscription order cannot be delayed. Please wait for the next order to be generated."
                        fontSize={15}
                        centered
                      />
                    )}
                  </Row>
                  <Row>
                    {this.state.orderCancelled ? (
                      <GreyText text="Order cancelled!" fontSize={20} centered marginBottom />
                    ) : (
                      <button
                        type="submit"
                        style={canCancel ? {} : { opacity: 0.2, pointerEvents: 'none' }}
                        className="btn btn-orange send-width margin-bottom"
                        onClick={this.nextStep}>
                        Cancel Subscription{' '}
                      </button>
                    )}
                    {canCancel ? (
                      ''
                    ) : (
                      <GreyText
                        text="Current subscription order cannot be cancelled. Please wait for the next order to be generated."
                        fontSize={15}
                        centered
                      />
                    )}
                  </Row>
                </div>
              </BackgroundContainer>
            </div>
          </Row>
        </div>

        <Footer />

        <Reason cancelStep={this.cancelStep} nextStep={this.nextStep} />

        <Future cancelStep={this.cancelStep} nextStep={this.nextStep} />

        <Feedback cancelStep={this.cancelStep} nextStep={this.nextStep} />

        <Final cancelStep={this.cancelStep} submitQuestionnare={this.submitQuestionnare} />

        <CancelOrderConfirm reactivate={this.reactivate} />
      </div>
    );
  }
}

const PaymentWrapped = connect(
  () => ({
    user: sessionSelectors.getUser(),
    orders: ordersSelectors.getOrders(),
    questionnaire: ordersSelectors.getQuestionnaire(),
  }),
  (dispatch) => ({
    actions: {
      orders: bindActionCreators(ordersActionCreators, dispatch),
    },
  }),
)(withRouter(injectStripe(LiveOrders)));

export default ({ props }) => (
  <Elements>
    <PaymentWrapped {...props} />
  </Elements>
);
