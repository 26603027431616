import React from 'react';

import { Link } from 'react-router-dom';

import Calendar from '../components/Calendar';

const months = [
  {
    key: 0,
    month: 'January',
  },
  {
    key: 1,
    month: 'Feburary',
  },
  {
    key: 2,
    month: 'March',
  },
  {
    key: 3,
    month: 'April',
  },
  {
    key: 4,
    month: 'May',
  },
  {
    key: 5,
    month: 'June',
  },
  {
    key: 6,
    month: 'July',
  },
  {
    key: 7,
    month: 'August',
  },
  {
    key: 8,
    month: 'September',
  },
  {
    key: 9,
    month: 'October',
  },
  {
    key: 10,
    month: 'November',
  },
  {
    key: 11,
    month: 'December',
  },
];

const days = [
  {
    key: 1,
    day: 'Monday',
  },
  {
    key: 2,
    day: 'Tuesday',
  },
  {
    key: 3,
    day: 'Wednesday',
  },
  {
    key: 4,
    day: 'Thursday',
  },
  {
    key: 5,
    day: 'Friday',
  },
  {
    key: 6,
    day: 'Saturday',
  },
  {
    key: 7,
    day: 'Sunday',
  },
];

export default class DateSelectionBanner extends React.Component {
  constructor(props) {
    super(props);

    const newDate = new Date();

    this.state = {
      todaysDate: newDate.getDate(),
      todaysMonth: newDate.getMonth(),
      todaysYear: newDate.getFullYear(),
    };

    this.renderText = this.renderText.bind(this);
    this.handleArrowClick = this.handleArrowClick.bind(this);
    this.onRef = this.onRef.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  onRef(ref) {
    this.child = ref;
  }

  handleArrowClick(value) {
    let date = this.props.currentDate;
    let month = this.props.currentMonth;
    let year = this.props.currentYear;
    let days;

    if (value === 'left') {
      date -= 1;

      if (date < 1) {
        month -= 1;

        days = new Date(year, month + 1, 0).getDate(); // Number of days in the month
        date = days;

        if (month < 0) {
          year -= 1;
          month = 11;

          days = new Date(year, month + 1, 0).getDate(); // Number of days in the month
          date = days;
        }
      }

      return { date, month, year };
    }

    if (value === 'right') {
      date += 1;
      days = new Date(year, month + 1, 0).getDate(); // Number of days in the month

      if (date > days) {
        month += 1;
        date = 1;

        if (month > 11) {
          year += 1;
          month = 0;
          date = 1;
        }
      }

      return { date, month, year };
    }
  }

  renderText(date, month, year) {
    let currentMonth;
    let dayDescription;
    let day;

    if (month >= 0 && month <= 11) {
      currentMonth = months[month].month;
    }

    const thisDate = new Date(this.state.todaysYear, this.state.todaysMonth, this.state.todaysDate);
    const thisDateCopy = new Date(this.state.todaysYear, this.state.todaysMonth, this.state.todaysDate);

    const tomorrow = new Date(thisDateCopy.setDate(thisDateCopy.getDate() + 1));
    const yesterday = new Date(thisDateCopy.setDate(thisDateCopy.getDate() - 2));

    const selectedDate = new Date(year, month, date);

    if (selectedDate.getTime() === thisDate.getTime()) {
      dayDescription = '(Today)';
    } else if (selectedDate.getTime() === tomorrow.getTime()) {
      dayDescription = '(Tomorrow)';
    } else if (selectedDate.getTime() === yesterday.getTime()) {
      dayDescription = '(Yesterday)';
    } else {
      dayDescription = '';
    }

    day = selectedDate.getDay();

    // Converting Sunday from 0 to 7
    if (day === 0) {
      day = 7;
    }

    if (this.props.justIcon !== undefined) {
      return (
        <p className="date">
          {date > 9 ? date : `0${date}`}/{month + 1 > 9 ? month + 1 : `0${month + 1}`}/{year}
        </p>
      );
    }

    return (
      <p className="date">
        {currentMonth} {date}, {year} {dayDescription}
      </p>
    );
  }

  render() {
    const {
      currentDate,
      currentMonth,
      currentYear,
      onUpdateDate,
      notRenderText,
      futureDateSelectedHandler,
      preventInitialSetState,
      orderDelayOffset,
      orderDate,
      orderMonth,
      orderYear,
    } = this.props;

    if (this.props.justIcon !== undefined) {
      return (
        <div style={{ display: 'flex' }}>
          {!notRenderText && this.renderText(currentDate, currentMonth, currentYear)}
          <Link
            href
            to="#"
            onClick={(e) => {
              e.preventDefault();
              this.child.updateCalendar(currentMonth, currentYear);
              this.setState({ calendarVisible: !this.state.calendarVisible });
            }}>
            <img
              alt="calendar"
              style={{ marginLeft: '10px' }}
              className="calendar-icon"
              src="/assets/images/calendar-icon.png"
            />
          </Link>
          <div
            role="button"
            tabIndex="0"
            onKeyPress={() => {}}
            className={`${this.state.calendarVisible ? ' active ' : ''}calander-popup-container`}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ calendarVisible: false });
            }}>
            <Link
              href
              to="#"
              className="calendar-close-icon"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ calendarVisible: false });
              }}>
              x
            </Link>
            <Calendar
              onRef={this.onRef}
              currentDate={currentDate}
              currentMonth={currentMonth}
              currentYear={currentYear}
              popup
              futureDateSelectedHandler={futureDateSelectedHandler}
              preventInitialSetState={preventInitialSetState}
              orderDelayOffset={orderDelayOffset}
              orderDate={orderDate}
              orderMonth={orderMonth}
              orderYear={orderYear}
              onDateSelect={(val) => {
                this.setState({ calendarVisible: false });
                onUpdateDate(val);
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="date-selection-banner">
        <Link
          href
          to="#"
          onClick={(e) => {
            e.preventDefault();
            onUpdateDate(this.handleArrowClick('left'));
          }}>
          <img alt="back-arrow" className="date-selection-arrow-left" src="/assets/images/right-arrow.png" />
        </Link>
        <div className="date-selection-text">
          {!notRenderText && this.renderText(currentDate, currentMonth, currentYear)}
          <Link
            href
            to="#"
            onClick={(e) => {
              e.preventDefault();
              this.child.updateCalendar(currentMonth, currentYear);
              this.setState({ calendarVisible: !this.state.calendarVisible });
            }}>
            <img alt="calendar" className="calendar-icon" src="/assets/images/calendar-icon.png" />
          </Link>
        </div>
        <Link
          href
          to="#"
          onClick={(e) => {
            e.preventDefault();
            onUpdateDate(this.handleArrowClick('right'));
          }}>
          <img alt="arrow" className="date-selection-arrow" src="/assets/images/right-arrow.png" />
        </Link>
        <div
          role="button"
          tabIndex="0"
          onKeyPress={() => {}}
          className={`${this.state.calendarVisible ? ' active ' : ''}calander-popup-container`}
          onClick={(e) => {
            e.preventDefault();
            this.setState({ calendarVisible: false });
          }}>
          <Link
            href
            to="#"
            className="calendar-close-icon"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ calendarVisible: false });
            }}>
            x
          </Link>
          <Calendar
            onRef={this.onRef}
            currentDate={currentDate}
            currentMonth={currentMonth}
            currentYear={currentYear}
            popup
            futureDateSelectedHandler={futureDateSelectedHandler}
            preventInitialSetState={preventInitialSetState}
            orderDelayOffset={orderDelayOffset}
            orderDate={orderDate}
            orderMonth={orderMonth}
            orderYear={orderYear}
            onDateSelect={(val) => {
              this.setState({ calendarVisible: false });
              onUpdateDate(val);
            }}
          />
        </div>
      </div>
    );
  }
}
