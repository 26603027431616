import React from 'react';
import { Link } from 'react-router-dom';

export default class CheckboxNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }
  render() {
    const { label, onClick, onChange, isChecked, disabled } = this.props;

    return (
      <div className="checkbox-container">
        <label>
          {label}
          <input type="checkbox" onClick={onClick} checked={isChecked} disabled={disabled} />
          <span className="checkmark"></span>
        </label>
        {!label && (
          <textarea
            cols="50"
            value={this.state.value}
            onChange={(e) => {
              this.setState({ value: e.target.value });
              onChange(e);
            }}
            style={!isChecked ? { opacity: 0.5 } : {}}
            placeholder="Type your reason here..."
            readOnly={!isChecked}
          />
        )}
      </div>
    );
  }
}
