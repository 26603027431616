import React from 'react';
import { withRouter } from 'react-router-dom';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import SimplyPowesome from '../components/SimplyPowesome';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';
import FoodCard from '../components/FoodCard';

const key_features = [
  [
    {
      image: '/assets/images/calories-icon.png',
      subj: 'Activity monitoring 24/7',
      text: 'Monitor your dog\'s exercise levels 24/7 so you know exactly how many steps they have taken, how far they have been and how many calories they have burnt in one day!',
    },
    {
      image: '/assets/images/cost-the-app.png',
      subj: 'No guessing',
      text: 'Activity data from the activity tracker combines with your dog\'s personal details to automatically calculate their nutritional needs, dynamically adjusting the recipe and quantity of their complete food diet.',
    },
    {
      image: '/assets/images/cost-activity-trackers.png',
      subj: 'More than just a fashion statement!',
      text: 'Stylish, lightweight and waterproof, our activity collar helps keep your dog fit and healthy.',
    },
  ],
  [
    {
      image: '/assets/images/bluetooth-icon.png',
      subj: 'Bluetooth enabled',
      text: 'The data from your dog\'s Fitkolar will automatically upload when it\'s near your phone.',
    },
    {
      image: '/assets/images/tailored-food.png',
      subj: 'Tailored food',
      text: 'Provides us with all the data we need to prepare meals that fully meet your dog\'s dietary needs.',
    },
    {
      image: '/assets/images/more-icon.png',
      subj: 'More',
      text: 'The Fitkolar provides you with information about your dog\'s activities by using sensors that track their movement. The data provided is intended to be a close estimation of their activity.',
    },
  ],
];


class MoreThanFood7 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { windowWidth: window.innerWidth };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/mtf7-banner.jpg)" heading="Fitkolar" subtext="a diet at unique as your pet" />
        <div className="row am-container">
          <div className="col-md-6">
            <img src="/assets/images/activity-monitoring.png" />
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-8">
                <h1 className="lh-2 mb-3">Activity monitoring</h1>
                <h6 className="color-orange fw-600 mb-4">Fitkolar</h6>
                <p className="lh-2 mb-4">Find out how healthy and active your dog really is with our innovative activity tracker.</p>
                <p className="lh-2">Attach our uniquely developed Fitkolar to your 4-legged friend's collar to help you monitor their lifestyle, make sure it takes all these essential steps a day and let us create his nutritious bespoke diet.</p>
              </div>
              <div className="col-md-4" />
            </div>
          </div>
        </div>
        <div className="kf-container">
          <h1 className="text-center mb-5">Key features</h1>
          {key_features.map((features, rindex) => (<div key={rindex} className="row">
            {features.map((f, cindex) => (<div key={cindex} className="col-md-4">
              <FoodCard image={f.image} subj={f.subj} text={f.text}/>
            </div>))}
          </div>))}
        </div>
        <div className="ts-container fw-600">
          <h3 className="color-orange text-center mb-3 font-script">Tech specs</h3>
          <div className="row">
          <div className="col-1" />
            <div className="col-10">
              <div className="row">
                <div className="col-md-6">
                  <p className="color-white mb-3"><strong>Display: </strong>OLED screen Fits any dog's collar</p>
                  <p className="color-white mb-3"><strong>Lightweight: </strong>25 grams</p>
                  <p className="color-white mb-3"><strong>Waterproof: </strong>IP67 rating - Up to 1 metre for 30 seconds</p>
                  <p className="color-white mb-3"><strong>Suitable for indoor and outdoor wear</strong></p>
                  <p className="color-white mb-3"><strong>Battery type: </strong>100mAh LiPo</p>
                </div>
                <div className="col-md-6">
                  <p className="color-white mb-3"><strong>Battery life: </strong>(rechargeable) up to 7 days(Usage dependant) USB charge cable</p>
                  <p className="color-white mb-3"><strong>Wireless connection: </strong>Bluetooth version 4.0</p>
                  <p className="color-white mb-3"><strong>Compatibility: </strong>Works with Android 4.3, iOS 8.3 or above</p>
                  <p className="color-white mb-3"><strong>Colour: </strong>Black</p>
                  <p className="color-white mb-3"><strong>Warranty: </strong>1 year</p>
                </div>
              </div>
            </div>
            <div className="col-1" />
          </div>
        </div>
        <div className="row gr-container">
          <div className="col-1" />
          <div className="col-md-7 mr-5 ml-5">
            <h3 className="color-orange font-script mb-3">
                            Get up and running- one paw at a time!
            </h3>
            <table>
              <tr>
                <td className="subj"><p className="color-white lh-2 mb-4"><strong>Step 1</strong> - Download the free Kasper & Kitty App to your mobile phone by selecting your device: Android (link) iOS (link)</p></td>
              </tr>
              <tr>
                <td className="subj"><p className="color-white lh-2 mb-4"><strong>Step 2</strong> - Charge your FitKolar by connecting it to a USB port on your computer. The light will stop flashing and turn solid once it's ready to use. This will take around XX minutes.</p></td>
              </tr>
              <tr>
                <td className="subj"><p className="color-white lh-2 mb-4"><strong>Step 3</strong> - Pair the FitKolar to your phone/device using the device's built in Bluetooth set up assistant</p></td>
              </tr>
              <tr>
                <td className="subj"><p className="color-white lh-2 mb-4"><strong>Step 4</strong> - Attach the FitKolar to your best friend's collar</p></td>
              </tr>
              <tr>
                <td className="subj"><p className="color-white lh-2 mb-4"><strong>Step 5</strong> - Follow the App's simple on-screen instructions to create your best friend's profile and set their fitness goals</p></td>
              </tr>
              <tr>
                <td className="subj"><p className="color-white lh-2 mb-4"><strong>Step 6</strong> - Time for walkies - you're ready to go!</p></td>
              </tr>
              <tr>
                <td colSpan={2}><p className="color-white">Grab your dog's lead and head off for a new adventure together!</p></td>
              </tr>
            </table>
          </div>
          <div className="col-3" />
        </div>
        <div className="row wn-container">
          <div className="col-1" />
          <div className="col-md-6 mr-5 ml-5">
            <h1 className="mb-4">What's next</h1>
            <h6 className="color-orange mb-4 fw-600">Track your best friend's progress on K&amp;K App.</h6>
            <p className="lh-2 mb-4">A quick glance at the dashboard will show you how many steps your pet has taken, the distance they have covered and how much rest they're really getting.</p>
            <p className="lh2 mb-4">The data from the activity tracker will automatically update when its near your phone so that you can make sure your dog is packing enough fun into each day.</p>
            <p className="lh2">The app also sends this information to us so that we can tailor your pet's meals to meet their exact nutritional needs, keeping them fit and healthy.</p>
          </div>
          <div className="col-1" />
          <div className="col-md-2">
            <img src="/assets/images/phone-icon.png" className="hide-on-mobile" />
          </div>
          <div className="col-1" />
        </div>
        <div className="row wtl-container text-center">
          <div className="col-3" />
          <div className="col-6">
            <h3 className="fw-bold mb-2">Woofness they'll love</h3>
            <h6 className="color-orange fw-bold lh-5">Treat your four-legged friend to a K&amp;K bespoke tasty meal plan for a healthier life!</h6>
            <button
              onClick={() => {
                scrollTo(0,0);
                this.props.history.push('/login');
              }}
              type="button"
              className="btn btn-grey"
            >Register</button>
          </div>
          <div className="col-3" />
        </div>
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}

export default withRouter(MoreThanFood7);
