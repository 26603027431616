import * as actionTypes from './actionTypes';

const initialState = {
  firstName: '',
  lastName: '',
  company: '',
  phone: '',
  streetAddressLine1: '',
  streetAddressLine2: '',
  city: '',
  zipPostalCode: '',
  county: '',
  country: 'GB',
  note: '',
  deliveryMethod: '',
  deliveryDate: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DELIVERY_UPDATE:
      return {
        ...action.delivery,
      };
    case actionTypes.DELETE_ALL_INTO_DELIVERY:
      return { ...initialState };
    default:
      return state;
  }
};
