import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@material-ui/core';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import CheckoutSteps from '../components/checkout/CheckoutSteps';

import { getOrder } from '../data/orders/index';

import * as sessionSelectors from '../services/session/selectors';
import * as productsActions from '../data/products/actions';
import * as dogsSelectors from '../data/dogs/selectors';

class OrderConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: {},
      fitkolar: undefined,
    };
  }

  componentDidMount() {
    const orderID = this.props.match.params.id;

    getOrder(orderID)
      .then(order => this.setState({ order }));

    this.props.actions.products.getProducts()
      .then((prod) => {
        if (Array.isArray(prod)) {
          const fitkolarArr = prod.filter(item => item.is_fitkolar);
          if (fitkolarArr.length > 0) {
            this.setState({ fitkolar: fitkolarArr[0] });
          }
        }
      });
  }

  render() {
    const { order, fitkolar } = this.state;
    const { user } = this.props;

    return (
      <div className="product-container container">
        <Navigation
          white
          textColor="white-text"
        />

        <div className="inner-container">
          <div className="checkout-nav">
            <div className="back-to-shop">
              <Link to="/shop">Back to shop</Link>
            </div>
            <CheckoutSteps step="3" />
          </div>

          <div className="menu-title">
            <img className="title-image" src="../assets/images/dashboard-nav-icon.png" alt="#" />
              ORDER CONFIRMATION
          </div>

          <Grid container className="confirmation-main">
            <Grid item xs={12} className="confirmation-right confirmation-right-mobile">
              <Grid item xs={12} className="back-to-somewhere">
                <button
                  className="back-to back-dashboard"
                  onClick={() => this.props.history.push('/dashboard')}
                >
                  GO TO DASHBOARD
                </button>

                <button
                  className="back-to view-subscription"
                  onClick={() => this.props.history.push('/your-dogs-food')}
                >
                  VIEW SUBSCRIPTION BOX
                </button>
              </Grid>
            </Grid>

            <Grid container xs={12} md={8} className="confirmation-left">

              <Grid item xs={12} className="greeting-title">
                <p className="header-main">Hello {this.props.user.first_name} and {this.props.dog.name},</p>
                <p className="greeting-content">Thank you for shopping with us. We'll get started on your order right away. We know how anxious your four-legged friend is about getting their food!
                </p>
              </Grid>

              <Grid container xs={12} className="date-info">
                <Grid item xs={12} md={6} className="date-outside">
                  <div className="date-box border-element">
                    <div className="header-main border-bottom">
                      ESTIMATED DELIVERY DATE
                    </div>
                    <div className="date-content">
                      {new Date(order.date_required).toDateString()}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className="date-outside">
                  <div className="date-box border-element">
                    <div className="header-main border-bottom">
                      YOUR ORDER WILL BE SENT TO
                    </div>
                    <div className="date-content">
                      <p>{user.delivery_address.address1}</p>
                      <p>{user.delivery_address.address2}</p>
                      <p>{user.delivery_address.town}, {user.delivery_address.postcode}</p>
                      <p>{user.delivery_address.county}</p>
                      <p>{user.delivery_address.country}</p>
                    </div>
                  </div>
                </Grid>
              </Grid>

              {Array.isArray(order.items) && order.items.length > 0 &&
                <Grid container xs={12} className="order-info">
                  <Grid item xs={12} className="order-info-inside border-element">

                    <Grid item xs={12} className="header-main order-details-title">
                      <div className="order-title">
                        ORDER DETAILS
                      </div>
                      <div className="order-number">
                        ORDER NUMBER #{order.ID}
                      </div>
                    </Grid>

                    <Grid container xs={12}>
                      {order.items !== undefined && order.items.map(item => (
                        <Grid container xs={12} className="order-items border-top">
                          <Grid item xs={2} className="order-image">
                            <img className="item-image" src={item.product.image.Url} alt="" />
                            <span className="item-counts">{item.quantity}</span>
                          </Grid>
                          <Grid item xs={8} className="order-name">
                            <span>{item.product.name}</span>
                          </Grid>
                          <Grid item xs={2} className="order-price">
                            £{item.price.toFixed(2)}
                          </Grid>
                        </Grid>
                      ))}

                      {order.fitkolars > 0 && fitkolar !== undefined &&
                        <Grid container xs={12} className="order-items border-top">
                          <Grid item xs={2} className="order-image">
                            <img className="item-image" src={fitkolar.image.Url} alt="" />
                            <span className="item-counts">{order.fitkolars}</span>
                          </Grid>

                          <Grid item xs={8} className="order-name">
                            <span>{fitkolar.name}</span>
                          </Grid>

                          <Grid item xs={2} className="order-price">
                            £{(fitkolar.price * order.fitkolars).toFixed(2)}
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>

            <Grid item xs={12} md={4} className="confirmation-right">
              <Grid item xs={12} className="back-to-somewhere">
                <button
                  className="back-to back-dashboard"
                  onClick={() => this.props.history.push('/dashboard')}
                >
                  GO TO DASHBOARD
                </button>

                <button
                  className="back-to view-subscription"
                  onClick={() => this.props.history.push('/your-dogs-food')}
                >
                  VIEW  SUBSCRIPTION BOX
                </button>
              </Grid>

              <Grid item xs={12} >
                <div className="large-dog-image">
                  <img src={require('../assets/images/large-dog-image.png')} />
                </div>
              </Grid>
            </Grid>
          </Grid>

        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(() => ({
  user: sessionSelectors.getUser(),
  dog: dogsSelectors.getCurrentDog(),
}), dispatch => ({
  actions: {
    products: bindActionCreators(productsActions, dispatch),
  },
}), null, { withRef: true })(OrderConfirmation);
