import React from 'react';

const CrossIcon = ({ classes, clickHandler }) => (
  <svg className={classes} onClick={clickHandler} width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Tablet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="cross-icon" transform="translate(-323.000000, -709.000000)" fill="#FAB522">
        <g id="Bitmap" transform="translate(302.000000, 688.000000)">
          <path d="M29.8265827,28.4998437 L35.7251464,22.6012799 C36.0916179,22.2351212 36.0916179,21.6410123 35.7251464,21.2748536 C35.358675,20.9083821 34.7651915,20.9083821 34.3987201,21.2748536 L28.5001563,27.1734173 L22.6012799,21.2748536 C22.2348085,20.9083821 21.641325,20.9083821 21.2748536,21.2748536 C20.9083821,21.6410123 20.9083821,22.2351212 21.2748536,22.6012799 L27.17373,28.4998437 L21.2748536,34.3984074 C20.9083821,34.7645661 20.9083821,35.358675 21.2748536,35.7248338 C21.4580893,35.9077568 21.6982343,35.9993746 21.9380667,35.9993746 C22.1778991,35.9993746 22.4180442,35.9077568 22.6012799,35.7245211 L28.5001563,29.8259573 L34.3987201,35.7245211 C34.5819558,35.9077568 34.8221009,35.9993746 35.0619333,35.9993746 C35.3017657,35.9993746 35.5419107,35.9077568 35.7251464,35.7245211 C36.0916179,35.3583623 36.0916179,34.7642534 35.7251464,34.3980947 L29.8265827,28.4998437 Z" id="Path"></path>
        </g>
      </g>
    </g>
  </svg>
);
export default CrossIcon;
