import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CheckboxNew from '../../components/CheckboxNew';
import * as dogsSelectors from '../../data/dogs/selectors';
import * as ordersActionCreators from '../../data/orders/actions';

import CloseIcon from '../../assets/images/wizard/review-close.png';

class Reason extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPriceTooHigh: false,
      isOutOfBudget: false,
      isNotGoodValue: false,
      isMonthlyPayments: false,

      isDeliveryServicesIssues: false,

      isNeedMoreTime: false,
      isFreeTrial: false,
      isPausing: false,
      isSkill: false,
      isLeftOverFood: false,

      isManagingPlan: false,

      isIssuesWithFood: false,
      isReleaseNotes: false,
      reasonNotes: '',

      enableNextStep: false,
    };

    this.changePriceTooHighGroup = this.changePriceTooHighGroup.bind(this);
    this.updateNeedMoretimeGroup = this.updateNeedMoretimeGroup.bind(this);
    this.changeEnableNextStepStatus = this.changeEnableNextStepStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  changePriceTooHighGroup() {
    if (!this.state.isPriceTooHigh) {
      this.setState(
        {
          isOutOfBudget: false,
          isNotGoodValue: false,
          isMonthlyPayments: false,
        },
        () => {
          this.changeEnableNextStepStatus();
        },
      );
    }
  }

  updateNeedMoretimeGroup() {
    if (!this.state.isNeedMoreTime) {
      this.setState(
        {
          isFreeTrial: false,
          isPausing: false,
          isSkill: false,
          isLeftOverFood: false,
        },
        () => {
          this.changeEnableNextStepStatus();
        },
      );
    }
  }

  handleChange(e) {
    this.setState({ reasonNotes: e.target.value }, () => {
      this.changeEnableNextStepStatus();
    });
  }

  nextStep() {
    this.props.updateQuestionnaire({
      out_of_budget: this.state.isOutOfBudget,
      not_good_value: this.state.isNotGoodValue,
      monthly_payments: this.state.isMonthlyPayments,
      delivery_services_issues: this.state.isDeliveryServicesIssues,
      free_trial: this.state.isFreeTrial,
      pausing: this.state.isPausing,
      still_taken: this.state.isSkill,
      left_over_food: this.state.isLeftOverFood,
      managing_plan_difficulty: this.state.isManagingPlan,
      issues_with_food: this.state.isIssuesWithFood,
      reason_note: this.state.reasonNotes,
    });

    this.props.nextStep();
  }

  changeEnableNextStepStatus() {
    const {
      isOutOfBudget,
      isNotGoodValue,
      isMonthlyPayments,
      isDeliveryServicesIssues,
      isFreeTrial,
      isPausing,
      isSkill,
      isLeftOverFood,
      isManagingPlan,
      isIssuesWithFood,
      reasonNotes,
    } = this.state;

    const enableStatus =
      isOutOfBudget ||
      isNotGoodValue ||
      isMonthlyPayments ||
      isDeliveryServicesIssues ||
      isFreeTrial ||
      isPausing ||
      isSkill ||
      isLeftOverFood ||
      isManagingPlan ||
      isIssuesWithFood ||
      reasonNotes.length > 0;

    this.setState({ enableNextStep: enableStatus });
  }

  render() {
    const dogName = dogsSelectors.getCurrentDog().name;
    return (
      <div
        className="modal animated fadeIn reason-modal"
        id="reason"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered row justify-content-center" role="document">
          <div className="modal-content">
            <div className="modal-header px-0 pt-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Cancel {dogName}&apos;s subscription
              </h5>
              <button
                type="button"
                className="close text-right"
                onClick={this.props.cancelStep}
                data-dismiss="modal"
                aria-label="Close">
                <img src={CloseIcon} alt="" />
              </button>
            </div>

            <div className="modal-body px-0">
              <div style={{ width: '95%' }}>
                <div className="description-area">
                  <div className="description-1">
                    Before you leave us, would you take a few minutes to answer some questions, so that we know what to
                    do better for next time?
                  </div>
                  <div className="description-2">
                    Your feedback can help us ensure all our customer's and their dogs have the best experience
                    possible. As a young business / company, we need your input to grow, so let us know what you think.
                  </div>
                  <div className="description-3">Your Kasper & Kitty team</div>
                </div>

                <div className="step-area">
                  <div className="outer-circle">
                    <div className="inner-circle">1</div>
                  </div>
                  <div className="divider-1"></div>
                  <div className="inner-circle">2</div>
                  <div className="divider-2"></div>
                  <div className="inner-circle">3</div>
                  <div className="divider-3"></div>
                  <div className="inner-circle">4</div>
                </div>

                <div className="reason-area">
                  <div className="reason-title">
                    <div>Why are you cancelling?</div>
                    <div>*You may select multiple</div>
                  </div>
                  <div className="reason-list">
                    <CheckboxNew
                      onClick={() => {
                        this.setState({ isPriceTooHigh: !this.state.isPriceTooHigh }, () => {
                          this.changePriceTooHighGroup();
                        });
                      }}
                      label="Price too high"
                      isChecked={this.state.isPriceTooHigh}
                      disabled={false}
                      white
                    />
                    <div className="sub-list">
                      <CheckboxNew
                        onClick={() => {
                          this.setState({ isOutOfBudget: !this.state.isOutOfBudget }, () => {
                            this.changeEnableNextStepStatus();
                          });
                        }}
                        label="Price is out of my budget"
                        isChecked={this.state.isOutOfBudget}
                        disabled={!this.state.isPriceTooHigh}
                        white
                      />
                      <CheckboxNew
                        onClick={() => {
                          this.setState({ isNotGoodValue: !this.state.isNotGoodValue }, () => {
                            this.changeEnableNextStepStatus();
                          });
                        }}
                        label="Not good value for money"
                        isChecked={this.state.isNotGoodValue}
                        disabled={!this.state.isPriceTooHigh}
                        white
                      />
                      <CheckboxNew
                        onClick={() => {
                          this.setState({ isMonthlyPayments: !this.state.isMonthlyPayments }, () => {
                            this.changeEnableNextStepStatus();
                          });
                        }}
                        label="I don't like monthly payments"
                        isChecked={this.state.isMonthlyPayments}
                        disabled={!this.state.isPriceTooHigh}
                        white
                      />
                    </div>
                    <CheckboxNew
                      onClick={() => {
                        this.setState({ isDeliveryServicesIssues: !this.state.isDeliveryServicesIssues }, () => {
                          this.changeEnableNextStepStatus();
                        });
                      }}
                      label="Delivery and service issues"
                      isChecked={this.state.isDeliveryServicesIssues}
                      disabled={false}
                      white
                    />
                    <CheckboxNew
                      onClick={() => {
                        this.setState({ isNeedMoreTime: !this.state.isNeedMoreTime }, () => {
                          this.updateNeedMoretimeGroup();
                        });
                      }}
                      label="Need more time to try it"
                      isChecked={this.state.isNeedMoreTime}
                      disabled={false}
                      white
                    />
                    <div className="sub-list">
                      <CheckboxNew
                        onClick={() => {
                          this.setState({ isFreeTrial: !this.state.isFreeTrial }, () => {
                            this.changeEnableNextStepStatus();
                          });
                        }}
                        label="Just wanted the free trial"
                        isChecked={this.state.isFreeTrial}
                        disabled={!this.state.isNeedMoreTime}
                        white
                      />
                      <CheckboxNew
                        onClick={() => {
                          this.setState({ isPausing: !this.state.isPausing }, () => {
                            this.changeEnableNextStepStatus();
                          });
                        }}
                        label="Just pausing, will resume once I have completed trial"
                        isChecked={this.state.isPausing}
                        disabled={!this.state.isNeedMoreTime}
                        white
                      />
                      <CheckboxNew
                        onClick={() => {
                          this.setState({ isSkill: !this.state.isSkill }, () => {
                            this.changeEnableNextStepStatus();
                          });
                        }}
                        label="My dog needs more time to try it"
                        isChecked={this.state.isSkill}
                        disabled={!this.state.isNeedMoreTime}
                        white
                      />
                      <CheckboxNew
                        onClick={() => {
                          this.setState({ isLeftOverFood: !this.state.isLeftOverFood }, () => {
                            this.changeEnableNextStepStatus();
                          });
                        }}
                        label="I still have a lot of leftover food that needs to be finished first"
                        isChecked={this.state.isLeftOverFood}
                        disabled={!this.state.isNeedMoreTime}
                        white
                      />
                    </div>
                    <CheckboxNew
                      onClick={() => {
                        this.setState({ isManagingPlan: !this.state.isManagingPlan }, () => {
                          this.changeEnableNextStepStatus();
                        });
                      }}
                      label="Managing the plan was to difficult"
                      isChecked={this.state.isManagingPlan}
                      disabled={false}
                      white
                    />
                    <CheckboxNew
                      onClick={() => {
                        this.setState({ isIssuesWithFood: !this.state.isIssuesWithFood }, () => {
                          this.changeEnableNextStepStatus();
                        });
                      }}
                      label="Issues with the food"
                      isChecked={this.state.isIssuesWithFood}
                      white
                      disabled={false}
                    />
                    <CheckboxNew
                      onClick={() => {
                        this.setState({
                          isReleaseNotes: !this.state.isReleaseNotes,
                        });
                      }}
                      isChecked={this.state.isReleaseNotes}
                      onChange={this.handleChange}
                      disabled={false}
                      white
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button className="cancel-btn" onClick={this.props.cancelStep}>
                Cancel
              </button>
              <button
                className={`next-btn ${this.state.enableNextStep ? '' : 'disabled'}`}
                onClick={this.nextStep}
                disabled={!this.state.enableNextStep}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questionnaire: state.data.orders.questionnaire,
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestionnaire: (payload) => dispatch(ordersActionCreators.updateQuestionnaire(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reason));
