import React from 'react';
import { Link } from 'react-router-dom';

export default class Checkbox extends React.Component {
  render() {
    const {
      disabled,
      active,
      label,
      onClick,
      centered,
      white,
    } = this.props;

    let label_par = <p className="label">{label}</p>;
    if (label.includes("Terms & Conditions")) {
      label_par = <p className="label">I agree to the Kasper & Kitty <a href="/terms-and-conditions" onClick={(e) => e.stopPropagation()}>Terms & Conditions</a></p>
    }

    return (
      <a
        style={disabled ? {opacity: 0.3, pointerEvents: 'none'} : {}}
        className={`${centered ? ' center ' : ''}checkbox-container`}
        onClick={onClick}
      >
        <div className={`${white ? ' white ' : ''}checkbox`}>
          <img
            src="/assets/images/tick.png"
            className={`${active == true ? ' active ' : ''}tick`}
            alt="Tick Icon"
          />
        </div>
        {label_par}
      </a>
    );
  }
}
