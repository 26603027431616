import React, { Component } from 'react';

export default class FoodCard extends Component {
  render() {
    return (
      <div className="foodcard-container">
        <img src={this.props.image} className="mb-4" />
        <h4 className="color-orange font-script mb-4">{this.props.subj}</h4>
        <p>{this.props.text}</p>
      </div>
    );
  }
}
