import React from 'react';

export default class InputField extends React.Component {
  render() {
    const {
      type,
      value,
      placeholder,
      onChangeText,
      disable,
      noMarginBoottom,
      reference,
      white,
      name,
      style,
    } = this.props;
    return (
      <input
        min="0"
        className={`${(noMarginBoottom ? ' no-margin-bottom ' : '') + (disable ? ' disabled ' : '') + (white ? ' white ' : '')}input-field`}
        type={type}
        name={name}
        value={value}
        onChange={onChangeText}
        placeholder={placeholder}
        disabled={!!disable}
        ref={reference}
        style={style}
      />
    );
  }
}
