import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as productsActions from '../data/products/actions';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import SimplyPowesome from '../components/SimplyPowesome';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';
import CheckboxString from '../components/CheckboxString';
import DentalCard from '../components/DentalCard';
import SgSection from '../components/SgSectoin';

const txtList = [
  'Uniquely recommended for your dog',
  'Composition fully complement their K&K\'s meals',
  'Minimize the risk of allergies',
  'Packed with nutritious ingredients',
  'Keep calories under control',
  'Part of your dog\'s monthly diet plan',
];

class MoreThanFood4 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    this.props.actions.products.getProducts()
      .then((prod) => {
        const t = [];

        for (const p in prod) {
          for (const c in prod[p].categories) {
            if (prod[p].categories[c].name === 'Treats') {
              t.push(prod[p]);
            }
          }
        }

        this.setState({ products: t });
      });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/mtf4-banner.jpg)" heading="Other food" subtext="a diet at unique as your pet" />
        <div className="what-would-container">
          <div className="row">
            <div className="col-2" />
            <div className="col-md-5 left-container mr-5 ml-5">
              <h1>What would life be without a treat?</h1>
              <h6 className="color-orange fw-600">Your four-legged friend deserves that pat on the back, and a little treat before nap time just as much as we do.</h6>
              <p>But what if they could be guilt-free?</p>
            </div>
            <div className="col-3 right-container hide-on-mobile">
              <img src="/assets/images/golden-dog.png" className="hide-on-mobile"/>
            </div>
            <div className="col-2" />
          </div>
        </div>
        <div className="treats-banner">
          <div className="row">
            <div className="col-6" />
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="color-orange font-script lh-2 mb-3">Our treats aren't like other pet treats</h3>
                  {txtList.map((text, index) => <CheckboxString key={index} text={text} />)}
                </div>
                <div className="col-4" />
              </div>
            </div>
          </div>
        </div>
        <div className="inner-container">
          <div className="guilt-container">
            <h1>Guilt free treats</h1>
            <div className="row justify-content-center">
              {this.state.products.map(prod => (
                <div key={prod.ID} className="col-6 col-sm-4">
                  <DentalCard
                    image={(prod.image !== undefined) ? prod.image.Url : ''}
                    subj={prod.name}
                    text={(prod.description.length > 150) ? prod.description.substring(0, 150) + '...' : prod.description }
                    id={prod.ID}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <SgSection />
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}

export default connect(() => ({}), dispatch => ({
  actions: {
    products: bindActionCreators(productsActions, dispatch),
  },
}), null, { withRef: true })(MoreThanFood4);
