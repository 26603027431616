import React from 'react';

import { Link } from 'react-router-dom';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import SimplyPowesome from '../components/SimplyPowesome';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';
import DentalCard from '../components/DentalCard';
import SgSection from '../components/SgSectoin';

const wetfoods = [
  [
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Grain Free Duck Tray',
      text: 'Training treats for younger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Grain Free Lamb Tray',
      text: 'Training treats for younger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Cereal Free Chicken',
      text: 'Training treats for younger dogs',
    },
  ],
  [
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Cereal Free White Fish',
      text: 'Training treats for yonger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Turkey Cereal Free Weight Control',
      text: 'Training treats for yonger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Lamb Cereal Free Weight Control',
      text: 'Training treats for yonger dogs',
    },
  ],
  [
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Duck Cereal Free Weight Control',
      text: 'Training treats for yonger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: 'Cereal Free Puppy',
      text: 'Training treats for yonger dogs',
    },
    {
      image: '/assets/images/grey-circle.png',
      subj: '??????',
      text: 'Training treats for yonger dogs',
    },
  ],
];

export default class MoreThanFood6 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { windowWidth: window.innerWidth };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/mtf5-banner.jpg)" heading="more than food" subtext="a diet at unique as your pet" />
        <div className="nulla-container">
          <div className="row">
            <div className="col-2" />
            <div className="col-5 left-container">
              <h1 className="mb-4">Nullia in sodales dolor vulputate</h1>
              <h6 className="color-orange fw-600 lh-2 mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</h6>
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse</p>
            </div>
            <div className="col-2 right-container">
              <img src="/assets/images/spotted-dog.png" />
            </div>
            <div className="col-3" />
          </div>
        </div>
        <div className="wetfood-container">
          {wetfoods.map((row, rindex) => (<div key={rindex} className="row">
            {row.map((col, cindex) => (<div key={cindex} className="col">
              <DentalCard image={col.image} subj={col.subj} text={col.text} />
            </div>))}
          </div>))}
        </div>
        <SgSection />
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
