import * as actionTypes from './actionTypes';

const initialState = {
  dogs: {},
  storeDogs: [],
  breeds: {},
  allergens: {},
  flavours: {},
  conditions: {},
  diets: {},
  currentDog: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DOG: {
      const newDogs = [...state.storeDogs];
      newDogs.push(action.dog);
      return {
        ...state,
        storeDogs: newDogs,
        currentDog: action.dog,
      };
    }
    case actionTypes.UPDATE_DOG: {
      const dogs = [...state.storeDogs];
      dogs[action.index][action.key] = action.value;
      return {
        ...state,
        storeDogs: dogs,
        currentDog: state.storeDogs[action.index],
      };
    }
    case actionTypes.REMOVE_DOG: {
      const dogList = [...state.storeDogs];
      dogList.splice(action.index, 1);
      if (dogList.length !== 0) {
        return {
          ...state,
          storeDogs: dogList,
        };
      }
      return {
        ...state,
        storeDogs: dogList,
        currentDog: null,
      };
    }
    case actionTypes.TOGGLE_DOG:
      return {
        ...state,
        currentDog: state.storeDogs[action.index],
      };
    case actionTypes.CLEAR:
      return Object.assign({}, state, {
        breeds: {},
        allergens: {},
        flavours: {},
        conditions: {},
        dogs: {},
      });
    case actionTypes.CLEAR_STORE_DOGS:
      return Object.assign({}, state, {
        storeDogs: [],
        currentDog: null,
      });
    case actionTypes.SELECT_DOG:
      return Object.assign({}, state, {
        currentDog: action.id,
      });
    case actionTypes.GET_BREEDS:
      return {
        ...state,
        breeds: action.breeds,
      };
    case actionTypes.UPDATE: {
      const newState = Object.assign({}, state);

      if (action.state.dog) {
        const updatedDogID = state.currentDog;

        const allDogs = state.dogs;

        allDogs[updatedDogID] = action.state.dog;

        return Object.assign({}, state, {
          dogs: allDogs,
        });
      }

      if (action.state.dogs) {
        newState.dogs = {
          ...state.dogs,
          ...action.state.dogs.reduce((prev, curr) => ({
            ...prev,
            [curr.ID]: curr,
          }), {}),
        };
      }

      if (action.state.breeds) {
        newState.breeds = {
          ...state.breeds,
          ...action.state.breeds.reduce((prev, curr) => ({
            ...prev,
            [curr.ID]: curr,
          }), {}),
        };
      }

      if (action.state.allergens) {
        newState.allergens = {
          ...state.allergens,
          ...action.state.allergens.reduce((prev, curr) => ({
            ...prev,
            [curr.ID]: curr,
          }), {}),
        };
      }

      if (action.state.flavours) {
        newState.flavours = {
          ...state.flavours,
          ...action.state.flavours.reduce((prev, curr) => ({
            ...prev,
            [curr.ID]: curr,
          }), {}),
        };
      }

      if (action.state.conditions) {
        newState.conditions = {
          ...state.conditions,
          ...action.state.conditions.reduce((prev, curr) => ({
            ...prev,
            [curr.ID]: curr,
          }), {}),
        };
      }

      if (action.state.diets) {
        newState.diets = {
          ...state.diets,
          ...action.state.diets.reduce((prev, curr) => ({
            ...prev,
            [curr.ID]: curr,
          }), {}),
        };
      }

      return newState;
    }
    default:
      return state;
  }
};
