import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import Textfield from '../../Textfield';

const DeliveryNote = ({ note, handleChange }) => (
  <Grid className="border-element h-100" id="delivery-note">
    <Grid container className="border-bottom">
      <h3 className="header-main">Note at home?</h3>
    </Grid>
    <Grid container className="border-body" spacing={16}>
      <Grid item xs={12}>
        <p className="info-text mt-2">Leave us a note for delivery instructions,
        don’t forget to let the postman know about your dog’s
        hunting preferences.
        </p>
      </Grid>
      <Grid item xs={12}>
        <Textfield
          label="Your Note"
          multiline
          value={note}
          onChange={handleChange}
          rows="4"
          fullWidth
        />
      </Grid>
    </Grid>
  </Grid>
);

export default DeliveryNote;
