import React from 'react';
import logoImage from '../assets/images/new_logo.png';
import logoImageMob from '../assets/images/logo-mobile.svg';

class ForgotPasswordCard extends React.Component {
  render() {
      const logoSize = {
          maxWidth:'110px',
      };
    return (
      <div className="animated fadeInLeft forgotten-password-card px-3 py-xl-4 py-lg-4 py-md-2 py-sm-2 py-2 row justify-content-center flex-column text-center">
        <div className={"col-12 text-center"}>
            <img  style={logoSize} src={logoImage} className="desktop mx-auto py-xl-5 py-lg-5 py-md-3 py-sm-1 py-0 pb-2 animated fadeInDown delay-_5s" alt="" />
            <img src={logoImageMob} className="mobile mx-auto py-xl-5 py-lg-5 py-md-3 py-sm-1 py-0 pb-2 animated fadeInDown delay-_5s" alt="" />
        </div>
        <h5 className="title text-uppercase font-weight-bold px-5 animated fadeInDown delay-_5s">{this.props.title}</h5>
        <p className="description px-4 animated fadeInUp delay-_5s">{this.props.description}</p>
        {this.props.children}
      </div>
    );
  }
}
export default ForgotPasswordCard;
