import * as actionTypes from './actionTypes';

const initialState = {
  ID: null,
  code: null,
  discount: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROMOCODE_UPDATE:
      return action.promocode;
    default:
      return state;
  }
};
