import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    borderRadius: '22px',
    boxShadow: 'unset',
    color: '#fff',
    height: '44px',
    padding: '0 45px',
    width: '100%',
  },
});

const ContainedButton = ({
  title, classes, component, ...restProps
}) => (
    <Button
      className={`contained-button ${classes.root}`}
      component={component}
      variant="contained"
      color="primary"
      {...restProps}
    >
      {title}
    </Button>
  );

ContainedButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(ContainedButton);
