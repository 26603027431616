import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '../components/Button';
import Input from '../components/Input';
import FormErrors from './FormErrors';
import * as session from '../services/session';
import logo from '../assets/images/new_logo.png';
import checkedIcon from '../assets/images/checked-icon.svg';
import * as dogsActionCreators from '../data/dogs/actions';
import * as Sentry from '@sentry/browser';

class FormSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailIsValid: false,
      password: '',
      // confirmPassword: 'Makeidea_1',
      loading: false,
      errors: [],
      redirect: false,
      isPassword: true,
      passwordHint: true,
      // password levels
      tooWeak: false,
      weak: false,
      medium: false,
      strong: false,
    };

    this.countSecurePoints = (value) => {
      let text = value;
      let securePoints = 0;
      if (text === null || text === undefined) {
        text = '';
      }
      if (text.length > 0) {
        securePoints += 1;
      }
      if (text.length >= 8) {
        securePoints += 1;
        if ((/([^0-9]*)\d([^0-9]*)\d([^0-9]*[0-9]*)*/.test(text))) {
          securePoints += 1;
        }
        if (RegExp(/[$-/:-?{-~!"^_`[\]]/).test(text)) {
          securePoints += 1;
        }
      }

      return securePoints;
    };

    this.togglePassword = () => {
      this.setState({
        isPassword: !this.state.isPassword,
      });
    };

    this.passwordChange = (event) => {
      const { value } = event.target;

      // reset levels
      this.setState({
        tooWeak: false,
        weak: false,
        medium: false,
        strong: false,
      });

      const hasMixed = passwordText => new RegExp(/[a-z]/).test(passwordText) && new RegExp(/[A-Z]/).test(passwordText);
      const hasNumber = passwordText => new RegExp(/[0-9]/).test(passwordText);
      const hasSpecial = passwordText => new RegExp(/[!#@$%^&*)(+=._-]/).test(passwordText);

      if (value === '') {
        this.setState({
          passwordHint: true,
        });
      } else {
        this.setState({ passwordHint: false });
        if (value.length < 1) {
          this.setState({ tooWeak: false });
        } else {
          this.setState({ tooWeak: true });
          if (value.length >= 8) {
            if (hasMixed(value) || hasNumber(value) || hasSpecial(value)) {
              this.setState({
                weak: true,
                tooWeak: false,
                medium: false,
                strong: false,
              });
            }
            if ((hasMixed(value) && hasNumber(value)) ||
                (hasMixed(value) && hasSpecial(value)) ||
                (hasNumber(value) && hasSpecial(value))) {
              this.setState({
                medium: true,
                weak: false,
                tooWeak: false,
                strong: false,
              });
            }
            if (hasMixed(value) && hasNumber(value) && hasSpecial(value)) {
              this.setState({
                strong: true,
                medium: false,
                weak: false,
                tooWeak: false,
              });
            }
          }
        }
      }
    };

    this.getChangeHandler = key => (event) => {
      this.setState({
        [key]: event.target.value,
      });
      if (key === 'password') {
        this.passwordChange(event);
      } else {
        const isValid = value => new RegExp(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)
          .test(value);
        this.setState({ emailIsValid: isValid(event.target.value) });
      }
    };

    this.attemptRegister = (e) => {
      e.preventDefault();
      this.setState({ loading: true, errors: [] });

      // Check all fields are filled
      const keys = ['email', 'password'];
      keys.forEach((key) => {
        if (this.state[key].length === 0) {
          this.displayError('All fields must be filled.');
        }
      });

      // Check passwords match
      // if (this.state.password !== this.state.confirmPassword) {
      //   this.displayError('Password fields do not match');
      //   return;
      // }

      const {
        email,
        password,
      } = this.state;

      const user = {
        email: email.toLowerCase(),
        password,
      };

      if (!this.state.emailIsValid) {
        Sentry.setUser({ email: this.state.email });
        Sentry.captureException(new Error("Invalid email at signup"));
        this.displayError('Please, provide valid email');
        return;
      }

      if (this.state.tooWeak && !this.state.weak) {
        this.displayError("Please, don't use too weak password");
        return;
      }

      session.register(user)
        .then((resp) => {
          this.setState({ loading: false });
          if (resp.errors && resp.errors.length > 0) {
            resp.errors.forEach(error => this.displayError(error));
          } else {
            // clear store dogs from cache of redux
            this.props.clearDogs();

            // init authorization after success created user.
            session.authenticate(user)
              .then(() => {
                this.setState({ loading: false, redirectToAddDog: true });
                this.props.history.push('/stay-in-touch');
              })
              .catch((err) => {
                this.setState({ loading: false });

                if (err.response && err.response.message) {
                  this.setState({ errors: [`${err.response.message}.`] });
                } else {
                  this.setState({ errors: ['An error occurred.'] });
                }
              });
          }
        }).catch(() => this.displayError('An error occured.'));
    };

    this.displayError = (error) => {
      this.setState({
        loading: false,
        errors: [error],
      });
    };
  }

  render() {
    const logoSize = {
      maxWidth: '110px',
      maxHeight: '55px',
    };
    const { errors } = this.state;
    return (
      <div className="col-xl-5 col-lg-5 col-md-12 row mx-0 justify-content-center animated fadeIn">
        <div className="col-xl-10 col-lg-11 col-md-12 text-center pt-4 form-auth">
          <div className="row justify-content-center pb-4">
            <img style={logoSize} src={logo} alt="" className="logo animated fadeInDown" />
          </div>
          <div className="row justify-content-center text-center">
            <h5 className="font-weight-bold text-uppercase animated fadeInDown">New To the Pack?</h5>
            <p className="subtitle px-4 animated fadeInUp">
                Discover the new way of feeding and exercising your dog from Kasper & Kitty! Bespoke calorie-controlled food delivered to your door every month.
            </p>

          </div>
          <div className="row justify-content-center sign-up-form">
            <div className="col-xl-9 col-lg-9 col-md-9">
              <FormErrors errors={errors} />
              <div className="input-container">

                <Input
                  type="text"
                  placeholder="Email*"
                  onChangeText={this.getChangeHandler('email')}
                />
                {this.state.emailIsValid
                      ?
                        <span className="icon animated fadeIn">
                          <img src={checkedIcon} alt="" />
                        </span>
                      :
                        <span className="icon animated fadeIn fa fa-envelope" />
                  }

              </div>
              <div className="input-container">

                <Input
                  onChangeText={this.getChangeHandler('password')}
                  type={`${this.state.isPassword ? 'password' : 'text'}`}
                  placeholder="Password*"
                />

                <div className="password-levels w-100">
                  {this.state.tooWeak ? <span className="animated fadeInUp password-level too-weak" /> : <span className="animated fadeInUp password-level" />}
                  {this.state.weak ? <span className="animated fadeInUp password-level weak" /> : <span className="animated fadeInUp password-level" />}
                  {this.state.medium ? <span className="animated fadeInUp password-level medium" /> : <span className="animated fadeInUp password-level" />}
                  {this.state.strong ? <span className="animated fadeInUp password-level strong" /> : <span className="animated fadeInUp password-level" />}
                </div>

                <span className={`password-hint ${this.state.passwordHint ? '' : 'hidden'}`}>8 characters or more</span>

                <span
                  className={`icon password fa ${this.state.isPassword ? 'fa-eye' : 'fa-eye-slash'}`}
                  role="button"
                  tabIndex={0}
                  onKeyPress={this.togglePassword}
                  onClick={this.togglePassword}
                />

                <p className="text-left  w-100 pb-3">* Required fields</p>
              </div>

              <Button
                onClick={event => this.attemptRegister(event)}
                classes="button-primary fill red animated fadeInUp"
                label="SIGN UP"
              />
              <p className="privacy-text py-3">
                    By continuing, you agree to Kasper & Kitty&apos;s
                <span>
                  <Link to="//kasperandkitty.com/index.php/terms-conditions/" target="_blank"> Terms of Conditions</Link>,
                  <Link to="//kasperandkitty.com/index.php/privacy-policy/" target="_blank"> Privacy Policy </Link>&
                  <Link to="//kasperandkitty.com/index.php/cookie-policy/" target="_blank"> Cookie Use</Link>
                </span>
              </p>
              <Link to="/login" title="Already a Member? Login!" className="already-member">Already a Member? Login!</Link>
            </div>

          </div>

        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  storeDogs: state.data.dogs.storeDogs,
});

const mapDispatchToProps = dispatch => ({
  clearDogs: () => dispatch(dogsActionCreators.clearDogs()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormSignUp));
