import React from 'react';
import { connect } from 'react-redux';
import CloseIcon from '../../assets/images/wizard/review-close.png';
import Button from '../../components/Button';

const HowCVCTip = ({
  title, isImage, image, target, isButton, btnLabel, btnFn, children,
}) => (

  <div className="modal custom-modal animated fadeIn" id={target} tabIndex="-1" role="dialog">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <img src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="modal-body">
          <div className="container-fluid px-0">
            <div className="row justify-content-center">
              <div className={`${isImage ? 'col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12' : 'col-12'}`}>
                <h5 className="modal-title text-left text-justify py-1" style={{ marginLeft: '10px', color: '#fab522' }}>{title}</h5>
                <p className="modal-description text-left text-justify py-1">
                  <ul className="pl-2" style={{ listStyle: 'none', marginLeft: '10px' }}>
                    <li className="py-1" style={{ fontWeight: 600 }}>Mastercard, Visa &amp; Discover</li>
                    <li className="py-1" style={{ color: '#737373', fontSize: 13 }}>3-digit verification number on the back of the card inside the signature strip</li>
                    <li className="py-1" style={{ fontWeight: 600 }}>American Express &amp; Diner's Club </li>
                    <li className="py-1" style={{ color: '#737373', fontSize: 13 }}>4-digit verification number on the front of the card above the card number</li>
                  </ul>
                </p>

                {isButton ?
                  <Button
                    onClick={btnFn}
                    data-toggle="modal"
                    data-target={`#${target}`}
                    classes="button-primary fill red"
                    label={btnLabel}
                  />
                  :
                  ''
                }
              </div>
              {isImage ?
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 text-center pl-0">
                  <img src={image} alt="" />
                </div>
                :
                ''
              }
            </div>
          </div>
        </div>
        <div className="modal-footer row mx-0 justify-content-start">
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default connect()(HowCVCTip);
