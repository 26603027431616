import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Row from '../components/Row';
import AuthorizationBanner from '../components/AuthorizationBanner';
import Button from '../components/Button';
import StayInTouchForm from '../components/StayInTouchForm';

class StayInTouch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  render() {
    let wordImage = '../assets/images/stay_touch_word.png',
      dogImage = '../assets/images/stay_touch_dog.png';
    return (
      <div className="container-fluid m-0 p-0 stay-in-touch">
        <Row>
          <StayInTouchForm />
          <AuthorizationBanner
            bannerColor="stay-in-touch"
            wordImage={wordImage}
            dogImage={dogImage}
          />
        </Row>
      </div>
    );
  }
}
export default withRouter(StayInTouch);
