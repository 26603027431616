import React, { Component } from 'react';

export default class KasperDog extends Component {
  render() {
    return (
      <div className="kasperdog-container">
        <img src={this.props.image} />
        <h6 className="font-script">{this.props.text}</h6>
      </div>
    );
  }
}
