import React from 'react';
import connect from 'react-redux/es/connect/connect';

import * as dogsActionCreators from '../data/dogs/actions';

class DogNavigation extends React.Component {
  constructor(props) {
    super(props);
  }

  handleArrowClick(value) {
    const { dogs, currentDog } = this.props;
    let keys = Object.keys(dogs);

    if (value === 'left') {
      if (keys.indexOf(currentDog.toString()) === 0) {
        this.props.selectDog(keys[keys.length - 1]);
      } else {
        this.props.selectDog(keys[keys.indexOf(currentDog.toString()) - 1])
      }
    } else if (value === 'right') {
      if (keys.indexOf(currentDog.toString()) === keys.length - 1) {
        this.props.selectDog(keys[0]);
      } else {
        this.props.selectDog(keys[keys.indexOf(currentDog.toString()) + 1]);
      }
    }
  }

  render() {
    const { dogs, currentDog } = this.props;

    return (
      <div className="dog-navigation">
        {dogs && Object.keys(dogs).length >= 2 &&
          <div className="row dog-nav">
            <div className="col-lg-6 col-md-8 col-sm-9 col-10 nav-body">
              <div className="row">
                <div
                  className="col-sm-3 col-2 left-arrow-area"
                  onClick={(e) => {
                    setTimeout(() => {
                      e.preventDefault();
                      this.handleArrowClick('left');
                    }, 500);
                  }}
                >
                  <img src="../assets/images/left-arrow-black.png" />
                </div>

                <div className="col-sm-6 col-8 dog-info">
                  <div className="image-border">
                    <img src={dogs[currentDog].image.Url ? "http://" + dogs[currentDog].image.Url : "/assets/images/kasper-image-white.png"}></img>
                  </div>

                  <p>{dogs[currentDog].name}</p>
                </div>

                <div
                  className="col-sm-3 col-2 right-arrow-area"
                  onClick={(e) => {
                    setTimeout(() => {
                      e.preventDefault();
                      this.handleArrowClick('right');
                    }, 500);
                  }}
                >
                  <p>next dog</p>
                  <img src="../assets/images/right-arrow-black.png" />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
};

const mapStateToProps = state => ({
  dogs: state.data.dogs.dogs,
  currentDog: state.data.dogs.currentDog
});

const mapDispatchToProps = dispatch => ({
  selectDog: index => dispatch(dogsActionCreators.select(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DogNavigation);