import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Layout from '../components/Layout';
import MainImageBanner from '../components/MainImageBanner';
import KaushanScriptHeading from '../components/KaushanScriptHeading';
import GreyText from '../components/GreyText';
import InputField from '../components/InputField';
import Row from '../components/Row';
import HalfColumn from '../components/HalfColumn';
import FullColumn from '../components/FullColumn';
import Checkbox from '../components/Checkbox';
import * as ordersActionCreators from '../data/orders/actions';

class GuestCheckout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      surname: '',
      mobileNumber: '',
      landline: undefined,
      email: '',
      billingPostcode: '',
      billingCountry: '',
      billingAddress1: '',
      billingAddress2: '',
      billingTownCity: '',
      billingCounty: '',
      deliveryEqualsBilling: false,
      deliveryPostcode: '',
      deliveryCountry: '',
      deliveryAddress1: '',
      deliveryAddress2: '',
      deliveryTownCity: '',
      deliveryCounty: '',
    };
  }

  render() {
    return (
      <Layout>
        <MainImageBanner
          image="url(/assets/images/dog-in-field.jpg)"
          heading="Guest checkout"
        />
        <div className="inner-container">
          <KaushanScriptHeading title="Guest checkout" />
          <form onSubmit={this.handleSubmit}>
            <Row>
              <FullColumn noPaddingBottom>
                <GreyText text="Your name" fontSize={20} />
              </FullColumn>
            </Row>
            <Row>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="First name*"
                  value={this.state.firstName}
                  onChangeText={event => this.setState({ firstName: event.target.value })}
                  noMarginBoottom
                />
              </HalfColumn>
              <HalfColumn>
                <InputField
                  type="text"
                  placeholder="Surname*"
                  value={this.state.surname}
                  onChangeText={event => this.setState({ surname: event.target.value })}
                  noMarginBoottom
                />
              </HalfColumn>
            </Row>
            <Row>
              <FullColumn noPaddingBottom>
                <GreyText text="Contact details" fontSize={20} />
              </FullColumn>
            </Row>
            <Row>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="number"
                  placeholder="Mobile number*"
                  value={this.state.mobileNumber}
                  onChangeText={event => this.setState({ mobileNumber: event.target.value })}
                  noMarginBoottom
                />
              </HalfColumn>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="number"
                  placeholder="Landline"
                  value={this.state.landline}
                  onChangeText={event => this.setState({ landline: event.target.value })}
                  noMarginBoottom
                />
              </HalfColumn>
            </Row>
            <div className="row no-margin">
              <HalfColumn>
                <InputField
                  type="email"
                  placeholder="Email*"
                  value={this.state.email}
                  onChangeText={event => this.setState({ email: event.target.value })}
                  noMarginBoottom
                />
              </HalfColumn>
            </div>
            <Row>
              <FullColumn noPaddingBottom>
                <GreyText text="Billing address" fontSize={20} />
              </FullColumn>
            </Row>
            <Row>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="Postcode*"
                  value={this.state.billingPostcode}
                  onChangeText={(event) => {
                      if (this.state.deliveryEqualsBilling) {
                          this.setState({
                              billingPostcode: event.target.value,
                              deliveryPostcode: event.target.value,
                          });
                      } else {
                          this.setState({
                              billingPostcode: event.target.value,
                          });
                      }
                  }}
                  noMarginBoottom
                />
              </HalfColumn>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="Country*"
                  value={this.state.billingCountry}
                  onChangeText={(event) => {
                      if (this.state.deliveryEqualsBilling) {
                          this.setState({
                              billingCountry: event.target.value,
                              deliveryCountry: event.target.value,
                          });
                      } else {
                          this.setState({
                              billingCountry: event.target.value,
                          });
                      }
                  }}
                  noMarginBoottom
                />
              </HalfColumn>
            </Row>
            <Row>
              <FullColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="Address line 1*"
                  value={this.state.billingAddress1}
                  onChangeText={(event) => {
                      if (this.state.deliveryEqualsBilling) {
                          this.setState({
                              billingAddress1: event.target.value,
                              deliveryAddress1: event.target.value,
                          });
                      } else {
                          this.setState({
                              billingAddress1: event.target.value,
                          });
                      }
                  }}
                  noMarginBoottom
                />
              </FullColumn>
            </Row>
            <Row>
              <FullColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="Address line 2"
                  value={this.state.billingAddress2}
                  onChangeText={(event) => {
                    if (this.state.deliveryEqualsBilling) {
                        this.setState({
                            billingAddress2: event.target.value,
                            deliveryAddress2: event.target.value,
                        });
                    } else {
                        this.setState({
                            billingAddress2: event.target.value,
                        });
                    }
                  }}
                  noMarginBoottom
                />
              </FullColumn>
            </Row>
            <Row>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="Town/City*"
                  value={this.state.billingTownCity}
                  onChangeText={(event) => {
                    if (this.state.deliveryEqualsBilling) {
                        this.setState({
                            billingTownCity: event.target.value,
                            deliveryTownCity: event.target.value,
                        });
                    } else {
                        this.setState({
                            billingTownCity: event.target.value,
                        });
                    }
                  }}
                  noMarginBoottom
                />
              </HalfColumn>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="County*"
                  value={this.state.billingCounty}
                  onChangeText={(event) => {
                    if (this.state.deliveryEqualsBilling) {
                        this.setState({
                            billingCounty: event.target.value,
                            deliveryCounty: event.target.value,
                        });
                    } else {
                        this.setState({
                            billingCounty: event.target.value,
                        });
                    }
                  }}
                  noMarginBoottom
                />
              </HalfColumn>
            </Row>
            <div className="row no-margin">
              <HalfColumn noPaddingBottom>
                <Checkbox
                  label="My delivery address is the same as my billing address"
                  active={this.state.deliveryEqualsBilling}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                        deliveryEqualsBilling: !this.state.deliveryEqualsBilling,
                    });

                    if (this.state.deliveryEqualsBilling) {
                        this.setState({
                            deliveryPostcode: '',
                            deliveryCountry: '',
                            deliveryAddress1: '',
                            deliveryAddress2: '',
                            deliveryTownCity: '',
                            deliveryCounty: '',
                        });
                    } else {
                        this.setState({
                            deliveryPostcode: this.state.billingPostcode,
                            deliveryCountry: this.state.billingCountry,
                            deliveryAddress1: this.state.billingAddress1,
                            deliveryAddress2: this.state.billingAddress2,
                            deliveryTownCity: this.state.billingTownCity,
                            deliveryCounty: this.state.billingCounty,
                        });
                    }
                }}
                />
              </HalfColumn>
            </div>
            <Row>
              <FullColumn noPaddingBottom>
                <GreyText text="Delivery address" fontSize={20} />
              </FullColumn>
            </Row>
            <Row>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="Postcode*"
                  value={this.state.deliveryPostcode}
                  onChangeText={event => this.setState({ deliveryPostcode: event.target.value })}
                  disable={this.state.deliveryEqualsBilling}
                  noMarginBoottom
                />
              </HalfColumn>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="Country*"
                  value={this.state.deliveryCountry}
                  onChangeText={event => this.setState({ deliveryCountry: event.target.value })}
                  disable={this.state.deliveryEqualsBilling}
                  noMarginBoottom
                />
              </HalfColumn>
            </Row>
            <Row>
              <FullColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="Address line 1*"
                  value={this.state.deliveryAddress1}
                  onChangeText={event => this.setState({ deliveryAddress1: event.target.value })}
                  disable={this.state.deliveryEqualsBilling}
                  noMarginBoottom
                />
              </FullColumn>
            </Row>
            <Row>
              <FullColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="Address line 2"
                  value={this.state.deliveryAddress2}
                  onChangeText={event => this.setState({ deliveryAddress2: event.target.value })}
                  disable={this.state.deliveryEqualsBilling}
                  noMarginBoottom
                />
              </FullColumn>
            </Row>
            <Row>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="Town/City*"
                  value={this.state.deliveryTownCity}
                  onChangeText={event => this.setState({ deliveryTownCity: event.target.value })}
                  disable={this.state.deliveryEqualsBilling}
                  noMarginBoottom
                />
              </HalfColumn>
              <HalfColumn noPaddingBottom>
                <InputField
                  type="text"
                  placeholder="County*"
                  value={this.state.deliveryCounty}
                  onChangeText={event => this.setState({ deliveryCounty: event.target.value })}
                  disable={this.state.deliveryEqualsBilling}
                  noMarginBoottom
                />
              </HalfColumn>
            </Row>
          </form>
        </div>
      </Layout>
    );
  }
}

export default connect(() => ({}), dispatch => ({
  actions: {
    orders: bindActionCreators(ordersActionCreators, dispatch),
  },
}))(GuestCheckout);
