import React from 'react';
import { connect } from 'react-redux';

import * as dogsSelectors from '../../data/dogs/selectors';

import Layout from '../../components/Layout';
import MainImageBanner from '../../components/MainImageBanner';
import SubNavigation from '../../components/SubNavigation';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';
import Row from '../../components/Row';
import DateSelectionBanner from '../../components/DateSelectionBanner';
import BackgroundContainer from '../../components/BackgroundContainer';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import * as dogs from '../../data/dogs/actions';

const Bars = ({ data, postfix, variable }) => {
  const max = Math.max(...data.map(o => o[variable]));

  return (
    <div className="bars-container">
      {data.map(item => (
        <div
          style={{ width: `${30 + (70 * (item[variable] / max))}%` }}
          className={`bar ${item.backgroundColor}`}
        >
          <p>{item.day}</p>
          <p>{item[variable]} {postfix}</p>
        </div>
      ))}
    </div>
  );
};

const getWeekNumber = (dt) => {
  const dayNum = dt.getUTCDay() || 7;
  const d = new Date(dt.getTime());

  d.setUTCDate((d.getUTCDate() + 4) - dayNum);

  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

  return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
};

const getWeekDay = (d) => {
  const day = new Date(d);
  const n = day.getDay();

  return (n === 0) ? 6 : n - 1;
};

const formatDate = d => d.toLocaleString('en-GB').split(',')[0];

const getPreviousMonday = (date) => {
  const day = date.getDay();

  if (date.getDay() === 0) {
    return new Date(new Date().setDate(date.getDate() - 7));
  }

  return new Date(new Date().setDate(date.getDate() - day));
};

const ActivitiesClass = ({ dog }) => (
  <Activities dog={dog} />
);

class Activities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: undefined,
      month: undefined,
      year: undefined,
      imageWidth: 0,
      days: [],
      dog: props.dog,
      goal: { code: '', name: '', text: '' },
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();

    dogs.getDays(this.state.dog.ID)
      .then((response) => {
        const d = [];

        for (let r in response) {
          r = response[r];
          const date = new Date(r.day);

          d.push({
            key: r.ID,
            day: {
              day: date.getDate(),
              month: date.getMonth(),
              year: date.getFullYear(),
            },
            datetime: date,
            steps: r.steps,
            distance: r.distance,
            rest: r.rest,
          });
        }

        this.setState({ days: d.sort((a, b) => a.steps - b.steps) });
      });

    dogs.getGoals()
      .then((response) => {
        for (let r in response) {
          r = response[r];

          if (r.code === this.state.dog.activity) {
            this.setState({ goal: { code: r.code, name: r.name, text: r.text } });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  getCurrentdate() {
    let day = {
      steps: 0,
      distance: 0,
      rest: 0,
      datetime: new Date(Date.UTC(this.state.year, this.state.month, this.state.date)),
    };

    for (let d in this.state.days) {
      d = this.state.days[d];

      if (
        d.day.day === this.state.date &&
        d.day.month === this.state.month &&
        d.day.year === this.state.year
      ) {
        day = d;
      }
    }

    return day;
  }

  updateDimensions() {
    const imageWidth = this.image.offsetWidth;

    this.setState({ imageWidth });
  }

  render() {
    const { dog } = this.state;

    const day = this.getCurrentdate();
    const weekNumber = getWeekNumber(day.datetime);

    const weekStats = [
      {
        day: 'Monday', steps: 0, distance: 0, rest: 0, backgroundColor: 'grey',
      },
      {
        day: 'Tuesday', steps: 0, distance: 0, rest: 0, backgroundColor: 'orange',
      },
      {
        day: 'Wednesday', steps: 0, distance: 0, rest: 0, backgroundColor: 'grey',
      },
      {
        day: 'Thursday', steps: 0, distance: 0, rest: 0, backgroundColor: 'orange',
      },
      {
        day: 'Friday', steps: 0, distance: 0, rest: 0, backgroundColor: 'grey',
      },
      {
        day: 'Saturday', steps: 0, distance: 0, rest: 0, backgroundColor: 'orange',
      },
      {
        day: 'Sunday', steps: 0, distance: 0, rest: 0, backgroundColor: 'grey',
      },
    ];

    for (let d in this.state.days) {
      d = this.state.days[d];

      if (getWeekNumber(d.datetime) === weekNumber) {
        weekStats[getWeekDay(d.datetime)].steps = d.steps;
        weekStats[getWeekDay(d.datetime)].distance = d.distance;
        weekStats[getWeekDay(d.datetime)].rest = d.rest;
      }
    }

    // get interval date
    let dateMonday = getPreviousMonday(new Date());
    if (day.datetime) {
      dateMonday = getPreviousMonday(day.datetime);
    }
    const dateSunday = new Date(new Date().setDate(dateMonday.getDate() + 6));

    return (
      <Layout>
        <MainImageBanner
          image="url(/assets/images/dog-in-field.jpg)"
          heading={`${this.props.dog.name}'s dashboard`}
          subtext={`${this.props.dog.name}'s food and daily menu`}
        />

        <SubNavigation />

        <div className="inner-container">
          <KaushanScriptHeading title={`${this.props.dog.name}'s activities`} />

          <Row>
            <div className="col-12 col-sm-8 col-md-4 col-lg-3">
              <Row>
                <div className="col-12 no-padding margin-bottom">
                  <div style={{ zIndex: 0 }} className="grey-background">
                    <div className="gi-content">
                      <div style={{ zIndex: 1 }} className="dog-image-border small">
                        <img
                          alt=""
                          className="dog-image-frame"
                          src="assets/images/dog-image-frame.png"
                        />

                        <div
                          ref={(image) => {
                            this.image = image;
                          }}
                          className="dog-image-container"
                          style={{ height: this.state.imageWidth, backgroundImage: this.props.dog.image.Url !== '' ? `url(http:${this.props.dog.image.Url})` : 'url(/assets/images/kasper-image-white.png)' }}
                        />
                      </div>
                    </div>

                    <OrangeTextHeading
                      text={`${this.props.dog.name}'s fitkolar`}
                      fontSize={20}
                      centered
                      marginBottom
                    />
                  </div>
                </div>
              </Row>

              <Row>
                <div className="col-12 no-padding margin-bottom">
                  <div style={{ zIndex: 0 }} className="grey-background">
                    <p className="text-white">Tracker Info</p>
                    <p className="text-white">MAC: {dog.tracker ? dog.tracker.device_madevice_mac : '-'}</p>
                    <p className="text-white">UUID: {dog.tracker ? dog.tracker.device_uuid : '-'}</p>
                  </div>
                </div>
              </Row>
            </div>

            <div className="col-12 col-md-8 col-lg-9">
              <DateSelectionBanner
                onUpdateDate={val => this.setState(val)}
                currentDate={this.state.date}
                currentMonth={this.state.month}
                currentYear={this.state.year}
              />

              <div style={{ marginTop: '16px' }}>
                <BackgroundContainer marginBottom>
                  <Row>
                    <div className="col-12 col-sm-6 col-lg-8">
                      <OrangeTextHeading
                        text="Weekly points"
                        fontSize={20}
                      />
                      <GreyText
                        text={`${formatDate(dateMonday)} - ${formatDate(dateSunday)}`}
                        fontSize={16}
                      />
                      <Bars
                        data={weekStats}
                        variable="steps"
                        postfix="points"
                      />
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="dial-container">
                        <img
                          className="dial"
                          src="/assets/images/kaspermeter.png"
                          alt="kaspermeter"
                        />
                        <img
                          className="dial-pointer"
                          src="/assets/images/dial-pointer.png"
                          alt="dial-pointer"
                        />
                      </div>

                      <p className="dashboard-text orange">GOAL: {this.state.goal.name}</p>

                      <br />

                      <p className="dashboard-text orange">{this.state.goal.text}</p>
                    </div>
                  </Row>
                </BackgroundContainer>

                <BackgroundContainer marginBottom>
                  <Row>
                    <div className="col-12 col-sm-6 col-lg-8">
                      <OrangeTextHeading
                        text="Weekly distance"
                        fontSize={20}
                      />

                      <GreyText
                        text={`${formatDate(dateMonday)} - ${formatDate(dateSunday)}`}
                        fontSize={16}
                      />

                      <Bars
                        data={weekStats}
                        variable="distance"
                        postfix="miles"
                      />
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="dial-container">
                        <img
                          className="dial"
                          src="/assets/images/kaspermeter.png"
                          alt="kaspermeter"
                        />

                        <img
                          className="dial-pointer"
                          src="/assets/images/dial-pointer.png"
                          alt="dial-pointer"
                        />
                      </div>
                    </div>
                  </Row>
                </BackgroundContainer>

                <BackgroundContainer>
                  <Row>
                    <div className="col-12 col-sm-6 col-lg-8">
                      <OrangeTextHeading
                        text="Weekly rest"
                        fontSize={20}
                      />

                      <GreyText
                        text={`${formatDate(dateMonday)} - ${formatDate(dateSunday)}`}
                        fontSize={16}
                      />

                      <Bars
                        data={weekStats}
                        variable="rest"
                        postfix="hours"
                      />
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4">
                      <div className="dial-container">
                        <img
                          className="dial"
                          src="/assets/images/kaspermeter.png"
                          alt="kaspermeter"
                        />

                        <img
                          className="dial-pointer"
                          src="/assets/images/dial-pointer.png"
                          alt="dial-pointer"
                        />
                      </div>
                    </div>
                  </Row>
                </BackgroundContainer>
              </div>
            </div>
          </Row>
        </div>

      </Layout>
    );
  }
}

export default connect(() => ({
  dog: dogsSelectors.getCurrentDog(),
}))(ActivitiesClass);
