import React from 'react';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import SimplyPowesome from '../components/SimplyPowesome';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';

export default class YourFriends extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/ybf-banner.jpg)" heading="KK club" subtext="Awaiting content" />
        <div className="blog-container">
          <p className="heading">Blog</p>
        </div>
        <div className="row tail-wagners-container">
          <div className="col" />
          <div className="col">
            <p className="heading">Tail waggers</p>
            <p className="desc">Happy owner's and dog's reviews</p>
          </div>
        </div>
        <div className="row social-media-wall-container">
          <div className="col-md-4">
            <p className="heading">Social media wall</p>
            <p className="desc">Join in the conversation!</p>
          </div>
          <div className="col-md-4">
            <img src="/assets/images/ybf-social.png" />
          </div>
          <div className="col-md-4" />
        </div>
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
