import React from 'react';
import { Redirect } from 'react-router-dom';

import Navigation from '../../components/Navigation';
import ImageBanner from '../../components/ImageBanner';
import TitleBox from '../../components/TitleBox';
import Footer from '../../components/Footer';
import FullColumn from '../../components/FullColumn';
import HalfColumn from '../../components/HalfColumn';
import Row from '../../components/Row';
import SubmitButton from '../../components/SubmitButton';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import Button from '../../components/Button';
import ForgotPasswordCard from '../../components/ForgotPasswordCard';
import dogImage from '../../assets/images/reset_password_done_dog.png';

export default class ForgottenPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };
  }

  render() {
    return (
      <div className="container-fluid forgotten-password-container">
        <div className="row justify-content-center h-100">
          <div className="col-xl-5 col-lg-7 col-md-12 row justify-content-center align-items-center">
            <div className="col-xl-9 col-lg-9 col-md-8">
              <div className="forgot-card-container">
                <ForgotPasswordCard
                  title="Your password has been changed!"
                  description=""
                >
                  <div className="row justify-content-center py-3">
                    <div className="col-xl-6 col-lg-6 col-md-8">
                      <Button
                          onClick={() => this.setState({ redirect: true })}
                          classes="button-primary fill red mb-3 animated fadeInUp delay-_5s"
                          label="BACK TO LOG IN"
                        />
                    </div>
                  </div>
                </ForgotPasswordCard>
                  {this.state.redirect && (<Redirect to="/login" />)}
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-5 col-md-6 forgotten-password-banner reset-password">
            <div className="forgotten-password-image-container">
              <img className="dog-image animated fadeInUp" src={dogImage} alt="" />
            </div>
          </div>

        </div>

      </div>
    );
  }
}
