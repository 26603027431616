/* eslint-disable arrow-parens */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrderDropDown, { components } from 'react-select';

import Navigation from '../components/Navigation';
import SubNavigation from '../components/SubNavigation';

import { dateFormat, dateCommonFormat } from '../utils';

import * as orderSelectors from '../data/orders/selectors';
import * as orderActionCreators from '../data/orders/actions';
import * as dogSelectors from '../data/dogs/selectors';

import { referralLink, referralCredit, order } from '../data/orders/api';

import DownArrowIcon from '../assets/images/down-arrow-black.png';
import UpArrowIcon from '../assets/images/up-arrow-black.png';
import CheckedIcon from '../assets/images/checked-icon.png';

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {children}
        <img src={CheckedIcon} style={{ position: 'absolute', right: 0 }} />
      </components.ValueContainer>
    )
  );
};

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={props.selectProps.menuIsOpen ? UpArrowIcon : DownArrowIcon} alt="arrow" />
      </components.DropdownIndicator>
    )
  )
}

class ReferAFriend extends Component {

  state = {
    refLink: '',
    referData: {
      numberOfRefer: 10,
      credit: 100,
    },
    applied: false,
    selectedIndex: 0,
    orderList: [],
  }

  componentDidMount() {
    referralLink().then(res => this.setState({ refLink: res.link }));
    referralCredit().then(res => this.setState({
      referData: {
        numberOfRefer: res.data ? res.data.numberOfRefferred || 0 : 0,
        credit: res.data ? res.data.credit || 0 : 0,
      },
    }));
    this.props.orderActions.getReferPromocode();
    this.props.orderActions.getOrders()
      .then((orders) => {

        if (orders && orders.length > 0) {
          var orderList = [];

          // exclude trial orders and filter status is "New"
          var filterOrders = orders.filter(order => order.status === "New" && order.subscription_cycle !== 1)

          filterOrders && filterOrders.forEach((order, index) => {
            var dogName = dogSelectors.get(order.dog_id).name;
            var requiredDate = dateFormat(order.date_required);

            orderList.push({
              value: dogName + ": " + requiredDate,
              label: dogName + ": " + requiredDate,
              index,
              orderID: order.ID,
            })
          });

          this.setState({ orderList });
        }
      })
  }

  openShareFacebook = () => {
    const { refLink } = this.state;
    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${refLink}`;
    window.open(facebookLink, 'popupWindow', 'height=555,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }

  openShareTwitter = () => {
    const { refLink } = this.state;
    const twitterLink = `https://twitter.com/share?url=${refLink}`;
    window.open(twitterLink, 'popupWindow', 'height=555,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }

  openShareWhatsapp = () => {
    const { refLink } = this.state;
    const whatsappLink = `https://api.whatsapp.com/send?phone=+&text=%20${refLink}`;
    window.open(whatsappLink, 'popupWindow', 'height=555,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
  }

  openShareEmail = () => {
    const { refLink } = this.state;
    const link = `mailto:?subject=I wanted you to see this site&amp;body=Check out this site ${refLink}.`;
    window.location.open(link, '_blank');
  }

  copyLink = () => {
    const copyToClipboard = str => {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    };
    copyToClipboard(this.state.refLink);
    this.setState({ didCopied: true }, () => setTimeout(() => {
      this.setState({ didCopied: false });
    }, 2000));
  }

  selectOrder = (selectedOrder) => {
    this.setState({ selectedIndex: selectedOrder.index })
  }

  apply = (code) => {
    this.setState({ applied: true });
    this.props.orderActions.applyReferPromo(this.state.orderList[this.state.selectedIndex].orderID, code.code);
  }

  render() {
    const { referralPromos } = this.props;
    var referralCodeList = [], availableCodeList = [], availableCode = false;

    if (referralPromos && referralPromos.length > 0) {
      referralCodeList = referralPromos.sort((a, b) => b.ID - a.ID)
      availableCodeList = referralCodeList.filter(code => code.used === false);

      if (availableCodeList.length > 0) {
        availableCode = true;
      } else {
        availableCode = false;
      }
    } else {
      availableCode = false;
    }

    return (
      <div className="container">
        <Navigation
          // absolute
          white
          textColor="white-text"
        />
        <SubNavigation />
        <div className="refer-a-friend-page content-container">
          <h4 className="refer-a-friend-page__nav">
            <img alt="" src="/assets/images/dashboard-nav-icon.png" />
            REFER A FRIEND
          </h4>

          <h3 className="refer-a-friend-page__title">Get £10 off your next order by inviting your friends to sign up to Kasper & Kitty!</h3>

          <p className="refer-a-friend-page__description">Invite your friends to join Kasper & Kitty to sign up for their FREE trial. They'll get one month FREE food and we'll give you £10 off your next order for every friend that signs up.</p>

          <div className="three-step-instruction">
            <div className="row">
              <div className="col-12 col-md-4 d-flex flex-row align-items-center">
                <img className="step-icon" src="/assets/images/step-1.png" alt="" />
                Send your code and get your friend to sign up
              </div>
              <div className="col-12 col-md-4 d-flex flex-row align-items-center">
                <img className="step-icon" src="/assets/images/step-2.png" alt="" />
                Receive £10 credit to spend on your next order
              </div>
              <div className="col-12 col-md-4 d-flex flex-row align-items-center">
                <img className="step-icon" src="/assets/images/step-3.png" alt="" />
                We will give your friend one month FREE food
              </div>
            </div>
          </div>

          <div className="dog-images">
            <img src="/assets/images/refer-a-friend-dog-2.svg" alt="" />
            <img src="/assets/images/refer-a-friend-dog-1.svg" alt="" />
          </div>
          <div className="d-flex flex-middle">
            <div className="refer-a-friend__share-code">
              <h3>Share your code</h3>

              <div className="refer-a-friend__share-code__link">
                <div className="link-container">
                  {this.state.refLink}
                </div>
                <div className="copy-button" onClick={this.copyLink}>
                  <img src="/assets/images/thankyou-copy.png" alt="Copy" /> {this.state.didCopied ? 'Copied' : 'Copy'}
                </div>
              </div>

              <h4>Share with</h4>
              <div className="social-buttons">
                <button className="button-outline" onClick={this.openShareFacebook}>
                  <img src="/assets/images/share-facebook-white.png" alt="Share Facebook" />
                    Facebook
                </button>
                <button className="button-outline" onClick={this.openShareTwitter}>
                  <img src="/assets/images/share-twitter-white.png" alt="Share Facebook" />
                    Twitter
                </button>
                <button className="button-outline" onClick={this.openShareWhatsapp}>
                  <img src="/assets/images/share-whatsapp-white.png" alt="Share Facebook" />
                    Whatsapp
                </button>
                <button className="button-outline">
                  <a target="_blank" href={`mailto:?subject=I wanted you to see this site ${this.state.refLink}&amp;body=Check out this site ${this.state.refLink}.`}>
                    <img src="/assets/images/share-email-white.png" alt="Share Facebook" />
                      Email
                  </a>
                </button>
              </div>

              <p>By participating in this program, you agree to our <Link to="/terms-and-conditions" href="_target">Terms & Conditions.</Link></p>
            </div>
          </div>

          {availableCode ? (
            <div className="refer-data">
              <p className="refer-data__title">You currently have £{this.state.referData.credit} credit available.</p>
              <p className="refer-data__description">Select your next order delivery date to apply your £10 credit.</p>
              <OrderDropDown
                className="OrderDropDown-Container"
                classNamePrefix="OrderDropDown"
                components={this.state.applied ? { DropdownIndicator, ValueContainer } : { DropdownIndicator }}
                options={this.state.orderList}
                onChange={this.selectOrder}
                value={this.state.orderList.length > 0 ? this.state.orderList[this.state.selectedIndex] : ''}
              />
              <div className="refer-data__apply">
                <button className={this.state.applied ? 'applied' : ''} onClick={() => this.apply(availableCodeList[0])}>
                  {this.state.applied ? 'Credit Applied' : 'Apply'}
                </button>
              </div>
            </div>
          ) : (
              <div className="refer-data">
                <p className="refer-data__title">You currently have £0 credit available.</p>
              </div>
            )}

          {referralCodeList.length > 0 &&
            <div className="promocode-list">
              <table>
                <tbody>
                  <tr>
                    <th style={{ width: '27%' }}>Date Added</th>
                    <th style={{ width: '18%' }}>Code</th>
                    <th style={{ width: '22.5%' }}>Amount</th>
                    <th style={{ width: '22.5%' }}>Status</th>
                    <th style={{ width: '10%' }}></th>
                  </tr>

                  {referralCodeList.map((code, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ width: '27%' }}>{dateCommonFormat(code.CreatedAt)}</td>
                        <td style={{ width: '18%' }}>{code.code}</td>
                        <td style={{ width: '22.5%' }}>£{code.total_discount}</td>
                        <td style={{ width: '22.5%' }}>{code.used ? 'Used' : 'Unused'}</td>
                        <td style={{ width: '10%' }}></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          }

          <h4 className="top-tips__title">So you want to spread the word?<br /> Here are our top tips!</h4>

          <div className="top-tips__container">
            <div className="row">

              <div className="top-tips__item">
                <div className="row">
                  <div className="top-tips__item__image">
                    <div>
                      <img src="/assets/images/refer-tip-1.png" alt="" />
                    </div>
                  </div>
                  <div className="top-tips__item__content">
                    <h6>WORD OF MOUTH</h6>
                    <p>We can't stop talking about our dogs either! So whether you are having a jog in the park or out and about with your friends, make sure to bring your refer a friend coupons with you.</p>
                  </div>
                </div>
              </div>

              <div className="top-tips__item">
                <div className="row">
                  <div className="top-tips__item__image">
                    <div>
                      <img src="/assets/images/refer-tip-2.svg" alt="" />
                    </div>
                  </div>
                  <div className="top-tips__item__content">
                    <h6>REVIEWS</h6>
                    <p>We love creating great food and service for you and your dog- and we hope your experience reflects just that. Feel free to share your refer a friend code whilst you are giving us a five paw rating.</p>
                  </div>
                </div>
              </div>

              <div className="top-tips__item">
                <div className="row">
                  <div className="top-tips__item__image">
                    <div>
                      <img src="/assets/images/refer-tip-3.svg" alt="" />
                    </div>
                  </div>
                  <div className="top-tips__item__content">
                    <h6>SOCIAL MEDIA</h6>
                    <p>Is your dog part of the #dogsofinstagram movement or has garnered much social media fame else wehere? Let friends and followers alike know how much you and your dog love Kasper & Kitty.</p>
                  </div>
                </div>
              </div>

              <div className="top-tips__item">
                <div className="row">
                  <div className="top-tips__item__image">
                    <div>
                      <img src="/assets/images/refer-tip-4.png" alt="" />
                    </div>
                  </div>
                  <div className="top-tips__item__content">
                    <h6>BLOG</h6>
                    <p>Whilst you are writing about the daily adventures with your dog, why not get your readers involved in your K&K experience by sharing your code and letting them know what you think.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
  referralPromos: orderSelectors.getReferralPromos(),
  orders: orderSelectors.getOrders(),
});
const mapDispatchToProps = dispatch => ({
  orderActions: bindActionCreators(orderActionCreators, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReferAFriend));
