import React from 'react';
import { connect } from 'react-redux';

import DogNavigation from './DogNavigation';
import ProgressBar from '../ProgressBar';

import * as actionTypes from '../../data/exports';

import LogoImage from '../../assets/images/new_logo.png';

class WizardLayout extends React.Component {
  constructor(props) {
    super(props);

    this.setProgress = (value) => {
      let persentage = value;
      if (persentage <= 25) {
        persentage = persentage * 100 / 25;
      } else if (persentage <= 50) {
        persentage = (persentage - 25) * 100 / 50 * 2;
      } else if (persentage <= 75) {
        persentage = (persentage - 50) * 100 / 75 * 3;
      } else if (persentage > 75) {
        persentage = (persentage - 75) * 4;
      }
      return persentage;
    };
  }
  render() {
    let dogProgress = 3;

    if (this.props.currentDog !== null) {
      dogProgress = this.props.currentDog.progress;
    }

    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 px-xl-3 px-lg-3 px-md-2 px-sm-2 px-2 pt-5 text-center">
            <img className="logo-wizard animated fadeInDown" src={LogoImage} alt="" />

            <div className="wizard-progress py-4 row justify-content-center">
              <div className="col-xl-4 col-lg-5 col-12 pl-0 row">
                <div className="col-3 animated fadeInUp">
                  <ProgressBar
                    color="orange"
                    value={this.setProgress(dogProgress)}
                    percentage={dogProgress}
                    isTransparent={dogProgress <= 0}
                    max={25}
                    min={0}
                  />
                </div>

                <div className="col-3 animated fadeInUp delay-_1s">
                  <ProgressBar
                    color="green"
                    value={this.setProgress(dogProgress)}
                    percentage={dogProgress}
                    isTransparent={dogProgress <= 25}
                    max={50}
                    min={25}
                  />
                </div>

                <div className="col-3 animated fadeInUp delay-_2s">
                  <ProgressBar
                    color="red"
                    value={this.setProgress(dogProgress)}
                    percentage={dogProgress}
                    isTransparent={dogProgress <= 50}
                    max={75}
                    min={50}
                  />
                </div>

                <div className="col-3 animated fadeInUp delay-_3s">
                  <ProgressBar
                    color="orange"
                    value={this.setProgress(dogProgress)}
                    percentage={dogProgress}
                    isTransparent={dogProgress <= 75}
                    max={100}
                    min={75}
                  />
                </div>
              </div>
            </div>
            <h5 className="font-weight-bold text-uppercase animated fadeInDown delay-_1s">{this.props.title}</h5>
          </div>

          <div className="col-xl-7 col-lg-10 col-md-10 row justify-content-center px-0 wizard-form">
            {this.props.children}
          </div>
        </div>

        {this.props.dogs && Object.keys(this.props.dogs).length >= 2 &&
          <DogNavigation />
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dogs: state.data.dogs.dogs,
  progress: state.data.wizard.progress,
  currentDog: state.data.dogs.currentDog,
});

const mapDispatchToProps = dispatch => ({
  onProgressChanged: progress => dispatch(actionTypes.setProgress(progress)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardLayout);
