import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ordersSelectors from '../../data/orders/selectors';
import * as dogsSelectors from '../../data/dogs/selectors';
import * as ordersActionCreators from '../../data/orders/actions';
import * as ordersApi from '../../data/orders/api';
import Navigation from '../../components/Navigation';
import MainImageBanner from '../../components/MainImageBanner';
import SubNavigation from '../../components/SubNavigation';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';
import Row from '../../components/Row';
import Footer from '../../components/Footer';
import { fetchApi } from '../../services/api';

const orderItems = (orderID, productID) => fetchApi('/orders/order_items/', { orderID, productID }, 'get');

class LiveOrderCruft extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      kibbleOrders: [],
      repeated: {},
    };

    this.reorder = this.reorder.bind(this);
  }

  componentDidMount() {
    this.props.actions.orders.getOrders().then((response) => {
      if (response === undefined) {
        return;
      }

      const orders = response.filter(item => new Date(item.CreatedAt).getMonth() === (new Date().getMonth() /* - 1 */));

      const lastMonthOrders = [];

      // get the order paid last month
      orders.forEach(item => lastMonthOrders.push(item));
      const repeated = {};
      const kibbleOrders = [];

      if (lastMonthOrders && lastMonthOrders.length > 0) {
        lastMonthOrders.forEach((item) => {
          if (item.order_items && item.order_items.length > 0) {
            item.order_items.forEach((sub) => {
              if (sub.is_kibble) {
                // product_id and order_id
                orderItems(item.ID, sub.ID)
                  .then((res) => {
                    if (res.quantity > 0) {
                      if (repeated[sub.ID]) {
                        repeated[sub.ID] += res.quantity;
                      } else {
                        repeated[sub.ID] = res.quantity;
                        kibbleOrders.push(sub);
                      }
                    }
                  })
                  .catch(e => console.log(e));
              }
            });
          }
        });
      }

      this.setState({ orders: lastMonthOrders, repeated, kibbleOrders });
    });
  }

  reorder() {
    const { orders, kibbleOrders, repeated } = this.state;
    console.log('orders:', orders, ', repeated:', repeated, ', kibbleOrders:', kibbleOrders);

    if (kibbleOrders && kibbleOrders.length <= 0) {
      alert('Sorry, you did not have last month\'s order');
      return;
    }

    const kibbleItemsAfter = kibbleOrders.map((item) => {
      const obj = { ...item };
      obj.quantity = repeated[item.ID];
      return obj;
    });

    const productList = [];

    kibbleItemsAfter.forEach((item) => {
      productList.push({
        key: item.ID,
        product: item.name,
        quantity: item.quantity,
        price: item.price, // This is unit price
        is_fitkolar: item.is_fitkolar,
      });
    });

    console.log('productList', productList);

    localStorage.setItem('productCart', JSON.stringify(productList));
    this.props.history.push('/cart');
  }

  render() {
    const { orders } = this.props;
    orders.filter(item => dogsSelectors.get(item.dog_id) !== undefined);

    console.log('orders:', orders);
    return (
      <div className="container">
        <Navigation />
        <MainImageBanner
          image="url(/assets/images/dog-in-field.jpg)"
          heading="Orders"
          subtext="Live and delivered"
        />
        <SubNavigation />
        <div className="inner-container">
          <KaushanScriptHeading title="Recent Orders" />
          <Row>
            <div className="col-12 col-md-8 col-lg-9">
              <div className="table-container">
                <table>
                  <tbody>
                    <tr className="heading">
                      <th>Recent deliveries</th>
                      <th>Order ID</th>
                      <th>Food for</th>
                      <th>Status</th>
                      <th>Cost</th>
                    </tr>
                    {orders.length > 0 ?
                      orders.map((order) => {
                        // let kibbleTotal = 0;
                        // if (order.order_items && order.order_items.length > 0) {
                        //   order.order_items.forEach((element) => {
                        //     if (element.is_kibble) {
                        //       kibbleTotal += (parseFloat(element.price) * parseInt(element.quantity, 10));
                        //     }
                        //   });
                        // }
                        return (
                          <tr>
                            <td>{order.CreatedAt.substr(0, 10)}</td>
                            <td>{order.ID}</td>
                            <td>{dogsSelectors.get(order.dog_id).name}</td>
                            <td>{order.status}</td>
                            <td>£{order.total.toFixed(2)}</td>
                          </tr>
                        );
                      })
                      :
                      <tr>
                        <td>No recent orders were found.</td>
                      </tr>
                    }
                  </tbody>
                </table>
                <div>
                  <span>
                    <button onClick={() => {
                      ordersApi.cancel()
                        .then((response) => {
                          alert(response.message);
                          if (response.success) {
                            this.props.history.push('/dashboard');
                          }
                        });
                    }}
                    >
                      Cancel
                    </button>
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    <button onClick={this.reorder}>
                      Reorder
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(() => ({
  dog: dogsSelectors.getCurrentDog(),
  orders: ordersSelectors.getOrders(),
}), dispatch => ({
  actions: {
    orders: bindActionCreators(ordersActionCreators, dispatch),
  },
}))(withRouter(LiveOrderCruft));
