import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slider from 'react-rangeslider';

import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import Checkbox from '../../components/Checkbox';
import BackgroundContainer from '../../components/BackgroundContainer';
import Row from '../../components/Row';
import FormErrors from '../../components/FormErrors';
import CheckboxGroup from '../../components/CheckboxGroup';

import * as dogsActionCreators from '../../data/dogs/actions';
import * as dogsSelectors from '../../data/dogs/selectors';
import { validateMandatory } from '../../utils';

const getCarouselItemClass = (key, state) => {
  if (state === 1 || state === 9) {
    if (state === (key - 2) || state === (key + 2)) {
      return ' displayed ';
    }
  }

  if (state === (key - 1) || state === (key + 1)) {
    return ' expanded ';
  }

  return '';
};

class DogActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      errors: [],
      dog: {
        activity: 1,
        medical_conditions: [],
      },
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    dogsActionCreators.getGoals()
      .then((response) => {
        const a = response
          .sort((a, b) => a.code - b.code)
          .map(r => ({
            key: r.ID,
            label: r.name,
            text: r.text,
            steps: r.steps,
            imageSource: (r.image.Files) ? `http:${r.image.Files[0].Url}` : '/assets/images/dog-2.png',
            activeImageSource: (r.imageActive.Files) ? `http:${r.imageActive.Files[0].Url}` : '/assets/images/dog-2.png',
          }));

        this.setState({ activities: a });
      });

    this.props.actions.dogs.fetchConditions();

    if (this.props.stepsState.dog) {
      this.setState({
        dog: {
          ...this.state.dog,
          ...this.props.stepsState.dog,
        },
      });
    }

    if (this.props.dog !== undefined) {
      const newDog = {
        medical_conditions: this.props.dog.medical_conditions !== null ? this.props.dog.medical_conditions : [],
        activity: this.props.dog.activity !== null ? this.props.dog.activity : 1,
      };

      this.setState({ dog: newDog });
    }
  }

  onNext() {
    return new Promise((resolve, reject) => {
      const mandatoryFields = ['activity'];
      const errors = validateMandatory(this.state.dog, mandatoryFields);

      if (errors.length > 0) {
        this.setState({ errors });
        reject();
      }

      this.props.onUpdateState({ dog: this.state.dog });

      if (this.props.dog !== undefined) {
        this.handleUpdate();
      }

      resolve();
    });
  }

  onChangeHandler(key) {
    return (event) => {
      let value = event;

      if (event.target) {
        value = event.target.value;
      }

      if (event.value) {
        value = event.value;
      }

      let formattedValue = value;

      const numericKeys = ['activity'];
      if (numericKeys.indexOf(key) !== -1) {
        if (value.length > 0) {
          formattedValue = parseFloat(value);

          if (Number.isNaN(formattedValue)) {
            formattedValue = value;
          }
        }
      }

      const dog = Object.assign({}, this.state.dog, {
        [key]: formattedValue,
      });

      this.setState({ dog });
    };
  }

  handleUpdate() {
    const wholeDog = this.props.dog;

    wholeDog.activity = this.state.dog.activity;
    wholeDog.medical_conditions = this.state.dog.medical_conditions;

    this.props.actions.dogs.updateDog(wholeDog)
      .then((dog) => {
        if (dog.errors) {
          for (const err of dog.errors) {
            this.state.errors.push(err);
          }

          return;
        }
        console.log('Response: ', response);
      })
      .catch((err) => {
        console.log('An error has occured: ', err);
      });
  }

  render() {
    const { onSubmit, stepsState, conditions } = this.props;
    const { activities } = this.state;

    let name;

    if (this.props.dog !== undefined) {
      name = this.props.dog.name;
    } else {
      name = stepsState.dog.name;
    }

    return (
      <div>
        <OrangeTextHeading
          text={`Let's get to know ${name}`}
          fontSize={30}
          centered
          marginBottom
        />
        <form onSubmit={onSubmit}>
          <FormErrors errors={this.state.errors} />
          <BackgroundContainer marginBottom>
            <OrangeTextHeading
              text={`Out of 9 how active would you say ${name} is?`}
              fontSize={30}
              centered
            />
            <GreyText
              text="Please hover over the icons for an expanded view."
              fontSize={20}
              centered
            />
            <GreyText
              text="Move the slider or click the number that most reflects their activity level."
              fontSize={20}
              centered
              marginBottom
            />
            <div className="activity-carousel-container">
              <div className="line" />
              {activities.map((item, index) => (
                <a
                  key={item.key}
                  className={`${(this.state.dog.activity === item.key ? ' active ' : '') + (getCarouselItemClass(item.key, this.state.dog.activity))}activity-carousel-item`}
                  onClick={(e) => {
                    e.preventDefault();
                    this.onChangeHandler('activity')(item.key);
                  }}
                >
                  <img src={item.imageSource} />
                  <p className="key">{index + 1}</p>
                </a>
              ))}
            </div>
            <div className="activity-text-container">
              {activities.map((item, index) => (
                <div
                  key={item.key}
                  className={`${(this.state.dog.activity === item.key ? ' active ' : '') + (getCarouselItemClass(item.key, this.state.dog.activity))}activity-text-item`}
                >
                  <p>
                    {item.label}
                  </p>
                </div>
              ))}
            </div>
            <Slider
              min={1}
              max={activities.length}
              step={1}
              value={this.state.dog.activity}
              orientation="horizontal"
              reverse={false}
              tooltip={false}
              handleLabel={this.state.dog.activity.toString()}
              onChange={this.onChangeHandler('activity')}
            />
            <div className="carousel-text-container">
              {activities.map(item => (
                <div
                  key={item.key}
                  className={`${this.state.dog.activity === item.key ? ' active ' : ''}carousel-text`}
                >
                  <GreyText
                    text={item.text}
                    fontSize={26}
                    centered
                    bold
                  />
                </div>
              ))}
            </div>
          </BackgroundContainer>
          <BackgroundContainer marginBottom>
            <Row>
              <div className="col-12 col-md-10">
                <OrangeTextHeading
                  text="Health issues"
                  fontSize={30}
                  centered
                />
                <GreyText
                  text={`Check any medical conditions ${name} has to help us create their perfect bespoke dry food blend and recommend the best treats for them.`}
                  fontSize={20}
                  marginBottom
                  centered
                />
              </div>
            </Row>
            <Row>
              <div className="col-12 col-md-10 no-padding">
                <Row>
                  <CheckboxGroup
                    options={conditions}
                    onChange={this.onChangeHandler('medical_conditions')}
                    value={this.state.dog.medical_conditions}
                    component={({ option, onClick, checked }) => (
                      <div className="col-12 col-md-3">
                        <Checkbox
                          label={option.name}
                          active={checked}
                          onClick={onClick}
                          white
                        />
                      </div>
                    )}
                  />
                </Row>
              </div>
            </Row>
          </BackgroundContainer>
        </form>
        <Row>
          <div className="col-12 col-md-8 no-padding">
            <Row>
              <div className="col-12 col-md-6 align-self-center">
                <p className="whos-taking-me-out">Who's taking me out today?</p>
              </div>
              <div className="col-12 col-md-6 align-self-center">
                <img className="dexter-with-ball" src="/assets/images/kasper-playing.png" />
              </div>
            </Row>
          </div>
        </Row>
      </div>
    );
  }
}

export default connect(() => ({
  conditions: dogsSelectors.getAllConditions(),
}), dispatch => ({
  actions: {
    dogs: bindActionCreators(dogsActionCreators, dispatch),
  },
}), null, { withRef: true })(DogActivity);
