import React from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

import * as selectors from '../../data/dogs/selectors';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as actionTypes from '../../data/exports';

const Dropdown = (props) => {
  const convertedOptions = props.options.map(item => ({
    ...item,
    value: item.name,
    label: item.name,
  }));

  return (
    <CreatableSelect
      classNamePrefix="Dropdown"
      isClearable={props.isClearable}
      options={convertedOptions}
      onChange={props.toggleItem}
      // if null passed, shows placeholder
      value={props.value === null ? null : { value: props.value, label: props.value }}
      onCreateOption={async (optionName) => {
        // props.onCreateOption must return an object with a name field
        // NOTE: async and await are here because onCreateOption can return promise or value
        const newOption = await props.onCreateOption(optionName);
        props.toggleItem({
          ...newOption,
          value: newOption.name,
          label: newOption.name,
          active: false,
        });
      }}
      placeholder={props.placeholder}
    />
  );
};

const mapStateToProps = state => ({
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
