import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCheckoutTotal } from '../utils';
import { getShopDeliveryCost } from '../data/orders/api';

import * as dogsActionCreators from '../data/dogs/actions';
import * as dogsSelectors from '../data/dogs/selectors';
import * as ordersActionCreators from '../data/orders/actions';

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      deliveryCost: 0,
      incompleteOrder: this.props.incompleteOrder ? this.props.incompleteOrder : [],
    }
  }

  componentDidMount() {
    this.props.actions.orders.getIncompleteOrder(this.props.dog.ID);
    getShopDeliveryCost().then(res => {
      this.setState({
        total: getCheckoutTotal(this.state.incompleteOrder, 0, 2, false, res.DeliveryCost).total,
        deliveryCost: getCheckoutTotal(this.state.incompleteOrder, 0, 2, false, res.DeliveryCost).deliveryCost,
      })
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.dog && this.props.dog !== prevProps.dog) {
      this.props.actions.orders.getIncompleteOrder(this.props.dog.ID);
    }

    if (this.props.incompleteOrder !== prevProps.incompleteOrder) {
      this.setState({ incompleteOrder: this.props.incompleteOrder ? this.props.incompleteOrder : [] });
      getShopDeliveryCost().then(res => {
        this.setState({
          total: getCheckoutTotal(this.state.incompleteOrder, 0, 2, false, res.DeliveryCost).total,
          deliveryCost: getCheckoutTotal(this.state.incompleteOrder, 0, 2, false, res.DeliveryCost).deliveryCost,
        })
      });
    }
  }

  render() {
    const { history, checkoutURL } = this.props;
    const { total, deliveryCost, incompleteOrder } = this.state;

    if (incompleteOrder.length > 0) {
      const qtyTotal = incompleteOrder.reduce((currentTotal, value) => {
        currentTotal += Number(value.quantity);
        return currentTotal;
      }, 0);

      return (
        <div className="shopping-cart-box" >
          <div className="shopping-cart-tile">
            <div className="cart-title-text">
              <span>BUY NOW SHOPPING CART</span>
            </div>
            <div className="shopping-cart">
              <img className="shopping-cart-icon" src="../assets/images/cart/large-shopping-cart.png" alt="" />
              <span className="shopping-cart-counts">{qtyTotal}</span>
            </div>
          </div>

          <div className="cart-price-detail">
            {incompleteOrder.map((item, index) => (
              <div className="cart-text" key={index}>
                <div className="cart-title"><span>{item.quantity} x</span> {item.product}</div>
                <div className="cart-price">£{(item.quantity * item.price).toFixed(2)}</div>
              </div>
            ))}
            <div className="cart-text">
              <div className="cart-title">Delivery</div>
              <div className="cart-price">£{deliveryCost}</div>
            </div>

            <div className="cut-off-line" />

            <div className="cart-text">
              <div className="cart-title">Total</div>
              <div className="cart-price">£{(total + deliveryCost).toFixed(2)}</div>
            </div>
          </div>
          <div className="checkout-box">
            <button
              className="cart-checkout-button"
              onClick={() => history.push(checkoutURL)}
            >
              PROCEED TO CHECKOUT
            </button>
          </div>
        </div>
      );
    }
    return (null);
  };
};

export default connect((state) => ({
  dog: dogsSelectors.getCurrentDog(),
  incompleteOrder: state.data.orders.incompleteOrder,
  orders: state.data.orders
}), dispatch => ({
  actions: {
    dogs: bindActionCreators(dogsActionCreators, dispatch),
    orders: bindActionCreators(ordersActionCreators, dispatch),
  },
}), null, { withRef: true })(ShoppingCart);