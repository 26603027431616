import React from 'react';
import PropTypes from 'prop-types';

const UploadFile = ({ onFileLoaded }) => {
  const handleLoadLocalFile = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];

    if (file) {
      reader.onloadend = () => onFileLoaded(reader.result);
      reader.readAsDataURL(file);
    } else {
      alert('File uploaded is not valid.');
    }
  };

  return (
    <div>
      <input
        className="upload-btn"
        type="file"
        accept=".jpg, .jpeg, .png, .svg"
        onChange={handleLoadLocalFile}
      />
    </div>
  );
};

UploadFile.propTypes = {
  onFileLoaded: PropTypes.func.isRequired,
};

export default UploadFile;
