import * as actionTypes from './actionTypes';

export const quantityUp = (dogId, productId) => ({
  type: actionTypes.QUANTITY_UP,
  dogId,
  productId,
});

export const quantityDown = (dogId, productId) => ({
  type: actionTypes.QUANTITY_DOWN,
  dogId,
  productId,
});

export const quantityChange = (dogId, productId, quantity) => ({
  type: actionTypes.QUANTITY_CHANGE,
  dogId,
  productId,
  quantity,
});

export const deleteAllForDog = dogId => ({
  type: actionTypes.DELETE_ALL_FOR_DOG,
  dogId,
});

export const deleteItem = (dogId, productId) => ({
  type: actionTypes.DELETE_ITEM_FOR_DOG,
  dogId,
  productId,
});

export const addToCart = (dogId, productId, quantity = 1) => ({
  type: actionTypes.ADD_TO_CART,
  dogId,
  productId,
  quantity,
});
export const addToCartForMultiple = (dogIds, productIds, quantity = 1) => ({
  type: actionTypes.ADD_TO_CART_MULTIPLE,
  dogIds,
  productIds,
  quantity,
});

export const deleteAll = () => ({
  type: actionTypes.DELETE_ALL,
});
