import React from "react";

const GreyText = ({ text, centered, marginBottom, bold, fontSize, children }) => (
  <p
    className={`${
      (centered ? " center " : "") +
      (marginBottom ? " margin-bottom " : "") +
      (bold ? " bold " : "")
    }grey-text`}
    style={fontSize ? { fontSize } : undefined}
  >
    {text ? text : children}
  </p>
);

export default GreyText;
