import React from 'react';
import { Grid, Hidden, Modal } from '@material-ui/core';
import Layout from '../Layout';
import OrderSummaryContainer from './OrderSummaryContainer';
import CheckoutSteps from './CheckoutSteps';
import PadlockIcon from '../icons/PadlockIcon';
import CartFab from './CartFab';
import { isCartEmpty } from '../../data/cart/utils';

export default class CheckoutLayout extends React.Component {
  state = {
    summaryModalShown: false,
  };

  toggleSummaryModal = () => {
    this.setState(prevState => ({
      summaryModalShown: !prevState.summaryModalShown,
    }));
  };

  render() {
    const {
      step, classes, children, title, submitSummary, paymentLoading, productCount, detailsValid
    } = this.props;
    return (
      <Layout classes="checkout-page" productCount={productCount} checkout>
        <div className="inner-container">
          <Grid container className="checkout-layout content-container">
            <Grid item xs={12}>
              <CheckoutSteps step={step} />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xl={8} xs={12} className="checkout-title">
                  <Grid container justify="space-between" alignItems="center" className="checkout-header">
                    <Grid item>
                      <Grid container alignItems="center">
                        <img className="paw-img" alt="paw-img" src="../../assets/images/dashboard-nav-icon.png" />
                        <h1 className="page-name">{title}</h1>
                      </Grid>
                    </Grid>
                    {step === 3 ?
                      <Grid item>
                        <Grid container alignItems="center">
                          <PadlockIcon /><span className="secure-encryption">Secure Encryption</span>
                        </Grid>
                      </Grid> : null
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item className={`checkout-content ${classes}`} xl={8} xs={12}>
                      {children}
                    </Grid>
                    {!isCartEmpty() ? (
                      <Hidden lgDown>
                        <Grid item className="checkout-sidebar" xl={4}>
                          <OrderSummaryContainer
                            step={step}
                            paymentLoading={paymentLoading}
                            detailsValid={detailsValid}
                            submitSummary={submitSummary}
                          />
                        </Grid>
                      </Hidden>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isCartEmpty() ? (
              <Hidden xlUp>
                <Modal hideBackdrop open={this.state.summaryModalShown}>
                  <OrderSummaryContainer
                    isModal
                    step={step}
                    closeModal={this.toggleSummaryModal}
                    paymentLoading={paymentLoading}
                    detailsValid={detailsValid}
                    submitSummary={submitSummary}
                  />
                </Modal>
              </Hidden>
            ) : null}
          </Grid>
        </div>
      </Layout>
    );
  }
}
