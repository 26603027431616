import React from 'react';

import { Link } from 'react-router-dom';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import SimplyPowesome from '../components/SimplyPowesome';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';

const terms = [
  {
    title: 'Terms & Conditions',
    contents: [
      'Kasper & Kitty reserves the right to amend or modify these terms or any other terms or policies referenced herein in its sole discretion. These Terms and Conditions were last updated on the 14th November 2018.\n',
    ],
  },
  {
    title: 'About Us:',
    contents: [
      'The use of this website and/or the associated digital app, member login area of website (hereinafter referred to collectively as the “Site”), food delivery service and the placing of an order for Kasper & Kitty products (hereinafter referred to as the “Products”) through the Site are subject to these terms and conditions (hereinafter referred to as the "Agreement"). By using the Site, you acknowledge that you have read and understand this Agreement and that you agree to be bound by all of its terms. If at any time you donot wish to accept the Agreement you must not continue to use the Site and Products. Your continued use of the Site, Products and your continued subscription after we make any changes constitutes your acceptance of the changes. If you do not agree to the changes, you must cancel your subscription..\n',
      'The Site is intended for use by UK residents age 18 or older. Anyone under the age of 18 should not use the Site without the consent and supervision of a parent or legal guardian.\n',
      'This website is owned and operated by Kasper and Kitty Ltd. We are a company registered in England and Wales (company number 10904119). Address for correspondence is 12 Park Lane, Tilehurst, Reading, RG31 5DL. VAT no: 280 6969 62\n',
    ],
  },
  {
    title: 'Interpretation',
    contents: [
      '1. Consumer means an individual acting for purposes which are wholly or mainly outside his or her trade, business, craft or profession;\n',
      '2. Contract means the legally-binding agreement between you and us for the sale and purchase of the Goods;\n',
      '3. Delivery Location means the location where the Goods are to be supplied, as set out in the Order;\n',
      '4. Goods means any goods that we supply to you, of the number and description as set out in the Order; \n',
      "5. Order means the Customer's order for the Goods from the Supplier as set out in the Customer's order or in the Customer's written acceptance of the Supplier's quotation.\n",
    ],
  },
  {
    title: 'Privacy and Electronic Communication',
    contents: [
      'To understand our practices, please review our Privacy Notice, which also governs your visit to this site.When you visit this site, sign up to an account voluntarily either online or through our Kasper and Kitty App or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on thissite. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing\n',
    ],
  },
  {
    title: 'Registration & Account',
    contents: [
      'Once an order has been placed through either our Website or App, an email acknowledgement will be sent to let you know that your order has been placed. By registering through our process, this represents your offer to purchase goods from us, which is accepted by us and confirmed in yourOrder Confirmation email. Our acceptance of your order creates a legally binding contract between us. The contract relates to the products that are listed on the Order Confirmation email.\n',
      'By subscribing to our service,you are agreeing to pay recurring charges for an indefinite time until cancelled by you or by us, this includes the period after signing up for a free trial.You can cancel at any time (after your first dog food parcelhas been sent & received) and will not be charged for cancellation. If you cancel before the first dog food parcel has been sent, we reserve the right to cancel your order and provide a refund of the payment. You can re-subscribe at any time following cancellation.If you cancel your Kasper and Kittysubscriptionand reactivate at a different time the pricemay bedifferent than the price of your originalplan at the time of cancelling.\n',
      'Your total price will be shown to you on the website or app on the day your order is processed and will include VAT. Your details can be edited at the stage before you confirm your order. If upon editing details the price changes, this will be shown to you before you confirm your next order.\n',
      'Please note that for delivery of your bespoke dry dog food, payment will be taken at the time youplace your order and will be charged to the payment card we hold on your account for you.\n',
      'To cancel your subscription,you must log-in to your account section of the website, or alternatively email friends@kasperandkitty.comto request cancellation.\n',
      'You may choose to pause your monthly delivery whereby we will not take payments for a period as specified by you, after which period we will continue to take payments as set out above.\n',
      'We reserve the right not to renew your subscription at any time without giving any reasons for our decision. We also reserve the right not to authorise a re-subscription.\n',
      'Accounts are non-transferable, and you cannot assign, transfer, trade or sell your Account with us to any other party.\n',
      'You must inform us of any changes to your information.  This includes payment details, delivery address and name changes. Orders will not be processed if the payment card has expired or changed.\n',
      'You agree not to impersonate any other person whilst using our Site. You also agree not to conduct yourself in an offensive, illegal, immoral or offensive manner whilst using our website.\n',
    ],
  },
  {
    title: 'The Goods',
    contents: [
      'The description of the Goods is as set out in our website, App, catalogues, brochures or other form of advertisement. Any description is for illustrative purposes only and there may be small discrepancies in colour or size.\n',
      'In the case of Goods made to your special requirements, it is your responsibility to ensure that any information you provide is accurate.\n',
    ],
  },
  {
    title: 'Offers, Vouchers, Promotions, Discounts and Gift Cards',
    contents: [
      'Free or discounted introductory offers, including the 2-week free trial, are only available to new users of Kasper and Kitty and are limited to one trial per dog, per household. Up to a maximum of 21kg of bespoke food.\n',
      'Discounted offers only apply to Kasper and Kitty subscription products, unless expressly stated.\n',
      'Offers or Vouchers may not be copied, reproduced, distributed or published either directly or indirectly in any form or stored in a data retrieval system without our prior written approval.\n',
      'Discounts cannot be used in conjunction with any other offers.\n',
      'You must provide valid payment details to redeem a free or discounted offer.\n',
      'You will be charged the full price for Kasper and Kitty bespoke dog food after your free or discounted offer. We will continue to bill you by your chosen payment method for the Kasper and Kitty service until you cancel your regular deliveries.\n',
      'We reserve the right to withdraw or deactivate any offer, promotion or discount, for any reason, at any time. \n',
      'To use your offer, you will be required to enter its unique code at our online checkout and use of such code will be deemed to confirm your agreement to these terms and conditions and any special conditions attached to the offer. \n',
      'Any discounts attached to offers, promotions and/or discounts apply to the price of the Products ordered only and not to any delivery charges due. \n',
      'We reserve the right to exclude the use of Offers, promotions or Discounts on specific products. \n',
      'There is no cash alternative for any promotions, offers or discounts.\n',
    ],
  },
  {
    title: 'Availability and Delivery',
    contents: [
      'All orders are subject to acceptance and availability. If an item relating to your order or part of your order is out of stock, we will contact you by email.\n',
      'Products will be delivered via our third-party courier.\n',
      'Delivery dates for your first delivery are selected by you prior to confirmation of your first order. An estimated dispatch date will be stated in your order confirmation email. This can be subject to change based on stock levels, your delivery address and when you placed your order. We will always inform you via email when your next delivery is due based on your 4-week subscription.\n',
      'Delivery windows stated by Kasper and Kitty are an indication, and not a guarantee of delivery within these hours. We reserve the right to deliver your box at any point on the day of delivery set out in the dispatch confirmation.\n',
      'Delivery will be completed when our authorised courier company, deliver the Products to the address you gave us. If no one is available at your address to take delivery, our authorised courier company, will follow the delivery instructions provided to us by you. If no leave safe instructions are specified, the order will be left in an area that the authorised courier company considers safe.\n',
      'The leave safe location specified by you shall be within the area of the delivery address and shall be accessible to the courier. We will endeavour to follow any delivery instructions you provide but this cannot be guaranteed.\n',
      'Kasper and Kitty’s obligation to deliver the box shall be fulfilled once the courier delivers the box at the safe spot specified by you or the box is considered safe by the courier.\n',
      'If the personal handover of the box is not possible and no leave safe is available Kasper and Kitty may also deliver the box to a nearby household or business. A household or business shall be regarded ‘nearby’ if the recipient resides in the same building as the original addressee. In addition, the box may also be delivered to a recipient residing in proximate walking distance to the delivery address (e.g. same housing complex, building on the opposite side of the street, next-door building). All aforementioned nearby households and businesses are subsequently referred to as “neighbours”.\n',
      'The customer will be notified of such delivery to a neighbour by delivery notice or via email.\n',
      'Products ordered from us can be delivered to addresses within the UK (including Northern Ireland). This excludes PO Boxes.\n',
      'You must notify us of a change of address via email or by updating the relevant area of your account with advance warning. We are not liable to replace or refund any orders that have been delivered to the wrong address.\n',
      'We will inform you if we cannot accept your order, which may be result of (i) the products not being available due to unexpected limits on our resources which we could not reasonably plan for (ii)  there is a problem with your payment method, (iii) there has been a pricing or product description error on the website, (iv) we are unable to deliver the products due to requirements at your delivery destination, (v) unable to meet your specified delivery date  or (v) you don’t meet our eligibility to order criteria or are in breach of any of the T&Cs or other law.\n',
      'Our website is for UK subscriptions and orders only. No orders will be accepted for outside of the UK.\n',
      'You will not hold us responsible for any delays out of our control including inclement weather delaying third party couriers.\n',
      'You must report any missing or undelivered packages by emailing us at friands@kasperandkitty.com \n',
      'Please ensure your address, contact details and leave safe information is kept up to date as referred to in the Registration and Account Management section of these Terms & Conditions.\n',
      'Your Kasper and Kitty delivery will be your responsibility from the time we deliver the product to the address you have given us. You will be notified by our 3rd party logistics provider of when this is.\n',
      'Boxes and items delivered to you may be heavy – please take care when lifting them.\n',
      'You acknowledge that the food and treat products have a finite shelf life of approximately 2 years and once delivered you must take all steps necessary to ensure that the products are stored according to our recommendations. We will not be liable for any products which spoil due to poor storage after they have been delivered.'
    ],
  },
  {
    title: 'Risk and ownership',
    contents: [
      'Risk of damage to or loss of the goods passes to you at the time of delivery to you, or if you fail to take delivery at the agreed time, the time when we tried to deliver. You will only own the goods once they have been successfully delivered and when we have received cleared payment in full. Goods supplied are not for resale.\n',
      'Once the delivery is completed, (i.e. signed for by customer, left in a safe spot as specified by the customer or considered safe by authorised courier company, or delivered to a neighbour if no leave safe is provided or available) the risk of any damage or loss of the box will be with the customer. Kasper and Kitty shall not be held liable for any damage, defect or loss which may occur thereafter.\n',
      'You are fully responsible for any damages or losses due to any ambiguity regarding the safe spot specified by you. Kasper and Kitty is not obliged to review the safe spot as to its general suitability.\n',
      'Refusal of the box does not negate the charge, Kasper and Kitty will not be liable for any losses sustained by the customer relating to a refusal of delivery by our authorized courier company.\n',
    ],
  },
  {
    title: 'Price and Payment',
    contents: [
      'The prices payable for goods that you order are as set out in our website. All prices are inclusive of UK VAT at the current rates and are correct at the time of entering information.\n',
      'Product prices are liable to change at any time, but changes will not affect orders in respect of which we have already sent you a dispatch confirmation. If changes are made, then we will notify you by email.\n',
      'Payment for subscription products must be by Credit or Debit Card. We accept payment by Visa and Mastercard.\n',
      'We do not accept American Express.\n',
      'On-going subscription payments will then be taken on the payment dates set out in the dispatch confirmation email, and also displayed in ‘your account’ section of the website.\n',
      'Kasper and Kitty does not hold payment card information; this is held with authorised 3rd parties namely Stripe. Upon cancellation of regular deliveries, if regular deliveries are not re-activated within a period of 90 days, we will request that the relevant third party deletes the relevant payment information and/or removes Kasper and Kitty’s ability to charge the payment card.\n',
      'We take all reasonable care to keep the details of your order and payment secure. However, unless we are fraudulent or negligent, we will not be liable to you for any losses caused as a result of any unauthorised access to this data by a third party.\n',
    ],
  },
  {
    title: 'Password and Security',
    contents: [
      'As part of the registration process, you will be asked to select a username and password and you will be responsible for all activities occurring under your username and for keeping your password secure. We may refuse to grant you a username that impersonates someone else, is protected by trademark or proprietary rights law, or is vulgar, offensive or otherwise inappropriate, as determined by us in our sole discretion. You are solely responsible for any and all uses of your username, password, and account.\n',
      'You are fully responsible for all orders made through your Kasper and Kitty account via our website or our Kasper and Kitty App. This includes any change made to your personal information or your dogs’ profile at any time.\n',
      'Please notify us should you believe that another user has your log in details.\n',
    ],
  },
  {
    title: 'Returns - Food, Treats and accessories ',
    contents: [
      'If you are unhappy with your goods for a legitimate reason such as: missing or damaged contents, or the box did not arrive, we will offer a full refund as long as it can be shown the box product you were charged for was not provided as it should have been.\n',
      'It is important to Kasper and Kitty that you are satisfied with our products and services. If you are not satisfied or you wish to return a product, please see how to do so below:\n',
      '•	Email us at friends@kasperandkitty.com from the email address associated with your user account or send us a message directly from our website.\n',
      '•	Please include details of the product that you wish to return and a reason for return. Please do this within 30  days of receiving your product from us.\n',
      '•	If we require the goods to be returned to us, please take reasonable care of the goods whilst in your possession prior to any return. We will inspect the goods on arrival.\n',
      '•	We may request further information from you regarding your return.\n',
      '•	Customers are liable for return postage costs.\n',
      '•	If you are returning an item over £50, you should consider using a trackable returns service. We don’t guarantee that we will receive your returned item.\n',
      '•	Once your return is received and inspected, we will send you an email to notify you that we have received your returned item.\n',
      '•	Refunds will be processed, and a credit will automatically be applied to your original credit or debit card used for payment, within 10 days.\n',
      '•	If you haven’t received a refund yet, please contact us at friends@kasperandkitty.com.\n',
      '•	Only full priced items may be refunded, unfortunately Offers, Discounts and Sale items cannot be refunded.\n',
      "•	If you are a consumer, you will always have legal rights in relation to Products that are faulty or not as described. These legal rights and your right to cancel the Contract are not affected by the Returns Policy in this Clause or these Terms. Advice about your legal rights is available from your local Citizens' Advice Bureau or Trading Standards office. Please note bespoke food is not included in Your Right to Cancel.\n",
    ],
  },
  {
    title: 'Returns Policy – FitKolar',
    contents: [
      'Your FitKolar purchase offers a one-year limited warranty valid from the date of purchase.\n',
      'If you are unhappy with your goods for a legitimate reason such as: missing or damaged contents, or the box did not arrive, we will offer a full refund as long as it can be shown the box product you were charged for was not provided as it should have been.\n',
      'It is important to Kasper and Kitty that you are satisfied with our products and services. If you are not satisfied or you wish to return a product, please see how to do so below:\n',
      '•	Email us at friends@kasperandkitty.com from the email address associated with your user account or send us a message directly from our website. \n',
      '•	Please include details of the product that you wish to return and a reason for return. \n',
      '•	If we require the goods to be returned to us, please take reasonable care of the goods whilst in your possession prior to any return. We will inspect the goods on arrival. \n',
      '•	We may request further information from you regarding your return. \n',
      '•	Customers are liable for return postage costs.\n',
      '•	All defects which arise from faults in material or functionality are fixed free of charge. The warranty does not apply to consumable parts, such as batteries, clips, collars or leads sold by Kasper & Kitty or cover damage caused by accident, abuse, misuse or any similar cause. \n',
      '•	Kasper & Kitty Limited does not warrant that the operation of the FitKolar will be uninterrupted or error-free.\n',
      '•	The Limited Warranty is not applicable to: (i) normal wear and tear; (ii) defects or damage caused by misuse, failure to follow instructions, accident, neglect, abuse, alteration, unusual stress, modification, improper or unauthorised repair, improper storage, water if the product has been opened, use with unsuitable devices, software or services; (iii) use for medical, healthcare or treatment purposes; (iv) software and services provided by Kasper & Kitty to owners of the FitKolar; (v) loss and security of data; (vi) used or resold FitKolars\n',
      '•	Any attempt to open or take apart the device will void the warranty.\n',
      '•	If you are returning an item over £50, you should consider using a trackable returns service. We don’t guarantee that we will receive your returned item.\n',
      '•	Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. \n',
      '•	Refunds will be processed, and a credit will automatically be applied to your original credit or debit card used for payment, usually within 10 days but could take up to 30 days.\n',
      '•	If you haven’t received a refund yet, please contact us at friends@kasperandkitty.com.\n',
      '•	Only full priced items may be refunded, unfortunately Offers, Discounts and Sale items cannot be refunded.\n',
      "•	If you are a consumer, you will always have legal rights in relation to Products that are faulty or not as described. These legal rights and your right to cancel are not affected by the Returns Policy or these Terms. Advice about your legal rights is available from your local Citizens' Advice Bureau or Trading Standards office. Please note bespoke food is not included in Your Right to Cancel.\n",
    ],
  },
  {
    title: 'Right to Cancel',
    contents: [
      'All customers have the right to cancel their orders under the Distance Selling Regulations which gives consumers extra protection when buying online. Specific legislation here that applies is regulation of The Distance Selling Regulations. \n',
      'You have the right to cancel the purchase of a good without having to give a reason at any time within the "cooling off period" of seven working days, beginning on the day after you receive the goods.\n',
      'If you are in possession of the goods you are under the duty to retain them and take reasonable care of them. You must send the goods back to us to our contact address at your own cost (unless we delivered the item to you in error or the item is damaged or defective) as soon as possible once you have cancelled the contract. \n',
      'We reserve the right to make a charge not exceeding our direct costs of recovering the goods if you do not return the goods or return them at our expense. \n',
      'Once you have notified us that you wish to cancel the contract, any sum debited to us will be refunded to you as soon as possible and in any event within 30 days of your cancellation.\n',
      'You will not have any right to cancel a purchase for the supply of any of the following goods:\n',
      '•	Bespoke Food created specifically for your dog(s)\n',
    ],
  },
  {
    title: 'Cancellation by us',
    contents: [
      'We reserve the right to cancel the contract between us if:\n',
      'we have insufficient stock to deliver the goods you have ordered;\n',
      'we do not deliver to your area;\n',
      'one or more of the goods you ordered was listed at an incorrect price due to a typographical error or an error in the pricing information received by us from our suppliers; or\n',
      'we consider you in breach of our terms and conditions;\n',
      'If we do cancel your contract, we will notify you by e-mail and will refund charges for any outstanding orders as soon as possible but in any event within 30 days of your order.\n',
    ],
  },
  {
    title: 'Liability',
    contents: [
      'If you do not receive goods ordered within 30 days of the date on which you ordered them, we will have no liability to you unless you notify us in writing at our contact address of the problem within 60 days of the date on which you ordered the goods (unless this is not reasonably practicable). If you notify a problem to us under this condition, our only obligation will be, at your option:\n',
      'I.	to make good any shortage or non-delivery;\n',
      'II.	to replace or repair any goods that are damaged or defective; or\n',
      'III.	to refund to you the amount paid by you for the goods in question in whatever way we choose.\n',
      'Both parties shall only be liable under this contract for losses, which are a reasonably foreseeable consequence of the relevant breach of contract.\n',
      'You must observe and comply with all applicable regulations and legislation, including obtaining all necessary customs, import or other permits to purchase goods from our site. The importation or exportation of certain of our goods to you may be prohibited by certain national laws. We make no representation and accept no liability in respect of the export or import of the goods you purchase.\n',
      'It is your responsibility as a dog owner to ensure that the measurements given in respect of your dogs’ height, weight, allergens or health issues are correct and updated if any changes occur. You acknowledge that Kasper and Kitty’s bespoke diet is proposed as a guide only. Your vet should be consulted if you have any concerns at any time over your pet’s health, condition or weight. \n',
      'Kasper and Kitty bespoke food is not intended as a veterinary supplement, nor is it a prescription diet for your dog(s).\n',
      'There are some cases where the natural shape of a dog i.e., Whippet.  may mean our algorithms do not map across If you are unsure about this at any stage please consult your vet for further advice.\n',
      'FitKolar Activity Monitor for dogs is intended to encourage an active and healthy lifestyles for your dog. The FitKolar is equipped with sensors to track your dog’s movements and various other metrics. The data from these is intended to be a close estimation only of your dog’s activity and additional measures such as distance, goals, rest and calories. Please note there may be a degree of inaccuracy in these metrics. The activity monitor is not intended for medical/veterinary use and we recommend consulting your dog’s vet should you have any concerns about their health or wellbeing.\n',
      'Notwithstanding the foregoing, nothing in these terms and conditions is intended to limit any rights you might have as a consumer under applicable local law or other statutory rights that may not be excluded nor in any way to exclude or limit our liability to you for any death or personal injury resulting from our negligence.\n',
      'If we fail to comply with these Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breach of the Terms or our negligence, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if they were an obvious consequence of our breach or if they were contemplated by you and us at the time we entered into this contract.\n',
      'We only supply the goods for domestic and private use. You agree not to use the goods for any commercial, business or re-sale purpose.\n',
      'We do not exclude or limit in any way our liability for:\n',
      '(a) death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors;\n',
      '(b) fraud or fraudulent misrepresentation;\n',
      '(c) breach of the terms implied by section 12 of the Sale of Goods Act 1979(title and quiet possession);\n',
      '(d) breach of the terms implied by sections 13, 14 and 15 of the Sale of Goods Act 1979 (description, satisfactory quality, fitness for purpose and samples); and\n',
      '(e) defective products under the Consumer Protection Act 1987.\n',
      'Our maximum liability to you under the terms of this contract is the total amount paid by you for the goods and the services.\n',
    ],
  },
  {
    title: 'Events outside our control',
    contents: [
      'We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a Contract that is caused by an Event Outside Our Control. An Event Outside Our Control is defined below in Clause 18.2.\n',
      'An "Event Outside Our Control" means any act or event beyond our reasonable control, including without limitation, strikes, lock-outs or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war, fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster, or failure of public or private telecommunications networks or impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport.\n',
      'If an Event Outside Our Control takes place that affects the performance of our obligations under a Contract:\n',
      '(a) we will contact you as soon as reasonably possible to notify you; and\n',
      '(b) our obligations under a Contract will be suspended and the time for performance of our obligations will be extended for the duration of the Event Outside Our Control. Where the Event Outside Our Control affects our delivery of Products to you, we will arrange a new delivery date with you after the Event Outside Our Control is over.\n',
    ],
  },
  {
    title: 'Notices',
    contents: [
      'Unless otherwise expressly stated in these terms and conditions, all notices from you to us must either be sent via email to friends@kasperandkitty.com or in writing and sent to our contact address at 12 Park Lane, Tilehurst, Reading, RG31 5DL and all notices from us to you will be displayed on our website from time to time.\n',
    ],
  },
  {
    title: 'Changes to legal notices',
    contents: [
      'We reserve the right to change these terms and conditions from time to time and you should look through them as often as possible.\n',
    ],
  },
  {
    title: 'Governing Law, jurisdiction and language',
    contents: [
      'This website, any content contained therein, and any contract brought into being as a result of usage of this website are governed by and construed in accordance with English law. Parties to any such contract agree to submit to the exclusive jurisdiction of the courts of England and Wales. All contracts are concluded in English.\n',
      'We try to avoid any dispute, so we deal with complaints as follows: In the event that you have a complaint regarding a product or service purchased from Kasper and Kitty, please contact our Customer Loyalty team by emailing them at: friends@kasperandkitty.com\n',
    ],
  },
  {
    title: 'Invalidity',
    contents: [
      'If any part of these terms and conditions is unenforceable (including any provision in which we exclude our liability to you) the enforceability of any other part of these conditions will not be affected.\n',
    ],
  },
  {
    title: 'Website and APP ',
    contents: [
      'We take all reasonable steps to ensure that this Website and our Kasper and Kitty App is available 24 hours every day, 365 days per year. However, websites do sometimes encounter downtime due to server and, other technical issues. Therefore, we will not be liable if this website is unavailable at any time.\n',
      'Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.\n',
      'Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.\n',
    ],
  },
  {
    title: 'Links to and from other websites',
    contents: [
      'Any links to third party websites located on this Website are provided for your convenience only. We have not reviewed each third-party website and have no responsibility for such third-party websites or their content.\n',
    ],
  },
  {
    title: 'Privacy',
    contents: [
      'You acknowledge and agree to be bound by the terms of our Privacy Policy.\n',
      'Your privacy is critical to us. We respect your privacy and comply with the General Data Protection Regulation with regard to your personal information.\n',
      'These Terms and Conditions should be read alongside, and are in addition to our policies, including our privacy policy and cookies policy which can be found The Policy can be found on our website www.kasperandkitty.com/privacy-policy\n',
      'For the purposes of these Terms and Conditions: \n',
      " a. 'Data Protection Laws' means any applicable law relating to the processing of Personal Data, including, but not limited to the Directive 95/46/EC (Data Protection Directive) or the GDPR.\n",
      " b. 'GDPR' means the General Data Protection Regulation (EU) 2016/679.\n",
      " c. 'Data Controller', 'Personal Data' and 'Processing' shall have the same meaning as in the GDPR.\n",
      'We are a Data Controller of the Personal Data we Process in providing the Goods to you.\n',
      'Where you supply Personal Data to us, so we can provide Goods to you, and we Process that Personal Data in the course of providing the Goods to you, we will comply with our obligations imposed by the Data Protection Laws: \n',
      ' a. before or at the time of collecting Personal Data, we will identify the purposes for which information is being collected;\n',
      ' b. we will only Process Personal Data for the purposes identified;\n',
      ' c. we will respect your rights in relation to your Personal Data; and\n',
      ' d. we will implement technical and organisational measures to ensure your Personal Data is secure.\n',
      'For any enquiries or complaints regarding data privacy, you can contact our Data Protection Officer at the following e-mail address: data.protection@kasperandkitty.com.\n',
    ],
  },
  {
    title: 'Third party rights',
    contents: [
      'Nothing in this Agreement is intended to, nor shall it confer any rights on a third party.\n',
    ],
  },
  {
    title: 'Ownership of rights',
    contents: [
      'All rights, including copyright, in this website are owned by or licensed to Kasper and Kitty Ltd. Any use of this website or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use, is prohibited without our permission. You may not modify, distribute or repost anything on this website for any purpose.\n',
    ],
  },
  {
    title: 'Submissions and Input to this Site',
    contents: [
      "Any non-personal communication or material you submit to this website by electronic mail, upload, or otherwise, including any data, questions, comments, suggestions or the like (“Submissions”)  are, and will be treated as, non-confidential and non-proprietary. Anything you submit or post becomes the property of Kasper and Kitty, we may use and reproduce such Submission freely, and for any purpose. Specifically, Kasper and Kitty is free to use for any purposes whatsoever (including but not limited to the developing, manufacturing, advertising and marketing of products) any ideas, artwork, inventions, developments, suggestions or concepts contained in any Submission you send to this website. Any such use is without compensation to the party submitting information. By making a Submission, you are also warranting that you own the material/content submitted that it is not defamatory, and that Kasper and Kitty’s use will not violate any third party's rights or otherwise place us in breach of any applicable laws. Kasper and Kitty is under no obligation to use the information submitted. Kasper and Kitty will not be held liable for any information submitted which is false or untrue.\n",
    ],
  },
];

export default class Terms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
    scrollTo(0,0);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/terms-banner.jpg)" heading="Terms &amp; conditions" subtext="all the legal stuff" />
        <div className="terms-container">
          {terms.map((term, tindex) => (<div key={tindex} className="heading">{`${++tindex}\u00A0.\u00A0\u00A0${term.title}`}
            {term.contents.map((content, cindex) => (<div key={cindex} className="desc">
              <div className="desc-index">{`${tindex}.${++cindex}`}</div>
              <div className="desc-content">{content}</div>
          </div>))}
        </div>))}
        </div>
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
