import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReviewDogInfo from '../../components/wizard/ReviewDogInfo';
import Button from '../../components/Button';
import CloseIcon from '../../assets/images/wizard/review-close.png';
import AddDog from '../../assets/images/add-dog.png';
import * as cartActionCreators from '../../data/cart/actions';
import * as productActionCreators from '../../data/products/actions';
import * as dogsActionCreators from '../../data/dogs/actions';

class Review extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      spinning: false,
    }

    this.makeOrder = () => {
      let data = {};

      if (this.props.currentDog.progress !== 100) {
        return;
      }
      data = this.transformDogToLegacyFormat(this.props.currentDog);

      return this.props.createDog(data);
    };

    this.handleRouter = (route) => {
      $('#review').modal('hide');
      this.props.history.push(route);
    };


    this.transformDogToLegacyFormat = (dog) => {
      const _weight = dog.weight;
      let weight = null;
      const weightUnits = dog.weightUnits;
      const _height = dog.height;
      let height = null;
      const heightUnits = dog.heightUnits;
      switch (weightUnits) {
        case 'kg': {
          weight = _weight;
          break;
        }
        case 'lbs': {
          weight = _weight * 0.453592;
          break;
        }
        case 'st': {
          weight = _weight * 6.35029;
          break;
        }
        default: {
          weight = _weight;
          break;
        }
      }
      switch (heightUnits) {
        case 'cm': {
          height = _height;
          break;
        }
        case 'ft': {
          height = _height * 30.48;
          break;
        }
        case 'in': {
          height = _height * 2.54;
          break;
        }
        default: {
          height = _height;
          break;
        }
      }
      const pancreatitis = {
        id: 4,
        allergensIds: [2, 13],
      };
      const dietaryPreferences = [
        {
          type: 'grain-free',
          allergensIds: [3],
        },
        {
          type: 'cereal-free',
          allergensIds: [1, 13],
        },
        {
          type: 'hypoallergenic',
          allergensIds: [4, 13],
        },
      ];
      // const allergens = Object.keys(dog.allAllergens).map(item => dog.allAllergens[item]);
      // const _allergens = Object.keys(dog.allergens).map(item => dog.allergens[item]);
      const _medical_conditions = dog.health_issues.filter(item => item.selected);
      const _flavours = dog.flavours.filter(item => item.selected);

      // if (_medical_conditions.find(condition => condition.ID === pancreatitis.id)) {
      //   pancreatitis.allergensIds.forEach((allergenId) => {
      //     if (!_allergens.find(allergen => allergen.ID === allergenId)) {
      //       const allergen = allergens.find(_allergen => _allergen.ID === allergenId);
      //       if (allergen) {
      //         _allergens.push(allergen);
      //       }
      //     }
      //   });
      // }

      // if (dog.dietary_preferences !== '') {
      //   const dietaryPreference = dietaryPreferences
      //     .find(_dietaryPreference => _dietaryPreference.type === dog.dietary_preferences);
      //   if (dietaryPreference) {
      //     dietaryPreference.allergensIds.forEach((allergenId) => {
      //       if (!_allergens.find(allergen => allergen.ID === allergenId)) {
      //         const allergen = allergens.find(_allergen => _allergen.ID === allergenId);
      //         if (allergen) {
      //           _allergens.push(allergen);
      //         }
      //       }
      //     });
      //   }
      // }

      const data = {};

      // if purebred or unknown, use breed id, else used crosbreed id
      data.breed_id = dog.breedTab === 'purebred' || dog.crossBreed[0] === null ? dog.breed.ID : dog.crossBreed[0].ID;
      data.breeds_id = [data.breed_id];
      if (dog.crossBreed[1] != null) {
        data.breeds_id.push(dog.crossBreed[1].ID);
      }
      if (dog.crossBreed[2] != null) {
        data.breeds_id.push(dog.crossBreed[2].ID);
      }
      data.crossbreed = dog.breedTab === 'crossbreed';
      data.breed_id_mix = dog.breed_id_mix;

      data.fitness_plan = dog.fitness_plan;
      data.activity = dog.activity;
      data.age = (dog.yearAge * 12) + dog.months;
      data.current_dry_food = dog.current_dry_food.length > 0 ? dog.current_dry_food : ['None'];
      data.current_wet_food = dog.current_wet_food.length > 0 ? dog.current_wet_food : ['None'];
      data.dob = `${dog.dob}T00:00:00Z`;
      data.flavours = _flavours;
      data.gender = dog.gender;
      data.weight = weight;
      data.height = height;
      data.image_string = dog.image;
      data.medical_conditions = _medical_conditions;
      data.microchip_id = '';
      data.yearAge = dog.yearAge !== null ? dog.yearAge : 0;
      data.months = dog.months !== null ? dog.months : 0;
      data.name = dog.name;
      data.neutered = dog.neutered;
      data.spayed = dog.spayed;
      data.shape = dog.shape;
      data.titbits = dog.titbits;
      data.prescription_diet = false;
      data.working_dog = false;
      data.diets = dog.dietary_preferences === '' ? [] : [{ id: dog.dietary_preferences }];
      data.allergens = Object.keys(dog.allergens).map(id => dog.allergens[id]);
      data.is_allergic = dog.allergy;
      return data;
    };
  }

  render() {
    const { isError } = this.props;
    const dogList = this.props.storeDogs.map((dog, index) => (
      <div className="col-12 review-dog-item accordion" key={dog.name} id={`dog-accordion-${dog.name}`}>
        <div className="card">
          <div className="card-header row align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 pl-xl-3 pl-lg-3 pl-md-2 pl-sm-1 pl-2 row justify-content-center pr-0">
                  {dog.image.length !== 0
                          ?
                            <div className="review-dog-item__dog-avatar">
                              <img src={dog.image} alt="" />
                            </div>
                          :
                            <div className="review-dog-item__dog-name">
                              {dog.name.slice(0, 1).toUpperCase()}
                            </div>
                      }
                </div>
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-6 col-6 pr-xl-3 pr-lg-3 pr-md-2 pr-sm-1 pr-0 pl-0 text-left">
                  <h4 className="review-dog-item_name">{dog.name}</h4>
                  <p className={`review-dog-item_progress ${dog.progress === 100 ? 'completed' : ''}`}>{dog.progress}% registration complete</p>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2 pl-xl-3 pl-lg-3 pl-md-2 pl-sm-1 pl-0 text-right pr-0">
                  <button
                    className="btn btn-link text-right review-dog-item_toggle"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#${dog.name}`}
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  />
                </div>
              </div>
            </div>

          </div>

          <div
            id={`${dog.name}`}
            className="collapse"
            aria-labelledby="headingOne"
            data-parent={`#dog-accordion-${dog.name}`}
          >
            <div className="card-body p-0 pb-3">
              <ReviewDogInfo dog={dog} index={index} />
            </div>
          </div>
        </div>
      </div>
    ));
    return (
      <div
        className="modal animated fadeIn review-modal"
        id="review"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered row justify-content-center" role="document">
          <div className="modal-content pt-3 px-3">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Pet&apos;s Profile Overview</h5>
              <button type="button" className="close text-right" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><img src={CloseIcon} alt="" /></span>
              </button>
            </div>
            <div className="modal-body pb-0 px-0">
              <div className="container-fluid px-0 ">
                <div className="div row justify-content-center review-dog-list px-3">
                  {dogList}
                  {/* remove doglength from 3 below to add multidog registration back in
                  <div className="col-12 review-dog-item accordion" id="dog-accordion-add-god">
                    <div className="card">
                      <div className="card-header row align-items-center">
                        <div className="container">
                          <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 pl-xl-3 pl-lg-3 pl-md-2 pl-sm-1 pl-2 row justify-content-center pr-0">
                              <div
                                className="review-dog-item__dog-avatar add-dog"
                                onClick={() => this.handleRouter('/add-dog')}
                              >
                                <img src={AddDog} alt="" />
                              </div>
                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-6 col-8 row align-items-center">
                              <h4 className="review-dog-item_name pt-2">ADD DOG</h4>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                  */}
                </div>
              </div>
            </div>
            <div className="modal-footer row justify-content-start pb-4 pt-0">
              <div className="divider my-3" />
              <div className="col-12 pl-0 text-left">
                <div className={`${isError ? 'error' : 'congrats'} pb-2`}>
                  {isError
                        ?
                        'Please complete information about your pets to 100%'
                        :
                        'Yay! You’re done with info about your pets!'
                    }
                </div>
                <Button
                  disabled={isError}
                  onClick={() => {
                    this.setState({ spinning: true });
                    if (!this.props.isError) {
                      this.makeOrder()
                        .then(() => {
                          $('#review').modal('hide');
                          this.props.history.push('/cart');            
                        })
                    }
                  }}
                  style={this.state.spinning ? {opacity: 0.4, pointerEvents: 'none', color: 'grey', borderColor: 'grey', backgroundColor: 'white'} : {}}
                  classes=" button-primary fill red animated fadeInUp"
                  label={this.state.spinning ? "LOADING..." : "SUBMIT"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dogs: state.data.dogs,
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
});
const mapDispatchToProps = dispatch => ({
  updateCurrentDog: (key, index, value) => dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
  removeCurrentDog: index => dispatch(dogsActionCreators.removeStoreCurrentDog(index)),
  toggleDog: index => dispatch(dogsActionCreators.toggleDog(index)),
  createDog: bindActionCreators(dogsActionCreators.create, dispatch),
  selectDog: id => dispatch(dogsActionCreators.select(id)),
  addToCart: (dogId, productId) => dispatch(cartActionCreators.addToCart(dogId, productId)),
  addProductToStore: product => dispatch(productActionCreators.add(product)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Review));
