import React, { Component } from 'react';

export default class SgSection extends Component {
  render() {
    return (
      <div className="sg-container">
        <div className="row">
          <div className="col-2" />
          <div className="col-md-6">
            <h3 className="color-orange fw-bold mb-3">100% Satisfaction Guaranteed</h3>
            <p className="color-white">Kasper is confident that your dog will enjoy these treats. If you are not entirely satisfied with your purchase please contact us. This does not affect your statutory rights. If you have any questions about your dog's bespoke feeding plan our advisers will be happy to help.</p>
          </div>
          <div className="col-md-2">
            <img src="/assets/images/yellow-paws.png" className="hide-on-mobile"/>
          </div>
          <div className="col-2" />
        </div>
      </div>
    );
  }
}
