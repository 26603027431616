import React from 'react';

export default class Input extends React.Component {
  render() {
    const {
      type,
      value,
      placeholder,
      onChangeText,
      disabled,
      reference,
      name,
    } = this.props;
    return (
      <input
        className="default-input w-100 my-3 animated fadeInUp delay-_2s"
        min="0"
        type={type}
        name={name}
        value={value}
        onChange={value => onChangeText(value)}
        placeholder={placeholder}
        disabled={disabled}
        ref={reference}
      />
    );
  }
}
