import React from 'react';
import { connect } from 'react-redux';

import Navigation from '../../components/Navigation';
import DogNavigation from '../../components/DogNavigation';
import SubNavigation from '../../components/SubNavigation';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';
import Calories from '../../components/Calories';

import * as dogsSelectors from '../../data/dogs/selectors';
import * as dogsActions from '../../data/dogs/actions';
import * as sessionSelectors from '../../services/session/selectors';

const DogFoodClass = ({ dog }) => (
  <YourDogsFood dog={dog} />
);

class YourDogsFood extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: undefined,
      month: undefined,
      year: undefined,
      calories: 0,
      shape: '',
      food: {},

      shares: [0, 0, 0, 0],
      knames: ["", "", "", ""],
      ingredients: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
      nutrients: ["", ""],
      vitamins: ["", ""],
      keyIngredients: ["", "", ""],
      short: false,
    };

    this.loadDogsData = this.loadDogsData.bind(this);
  }

  componentDidMount() {
    this.loadDogsData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.dog && (prevProps.dog !== this.props.dog)) {
      this.loadDogsData();
    }
  }

  loadDogsData() {
    dogsActions.getCalories(this.props.dog.ID)
      .then((resp) => {
        this.setState({
          calories: resp.totalCalories,
          shape: resp.shape,
          food: resp.food,
        });
      });

    dogsActions.getKibbleShares(this.props.dog.ID)
      .then((resp) => {
        // prepare ingredients. server sends 10
        let ings = resp.ingredients.map(s => s.trim())
        let nuts = resp.nutrients.map(s => s.trim())
        let vits = resp.vitamins.map(s => s.trim())
        this.setState({
          shares: resp.shares,
          knames: resp.knames,
          ingredients: ings,
          nutrients: nuts,
          vitamins: vits,
          keyIngredients: resp.key_ingredients
        });
      });
  }

  render() {
    // total food needed

    // what was happening here?
    const foodCalPercentage = Math.round(this.state.food.calories / this.state.calories * 100);
    const portion = parseInt(this.state.food.portion, 10);

    const dogs = dogsSelectors.getAll();

    return (
      <div className="container your-dogs-food">
        <Navigation />

        {dogs && Object.keys(dogs).length >= 2 &&
          <DogNavigation />
        }

        <SubNavigation />

        <div className="inner-container">
          <KaushanScriptHeading title={`${this.props.dog.name}'s food`} />

          <Calories
            portion={portion}
            calories={this.state.calories}
            shape={this.state.shape}
            dog={this.props.dog}
            ingredients={this.state.ingredients}
            nutrients={this.state.nutrients}
            vitamins={this.state.vitamins}
            counter
            percentage={foodCalPercentage}

            shares={this.state.shares}
            knames={this.state.knames}
            keyIngredients={this.state.keyIngredients}
            short={this.state.short}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  dogs: state.data.dogs.dogs,
  dog: dogsSelectors.getCurrentDog(),
  user: sessionSelectors.getUser(),
}))(DogFoodClass);
