import React from 'react';

export default class IngredientOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.toggleOption = (value) => {
      this.props.toggle(value);
    };
  }
  render() {
    const {
      label, value, isIcon, icon, enable, active,
    } = this.props;
    return (
      <div className={`ingredient-option-container ${enable ? 'enable' : ''} ${active ? 'active' : ''}`} onClick={() => this.toggleOption(value)}>
        <div className="option">
          {isIcon
                ?
                  <img className={"icon"} src={icon} alt={label} />
                :
                ''
            }
          <span className="label">{label}</span>
        </div>
      </div>
    );
  }
}
