import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slider from 'react-rangeslider';

import Layout from '../../components/Layout';
import DogNavigation from '../../components/DogNavigation';
import SubNavigation from '../../components/SubNavigation';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';
import BackgroundContainer from '../../components/BackgroundContainer';
import Row from '../../components/Row';
import FullColumn from '../../components/FullColumn';
import HalfColumn from '../../components/HalfColumn';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import FormErrors from '../../components/FormErrors';
import DateSelectionBanner from '../../components/DateSelectionBanner';
import InputField from '../../components/InputField';
import Dropdown from '../../components/Dropdown';
import ImperialCheckbox from '../../components/ImperialCheckbox';
import GreyText from '../../components/GreyText';

import * as dogsSelectors from '../../data/dogs/selectors';
import * as dogsActionCreators from '../../data/dogs/actions';

const yesNo = ['Yes', 'No'];
const maleFemale = ['Male', 'Female'];

const CheckGroup = ({
  data, onClick, radio, active,
}) => (
    <div className="check-group-container">
      {data.map((item, index) => (
        <a
          key={index}
          className="check-group-item"
          onClick={(e) => {
            e.preventDefault();

            onClick(item);
          }}
          href
        >
          <div className="check">
            {radio ? (
              <div className={`check-inner${item === active ? ' active ' : ''}`} />
            ) : (
                <div className={`check-inner${active.map(activeItem => activeItem).indexOf(item) !== -1 ? ' active ' : ''}`} />
              )}
          </div>
          <p>{item}</p>
        </a>
      ))}
    </div>
  );

const toggleUnits = (val, key, isImperial) => {
  let result = 0;
  if (key === 'weight') {
    if (isImperial) {
      result = val / 2.205;
    } else {
      result = val * 2.205;
    }
  } else if (isImperial) {
    result = val / 2.25;
  } else {
    result = val * 2.25;
  }
  return Math.round(result);
};

const getCarouselItemClass = (key, state) => {
  if (state === 1 || state === 9) {
    if (state === (key - 2) || state === (key + 2)) {
      return ' displayed ';
    }
  }

  if (state === (key - 1) || state === (key + 1)) {
    return ' expanded ';
  }

  return '';
};

const getActivityCarouselItemClass = (key, state) => {
  if (state === (key - 1) || state === (key + 1) || state === (key - 3) || state === (key + 3) || state === (key - 5) || state === (key + 5) || state === (key - 7) || state === (key + 7)) {
    return ' displayed ';
  }
  if (state === (key - 2) || state === (key + 2)) {
    return ' mediumExpanded ';
  }
  if (state === (key - 4) || state === (key + 4)) {
    return ' mediumExpanded ';
  }
  if (state === (key - 4) || state === (key + 4)) {
    return ' mediumExpanded ';
  }
  if (state === (key - 6) || state === (key + 6)) {
    return ' mediumExpanded ';
  }
  if (state === (key - 8) || state === (key + 8)) {
    return ' mediumExpanded ';
  }

  return '';
};

class DogProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      today: new Date(),
      dogImage: '',
      neutered: false,
      spayed: false,
      healthIssues: [],
      titbits: false,
      hasAllergies: false,
      foodAllergens: [],
      dietPreferences: [],
      favouriteFlavours: [],
      errors: [],
      message: '',
      fitness_plan: false,

      microchipId: 0,
      age: 0,
      weight: 0,
      date: 0,
      month: 0,
      year: 0,
      name: '',
      gender: 'male',
      breeds_id: [],
      activity: 1,
      shape: 1,

      years: 0,
      months: 0,

      isLb: false,
      isInch: false,

      activities: [],
      shapes: [],

      imageError: '',
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.isVariableAlredyInState = this.isVariableAlredyInState.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.setDogInformation = this.setDogInformation.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);

    // Fetches and adds to redux
    this.props.actions.dogs.fetchFlavours();
    this.props.actions.dogs.fetchAllergens();
    this.props.actions.dogs.fetchConditions();
    this.props.actions.dogs.fetchDiets();
    this.props.actions.dogs.fetchBreeds();

    this.setDogInformation();

    dogsActionCreators.getGoals()
      .then((response) => {
        const activities = response
          .sort((a, b) => a.code - b.code)
          .map(r => ({
            key: r.ID,
            label: r.name,
            text: r.text,
            steps: r.steps,
            imageSource: (r.image.Files) ? `http://${r.image.Files[0].Url}` : '/assets/images/dog-2.png',
            activeImageSource: (r.imageActive.Files) ? `http://${r.imageActive.Files[0].Url}` : '/assets/images/dog-2.png',
          }));
        this.setState({ activities });
      });

    dogsActionCreators.getShapes()
      .then((response) => {
        const shapes = response.sort((a, b) => a.code - b.code).map(r => ({
          key: r.ID,
          label: r.name,
          text: r.text,
          imageSource: (r.image.Files) ? `http://${r.image.Files[0].Url}` : '/assets/images/dog-2.png',
          activeImageSource: (r.imageActive.Files) ? `http://${r.imageActive.Files[0].Url}` : '/assets/images/dog-2.png',
        }));

        this.setState({ shapes });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.dog && (this.props.dog.ID !== prevProps.dog.ID)) {
      this.setDogInformation();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onChangeImage() {
    // Assuming only image
    const upload = this.file.files[0];
    const size = upload.size / 1024;
    const type = upload.type.split('/');
    const allowed = ['png', 'jpeg', 'jpg', 'gif', 'svg'];

    if (size > 10 * 1024) {
      this.setState({ imageError: 'The size is bigger than 10MB.', dogImage: '' });
      this.file.value = '';
      return;
    }

    if (type[0] !== 'image' || allowed.indexOf(type[1]) < 0) {
      this.setState({ imageError: 'The type of image should be .png, .jpeg, .jpg, .svg or .gif.', dogImage: '' });
      this.file.value = '';
      return;
    }

    if (upload) {
      const reader = new FileReader();
      reader.onloadend = () => this.setState({ dogImage: reader.result, imageError: '' });
      reader.readAsDataURL(upload);
    } else {
      this.setState({ imageError: '', dogImage: '' });
      this.file.value = '';
    }
  }

  onChangeHandler(key) {
    return (event) => {
      let value = event;

      if (event.target) {
        value = event.target.value;
      }

      if (event.value) {
        value = event.value;
      }

      let formattedValue = value;

      const numericKeys = ['activity', 'shape'];
      if (numericKeys.indexOf(key) !== -1) {
        if (value.length > 0) {
          formattedValue = parseFloat(value);

          if (Number.isNaN(formattedValue)) {
            formattedValue = value;
          }
        }
      }

      this.setState({ [key]: formattedValue });
    };
  }

  // Gets the informattion about the dogs and sets the state based on the values
  setDogInformation() {
    const { dog } = this.props;

    if (dog !== undefined) {
      const conv_dob = new Date(this.props.dog.dob);
      const currentYear = this.state.today.getFullYear();
      const currentMonth = this.state.today.getMonth() + 1;
      const breedId = dog.breeds ? dog.breeds.map(item => item.ID) : [];
      const birthMonth = conv_dob.getMonth() + 1;
      const birthYear = conv_dob.getFullYear();
      let ageYear;
      let ageMonth;


      if (birthMonth <= currentMonth) {
        ageYear = currentYear - birthYear;
        ageMonth = currentMonth - birthMonth;
      } else {
        ageYear = currentYear - birthYear - 1;
        ageMonth = (12 - birthMonth) + currentMonth;
      }


      this.setState({
        year: conv_dob.getFullYear(),
        month: conv_dob.getMonth(),
        date: conv_dob.getDate(),

        microchipId: dog.microchip_id,
        name: dog.name,
        height: dog.height,
        heightShow: dog.height.toFixed(2),
        age: dog.age,
        currentMonth,
        currentYear,
        months: ageMonth,
        years: ageYear,
        weight: dog.weight,
        weightShow: dog.weight.toFixed(2),
        breeds_id: dog.breeds_id ? dog.breeds_id : breedId,
        neutered: dog.neutered,
        spayed: dog.spayed,
        gender: dog.gender,
        activity: dog.activity,
        shape: dog.shape,
        healthIssues: dog.medical_conditions !== null ? dog.medical_conditions : [],
        titbits: dog.titbits,
        hasAllergies: dog.is_allergic,
        fitness_plan: dog.fitness_plan,
        foodAllergens: dog.allergens !== null ? dog.allergens : [],
        dietPreferences: dog.diets !== null ? dog.diets : [],
        favouriteFlavours: dog.flavours !== null ? dog.flavours : [],
      });
    }
  }

  isVariableAlredyInState(variable, state) {
    return this.state[state].filter(item => item.name === variable).length > 0;
  }

  updateDimensions() {
    const imageWidth = this.image.offsetWidth;

    this.setState({ imageWidth });
  }

  handleUpdate() {
    const totalAge = (parseInt(this.state.years, 10) * 12) + parseInt(this.state.months, 10);
    let dob = new Date(this.state.year, this.state.month, this.state.date);

    const payload = {
      ID: this.props.dog.ID,
      gender: this.props.dog.gender,
      activity: this.state.activity,
      shape: this.state.shape,
      current_dry_food: this.props.dog.current_dry_food,
      current_wet_food: this.props.dog.current_wet_food,
      breed_id: this.state.breeds_id[0],
      breeds_id: this.state.breeds_id,
      crossbreed: this.state.breeds_id.length > 1,
      image_string: this.state.dogImage,
      name: this.state.name,
      dob: dob,
      microchip_id: this.state.microchipId,
      height: Number.parseFloat(this.state.height),
      age: totalAge,
      weight: Number.parseFloat(this.state.weight),
      neutered: this.state.neutered,
      spayed: this.state.spayed,
      fitness_plan: this.state.fitness_plan,
      is_allergic: this.state.hasAllergies,
      medical_conditions: this.state.healthIssues,
      titbits: this.state.titbits,
      allergens: this.state.foodAllergens,
      diets: this.state.dietPreferences,
      flavours: this.state.favouriteFlavours,
    };

    this.props.actions.dogs.updateDog(payload)
      .then((response) => {
        if (response.errors) {
          for (const err of response.errors) {
            this.state.errors.push(err);
          }

          return;
        }
        this.setState({ message: 'Successfully updated' });
        setTimeout(() => { this.setState({ message: '' }); this.props.history.push('/your-pets'); }, 2000);
      })
      .catch((err) => {
        console.log('An error has occured: ', err);
      });
  }

  breedChangeHandler(key) {
    return (event) => {
      const value = event.map(item => item.value);

      this.setState({ breeds_id: value });
    };
  }
  updateWithUnits(key) {
    // key is either weight or height
    return (event) => {
      let value = event;
      if (event.target) {
        value = event.target.value;
      }
      if (event.value) {
        value = event.value;
      }
      value = parseFloat(value);
      const toMetric = {
        weight: 0.45,
        height: 2.54,
      };
      const obj = {};
      obj[key] = Math.round(value
        * (this.state[key === 'weight' ? 'isLb' : 'isInch'] ? toMetric[key] : 1));
      obj[`${key}Show`] = value.toString();
      this.setState(obj);
    };
  }

  render() {
    const { breeds, dogs } = this.props;
    const { activities, shapes } = this.state;

    // dob
    let dob = new Date(this.props.dog.dob);
    dob = dob.toLocaleDateString('en-GB', {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    });

    // msg
    const msgDisp = this.state.message === '' ? 'none' : 'block';
    const img_fallback = this.props.dog.image.Url !== '' ?
      `url(http://${this.props.dog.image.Url})`
      :
      'url(/assets/images/kasper-image-white.png)';


    return (
      <Layout>
        {dogs && Object.keys(dogs).length >= 2 &&
          <DogNavigation />
        }

        <SubNavigation />

        <div className="inner-container">
          <KaushanScriptHeading title={`${this.props.dog.name}'s profile`} />
          <Row>
            <FormErrors errors={this.state.errors} />
            <FullColumn noPaddingBottom>
              <BackgroundContainer>
                <Row>
                  <div className="col-12 col-md-3 order-md-2">
                    <div style={{ zIndex: 0 }}>
                      <div className="gi-content">
                        <div style={{ zIndex: 1 }} className="dog-image-border small">
                          <img
                            alt=""
                            className="dog-image-frame"
                            src="assets/images/dog-image-frame.png"
                          />
                          <div
                            ref={(image) => {
                              this.image = image;
                            }}
                            className="dog-image-container"
                            style={{
                              height: this.state.imageWidth,
                              backgroundImage:
                                this.state.dogImage !== '' ?
                                  `url(${this.state.dogImage})` :
                                  img_fallback,
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <InputField
                      reference={(file) => {
                        this.file = file;
                      }}
                      type="file"
                      placeholder="Upload your dog's picture"
                      value={this.state.image}
                      onChangeText={this.onChangeImage}
                    />

                    {this.state.imageError.length > 0 &&
                      <p style={{ color: 'red', textAlign: 'center', lineBreak: 'anywhere' }}>{this.state.imageError}</p>
                    }
                  </div>

                  <div className="col order-md-1">
                    <OrangeTextHeading
                      text="General Information"
                      fontSize={26}
                      marginBottom
                    />

                    <table className="profile-table">
                      <tbody>
                        <tr>
                          <td>Name:</td>
                          <td><input value={this.state.name} onChange={e => this.setState({ name: e.target.value })} /></td>
                        </tr>
                        <tr>
                          <td>Gender:</td>
                          <td><CheckGroup
                            radio
                            data={maleFemale}
                            onClick={(val) => {
                              if (val === 'Male') {
                                this.setState({ gender: 'male' });
                              } else {
                                this.setState({ gender: 'female' });
                              }
                            }}
                            active={this.state.gender === 'male' ? 'Male' : 'Female'}
                          />
                          </td>
                        </tr>
                        <tr>
                          <td>Breed:</td>
                          <td>
                            <Dropdown
                              activeOption={this.state.breeds_id}
                              onChangeOption={this.breedChangeHandler('breeds_id')}
                              placeholder="Breed"
                              options={breeds}
                              isMultiple
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Date of birth:</td>
                          <td>
                            <DateSelectionBanner
                              onUpdateDate={(val) => {
                                this.setState({
                                  date: val.date,
                                  month: val.month,
                                  year: val.year,
                                  nowYear: this.state.today.getFullYear(),
                                  nowMonth: this.state.today.getMonth(),
                                  years: val.month <= this.state.today.getMonth() ? (this.state.today.getFullYear() - val.year) : (this.state.today.getFullYear() - val.year - 1),
                                  months: val.month <= this.state.today.getMonth() ? (this.state.today.getMonth() - val.month) : ((12 - val.month) + this.state.today.getMonth()),
                                });
                              }
                              }
                              currentDate={this.state.date}
                              currentMonth={this.state.month}
                              currentYear={this.state.year}
                              justIcon
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Age (years):</td>
                          <td><input value={this.state.years} onChange={e => this.setState({ years: e.target.value })} /></td>
                        </tr>
                        <tr>
                          <td>Age (months):</td>
                          <td><input value={this.state.months} onChange={e => this.setState({ months: e.target.value })} /></td>
                        </tr>
                        {this.state.gender === 'male' ?
                          <tr>
                            <td>Neutered:</td>
                            <td>
                              <CheckGroup
                                radio
                                data={yesNo}
                                onClick={(val) => {
                                  if (val === 'No') {
                                    this.setState({ neutered: false });
                                  } else {
                                    this.setState({ neutered: true });
                                  }
                                }}
                                active={this.state.neutered === true ? 'Yes' : 'No'}
                              />
                            </td>
                          </tr>
                          :
                          <tr>
                            <td>Spayed:</td>
                            <td>
                              <CheckGroup
                                radio
                                data={yesNo}
                                onClick={(val) => {
                                  if (val === 'No') {
                                    this.setState({ spayed: false });
                                  } else {
                                    this.setState({ spayed: true });
                                  }
                                }}
                                active={this.state.spayed === true ? 'Yes' : 'No'}
                              />
                            </td>
                          </tr>}
                        <tr>
                          <td>Weight:</td>
                          <td><input
                            value={this.state.weightShow}
                            type="number"
                            min="0"
                            placeholder={`Weight (${this.state.isLb ? 'Lb' : 'Kg'})*`}
                            onChange={
                              this.updateWithUnits('weight')
                            }
                          />
                          </td>
                          <td>
                            <ImperialCheckbox
                              data="Kg"
                              onClick={() => {
                                const weightShow = toggleUnits(this.state.weightShow, 'weight', this.state.isLb);

                                this.setState({ isLb: !this.state.isLb, weightShow });
                              }}
                              active={this.state.isLb}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Height:</td>
                          <td><input
                            value={this.state.heightShow}
                            type="number"
                            min="0"
                            placeholder={`Height (${this.state.isInch ? 'In' : 'Cm'})*`}
                            onChange={this.updateWithUnits('height')}
                          />
                          </td>
                          <td>
                            <ImperialCheckbox
                              data="Cm"
                              onClick={() => {
                                const heightShow = toggleUnits(this.state.heightShow, 'height', !this.state.isInch);

                                this.setState({ isInch: !this.state.isInch, heightShow });
                              }}
                              active={this.state.isInch}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Activity levels:</td>
                          <td>
                            <div className="activity-carousel-container">
                              {activities.filter(item => item.key === this.props.dog.activity).map(item => (
                                <a
                                  key={item.key}
                                  className="active activity-carousel-item"
                                  href
                                  onClick={e => e.preventDefault()}
                                >
                                  <img src={item.imageSource} alt="" />
                                  <p className="key">{item.key}</p>
                                </a>
                              ))}
                            </div>
                          </td>
                          <td>
                            <BackgroundContainer marginBottom>
                              <OrangeTextHeading
                                text={`Out of 9 how active would you say ${this.props.dog.name} is?`}
                                fontSize={30}
                                centered
                              />
                              <p style={{ width: '525px', height: '1px' }} />
                              <div className="activity-carousel-container">
                                <div className="line" />
                                {activities.map((item, index) => (
                                  <a
                                    href
                                    key={item.key}
                                    className={`${(this.state.activity === item.key ? ' mediumExpanded ' : '') + (getActivityCarouselItemClass(item.key, this.state.activity))}activity-carousel-item`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.onChangeHandler('activity')(item.key);
                                    }}
                                  >
                                    <img src={item.imageSource} alt="" />
                                    <p className="key">{index + 1}</p>
                                  </a>
                                ))}
                              </div>
                              <div className="activity-text-container">
                                {activities.map(item => (
                                  <div
                                    key={item.key}
                                    className={`${(this.state.activity === item.key ? ' active ' : '') + (getActivityCarouselItemClass(item.key, this.state.activity))}activity-text-item`}
                                  >
                                    <p>
                                      {item.label}
                                    </p>
                                  </div>
                                ))}
                              </div>
                              <Slider
                                min={1}
                                max={activities.length}
                                step={1}
                                value={this.state.activity}
                                orientation="horizontal"
                                reverse={false}
                                tooltip={false}
                                handleLabel={this.state.activity.toString()}
                                onChange={this.onChangeHandler('activity')}
                              />
                              <div className="carousel-text-container">
                                {activities.map(item => (
                                  <div
                                    key={item.key}
                                    className={`${this.state.activity === item.key ? ' active ' : ''}carousel-text`}
                                  >
                                    <GreyText
                                      text={item.text}
                                      fontSize={26}
                                      centered
                                      bold
                                    />
                                  </div>
                                ))}
                              </div>
                            </BackgroundContainer>
                          </td>
                        </tr>
                        <tr>
                          <td>Shape:</td>
                          <td>
                            <div className="activity-carousel-container">
                              {shapes.filter(item => item.key === this.props.dog.shape).map(item => (
                                <a
                                  key={item.key}
                                  className="active activity-carousel-item"
                                  href
                                  onClick={e => e.preventDefault()}
                                >
                                  <img src={item.imageSource} alt="" />
                                  <p className="key">{item.key}</p>
                                </a>
                              ))}
                            </div>
                          </td>
                          <td>
                            <BackgroundContainer marginBottom>
                              <OrangeTextHeading text={`What shape is ${this.props.dog.name}?`} fontSize={30} centered />
                              <div className="activity-carousel-container">
                                <div className="line" />
                                {shapes.map((item, index) => (
                                  <a
                                    href
                                    key={item.key}
                                    className={`${(this.state.shape === item.key ? ' active ' : '') + (getCarouselItemClass(item.key, this.state.shape))}activity-carousel-item`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.onChangeHandler('shape')(item.key);
                                    }}
                                  >
                                    {this.state.shape === item.key ? (
                                      <img alt="" src={item.activeImageSource} />
                                    ) : (
                                        <img alt="" src={item.imageSource} />
                                      )}
                                    <p className="key">{index + 1}</p>
                                  </a>
                                ))}
                              </div>
                              <div className="activity-text-container">
                                {shapes.map(item => (
                                  <div
                                    key={item.key}
                                    className={`${(this.state.shape === item.key ? ' active ' : '') + (getCarouselItemClass(item.key, this.state.shape))}activity-text-item`}
                                  >
                                    <p>
                                      {item.label}
                                    </p>
                                  </div>
                                ))}
                              </div>
                              <Slider
                                min={1}
                                max={shapes.length}
                                step={1}
                                value={this.state.shape}
                                orientation="horizontal"
                                reverse={false}
                                tooltip={false}
                                handleLabel={this.state.shape.toString()}
                                onChange={this.onChangeHandler('shape')}
                              />
                              <div className="carousel-text-container">
                                {shapes.map(item => (
                                  <div
                                    key={item.key}
                                    className={`${this.state.shape === item.key ? ' active ' : ''}carousel-text`}
                                  >
                                    <GreyText
                                      text={item.text}
                                      fontSize={26}
                                      centered
                                      bold
                                    />
                                  </div>
                                ))}
                              </div>
                            </BackgroundContainer>
                          </td>
                        </tr>
                        <tr>
                          <td>Fitness Plan:</td>
                          <td>
                            <CheckGroup
                              radio
                              data={yesNo}
                              onClick={(val) => {
                                if (val === 'No') {
                                  this.setState({ fitness_plan: false });
                                } else {
                                  this.setState({ fitness_plan: true });
                                }
                              }}
                              active={this.state.fitness_plan === true ? 'Yes' : 'No'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Health issues:</td>
                          <td>
                            <CheckGroup
                              data={this.props.health.map(item => item.name)}
                              onClick={(val) => {
                                // e.g. val=Pancreatis
                                const isInState = this.isVariableAlredyInState(val, 'healthIssues');

                                if (isInState) {
                                  const selection = this.state.healthIssues.map(item => item.name).indexOf(val);
                                  const list = this.state.healthIssues.map(item => item);

                                  // remove val from list
                                  list.splice(selection, 1);

                                  this.setState({ healthIssues: list });
                                } else {
                                  const list = [];

                                  for (let i = 0; i < this.props.health.length; i += 1) {
                                    if (this.props.health[i].name === val) {
                                      list.push(this.props.health[i]);
                                    }
                                  }

                                  this.setState({ healthIssues: [...this.state.healthIssues, ...list] });
                                }
                              }}
                              active={this.state.healthIssues.map(item => item.name)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Row>
                <Row>
                  <FullColumn>
                    <OrangeTextHeading
                      text="Eating Habits"
                      fontSize={26}
                      marginBottom
                    />
                    <table className="profile-table">
                      <tbody>
                        <tr>
                          <td>Allergies:</td>
                          <td>
                            <CheckGroup
                              radio
                              data={yesNo}
                              onClick={(val) => {
                                if (val === 'No') {
                                  this.setState({ hasAllergies: false });
                                } else {
                                  this.setState({ hasAllergies: true });
                                }
                              }}
                              active={this.state.hasAllergies === true ? 'Yes' : 'No'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Titbits:</td>
                          <td>
                            <CheckGroup
                              radio
                              data={yesNo}
                              onClick={(val) => {
                                if (val === 'No') {
                                  this.setState({ titbits: false });
                                } else {
                                  this.setState({ titbits: true });
                                }
                              }}
                              active={this.state.titbits === true ? 'Yes' : 'No'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Ingredients left out:</td>
                          <td>
                            <CheckGroup
                              data={this.props.allergens.map(item => item.name)}
                              onClick={(val) => {
                                const isInState = this.isVariableAlredyInState(val, 'foodAllergens');

                                if (isInState) {
                                  const selection = this.state.foodAllergens.map(item => item.name).indexOf(val);

                                  const list = this.state.foodAllergens;

                                  list.splice(selection, 1);

                                  this.setState({ foodAllergens: list }); // , () => this.handleUpdate());
                                } else {
                                  const list = [];

                                  for (let i = 0; i < this.props.allergens.length; i++) {
                                    if (this.props.allergens[i].name === val) {
                                      list.push(this.props.allergens[i]);
                                    }
                                  }

                                  this.setState({ foodAllergens: [...this.state.foodAllergens, ...list] });
                                }
                              }}
                              active={this.state.foodAllergens.map(item => item.name)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Diet preferences:</td>
                          <td>
                            <CheckGroup
                              data={this.props.diets.map(item => item.name)}
                              onClick={(val) => {
                                const isInState = this.isVariableAlredyInState(val, 'dietPreferences');

                                if (isInState) {
                                  const selection = this.state.dietPreferences.map(item => item.name).indexOf(val);

                                  const list = this.state.dietPreferences;

                                  list.splice(selection, 1);

                                  this.setState({ dietPreferences: list });
                                } else {
                                  const list = [];

                                  for (let i = 0; i < this.props.diets.length; i++) {
                                    if (this.props.diets[i].name === val) {
                                      list.push(this.props.diets[i]);
                                    }
                                  }

                                  this.setState({ dietPreferences: [...this.state.dietPreferences, ...list] });
                                }
                              }}
                              active={this.state.dietPreferences.map(item => item.name)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Favourite flavours:</td>
                          <td>
                            <CheckGroup
                              data={this.props.flavours.map(item => item.name)}
                              onClick={(val) => {
                                const isInState = this.isVariableAlredyInState(val, 'favouriteFlavours');

                                if (isInState) {
                                  const selection = this.state.favouriteFlavours.map(item => item.name).indexOf(val);

                                  const list = this.state.favouriteFlavours;

                                  list.splice(selection, 1);

                                  this.setState({ favouriteFlavours: list });
                                } else {
                                  const list = [];

                                  for (let i = 0; i < this.props.flavours.length; i++) {
                                    if (this.props.flavours[i].name === val) {
                                      list.push(this.props.flavours[i]);
                                    }
                                  }

                                  this.setState({ favouriteFlavours: [...this.state.favouriteFlavours, ...list] });
                                }
                              }}
                              active={this.state.favouriteFlavours.map(item => item.name)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FullColumn>
                </Row>

                <HalfColumn>
                  <div className="">
                    <button style={{ marginRight: '10px', marginBottom: '10px', width: '75px' }} onClick={this.handleUpdate}>Save</button>
                    <button
                      style={{ marginRight: '10px', marginBottom: '10px', width: '75px' }}
                      onClick={() => { this.setDogInformation(); this.props.history.push('/your-pets'); }}
                    >
                      Discard
                    </button>
                    <div className="alert alert-success" style={{ display: msgDisp }}>
                      {this.state.message}
                    </div>
                  </div>
                </HalfColumn>

              </BackgroundContainer>
            </FullColumn>
          </Row>
        </div>
      </Layout>
    );
  }
}

export default connect((state) => ({
  dogs: state.data.dogs.dogs,
  dog: dogsSelectors.getCurrentDog(),
  allergens: dogsSelectors.getAllAllergens(),
  flavours: dogsSelectors.getAllFlavours(),
  diets: dogsSelectors.getAllDiets(),
  health: dogsSelectors.getAllConditions(),
  breeds: dogsSelectors.getAllBreeds(),
}), dispatch => ({
  actions: {
    dogs: bindActionCreators(dogsActionCreators, dispatch),
  },
}))(DogProfile);
