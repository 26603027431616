import React from 'react';

const SliderNextIcon = props => (
  <svg onClick={props.onClick} className="slider-next-arrow" width="18px" height="13px" viewBox="0 0 18 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Step-1:-Shopping-Basket" transform="translate(-487.000000, -1708.000000)" fill="#FAB522">
        <g id="Group-11" transform="translate(439.000000, 1708.000000)">
          <g id="login" transform="translate(48.000000, 0.000000)">
            <path d="M10.9506857,12.1465 C10.7496,12.342 10.7496,12.658 10.9506857,12.8535 C11.0509714,12.951 11.1826286,13 11.3142857,13 C11.4459429,13 11.5776,12.951 11.6778857,12.8535 L17.8488,6.854 C17.8966286,6.808 17.9341714,6.7525 17.9604,6.691 C18.0123429,6.569 18.0123429,6.4315 17.9604,6.309 C17.9341714,6.2475 17.8961143,6.192 17.8488,6.1455 L11.6778857,0.1465 C11.4768,-0.049 11.1517714,-0.049 10.9506857,0.1465 C10.7496,0.342 10.7496,0.658 10.9506857,0.8535 L16.2442286,6 L0.514285714,6 C0.229885714,6 0,6.2235 0,6.5 C0,6.7765 0.229885714,7 0.514285714,7 L16.2442286,7 L10.9506857,12.1465 Z" id="Path"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>

);
export default SliderNextIcon;
