import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as dogsActionCreators from '../../data/dogs/actions';

import WizardLayout from '../../components/wizard/WizardLayout';
import Button from '../../components/Button';
import UploadFile from '../../components/wizard/UploadFile';
import WizardInput from '../../components/wizard/WizardInput';
import Dog from '../../components/wizard/Dog';
import * as actionTypes from '../../data/exports';
import * as selectors from '../../data/dogs/selectors';
import Review from '../../components/wizard/Review';

class AddDog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dog: {
        image: '',
        name: '',
        gender: '',
        age: 5,
        yearAge: null,
        months: null,
        dob: '',
        neutered: null,
        spayed: null,
        pregnant: null,
        microchip_id: '',
        weight: 0,
        weightUnits: 'kg',
        heightUnits: 'cm',
        height: 0,
        user_id: 0,
        breed_id_mix: 0,
        breed: null,
        crossBreed: [null, null, null],
        allergens: {},
        allAllergens: {},
        goal_id: 0,
        shape: 3,
        shapeName: '',
        activity: 5,
        activityName: '',
        health_issues: [],
        dietary_preferences: '',
        allergy: null,
        activity_goal: 1,
        current_dry_food: '',
        current_wet_food: '',
        prescription_diet: false,
        working_dog: false,
        titbits: null,
        flavours: [],
        step: '/gender',
        progress: 3,
        breedTab: 'purebred',
        error: '',
      },
      uploadedImage: '',
      isComplete: false,
      showReview: false,
    };

    this.handleInputChange = (event) => {
      const { value } = event.target;
      if (value.length < 2) {
        this.setDog(event);
      } else {
        const index = this.props.getIndex;
        this.props.updateCurrentDog('name', index, value);
      }
      this.checkCompleted();
    };

    this.handleNextStep = async () => {
      await this.props.toggleDog(0);
      await this.props.history.push('/gender');
    };

    this.handleUploadImage = (image) => {
      this.setState({ uploadedImage: image });
    };

    this.onImgLoad = ({ target: img }) => {
      const imgDataUrl = this.getCroppedImg(img);
      this.setState({ uploadedImage: imgDataUrl });
    };

    this.getCroppedImg = (image) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const { naturalWidth, naturalHeight } = image;
      const actualSize = naturalWidth >= naturalHeight ? naturalHeight : naturalWidth;

      canvas.height = actualSize;
      canvas.width = actualSize;

      ctx.drawImage(
        image,
        0,
        0,
      );

      return canvas.toDataURL();
    };

    this.setDog = async (e) => {
      if (e.target.value.length !== 0) {
        const copyDog = { ...this.state.dog };
        copyDog.name = e.target.value;
        copyDog.image = this.state.uploadedImage;
        e.target.value = '';
        this.setState({ uploadedImage: '' });

        await this.props.setDog(copyDog);
        await this.checkCompleted();
      }
    };

    this.checkCompleted = () => {
      let completedDog;
      if (this.props.storeDogs.length !== 0) {
        completedDog = this.props.storeDogs.some(dog => dog.progress === 100);
        this.setState({ isComplete: completedDog });
      } else {
        this.setState({ isComplete: false });
        this.showReview();
      }
    };

    this.showReview = () => {
      this.setState({
        showReview: this.props.storeDogs.some(item => item.progress > 3),
      });
    };
  }

  componentDidMount() {
    this.checkCompleted();
    this.showReview();
  }

  render() {
    this.props.toggleDogNav(false);
    const dogList = this.props.storeDogs.map((dog, index) =>
      (<Dog
        dog={dog}
        isAvatar={dog.image !== ''}
        onChangeText={e => this.handleInputChange(e)}
        onKeyPress={e => this.handleInputChange(e)}
        onRemove={() => this.checkCompleted()}
        index={index}
        key={index}
        step={dog.step}
      />));
    return (
      <WizardLayout
        title=""
      >
        <h5 className="pl-xl-5 pl-lg-5 pl-md-5 pl-sm-0 px-2 text-center ml-xl-4 ml-lg-4 ml-md-3 ml-sm-0 ml-0 font-weight-bold text-uppercase animated fadeIn">
          let&apos;s start with your dog&apos;s name
        </h5>
        <div id="dogList" className="px-0 col-xl-9 col-lg-9 col-12 row justify-content-center">
          {dogList}
        </div>
        <div id="add-dog" className="col-xl-9 col-lg-9  col-12 row  justify-content-center py-4">
          {/* remove doglength from 3 below to add multidog registration back in */}
          {dogList.length === 0 &&
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 add-dog-image text-center animated fadeIn">
            <UploadFile
              onFileLoaded={imageFile => this.handleUploadImage(imageFile)}
            />
            <div className="upload mx-auto" style={{ backgroundColor: this.state.uploadedImage === '' ? '#b92454' : 'transparent' }}>
              {this.state.uploadedImage === '' ?
                <span className="fa fa-upload" />
                :
                <img className="avatar" src={this.state.uploadedImage} alt="dog" onLoad={this.onImgLoad} />
              }
            </div>
            <div className="upload-text px-1 pt-2 ">
              {this.state.uploadedImage === '' ? 'Don\'t forget to upload the picture :)' : ''}
            </div>
          </div>
          }
          {dogList.length === 0 &&
          <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 pr-xl-0 pr-lg-0 pr-md-0 pr-sm-3 pr-3">
            <WizardInput
              autofocus
              animation="fadeIn"
              max="10"
              type="text"
              placeholder="+ Add a dog"
              tip="up to 10 characters"
              addDogInput
              keyTip=""
              onChangeText={e => this.handleInputChange(e)}
              onKeyPress={e => this.setDog(e)}
              handleBlur={false}
            />
          </div>
          }
          {dogList.length === 0 &&
          <div className="col-2" />
          }
          <div style={{ maxWidth: '390px' }} className="col-xl-12 col-lg-11 col-md-11 col-sm-12 col-12 row justify-content-center pl-xl-5 pl-lg-5 pl-md-5 pl-sm-0 pl-0 pr-0">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-center">
              <Button
                disabled={this.props.storeDogs.length === 0}
                onClick={this.handleNextStep}
                classes="add-dog-btn button-primary fill orange animated fadeIn delay-_1s"
                label="NEXT"
              />
            </div>
            {this.state.showReview &&
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-center">
              <button
                className="add-dog-btn button-primary fill red animated fadeIn delay-_1s"
                data-toggle="modal"
                data-target="#review"
              >
                REVIEW
              </button>
              <Review isError={!this.state.isComplete} />
            </div>
            }
          </div>
        </div>
      </WizardLayout>
    );
  }
}

const mapStateToProps = state => ({
  dogs: state.data.dogs,
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
  setDog: dog => dispatch(dogsActionCreators.set(dog)),
  toggleDog: index => dispatch(dogsActionCreators.toggleDog(index)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddDog));
