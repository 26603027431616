import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Button from '../components/Button';
import StayInTouchVariant from '../components/StayInTouchVariant';
import logo from '../assets/images/new_logo.png';
import * as session from '../services/session';


class StayInTouchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      send_products: true,
      send_news: true,
    };
    session.updateUser({
      send_products: true,
      send_news: true,
    });

    this.handleCheckbox = (attrName) => {
      // on click reverses current value
      const newVal = !this.state[attrName];
      this.setState({
        [attrName]: newVal,
      });
      // update user on server
      session.updateUser({
        [attrName]: newVal,
      });
    };
  }
  render() {
    const logoSize = {
      maxWidth: '110px',
      maxHeight: '55px',
    };
    return (
      <div className="col-xl-5 col-lg-5 col-md-9 row mx-0 justify-content-center animated fadeIn px-0">
        <div className="col-xl-10 col-lg-12 col-md-10 col-sm-9 col-12 text-center pt-5 px-xl-0 px-lg-3 px-md-3 px-4">
          <div className="row justify-content-center pb-4">
            <img style={logoSize} src={logo} alt="" className="logo animated fadeInDown" />
          </div>
          <div className="row justify-content-center text-center">
            <h5 className="font-weight-bold text-uppercase animated fadeInDown">Let&apos;s stay in touch!</h5>
            <p className="subtitle px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 animated fadeInUp">
                We love talking about our pets and we are working on some exciting things we&apos;d love to share. If it&apos;s okay with you, it would be great to stay in touch.
            </p>
            <StayInTouchVariant
              animation="fadeInUp delay-_2s"
              active={this.state.send_products}
              title="New product launches, free samples & discounts"
              description="Keep updated with our next gen product releases, cool new features on app and website, and lots of free goodies, discounts and sales"
              onClick={() => this.handleCheckbox('send_products')}
            />
            <StayInTouchVariant
              animation="fadeInUp delay-_5s"
              active={this.state.send_news}
              title="Latest dog news, trends and blogs from around the world"
              description="Get the latest on dog lifestyle, wellbeing, health guidance and lifehacks. Stories, news and ideas to everything dog."
              onClick={() => this.handleCheckbox('send_news')}
            />
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-9 col-md-10 col-sm-12 col-12 row justify-content-around">
              <div className="col-xl-6 col-lg-6 col-md-6 col-6 px-1 text-center">
                <Button
                  onClick={() => {
                    session.updateUser({ send_news: false, send_products: false });
                    this.props.history.push('/add-dog');
                  }}
                  classes="button-primary fill orange transparent animated fadeInLeft delay-_7s stay-in-touch-btn"
                  label="NO"
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-6 px-1 text-center">
                <Button
                  onClick={() => this.props.history.push('/add-dog')}
                  classes="button-primary fill orange animated fadeInRight delay-_7s stay-in-touch-btn"
                  label="YES, COOL!"
                />
              </div>
              <p className="pt-4 pb-xl-1 pb-lg-1 pb-md-2 pb-sm-3 pb-4 simple-link animated fadeInUp delay-_7s">Feel free to check out our <Link to="//kasperandkitty.com/index.php/privacy-policy/" className="forgot-password" target="_blank">Privacy Policy</Link> at any time.</p>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(StayInTouchForm);
