import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropdown from 'react-dropdown';

import Button from '../../components/Button';

import HowWeightTip from '../../components/wizard/HowWeightTip';
import Tip from '../../components/wizard/Tip';
import WeightTip from '../../components/wizard/WeightTip';
import WizardLayout from '../../components/wizard/WizardLayout';
import WizardInput from '../../components/wizard/WizardInput';
import WrongWeightTip from '../../components/wizard/WrongWeightTip';

import TooOld from '../../assets/images/wizard/tips/age_too_old.png';
import FintessPlan from '../../assets/images/wizard/tips/fintess-plan.svg';
import HowWeight from '../../assets/images/wizard/tips/how-weight.svg';
import PopUpIcon from '../../assets/images/wizard/health-option-icon.png';

import * as actionTypes from '../../data/exports';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as selectors from '../../data/dogs/selectors';

class Weight extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isError: false
    };

    this.handleStep = (step, e) => {
      e.preventDefault();
      if (this.props.currentDog.weight === 0) {
        this.setState({ isError: true })
      } else if (this.props.currentDog.weight > 100) {
        $(`#wrong-weight`).modal('show');
      } else {
        this.props.history.push(step)
      }

      // } else if (this.props.currentDog.breed !== null) {
      //   console.log('here --- 1')
      //   if (this.checkBreedWeight(this.props.currentDog.weight)) {
      //     this.props.history.push(step);
      //   }
      // } else {
      //   console.log('here')
      //   this.props.history.push(step);
      // }

    };

    this.handleBackStep = (step, e) => {
      e.preventDefault();
      this.props.history.push(step);
    };

    this.handleWeight = (e) => {

      if (this.props.currentDog.weight !== 0) {
        this.setState({ isError: false })
      }

      const weight = Number.isNaN(parseInt(e.target.value * 10) / 10) ? 0 : parseInt(e.target.value * 10) / 10;
      const dogIndex = this.props.getIndex;
      this.props.updateCurrentDog('weight', dogIndex, weight);

    };

    this.checkBreedWeight = (weight) => {

      weight = weight === '' ? 0 : weight;

      if (this.props.currentDog.breed !== null && !Number.isNaN(weight)) {
        let breedWeight = 0;
        if (this.props.currentDog.gender === "female") {
          breedWeight = this.props.currentDog.breed.weight_female;
        }
        else {
          breedWeight = this.props.currentDog.breed.weight_male;
        }

        let possibleMistake = (10 / 100) * breedWeight.toFixed(0);
        if (possibleMistake < 5) {
          possibleMistake = 5;
        }

        const difference = (a, b) => Math.abs(a - b);

        if (breedWeight === 0) {
          return true
        }
        const weightDiff = difference(weight, breedWeight.toFixed(0));

        if (weight === 0) {
          this.setState({ isError: true });
          return false
        }

        if (weightDiff > possibleMistake) {
          $(`#wrong-weight`).modal('show');
          return false
        }
        return true
      }
    };

    this.setWeightUnits = (units) => {
      const dogIndex = this.props.getIndex;
      this.props.updateCurrentDog('weightUnits', dogIndex, units.value);
    };

    this.handleModal = (modal) => {
      $(`#${modal}`).modal('show');
    };
  }

  componentDidMount() {
    const dogIndex = this.props.getIndex;
    const { pathname } = this.props.location;

    if (this.props.currentDog.weight === 0) {
      this.props.updateCurrentDog('progress', dogIndex, 50);
    }

    this.props.updateCurrentDog('step', dogIndex, pathname);
    this.props.toggleDogNav(true);
  }

  populateEstimate() {
    let dog = this.props.currentDog;
    let weight = 0;
    let gender = dog.gender === "female" ? "weight_female" : "weight_male"
    let nonNullBreeds = dog.crossBreed.filter(b => b !== null);

    if (dog.breedTab === "purebred") {
      weight = dog.breed[gender]
    }
    // crossbreed
    else if (nonNullBreeds.length === 2) {
      let [primaryWeight, secondWeight] = [nonNullBreeds[0][gender], nonNullBreeds[1][gender]];
      weight = primaryWeight * 0.66 + secondWeight * 0.33;
    }
    else if (nonNullBreeds.length === 3) {
      let [primaryWeight, secondWeight, thirdWeight] =
        [nonNullBreeds[0][gender], nonNullBreeds[1][gender], nonNullBreeds[2][gender]];

      weight = primaryWeight * 0.5 + (secondWeight + thirdWeight) / 4;
    }

    this.props.updateCurrentDog('weight', this.props.getIndex, parseFloat(weight.toFixed(2)));
    if (weight === 0) {
      this.handleModal('unknownWeight');
    }
  }

  render() {
    const options = ['kg', 'lbs', 'st'];
    return (
      <WizardLayout>
        <div className="col-12 row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10 col-12 px-xl-3 px-lg-3 px-md-2 px-sm-1 px-0 row justify-content-center weight-from">
            <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
              What is {this.props.currentDog.name}'s weight?
                <span className="pop-up-link" onClick={() => this.handleModal('weight')}>
                <a><img className="ml-2 mb-2" src={PopUpIcon} /></a>
              </span>
            </h5>
            <div className="col-12">
              <WizardInput
                autofocus={true}
                error={this.state.isError}
                errorMessage={"Please fill this field"}
                value={`${this.props.currentDog.weight !== 0 ? this.props.currentDog.weight : ''}`}
                placeholder="eg. 5"
                type="number"
                onChangeText={e => this.handleWeight(e)}
              />
              <Dropdown
                defaultOption={this.props.currentDog.weightUnits}
                value={this.props.currentDog.weightUnits}
                options={options}
                onChange={this.setWeightUnits}
              />
            </div>
            <div className="col-12 px-0 row justify-content-between">
              <a className={"col-6 text-left underline pop-up-link"} data-toggle="modal" href={"#how-estimate"}>How to weigh your dog?</a>
              <a className={"col-6 text-right underline pop-up-link"} data-toggle="modal" href={"#"} onClick={e => this.populateEstimate()}>What is your estimate?</a>
            </div>
            <div className="col-12 row justify-content-between">
              <div className="col-12 pl-0 pt-2">
                <input
                  checked={this.props.currentDog.fitness_plan}
                  type="checkbox"
                  onClick={(e) => {
                    let newValue = !this.props.currentDog.fitness_plan;
                    const dogIndex = this.props.getIndex;
                    this.props.updateCurrentDog('fitness_plan', dogIndex, newValue);
                  }}
                />
                <span> Add to Fitness Plan</span>
                <span className="pop-up-link" onClick={() => this.handleModal('fintess-plan')}>
                  <a><img className="ml-2 mb-1" src={PopUpIcon} /></a>
                </span>
              </div>
            </div>
            <div className="col-6 pl-xl-5 pl-lg-3 pl-md-2 pr-1 pt-4 text-center">
              <Button
                onClick={e => this.handleBackStep('/breed', e)}
                classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                label="BACK"
              />
            </div>
            <div className="col-6 pr-xl-5 pr-lg-3 pr-md-2 pl-1 pt-4 text-center">
              <Button
                onClick={e => this.handleStep('/height', e)}
                classes="button-primary fill orange animated fadeInRight delay-_2s"
                label="NEXT"
              />
            </div>
          </div>
        </div>
        <WeightTip
          title={"Sorry!"}
          description={`We cannot provide an estimate as we currently don’t recognise your breed. Please enter an estimate of your dog’s weight if you don’t know exactly. You can adjust any time later.`}
          target="unknownWeight"
          isImage={true}
          image={TooOld}
          isButton={false}
          btnLabel=""
        />
        <WeightTip
          title={""}
          description={`Your ${this.props.currentDog.name}'s weight will help us to optimise their unique meal plan and calorie intake. Please update regularly for accurate portion sizes.`}
          target="weight"
          isImage={true}
          image={TooOld}
          isButton={false}
          btnLabel=""
        />
        <HowWeightTip
          title={"How to weigh your dog?"}
          description={""}
          target="how-estimate"
          isImage={true}
          image={HowWeight}
          isButton={false}
          btnLabel=""
        />
        <Tip
          title={""}
          description={`This is a specially created newsletter for our overweight dogs to inspire them to get fit`}
          target="fintess-plan"
          isImage={true}
          image={FintessPlan}
          isButton={false}
          btnLabel=""
        />
        <WrongWeightTip
          target="wrong-weight"
          image={TooOld}
          btnLabel="CHANGE"
        />
      </WizardLayout>
    );
  }
}

const mapStateToProps = state => ({
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});

const mapDispatchToProps = dispatch => ({
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Weight));
