import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Collapse } from 'reactstrap';

import * as dogsActions from '../../data/dogs/actions';
import * as productActions from '../../data/products/actions';

import UpArrowIcon from '../../assets/images/up-arrow-black.png';
import DownArrowIcon from '../../assets/images/down-arrow-black.png';
import UpCaret from '../../assets/images/icon-caret-up.png';
import DownCaret from '../../assets/images/icon-caret-down.png';
import CheckedIcon from '../../assets/images/checked-orange-icon.png';
import UniqueRecipe from '../../assets/images/unique-recipe.png';
import Bespoke from '../../assets/images/bespoke/bespoke-1.png';
import FoodBowl from '../../assets/images/bespoke/food-bowl.png';
import Yucca from '../../assets/images/bespoke/yucca.png';
import Carrot from '../../assets/images/bespoke/carrot.png';
import Leaves from '../../assets/images/bespoke/leaves.png';
import Maize from '../../assets/images/bespoke/maize.png';
import Leaf from '../../assets/images/bespoke/leaf.png';

const ProteinList = ["Chicken", "Turkey", "Lamb", "Salmon", "Chicken?"];

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dog: {},
      kibble: [],
      isIngredientsOpen: false,
      isVitaminsOpen: false,
      isPortionOpen: false,
      isTrialOpen: false,

      weight_kg: 0,
      calories: 0,
      shape: '',
      food: {},

      shares: [0, 0, 0, 0],
      knames: ["", "", "", ""],
      ingredients: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
      nutrients: ["", ""],
      vitamins: ["", ""],
      keyIngredients: ["", "", ""],

      ingredientOffset: 0,
      vitaminOffset: 0,
      portionOffset: 0,
      trialOffset: 0,
    }

    this.collapseClick = this.collapseClick.bind(this);
    this.ingredientsRef = React.createRef();
    this.vitaminRef = React.createRef();
    this.portionRef = React.createRef();
    this.trialRef = React.createRef();
  }

  async componentDidMount() {
    const { currentDog, dogs } = this.props;

    this.setState({ dog: dogs[currentDog] });
    dogsActions.getCalories(currentDog)
      .then((resp) => {
        this.setState({
          calories: resp.totalCalories,
          shape: resp.shape,
          food: resp.food,
        });
      });

    dogsActions.getKibbleShares(currentDog)
      .then((resp) => {
        let ings = resp.ingredients.map(s => s.trim())
        let nuts = resp.nutrients.map(s => s.trim())
        let vits = resp.vitamins.map(s => s.trim())

        this.setState({
          shares: resp.shares,
          knames: resp.knames,
          ingredients: ings,
          nutrients: nuts,
          vitamins: vits,
          keyIngredients: resp.key_ingredients
        });
      });

    productActions.getBespoke(currentDog)
      .then((resp) => {
        this.setState({
          weight_kg: resp.weight_kg
        });
      });

    setTimeout(() => {
      this.setState({
        ingredientOffset: this.ingredientsRef.current.offsetTop,
        vitaminOffset: this.vitaminRef.current.offsetTop,
        portionOffset: this.portionRef.current.offsetTop,
        trialOffset: this.trialRef.current.offsetTop,
      })
    })
  }

  collapseClick(e) {
    e.preventDefault();

    if (e.target.id === 'ingredients' || e.target.alt === 'ingredients') {
      if (window.pageYOffset > this.state.ingredientOffset)
        window.scrollTo(window.pageYOffset, this.state.ingredientOffset - 16);
      this.setState({
        isIngredientsOpen: !this.state.isIngredientsOpen,
        isVitaminsOpen: false,
        isPortionOpen: false,
        isTrialOpen: false
      })
    } else if (e.target.id === 'vitamins' || e.target.alt === 'vitamins') {

      if (window.pageYOffset > this.state.vitaminOffset)
        window.scrollTo(window.pageYOffset, this.state.vitaminOffset - 16);

      this.setState({
        isIngredientsOpen: false,
        isVitaminsOpen: !this.state.isVitaminsOpen,
        isPortionOpen: false,
        isTrialOpen: false
      })
    } else if (e.target.id === 'portion' || e.target.alt === 'portion') {
      if (window.pageYOffset > this.state.portionOffset)
        window.scrollTo(window.pageYOffset, this.state.portionOffset - 16);

      this.setState({
        isIngredientsOpen: false,
        isVitaminsOpen: false,
        isPortionOpen: !this.state.isPortionOpen,
        isTrialOpen: false
      })
    } else if (e.target.id === 'trial' || e.target.alt === 'trial') {
      if (window.pageYOffset > this.state.trialOffset)
        window.scrollTo(window.pageYOffset, this.state.trialOffset - 16);

      this.setState({
        isIngredientsOpen: false,
        isVitaminsOpen: false,
        isPortionOpen: false,
        isTrialOpen: !this.state.isTrialOpen
      })
    }
  }

  render() {
    const { dog, keyIngredients } = this.state;
    const foodCalPercentage = Math.round(this.state.food.calories / this.state.calories * 100);
    const portion = parseInt(this.state.food.portion, 10);
    let isPancreatitis = false;
    let dietOption = '';
    let keyIng = '';

    const getKibPrice = (price) => {
      return price * (100 - this.props.promocode.kibble_discount) / 100;
    }

    const getSubtotal = () => {
      return this.props.items.reduce((total, item) => {
        if (item.isBespoke) {
          return total + getKibPrice(item.price);
        }
        return total + item.price * item.quantity;
      }, 0);
    }


    dog.medical_conditions && dog.medical_conditions.length > 0 && dog.medical_conditions.map((condition) => {
      if (condition.name === "Pancreatitis") {
        isPancreatitis = true;
      }
    });

    dog.diets && dog.diets.length > 0 && dog.diets.map((diet) => {
      if (diet.name === "Grain free") {
        dietOption = "Grain-Free";
      } else if (diet.name === "Cereal Free") {
        dietOption = "Cereal-Free";
      } else {
        dietOption = diet.name;
      }
    });

    keyIngredients && keyIngredients.length > 0 && keyIngredients.forEach((ing) => {
      if (ProteinList.indexOf(ing.name) >= 0) {
        keyIng = ing.name;
        return;
      }
    });

    const monthlyPortionKg = ((28 / this.props.promocode.NumDaysTilNextOrder) * this.state.weight_kg).toFixed(2);

    return (
      <Grid className="divTable border-element">
        <Grid container className="border-bottom">
          <Grid item md={11} xs={12} className="header-main-new">{this.props.dog.name}'s first subscription box</Grid>
        </Grid>

        {this.props.items.map(item => (
          <Grid container className="border-bottom" key={item.ID}>
            <Grid container>
              {item.isBespoke ? (
                <Grid container className="main-area">
                  <Grid container className="bespoke">
                    <Grid item xs={5} className="bespoke-left">
                      <img alt="Bespoke" src={Bespoke} className="bespoke-1" />
                      <img alt="FoodBowl" src={FoodBowl} className="foodBowl" />
                      <img alt="Yucca" src={Yucca} className="yucca" />
                      <img alt="Carrot" src={Carrot} className="carrot" />
                      <img alt="Leaves" src={Leaves} className="leaves" />
                      <img alt="Maize" src={Maize} className="maize" />
                      <img alt="Leaf" src={Leaf} className="leaf" />
                    </Grid>

                    <Grid item xs={7}>
                      <div className="bespoke-right">
                        <img alt="recipe" src={UniqueRecipe} className="recipe" />

                        <div className="options">
                          {(isPancreatitis || dietOption) &&
                            <div className="bespoke-option">
                              <img alt="checked" src={CheckedIcon} />
                              <p>{isPancreatitis ? 'Pancreatitis' : dietOption}</p>
                            </div>
                          }

                          {keyIng &&
                            <div className="bespoke-option">
                              <img alt="checked" src={CheckedIcon} />
                              <p>{keyIng}</p>
                            </div>
                          }

                          {/* <div className="bespoke-option">
                            <img alt="checked" src={CheckedIcon} />
                            <p>FREE Portion Scoop</p>
                          </div> */}

                          <div className="bespoke-option">
                            <img alt="checked" src={CheckedIcon} />
                            <p>Nutritional Guidance</p>
                          </div>

                          <div className="bespoke-option">
                            <img alt="checked" src={CheckedIcon} />
                            <p>{this.state.weight_kg.toFixed(2)}kg bespoke dry food</p>
                          </div>
                        </div>

                        <div className="price">
                          <div className="bespoke-option">
                            <div className="bespoke-trial">{this.props.promocode.NumDaysTilNextOrder} days = £{getKibPrice(item.price).toFixed(2)}</div>
                          </div>

                          <div className="bespoke-option">
                            <div className="regular-price">Regular Price per week = &nbsp;<div className="regular-strikethrough">£{(item.price / 2).toFixed(2)}</div></div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <div className="vertical-divider"></div>
                  <Grid container className="accordion-area">
                    <Grid item xs={12} className="main-ingredients">
                      Main Ingredients
                      <Grid item xs={12}>
                        <Grid container className="img-area">
                          {this.state.keyIngredients.length > 0 && this.state.keyIngredients.map((ing, index) => {
                            if (ing) {
                              return (
                                <Grid item className="item" key={index}>
                                  <img alt={ing.name} src={"https://" + ing.image.Url} />
                                  <div className="item-text">{ing.name}</div>
                                </Grid>
                              )
                            }
                          })}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="accordion">
                      <Grid container>
                        <Grid item xs={12}>
                          <div className="accordion-header" onClick={this.collapseClick} id="ingredients" ref={this.ingredientsRef}>
                            Ingredients & Composition
                            <img
                              alt="ingredients"
                              src={this.state.isIngredientsOpen ? UpArrowIcon : DownArrowIcon}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <Collapse isOpen={this.state.isIngredientsOpen}>
                            <Grid container className="accordion-collapse">
                              <Grid item xs={12}>
                                <Grid container className="img-area">
                                  {this.state.keyIngredients.length > 0 && this.state.keyIngredients.map((ing, index) => {
                                    if (ing) {
                                      return (
                                        <Grid item className="item" key={index}>
                                          <img alt={ing.name} src={"https://" + ing.image.Url} />
                                          <div className="item-text">{ing.name}</div>
                                        </Grid>
                                      )
                                    }
                                  })}
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <div className="ingredients">
                                  {this.state.ingredients && this.state.ingredients.join('; ')}
                                </div>
                              </Grid>

                              <Grid item xs={12}>
                                <div className="nutrients">
                                  {this.state.nutrients && this.state.nutrients.join('; ')}
                                </div>
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="divider">
                    </Grid>

                    <Grid item xs={12} className="accordion">
                      <Grid container>
                        <Grid item xs={12}>
                          <div className="accordion-header" onClick={this.collapseClick} id="vitamins" ref={this.vitaminRef}>
                            Vitamins & Supplements
                            <img
                              alt="vitamins"
                              src={this.state.isVitaminsOpen ? UpArrowIcon : DownArrowIcon}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <Collapse isOpen={this.state.isVitaminsOpen}>
                            <Grid container className="accordion-collapse">
                              {this.state.vitamins && this.state.vitamins.length > 0 && this.state.vitamins.map((vitamin, index) => {
                                return (
                                  <Grid item xs={12} key={index}>
                                    <div className="vitamins">
                                      {vitamin};
                                	</div>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="divider">
                    </Grid>

                    <Grid item xs={12} className="accordion">
                      <Grid container>
                        <Grid item xs={12}>
                          <div className="accordion-header" onClick={this.collapseClick} id="portion" ref={this.portionRef}>
                            Recommended daily portion
                            <img
                              alt="portion"
                              src={this.state.isPortionOpen ? UpArrowIcon : DownArrowIcon}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <Collapse isOpen={this.state.isPortionOpen}>
                            <Grid container className="accordion-collapse">
                              <Grid item xs={12} className="portion">
                                Based on {dog.name}'s profile and activity levels, we recommend feeding {dog.name} {foodCalPercentage}% of their diet in bespoke dry kibble.
                              </Grid>

                              <Grid item xs={12} className="portion">
                                Total recommeded calories (100%):<br />
                                <span>{this.state.calories} kcal per day</span>
                              </Grid>

                              <Grid item xs={12} className="portion">
                                Daily dry food portion ({foodCalPercentage}%):<br />
                                <span>{Math.round(this.state.food.calories)} kcal / {portion} g per day</span>
                              </Grid>

                              <Grid item xs={12} className="portion">
                                Daily extras allowance ({100 - foodCalPercentage}%): <br />
                                <span>{dog.titbits ? Math.round(this.state.calories * 15 / 100) : Math.round(this.state.calories * 10 / 100)} kcal per day</span>
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="divider"></Grid>

                    <Grid item xs={12} className="accordion">
                      <Grid container>
                        <Grid item xs={12}>
                          <div className="accordion-header" onClick={this.collapseClick} id="trial" ref={this.trialRef}>
                            After trial
                            <img
                              alt="trial"
                              src={this.state.isTrialOpen ? UpArrowIcon : DownArrowIcon}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <Collapse isOpen={this.state.isTrialOpen}>
                            <Grid container className="accordion-collapse">
                              <Grid item xs={12} className="trial">
                                After your {this.props.promocode.NumDaysTilNextOrder} days trial, your monthly food subscription box will auto-renew at £{(item.price * 2).toFixed(2)}/month or £{(item.price / 2).toFixed(2)}/week
                                (subject to updates on your dog's profile and any monthly extras) with free delivery. <span class="bold-red-text">You will receive 28-days of {this.props.dog.name}’s dry food ({monthlyPortionKg} kg) each month.</span>
                              </Grid>
                              <Grid item xs={12} className="trial">
                                <span>NO</span>&nbsp;commitment. Skip or cancel anytime 7 days before the next delivery.
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                  <Grid container className="cart-cell-row">
                    <Grid item md={8} xs={8} className="cart-cell border-right-md">
                      <Grid container alignItems="center">
                        <Grid item md={2} xs={4}>
                          <img
                            alt={item.name}
                            src={window.location.protocol + item.image.Url}
                          />
                        </Grid>

                        <Grid item md={10} xs={8}>
                          <div className="product-name">{item.name}</div>
                          <div className="item-description">{item.description}</div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={2} className="cart-cell-count">
                      <span>{item.quantity}</span>
                      <div className="icon-area">
                        <img
                          src={UpCaret}
                          className="quantity-change"
                          onClick={() => this.props.quantityUp(this.props.dog.ID, item.ID)}
                        />
                        <img
                          src={DownCaret}
                          className="quantity-change"
                          onClick={() => (item.quantity > 1 ? this.props.quantityDown(this.props.dog.ID, item.ID) : this.props.deleteItem(this.props.dog.ID, item.ID))}
                        />
                      </div>
                    </Grid>

                    <Grid item md={2} xs={4} className="cart-cell">
                      <Grid container className="h-100 price">
                        <Grid item className="price-pcs">
                          <Grid item>£{(item.price * item.quantity).toFixed(2)}</Grid>
                          <Grid item className="pcs">£5.00/pcs.</Grid>
                        </Grid>

                        <Grid item className="button-group">
                          <Grid container justify="flex-end">
                            <button
                              className="quantity-change"
                              onClick={() => (item.quantity > 1 ? this.props.quantityDown(this.props.dog.ID, item.ID) : this.props.deleteItem(this.props.dog.ID, item.ID))}
                            >−
                            </button>
                            <span className="px-2">{item.quantity}</span>
                            <button
                              className="quantity-change"
                              onClick={() => this.props.quantityUp(this.props.dog.ID, item.ID)}
                            >+
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
        ))
        }

        <Grid container className="cart-description">
          <Grid item className="cart-cell">subtotal</Grid>

          <Grid item className="cart-cell-price text-right text-md-left">
            £{getSubtotal().toFixed(2)}
          </Grid>
        </Grid>
      </Grid >
    );
  }
}

const mapStateToProps = (state) => ({
  dogs: state.data.dogs.dogs,
  currentDog: state.data.dogs.currentDog,
  promocode: state.data.promocode,
});

export default connect(mapStateToProps)(Cart);