/* eslint-disable max-len */
import React from 'react';

const PaymentPlan = ({bespokeCost, numDays}) => {
  // todo: adjust when kibble delivery cost is configurable
  const monthlyCost = parseFloat(bespokeCost);
  return (
    <div className="payment-plan">
      <p className="bold pb-2">*What happens after your {numDays} day free trial ends?</p>
      <p>To make sure you don’t run out of food, we will continue to send you monthly subscription boxes at
        £{monthlyCost.toFixed(2)} or £{(monthlyCost/4).toFixed(2)} per week (subject to updates on your dog’s profile and any monthly extras) with free delivery. 
        Skip or cancel 7 days before the next delivery. For more information, please read <a href="www.kasperandkitty.com/how-it-works">here</a>.
      </p>
    </div>
  );
} 

export default PaymentPlan;
