import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { Elements } from 'react-stripe-elements';

import * as dogsSelectors from '../../data/dogs/selectors';

import Layout from '../../components/Layout';
import StepWizard from '../../components/StepWizard';
import RegistrationImageBanner from '../../components/RegistrationImageBanner';

// Step routes
import Welcome from './welcome';
import YourDog from './yourDog';
import DogInformation from './dogInformation';
import DogActivity from './dogActivity';
import DogShape from './dogShape';
import DailyMenu from './dailyMenu';
import Checkout from './checkout';
import AboutYou from './aboutYou';
import Payment from './payment';
import Congratulations from './congratulations';

class Onboarding extends React.Component {
  constructor(props) {
    super(props);

    this.steps = [
      {
        component: Welcome,
        path: '',
        title: 'Welcome to club K&K',
        image: 'url(/assets/images/dog-in-field.jpg)',
        heading: 'Welcome',
        subtext: 'To club K&K',
      },
      {
        component: YourDog,
        path: '/your-dog',
        title: 'Your Dog',
        image: 'url(/assets/images/dog-in-field.jpg)',
        heading: 'Welcome',
        subtext: 'To club K&K',
      },
      {
        component: DogInformation,
        path: '/dog-information',
        title: 'Your Dog',
        image: 'url(/assets/images/dog-in-field.jpg)',
        heading: 'Woof, woof!',
        subtext: 'Let us know a little more about your dog',
      },
      {
        component: DogActivity,
        path: '/dog-activity',
        title: 'Your Dog',
        image: 'url(/assets/images/dog-in-field.jpg)',
        heading: 'Woof, woof!',
        subtext: 'Let us know a little more about your dog',
      },
      {
        component: DogShape,
        path: '/dog-shape',
        title: 'Your Dog',
        image: 'url(/assets/images/dog-in-field.jpg)',
        heading: 'Woof, woof!',
        subtext: 'Let us know a little more about your dog',
      },
      {
        component: DailyMenu,
        path: '/daily-menu',
        title: `${this.props.dog !== undefined ? this.props.dog.name : ''}'s Bespoke Daily Menu`,
        image: 'url(/assets/images/dog-in-field.jpg)',
        heading: `${this.props.dog !== undefined ? this.props.dog.name : ''}'s`,
        subtext: 'Bespoke calorie controlled daily menu',
      },
      {
        component: AboutYou,
        path: '/about-you',
        title: 'About You',
        image: 'url(/assets/images/dog-in-field.jpg)',
        heading: 'Your account',
        subtext: 'Tell us a bit more about you',
      },
      {
        component: Checkout,
        path: '/checkout',
        title: 'Checkout',
        image: 'url(/assets/images/dog-in-field.jpg)',
        heading: 'Checkout',
        subtext: 'Complete your order',
        next: null,
      },

      {
        component: Payment,
        path: '/payment',
        title: 'Payment Details',
        image: 'url(/assets/images/dog-in-field.jpg)',
        heading: 'Payment Details',
        subtext: 'You\'re nearly ready to start a new journey together',
        next: null,
      },
      {
        component: Congratulations,
        path: '/congratulations',
        title: 'Congratulations',
        image: 'url(/assets/images/dog-in-field.jpg)',
        heading: 'Account complete',
        subtext: 'Thank you for joining the Kasper & Kitty community',
        noControls: true,
      },
    ];

    this.state = {
      redirect: '',
      currentStep: null,
    };
  }

  render() {
    if (this.state.redirect.length > 0) {
      return (
        <Redirect
          to={{ pathname: this.state.redirect }}
        />
      );
    }

    const {
      currentStep,
    } = this.state;

    return (
      <Layout>
        {currentStep !== null && (
          <RegistrationImageBanner
            image={currentStep.image}
            heading={currentStep.heading}
            subtext={currentStep.subtext}
          />
        )}
        <Elements>
          <StepWizard
            steps={this.steps}
            pathPrefix="/welcome"
            onChangeStep={step => this.setState({ currentStep: step })}
          />
        </Elements>
      </Layout>
    );
  }
}

export default connect(() => ({
  dog: dogsSelectors.getCurrentDog(),
}), dispatch => ({}), null, { withRef: true })(Onboarding);
