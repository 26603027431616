import React from 'react';

import SubNavigation from '../../components/SubNavigation';
import Row from '../../components/Row';
import Navigation from '../../components/Navigation';
import Pdsa from '../../components/Pdsa';
import Footer from '../../components/Footer';
import AccountSidebar from '../../components/AccountSidebar';
import AccountDetails from '../../components/AccountDetails';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';

const information = [
  {
    db: 'first_name',
    descr: 'First name',
    value: '',
  },
  {
    db: 'surname',
    descr: 'Surname',
    value: '',
  },
  {
    db: 'mobile_number',
    descr: 'Mobile number',
    value: '',
  },
  {
    db: 'email',
    descr: 'Email',
    value: '',
  },
];

const title = 'YOUR DETAILS';

const YourDetails = () => (
  <div className="container">
    <Navigation
      white
      textColor="white-text"
    />

    <SubNavigation />

    <div className="account-container">
      <KaushanScriptHeading title="Your account" />

      <Row>
        <div className="col-md-3">
          <AccountSidebar />
        </div>
        <div className="col-md-7">
          <AccountDetails data={information} title={title} page={1} />
        </div>
        <div className="col-md-1" />
      </Row>

    </div>

    <Pdsa />
    <Footer />
  </div>
);

export default YourDetails;
