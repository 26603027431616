import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import Textfield from '../../Textfield';
import countries from '../../../data/countries';

class DeliveryForm extends React.Component {
  render() {
    const {
      isSubmitted,
      firstName,
      lastName,
      company,
      phone,
      streetAddressLine1,
      streetAddressLine2,
      city,
      zipPostalCode,
      county,
      country,
      handleChange,
    } = this.props;

    return (
      <Grid className="border-element" id="delivery-form">
        <Grid container className="border-bottom">
          <h3 className="header-main">Delivery Address</h3>
        </Grid>

        <Grid container className="border-body" spacing={16}>
          <Grid item xs={12}>
            <Textfield
              error={isSubmitted && firstName === ''}
              label="First Name"
              value={firstName}
              onChange={handleChange('firstName')}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              error={isSubmitted && lastName === ''}
              label="Last Name"
              onChange={handleChange('lastName')}
              value={lastName}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              label="Company (optional)"
              onChange={handleChange('company')}
              value={company}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              label="Phone"
              onChange={handleChange('phone')}
              value={phone}
              type="tel"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              error={isSubmitted && streetAddressLine1 === ''}
              label="Street Address Line 1"
              onChange={handleChange('streetAddressLine1')}
              value={streetAddressLine1}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              label="Street Address Line 2"
              onChange={handleChange('streetAddressLine2')}
              value={streetAddressLine2}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              label="City"
              onChange={handleChange('city')}
              value={city}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              error={isSubmitted && zipPostalCode === ''}
              label="Postal Code"
              onChange={handleChange('zipPostalCode')}
              value={zipPostalCode}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Textfield
              label="County"
              onChange={handleChange('county')}
              value={county}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Textfield
              select
              label="Country"
              onChange={handleChange('country')}
              value={country}
              helperText="*Required Fields"
              required
              fullWidth
            >
              {countries.map(item => (
                <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
              ))}
            </Textfield>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default DeliveryForm;
