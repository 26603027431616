import React from 'react';

export default function BackgroundContainer(props) {
  const { marginBottom, equalPadding, orangeBorder } = props;

  return (
    <div
      className={`${(marginBottom ? ' margin-bottom ' : '') + (equalPadding ? ' padding ' : '')}background-container`}
      style={orangeBorder ? { border: '1px solid #fab522' } : {}}
    >
      {props.children}
    </div>
  );
}
