import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import SignInLayout from '../../components/SignInLayout';
import { isLoggedIn } from '../../services/session';

const SignIn = () => {
  if (isLoggedIn()) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="container-fluid p-0 m-0">
      <SignInLayout
        bannerColor="sign-in"
      />
    </div>
  );
};

export default connect(() => ({
  loggedIn: isLoggedIn(),
}))(SignIn);
