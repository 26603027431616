import React from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as dogsSelectors from '../data/dogs/selectors';
import * as productsActions from '../data/products/actions';

import Navigation from '../components/Navigation';
import InputField from '../components/InputField';
import MainImageBanner from '../components/MainImageBanner';
import SubNavigation from '../components/SubNavigation';
import Row from '../components/Row';
import FullColumn from '../components/FullColumn';
// import InstagramCarousel from '../components/InstagramCarousel';
import Footer from '../components/Footer';
import GreyText from '../components/GreyText';
import KaushanSkriptHeading from '../components/KaushanScriptHeading';
import HalfColumn from '../components/HalfColumn';
import * as ordersActionCreators from '../data/orders/actions';
import * as sessionSelectors from '../services/session/selectors';
import { getCheckoutTotal } from '../utils';

const TableRow = ({
  title, product, quantity, price, heading, onDelete, onChangeQuantityUp, onChangeQuantityDown,
}) => (
  <Row className="row">
    <div className="col-6 col-sm-8 no-padding">
      <p className={`${heading ? ' heading ' : ''}table-row product`}>{(title !== undefined) ? title : product.product}</p>
    </div>
    <div className="col-3 col-sm-2 no-padding">
      <p className={`${heading ? ' heading ' : ''}table-row quantity`}>
        {(onChangeQuantityUp !== undefined && onChangeQuantityDown !== undefined) ?
          <div className="control">
            <div role="button" tabIndex="0" onKeyPress={() => {}} className="remove" onClick={onChangeQuantityDown}>
              <i className="fa fa-minus-square" aria-hidden="true" />
            </div>
            {quantity}
            <div role="button" tabIndex="0" onKeyPress={() => {}} className="add" onClick={onChangeQuantityUp}>
              <i className="fa fa-plus-square" aria-hidden="true" />
            </div>
          </div> : quantity}
      </p>
    </div>
    <div className="col-3 col-sm-2 no-padding">
      <p className={`${heading ? ' heading ' : ''}table-row price`}>{price}</p>
    </div>
    {onDelete !== undefined &&
    <div className="delete-icon-container">
      <a
        href
        className="delete-icon"
        onClick={onDelete}
      >
        <img alt="delete" src="/assets/images/delete.png" />
      </a>
    </div>
    }
  </Row>
);

const SummaryRow = ({ label, price }) => (
  <Row>
    <div className="col no-padding">
      <p className="summary-text">{label}</p>
    </div>
    <div className="col-3 col-sm-2 no-padding">
      <p className="summary-price">{price}</p>
    </div>
  </Row>
);

class Basket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shop: [],
      delivery: true,
      promoCode: '',
      promocodeApplied: false,
      promocodeAppliedValue: '',
      promocodeInvalid: false,
      discountFitkolarOnly: false,
      deliveryInstructions: '',
      discount: 0,
      discountMode: '',
      order_id: undefined,
      promo: false,
    };

    this.handleOrder = this.handleOrder.bind(this);
    this.checkPromo = this.checkPromo.bind(this);
  }

  componentDidMount() {
    const storage = localStorage.getItem('productCart');

    if (storage !== null) {
      const parsedStorage = JSON.parse(storage);
      this.setState({ shop: parsedStorage });
    }

    console.log(this.props.user);
  }

  handleOrder() {
    for (let i = 0; i < this.state.shop.length; i += 1) {
      this.state.shop[i].quantity = parseInt(this.state.shop[i].quantity, 10);
    }

    const payload = {
      dog_id: this.props.dog.ID,
      delivery_address: this.props.user.delivery_address,
      billing_address: this.props.user.billing_address,
      order_items: this.state.shop,
      fitkolars: 0,
      promotion_id: this.state.promoCode,
      delivery_method: this.state.delivery ? 'Free' : 'Standard',
      delivery_instructions: this.state.deliveryInstructions,
      delivery_cost: 3.95, // as of now: delivery when making order in shop always 3.95
    };

    this.props.actions.orders.createOrder(payload)
      .then((order_id) => {
        localStorage.setItem('productCart', JSON.stringify([]));
        this.setState({ order_id });
      })
      .catch(() => {
        window.alert('An error occured while placing your order.');
      });
  }

  checkPromo() {
    this.setState({ promocodeInvalid: false, discount: 0, discountMode: '' });

    if (this.state.promoCode && this.state.promoCode !== '') {
      this.props.actions.orders.getPromocode(this.state.promoCode)
        .then((promo) => {
          if (promo.ID > 0) {
            this.setState({
              promocodeApplied: true,
              promocodeAppliedValue: `${(promo.mode === 'percent') ? `${promo.discount}%` : `${promo.discount}`} off!`,
              promo,
            });
          } else {
            this.setState({ promocodeApplied: false, promocodeInvalid: true });
          }
        });
    } else {
      this.setState({ promocodeInvalid: true });
    }
  }

  render() {
    const {
      total,
      totalDelivery,
      deliveryCost,
      firstDelivery,
    } = getCheckoutTotal(this.state.shop, 0, 2, this.state.promo, 3.95);
    if (this.state.order_id !== undefined) {
      return <Redirect to={`/payment/${this.state.order_id}`} />;
    }

    let [totalPrice, totalQuantity] = [0, 0];
    if (this.props.user.cruft) {
      this.state.shop.forEach((item) => {
        totalPrice += item.price * item.quantity;
        totalQuantity += item.quantity;
      });
    }

    return (
      <div className="container">
        <Navigation
          absolute
          textColor="white-text"
        />
        <MainImageBanner
          image="url(/assets/images/dog-in-field.jpg)"
          heading="Shop"
          subtext="Everything your pet needs"
        />
        <SubNavigation />
        <div className="shop-basket">
          <div className="inner-container">
            <Row>
              <div className="col-12">
                <KaushanSkriptHeading title="Cart - Checkout" />
                {this.state.shop.length > 0 ? (
                  <FullColumn>
                    <TableRow title="Product name" quantity="Qty" price="Price" heading />
                    {this.props.user.cruft ?
                      <TableRow
                        title={`${this.props.dog.name}'s Bespoke Dry Food`}
                        quantity={1}
                        price={`£${totalPrice.toFixed(2)}`}
                      />
                      :
                      this.state.shop.map(item => (
                        <TableRow
                          product={item}
                          quantity={item.quantity}
                          price={`£${(item.price * item.quantity).toFixed(2)}`}
                          onDelete={() => {
                          const selection = this.state.shop.map(product => product.key).indexOf(item.key);

                          const list = this.state.shop;

                          list.splice(selection, 1);

                          localStorage.setItem('productCart', JSON.stringify(list));

                          this.setState({ shop: list });
                        }}
                          onChangeQuantityUp={() => {
                          for (let i = 0; i < this.state.shop.length; i += 1) {
                            if (this.state.shop[i].key === item.key) {
                              const list = this.state.shop;

                              list[i].quantity += 1;

                              if (list[i].quantity > 99) {
                                list[i].quantity = 99;
                              }

                              localStorage.setItem('productCart', JSON.stringify(list));
                              this.setState({ shop: list });
                            }
                          }
                        }}
                          onChangeQuantityDown={() => {
                          for (let i = 0; i < this.state.shop.length; i += 1) {
                            if (this.state.shop[i].key === item.key) {
                              const list = this.state.shop;

                              list[i].quantity -= 1;

                              if (list[i].quantity < 0) {
                                list[i].quantity = 0;
                              }

                              localStorage.setItem('productCart', JSON.stringify(list));
                              this.setState({ shop: list });
                            }
                          }
                        }}
                        />
                    ))}
                    <SummaryRow label="Sub total" price={`£${total.toFixed(2)}`} />
                    <SummaryRow label="Delivery" price={`£${deliveryCost.toFixed(2)}`} />
                    <SummaryRow label="Total to pay" price={`£${totalDelivery.toFixed(2)}`} />
                    {!this.props.user.cruft &&
                    <div className="row">
                      <HalfColumn noPadding>
                        <GreyText
                          text="Enter your promotional code"
                          fontSize={20}
                          bold
                          marginBottom
                        />
                        <Row>
                          <div className="col no-padding">
                            <InputField
                              type="text"
                              placeholder="Promo code"
                              value={this.state.promoCode}
                              onChangeText={event => this.setState({ promoCode: event.target.value })}
                            />
                          </div>
                          <a
                            href
                            className="apply-button"
                            onClick={this.checkPromo}
                          >
                            Apply
                          </a>
                        </Row>
                        {this.state.promocodeApplied && <GreyText bold text={`Code applied, ${this.state.promocodeAppliedValue}`} fontSize={16} />}
                        {this.state.promocodeInvalid && <GreyText bold text="This promocode is not valid" fontSize={16} />}
                      </HalfColumn>
                    </div>
                    }
                    <Row>
                      <div className="col-12 col-md-6 align-self-center no-padding">
                        <GreyText
                          text="Delivery date"
                          fontSize={20}
                          bold
                          marginBottom
                        />
                        <GreyText
                          text={firstDelivery}
                          fontSize={20}
                          marginBottom
                        />
                      </div>
                      <div className="col-12 col-md-6 align-self-center no-padding">
                        <img
                          alt=""
                          className="checkout-van"
                          src="/assets/images/checkout-van.png"
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="col-12 col-md-6 no-padding">
                        <GreyText
                          text="Delivery instructions"
                          fontSize={20}
                          bold
                          marginBottom
                        />
                        <textarea
                          className="text-area"
                          value={this.state.deliveryInstructions}
                          onChange={event => this.setState({ deliveryInstructions: event.target.value })}
                        />
                      </div>
                      <div className="col-12 col-md-6 no-padding">
                        <div className="place-order-container">
                          <a
                            href
                            className="place-order-button"
                            onClick={(e) => {
                              e.preventDefault();

                              if (this.props.user !== null) {
                                this.handleOrder();
                              } else {
                                localStorage.setItem('guestCheckout', this.state);
                                this.props.history.push('/guest-checkout');
                              }
                            }}
                          >
                            {this.props.user !== null ? 'Place Order' : 'Checkout as guest'}
                          </a>
                        </div>
                      </div>
                    </Row>
                  </FullColumn>
                ) : (
                  <GreyText
                    text="Your cart is currently empty. Please come back when you have added things to your cart."
                    fontSize={18}
                    centered
                  />
                )}
              </div>
            </Row>
            <Row>
              <FullColumn>
                <p className="grey-text center">By placing your order you agree to our <Link href to="/terms-and-conditions" className="underline">Terms and Conditions</Link>, <Link href to="/privacy-policy" className="underline">Privacy Policy</Link> and <Link href to="/delivery-and-return" className="underline">Returns Policies</Link></p>
              </FullColumn>
            </Row>
          </div>
        </div>
        {/* <InstagramCarousel /> */}
        <Footer />
      </div>
    );
  }
}

export default withRouter(connect(() => ({
  dog: dogsSelectors.getCurrentDog(),
  user: sessionSelectors.getUser(),
}), dispatch => ({
  actions: {
    products: bindActionCreators(productsActions, dispatch),
    orders: bindActionCreators(ordersActionCreators, dispatch),
  },
}), null, { withRef: true })(Basket));
