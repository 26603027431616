import React from 'react';
import { Link } from 'react-router-dom';

export default function Day(props) {
  const {
    date,
    currentDay,
    goalAchieved,
    onSelect,
    empty,
    popup,
  } = props;

  return (
    <Link
      href
      to="#"
      className={`${(popup ? ' popup ' : '') + (currentDay ? ' active ' : '')}day`}
      onClick={onSelect}
    >
      <div style={empty ? { opacity: 0 } : { opacity: 1 }}>
        {popup !== true && (
          <img
            alt="goal"
            className="calendar-goal"
            src={goalAchieved ? '/assets/images/goals-icon.png' : '/assets/images/failed-goal.png'}
          />
        )}
      </div>
      {empty ? <p style={{ opacity: 0 }} className="date">1</p> : <p className="date">{date}</p>}
    </Link>
  );
}
