import React from 'react';
import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@material-ui/core';

import CheckedIcon from '../../icons/CheckedIcon';
import ContainedButton from '../../ContainedButton';

const ChooseOption = ({ closeModal, nextStep }) => (
  <Grid container className="h-100">
    <Grid item className="left-column" xs={12} md={7} xl={6}>
      <Grid container>
        <Grid item xl={12} className="pl-md-5">
          <h2 className="extra-header">go the extra mile</h2>
          <p className="pb-2 pb-sm-4">
            A health and fitness tracker for dogs that lasts 2+ weeks
            to monitor your dog’s activity and sleep 24/7
            giving you actionable insights and daily goals to
            improve your dog’s overall well being…<a href="http://www.kasperandkitty.com/index.php/fitkolar-dog-activity-tracker/" target="_blank">Read More</a>
          </p>
          <ul className="pl-0 mb-0">
            <li className="pb-1 pb-sm-3 align-middle"><CheckedIcon /><span className="pl-2">Free delivery</span></li>
            <li className="pb-1 pb-sm-3"><CheckedIcon /><span className="pl-2">90 days Return Policy</span></li>
            <li className="pb-3 "><CheckedIcon /><span className="pl-2">Limited 1-year warranty</span></li>
          </ul>
        </Grid>
        <Grid item xs={9} className="separator-line" />
        <Grid item xs={12} className="pl-md-5">
          <RadioGroup>
            <FormControlLabel
              control={<Radio checked className="radio-icon" value="fitkolar" />}
              className="fill-white pt-4"
              label={
                <p>
                  Add <span className="orange-text bold">Fitkolar</span> (RRP £39.99) to <span className="orange-text bold">FREE 2-week Food Trial</span>
                </p>
              }
            />
          </RadioGroup>
        </Grid>
        <Grid item className="buttons pt-3" xs={12}>
          <Grid container alignItems="center">
            <Grid item md={8} xs={12}>
              <ContainedButton title="Add to cart" onClick={nextStep} />
            </Grid>
            <Grid item md={4} xs={12} className="mt-2 mt-md-0 text-center text-md-right">
              <button className="btn-text" onClick={closeModal}>NO, THANKS</button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item className="right-column" md={5} xl={6}>
      <Grid container className="h-100" alignItems="flex-end">
        <Grid item>
          <img className="image-dog" src="../../../assets/images/extra-popup-dog.png" alt="extra-popup-dog"/>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default ChooseOption;
