import * as actionTypes from './actionTypes';

export const update = state => ({
  type: actionTypes.UPDATE,
  state,
});

export const set = progress => ({
  type: actionTypes.SET,
  progress,
});

export const setProgress = progress => dispatch => (
  dispatch(set(progress))
);

export const toggleNavigation = value => ({
  type: actionTypes.TOGGLE,
  isNavigation: value,
});

export const toggleDogNavigation = value => (dispatch) => {
  dispatch(toggleNavigation(value));
};
