import React from 'react';
import { connect } from 'react-redux';
import { Grid, Modal } from '@material-ui/core';
import { Link } from 'react-router-dom';

import CartGroup from '../../cart/CartGroup';
import AlsoLikeBlock from './AlsoLikeBlock';
import CheckoutLayout from '../CheckoutLayout';
import ExtraModal from '../basket/ExtraModal';
import ContainedButton from '../../ContainedButton';
import { isCartEmpty } from '../../../data/cart/utils';
import * as actionCreators from '../../../data/checkout/actions';
import * as api from '../../../data/orders/api';
import * as apiDogs from '../../../data/dogs/api';
import { getCartItemsByDog } from '../../../data/cart/utils';
import * as dogsSelectors from '../../../data/dogs/selectors';
import * as cartActionCreators from '../../../data/cart/actions';
import * as productActionCreators from '../../../data/products/actions';
import * as dogsActionCreators from '../../../data/dogs/actions';

const ProceedCheckoutLink = props => <Link to="/checkout/delivery" {...props} />;

class CheckoutBasketPage extends React.Component {
  state = {
    extraModalShown: false,
    shouldShowExtraModal: false,
  };

  componentDidMount() {
    // todo change here
    this.promocodeCheck();
    const query = new URLSearchParams(this.props.location.search)
    let dogID = query.get("id")
    //if query invalid, checkout latest dog to be added
    // fetch dogs from server
    if (this.props.dogs[dogID] === undefined) {
      // fetch all user's dogs, get latest one
      apiDogs.list()
        .then(res => res.sort((a, b) => a.ID > b.ID ? -1 : 1))
        .then(sorted => {
          let dogID = sorted[0].ID;
          this.props.selectDog(dogID);

          return sorted[0];
          // let apiDog = dogsSelectors.getCurrentDog();
        })
        .then(currentDog => {
          productActionCreators.getBespoke(currentDog.ID)
            .then(product => {
              if (this.props.cart.length === 0) {
                product.isBespoke = true;
                this.props.addProductToStore(product);
                this.props.addToCart(currentDog.ID, product.ID);
              }
            });
        });
    }
  }

  hideExtraModal = () => {
    this.setState({
      extraModalShown: false,
    });
    this.props.history.push('/checkout/delivery');
  };

  submitSummary = (event) => {
    if (this.state.shouldShowExtraModal) {
      event.preventDefault();
      this.setState({
        extraModalShown: true,
        shouldShowExtraModal: false,
      });
    }
  };

  promocodeCheck = () => {
    api.autoPromo().then(res => res.ID ? this.props.promocodeUpdate(res) : '')
  };

  render() {
    const {
      items,
      dog
    } = getCartItemsByDog(this.props.dogs, this.props.products, this.props.cart);
    return (
      <CheckoutLayout
        title="Shopping Basket"
        step={1}
        shouldShowExtraModal={this.state.shouldShowExtraModal}
        submitSummary={this.submitSummary}
        classes="checkout-basket-content"
        productCount={items.length}
      >
        <Grid container>
          <Grid item xs={12}>
            <CartGroup />
          </Grid>
          <Grid item xs={12}>
            <AlsoLikeBlock />
          </Grid>
          {!isCartEmpty() ? (
            <Grid item xs={12}>
              <Grid container justify="center" className="buttons-wrapper">
                <Grid item>
                  <ContainedButton title="Proceed To checkout" onClick={this.submitSummary} component={ProceedCheckoutLink} />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Modal hideBackdrop open={this.state.extraModalShown}>
          <ExtraModal closeModal={this.hideExtraModal} />
        </Modal>
      </CheckoutLayout>
    );
  }
}

const mapStateToProps = state => ({
  dogs: state.data.dogs.dogs,
  products: state.data.products,
  cart: state.data.cart,
});

const mapDispatchToProps = dispatch => ({
  promocodeUpdate: promocode => dispatch(actionCreators.promocodeUpdate(promocode)),
  addToCart: (dogId, productId) => dispatch(cartActionCreators.addToCart(dogId, productId)),
  addProductToStore: product => dispatch(productActionCreators.add(product)),
  deleteAll: () => dispatch(cartActionCreators.deleteAll()),
  selectDog: id => dispatch(dogsActionCreators.select(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBasketPage);
