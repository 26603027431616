import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import Row from '../../components/Row';
import FullColumn from '../../components/FullColumn';
import InputField from '../../components/InputField';
import FormErrors from '../../components/FormErrors';
import * as dogsSelectors from '../../data/dogs/selectors';
import * as dogsActionCreators from '../../data/dogs/actions';

import { validateMandatory } from '../../utils';

class YourDog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dog: {
        name: '',
      },
      dogImage: '',
      imageWidth: 0,
      errors: [],
      imageError: false,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();

    window.addEventListener('resize', this.updateDimensions);

    if (this.props.stepsState.dog) {
      this.setState({
        dog: Object.assign(
          {},
          this.state.dog,
          this.props.stepsState.dog,
        ),
      });
    }

    if (this.props.stepsState.dogImage) {
      this.setState({
        dogImage: this.props.stepsState.dogImage,
      });
    }

    if (this.props.dog !== undefined) {
      console.log(this.props.dog);
      const newDog = {
        name: this.props.dog.name !== null ? this.props.dog.name : '',
      };

      this.setState({
        dog: newDog,
        dogImage: this.props.dog.image !== null && this.props.dog.image.Url !== "" && this.props.dog.image.Url !== undefined ? this.props.dog.image.Url : '',
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    const imageWidth = this.image.offsetWidth;

    this.setState({ imageWidth });
  }

  onChangeImage() {
    // Assuming only image
    const upload = this.file.files[0];
    const size = upload.size / 1024;
    const type = upload.type.split('/');
    const allowed = ['png', 'jpeg', 'jpg', 'gif'];

    if (size > 4000 || type[0] !== 'image' || allowed.indexOf(type[1]) < 0) {
      this.setState({ imageError: true, dogImage: '' });
      this.file.value = '';
      return;
    }

    const reader = new FileReader();
    const url = reader.readAsDataURL(upload);

    reader.onloadend = function (e) {
      this.setState({
        dogImage: [reader.result],
        imageError: false,
      });
    }.bind(this);
  }

  onNext() {
    return new Promise((resolve, reject) => {
      const mandatoryFields = ['name'];
      const errors = validateMandatory(this.state.dog, mandatoryFields);

      if (errors.length > 0) {
        this.setState({ errors });
        reject();
      }

      this.props.onUpdateState({ dog: this.state.dog, dogImage: this.state.dogImage });

      if (this.props.dog !== undefined) {
        this.handleUpdate(reject);
      }

      resolve();
    });
  }

  onChangeHandler(key) {
    return (event) => {
      let value = event;

      if (event.target) {
        value =  event.target.value;
      }

      const dog = Object.assign({}, this.state.dog, {
        [key]: value,
      });

      this.setState({ dog });
    };
  }

  handleUpdate(reject) {
    const mandatoryFields = ['name'];

    const errors = validateMandatory(this.state.dog, mandatoryFields);

    if (errors.length > 0) {
      this.setState({ errors });
      reject();

      return;
    }

    const wholeDog = this.props.dog;

    wholeDog.name = this.state.dog.name;

    if (this.state.dogImage !== '') {
      wholeDog.image = this.state.dogImage;
    }

    // uses updateDog to get a responce of the full updated dog from the api and then uses this response to update redux
    this.props.actions.dogs.updateDog(wholeDog)
      .then((response) => {
        if (response.errors) {
          for (const err of response.errors) {
            this.state.errors.push(err);
          }

          return;
        }
        console.log("Response: ", response);
      })
      .catch((err) => {
        console.log("An error has occured: ", err);
      });
  }

  render() {
    const { onSubmit } = this.props;

    return (
      <Row>
        <FullColumn>
          <OrangeTextHeading
            text="Tell us your dog's name and upload their photo!"
            fontSize={30}
            centered
          />
          <GreyText
            text="Before you tell us a bit more about you, Kasper &amp; Kitty would love to find out your four-legged friend's name and see their picture. They can't wait to meet their new friend!"
            fontSize={20}
            centered
            marginBottom
          />
          <form onSubmit={onSubmit}>
            <Row>
              <div className="col-12 col-md-8 align-self-center">
                <FormErrors errors={this.state.errors} />
                <InputField
                  type="text"
                  placeholder="What's your dogs name?*"
                  value={this.state.dog.name}
                  onChangeText={this.onChangeHandler('name')}
                />
                <InputField
                  reference={(file) => {
                    this.file = file;
                  }}
                  type="file"
                  placeholder="Upload your dog's picture"
                  value={this.state.image}
                  onChangeText={this.onChangeImage}
                />
                {this.state.imageError && <div className="image-error">
                  Maximum file size: 4MB. Allowed extensions: png, jpg, jpeg, gif.
                </div>}
              </div>
              <div className="col-12 col-md-4 align-self-center">
                <div className="dog-image-border">
                  <img
                    className="dog-image-frame"
                    src="/assets/images/dog-image-frame.png"
                  />
                  <div
                    ref={(image) => {
                      this.image = image;
                    }}
                    className="dog-image-container"
                    style={{
                      height: this.state.imageWidth,
                      backgroundImage: this.state.dogImage !== '' ? `url(${this.state.dogImage})` : 'url(/assets/images/kaspermeter-head.png)',
                    }}
                  />
                </div>
              </div>
            </Row>
          </form>
        </FullColumn>
      </Row>
    );
  }
}

export default connect(() => ({
  // dog: dogsSelectors.getCurrentDog(),
}), dispatch => ({
  actions: {
    dogs: bindActionCreators(dogsActionCreators, dispatch),
  },
}), null, { withRef: true })(YourDog);
