import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CheckboxNew from '../../components/CheckboxNew';
import * as ordersActionCreators from '../../data/orders/actions';
import * as dogsSelectors from '../../data/dogs/selectors';

import CloseIcon from '../../assets/images/wizard/review-close.png';
import CheckMarkIcon from '../../assets/images/checked-white-icon.png';

class Final extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCheaperProductRange: false,
      isWetFoodSubscription: false,
      isCatFood: false,
      isWeightWatchersProgram: false,
      isWebsiteAppExperience: false,
      isTracker: false,

      isFutureRequest: false,
      futureRequestNote: '',
    };

    this.nextStep = this.nextStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      futureRequestNote: e.target.value,
    });
  }

  nextStep() {
    this.props.updateQuestionnaire({
      cheaper_product_range: this.state.isCheaperProductRange,
      wet_subscription_food: this.state.isWetFoodSubscription,
      cat_food: this.state.isCatFood,
      weight_watcher_program: this.state.isWeightWatchersProgram,
      website_app_experience: this.state.isWebsiteAppExperience,
      tracker: this.state.isTracker,
      future_request: this.state.futureRequestNote,
    });

    this.props.submitQuestionnare();
  }

  render() {
    const dogName = dogsSelectors.getCurrentDog().name;
    return (
      <div
        className="modal animated fadeIn final-modal"
        id="final"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered row justify-content-center"
          role="document">
          <div className="modal-content">
            <div className="modal-header py-0 px-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Cancel {dogName}&apos;s subscription
              </h5>
              <button
                type="button"
                className="close text-right"
                onClick={this.props.cancelStep}
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">
                  <img src={CloseIcon} alt="" />
                </span>
              </button>
            </div>

            <div className="modal-body px-0 pb-0">
              <div style={{ width: '95%' }}>
                <div className="step-area">
                  <div className="inner-circle-1">
                    <img src={CheckMarkIcon} />
                  </div>
                  <div className="divider"></div>
                  <div className="inner-circle-1">
                    <img src={CheckMarkIcon} />
                  </div>
                  <div className="divider"></div>
                  <div className="inner-circle-1">
                    <img src={CheckMarkIcon} />
                  </div>
                  <div className="divider"></div>
                  <div className="outer-circle">
                    <div className="inner-circle">4</div>
                  </div>
                </div>

                <div className="final-area">
                  <div className="final-title">
                    <div>
                      What future/product could we add to make our experience
                      better?
                    </div>
                    <div>*You may select multiple</div>
                  </div>
                  <div className="final-list">
                    <CheckboxNew
                      onClick={() => {
                        this.setState({
                          isCheaperProductRange: !this.state
                            .isCheaperProductRange,
                        });
                      }}
                      label="Cheaper Product Range"
                      checked={this.state.isCheaperProductRange}
                      white
                    />
                    <CheckboxNew
                      onClick={() => {
                        this.setState({
                          isWetFoodSubscription: !this.state
                            .isWetFoodSubscription,
                        });
                      }}
                      label="Wet food subscription"
                      checked={this.state.isWetFoodSubscription}
                      white
                    />
                    <CheckboxNew
                      onClick={() => {
                        this.setState({ cat_food: !this.state.cat_food });
                      }}
                      label="Cat Food"
                      checked={this.state.cat_food}
                      white
                    />
                    <CheckboxNew
                      onClick={() => {
                        this.setState({
                          weight_watcher_program: !this.state
                            .weight_watcher_program,
                        });
                      }}
                      label="Weight watchers program"
                      checked={this.state.weight_watcher_program}
                      white
                    />
                    <CheckboxNew
                      onClick={() => {
                        this.setState({
                          website_app_experience: !this.state
                            .website_app_experience,
                        });
                      }}
                      label="Better website and app experience"
                      checked={this.state.website_app_experience}
                      white
                    />
                    <CheckboxNew
                      onClick={() => {
                        this.setState({ tracker: !this.state.tracker });
                      }}
                      label="GPS Tracker"
                      checked={this.state.tracker}
                      white
                    />
                    <CheckboxNew
                      onClick={() => {
                        this.setState({
                          isFutureRequest: !this.state.isFutureRequest,
                        });
                      }}
                      isChecked={this.state.isFutureRequest}
                      onChange={this.handleChange}
                      white
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button className="cancel-btn" onClick={this.props.cancelStep}>
                Cancel
              </button>
              <button className="next-btn" onClick={this.nextStep}>
                Finish
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questionnaire: state.data.orders.questionnaire,
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestionnaire: (payload) =>
    dispatch(ordersActionCreators.updateQuestionnaire(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Final));
