import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

const about = [
  {
    text: 'About us',
    route: 'https://www.kasperandkitty.com/about-us/',
  },
  {
    text: 'Our Team',
    route: 'https://www.kasperandkitty.com/about-us/',
  },
  {
    text: 'Press',
    route: 'https://www.kasperandkitty.com/about-us/',
  },
];

const help = [
  {
    text: 'Delivery & Returns',
    route: 'https://www.kasperandkitty.com/delivery-returns/',
  },
  {
    text: 'Contact Us',
    route: 'https://www.kasperandkitty.com/support/',
  },
  {
    text: 'FAQ\'s',
    route: 'https://www.kasperandkitty.com/faq/',
  },
  {
    text: 'Terms & Conditions',
    route: 'https://www.kasperandkitty.com/terms-conditions/',
  },
  {
    text: 'Privacy Policy',
    route: 'https://www.kasperandkitty.com/privacy-policy/',
  },
];

export default class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className='footer-divider'></div>

        <footer className="inner-container page-footer">
          <Grid container>
            <Grid item md={4} xs={12} className="footer-column">
              <div className="footer-title">
                <img src="../../assets/images/paw.png" alt=""></img>
                <p className="footer-title-text">Kasper &amp; Kitty</p>
              </div>

              <p className="footer-content-text">Kasper &amp; Kitty offers a bespoke subscription service - delivering nutritious food and treats to meet your pet’s unique dietary needs.</p>
              <p className="footer-content-text">Our clever Fitkolar and App gives us all the information we need to do just that! No guesswork or fancy algorithms! And we practically deliver your pet's meals straight to their bowl - anywhere in the UK within 48h.</p>
            </Grid>

            <Grid item md={2} xs={12} className="footer-column about" style={{ display: 'flex' }}>
              <p className="footer-title-text">About</p>
              {about.map((item, index) => <a className="footer-content-text" key={index} href={item.route} >{item.text}</a>)}
            </Grid>

            <Grid item md={2} xs={12} className="footer-column help">
              <p className="footer-title-text">Help &amp; Support</p>
              {help.map((item, index) => <a className="footer-content-text" key={index} href={item.route} >{item.text}</a>)}
            </Grid>

            <Grid item md={4} xs={12} className="footer-column">
              <p className="footer-title-text">Stay in touch with us!</p>

              <p className="footer-content-text">We love to hear from our customers and all the fun you and your friend have been getting up to.</p>

              <div className="link-group">
                <Link to="/facebook"><img src="../../assets/images/FB.png" aria-hidden="true" /></Link>
                <Link to="/twitter"><img src="../../assets/images/Twitter.png" aria-hidden="true" /></Link>
                <Link to="/instagram"><img src="../../assets/images/Instagram.png" aria-hidden="true" /></Link>
                <Link to="/linkedin"><img src="../../assets/images/Linkedin.png" aria-hidden="true" /></Link>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className="footer-text" align="center">
              @ Kasper &amp; Kitty 2020 | Bespoke Pet Wellbeing
          </Grid>
          </Grid>
        </footer>
      </React.Fragment>
    );
  }
}