import React from 'react';

const formatError = (error) => {
  let msg = error.replace(/_/g, ' ');
  return `${msg.substr(0, 1).toUpperCase()}${msg.substr(1)}`;
};


const FormErrors = ({ errors, type, width }) => {
  if (errors.length === 0) {
    return null;
  }

  return (
    <div>
      {type === 'success' ? (
        <div className="alert alert-success" style={{ width: width || '92%' }}>
          <ul className="my-0 px-2">
            {errors.map((error, index) => (
              <li key={index}>{formatError(error)}</li>
            ))}
          </ul>
        </div>
      ) : (
          <div className="alert alert-danger">
            <ul className="my-0 px-2">
              {errors.map((error, index) => (
                <li key={index}>{formatError(error)}</li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
};

export default FormErrors;
