import React from 'react';

import { Link } from 'react-router-dom';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import SimplyPowesome from '../components/SimplyPowesome';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';

const introOffers = [
  {
    title: '£2 for Kasper & Kitty Introductory Trial Offer with a Direct Debit',
    contents: [
      "Offer runs from 1 January 2019",
      "This offer (the “Introductory Trial Offer”), which is made to you by Kasper & Kitty and, as defined in\
      the Kasper & Kitty terms and conditions, entitles you to access the Kasper & Kitty monthly\
      subscription (as defined in the Kasper & Kitty monthly terms and conditions of use) for an offer of\
      two (2) weeks for £2.00 - bespoke dog meal plan from the moment that you activate such trial offer\
      by submitting your payment details by direct debit and paying the advertised price, £2.00 (the\
      “Introductory Trial Offer”).",
      "By submitting your payment details, (i) you accept the Introductory Trial Offer at £2 for 2 weeks, (ii)\
      consent to us using your payment details in accordance with our privacy policy, (iii) acknowledge and\
      agree to the Kasper & Kitty terms and conditions and these Introductory Trial Offer terms and\
      conditions.",
      "If you decide that you do not want to remain a paying user of the Kasper & Kitty monthly\
      subscription, you must cancel your subscription to Kasper & Kitty by (i) emailing\
      friends@kasperandkitty.com (ii) calling us on 0800 888 6554, prior to the end of your Introductory\
      Trial Offer. Otherwise, at the end of your Introductory Trial Offer, you will automatically become a\
      monthly paying member of Kasper & Kitty at the regular Kasper & Kitty monthly price which is\
      dependent on your dogs individual bespoke meal plan, as indicated on your account after\
      registration, and the payment method you provided will automatically be charged the Kasper & Kitty\
      subscription fee each month, until you cancel your Kasper & Kitty monthly subscription",
      "If you wish to cancel your Kasper & Kitty monthly subscription after the end of your Introductory\
      Trial Offer, you may do so by following the instructions above.\
      There are no refunds or credits for partial monthly subscriptions.\
      The offer is open only to persons of 18 years or over at the time of entry who are resident in the UK\
      If Kasper & Kitty increases or decreases the monthly fee in the future, we will provide you with\
      notice. Price changes will take effect at the start of the next subscription offer following the date of\
      the price change.\
      By continuing to use Kasper & Kitty monthly after the price change takes effect, you accept the new\
      price.\
      You may only use this Introductory Trial Offer once per dog.\
      If you have subscribed to Kasper & Kitty monthly or have taken a previous free trial offer, you are\
      ineligible for this Introductory Trial Offer. You may also be ineligible for this Introductory Trial Offer if\
      you have taken other previous trial offers offered by Kasper & Kitty .\
      A limited number of Introductory Trial Offers are available, while supplies last.\
      This Introductory Trial Offer expires and must be redeemed before the date advertised.\
      Kasper & Kitty reserves the right to earlier terminate this Introductory Trial Offer at any time and for\
      any reason.\
      After such time of termination, Kasper & Kitty shall not be obligated to redeem any further\
      Introductory Trial Offers.\
      Kasper & Kitty reserves the right, in its absolute discretion, to withdraw or to modify this\
      Introductory Trial Offer and the terms and conditions at any time without prior notice and with no\
      liability.\
      These terms and conditions will be interpreted in accordance with the laws of England.\
      Kasper & Kitty will use the personal data provided to administer this offer and for customer service\
      and marketing purposes, subject to the appropriate consents being given. Kasper & Kitty will not\
      pass your data to any third party."
    ],
  },
];

export default class IntroOffer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/cookie-banner.jpg)" heading="£2 for Kasper & Kitty Introductory Trial Offer with a Direct Debit" subtext="Offer runs from 1 January 2019" />
        <div className="cookies-container">
          {introOffers.map((cookie, tindex) => (<div key={tindex} className="heading">{`${++tindex}\u00A0.\u00A0\u00A0${cookie.title}`}
            {cookie.contents.map((content, cindex) => (<div key={cindex} className="desc">
              {/* <div className="desc-index">{`${tindex}.${++cindex}`}</div> */}
              <div className="desc-content">{content}</div>
                                                       </div>))}
                                            </div>))}
        </div>
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
