import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as productsActions from '../data/products/actions';
import * as ordersActions from '../data/orders/actions';
import * as dogsSelectors from '../data/dogs/selectors';

import SubscriptionBox from '../components/SubscriptionBox';
import ShoppingCart from '../components/ShoppingCart';

import Navigation from '../components/Navigation';
import SubNavigation from '../components/SubNavigation';
import Row from '../components/Row';
import Footer from '../components/Footer';

import * as api from '../data/orders/api';

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productId: parseInt(this.props.match.params.id.split('-')[0], 10),
      product: {},
      subscription: {},
      selectedTab: 1,
      quantity: 1,
      subQuantity: 1,
      buyQuantity: 1,
      imageIndex: 0,
      related: [],
      innerWidth: null,

      // if '', can't add,
      // else if 'sub', add item to subscription box,
      // else if 'shop', add item to shoping cart box
      pressState: ''
    };

    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.loadQuantity();

    window.addEventListener('resize', this.resize.bind(this));
    this.resize();

    this.props.actions.products.getProducts()
      .then((prod) => {
        prod.forEach((p) => {
          if (p.ID === this.state.productId) {
            this.setState({ product: p });
          }
        });

        let related = prod;
        if (related.length > 0) {
          related.sort(() => 0.5 - Math.random());
          related = related.slice(0, 4);

          related.sort((p1, p2) => {
            const r1 = this.props.incompleteOrder.find(item => item.key === p1.ID) === undefined ? 1 : 0;
            const r2 = this.props.incompleteOrder.find(item => item.key === p2.ID) === undefined ? 1 : 0;
            return r1 - r2;
          });

          this.setState({ related });
        }
      });
  }

  resize() {
    this.setState({ innerWidth: window.innerWidth });
  }

  scrollToMyRef() {
    if (window.innerWidth <= 575) {
      window.scrollTo({
        top: this.myRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  }

  loadQuantity() {
    // why need this logic?
    // this logic is for setting quantity (subscriptionbox and shopping cart) automatically on product page when user select one of products

    // when user referesh manually product page, get product id from localstorage
    let filtered = this.props.incompleteOrder.filter(i => i.key === this.state.productId)

    if (filtered.length > 0) {
      this.setState({ buyQuantity: filtered[0].quantity }) // set quantity from localstorage (for buy quantity)
    }

    let subOrders = JSON.parse(localStorage.getItem('subscription'));

    let filteredOrder = subOrders.filter(sub => sub.dogID === this.props.dog.ID)
    let filteredSubItems = filteredOrder[0].subscription.items && filteredOrder[0].subscription.items.filter(item => item.product_id === this.state.productId)

    if (filteredSubItems.length > 0) {
      this.setState({ subQuantity: filteredSubItems[0].quantity }) // set quantity from localstorage (for sub quantity)
    }
  }

  buyNow(id, name, quantity, price, imageUrl, isFitkolar) {
    const newItem = {
      key: id,
      product: name,
      quantity,
      price,
      imageUrl,
      isFitkolar,
    };

    let productList = [];

    const { incompleteOrder } = this.props;

    let foundIndex = -1;
    if (incompleteOrder.length > 0) {
      incompleteOrder.map((item, index) => {
        if (item.key === id) {
          foundIndex = index;
          return Object.assign(item, newItem);
        }
        return item;
      });

      productList = [...productList, ...incompleteOrder];
    }

    if (foundIndex === -1 && quantity !== 0) {
      productList.push(newItem);
    } else if (foundIndex !== -1 && quantity === 0) {
      productList.splice(foundIndex, 1);
    }

    const payload = {
      dog_id: this.props.dog.ID,
      order_items: productList,
      order_id: this.props.incompleteOrder.length > 0 ? this.props.incompleteOrder[0].order_id : 0,
      status: 'Incomplete',
      delivery_cost: 3.95,
    };

    api.updateIncompleteOrder(payload)
      .then(res => (res.success ?
        { message: 'updated ok ' } : (res.message === 'No incomplete orders for user' ? api.ordernew(payload) : { message: res.message })))
      .then(() => this.props.actions.orders.getIncompleteOrder(this.props.dog.ID))
      .catch(err => console.log('error trying to update incomplete order', err));
  }

  addToSubscription(product_id, name, quantity, price, isKibble, image) {
    this.setState({
      subscription: {
        product_id,
        name,
        quantity,
        price,
        isKibble,
        image
      }
    })
  }

  quantityDown = () => {
    let quantity = 1;

    if (this.state.pressState === '') {
      quantity = this.state.quantity;
    } else if (this.state.pressState === 'shop') {
      quantity = this.state.buyQuantity;
    } else if (this.state.pressState === 'sub') {
      quantity = this.state.subQuantity;
    }

    if (quantity <= 0) quantity = 0;
    else quantity = quantity - 1;

    if (this.state.pressState === '') { // only decrease quantity
      this.setState({ quantity })
    } else if (this.state.pressState === 'shop') { // add item to shoping cart box
      this.setState({ buyQuantity: quantity });

      this.buyNow(
        this.state.product.ID,
        this.state.product.name,
        quantity,
        this.state.product.price,
        this.state.product.image.Url,
        this.state.product.is_fitkolar,
      );
    } else if (this.state.pressState === 'sub') { // add item to subscription box
      this.setState({ subQuantity: quantity });

      this.addToSubscription(
        this.state.product.ID,
        this.state.product.name,
        quantity,
        this.state.product.price,
        this.state.product.is_kibble,
        this.state.product.image,
      );
    }
  }

  quantityUp = () => {
    let quantity = 1;

    if (this.state.pressState === '') {
      quantity = this.state.quantity;
    } else if (this.state.pressState === 'shop') {
      quantity = this.state.buyQuantity;
    } else if (this.state.pressState === 'sub') {
      quantity = this.state.subQuantity;
    }

    if (quantity >= 99) quantity = 99;
    else quantity = quantity + 1;

    if (this.state.pressState === '') { // only increase quantity
      this.setState({ quantity });
    } else if (this.state.pressState === 'shop') { // add item to shoping cart box
      this.setState({ buyQuantity: quantity });

      this.buyNow(
        this.state.product.ID,
        this.state.product.name,
        quantity,
        this.state.product.price,
        this.state.product.image.Url,
        this.state.product.is_fitkolar,
      );
    } else if (this.state.pressState === 'sub') { // add item to subscription box
      this.setState({ subQuantity: quantity });

      this.addToSubscription(
        this.state.product.ID,
        this.state.product.name,
        quantity,
        this.state.product.price,
        this.state.product.is_kibble,
        this.state.product.image,
      );
    }
  }

  render() {

    const subOrders = JSON.parse(localStorage.getItem('subscription'));

    let index = subOrders.findIndex(sub => sub.dogID === this.props.dog.ID)

    const deliveryDate = new Date(subOrders[index].subscription.dateRequired);
    const now = new Date();
    const enableChangeSubscr = (now < deliveryDate) && (subOrders[index].subscription.orderStatus === 'New') && (subOrders[index].subscription.subCycle > 1);

    if (this.state.product.ID === undefined) {
      return '';
    }

    const { imageIndex, related } = this.state;

    const productImages = [{
      FileName: this.state.product.image.FileName,
      Url: this.state.product.image.Url,
    }];

    if (Array.isArray(this.state.product.product_images)) {
      this.state.product.product_images.forEach(element => productImages.push({
        FileName: element.Image.FileName,
        Url: element.Image.Url,
      }));
    }

    return (
      <div className="product-container container">
        <Navigation
          white
          textColor="white-text"
          productCount={this.props.incompleteOrder.reduce((acc, cur) => acc + cur.quantity, 0)}
        />

        <SubNavigation />

        <div className="inner-container">
          <div className="product-row">
            <div className="menu-title">
              <img className="title-image" src="../assets/images/dashboard-nav-icon.png" alt="#" />
              {this.props.dog.name.toUpperCase()}&apos;S SHOP
            </div>

            <Row className="d-none d-sm-block">
              <div className="col-12 breadcrumbs">
                <Link to="/"><i className="fa fa-home" aria-hidden="true" /></Link>
                <i className="fa fa-angle-double-right" aria-hidden="true" />
                <Link to="/shop">Back to shop</Link>
              </div>
            </Row>

            <Row className="mb-3 d-block d-sm-none">
              <Link to="/subscription">
                <button className="d-block w-100 btn-view-subs-box">VIEW CURRENT SUBSCRIPTION BOX</button>
              </Link>
            </Row>

            <Row>
              <div className="col-12 col-lg-8 padding-right padding-bottom small full-height">
                <div className="d-block d-sm-none product-name">{this.state.product.name}</div>

                <div className="item-image-and-detail">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 clearfix shop-item-all-image">
                    <div className="pull-right shop-item-image">
                      <img src={productImages[imageIndex].Url} alt="product" />
                    </div>

                    <div className="pull-left shop-item-all-images">
                      {productImages.map((image, index) => (
                        <div
                          key={index}
                          role="button"
                          tabIndex={0}
                          onKeyPress={() => { }}
                          className="item-image-each-small"
                          onClick={() => this.setState({ imageIndex: index })}
                        >
                          <img
                            className="small-image"
                            src={image.Url}
                            alt="random_products"
                          />
                        </div>
                      ))}
                    </div>

                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 shop-item-information">
                    <div className="d-none d-sm-block product-name">
                      {this.state.product.name}
                    </div>

                    <div className="counts-price">
                      <div className="product-price">£{this.state.product.price % 1 < 0.01 ? `${this.state.product.price}.00` : this.state.product.price}</div>

                      <div className="increment-decrease">
                        <div
                          role="button"
                          tabIndex="0"
                          className="item remove-item"
                          onClick={this.quantityDown}
                        >
                          −
                        </div>

                        <div className="item count-item">
                          {this.state.pressState === 'shop' ? this.state.buyQuantity : (
                            this.state.pressState === 'sub' ? this.state.subQuantity : this.state.quantity)}
                        </div>

                        <div
                          role="button"
                          tabIndex="0"
                          className="item add-item"
                          onClick={this.quantityUp}
                        >
                          +
                        </div>
                      </div>
                    </div>

                    <div className="items-buttons">
                      <button
                        style={enableChangeSubscr ? {} : { opacity: 0.2, pointerEvents: 'none' }}
                        className="item-button add-subscription"
                        onClick={() => {
                          if (this.state.pressState === '') {
                            this.addToSubscription(
                              this.state.product.ID,
                              this.state.product.name,
                              this.state.quantity,
                              this.state.product.price,
                              this.state.product.is_kibble,
                              this.state.product.image,
                            );

                            this.setState(({
                              pressState: 'sub',
                              subQuantity: this.state.quantity
                            }))
                          } else {
                            this.addToSubscription(
                              this.state.product.ID,
                              this.state.product.name,
                              this.state.subQuantity,
                              this.state.product.price,
                              this.state.product.is_kibble,
                              this.state.product.image,
                            );

                            this.setState(({
                              pressState: 'sub',
                            }))
                          }
                        }}
                      >
                        ADD TO SUBSCRIPTION
                        <img className="subscription-refresh" src="../assets/images/refresh-white.png" alt="" />
                      </button>

                      <button
                        className="item-button buy-now"
                        onClick={() => {
                          if (this.state.pressState === '') {
                            this.buyNow(
                              this.state.product.ID,
                              this.state.product.name,
                              this.state.quantity,
                              this.state.product.price,
                              this.state.product.image.Url,
                              this.state.product.is_fitkolar,
                            );

                            this.setState({
                              pressState: 'shop',
                              buyQuantity: this.state.quantity
                            });
                          } else {
                            this.buyNow(
                              this.state.product.ID,
                              this.state.product.name,
                              this.state.buyQuantity,
                              this.state.product.price,
                              this.state.product.image.Url,
                              this.state.product.is_fitkolar,
                            );

                            this.setState({ pressState: 'shop' });
                          }
                        }}
                      >
                        BUY NOW
                      </button>
                    </div>

                    <div className="product-weight">
                      <span className="product-weight-title">Weight(g): </span>{this.state.product.weight}g
                    </div>

                    {this.state.product.amount !== undefined && this.state.product.amount !== '' &&
                      <div className="product-amount">
                        <span className="product-amount-title">Amount: </span>{this.state.product.amount}
                      </div>
                    }

                    <div className="product-supplier">
                      <span className="product-supplier-title">Category: </span>
                      {Array.isArray(this.state.product.categories) && this.state.product.categories.length > 0 ? this.state.product.categories[0].name : ''}
                    </div>

                  </div>
                </div>

                <div className="product-description">{this.state.product.description}</div>

                <div>
                  <div id="accordion" className="d-block d-sm-none product-details-mobile">
                    <div className="item-product-detail">
                      <div className="item-header">
                        <button className="item-toggle" data-toggle="collapse" data-target="#collapseComposition" aria-expanded="true" aria-controls="collapseComposition">
                          Composition
                          <i className="fa fa-angle-down" />
                        </button>
                      </div>

                      <div id="collapseComposition" className="collapse collapsed" data-parent="#accordion">
                        <div className="item-body">
                          {this.state.product.composition}
                        </div>
                      </div>
                    </div>

                    <div className="item-product-detail">
                      <div className="item-header">
                        <button className="item-toggle" data-toggle="collapse" data-target="#collapseAdditionalInformation" aria-expanded="true" aria-controls="collapseAdditionalInformation">
                          Additional information
                          <i className="fa fa-angle-down" />
                        </button>
                      </div>

                      <div id="collapseAdditionalInformation" className="collapse collapsed" aria-labelledby="headingAdditionalInformation" data-parent="#accordion">
                        <div className="item-body">
                          {this.state.product.additional_information}
                        </div>
                      </div>
                    </div>

                    <div className="item-product-detail">
                      <div className="item-header">
                        <button className="item-toggle" data-toggle="collapse" data-target="#collapseAdditives" aria-expanded="true" aria-controls="collapseAdditives">
                          Additives
                          <i className="fa fa-angle-down" />
                        </button>
                      </div>

                      <div id="collapseAdditives" className="collapse collapsed" data-parent="#accordion">
                        <div className="item-body">
                          {this.state.product.additives}
                        </div>
                      </div>
                    </div>

                    <div className="item-product-detail">
                      <div className="item-header">
                        <button className="item-toggle" data-toggle="collapse" data-target="#collapseConstituents" aria-expanded="true" aria-controls="collapseConstituents">
                          Constituents
                          <i className="fa fa-angle-down" />
                        </button>
                      </div>

                      <div id="collapseConstituents" className="collapse collapsed" data-parent="#accordion">
                        <div className="item-body">
                          {this.state.product.constituents}
                        </div>
                      </div>
                    </div>

                    <div className="item-product-detail">
                      <div className="item-header">
                        <button className="item-toggle" data-toggle="collapse" data-target="#collapseGuide" aria-expanded="true" aria-controls="collapseGuide">
                          Guide
                          <i className="fa fa-angle-down" />
                        </button>
                      </div>

                      <div id="collapseGuide" className="collapse collapsed" data-parent="#accordion">
                        <div className="item-body">
                          {this.state.product.guide}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-none d-sm-block product-details">
                    <div className="product-details-tabs">
                      <div
                        role="button"
                        tabIndex="0"
                        onKeyPress={() => { }}
                        className={`product-details-tab ${(this.state.selectedTab === 1) ? 'active' : ''}`}
                        onClick={() => this.setState({ selectedTab: 1 })}
                      >
                        Composition
                      </div>

                      <div
                        role="button"
                        tabIndex="0"
                        onKeyPress={() => { }}
                        className={`product-details-tab ${(this.state.selectedTab === 3) ? 'active' : ''}`}
                        onClick={() => this.setState({ selectedTab: 3 })}
                      >
                        Additional information
                      </div>

                      <div
                        role="button"
                        tabIndex="0"
                        onKeyPress={() => { }}
                        className={`product-details-tab ${(this.state.selectedTab === 4) ? 'active' : ''}`}
                        onClick={() => this.setState({ selectedTab: 4 })}
                      >
                        Additives
                      </div>

                      <div
                        role="button"
                        tabIndex="0"
                        onKeyPress={() => { }}
                        className={`product-details-tab ${(this.state.selectedTab === 5) ? 'active' : ''}`}
                        onClick={() => this.setState({ selectedTab: 5 })}
                      >
                        Constituents
                      </div>

                      <div
                        role="button"
                        tabIndex="0"
                        onKeyPress={() => { }}
                        className={`product-details-tab ${(this.state.selectedTab === 6) ? 'active' : ''}`}
                        onClick={() => this.setState({ selectedTab: 6 })}
                      >
                        Guide
                      </div>
                    </div>

                    <div className="product-details-content">
                      {this.state.selectedTab === 1 &&
                        <div className="product-details-content-tab">
                          <div className="details-box">
                            {this.state.product.composition}
                          </div>
                        </div>
                      }

                      {this.state.selectedTab === 3 &&
                        <div className="product-details-content-tab">
                          <div className="details-box">
                            {this.state.product.additional_information}
                          </div>
                        </div>
                      }

                      {this.state.selectedTab === 4 &&
                        <div className="product-details-content-tab">
                          <div className="details-box">
                            {this.state.product.additives}
                          </div>
                        </div>
                      }

                      {this.state.selectedTab === 5 &&
                        <div className="product-details-content-tab">
                          <div className="details-box">
                            {this.state.product.constituents}
                          </div>
                        </div>
                      }

                      {this.state.selectedTab === 6 &&
                        <div className="product-details-content-tab">
                          <div className="details-box">
                            {this.state.product.guide}
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="related-products">
                    <div className="title">RECOMMENDED PRODUCTS</div>

                    <div className="products">
                      {related.map((product, index) => {
                        let routeId = product.name.replace(/[(,)`)]/g, '').split(' ').join('-').toLowerCase();
                        let newURL = product.ID + '-' + routeId
                        return (
                          <div className="product" key={index}>
                            <Link to={{ pathname: `/product/${newURL}` }}>
                              <div
                                style={{
                                  backgroundImage: `url(http://${product.image.Url})`,
                                }}
                                className="related-product-image"
                              />

                              <div className="product-content">
                                <div className="product-name">{product.name}</div>
                                <div className="product-price">£{product.price}</div>
                              </div>
                            </Link>
                          </div>
                        )
                      })
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 padding-left padding-bottom small full-height" ref={this.myRef}>
                <ShoppingCart
                  history={this.props.history}
                  checkoutURL="/checkout"
                />

                <SubscriptionBox
                  dogID={this.props.dog.ID}
                  subscription={this.state.subscription}
                  updatedLocalStorage={() => { }}
                />

                <p className="remove-item-hint">
                  To remove or change quantity of existing items, please go to your orders.
                </p>
              </div>
            </Row>

          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default connect((state) => ({
  incompleteOrder: state.data.orders.incompleteOrder,
  dog: dogsSelectors.getCurrentDog(),
}), dispatch => ({
  actions: {
    products: bindActionCreators(productsActions, dispatch),
    orders: bindActionCreators(ordersActions, dispatch)
  },
}), null, { withRef: true })(Product);
