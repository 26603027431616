import React from 'react';
import { connect } from 'react-redux';
import CloseIcon from '../../assets/images/wizard/review-close.png';
import Button from '../../components/Button';
import { withRouter } from 'react-router-dom';
import * as actionTypes from '../../data/exports';
import * as selectors from '../../data/dogs/selectors';
import * as dogsActionCreators from '../../data/dogs/actions';

class WeightTip extends React.Component {
    constructor(props) {
        super(props);

        this.toggleModals = () => {
            $('#weight').modal('hide');
            $('#how-estimate').modal('show');

        }
    }
    render() {
        const { title, description, isImage, image, target, isButton, btnLabel, btnFn } = this.props;
        return (
            <div className="modal custom-modal animated fadeIn" id={target} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src={CloseIcon} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid px-0">
                                <div className="row justify-content-center">
                                    <div className={`${isImage ? 'col-sm-7 mb-4 mb-sm-0' : 'col-12'}`}>
                                        <h5 className="modal-title text-left text-justify py-1">{title}</h5>
                                        <p className="modal-description text-left text-justify py-1">{description}</p>
                                        {/*<p className="modal-description text-left text-justify py-1">*/}
                                            {/*If you're unsure, please use our <a href="#" className={"weight-tip-link"}>weight estimator.</a>*/}
                                        {/*</p>*/}
                                        <p>
                                            <a onClick={this.toggleModals} style={{cursor:'pointer'}} className={"weight-tip-link"}>Need a tip in how to weigh your dog?</a>
                                        </p>


                                        {isButton
                                            ?
                                            <Button
                                                onClick={btnFn}
                                                data-toggle="modal"
                                                data-target={`#${target}`}
                                                classes="button-primary fill red"
                                                label={btnLabel}
                                            />
                                            :
                                            ''
                                        }

                                    </div>
                                    {isImage ?
                                        <div className="col-5 pl-0">
                                            <img src={image} alt="tip-dog-image" className="mw-100" />
                                        </div>
                                        :
                                        ''
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer row mx-0 justify-content-start">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    dogs: state.data.dogs,
    storeDogs: state.data.dogs.storeDogs,
    currentDog: state.data.dogs.currentDog,
    getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
    setDog: dog => dispatch(dogsActionCreators.set(dog)),
    toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
    updateCurrentDog: (key, index, value) =>
        dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WeightTip);
