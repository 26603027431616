import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const textColor = '#151515';

const styles = () => ({
  input: {
    color: textColor,
    fontFamily: 'Open Sans, serif',
    fontSize: '14px',
    letterSpacing: '0.1px',
    '&:before': {
      borderBottom: '#ebebeb 1px solid',
    },
    '&$inputFocused $notchedOutline': {
      borderColor: '#007853',
    },
    '&$inputDisabled': {
      '&:before': {
        borderBottom: '#ebebeb 1px solid',
      },
    },
  },
  label: {
    fontFamily: 'Open Sans, serif',
    fontSize: '14px',
    letterSpacing: '0.1px',
    '&$labelFocused': {
      color: '#007853',
    },
  },
  inputUnderline: {
    '&:after': {
      borderBottomColor: '#007853',
    },
  },
  labelFocused: {},
  inputFocused: {},
  inputDisabled: {},
  notchedOutline: {
    '&$notchedOutline': {
      borderColor: '#ebebeb',
    },
  },
});

const Textfield = (props) => {
  const {
    classes, endAdornment, variant, ...restProps
  } = props;
  const inputClasses = {
    root: classes.input,
    focused: classes.inputFocused,
    disabled: classes.inputDisabled,
  };
  switch (variant) {
    case 'outlined':
      inputClasses.notchedOutline = classes.notchedOutline;
      break;
    case 'standard':
      inputClasses.underline = classes.inputUnderline;
      break;
    case undefined:
      inputClasses.underline = classes.inputUnderline;
      break;
    default:
      break;
  }
  const labelClasses = {
    root: classes.label,
    focused: classes.labelFocused,
  };

  return (
    <TextField
      className={props.short ? 'checkout-textfield promo-input' : 'checkout-textfield'}
      variant={variant || 'standard'}
      InputProps={{
        classes: inputClasses,
        endAdornment,
      }}
      InputLabelProps={{
        classes: labelClasses,
      }}
      {...restProps}
    />
  );
};

Textfield.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Textfield);
