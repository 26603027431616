import React from 'react';
import { Grid } from '@material-ui/core';
import Textfield from '../../Textfield';
import checkedIcon from '../../../assets/images/checked-icon.svg';
import crossedIcon from '../../../assets/images/crossed-icon.png';

const Promocode = ({
  promocode, handleChange, promocodeCheck, promocodeValid, header = 'Promocode', showFitkolarAddedMessage,
}) => (
    <Grid className="each-part border-element">
      <Grid container className="border-bottom">
        <h3 className="header-main">{header}</h3>
      </Grid>
      <Grid container className={header === 'Promocode' ? "border-body" : ""} spacing={16}>
        <Grid item xs={12}>
          <p className="info-text mt-3">Enter code to get great discounts!</p>
        </Grid>
        <Grid item xs={12} className="mb-2">
          <Textfield
            name="promocode"
            label="Promocode"
            short
            fullWidth
            value={promocode}
            onChange={handleChange}
            onBlur={promocodeCheck}
          />
          {
            promocodeValid ?
              <span className="icon animated">
                <img className="promo-tick" src={checkedIcon} alt="" />
              </span>
              :
              <span className="icon animated">
                <img className="promo-cross" src={crossedIcon} alt="" />
              </span>
          }
        </Grid>
        {showFitkolarAddedMessage && <p style={{ fontSize: 10, fontStyle: 'italic' }}>You can use this voucher to have 50% Fitkolar product discount, <strong>we added Fitkolar product to your cart</strong>.</p>}
      </Grid>
    </Grid >
  );

export default Promocode;
