import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    borderRadius: '22px',
    color: theme.palette.primary.main,
    height: '44px',
    padding: '0 45px',
    width: '100%',
  },
});

const OutlinedButton = ({ title, classes, component }) => (
  <Button
    className={`outlined-button ${classes.root}`}
    component={component}
    variant="outlined"
    color="primary"
  >
    {title}
  </Button>
);

OutlinedButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(OutlinedButton);
