import React from 'react';

import { Link } from 'react-router-dom';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import SimplyPowesome from '../components/SimplyPowesome';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';

const offers = [
  {
    title: 'Kasper & Kitty Free FitKolar* Offer with a 3 month Direct Debit.',
    contents: [
      "*If cancelled within 3 months a charge of £39.99 will apply for the FitKolar, to be taken from your payment details provided when signing up",
      "Offer runs from 1 January 2019",
      "This offer (the “FitKolar Offer”), which is made to you by Kasper & Kitty and, as defined in the Kasper\
      & Kitty terms and conditions, entitles you to a free Fitkolar RRP £39.99 when purchasing a 3 month\
      subscription of bespoke Kasper and Kitty food (as defined in the Kasper & Kitty monthly terms and\
      conditions of use) from the moment that you activate such membership of the food subscription\
      offer by submitting your payment details by direct debit and paying the advertised price.",
      "By submitting your payment details, (i) you accept the free FitKolar Offer (RRP £39.99), (ii) consent to\
      us using your payment details in accordance with our privacy policy, (iii) acknowledge and agree to\
      the Kasper & Kitty terms and conditions and these FitKolar Offer terms and conditions.\
      If you decide that you do not want to remain a paying user of the Kasper & Kitty monthly\
      subscription within three months of taking the FitKolar offer, please be advised that you will be\
      charged £39.99 for the FitKolar and you must cancel your subscription to Kasper & Kitty by (i)\
      emailing friends@kasperandkitty.com (ii) calling us on 0800 888 6554, prior to the end of your\
      FitKolar Offer.",
      "Otherwise, at the end of your FitKolar Offer, you will automatically become a monthly paying\
      member of Kasper & Kitty at the regular Kasper & Kitty monthly price which is dependent on your\
      dogs individual bespoke meal plan, as indicated on your account after registration, and the payment\
      method you provided will automatically be charged the Kasper & Kitty subscription fee each month,\
      until you cancel your Kasper & Kitty monthly subscription.",
      "If you wish to cancel your Kasper & Kitty monthly subscription after the end of your FitKolar Offer,\
      you may do so by following the instructions above.",
      "There are no refunds or credits for partial monthly subscriptions.",
      "The offer is open only to persons of 18 years or over at the time of entry who are resident in the UK",
      "If Kasper & Kitty increases or decreases the monthly fee in the future, we will provide you with\
      notice. Price changes will take effect at the start of the next subscription offer following the date of\
      the price change.",
      "By continuing to use Kasper & Kitty monthly after the price change takes effect, you accept the new\
      price.",
      "You may only use this FitKolar Offer once per dog.",
      "If you have subscribed to Kasper & Kitty monthly or have taken a previous free trial offer, you are\
      ineligible for this FitKolar Offer. You may also be ineligible for this FitKolar Offer if you have taken\
      other previous trial offers offered by Kasper & Kitty .",
      "A limited number of FitKolar Offers are available, while supplies last.",
      "This FitKolar Offer expires and must be redeemed before the date advertised.",
      "Kasper & Kitty reserves the right to earlier terminate this FitKolar Offer at any time and for any\
      reason.",
      "After such time of termination, Kasper & Kitty shall not be obligated to redeem any further FitKolar\
      Offers.",
      "Kasper & Kitty reserves the right, in its absolute discretion, to withdraw or to modify this FitKolar\
      Offer and the terms and conditions at any time without prior notice and with no liability.",
      "These terms and conditions will be interpreted in accordance with the laws of England.",
      "Kasper & Kitty will use the personal data provided to administer this offer and for customer service\
      and marketing purposes, subject to the appropriate consents being given. Kasper & Kitty will not\
      pass your data to any third party."
    ],
  },
];

export default class Offer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/cookie-banner.jpg)" heading="Kasper & Kitty Free FitKolar* Offer with a 3 month Direct Debit" subtext="Offer runs from 1 January 2019" />
        <div className="cookies-container">
          {offers.map((cookie, tindex) => (<div key={tindex} className="heading">{`${++tindex}\u00A0.\u00A0\u00A0${cookie.title}`}
            {cookie.contents.map((content, cindex) => (<div key={cindex} className="desc">
              {/* <div className="desc-index">{`${tindex}.${++cindex}`}</div> */}
              <div className="desc-content">{content}</div>
                                                       </div>))}
                                            </div>))}
        </div>
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
