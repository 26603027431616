import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actionTypes from '../../data/exports';
import * as dogsActionCreators from '../../data/dogs/actions';
import WizardLayout from '../../components/wizard/WizardLayout';
import WizardInput from '../../components/wizard/WizardInput';
import Button from '../../components/Button';
import Dropdown from 'react-dropdown';
import * as selectors from '../../data/dogs/selectors';
import HowHeightTip from '../../components/wizard/HowHeightTip';
import HowHeight from '../../assets/images/wizard/tips/how-height.png';

class Height extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isError: false
        };

        this.handleStep = (step, e) => {
            e.preventDefault();
            if (this.props.currentDog.height === 0){
                this.setState({isError: true})
            }else {
            this.props.history.push(step);
            }
        };

        this.handleHeight = (e) => {

            if (this.props.currentDog.height !== 0){
                this.setState({isError: false})
            }

            const height = parseInt(e.target.value * 100) / 100;
            const dogIndex = this.props.getIndex;
            this.props.updateCurrentDog('height', dogIndex, height);
        };

        this.setHeightUnits = (units) => {
            const dogIndex = this.props.getIndex;
            this.props.updateCurrentDog('heightUnits', dogIndex, units.value);
        };
    }

    componentDidMount() {
        const dogIndex = this.props.getIndex;
        const {pathname} = this.props.location;

        if(this.props.currentDog.height === 0){
            this.props.updateCurrentDog('progress', dogIndex, 50);
        }

        this.props.updateCurrentDog('step', dogIndex, pathname);
        this.props.toggleDogNav(true);
    }

    populateEstimate(){
        let height = 18.102 * Math.log(this.props.currentDog.weight) - 2.0484;
        this.props.updateCurrentDog('height', this.props.getIndex, parseFloat(height.toFixed(2)));
    }

    render() {
        const options = ['cm', 'ft', 'in'];
        return (
            <WizardLayout>
                <div className="col-12 row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10 col-12 px-xl-3 px-lg-3 px-md-2 px-sm-1 px-0 row justify-content-center weight-from">
                        <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                            how tall is {this.props.currentDog.name}?
                        </h5>
                        <div className="col-12">
                            <WizardInput
                                autofocus={true}
                                error={this.state.isError}
                                errorMessage={"Please fill this field"}
                                placeholder="eg. 5"
                                value={`${this.props.currentDog.height !== 0 ? this.props.currentDog.height : '' }`}
                                type="number"
                                handleBlur={false}
                                onChangeText={this.handleHeight}
                            />
                            <Dropdown
                                defaultOption={this.props.currentDog.heightUnits}
                                value={this.props.currentDog.heightUnits}
                                options={options}
                                onChange={this.setHeightUnits}
                            />
                        </div>
                        <div className="col-12 px-0 row justify-content-between">
                            <a className={"col-6 text-left underline pop-up-link"} data-toggle="modal" href={"#how-estimate"}>How to measure height?</a>
                            <a className={"col-6 text-right underline pop-up-link"} data-toggle="modal" href={"#"} onClick={e => this.populateEstimate()}>What is your estimate?</a>
                        </div>
                        <div className="col-6 pl-xl-5 pl-lg-3 pl-md-2 pr-1 pt-4 text-center">
                            <Button
                                onClick={e => this.handleStep('/weight', e)}
                                classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                                label="BACK"
                            />
                        </div>
                        <div className="col-6 pr-xl-5 pr-lg-3 pr-md-2 pl-1 pt-4 text-center">
                            <Button
                                onClick={e => this.handleStep('/body-condition', e)}
                                classes="button-primary fill orange animated fadeInRight delay-_2s"
                                label="NEXT"
                            />
                        </div>
                    </div>
                </div>
                <HowHeightTip
                    title={"How to measure height?"}
                    description={""}
                    target="how-estimate"
                    isImage={true}
                    image={HowHeight}
                    isButton={false}
                    btnLabel=""
                />
            </WizardLayout>
        );
    }
}

const mapStateToProps = state => ({
    storeDogs: state.data.dogs.storeDogs,
    currentDog: state.data.dogs.currentDog,
    getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
    updateCurrentDog: (key, index, value) =>
        dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
    toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Height));
