import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dateFns from 'date-fns';

import Button from '../../components/Button';
import WizardLayout from '../../components/wizard/WizardLayout';
import WizardInput from '../../components/wizard/WizardInput';
import Calendar from '../../components/wizard/Calendar';
import Tip from '../../components/wizard/Tip';

import TooOld from '../../assets/images/wizard/tips/age_too_old.png';
import CalendarIcon from '../../assets/images/calendar-icon.png';
import PopUpIcon from '../../assets/images/wizard/health-option-icon.png';
import NursingOrPregnantTip from '../../components/wizard/NursingOrPregnantTip';
import Fb from '../../assets/images/wizard/tips/fb.png';
import Tweet from '../../assets/images/wizard/tips/twitter.png';
import Insta from '../../assets/images/wizard/tips/insta.png';
import In from '../../assets/images/wizard/tips/In.png';

import * as dogsActionCreators from '../../data/dogs/actions';
import * as actionTypes from '../../data/exports';
import * as selectors from '../../data/dogs/selectors';
import * as session from '../../services/session';

const datePattern = /(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\d{4}))|((29)(\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))/;

class Age extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backStep: '',
      isDatepicker: false,
      dateValue: '',
      isError: '',
      dateTip: 'Please use DD/MM/YYYY format',
    };

    this.toggleDatepicker = () => {
      this.setState({ isDatepicker: !this.state.isDatepicker });
    };

    this.handleAgeChange = (e) => {
      const dogIndex = this.props.getIndex;
      const inputTarget = e.target.attributes.name.value;

      this.setState({ isError: '' });

      if (inputTarget === 'year') {
        const inputValue = parseInt(e.target.value, 10);

        this.props.updateCurrentDog('yearAge', dogIndex, Number.isNaN(inputValue) ? 0 : inputValue);
      } else if (inputTarget === 'month') {
        const inputValue = parseInt(e.target.value, 10);

        this.props.updateCurrentDog('months', dogIndex, Number.isNaN(inputValue) ? 0 : inputValue);
      }

      this.calculateBirthday();
    };

    this.handleDobInput = (e) => {
      const dogIndex = this.props.getIndex;
      const date = e.target.value.split('-').reverse().join('/');

      this.setState({ dateValue: date });

      if (datePattern.test(date)) {
        const year = parseInt(date.split('/')[2], 10),
          month = parseInt(date.split('/')[1], 10) - 1,
          day = parseInt(date.split('/')[0], 10);

        const inputedDate = new Date(year, month, day);
        const today = new Date();

        this.setState({ dateTip: '' });
        if (inputedDate > today) {
          this.setState({ isError: 'Please select correct birthday' });
          return;
        }

        if (year >= 1970) {
          const formatDate = dateFns.format(inputedDate, 'YYYY-MM-DD');
          this.props.updateCurrentDog('dob', dogIndex, formatDate);
          this.setAgeFromDatepicker(inputedDate);
          this.setState({ isError: '' })
        }
      } else {
        this.setState({ dateTip: 'Please use DD/MM/YYYY format' })
        this.clearDogDob();
      }

      if (date === '') {
        this.clearDogDob();
      }
    };

    this.calculateBirthday = () => {
      const dogIndex = this.props.getIndex;
      const today = new Date();
      const year = dateFns.subYears(today, this.props.currentDog.yearAge);
      const birthday = dateFns.subMonths(year, this.props.currentDog.months);
      const result = dateFns.format(birthday, 'YYYY-MM-DD');

      this.props.updateCurrentDog('dob', dogIndex, result);
      this.setState({ dateValue: dateFns.format(birthday, 'DD/MM/YYYY') });
    };

    this.handleDateClick = (date) => {
      const dogIndex = this.props.getIndex;
      const formatDate = dateFns.format(date, 'YYYY-MM-DD');
      this.props.updateCurrentDog('dob', dogIndex, formatDate);

      this.setState({ dateValue: dateFns.format(date, 'DD/MM/YYYY') });
      this.setAgeFromDatepicker(date);
      this.toggleDatepicker();
      this.setState({ isError: '' });
    };

    this.setAgeFromDatepicker = (date) => {
      const today = new Date();
      const diff = Math.abs(today - date);
      this.checkAgeFromCalendar(new Date(diff).getFullYear() - 1970, new Date(diff).getMonth());
    };

    this.checkAgeFromCalendar = (year, months) => {
      const dogIndex = this.props.getIndex;

      if (year > 30) {
        $('#old').modal('show');
        this.clearDogDob();
        return false;
      }

      this.props.updateCurrentDog('yearAge', dogIndex, year);

      if (year < 1 && months <= 1) {
        $('#young').modal('show');
        this.clearDogDob();
        return false;
      }

      this.props.updateCurrentDog('months', dogIndex, months);
      return true;
    };

    this.clearDogDob = () => {
      // clear dog age data from store
      const dogIndex = this.props.getIndex;

      this.props.updateCurrentDog('dob', dogIndex, '');
      this.props.updateCurrentDog('yearAge', dogIndex, null);
      this.props.updateCurrentDog('months', dogIndex, null);
    };

    this.handleStep = (step, e) => {
      e.preventDefault();

      if (this.props.currentDog.dob === '') {
        this.setState({ isError: `Please select ${this.props.currentDog.name}'s birthday.` });
      } else if (this.checkAgeFromCalendar(this.props.currentDog.yearAge, this.props.currentDog.months)) {
        this.props.history.push(step);
      }
    };

    this.handleBackStep = (step, e) => {
      e.preventDefault();
      this.props.history.push(step);
    };

    this.handleModal = () => {
      $('#age').modal('show');
    };

    this.checkAge = (e) => {
      const dogIndex = this.props.getIndex;
      const inputTarget = e.target.attributes.name.value;
      const inputValue = parseInt(e.target.value, 10);

      this.setState({ isError: '' });

      // handle modals for years and months inputs
      if (inputTarget === 'year') {
        if (inputValue > 30) {
          $('#old').modal('show');
          this.props.updateCurrentDog('yearAge', dogIndex, null);
        } else {
          this.props.updateCurrentDog('yearAge', dogIndex, inputValue);
          this.props.updateCurrentDog('error', dogIndex, '');
        }
      } else if (inputTarget === 'month') {
        if (this.props.currentDog.yearAge < 1 && inputValue === 1) {
          $('#young').modal('show');
          this.props.updateCurrentDog('months', dogIndex, null);
        } else {
          this.props.updateCurrentDog('months', dogIndex, inputValue);
          this.props.updateCurrentDog('error', dogIndex, '');
        }
      }

      // check null or NaN value for dog age fields
      if (inputTarget === 'year' && Number.isNaN(inputValue)) {
        this.props.updateCurrentDog('yearAge', dogIndex, 0);
      }

      if (inputTarget === 'month' && Number.isNaN(inputValue)) {
        this.props.updateCurrentDog('months', dogIndex, 0);
      }
    };

    this.handleDateModal = () => {
      $('#young').modal('hide');
      $('#NursingOrPregnant').modal('show');
    };

    this.setDogError = (date, name) => {
      const has_puppy = true;
      const datePlusSix = new Date(date.setDate(date.getDate() + 42));
      const puppy_date = `${datePlusSix.getDate()}-${datePlusSix.getMonth() + 1}-${datePlusSix.getFullYear()}`;
      const puppy_name = name;

      session.updateUser({
        puppy_date,
        has_puppy,
        puppy_name,
      }).then(() => {
        const dogIndex = this.props.getIndex;

        this.props.updateCurrentDog('error', dogIndex, 'age');
        $('#NursingOrPregnant').modal('hide');
        this.props.history.push('/add-dog');
      })
        .catch(() => {
          alert('The date was unable to be saved, please try again!');
        });
    };
  }

  componentDidMount() {
    const dogIndex = this.props.getIndex;
    const { pathname } = this.props.location;

    if (this.props.currentDog.dob === '') {
      this.props.updateCurrentDog('progress', dogIndex, 15);
    }

    this.props.updateCurrentDog('step', dogIndex, pathname);
    this.props.toggleDogNav(true);

    if (this.props.currentDog.gender === 'male') {
      this.setState({ backStep: '/neutered' });
    } else if (!this.props.currentDog.spayed) {
      this.setState({ backStep: '/pregnant' });
    } else {
      this.setState({ backStep: '/spayed' });
    }

    // set dob from dog to state
    if (this.props.currentDog.dob !== '') {
      this.setState({ dateValue: dateFns.format(this.props.currentDog.dob, 'DD/MM/YYYY') });
    }
  }

  render() {
    const socialList = () => (
      <ul className="social-list">
        <li className="social-list_item">
          <a href="https://www.facebook.com/kasperandkitty/" target="_blank" className="social-list_item-link">
            <img className="social-list_item-link-icon" src={Fb} alt="" />
          </a>
        </li>
        <li className="social-list_item">
          <a href="https://twitter.com/kasperandkitty?lang=en" target="_blank" className="social-list_item-link">
            <img className="social-list_item-link-icon" src={Tweet} alt="" />
          </a>
        </li>
        <li className="social-list_item">
          <a href="https://www.instagram.com/kasperandkitty/" target="_blank" className="social-list_item-link">
            <img className="social-list_item-link-icon" src={Insta} alt="" />
          </a>
        </li>
        <li className="social-list_item">
          <a href="https://www.linkedin.com/company/kasper-kitty/" target="_blank" className="social-list_item-link">
            <img className="social-list_item-link-icon" src={In} alt="" />
          </a>
        </li>
      </ul>
    );

    return (
      <WizardLayout
        title=""
        wizardProgress={this.props.progress}
      >
        <div className="col-12 row justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-9 col-sm-10 col-12  row pb-4 justify-content-center age-step">
            <h5 className={`w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s ${this.state.isDatepicker ? 'hide' : ''}`}>
              how old is {this.props.currentDog.name}?
              <span
                tabIndex={0}
                role="button"
                onKeyPress={() => { }}
                className="pop-up-link"
                onClick={this.handleModal}
              >
                <a><img className="ml-2 mb-1" src={PopUpIcon} alt="pop-up" /></a>
              </span>
            </h5>

            <h6 className={`w-100  text-center text-uppercase animated fadeInDown delay-_2s ${this.state.isDatepicker ? 'hide' : ''}`}>age</h6>

            <div className={`col-6 pl-0 ${this.state.isDatepicker ? 'hide' : ''}`}>
              <WizardInput
                animation="fadeInLeft delay-_2s"
                type="number"
                value={`${this.props.currentDog.yearAge !== null ? this.props.currentDog.yearAge : ''}`}
                placeholder="Years"
                name="year"
                autofocus
                handleBlur
                onChangeText={e => this.handleAgeChange(e)}
                onFocusOut={e => this.checkAge(e)}
              />
            </div>

            <div className={`col-6 pr-0 ${this.state.isDatepicker ? 'hide' : ''}`}>
              <WizardInput
                animation="fadeInRight delay-_2s"
                type="number"
                value={`${this.props.currentDog.months !== null ? this.props.currentDog.months : ''}`}
                name="month"
                placeholder="Months"
                handleBlur
                onChangeText={e => this.handleAgeChange(e)}
                onFocusOut={e => this.checkAge(e)}
              />
            </div>

            <div className="col-12 pb-3 text-center border-top age-divider">
              <span className="">
                or
              </span>
            </div>

            <div className="col-12">
              <h6 className="w-100 pb-0 mb-0 text-center text-uppercase animated fadeInDown delay-_2s">birthday</h6>
            </div>

            <div
              role="button"
              onKeyPress={() => { }}
              tabIndex={0}
              className={`col-12 px-0 datepicker ${this.state.isDatepicker ? 'enabled' : ''}`}
            >
              <WizardInput
                disabled={false}
                animation="fadeInTop delay-_2s"
                type="date"
                name="dob"
                handleBlur={false}
                addDogInput
                keyTip={this.state.dateTip}
                value={this.state.dateValue}
                placeholder="2013-05-12"
                onChangeText={e => this.handleDobInput(e)}
              />

              <span
                className="calendar-icon"
                onClick={this.toggleDatepicker}
              >
                <img src={CalendarIcon} alt="" />
              </span>
            </div>

            {this.state.isDatepicker ?
              <Calendar
                endDate={new Date()}
                onChange={date => this.handleDateClick(date)}
                clearDob={this.clearDogDob}
                dogDate={this.props.currentDog.dob}
              />
              :
              ''
            }

            {this.state.isError ?
              <div className="animated fadeIn col-12 pt-5 text-center error-message">
                {this.state.isError}
              </div>
              :
              ''
            }

            <div className={`col-6 pl-xl-2 pl-lg-3 pl-md-2 pl-0 pr-1 pt-5 text-center ${this.state.isDatepicker ? 'd-none' : ''}`}>
              <Button
                onClick={e => this.handleBackStep(this.state.backStep, e)}
                classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                label="BACK"
              />
            </div>

            <div className={`col-6 pr-xl-2 pr-lg-3 pr-md-2 pr-0 pl-1 pt-5 text-center ${this.state.isDatepicker ? 'd-none' : ''}`}>
              <Button
                onClick={e => this.handleStep('/breed', e)}
                classes="button-primary fill orange animated fadeInRight delay-_2s"
                label="NEXT"
              />
            </div>
          </div>
        </div>

        <Tip
          title={`${this.props.currentDog.name} is over 30 years old! Congratulations :)`}
          description={`Not to say this isn't great but quite rare. Please double check ${this.props.currentDog.name}'s bday.`}
          target="old"
          isImage
          image={TooOld}
          isButton
          btnLabel="CHANGE"
        />

        <Tip
          title={"We are sorry, we don't cater for puppies under 6 weeks old"}
          description="But we do have some great formulas suited to puppy growth and development as soon as they are ready to eat solid food."
          target="young"
          isImage
          image={TooOld}
          isButton
          btnFn={() => this.handleDateModal()}
          btnLabel="REMIND ME"
        />

        <Tip
          title=""
          description={`Did you know your ${this.props.currentDog.name}’s nutritional needs evolve throughout their life stages? Our bespoke blends fully support your pet from puppy through maturity adapting things such as protein level, oils and other important nutritional factors.`}
          target="age"
          isImage
          image={TooOld}
          isButton={false}
          btnLabel=""
        />

        <NursingOrPregnantTip
          title="Give your puppies the best start with Kasper & Kitty"
          description="We will help you get your puppy weaning in no time with our unique, bespoke blend giving your growing puppy all the essentials nutrients during development. We start supplying Kasper & Kitty from 6 weeks onwards and will let you know when you can start ordering."
          isButton
          target="NursingOrPregnant"
          btnLabel="UPDATE ME"
          btnFn={(date, name) => this.setDogError(date, name)}
        >
          <div className="congrats py-2 col-12 pl-0 pr-0 mr-0">
            Join us for some fun on social media- where we will be sharing upcoming events and giveaways.
          </div>

          <div className="col-12 row justify-content-start tip-social-links pl-0 pr-0 ml-0">
            {socialList()}
          </div>
        </NursingOrPregnantTip>
      </WizardLayout>
    );
  }
}

const mapStateToProps = state => ({
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});

const mapDispatchToProps = dispatch => ({
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Age));
