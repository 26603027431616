import React from 'react';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement, Elements } from 'react-stripe-elements';

import * as ordersActionCreators from '../../data/orders/actions';
import * as sessionSelectors from '../../services/session/selectors';

import Navigation from '../../components/Navigation';
import SubNavigation from '../../components/SubNavigation';
import Row from '../../components/Row';
import FullColumn from '../../components/FullColumn';
import Footer from '../../components/Footer';
import GreyText from '../../components/GreyText';
import Dropdown from '../../components/Dropdown';
import Checkbox from '../../components/Checkbox';
import AccountSidebar from '../../components/AccountSidebar';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';
import FormErrors from '../../components/FormErrors';
import { PayHandler } from "../../services/PayHandler";
import * as session from "../../services/session";
import * as Sentry from "@sentry/browser";
import { Grid } from "@material-ui/core";

const cards = [
  {
    name: 'Visa',
  },
  {
    name: 'Mastercard',
  },
  {
    name: 'American Express',
  },
];

const Radio = ({
  currentState, activeWhenStateIs, label, onClick,
}) => (
  <Checkbox
    active={currentState === activeWhenStateIs}
    onClick={onClick}
    label={label}
  />
);

class CardDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: '',
      cardType: '',
      tandc: false,
      updated: false,
      errors: [],

      paymentLoading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.payHandler = new PayHandler(this.props);
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ paymentLoading: true });

    return this.payHandler.setup({})
    .then(()=>{
      session.updateUser({});
      this.setState({ updated: true, paymentLoading: false });
    })
    .catch((error) => {
      console.log('payment error:', error);
      Sentry.captureException(error);
      this.setState({ paymentLoading: false });
      alert(`An error occured while updating your card.`);
    });
  }

  render() {
    const { errors, paymentLoading } = this.state;
    const { user } = this.props;
    return (
      <div className="container">
        <Navigation
          white
          textColor="white-text"
        />
        <SubNavigation />

        <div className="shop-payment">
          <div className="inner-container">
            <KaushanScriptHeading title={`Update your card details`} />
            {this.state.updated !== true ? (
              <Row>
                <div className="col-md-2">
                  <Row noMarginBoottom>
                    <FullColumn noPaddingBottom>
                      <AccountSidebar />
                    </FullColumn>
                  </Row>
                </div>
                <div className="col-md-5">
                  {!!user.bank_card ? (
                    <Grid container alignItems='center' justify='center'>
                      <Grid item xs={2}>
                        <img
                          src={`/assets/images/card/${user.bank_card.brand}.png`}
                          alt='card-brand'
                          style={{
                            width: '70px',
                            maxWidth: '100%',
                          }}
                        />
                      </Grid>

                      <Grid item xs={10}>
                          <span
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            <strong>{user.bank_card.brand}</strong> ending in{' '}
                            {user.bank_card.last4}
                          </span>
                      </Grid>
                    </Grid>
                  ): ""}
                  <form onSubmit={this.handleSubmit}>
                    <Row noMarginBoottom>
                      <FullColumn noPaddingBottom>
                        <GreyText
                          text="Payment method"
                          fontSize={20}
                          marginBottom
                        />
                      </FullColumn>
                    </Row>
                    <Row>
                      <div className="col-12 no-padding">
                        <Row>
                          <div className="col-12 col-lg-4">
                            <Radio
                              currentState={this.state.paymentMethod}
                              activeWhenStateIs="Credit card"
                              label="Credit card"
                              onClick={() => this.setState({ paymentMethod: 'Credit card' })}
                            />
                          </div>
                          <div className="col-12 col-lg-4">
                            <Radio
                              currentState={this.state.paymentMethod}
                              activeWhenStateIs="Debit card"
                              label="Debit card"
                              onClick={() => this.setState({ paymentMethod: 'Debit card' })}
                            />
                          </div>
                          <div className="col-12 col-lg-4" />
                        </Row>
                      </div>
                    </Row>
                    <FormErrors errors={errors} />
                    <Row>
                      <FullColumn noPaddingBottom>
                        <Dropdown
                          activeOption={this.state.cardType}
                          onChangeOption={event => this.setState({ cardType: event.value })}
                          options={cards}
                          placeholder="Card type"
                        />
                      </FullColumn>
                    </Row>
                    <Row>
                      <FullColumn noPaddingBottom>
                        <CardNumberElement id="cardNumber"
                          className="input-field"
                          placeholder="Card number"
                          style={{
                            base: {
                              fontSize: '18px',
                              fontWeight: 700,
                            },
                          }}
                        />
                      </FullColumn>
                    </Row>
                    <Row>
                      <FullColumn noPaddingBottom>
                        <CardExpiryElement
                          className="input-field"
                          placeholder="MM/YY"
                          style={{
                            base: {
                              fontSize: '18px',
                              fontWeight: 700,
                            },
                          }}
                        />
                      </FullColumn>
                    </Row>
                    <Row>
                      <FullColumn noPaddingBottom>
                        <CardCVCElement
                          className="input-field"
                          placeholder="CVC"
                          style={{
                            base: {
                              fontSize: '18px',
                              fontWeight: 700,
                            },
                          }}
                        />
                      </FullColumn>
                    </Row>
                    <Row>
                      <div className="col" />
                    </Row>
                    <Row>
                      <FullColumn noPaddingBottom>
                        <Checkbox
                          active={this.state.tandc}
                          onClick={() => this.setState({ tandc: !this.state.tandc })}
                          label="I agree to the Kasper &amp; Kitty Terms &amp; Conditions"
                        />
                      </FullColumn>
                    </Row>
                    {paymentLoading ?
                      (
                        <Row>
                          <ReactLoading type="spinningBubbles" color="grey" height={100} width={100} />
                        </Row>
                      )
                      :
                      (
                        <Row>
                          <FullColumn>
                            <button
                              type="submit"
                              className="sign-up-button"
                              disabled={paymentLoading}
                            >
                              Save
                            </button>
                          </FullColumn>
                        </Row>
                      )
                    }
                    <Row>
                      <FullColumn>
                        <p className="grey-text"><span className="bold">Note:</span> When paying by credit or debit card, no payment will be taken from your account until we have dispatched your order.</p>
                      </FullColumn>
                    </Row>
                  </form>
                  </div>
                  <div className="col-md-1" />
              </Row>
            ) : (
              <div>
                <Row>
                  <div className="col-md-4">
                    <AccountSidebar />
                  </div>
                  <div className="col-md-1"/>
                  <div className="col-md-5">
                    <GreyText
                      text="Your card details have been successfully updated!"
                      fontSize={18}
                      centered
                      marginBottom
                    />
                  </div>
                  <div className="col-md-4"/>
                </Row>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const PaymentWrapped = connect(() => ({
  user: sessionSelectors.getUser(),
}), dispatch => ({
  actions: {
    orders: bindActionCreators(ordersActionCreators, dispatch),
  },
}), null, { forwardRef: true })(withRouter(injectStripe(CardDetails)));

export default ({ props }) => (
  <Elements>
    <PaymentWrapped {...props} />
  </Elements>
);
