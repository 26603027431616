import React from 'react';

export default class CommonImageBanner extends React.Component {
  render() {
    const { image, heading, subtext } = this.props;

    return (
      <div className="common-image-banner">
        <div className="image" style={{ backgroundImage: image }} />
        <div className="text-container">
          <p className="heading">{heading}</p>
          <p className="subtext">{subtext}</p>
        </div>
      </div>
    );
  }
}
