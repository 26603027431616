import React from 'react';

export default class SignUpLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
    };
  }
  render() {
    return (
      <div className="col-xl-7 col-lg-7 col-md-7 p-0 banner d-xl-block d-lg-block d-md-block d-sm-block d-none">
        <div className={`banner-container ${this.props.bannerColor}`}>
            <div className="image-container">
                <img src={this.props.wordImage} alt="" className="word desktop animated fadeInRight" />
                <img src={this.props.tabletWord} alt="" className="word tablet animated fadeInRight" />
                <img src={this.props.dogImage} alt="" className="dog animated fadeInUp" />
            </div>
        </div>
      </div>
    );
  }
}
