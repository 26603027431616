import React from 'react';
import { withRouter } from 'react-router-dom';
import ForgotPasswordCard from '../../components/ForgotPasswordCard';
import FallingGif from '../../assets/images/wizard/IngredientsFalling_Alpha_1080.gif';
import Big from '../../assets/images/order-confirmed/order-confirmed-bg-big.png';
import Medium from '../../assets/images/order-confirmed/order-confirmed-bg-md.png';
import Small from '../../assets/images/order-confirmed/order-confirmed-bg-sm.png';
import Cloud from '../../assets/images/order-confirmed/tablet-cloud.svg';

class OrderConfirmed extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return  (
      <div className="container-fluid px-0 order-confirmed-container">
        <div className="background h-100">
          <img className="sm-image" src={Small} alt="" />
          <img className="md-image" src={Medium} alt="" />
          <img className="lg-image" src={Big} alt="" />
          <img className="cloud-image" src={Cloud} alt="" />
        </div>
        <div className="row justify-content-xl-around justify-content-lg-around justify-content-md-center justify-content-sm-center justify-content-center h-100 align-items-center order-confirmed-items">
          <div className="bg-white col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-xl-right text-lg-right text-md-center text-sm-center text-center mt-xl-0 mt-lg-0 mt-md-5 mt-sm-5 mt-5 py-4 forgot-card-container ">
            <ForgotPasswordCard
              title={"we won't be long ;) we are fetching your recipe now"}
            >
              <p className="description px-4 py-1 animated fadeInUp delay-_5s">
                Thank you for registering with Kasper & Kitty. You&apos;re now one step closer in providing
                your dog a healthy balanced diet suited just to his/her nutritional needs.
              </p>
              <p className="description px-4 py-1 animated fadeInUp delay-_5s">
                Now excuse us while we go fetch your bespoke menu from the many thousands of recipe
                options
              </p>
            </ForgotPasswordCard>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-xl-right text-lg-right text-md-center text-sm-center text-center">
            <img className="falling-ingredients" src={FallingGif} alt="" />
          </div>
        </div>
        {setTimeout(() => this.props.history.push('/cart'), 5000)}
      </div>
    );
  }

} 

export default withRouter(OrderConfirmed);
