import React from 'react';
import { withRouter } from 'react-router-dom';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';
import KasperDog from '../components/KasperDog';
import FoodCard from '../components/FoodCard';
import ActivityCarousel from '../components/ActivityCarousel';

const kasperDogs = [
  {
    image: '/assets/images/yellow-dog-sedentry.png',
    text: 'Sedentary',
  },
  {
    image: '/assets/images/yellow-dog-lazy.png',
    text: 'Lazy',
  },
  {
    image: '/assets/images/yellow-dog-playful.png',
    text: 'Playful',
  },
  {
    image: '/assets/images/grey-dog-turbo-paws.png',
    text: 'Turbo paws',
  },
  {
    image: '/assets/images/yellow-dog-olympic.png',
    text: 'Olympic',
  },
];

const protein_foods = [
  {
    image: '/assets/images/chicken-icon.png',
    subj: 'Chicken',
    text: 'Chicken is rich in omega 6 fatty acids, which help promote a healthy skin and shiny coat, as well as glucosamine and amino acids, essential for bone health.',
  },
  {
    image: '/assets/images/turkey.png',
    subj: 'Turkey',
    text: 'Turkey\'s proteins are a good source of essential amino acids to help with cell growth and repair.',
  },
  {
    image: '/assets/images/lamb-icon.png',
    subj: 'Lamb',
    text: 'Lamb is a tasty source of lean and nutrient-rich proteins packed with essential amino acids and omega 3 fatty acids.',
  },
  {
    image: '/assets/images/fish-icon.png',
    subj: 'White fish',
    text: 'White fish is a source of protein with one of the highest amount of amino acids in a food. Rich in omega 3 essential for your dog\'s overall health and their skin.',
  },
];

const woofful_foods = [
  {
    image: '/assets/images/broth.png',
    subj: 'Broth',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/carrots.png',
    subj: 'Carrots',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/chicory-icon.png',
    subj: 'Chicory Emulsion',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/parsley-icon.png',
    subj: 'Parsley',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/peas-icon.png',
    subj: 'Peas',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/rosemary-icon.png',
    subj: 'Rosemary',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/yucca-icon.png',
    subj: 'Yucca',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
];

const carbohydrates_foods = [
  {
    image: '/assets/images/brown-rice-icon.png',
    subj: 'Brown Rice',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/long-rice-icon.png',
    subj: 'Long Grain Rice',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/grain.png',
    subj: 'Barley',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/potato-icon.png',
    subj: 'Potatoes',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
];

const fatty_foods = [
  {
    image: '/assets/images/fish.png',
    subj: 'Fish Oil',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/calories-container.png',
    subj: 'Omega 3',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/calories-container.png',
    subj: 'Omega 6',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
  {
    image: '/assets/images/calories-container.png',
    subj: 'Ingredient',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nost.',
  },
];

class MoreThanFood2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { windowWidth: window.innerWidth };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner
          image="url(/assets/images/mtf2-banner.jpg)"
          heading="Dry food"
          subtext="bespoke dry food"
        />
        <div className="lifestyle-container">
          <h1 className="mb-1">Bespoke food for your dog's lifestyle</h1>
          <h6 className="color-orange fw-bold mb-4">As unique as they are!</h6>
          <div className="row mb-3">
            <div className="col-md-1" />
            <ActivityCarousel />
            <div className="col-md-1" />
          </div>
          <div className="row">
            <div className="col-1" />
            <div className="col-md-10 mr-5 ml-5">
              <p>Expertly blended and portioned especially for your dog to suit criteria such as their age, gender, real time activity levels, lifestyle and nutritional requirements, their personal calorie-controlled dry food meals work with the K&amp;K App to promote healthy weight and overall wellbeing.</p>
            </div>
            <div className="col-1" />
          </div>
        </div>
        <div className="deliver-container">
          <h1 className="mb-3">We deliver</h1>
          <h6 className="fw-bold color-orange mb-3 mr-3 ml-3">a complete, healthy, calorie controlled diet for every stage of your dog's life</h6>
          <p className="mb-3">with a choice of four menus of carefully sourced premium ingredients each specially blended for life stages from puppy to senior dogs</p>
          <div className="row mt-5 pt-4 mb-4 mr-3 ml-3">
            <div className="col-md-6">
              <div className="deliver-item float-left">
                <img src="/assets/images/lamb-icon.png" />
                <h3 className="color-orange font-script">Really meaty with lamb</h3>
              </div>
              <div className="deliver-item float-left">
                <img src="/assets/images/fish-icon.png" />
                <h3 className="color-orange font-script">Cereal-free with white fish</h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="deliver-item float-left">
                <img src="/assets/images/turkey.png" />
                <h3 className="color-orange font-script">Grain-free with turkey</h3>
              </div>
              <div className="deliver-item float-left">
                <img src="/assets/images/chicken-icon.png" />
                <h3 className="color-orange font-script">Premium with chicken</h3>
              </div>
            </div>
          </div>
          <div className="mr-3 ml-3">
            <p className="mb-3">We create your dog's unique blend. So you can monitor your dog's health and see how their wellbeing improves.<br/></p>
            <p>Our vet is here to help too with advice to help your pet transition gradually from his current diet to Kasper &amp; Kitty's.</p>
          </div>
          </div>
        <div className="premium-container">
          <div className="row">
            <div className="col-2" />
            <div className="col-md-8">
              <h1 className="mb-2 mr-3 ml-3">Premium ingredients for your fitter, trimmer, happier dog</h1>
              <div className="px-5">
                <h6 className="color-orange fw-bold mb-3">Your pet's bespoke calorie-controlled meals feature the optimal blend of highest quality ingredients - everyday.</h6>
                <p className="mb-2">Like single source high quality real meat packed with antioxidants and omega fatty acids.</p>
                <p className="mb-3">They promote your dog's digestive, bone and joint health. And a shiny coat.</p>
                <p>All you need to do is let them enjoy their dinner!</p>
              </div>
            </div>
            <div className="col-2" />
          </div>
        </div>
        <div className="proteins-container">
          <h1 className="text-center mb-5">Proteins</h1>
          <div className="row mr-3 ml-3">
            <div className="col-md-2" />
            {protein_foods.map((food, index) => (
              <div key={index} className="col-6 col-md-2">
                <FoodCard image={food.image} subj={food.subj} text={food.text} />
              </div>
            ))}
            <div className="col-md-2" />
          </div>
        </div>
        <div className="woofful-container">
          <h1 className="mb-4">Woofful Ingredients</h1>
          <div className="row justify-content-center">
            {woofful_foods.map((food, index) => (
              <div key={index} className="col-6 col-md-3 col-xl">
                <FoodCard image={food.image} subj={food.subj} text={food.text} />
              </div>
            ))}
          </div>
        </div>
        <div className="carbohydrates-container">
          <h1 className="mb-5">Carbohydrates</h1>
          <div className="row">
            <div className="col-0 col-lg-2" />
            {carbohydrates_foods.map((food, index) => (
              <div key={index} className="col-6 col-md-3 col-lg-2">
                <FoodCard image={food.image} subj={food.subj} text={food.text} />
              </div>
            ))}
            <div className="col-0 col-lg-2" />
          </div>
        </div>
        <div className="fatty-container">
          <h1 className="mb-5">Good fatty acids</h1>
          <div className="row">
            <div className="col-0 col-lg-2" />
            {fatty_foods.map((food, index) => (<div key={index} className=" col-6 col-md-3 col-lg-2">
              <FoodCard image={food.image} subj={food.subj} text={food.text} />
            </div>))}
            <div className="col-0 col-lg-2" />
          </div>
        </div>
        <div className="advice-container">
          <div className="row justify-content-center mr-53 ml-3">
            <div className="col-0 col-md-2" />
            <div className="col-12 col-md-5">
              <h2 className="color-orange fw-bold">Vet's advice</h2>
              <h6>We'd recommend you make changes to your dog's diet gradually, by mixing increasing amounts of K&K in with decreasing amounts of his old brand. This will reduce the chances of any stomach upsets so that he can start feeling healthier and happier straight away!</h6>
            </div>
            <div className="col-5 col-md-3">
              <img src="/assets/images/kasper-bowl.png" />
            </div>
            <div className="col-md-2" />
          </div>
        </div>
        <div className="row wtl-container text-center">
          <div className="col-3" />
          <div className="col-6">
            <h3 className="fw-bold mb-2">Woofness they'll love</h3>
            <h6 className="color-orange fw-bold lh-5">Treat your four-legged friend to a K&amp;K bespoke tasty meal plan for a healthier life!</h6>
            <button
              onClick={() => {
                scrollTo(0,0);
                this.props.history.push('/login');
              }}
              type="button"
              className="btn btn-grey"
            >Register</button>
          </div>
          <div className="col-3" />
        </div>
        {/* <div className="woofness-container">
          <div className="row justify-content-center">
            <div className="col-0 col-md-2" />
            <div className="col-12 col-md-5">
              <h2 className="fw-bold">Woofness they'll love</h2>
              <h6 className="color-orange fw-bold mb-2">Treat your four-legged friend to a K&amp;K bespoke tasty meal plan for a healthier life!</h6>
            </div>
            <div className="col-8 col-sm-5 col-md-3">
              <button
                onClick={() => {
                  scrollTo(0,0);
                  this.props.history.push('/login');
                }}
                type="button"
                className="btn btn-grey"
              >Register</button>
            </div>
            <div className="col-md-2" />
          </div>
        </div> */}
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}

export default withRouter(MoreThanFood2);
