import React from 'react';

export default class KaushanScriptHeading extends React.Component {
  render() {
    const { title } = this.props;
    return (
      <p className="kaushanscript-heading">{title}</p> 
    );
  }
}
