import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as dogsActionCreators from '../../data/dogs/actions';
import Tip from '../../components/wizard/Tip';
import TipImage from '../../assets/images/wizard/tips/pregnant_tip.png';
import Fb from '../../assets/images/wizard/tips/fb.png';
import Tweet from '../../assets/images/wizard/tips/twitter.png';
import Insta from '../../assets/images/wizard/tips/insta.png';
import In from '../../assets/images/wizard/tips/In.png';
import WizardLayout from '../../components/wizard/WizardLayout';
import Button from '../../components/Button';
import RadioInput from '../../components/wizard/RadioInput';
import * as actionTypes from '../../data/exports';
import * as selectors from '../../data/dogs/selectors';
import NursingOrPregnantTip from '../../components/wizard/NursingOrPregnantTip';
import FormControl from '@material-ui/core/es/FormControl/FormControl';
import RadioGroup from '@material-ui/core/es/RadioGroup/RadioGroup';
import FormControlLabel from '@material-ui/core/es/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/es/Radio/Radio';
import * as session from '../../services/session';

class Pregnant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dog: this.props.currentDog,
    };

    this.handleStep = (step, e) => {
      e.preventDefault();

      if (this.props.currentDog.pregnant) {
        $('#pregnant').modal('show');
      } else {
        this.props.history.push(step);
      }
    };

    this.handleBackStep = (step, e) => {
      e.preventDefault();
      this.props.history.push(step);
    };

    this.handleRadioInput = (e) => {
      const dogIndex = this.props.getIndex;
      this.props.updateCurrentDog('pregnant', dogIndex, Boolean(Number(e.target.value)));
      if ((Number(e.target.value))) {
        $('#pregnant').modal('show');
      } else {
        this.props.updateCurrentDog('error', dogIndex, '');
        this.props.history.push('/age');
      }
    };

    this.toggleDateTip = () => {
      $('#pregnant').modal('hide');
      $('#NursingOrPregnant').modal('show');
    };

    this.setErrorToDog = (date, name) => {
      const pregnant_dog = true;
      const pregnant_date = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      const pregnant_dog_name = name;

      session.updateUser({
        pregnant_date,
        pregnant_dog,
        pregnant_dog_name,
      }).then(() => {
        const dogIndex = this.props.getIndex;
        this.props.updateCurrentDog('error', dogIndex, 'pregnant');
        this.props.history.push('/add-dog');
      })
        .catch(() => {
          alert('The date was unable to be saved, please try again!');
        });
    };
  }

  componentDidMount() {
    const dogIndex = this.props.getIndex;
    if (this.props.currentDog.pregnant === null) {
      this.props.updateCurrentDog('progress', dogIndex, 8);
    }
    const { pathname } = this.props.location;
    this.props.updateCurrentDog('step', dogIndex, pathname);
    this.props.toggleDogNav(true);
  }

  render() {
    const socialList = () => (
      <ul className="social-list">
        <li className="social-list_item">
          <a href="https://www.facebook.com/kasperandkitty/" target="_blank" className="social-list_item-link">
            <img className="social-list_item-link-icon" src={Fb} alt="" />
          </a>
        </li>
        <li className="social-list_item">
          <a href="https://twitter.com/kasperandkitty?lang=en" target="_blank" className="social-list_item-link">
            <img className="social-list_item-link-icon" src={Tweet} alt="" />
          </a>
        </li>
        <li className="social-list_item">
          <a href="https://www.instagram.com/kasperandkitty/" target="_blank" className="social-list_item-link">
            <img className="social-list_item-link-icon" src={Insta} alt="" />
          </a>
        </li>
        <li className="social-list_item">
          <a href="https://www.linkedin.com/company/kasper-kitty/" target="_blank" className="social-list_item-link">
            <img className="social-list_item-link-icon" src={In} alt="" />
          </a>
        </li>
      </ul>
    );
    const radioStyles = {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    };
    return (
      <WizardLayout
        title=""
        wizardProgress={this.props.progress}
      >
        <form className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 row pb-4 justify-content-center px-5">
          <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                        is {this.props.currentDog.name} nursing or pregnant?
          </h5>
          <FormControl component="fieldset" className="material-radio-buttons" style={{ width: '65%' }}>
            <RadioGroup
              style={radioStyles}
              aria-label="pregnant"
              name="pregnant"
              value={this.props.currentDog.pregnant !== null ? this.props.currentDog.pregnant ? '1' : '0' : null}
              onChange={this.handleRadioInput}
            >
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label="Yes"
                labelPlacement="end"
              />
              <FormControlLabel
                value="0"
                control={<Radio color="primary" />}
                label="No"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </form>
        <div style={{ maxWidth: '390px' }} className="col-xl-11 col-lg-11 col-md-12 col-12 px-2 row justify-content-center">
          <div className="col-6 pl-xl-4 pl-lg-4 pl-md-3 pr-1 text-center">
            <Button
              onClick={e => this.handleBackStep('/spayed', e)}
              classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
              label="BACK"
            />
          </div>
          <div className="col-6 pr-xl-4 pr-lg-4 pr-md-3 pl-1 text-center">
            <Button
              disabled={this.props.currentDog.pregnant === null}
              onClick={e => this.handleStep('/age', e)}
              classes="button-primary fill orange animated fadeInRight delay-_2s"
              label="NEXT"
            />
          </div>
        </div>

        <NursingOrPregnantTip
          title="Give your puppies the best start with Kasper & Kitty"
          description="We will help you get your puppy weaning in no time with our unique, bespoke blend giving your growing puppy all the essentials nutrients during development. We start supplying Kasper & Kitty from 6 weeks onwards and will let you know when you can start ordering."
          isButton
          target="NursingOrPregnant"
          btnLabel="UPDATE ME"
          btnFn={(date, name) => this.setErrorToDog(date, name)}
        >
          <div className="congrats py-2 col-12 pl-0 pr-0 mr-0">
            Join us for some fun on social media- where we will be sharing upcoming events and giveaways.
          </div>
          <div className="col-12 row justify-content-start tip-social-links pl-0 pr-0 ml-0">
            {socialList()}
          </div>
        </NursingOrPregnantTip>

        <Tip
          title={'We are sorry, we don\'t cater for pregnant or nursing dogs yet'}
          description="But we do have some great formulas suited to puppy growth and development!"
          target="pregnant"
          isImage
          image={TipImage}
          isButton
          btnLabel="UPDATE ME"
          btnFn={() => this.toggleDateTip()}
        >
          <div className="congrats py-2 col-12 pl-0 pr-0 mr-0">
            Join us for some fun on social media- where we will be sharing upcoming events and giveaways.
          </div>
          <div className="col-12 row justify-content-start tip-social-links pl-0 pr-0 ml-0">
            {socialList()}
          </div>
        </Tip>
      </WizardLayout>
    );
  }
}

const mapStateToProps = state => ({
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pregnant));
