import React, { Component } from 'react';

export default class ImperialCheckbox extends Component {
  render() {
    const { data, onClick, active } = this.props;
    return (
      <div className="check-group-container">
        <a
          className="check-group-item"
          onClick={(e) => {
            e.preventDefault();
            onClick(data);
          }}
        >
          <div className="check">
            <div className={`check-inner${active ? '' : ' active'}`} />
          </div>
          <p>{data}</p>
        </a>
      </div>
    );
  }
}
