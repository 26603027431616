import React from 'react';
import { Grid } from '@material-ui/core';
import CrossIcon from '../icons/CrossIcon';

const ProductDescription = ({ item, closeModal }) => (
  <div className="mui-modal product-modal">
    <CrossIcon clickHandler={closeModal} classes="cross-modal" />
    <Grid container direction="column" className="px-4 pt-3">
      <Grid item>
        <img className="product-image" src={window.location.protocol + item.image.Url} alt={item.name} />
      </Grid>
      <Grid item>
        <h3 className="product-name">{item.name}</h3>
      </Grid>
      <Grid item className="pb-3">
        <p className="product-description">{item.description}</p>
      </Grid>
    </Grid>
  </div>
);

export default ProductDescription;
