import React from 'react';

export default class HalfColumn extends React.Component {
  render() {
    const {
      border, centerVertically, centerHorizontally, noPaddingBottom,
    } = this.props;
    return (
      <div className={`${(border ? ' orange-border-left ' : '') + (centerVertically ? ' align-self-center ' : '') + (centerHorizontally ? ' offset-0 offset-md-3 ' : '') + (noPaddingBottom ? ' no-padding-bottom ' : '')}col-12 col-md-6 padding`}>
        {this.props.children}
      </div>
    );
  }
}
