import React from 'react';
import SignUpLayout from '../../components/SignUpLayout';

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    // will be automatically filled in promocode field
    const autoPromo = query.get("promo");

    localStorage.clear()
    if (autoPromo) {
      localStorage.setItem("autoPromo", autoPromo);
    }
  }

  render() {
    return (
      <div className="container-fluid p-0 m-0">
        <SignUpLayout
            bannerColor={"sign-up"}
        />
      </div>
    );
  }
}
