import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CheckboxNew from '../../components/CheckboxNew';
import * as dogsSelectors from '../../data/dogs/selectors';

import CloseIcon from '../../assets/images/wizard/review-close.png';

class BodyCondition extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const dogName = dogsSelectors.getCurrentDog().name;
    return (
      <div
        className="modal animated fadeIn body-condition-modal"
        id="body-condition"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered row justify-content-center"
          role="document">
          <div className="modal-content">
            <div className="modal-header px-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Body Condition
              </h5>
              <button
                type="button"
                className="close text-right"
                onClick={this.props.cancelStep}
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">
                  <img src={CloseIcon} alt="" />
                </span>
              </button>
            </div>

            <div className="modal-body px-0">
              <div className="physical">
                <div className="physical--weight">
                  <div className="label">Weight</div>
                  <div>
                  </div>
                </div>
                <div className="physical--height">
                  <div className="label">Height</div>
                  <div>
                  </div>
                </div>
              </div>
              <div>
                <div className="label">Body Condition</div>
                <div></div>
              </div>
              <div>
                <div className="label">Activity Level</div>
                <div></div>
              </div>
            </div>

            <div className="modal-footer">
              <button className="cancel-btn" onClick={this.props.cancelStep}>
                Cancel
              </button>
              <button className="next-btn" onClick={this.nextStep}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questionnaire: state.data.orders.questionnaire,
});

const mapDispatchToProps = (dispatch) => ({
  updateQuestionnaire: (payload) =>
    dispatch(ordersActionCreators.updateQuestionnaire(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BodyCondition));
