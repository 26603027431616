import React from 'react';

import Row from '../components/Row';
import FullColumn from '../components/FullColumn';

export default class RegistrationStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      steps: [],
    };
  }

  componentDidMount() {
    const count = this.props.stepCount;
    const list = [];

    for (let i = 0; i < count; i++) {
      list.push(i + 1);
    }

    this.setState({ steps: list });
  }

  render() {
    const { activeStep } = this.props;
    return (
      <Row>
        <FullColumn>
          <div className="line" />
          <Row>
            <FullColumn>
              <div className="row padding justify-content-between align-items-center">
                {this.state.steps.map(item => <div key={item} className={`${(item === activeStep ? ' active ' : '') + (item < activeStep ? ' complete ' : '')}step`} />)}
              </div>
            </FullColumn>
          </Row>
        </FullColumn>
      </Row>
    );
  }
}
