import React from 'react';

// Returns true if option is in values
const isChecked = (option, values) => values.filter(item => item.ID === option.ID).length > 0;
const addOption = (option, values) => [...values, option];
const removeOption = (option, values) => values.filter(item => item.ID !== option.ID);

const CheckboxGroup = ({
  options, onChange, value, component: Component,
}) =>
  options.map(option => (
    <Component
      key={option.ID}
      option={option}
      onClick={(e) => {
        e.preventDefault();

        if (isChecked(option, value) === true) {
          onChange(removeOption(option, value));
        } else {
          onChange(addOption(option, value));
        }
      }}
      onChange={onChange}
      checked={isChecked(option, value)}
    />
  ));

export default CheckboxGroup;
