import React from 'react';

import Navigation from '../../components/Navigation';
import MainImageBanner from '../../components/MainImageBanner';
import InstagramCarousel from '../../components/InstagramCarousel';
import Pdsa from '../../components/Pdsa';
import Footer from '../../components/Footer';

export default class ComingSoon extends React.Component {
  render() {
    return (
      <div className="container">
        <Navigation
          absolute
          white
          textColor="white-text"
        />
        <MainImageBanner
          image="url(/assets/images/your-account.jpg)"
          heading="Your Account"
          subtext="Fitkolar settings"
        />
        <SubNavigation />

        <div className="account-container">
        <KaushanScriptHeading title={'Your account'} />
        </div>

        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
