import React from 'react';

export default class CheckboxInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkbox: props.active,
    };
  }
  render() {
    return (
      <div
        className={`checkbox-input-container ${this.props.color}`}
        onClick={this.props.onClick}
      >
        <input
          type="checkbox"
          defaultChecked={this.state.checkbox}
          onChange={() => this.setState({ checkbox: !this.state.checkbox })}
        />
        <span className="label" />
      </div>
    );
  }
}
