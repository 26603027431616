import React from 'react';
import ReactLoading from 'react-loading';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';

import Row from '../../components/Row';
import HalfColumn from '../../components/HalfColumn';
import FullColumn from '../../components/FullColumn';
import GreyText from '../../components/GreyText';
import Checkbox from '../../components/Checkbox';
import Dropdown from '../../components/Dropdown';
import * as ordersActionCreators from '../../data/orders/actions';
import * as sessionSelectors from '../../services/session/selectors';

import * as orderApi from '../../data/orders/index';
import FormErrors from '../../components/FormErrors';

const cards = [
  { name: 'Visa' },
  { name: 'Mastercard' },
  { name: 'American Express' },
];


const Stat = ({ label, value }) => (
  <p className="grey-text"><span className="bold">{label}:</span> {value}</p>
);

const Radio = ({
  currentState, activeWhenStateIs, label, onClick,
}) => (
  <Checkbox
    active={currentState === activeWhenStateIs}
    onClick={onClick}
    label={label}
  />
);

let numberFlag;
let dataFlag;
let csvFlag;

class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order_id: '',
      paymentMethod: '',
      cardType: -1,
      cardNumber: '',
      cardMonth: '',
      cardYear: '',
      csv: '',
      tandc: false,
      Payment: undefined,
      firstName: '',
      surname: '',
      billingPostcode: '',
      billingCountry: '',
      billingAddress1: '',
      billingAddress2: '',
      billingTownCity: '',
      billingCounty: '',
      mobileNumber: '',
      landline: '',
      email: '',
      redirectToNextStep: false,
      alreadyPaid: false,
      errors: [],
      submitting: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCardMonth = this.handleChangeCardMonth.bind(this);
  }

  componentDidMount() {
    this.setState({ order_id: this.props.stepsState.orderID }, () => this.checkOrder(this.state.order_id));

    if (this.props.stepsState.Payment) {
      this.setState({
        Payment: this.props.stepsState.Payment,
        paymentMethod: this.props.stepsState.paymentMethod,
        cardType: this.props.stepsState.cardType,
        cardNumber: this.props.stepsState.cardNumber,
        cardMonth: this.props.stepsState.cardMonth,
        cardYear: this.props.stepsState.cardYear,
        tandc: this.props.stepsState.tandc,
      });
    }

    this.setState({
      firstName: this.props.stepsState.aboutYoufirstName,
      surname: this.props.stepsState.aboutYousurname,
      billingPostcode: this.props.stepsState.aboutYoubillingPostcode,
      billingCountry: this.props.stepsState.aboutYoubillingCountry,
      billingAddress1: this.props.stepsState.aboutYoubillingAddress1,
      billingAddress2: this.props.stepsState.aboutYoubillingAddress2,
      billingTownCity: this.props.stepsState.aboutYoubillingTownCity,
      billingCounty: this.props.stepsState.aboutYoubillingCounty,
      mobileNumber: this.props.stepsState.aboutYoumobileNumber,
      landline: this.props.stepsState.aboutYoulandline,
      email: this.props.stepsState.aboutYouemail,
    });
  }

  onNext() {
    return new Promise((resolve, reject) => {
      this.props.onUpdateState({
        Payment: true,
        paymentMethod: this.state.paymentMethod,
        cardType: this.state.cardType,
        // cardNumber: this.state.cardNumber,
        // cardMonth: this.state.cardMonth,
        // cardYear: this.state.cardYear,
        tandc: this.state.tandc,
      });
      resolve();
    });
  }

  checkOrder(id) {
    orderApi.getOrder(id)
      .then((order) => {
        if (order.awaiting_payment === false) {
          this.setState({ alreadyPaid: true });
        }
      })
      .catch((err) => {
        console.log('An error occured. ', err);
      });
  }

  handleChangeCardMonth(e) {
    const month = e.target.value;
    if (month > 12) {
      const errors = ['Please input valid Exp month.'];
      this.setState({ errors });
    } else {
      this.setState({ errors: [], cardMonth: month });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitting: true });

    const { user } = this.props;

    console.log(`${user.first_name} ${user.surname}`);
    // Generate card token
    this.props.stripe.createToken({ name: `${user.first_name} ${user.surname}` })
      .then((r) => {
        if (r.error) {
          alert(r.error.message);
          this.setState({ submitting: false });
        } else {
          const payload = {
            order_id: this.state.order_id,
            stripe_token: r.token.id,
          };
          this.props.actions.orders.orderpay(payload)
            .then((r) => {
              if (r.error) {
                alert('An error occured while processing your payment: ' + r.error.message);
                this.setState({ submitting: false });
              }
              else{
                this.setState({ redirectToNextStep: true , submitting: false});               
              }
            })
            .catch((err) => {
              window.alert('An error occured while processing your payment.');
              this.setState({ submitting: false });
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    /*
    const errorStyle = {
      display: 'none',
      width: '100%',
      height: '100%',
    };

    const errorTypeStyle = {
      display: 'none',
      width: '100%',
      height: '100%',
    };

    const errorCSVStyle = {
      display: 'none',
      width: '100%',
      height: '100%',
    };

    const errorBoxTypeStyle = {
      width: '100%',
      height: '10px',
      backgroundColor: '#ff666650',
      border: '2px solid #ff4c4c50',
      color: '#7e8387',
      display: 'none',
      marginTop: '4.5%',
      paddingBottom: '8%',
      paddingLeft: '2%',
    };

    const errorBoxStyle = {
      width: '100%',
      height: '20px',
      backgroundColor: '#ff666650',
      border: '2px solid #ff4c4c50',
      color: '#7e8387',
      display: 'none',
      marginTop: '4.5%',
      paddingBottom: '13%',
      paddingLeft: '2%',
    };

    const errorBoxCSVStyle = {
      width: '100%',
      height: '10px',
      backgroundColor: '#ff666650',
      border: '2px solid #ff4c4c50',
      color: '#7e8387',
      display: 'none',
      marginTop: '4.5%',
      paddingBottom: '8%',
      paddingLeft: '2%',
    };

    if (this.state.cardNumber.length !== 16 && this.state.cardNumber.length !== 0) {
      errorStyle.display = 'block';
      errorBoxStyle.display = 'block';
      numberFlag = 1;
    } else if (this.state.cardNumber.length === 16 || this.state.cardNumber.length === 0) {
      errorStyle.display = 'none';
      errorBoxStyle.display = 'none';
      numberFlag = 0;
    }

    if ((this.state.cardMonth.length === 2 && this.state.cardYear.length === 4) || (this.state.cardMonth.length === 0 || this.state.cardYear.length === 0)) {
      errorTypeStyle.display = 'none';
      errorBoxTypeStyle.display = 'none';
    } else if (this.state.cardMonth.length !== 2 || this.state.cardYear.length !== 4) {
      errorTypeStyle.display = 'block';
      errorBoxTypeStyle.display = 'block';
    }

    if (this.state.csv.length === 3 || this.state.csv.length === 0) {
      errorCSVStyle.display = 'none';
      errorBoxCSVStyle.display = 'none';
    } else {
      errorCSVStyle.display = 'block';
      errorBoxCSVStyle.display = 'block';
    }
    */

    if (this.state.redirectToNextStep) {
      this.props.forceStep(9);
      this.setState({ redirectToNextStep: false });
      return <Redirect to="/welcome/congratulations" />;
    }

    if (this.state.alreadyPaid) {
      return <p>This order has already been paid. Click <a style={{ color: 'red' }} href="/payment-history">here</a> to view this order.</p>;
      // return <p>This order has already been paid. Click <a style={{ color: 'red' }} href={`/order-history/${this.state.order_id}`}>here</a> to view this order.</p>;
    }

    const { errors, submitting } = this.state;

    return (
      <div>
        <Row>
          <HalfColumn>
            <Row noMarginBoottom>
              <FullColumn noPaddingBottom>
                <GreyText
                  text="Your Details"
                  fontSize={20}
                  marginBottom
                />
                <Stat label="Name" value={`${this.state.firstName} ${this.state.surname}`} />
                <Stat label="Street Address" value={this.state.billingAddress1} />
                <Stat label="Town/City" value={this.state.billingTownCity} />
                <Stat label="Postcode" value={this.state.billingPostcode} />
                <Stat label="County" value={this.state.billingCounty} />
                <Stat label="Country" value={this.state.billingCountry} />
                <Row><FullColumn /></Row>
                <Stat label="Mobile" value={this.state.mobileNumber} />
                <Stat label="Landline" value={this.state.landline} />
                <Stat label="Email" value={this.state.email} />
                <Row><FullColumn /></Row>
                <Row>
                  <div className="col-12 col-sm-9 col-md-7 order-2 order-sm-1 no-padding align-self-center">
                    <p className="piggy-bank-text">You can of course choose to cancel or &quot;paws&quot; your deliveries at any time! But Kasper and Kitty are confident that your dog won&apos;t be looking back.</p>
                  </div>
                  <div className="col-12 col-sm-3 col-md-5 order-1 order-sm-2 no-padding align-self-center">
                    <img
                      alt=""
                      className="piggy-bank-image"
                      src="/assets/images/pig-icon.png"
                    />
                  </div>
                </Row>
              </FullColumn>
            </Row>
          </HalfColumn>
          <HalfColumn>
            <form onSubmit={this.handleSubmit}>
              <Row noMarginBoottom>
                <FullColumn noPaddingBottom>
                  <GreyText
                    text="Payment method"
                    fontSize={20}
                    marginBottom
                  />
                </FullColumn>
              </Row>
              <Row>
                <div className="col-12 no-padding">
                  <Row>
                    <div className="col-12 col-lg-4">
                      <Radio
                        currentState={this.state.paymentMethod}
                        activeWhenStateIs="Credit card"
                        label="Credit card"
                        onClick={() => this.setState({ paymentMethod: 'Credit card' })}
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      <Radio
                        currentState={this.state.paymentMethod}
                        activeWhenStateIs="Debit card"
                        label="Debit card"
                        onClick={() => this.setState({ paymentMethod: 'Debit card' })}
                      />
                    </div>
                    <div className="col-12 col-lg-4" />
                  </Row>
                </div>
              </Row>
              <FormErrors errors={errors} />
              <Row>
                <FullColumn noPaddingBottom>
                  <Dropdown
                    activeOption={this.state.cardType}
                    onChangeOption={event => this.setState({ cardType: event.value })}
                    options={cards}
                  />
                </FullColumn>
              </Row>
              {/* <Row> */}
              {/*  <FullColumn noPaddingBottom> */}
              {/*    <InputField */}
              {/*      type="text" */}
              {/*      placeholder="Card number" */}
              {/*      value={this.state.cardNumber} */}
              {/*      onChangeText={event => this.setState({ cardNumber: event.target.value })} */}
              {/*      noMarginBoottom */}
              {/*    /> */}
              {/*    <div className="errorBox" style={errorBoxStyle}> */}
              {/*      <div className="errorMessage" style={errorStyle}> */}
              {/*        Invalid Card Number. Needs to be 16 numbers long. */}
              {/*      </div> */}
              {/*    </div> */}
              {/*  </FullColumn> */}
              {/* </Row> */}
              <Row>
                <FullColumn noPaddingBottom>
                  <CardNumberElement
                    className="input-field"
                    placeholder="Card number"
                    style={{
                      base: {
                        fontSize: '18px',
                        fontWeight: 700,
                      },
                    }}
                  />
                </FullColumn>
              </Row>
              {/* <Row> */}
              {/*  <FullColumn noPaddingBottom> */}
              {/*    <Row> */}
              {/*      <div className="col-6 padding-right small all"> */}
              {/*        <InputField */}
              {/*          type="number" */}
              {/*          placeholder="Exp month" */}
              {/*          value={this.state.cardMonth} */}
              {/*          onChangeText={this.handleChangeCardMonth} */}
              {/*          noMarginBoottom */}
              {/*        /> */}
              {/*      </div> */}
              {/*      <div className="col-6 padding-left small all"> */}
              {/*        <InputField */}
              {/*          type="number" */}
              {/*          placeholder="Exp year" */}
              {/*          value={this.state.cardYear} */}
              {/*          onChangeText={event => this.setState({ cardYear: event.target.value })} */}
              {/*          noMarginBoottom */}
              {/*        /> */}
              {/*      </div> */}
              {/*      <div className="errorBox" style={errorBoxTypeStyle}> */}
              {/*        <div className="errorMessage" style={errorTypeStyle}> */}
              {/*          Invalid Numbers. */}
              {/*        </div> */}
              {/*      </div> */}
              {/*    </Row> */}
              {/*  </FullColumn> */}
              {/* </Row> */}
              <Row>
                <FullColumn noPaddingBottom>
                  <CardExpiryElement
                    className="input-field"
                    placeholder="MM/YY"
                    style={{
                      base: {
                        fontSize: '18px',
                        fontWeight: 700,
                      },
                    }}
                  />
                </FullColumn>
              </Row>
              {/* <Row> */}
              {/*  <FullColumn noPaddingBottom> */}
              {/*    <InputField */}
              {/*      type="text" */}
              {/*      placeholder="CSV" */}
              {/*      value={this.state.csv} */}
              {/*      onChangeText={event => this.setState({ csv: event.target.value })} */}
              {/*      noMarginBoottom */}
              {/*    /> */}
              {/*    <div className="errorBox" style={errorBoxCSVStyle}> */}
              {/*      <div className="errorMessage" style={errorCSVStyle}> */}
              {/*        Invaid CSV Number. */}
              {/*      </div> */}
              {/*    </div> */}
              {/*  </FullColumn> */}
              {/* </Row> */}
              <Row>
                <FullColumn noPaddingBottom>
                  <CardCVCElement
                    className="input-field"
                    placeholder="CVC"
                    style={{
                      base: {
                        fontSize: '18px',
                        fontWeight: 700,
                      },
                    }}
                  />
                </FullColumn>
              </Row>
              <Row>
                <div className="col" />
              </Row>
              <Row>
                <FullColumn noPaddingBottom>
                  <Checkbox
                    active={this.state.tandc}
                    onClick={() => this.setState({ tandc: !this.state.tandc })}
                    label="I agree to the Kasper &amp; Kitty Terms &amp; Conditions"
                  />
                </FullColumn>
              </Row>
              {submitting ?
                (
                  <Row>
                    <ReactLoading type="spinningBubbles" color="grey" height={100} width={100} />
                  </Row>
                )
                :
                (
                  <Row>
                    <FullColumn>
                      <button
                        type="submit"
                        className="sign-up-button"
                        disabled={submitting}
                      >
                      Pay
                      </button>
                    </FullColumn>
                  </Row>
                )
              }


              <Row>
                <FullColumn>
                  <p className="grey-text"><span className="bold">Note:</span> When paying by credit or debit card, no payment will be taken from your account until we have dispatched your order.</p>
                </FullColumn>
              </Row>
            </form>
          </HalfColumn>
        </Row>
      </div>
    );
  }
}

export default connect(() => ({
  user: sessionSelectors.getUser(),
}), dispatch => ({
  actions: {
    orders: bindActionCreators(ordersActionCreators, dispatch),
  },
}), null, { withRef: true })(injectStripe(Payment, { withRef: true }));
