import * as actionTypes from './actionTypes';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUANTITY_UP:
      return state.map(item => (
        (item.dogId === action.dogId && item.productId === action.productId) ?
          {
            ...item,
            quantity: item.quantity + 1,
          } :
          item
      ));
    case actionTypes.QUANTITY_DOWN:
      return state.map(item => (
        (item.dogId === action.dogId && item.productId === action.productId && item.quantity > 1) ?
          {
            ...item,
            quantity: item.quantity - 1,
          } :
          item
      ));
    case actionTypes.QUANTITY_CHANGE:
      return state.map(item => (
        (item.dogId === action.dogId && item.productId === action.productId) ?
          {
            ...item,
            quantity: action.quantity,
          } :
          item
      ));
    case actionTypes.DELETE_ITEM_FOR_DOG:
      return state.filter(item =>
        item.dogId !== action.dogId || item.productId !== action.productId);
    case actionTypes.DELETE_ALL_FOR_DOG:
      return state.filter(item => item.dogId !== action.dogId);
    case actionTypes.ADD_TO_CART:
      return [...state, { dogId: action.dogId, productId: action.productId, quantity: action.quantity }];
    case actionTypes.ADD_TO_CART_MULTIPLE: {
      const newLineItems = [];
      const oldLineItems = state.slice();
      action.dogIds.forEach((dogId) => {
        action.productIds.forEach((productId) => {
          const index = state.findIndex(item => item.dogId === dogId && item.productId === productId);
          if (index !== -1) {
            oldLineItems[index].quantity += action.quantity;
          } else {
            newLineItems.push({
              dogId,
              productId,
              quantity: action.quantity,
            });
          }
        });
      });
      return oldLineItems.concat(newLineItems);
    }
    case actionTypes.DELETE_ALL:
      return [];
    default:
      return state;
  }
};
