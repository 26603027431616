import React from 'react';

import SubNavigation from '../../components/SubNavigation';
import Row from '../../components/Row';
import Navigation from '../../components/Navigation';

import Pdsa from '../../components/Pdsa';
import Footer from '../../components/Footer';
import AccountSidebar from '../../components/AccountSidebar';
import AccountDetails from '../../components/AccountDetails';

import KaushanScriptHeading from '../../components/KaushanScriptHeading';


export default class DeliveryDetails extends React.Component {

  render() {
    const information = [
      {
        db: 'address1',
        descr: "Address line 1",
        value: ""
      },
      {
        db: 'address2',
        descr: "Address line 2",
        value: ""
      },
      {
        db: 'company',
        descr: "Company",
        value: ""
      },
      {
        db: 'town',
        descr: "Town",
        value: ""
      },
      {
        db: 'county',
        descr: "County",
        value: ""
      },
      {
        db: 'country',
        descr: "Country",
        value: ""
      },
      {
        db: 'postcode',
        descr: "Postcode",
        value: ""
      },
    ];

    const title = "DELIVERY DETAILS";

    return (
      <div className="container">
        <Navigation
          white
          textColor="white-text"
        />

        <SubNavigation />

        <div className="account-container">
          <KaushanScriptHeading title={'Your account'} />

          <Row>
            <div className="col-md-4">
              <AccountSidebar />
            </div>
            <div className="col-md-7">
              <AccountDetails data={information} title={title} page={2} />
            </div>
            <div className="col-md-1" />
          </Row>

        </div>

        <Pdsa />

        <Footer />
      </div>
    );
  }
}
