import React, { Component } from 'react';

class GoogleMap extends Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
  }

  componentDidMount() {
    const { google } = window;
    const location = new google.maps.LatLng(this.props.lat, this.props.lon);
    const map = new google.maps.Map(this.mapRef.current, {
      zoom: 13,
      center: location,
    });

    const marker = new google.maps.Marker({
      position: location,
    });

    marker.setMap(map);
  }

  render() {
    return (
      <div className="map-container" ref={this.mapRef} />
    );
  }
}

export default GoogleMap;
