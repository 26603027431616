import React from 'react';

const CheckedIcon = () => (
  <svg className="checked-icon" width="17px" height="16px" viewBox="0 0 17 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M4.2577,7.1466 C4.6014,6.803 5.1585,6.803 5.5022,7.1466 L7.6355,9.28 L14.6577,2.2577 C15.0014,1.9141 15.5585,1.9141 15.9022,2.2577 C16.2458,2.6014 16.2458,3.1585 15.9022,3.5022 L8.3426,11.0617 C7.9521,11.4523 7.3189,11.4523 6.9284,11.0617 L4.2577,8.3911 C3.9141,8.0474 3.9141,7.4903 4.2577,7.1466 Z" id="path-1" />
    </defs>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Step-3:-Payment" transform="translate(-106.000000, -618.000000)">
        <g id="Group-3-Copy" transform="translate(86.000000, 546.000000)">
          <g id="Default" transform="translate(20.000000, 69.000000)">
            <g id="icon-/-check-box-copy-6" transform="translate(0.000000, 3.000000)">
              <path className="check-border" d="M13.2222222,14.2222222 L2.77777778,14.2222222 C2.22549303,14.2222222 1.77777778,13.774507 1.77777778,13.2222222 L1.77777778,2.77777778 C1.77777778,2.22549303 2.22549303,1.77777778 2.77777778,1.77777778 L9.77777778,1.77777778 C10.2686976,1.77777778 10.6666667,1.37980867 10.6666667,0.888888889 L10.6666667,0.888888889 C10.6666667,0.397969111 10.2686976,1.35310943e-15 9.77777778,1.44328993e-15 L1.77777778,0 C0.8,0 0,0.8 0,1.77777778 L0,14.2222222 C0,15.2 0.8,16 1.77777778,16 L14.2222222,16 C15.2,16 16,15.2 16,14.2222222 L16,8 C16,7.50908022 15.6020309,7.11111111 15.1111111,7.11111111 L15.1111111,7.11111111 C14.6201913,7.11111111 14.2222222,7.50908022 14.2222222,8 L14.2222222,13.2222222 C14.2222222,13.774507 13.774507,14.2222222 13.2222222,14.2222222 Z" id="check-border" fill="#CDE5DD" fillRule="nonzero" />
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <use className="checkbox-tick" id="check-path" fill="#007853" xlinkHref="#path-1" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default CheckedIcon;
