import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import postcode from 'postcode-validator';

import InputField from '../components/InputField';
import Checkbox from '../components/Checkbox';
import Row from '../components/Row';
import * as session from '../services/session';
import * as sessionSelectors from '../services/session/selectors';

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editmode: false,
      page: this.props.page,
      billing_address: {},
      delivery_address: {},
      deliveryInstructions: '',
      first_name: '',
      surname: '',
      email: '',
      mobile_number: '',
      sendNews: false,
      sendProducts: false,
      responseErrors: [],
    };

    this.getDatabaseValues = this.getDatabaseValues.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
    this.stateChangeHandler = this.stateChangeHandler.bind(this);
    this.fillInputField = this.fillInputField.bind(this);
  }

  componentDidMount() {
    this.getDatabaseValues();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.getDatabaseValues();
    }
  }

  getDatabaseValues() {
    const { user } = this.props;

    this.setState({
      delivery_address: user.delivery_address,
      billing_address: user.billing_address,
      deliveryInstructions: user.delivery_instructions,
      first_name: user.first_name,
      surname: user.surname,
      email: user.email,
      mobile_number: user.mobile_number,
      sendNews: user.send_news,
      sendProducts: user.send_products,
    });

    switch (this.props.page) {
      case 1:
        this.props.data.map((line, index) => (line.value = user[line.db]));
        break;

      case 2:
        this.props.data.map((line, index) => (line.value = user.delivery_address !== null ? user.delivery_address[line.db] : ''));
        break;

      case 3:
        this.props.data.map((line, index) => (line.value = user.billing_address !== null ? user.billing_address[line.db] : ''));
        break;
    }
  }

  stateChangeHandler(key, value) {
    this.setState({ responseErrors: [] });

    switch (this.state.page) {
      case 1:
        this.setState({ [key]: value });
        break;
      case 2:
        const object = Object.assign({}, this.state.delivery_address, {
          [key]: value,
        });

        this.setState({ delivery_address: object });
        break;

      case 3:
        const object2 = Object.assign({}, this.state.billing_address, {
          [key]: value,
        });

        this.setState({ billing_address: object2 });
        break;
    }
  }

  submitChanges() {
    this.setState({ responseErrors: [] });

    switch (this.state.page) {
      case 1:
        session.updateUser({
          first_name: this.state.first_name,
          surname: this.state.surname,
          email: this.state.email,
          mobile_number: this.state.mobile_number,
          send_news: this.state.sendNews,
          send_products: this.state.sendProducts,
        }).then((response) => {
          if (response.success) {
            const message = ['Your changes has been saved.'];
            this.setState({ responseErrors: message });
          } else {
            this.setState({ responseErrors: response.errors });
          }
        });
        break;

      case 2:
        if (!postcode.validate(this.state.delivery_address.postcode, 'UK')) {
          const message = ['Postcode is invalid for UK'];
          this.setState({ responseErrors: message });
          break;
        }

        session.updateUser({
          delivery_address: this.state.delivery_address,
          delivery_instructions: this.state.deliveryInstructions,
        }).then((response) => {
          if (response.success) {
            const message = ['Your changes has been saved.'];
            this.setState({ responseErrors: message });
          } else {
            this.setState({ responseErrors: response.errors });
          }
        });

        break;

      case 3:
        if (!postcode.validate(this.state.billing_address.postcode, 'UK')) {
          const message = ['Postcode is invalid for UK'];
          this.setState({ responseErrors: message });
          break;
        }

        session.updateUser({
          billing_address: this.state.billing_address,
        }).then((response) => {
          if (response.success) {
            const message = ['Your changes has been saved.'];
            this.setState({ responseErrors: message });
          } else {
            this.setState({ responseErrors: response.errors });
          }
        });
        break;
    }

    this.setState({ editmode: !this.state.editmode });
  }

  fillInputField(value) {
    switch (this.state.page) {
      case 1:
        return this.state[value];
        break;

      case 2:
        return this.state.delivery_address !== null ? this.state.delivery_address[value] : '';
        break;

      case 3:
        return this.state.billing_address !== null ? this.state.billing_address[value] : '';
        break;
    }
  }

  render() {
    const { responseErrors } = this.state;

    return (
      <div className="table">
        <p className="title">{this.props.title}</p>
        <p className="data" />

        {this.props.data.map((line, index) => (
          <InputField
            type="text"
            placeholder={line.descr}
            value={this.fillInputField(line.db)}
            onChangeText={event => this.stateChangeHandler(line.db, event.target.value)}
            disable={!this.state.editmode || line.db === 'country'}
            noMarginBoottom
            key={index}
          />
        ))}

        {this.state.page === 2 &&
          <InputField
            type="text"
            placeholder={"Delivery instructions"}
            value={this.state.deliveryInstructions}
            onChangeText={event => this.setState({ deliveryInstructions: event.target.value })}
            disable={!this.state.editmode}
            noMarginBoottom
          />
        }

        <p className="spacer" />
        <p className="title">{"Communication Preferences"}</p>
        <p className="spacer" />

        <Row>
          <div className="col-12 col-sm-6 col-lg-12">
            <Checkbox
              disabled={!this.state.editmode}
              active={this.state.sendNews}
              onClick={() => this.setState({ sendNews: !this.state.sendNews })}
              label="New Product Launches, Free Samples &amp; Discounts"
              white
            />
          </div>
        </Row>

        <Row>
          <div className="col-12 col-sm-6 col-lg-12">
            <Checkbox
              disabled={!this.state.editmode}
              active={this.state.sendProducts}
              onClick={() => this.setState({ sendProducts: !this.state.sendProducts })}
              label="Latest dog news, trends and blogs from around the world"
              white
            />
          </div>
        </Row>

        <button
          onClick={() => {
            this.setState({ editmode: !this.state.editmode });
          }}
          disabled={this.state.editmode}
        >
          Edit
        </button>

        <button
          disabled={!this.state.editmode}
          onClick={() => {
            this.submitChanges();
          }}
        >
          Save
        </button>

        {responseErrors.length > 0 &&
          (
            <div className="alert alert-success">
              {responseErrors.map(error => <div>{error}</div>)}
            </div>
          )}
      </div>
    );
  }
}

export default connect(() => ({
  user: sessionSelectors.getUser(),
}), dispatch => ({
  actions: {
    user: bindActionCreators(sessionSelectors, dispatch),
  },
}))(AccountDetails);
