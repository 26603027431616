import { fetchApi } from '../api';

const endPoints = {
  authenticate: '/users/login',
  register: '/users/register',
  forgotten: '/users/forgotten',
  contact: '/users/submitContactForm',
  reset: '/users/reset',
  resetCruft: '/users/resetCruft',
  update: '/users/update',
  userInfo: '/users/me',
};

export const authenticate = ({ email, password }) => fetchApi(endPoints.authenticate, { email, password }, 'post');

export const register = user =>
  fetchApi(endPoints.register, user, 'post');

export const contact = (userMessage) => fetchApi(endPoints.contact, userMessage, 'post');
export const forgotten = ({ email }) => fetchApi(endPoints.forgotten, { email }, 'post');
export const reset = ({ code, password }) => fetchApi(endPoints.reset, { code, password }, 'post');
export const resetCruft = ({ user_id: ID, password }) => fetchApi(endPoints.resetCruft, { user_id: ID, password }, 'post');

export const update = user => fetchApi(endPoints.update, user, 'put');
export const userInfo = () => fetchApi(endPoints.userInfo, {}, 'get');
