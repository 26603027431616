import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgressbar from 'react-circular-progressbar';
import * as actionTypes from '../../data/exports';
import HomeImage from '../../assets/images/wizard/dog-navigation_home.png';
import MenuIcon from '../../assets/images/mobile-menu-dots.svg';
import * as dogsActionCreators from '../../data/dogs/actions';

class DogNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileNav: true
    };

    this.goHome = () => {
      this.removeModalBackground();
      this.props.history.push('/add-dog');
    };

    this.toggleCurrentDog = (index, step) => {
      this.removeModalBackground();
      this.props.toggleDog(index);
      this.props.history.push(step);
    };

    this.removeModalBackground = () => {
      const modalBackground = document.getElementsByClassName('modal-backdrop ');
      if(modalBackground[0] !== undefined){
          modalBackground[0].remove();
      }
    };

      this.handleNavigation = () => {
          this.setState({mobileNav: !this.state.mobileNav})
      }

  }

  componentDidMount(){
      if (window.outerWidth < 500){
          this.setState({mobileNav: false})
      }
  }
  render() {
    const dogs = this.props.storeDogs.map((dog, index) => (
      <div
        className={`dog-item animated fadeIn delay-_${index}s`}
        key={dog.name}
        onClick={() => this.toggleCurrentDog(index, dog.step)}
      >
        {dog.image.length !== 0
                  ?
                    <div className="dog-item_avatar">
                      <img src={dog.image} alt="" />
                      <CircularProgressbar
                        percentage={dog.progress}
                        strokeWidth={2}
                        styles={{
                                path: {
                                    stroke: '#fab522',
                                },
                                trail: { stroke: '#dcdcdd' },
                            }}
                      />
                    </div>
                  :
                    <div className={`dog-item_name ${this.props.currentDog.name === dog.name ? 'current' : ''}`}>
                      {dog.name.slice(0, 1).toUpperCase()}
                      <CircularProgressbar
                        percentage={dog.progress}
                        strokeWidth={2}
                        styles={{
                                path: {
                                    stroke: '#fab522',
                                },
                                trail: { stroke: '#dcdcdd' },
                            }}
                      />
                    </div>
                  }
        <span className="dog-item_nick">{dog.name}</span>
      </div>
    ));
    return (
      <div className={`dog-navigation_container ${this.props.isNav ? '' : 'hide'}`}>
        <div className={`dogs ${this.state.mobileNav ? '' : 'd-none'}`}>
          {dogs}
        </div>
        <div className={`home ${this.state.mobileNav ? '' : 'd-none'}`} onClick={this.goHome}>
          <img src={HomeImage} alt="" />
        </div>
          <div className="home mt-3 mobile-trigger" onClick={ this.handleNavigation}>
              <img src={MenuIcon} alt="" />
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dogs: state.data.dogs,
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  isNav: state.data.wizard.isNavigation,
});
const mapDispatchToProps = dispatch => ({
  onProgressChanged: progress => dispatch(actionTypes.setProgress(progress)),
  toggleDog: index => dispatch(dogsActionCreators.toggleDog(index)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DogNavigation));
