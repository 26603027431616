import React from 'react';

const InfoBlock = () => (
  <div id="info-block">
    <p>
      We have 90 days return policy. Read more about our <a target="_blank" href="http://www.kasperandkitty.com/index.php/delivery-returns/">Return & Refund Policy.</a>
    </p>
    <p>
      We deliver Mon-Fri, except Bank Holidays. Read more about <a target="_blank" href="http://www.kasperandkitty.com/index.php/delivery-returns/">Delivery.</a>
    </p>
    <p className="no-margin-bottom">
      <span className="bold">Do you need help?</span><br />
      Find out more in our <a target="_blank" href="https://www.kasperandkitty.com/index.php/support-2/">Contact Us</a> section or call us at 0800 888 6654.
    </p>
  </div>
);

export default InfoBlock;
