import React from 'react';

import Navigation from './Navigation';
import Footer from './Footer';

const Layout = ({
  children, classes, productCount, checkout,
}) => (
  <div className={`main-layout ${classes}`}>
    <Navigation productCount={productCount} checkout={checkout} />
    {children}
    <Footer newTab />
  </div>
);

export default Layout;
