import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ordersSelectors from '../../data/orders/selectors';
import * as sessionSelectors from '../../services/session/selectors';
import * as ordersActionCreators from '../../data/orders/actions';


import Navigation from '../../components/Navigation';
import MainImageBanner from '../../components/MainImageBanner';
import SubNavigation from '../../components/SubNavigation';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';
import Row from '../../components/Row';
import BackgroundContainer from '../../components/BackgroundContainer';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
// import InstagramCarousel from '../../components/InstagramCarousel';
import Footer from '../../components/Footer';


class PaymentHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
    };

    this.renderPayments = this.renderPayments.bind(this);
  }

  componentDidMount() {
    this.props.actions.orders.getOrders().then(orders => this.setState({ orders }));
  }

  dateRearrange(originalDate){
    //Date has to be as a 10 character long string (YEAR-MONTH-DAY)
    let year = originalDate.substr(0, 4);
    let month = originalDate.substr(5,2);
    let day = originalDate.substr(8,2);
    let newDate = day+"-"+month+"-"+year;
    return newDate;
  }

  renderPayments() {
    const payments = [];

    this.state.orders.map(item => (item.awaiting_payment === false ? payments.push(item) : undefined));

    if (payments.length > 0) {
      return (
        payments.map(item => (
          <tr key={item.ID}>
            <td><Link to={`/payment-history/${item.ID}`} href>{item.ID}</Link></td>
            <td>{this.dateRearrange(item.CreatedAt.substr(0, 10))}</td>
            <td>£{item.total.toFixed(2)}</td>
          </tr>
        ))
      );
    }
    return (
      <tr>
        <td>No payment history was found.</td>
      </tr>
    );
  }

  render() {
    const { orders } = this.props;

    // Find the first unpaid future order.
    const nextPaymentOrder = orders.filter(order => order.awaiting_payment === true).sort((a, b) => a.id > b.id).find(order => Date.parse(order.date_required) >= new Date().getTime());

    return (
      <div className="contianer">
        <Navigation />
        {/* <MainImageBanner
          image="url(/assets/images/dog-in-field.jpg)"
          heading="Payment history"
          subtext=""
        /> */}
        <SubNavigation />
        <div className="inner-container">
          <KaushanScriptHeading title="Payment history" />
          <Row>
            <div className="col-12 col-md-8">
              <div className="table-container">
                <table>
                  <tbody>
                    <tr className="heading">
                      <th>Order ID</th>
                      <th>Date</th>
                      <th>Amount</th>
                    </tr>
                    {this.renderPayments()}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <BackgroundContainer>
                <OrangeTextHeading
                  text="Your next payment is due on:"
                  centered
                  fontSize={26}
                />
                <GreyText
                  text={nextPaymentOrder ? this.dateRearrange(nextPaymentOrder.date_required.substr(0, 10)) : 'To Be Confirmed'}
                  fontSize={26}
                  centered
                  marginBottom
                />
                <GreyText
                  text="Billing address"
                  fontSize={20}
                  centered
                  bold
                />
                <GreyText
                  text={`${sessionSelectors.getUser().first_name} ${sessionSelectors.getUser().surname}`}
                  fontSize={20}
                  centered
                />
                <GreyText
                  text={sessionSelectors.getUser().billing_address.address1}
                  fontSize={20}
                  centered
                />
                <GreyText
                  text={sessionSelectors.getUser().billing_address.address2}
                  fontSize={20}
                  centered
                />
                <GreyText
                  text={sessionSelectors.getUser().billing_address.town}
                  fontSize={20}
                  centered
                />
                <GreyText
                  text={sessionSelectors.getUser().billing_address.postcode}
                  fontSize={20}
                  centered
                />
              </BackgroundContainer>
            </div>
          </Row>
        </div>
        {/* <InstagramCarousel /> */}
        <Footer />
      </div>
    );
  }
}

export default connect(() => ({
  orders: ordersSelectors.getOrders(),
}), dispatch => ({
  actions: {
    orders: bindActionCreators(ordersActionCreators, dispatch),
  },
}))(PaymentHistory);
