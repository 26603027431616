import React from 'react';
import { connect } from 'react-redux';

import * as orderAPI from '../../data/orders/index';
import * as sessionSelectors from '../../services/session/selectors';
import * as dogsSelectors from '../../data/dogs/selectors';

import Navigation from '../../components/Navigation';
import MainImageBanner from '../../components/MainImageBanner';
import SubNavigation from '../../components/SubNavigation';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';
import Row from '../../components/Row';
import FullColumn from '../../components/FullColumn';
import Footer from '../../components/Footer';
import { fetchApi } from '../../services/api';
import * as utils from '../../utils';
import { promocode } from '../../data/orders/api';
import { PayHandler } from '../../services/PayHandler';
import { withRouter } from 'react-router-dom';
import { Elements, injectStripe } from 'react-stripe-elements';
import * as Sentry from '@sentry/browser';

import { updateSubscriptionOrder } from '../../data/orders/api';


const TableRow = ({ product, quantity, units, priceExVAT, priceIncVAT, heading, onClickX }) => (
  <Row>
    <div className="col-6 col-sm-4 no-padding">
      <p style={{ borderRadius: 0 }} className={`${heading ? ' heading ' : ''}table-row product`}>
        {product}
      </p>
    </div>
    <div className="col-3 col-sm-2 no-padding">
      <p className={`${heading ? ' heading ' : ''}table-row quantity`}>{quantity}</p>
    </div>
    <div className="col-3 col-sm-2 no-padding">
      <p className={`${heading ? ' heading ' : ''}table-row quantity`}>{units}</p>
    </div>
    <div className="col-3 col-sm-2 no-padding">
      <p className={`${heading ? ' heading ' : ''}table-row price`}>{priceExVAT}</p>
    </div>
    <div className="col-2 col-sm-1 no-padding">
      <p className={`${heading ? ' heading ' : ''}table-row price`}>{priceIncVAT}</p>
    </div>
    <div className="col-1 col-sm-1 no-padding">
      {onClickX && <button className="table-row price remove-button" onClick={onClickX}></button>}
    </div>
  </Row>
);

const SummaryRow = ({ label, price }) => (
  <Row>
    <div className="col-9  no-padding">
      <p className="summary-text">{label}</p>
    </div>
    <div className="col-2 col-sm-1 no-padding">
      <p className="summary-price">{price}</p>
    </div>
  </Row>
);

class Order extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: undefined,
      promo: false,
    };
  }

  componentDidMount() {
    const orderItems = (orderID) => fetchApi('/orders/all_order_items/', { orderID }, 'get');
    const orderId = this.props.match.params.order_id;
    orderAPI
      .getOrder(orderId)
      .then((resp) => {
        this.setState({ order: resp });
      })
      .then(() => {
        // Althogh order contains orderItems those appear to be incorrectly populated.
        // therfore fetching orderItems directly
        orderItems(this.state.order.ID)
          .then((resp) => {
            this.setState({ correct_order_items: resp });
          })
          .catch((err) => {
            console.log('error! ', err);
          });

        promocode(this.state.order.promo_id)
          .then((promo) => {
            this.setState({ promo });
          })
          .catch((err) => console.error(err));

        this.payHandler = new PayHandler(this.props);
        this.payHandler
          .checkPaymentIntent(this.state.order)
          .then((status) => {
            if (status === 'succeeded') {
              this.props.history.push('/thanks');
            }
          })
          .catch((error) => {
            console.log('payment error:', error);
            Sentry.captureException(error);
            this.setState({ paymentLoading: false });
            let message = '';
            if (error.message) {
              message = error.message;
            }
            alert(`An error occured while processing your payment: ${message}`);
            this.props.history.push('/live-orders');
          });
      })
      .catch((err) => {
        console.log('An error occured whilst getting this order.', err);
      });
  }

  dateRearrange(originalDate) {
    //Date has to be as a 10 character long string (YEAR-MONTH-DAY)
    let year = originalDate.substr(0, 4);
    let month = originalDate.substr(5, 2);
    let day = originalDate.substr(8, 2);
    let newDate = day + '-' + month + '-' + year;
    return newDate;
  }

  removeSubscriptionItem(id) {
    // prep new state
    let newOrder = this.state.order;
    newOrder.order_items = newOrder.order_items.filter((item) => item.ID !== id);

    // update on server
    let payload = {
      orderID: newOrder.ID,
      items: newOrder.items.map((item) => {
        return item.product.ID === id
          ? { productID: item.product.ID, quantity: 0 }
          : { productID: item.product.ID, quantity: item.quantity };
      }),
    };

    updateSubscriptionOrder(payload.orderID, payload.items).then((res) => {
      if (res.success) {
        this.setState({ order: newOrder });
      }
    });
  }

  getOriginalAndDiscountedTotal(originalTotal, isOrderReleased) {
    const { total_discount } = this.props.user;
    if (!total_discount || total_discount === 0 || isOrderReleased) {
      return `£${originalTotal.toFixed(2)}`;
    }
    const newTotal = ((100 - total_discount) / 100) * originalTotal;
    return (
      <span>
        <s>£{originalTotal.toFixed(2)}</s> £{newTotal.toFixed(2)}
      </span>
    );
  }

  render() {
    const { order, correct_order_items } = this.state;
    if (order === undefined || correct_order_items === undefined) {
      return null;
    }

    // Dry food kiddle must be one order item, the name is Bespoke dry kiddle recipe
    const kibblesRecipe = (order.order_items && order.order_items.filter((item) => item.is_kibble)) || [];
    const nonKibbles = (order.order_items && order.order_items.filter((item) => !item.is_kibble)) || [];
    let [kibbleQuantity, kibPricePreVat, kibPrice] = [0, 0, 0];

    let orderTotalPrice = order.total;

    kibblesRecipe.forEach((item) => {
      var { quantity, price } = correct_order_items.find((item_correct) => item_correct.product_id == item.ID);
      kibbleQuantity += quantity / 100;
      kibPricePreVat += price;
      let roundedOnce = (Math.round(price * 100) / 100) * 1.2;
      let roundedTwice = Math.round(roundedOnce * 100) / 100;
      kibPrice += roundedTwice; //matching the rounding on backend
    });

    kibPricePreVat = Math.round(kibPricePreVat * 100) / 100;
    kibPrice = utils.applyPromotionToKibble(kibPrice, this.state.promo);
    orderTotalPrice = utils.applyPromotionToKibble(kibPrice, this.state.promo);

    let fitkolarTotal = utils.applyPromotionToFitkolar(utils.FITKOLAR_PRICE_PRE_VAT, this.state.promo);
    fitkolarTotal = (Math.floor(fitkolarTotal * 100) / 100) * this.state.order.fitkolars;

    let cancelledOrAwaiting =
      order.status === 'Cancelled' ? 'This order is cancelled' : 'This order is still awaiting payment';

    return (
      <div className="container">
        <Navigation />
        <MainImageBanner
          image="url(/assets/images/dog-in-field.jpg)"
          heading={`${this.props.dog.name}'s dashboard`}
          subtext={`${this.props.dog.name}'s food and daily menu`}
        />
        <SubNavigation />
        <div className="inner-container">
          <KaushanScriptHeading title={`Order - ${order.ID}`} />
          <Row>
            <FullColumn noPaddingBottom>
              {order.awaiting_payment === true && <div className="payment-needed">{cancelledOrAwaiting}</div>}
              <div className="table-container">
                <div className="section-header-order">Order Details</div>
                <Row>
                  <div className="col-12 col-sm-6 no-padding margin-bottom full-height">
                    <div className="table-info-container-order">
                      <p>
                        <span>Order ID:</span> {order.ID}
                      </p>
                      <p>
                        <span>Date Added:</span> {this.dateRearrange(order.CreatedAt.substr(0, 10))}
                      </p>
                      {order.awaiting_payment === false && (
                        <div>
                          <p>
                            <span>Delivery Method:</span> {order.delivery_method}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 no-padding margin-bottom full-height">
                    <div className="table-info-container-order">
                      <p>
                        <span>Email:</span> <a href={`mailto:${this.props.user.email}`}>{this.props.user.email}</a>
                      </p>
                      <p>
                        <span>Telephone:</span> {this.props.user.mobile_number}
                      </p>
                    </div>
                  </div>
                </Row>
              </div>
            </FullColumn>
          </Row>
          <Row>
            <FullColumn noPaddingBottom>
              <div className="table-container">
                <Row>
                  {order.billing_address !== null && (
                    <div className="col-12 col-sm-6 no-padding margin-bottom full-height">
                      <div className="section-header">Billing Address</div>
                      <div className="table-info-container">
                        <p>Caroline Broquere Lartigue</p>
                        <p>Oakwood House</p>
                        <p>Wallace Lane</p>
                        <p>Forton PR3 0BB</p>
                        <p>Lancashire</p>
                        <p>United Kingdom</p>
                      </div>
                    </div>
                  )}
                  {order.delivery_address !== null && (
                    <div className="col-12 col-sm-6 no-padding margin-bottom full-height">
                      <div className="section-header-order">Delivery Address</div>
                      <div className="table-info-container-order">
                        <p>{order.delivery_address.company}</p>
                        <p> {order.delivery_address.address1}</p>
                        <p> {order.delivery_address.address2}</p>
                        <p> {order.delivery_address.town}</p>
                        <p> {order.delivery_address.county}</p>
                        <p> {order.delivery_address.postcode}</p>
                        <p>{order.delivery_address.country}</p>
                      </div>
                    </div>
                  )}
                </Row>
              </div>
            </FullColumn>
          </Row>
          <Row>
            <FullColumn>
              <TableRow
                product="Product name"
                quantity="Qty (Kg)"
                units="Units"
                priceExVAT="Price Exc VAT"
                priceIncVAT="Price Inc VAT"
                heading
              />
              {kibbleQuantity > 0 && (
                <TableRow
                  product="Bespoke Meal Plan"
                  quantity={kibbleQuantity.toFixed(2)}
                  units="1"
                  priceExVAT={`£${((order.total - order.delivery_cost) / 1.2).toFixed(2)}`}
                  priceIncVAT={`£${(order.total - order.delivery_cost).toFixed(2)}`}
                />
              )}
              {nonKibbles.map((item) => (
                <TableRow
                  product={item.name}
                  quantity="N/A"
                  units={
                    this.state.correct_order_items.find((p) => {
                      return p.product_id == item.ID;
                    }).quantity
                  }
                  priceExVAT={`£${(
                    (item.price / 1.2) *
                    this.state.correct_order_items.find((p) => {
                      return p.product_id == item.ID;
                    }).quantity
                  ).toFixed(2)}`}
                  priceIncVAT={`£${(
                    item.price *
                    this.state.correct_order_items.find((p) => {
                      return p.product_id == item.ID;
                    }).quantity
                  ).toFixed(2)}`}
                  onClickX={order.status === 'Cancelled' ? null : () => this.removeSubscriptionItem(item.ID)}
                />
              ))}

              {order.fitkolars > 0 && (
                <TableRow
                  product="Fitkolar(s)"
                  quantity="N/A"
                  units={order.fitkolars}
                  priceExVAT={`£${fitkolarTotal}`}
                  priceIncVAT={`£${Math.floor(fitkolarTotal * 100 * 1.2) / 100}`}
                />
              )}

              <SummaryRow
                label="Sub total"
                price={
                  order.is_reoccurring
                    ? `£${order.total.toFixed(2)}`
                    : `£${(order.total - order.delivery_cost).toFixed(2)}`
                }
              />

              <SummaryRow
                label="Delivery"
                price={order.is_reoccurring ? 'FREE' : `£${order.delivery_cost.toFixed(2)}`}
              />
              <SummaryRow label="Total to pay" price={this.getOriginalAndDiscountedTotal(order.total, order.release)} />
            </FullColumn>
          </Row>
        </div>
        {/* <InstagramCarousel /> */}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = () => ({
  user: sessionSelectors.getUser(),
  dog: dogsSelectors.getCurrentDog(),
});

const PaymentWrapped = connect(mapStateToProps)(withRouter(injectStripe(Order)));

export default ({ props }) => (
  <Elements>
    <PaymentWrapped {...props} />
  </Elements>
);
