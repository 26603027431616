import React from 'react';
import ReactLoading from 'react-loading';
import connect from 'react-redux/es/connect/connect';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link, withRouter } from 'react-router-dom';
import * as cartActionCreators from '../../data/cart/actions';
import { getCartItemsByDog } from '../../data/cart/utils';
import ContainedButton from '../ContainedButton';
import * as utils from '../../utils';

class OrderSummary extends React.Component {
  render() {
    const {
      items,
      dog
    } = getCartItemsByDog(this.props.dogs, this.props.products, this.props.cart);

    let ProceedCheckoutLink;
    switch (this.props.step) {
      case 1:
        ProceedCheckoutLink = props => <Link to="/checkout/delivery" {...props} />;
        break;
      case 2:
        ProceedCheckoutLink = props => <Button {...props} />;
        break;
      case 3:
        ProceedCheckoutLink = props => <Button {...props} />;
        break;
      default:
        throw new Error('Unknown step');
    }

    const {
      total,
      totalDelivery,
      deliveryCost,
      firstDelivery,
    } = utils.getCheckoutTotal(
      items,
      0,
      2,
      this.props.promocode,
      this.props.promocode.TrialPnP, // as of now: delivery at registration stage can only be 2£
    );

    const deliveryDate = new Date(this.props.delivery.deliveryDate);
    let orderTotal = this.props.step >= 2 ? totalDelivery : total;
    const shipping = deliveryCost;
    let bespokeCost = items.filter(item => item.isBespoke);

    const getKibPrice = (item) => item.price * (100 - this.props.promocode.kibble_discount) / 100;

    return (
      <Grid id="order-summary" className="border-element border-body">
        <Grid className="header-main border-bottom">Order summary</Grid>
        <Grid container className="line-items border-bottom">
          {items.map(item => (
            <Grid container className="line-item" alignItems="center" key={item.name}>
              {item.isBespoke ?
                <Grid item xs={10}>
                  <div>{this.props.promocode.NumDaysTilNextOrder} Day Trial Subscription</div>
                  <div>Box for {dog.name}</div>
                </Grid>
                :
                <Grid item xs={10}>
                  <div>{item.name}</div>
                </Grid>
              }
              <Grid item xs={2} className="text-right">
                {item.isBespoke ?
                  <div>
                    <span className="bold">
                      {getKibPrice(item) === 0 ? 'FREE' : '£' + getKibPrice(item).toFixed(2)}
                    </span>
                    <p className="strikethrough">£{item.price.toFixed(2)}</p>
                  </div>
                  :
                  <span className="bold">
                    £{(item.price * item.quantity).toFixed(2)}
                  </span>
                }

                {this.props.step === 2 || this.props.step === 3 ?
                  <p className="edit-link"><a href="/cart">Edit</a></p>
                  : null
                }
              </Grid>
            </Grid>
          ))}
        </Grid>
        {this.props.step >= 2 ? (
          <Grid container className="line-items border-bottom" alignItems="flex-start">
            <Grid item xs={10}>
              Shipping
              {this.props.step === 3 ?
                <div className="shipping-info">
                  <p>{this.props.delivery.firstName} {this.props.delivery.lastName}</p>
                  <p>{this.props.delivery.city ? `${this.props.delivery.city}, ` : null}{this.props.delivery.streetAddressLine1}</p>
                  <p>{this.props.delivery.zipPostalCode}, {this.props.delivery.country}</p>
                </div> : null}
            </Grid>
            <Grid item xs={2} className="text-right">
              <span className="bold">{`£${shipping ? shipping.toFixed(2) : 0}`}</span>
              {this.props.step === 3 ? <p className="edit-link"><a href="/checkout/delivery">Edit</a></p> : null}
            </Grid>
          </Grid>
        ) : null}
        {this.props.step === 3 ? (
          <div>
            {/*commented out to remove delivery date
            {this.props.delivery.deliveryDate ?
              <Grid container className="line-items border-bottom" alignItems="flex-start">
                <Grid item xs={9}>
                  Delivery Date <p className="shipping-info">Every {deliveryDate.getDate()}th of the month</p>
                </Grid>
                <Grid item xs={3} className="text-right">
                  <span className="bold">{deliveryDate.toLocaleDateString('ru-RU')}</span>
                  <p className="edit-link"><a href="/checkout/delivery">Edit</a></p>
                </Grid>
              </Grid> : null}
            */}
            {this.props.promocode.ID ? (
              <Grid container className="line-items border-bottom" alignItems="flex-start">
                <Grid item xs={9}>PromoCode</Grid>
                <Grid item xs={3} className="text-right">
                  <span className="bold">{this.props.promocode.code}</span>
                </Grid>
              </Grid>
            ) : null}
          </div>
        ) : null}
        <Grid container className="order-total bold" alignItems="center">
          <Grid item xs={10}>Total</Grid>
          <Grid item xs={2} className="text-right">£{orderTotal.toFixed(2)}</Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {this.props.paymentLoading ?
              <div className="loading-wrapper-summary">
                <ReactLoading type="spinningBubbles" color="grey" height={45} width={45} />
              </div>
              :
              <ContainedButton
                onClick={this.props.submitSummary}
                title={this.props.step !== 3 ? 'Proceed to Checkout' : 'Ready for food? Finish here.'}
                component={ProceedCheckoutLink}
                disabled={this.props.detailsValid}
              />
            }
          </Grid>
          {this.props.step === 3 ?
            <Grid item className="tip" xs={12}>
              {/* *After trial, cost for next month subscription box is just £{(2*(bespokeCost[0].price)).toFixed(2)}. Free delivery. */}By clicking Pay Now you agree to <a href="https://www.kasperandkitty.com/index.php/terms-conditions/" target="_blank">Terms & Conditions.</a>
              <div className="bold">Skip or cancel anytime</div>
            </Grid> : null}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  dogs: state.data.dogs.dogs,
  products: state.data.products,
  cart: state.data.cart,
  delivery: state.data.delivery,
  promocode: state.data.promocode,
});
const mapDispatchToProps = dispatch => ({
  deleteAll: () => dispatch(cartActionCreators.deleteAll()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderSummary));
