import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navigation from '../../components/Navigation';
import DogNavigation from '../../components/DogNavigation';
import SubNavigation from '../../components/SubNavigation';
import Row from '../../components/Row';
import FullColumn from '../../components/FullColumn';
import BackgroundContainer from '../../components/BackgroundContainer';
import DateSelectionBanner from '../../components/DateSelectionBanner';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import OpenSansHeading from '../../components/OpenSansHeading';
import Footer from '../../components/Footer';
import MonthlyMenu from '../../components/MonthlyMenu';

import { getData, getPortions, getOrders, calories } from '../../data/dogs/api';
import { notification } from '../../data/products/api';

import * as dogsActionCreators from '../../data/dogs/actions';
import * as ordersActionCreators from '../../data/orders/actions';
import * as dogsSelectors from '../../data/dogs/selectors';
import * as sessionSelectors from '../../services/session/selectors';

import { getOriginalAndDiscountedTotal } from '../../utils';

const Stat = ({ subject, value }) => (
  <p className='dashboard-stat'>
    <span>{subject}: </span>
    {value}
  </p>
);

const DashboardScreen = ({ dog, user, history }) => {
  if (dog.tracker === null) {
    return <Dashboard history={history} withoutTracker dog={dog} user={user} />;
  }

  return <Dashboard dog={dog} user={user} />;
};

const daysBefore = (date, n) => new Date(date.getTime() - n * 24 * 3600 * 1000);

const dateFormat = (date) => {
  const day = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()
  const month = (date.getMonth() + 1 <= 9) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
  return day + '/' + month + '/' + date.getFullYear()
}


const NextOrderInfo = ({ orders, isMobile, user }) => {
  let nextOrderData = {
    price: '-',
    deliveryDate: '-',
    status: '-',
    lastChangeDate: '-',
    editable: false,
  };
  let lastOrder = null;

  if (orders.length > 0) {
    const sortedOrders = orders.filter((order) => {
      if (order.dog_id !== dogsSelectors.getCurrentDog().ID) {
        return false;
      }

      if (
        new Date(order.date_required) >= new Date() &&
        order.status !== 'Cancelled'
      ) {
        if (Array.isArray(order.order_items) && order.order_items.length > 0) {
          return !order.shipped && (order.status !== "Incomplete");
        }
      }

      return false;
    });

    if (sortedOrders.length > 0) {
      sortedOrders.sort(
        (a, b) => new Date(b.date_required) - new Date(a.date_required)
      );

      lastOrder = sortedOrders[0];

      if (lastOrder !== undefined) {
        const orderDate = new Date(lastOrder.date_required);
        const lastChangeDate = daysBefore(orderDate, 3);

        nextOrderData = {
          price: `£${lastOrder.total.toFixed(2)}`,
          deliveryDate: dateFormat(orderDate),
          status: lastOrder.status,
          lastChangeDate: dateFormat(lastChangeDate),
          editable: !lastOrder.promo_id,
        };
      }
    }
  }

  if (isMobile) {
    return (
      <React.Fragment>
        <table className="dashboard-order-menu-table">
          <tbody>
            <tr>
              <td className="label">Price for the order</td>
              <td className="information">{nextOrderData.price}</td>
            </tr>
            <tr>
              <td className="label">Next Delivery</td>
              <td className="information">{nextOrderData.deliveryDate}</td>
            </tr>
            <tr>
              <td className="label">Status</td>
              <td className="information">{nextOrderData.status}</td>
            </tr>
            <tr>
              <td className="label">Last day to change order</td>
              <td className="information">{nextOrderData.lastChangeDate}</td>
            </tr>
          </tbody>
        </table>
        <div className="button-group">
          <button
            className="view-orders"
            onClick={() => { window.location.href = './live-orders'; }}
          >
            View Orders
          </button>
          <button
            className="edit"
            disabled={!nextOrderData.editable}
            style={!nextOrderData.editable ? { visibility: 'hidden' } : {}}
            onClick={() => { window.location.href = './payment-history/' + nextOrderData.ID; }}
          >
            Edit
          </button>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <div className='col-12 no-padding'>
        <GreyText
          fontSize={16}
          bold
          marginBottom
        >
          Price of next order: {getOriginalAndDiscountedTotal(lastOrder, user)}
        </GreyText>
        <GreyText
          text={`Next delivery: ${nextOrderData.deliveryDate}`}
          fontSize={16}
          bold
          marginBottom
        />
        <GreyText
          text={`Status: ${nextOrderData.status}`}
          fontSize={16}
          bold
          marginBottom
        />
        <GreyText
          text={`Last date to change order: ${nextOrderData.lastChangeDate}`}
          fontSize={16}
          bold
          marginBottom
        />

        <button
          onClick={() => {
            scrollTo(0, 0);
            window.location.href = './live-orders';
          }}
          disabled={!nextOrderData.editable}
          style={!nextOrderData.editable ? { visibility: 'hidden' } : {}}
        >
          Edit
        </button>
      </div>
    )
  }
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: undefined,
      month: undefined,
      year: undefined,
      imageWidth: 0,
      windowWidth: 0,
      generalInfoHeight: 0,
      stepRotation: 0,
      calorieRotation: 0,
      steps: 0,
      distance: 0,
      activityTime: 0,
      restTime: 0,
      orders: [],
      portions: [],
      bespokeCalories: 0,
      portionsToday: 0,
      kasperPoints: 0,
      kasperPointsGoal: 0,
      dailyCalories: 0,
      maxCalories: 0,
      nextDate: new Date(),

      notificationTitle: '',
      fitkolarUrl: '',
      fitkolarIcon: '',

      shownPortions: [],
      isMobile: false,
      height: 0,
      left: 0,
      deg: 0
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  componentDidMount() {
    this.updateDimensions();
    // this.dialdeg(5200, 33450)

    window.addEventListener('resize', this.updateDimensions);

    // Rotates the dials on load
    setTimeout(() => {
      this.setState({
        stepRotation: 180,
        calorieRotation: 90,
      });
    }, 1000);

    this.fetchData();

    getOrders().then((orders) => {
      if (orders.length > 0) {
        this.setState({ orders });
      }
    });

    notification().then((notifications) => {
      if (notifications.ID > 0) {
        this.setState({
          notificationTitle: notifications.title,
          fitkolarUrl: notifications.url,
          fitkolarIcon: notifications.icon.Url,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.dog && (prevProps.dog !== this.props.dog)) {
      this.fetchData(new Date(this.state.delayYear, this.state.delayMonth, this.state.delayDate));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  fetchData(passedDate = undefined) {
    return new Promise((resolve, reject) => {
      let dataRequest = null;
      const orderRequest = getOrders();
      const portionRequest = getPortions(this.props.dog.ID);
      const caloriesRequest = calories(this.props.dog.ID);

      if (passedDate !== undefined) {
        let date = `${passedDate.getFullYear()}-`;

        const month = passedDate.getUTCMonth() + 1;

        if (month < 10) {
          date += '0';
        }

        date += `${month}-`;

        const day = passedDate.getUTCDate() + 1;
        if (day < 10) {
          date += '0';
        }

        date += `${day}`;
        dataRequest = getData(this.props.dog.ID, date);
      } else if (this.props.dog.tracker) {
        const now = new Date();
        let date = `${now.getFullYear()}-`;

        const month = now.getUTCMonth() + 1;

        if (month < 10) {
          date += '0';
        }

        date += `${month}-`;

        const day = now.getUTCDate();
        if (day < 10) {
          date += '0';
        }

        date += `${day}`;

        dataRequest = getData(this.props.dog.ID, date);
      }

      Promise.all([dataRequest, orderRequest, portionRequest, caloriesRequest])
        .then(
          ([
            dataResponse,
            orderResponse,
            portionResponse,
            caloriesResponse,
          ]) => {
            const newState = Object.assign({}, this.state);

            if (dataResponse) {
              newState.activityTime = dataResponse.activity_time;
              newState.kasperPoints = dataResponse.points;
              newState.kasperPointsGoal =
                dataResponse.required_points !== 0
                  ? dataResponse.required_points
                  : 1;
              newState.steps = dataResponse.steps;
              newState.distance = dataResponse.distance;
              newState.restTime = dataResponse.rest_time;
              newState.stepRotation =
                newState.kasperPoints / newState.kasperPointsGoal;
            }

            orderResponse.sort((a, b) => a.date_required > b.date_required);
            newState.orders = orderResponse;

            if (caloriesResponse) {
              if (caloriesResponse.food !== null) {
                newState.portionsToday = caloriesResponse.food.portion;
                newState.bespokeCalories = caloriesResponse.food.calories;
              }

              newState.maxCalories = caloriesResponse.totalCalories;
              newState.dailyCalories = caloriesResponse.calories;

              // Removes items with no name
              const filterPortions = [
                ...portionResponse.filter(
                  (p) => p.Food && p.Food.name !== ''
                ),
              ];

              const portions = filterPortions.map((p) => ({
                name: p.Food.name,
                brand: p.Food.brand,
                calories: p.Food.subCategory === "Dry" ? Math.round(p.Food.calories * p.amount / p.Food.portion) : Math.round(p.Food.calories * p.amount),
                added: false,
                amount: p.amount,
                unit: p.Food.units,
                percentage:
                  (p.Food.calories * p.amount * 100) / this.state.maxCalories,
                subCategory: 'None',
                id: p.ID,
                date: new Date(Date.parse(p.CreatedAt)),
                food: p.Food,
              }));

              const shownPortions = this.filterPortions(portions);

              newState.portions = portions;
              newState.shownPortions = [...shownPortions];
              newState.calorieRotation =
                shownPortions.reduce((prev, next) => prev + next.calories, 0) /
                newState.maxCalories;
            }

            this.setState(newState, () => {
              // Rotates the dials on load
              setTimeout(() => {
                this.setState({
                  stepRotation: newState.stepRotation * 360,
                  calorieRotation: newState.calorieRotation * 360,
                });
              }, 1000);
            });

            resolve();
          }
        )
        .catch(() => {
          this.setState({
            kasperPoints: -1,
            kasperPointsGoal: -1,
            steps: -1,
            distance: -1,
            activityTime: -1,
            restTime: -1,
          });

          calories(this.props.dog.ID).then((resp) => {
            this.setState({
              maxCalories: resp.totalCalories,
              dailyCalories: resp.calories,
              food: this.state.food || resp.food,
              portionsToday: resp.food.portion,
              bespokeCalories: resp.food.calories,
            });
          });
          getPortions(this.props.dog.ID).then((resp) => {
            const filterPortions = [
              ...resp.filter((p) => p.Food && p.Food.name !== ''),
            ];
            const portions = filterPortions.map((p) => ({
              name: p.Food.name,
              brand: p.Food.brand,
              calories: p.Food.subCategory === "Dry" ? Math.round(p.Food.calories * p.amount / p.Food.portion) : Math.round(p.Food.calories * p.amount),
              added: false,
              amount: p.amount,
              unit: p.Food.units,
              percentage:
                (p.Food.calories * p.amount * 100) / this.state.maxCalories,
              subCategory: 'None',
              id: p.ID,
              date: new Date(Date.parse(p.CreatedAt)),
              food: p.Food,
            }));

            const shownPortions = this.filterPortions(portions);
            this.setState({
              portions,
              shownPortions: [...shownPortions],
              calorieRotation:
                shownPortions.reduce((prev, next) => prev + next.calories, 0) /
                this.state.maxCalories,
            });
          });
        });
    });
  }

  updateDimensions() {
    const imageWidth = this.image.offsetWidth;
    const windowWidth = window.innerWidth;

    const dateRowHeight = this.dateRowContainer.clientHeight;
    const dialRowHeight = this.dialRowContainer.clientHeight;
    const iconRowHeight = this.iconRowContainer.clientHeight;

    const overallHeight = dateRowHeight + dialRowHeight + iconRowHeight - 16; // -16 because of the padding at the bottom of the row

    this.setState({
      imageWidth,
      windowWidth,
    });

    this.setState({ isMobile: window.innerWidth <= 480 });

    // This will set the height of the
    if (this.state.windowWidth >= 576) {
      this.setState({ generalInfoHeight: overallHeight });
    } else {
      this.setState({ generalInfoHeight: 'auto' });
    }
  }

  filterPortions(portions) {
    const beginDate = new Date(
      this.state.year,
      this.state.month,
      this.state.date
    );
    const endDate = new Date(
      this.state.year,
      this.state.month,
      this.state.date
    );

    beginDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    endDate.setDate(endDate.getDate() + 1);

    const shownPortions = portions
      .filter((p) => beginDate < p.date && endDate > p.date)
      .map((p) => {
        p.percentage = Math.round(p.percentage);
        return p;
      });

    return shownPortions;
  }

  dialdeg(pt, tt) {
    if (pt === 0) return { deg: 0, height: 0, left: 0 };
    if (tt === 0) return { deg: 0, height: 0, left: 0 };

    let krotate = (pt / tt).toFixed(2);

    if (krotate <= 0.25) {
      return { deg: (pt / tt) * 360, height: - 20 * krotate, left: - 20 * krotate }
    } else if (krotate > 0.25 && krotate <= 0.5) {
      return { deg: (pt / tt) * 360, height: - 20 * krotate, left: 5 - 20 * (krotate - 0.25) }
    } else if (krotate > 0.5 && krotate <= 0.75) {
      return { deg: (pt / tt) * 360, height: -10 + 20 * (krotate - 0.5), left: 20 * (krotate - 0.5) }
    } else if (krotate > 0.75 && krotate <= 1) {
      return { deg: (pt / tt) * 360, height: 5 - 20 * (krotate - 0.75), left: 5 - 20 * (krotate - 0.75) }
    }
  }

  render() {
    if (this.props.dog.dob === 'unknown') {
      console.error('Dog DOB not known');
    }

    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;
    const convDob = new Date(this.props.dog.dob);
    const birthYear = convDob.getFullYear();
    const birthMonth = convDob.getMonth() + 1;
    let ageMonth = currentMonth - birthMonth;
    let ageYear = currentYear - birthYear;

    if (birthMonth <= currentMonth) {
      ageYear = currentYear - birthYear;
      ageMonth = currentMonth - birthMonth;
    } else {
      ageYear = currentYear - birthYear - 1;
      ageMonth = 12 - birthMonth + currentMonth;
    }

    const activities = {
      1: 'Sedentary',
      2: 'Chillaxed',
      3: 'Lazy',
      4: 'Hesitant',
      5: 'Playful',
      6: 'Active',
      7: 'Turbo',
      8: 'Bonkers',
      9: 'Olympic',
    };
    const shapes = {
      1: 'Very thin',
      2: 'Skinny',
      3: 'Average',
      4: 'Chubby',
      5: 'Overweight',
    };

    // medical_conditions
    let medical_conditions = [];
    for (const m in this.props.dog.medical_conditions) {
      medical_conditions.push(this.props.dog.medical_conditions[m].name);
    }
    medical_conditions = medical_conditions.join(', ');

    // allergens
    let allergens = [];
    for (const a in this.props.dog.allergens) {
      allergens.push(this.props.dog.allergens[a].name);
    }
    allergens = allergens.join(', ');

    // diets
    let diets = [];
    for (const d in this.props.dog.diets) {
      diets.push(this.props.dog.diets[d].name);
    }
    diets = diets.join(', ');

    // flavours
    let flavours = [];
    for (const f in this.props.dog.flavours) {
      flavours.push(this.props.dog.flavours[f].name);
    }
    flavours = flavours.join(', ');

    // dob
    let dob = new Date(this.props.dog.dob);
    dob = dob.toLocaleDateString('en-GB', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const {
      kasperPoints,
      kasperPointsGoal,
      dailyCalories,
      maxCalories,
      shownPortions,
      restTime,
      bespokeCalories,
      portionsToday,
      steps,
      distance,
      activityTime,
      notificationTitle,
      fitkolarUrl,
      fitkolarIcon,
    } = this.state;

    const consumedCalories = shownPortions.reduce((prev, next) => prev + next.calories, 0);

    if (this.state.kasperPointsGoal > 0) {
      this.state.stepRotation =
        227 + (this.state.kasperPoints / this.state.kasperPointsGoal) * 180;
    } else {
      this.state.stepRotation = 227;
    }

    if (this.state.maxCalories > 0) {
      this.state.calorieRotation =
        223 + (consumedCalories / this.state.maxCalories) * 90;
    } else {
      this.state.calorieRotation = 223;
    }

    const distanceImg = {
      paddingBottom: '3.3%',
    };

    const dogs = dogsSelectors.getAll();

    return (
      <div className='container'>
        <Navigation />

        {dogs && Object.keys(dogs).length >= 2 &&
          <DogNavigation />
        }

        <SubNavigation />
        <div className='inner-container dashboard'>
          <OpenSansHeading
            title={`Welcome back ${this.props.dog.name} & ${this.props.user.first_name}!`}
          />

          <Row>
            <FullColumn noPaddingBottom>
              <Row>
                <div className='col-12 col-sm-6 col-md-8 col-lg-9 padding-right small'>
                  <Row>
                    <div className='notificationBanner'>
                      <div className='notificationContent'>
                        <img
                          className='notificationIcon'
                          src={fitkolarIcon}
                          alt=''
                        />
                        <div className='greenAnnouncement'>
                          {notificationTitle}
                        </div>
                      </div>

                      <div className="notificationLink">
                        <a href={`${window.location.protocol}//${window.location.host}/product/35`}>Order Fitkolar
                          <img className="gotoLink" src="../../assets/images/right-arrow.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </Row>
                </div>

                <div className='col-12 col-sm-6 col-md-4 col-lg-3 padding-left small padding-bottom'>
                  <Row>
                    <div
                      className='orangeGoToLink'
                      role='button'
                      tabIndex={0}
                      onKeyPress={() => { }}
                      onClick={() => this.props.history.push('/shop')}
                    >
                      Go To The Shop
                    </div>
                  </Row>
                </div>
              </Row>
            </FullColumn>
          </Row>

          <Row>
            <FullColumn noPaddingBottom>
              <Row>
                <div className='col-12 col-sm-6 col-md-8 col-lg-9 padding-right small'>
                  <Row
                    reference={(dateRowContainer) => {
                      this.dateRowContainer = dateRowContainer;
                    }}
                  >
                    <div className='col-12 no-padding padding-bottom'>
                      <DateSelectionBanner
                        onUpdateDate={(val) => {
                          this.setState(val);
                          this.setState({
                            delayDate: val.date,
                            delayMonth: val.month,
                            delayYear: val.year,
                          });
                          this.fetchData(
                            new Date(val.year, val.month, val.date)
                          );
                        }}
                        currentDate={this.state.date}
                        currentMonth={this.state.month}
                        currentYear={this.state.year}
                      />
                    </div>
                  </Row>

                  <Row
                    reference={(dialRowContainer) => {
                      this.dialRowContainer = dialRowContainer;
                    }}
                  >
                    <div className='col-12 col-md-6 padding-right medium padding-bottom'>
                      {this.state.isMobile ? (
                        <BackgroundContainer equalPadding>
                          <div className='dial-container-mobile'>
                            <div className='dial-img'>
                              <img src="../../assets/images/kaspermeter.png" />
                              <img
                                src="../../assets/images/dial-pointer.png"
                                style={{
                                  top: `calc(22% + ${this.dialdeg(kasperPoints, kasperPointsGoal).height}px`,
                                  left: `calc(47% + ${this.dialdeg(kasperPoints, kasperPointsGoal).left}px`,
                                  transform: `rotate(${this.dialdeg(kasperPoints, kasperPointsGoal).deg}deg)`
                                }}
                              />
                            </div>
                            <div className='dial-kaspermeter'>
                              <p>Kaspermeter</p>
                              <p>Kasper Points</p>
                              <p className='points-number'>
                                {kasperPoints != -1 ? kasperPoints : '-'} /{' '}
                                {kasperPointsGoal != -1 ? kasperPointsGoal : '-'}
                              </p>
                              <a href='/dashboard'>Refresh
                                <img
                                  alt=''
                                  className='refresh-icon'
                                  src='assets/images/dashboard-refresh.png'
                                />
                              </a>
                            </div>
                            <div className='dog-img'>
                              <img
                                alt=''
                                src='/assets/images/kaspermeter-head.png'
                              />
                            </div>
                          </div>
                        </BackgroundContainer>
                      ) : (
                          <BackgroundContainer equalPadding>
                            <div className='dial-container'>
                              <img
                                alt=''
                                className='dial'
                                src='/assets/images/kaspermeter-labelled.png'
                              />
                              <img
                                alt=''
                                className='dialPic'
                                src='/assets/images/kaspermeter@4x.png'
                              />
                              <div className='dialText'>Kaspermeter</div>
                              <img
                                alt=''
                                className='dial-pointer'
                                src='/assets/images/dial-pointer.png'
                                style={{
                                  transform: `rotate(${this.state.stepRotation}deg) translate(-50%, -50%)`,
                                }}
                              />
                            </div>
                            <div className='dashboard-result-area'>
                              <img
                                alt=''
                                className='dashboard-result-icons'
                                src='assets/images/kasper-points.png'
                              />
                              <div className='points-text-area'>
                                <p className='points-text'>KASPER POINTS</p>
                                <p className='points-number'>
                                  {kasperPoints != -1 ? kasperPoints : '-'} /{' '}
                                  {kasperPointsGoal != -1 ? kasperPointsGoal : '-'}
                                </p>
                              </div>
                              <div className='points-percent-area'>
                                <p className='points-text'>
                                  {kasperPoints != -1
                                    ? (kasperPoints / kasperPointsGoal).toFixed(2) *
                                    100
                                    : '- '}
                                %
                              </p>
                                <a href='/dashboard'>
                                  <img
                                    alt=''
                                    className='refresh-icon'
                                    src='assets/images/dashboard-refresh.png'
                                  />
                                </a>
                              </div>
                            </div>
                          </BackgroundContainer>
                        )}
                    </div>

                    <div className='col-12 col-md-6 padding-left medium padding-bottom'>
                      {this.state.isMobile ? (
                        <BackgroundContainer equalPadding>
                          <div className='dial-container-mobile'>
                            <div className='dial-img'>
                              <img src="../../assets/images/kaspermeter.png" />
                              <img
                                src="../../assets/images/dial-pointer.png"
                                style={{
                                  top: `calc(22% + ${this.dialdeg(consumedCalories, maxCalories).height}px`,
                                  left: `calc(47% + ${this.dialdeg(consumedCalories, maxCalories).left}px`,
                                  transform: `rotate(${this.dialdeg(consumedCalories, maxCalories).deg}deg)`
                                }}
                              />
                            </div>
                            <div className='dial-kalorometer'>
                              <p>Kalorometer</p>
                              <p>Total Calories</p>
                              <p>
                                {consumedCalories}/{maxCalories} kcal
                              </p>
                              <a href='/dashboard'>Refresh
                                <img
                                  alt=''
                                  className='refresh-icon'
                                  src='assets/images/dashboard-refresh.png'
                                />
                              </a>
                            </div>
                            <div className='dog-img'>
                              <img
                                alt=''
                                src='/assets/images/kaspermeter-head.png'
                              />
                            </div>
                          </div>
                        </BackgroundContainer>
                      ) : (
                          <BackgroundContainer equalPadding>
                            <div className='dial-container'>
                              <img
                                alt=''
                                className='dial'
                                src='/assets/images/kalorometer-labelled.png'
                              />
                              <img
                                alt=''
                                className='dialPicTwo'
                                src='/assets/images/kaspermeter-head.png'
                              />
                              <div className='dialText'>Kalorometer</div>
                              <img
                                alt=''
                                className='dial-pointer'
                                src='/assets/images/dial-pointer.png'
                                style={{
                                  transform: `rotate(${this.state.calorieRotation}deg) translate(-50%, -50%)`,
                                }}
                              />
                            </div>
                            <p className='dashboard-text'>
                              <a
                                className='dashboard-text dial'
                                href='/your-dogs-food'
                              >
                                {/* Edit calories */}
                                CALORIES
                              </a>
                            </p>
                            <div className='dashboard-result-area'>
                              <img
                                alt=''
                                className='dashboard-result-icons'
                                src='assets/images/total-calories.png'
                              />
                              <div className='points-text-area'>
                                <p className='points-text'>TOTAL CALORIES</p>
                                <p className='points-number'>
                                  {consumedCalories}/{maxCalories}
                                </p>
                              </div>
                              <div className='points-percent-area'>
                                <p className='points-text'>
                                  {maxCalories > 0
                                    ? Math.round(
                                      (consumedCalories / maxCalories).toFixed(
                                        2
                                      ) * 100
                                    )
                                    : '- '}
                                  %
                                </p>
                                <a href='/dashboard'>
                                  <img
                                    alt=''
                                    className='refresh-icon'
                                    src='assets/images/dashboard-refresh.png'
                                  />
                                </a>
                              </div>
                            </div>
                          </BackgroundContainer>
                        )}
                    </div>
                  </Row>
                  <Row
                    reference={(iconRowContainer) => {
                      this.iconRowContainer = iconRowContainer;
                    }}
                    className='active-contain'
                  >
                    <div className='col-12 col-md-6 padding-right medium'>
                      <Row>
                        <div className='col-6 padding-right small all padding-bottom'>
                          <div className="activity-container-dash">
                            <img
                              alt=''
                              className='dog-info-icon distanceImg'
                              src='/assets/images/distance.png'
                              style={distanceImg}
                            />
                            <div>
                              <p className='dashboard-text icon'>Distance</p>
                              <p
                                className={'dashboard-text' + (this.props.dog.tracker ? ' gray' : ' orange')}
                                style={
                                  this.props.dog.tracker ? {} : { fontSize: 14 }
                                }
                              >
                                {this.props.dog.tracker
                                  ? distance !== -1
                                    ? `${(distance * 0.6213712).toFixed(2)} Miles`
                                    : '-'
                                  : 'Fitkolar not set up'}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className='col-6 padding-left small all padding-bottom'>
                          <div className="activity-container-dash">
                            <img
                              alt=''
                              className='dog-info-icon'
                              src='/assets/images/steps-icon.png'
                              style={distanceImg}
                            />
                            <div>
                              <p className='dashboard-text icon'>Steps</p>
                              <p
                                className={'dashboard-text' + (this.props.dog.tracker ? ' gray' : ' orange')}
                                style={
                                  this.props.dog.tracker ? {} : { fontSize: 14 }
                                }
                              >
                                {this.props.dog.tracker
                                  ? steps != -1
                                    ? `${steps} STEPS`
                                    : '-'
                                  : 'Fitkolar not set up'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>

                    <div className='col-12 col-md-6 padding-left medium'>
                      <Row>
                        <div className='col-6 padding-right small all padding-bottom'>
                          <div className="activity-container-dash">
                            <img
                              alt=''
                              className='dog-info-icon'
                              src='/assets/images/calories-icon.png'
                            />
                            <div>
                              <p className='dashboard-text icon'>Time Active</p>
                              <p
                                className={'dashboard-text' + (this.props.dog.tracker ? ' gray' : ' orange')}
                                style={
                                  this.props.dog.tracker ? {} : { fontSize: 14 }
                                }
                              >
                                {this.props.dog.tracker
                                  ? activityTime !== -1
                                    ? `${Math.round(activityTime)} Mins`
                                    : '-'
                                  : 'Fitkolar not set up'}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className='col-6 padding-left small all padding-bottom'>
                          <div className="activity-container-dash">
                            <img
                              alt=''
                              className='dog-info-icon'
                              src='/assets/images/rest-icon.png'
                            />
                            <div>
                              <p className='dashboard-text icon'>Rest</p>
                              <p
                                className={'dashboard-text' + (this.props.dog.tracker ? ' gray' : ' orange')}
                                style={
                                  this.props.dog.tracker ? {} : { fontSize: 14 }
                                }
                              >
                                {this.props.dog.tracker
                                  ? restTime !== -1
                                    ? `${Math.floor(
                                      restTime / 60
                                    )}H, ${Math.round(restTime % 60)}M`
                                    : '-'
                                  : 'Fitkolar not set up'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Row>
                </div>

                {this.state.isMobile ? (
                  <div className='col-12 col-sm-6 col-md-4 col-lg-3 padding-left small padding-bottom'>
                    <div className='general-info-container' style={{ borderRadius: 12 }} />

                    <div
                      style={{ height: this.state.generalInfoHeight }}
                      className='gi-content'
                    >
                      <div className='gi-content-title'>
                        <div className="image-area">
                          <img src={this.props.dog.image.Url ? `http://${this.props.dog.image.Url})` : "/assets/images/kasper-image-white.png"} />
                          <p>{this.props.dog.name}</p>
                        </div>
                      </div>
                      <div className='gi-content-general'>
                        <table className="gi-content-table">
                          <tbody>
                            <tr>
                              <th>General information</th>
                              <th><img src="../../assets/images/edit.png" onClick={() => { window.location.href = './dog-profile'; }} /></th>
                            </tr>
                            <tr>
                              <td className="label">Name</td>
                              <td className="information" style={{ textTransform: 'uppercase' }}>{this.props.dog.name}</td>
                            </tr>
                            <tr>
                              <td className="label">Gender</td>
                              <td className="information">{this.props.dog.gender}</td>
                            </tr>
                            <tr>
                              <td className="label">Age</td>
                              <td className="information">{ageYear}y {ageMonth}m</td>
                            </tr>
                            <tr>
                              <td className="label">Reproduction Status</td>
                              <td className="information">{this.props.dog.spayed ? 'Spayed' : 'Not Spayed'}</td>
                            </tr>
                            <tr>
                              <td className="label">Breed</td>
                              <td className="information">{this.props.dog.breed.name}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='gi-content-condition'>
                        <table className="gi-content-table">
                          <tbody>
                            <tr>
                              <th>Body Condition</th>
                              <th><img src="../../assets/images/edit.png" onClick={() => { window.location.href = './dog-profile'; }} /></th>
                            </tr>
                            <tr>
                              <td className="label">Weight</td>
                              <td className="information">{`${this.props.dog.weight.toFixed(2)} kg`}</td>
                            </tr>
                            <tr>
                              <td className="label">Body Condition</td>
                              <td className="information">{shapes[this.props.dog.shape]}</td>
                            </tr>
                            <tr>
                              <td className="label">Activity Level</td>
                              <td className="information">{activities[this.props.dog.activity]}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='gi-content-health'>
                        <table className="gi-content-table">
                          <tbody>
                            <tr>
                              <th>Health Details</th>
                              <th><img src="../../assets/images/edit.png" onClick={() => { window.location.href = './dog-profile'; }} /></th>
                            </tr>
                            <tr>
                              <td className="label">Health issues</td>
                              <td className="information">{medical_conditions}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='gi-content-more'>
                        <button onClick={() => { window.location.href = './dog-profile'; }} >
                          Go To Profile
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                    <div className='col-12 col-sm-6 col-md-4 col-lg-3 padding-left small padding-bottom'>
                      <div className='general-info-container' />
                      <div
                        style={{ height: this.state.generalInfoHeight }}
                        className='gi-content'
                      >
                        <div className='dog-image-border small'>
                          <img
                            alt=''
                            className='dog-image-frame'
                            src='assets/images/dog-image-frame.png'
                          />
                          <div
                            ref={(image) => {
                              this.image = image;
                            }}
                            className='dog-image-container'
                            style={{
                              height: this.state.imageWidth,
                              backgroundImage:
                                this.props.dog.image.Url !== ''
                                  ? `url(http://${this.props.dog.image.Url})`
                                  : 'url(/assets/images/kasper-image-white.png)',
                            }}
                          />
                        </div>
                        <div className='gi-text'>
                          <OrangeTextHeading
                            text='General Information'
                            fontSize={30}
                          />
                          <Stat subject='Gender' value={this.props.dog.gender} />
                          <Stat subject='Breed' value={this.props.dog.breed.name} />
                          <Stat subject='Age (years)' value={ageYear} />
                          <Stat subject='Age (months)' value={ageMonth} />
                          <Stat subject='Date of birth' value={dob} />
                          {this.props.dog.gender === 'male' ? (
                            <Stat
                              subject='Neutered'
                              value={this.props.dog.neutered ? 'Yes' : 'No'}
                            />
                          ) : (
                              <Stat
                                subject='Spayed'
                                value={this.props.dog.spayed ? 'Yes' : 'No'}
                              />
                            )}
                          <Stat
                            subject='Weight'
                            value={`${this.props.dog.weight.toFixed(2)} kg`}
                          />
                          <Stat
                            subject='Height'
                            value={`${this.props.dog.height.toFixed(2)} cm`}
                          />
                          <Stat
                            subject='Activity levels'
                            value={activities[this.props.dog.activity]}
                          />
                          <Stat
                            subject='Health Issues'
                            value={medical_conditions}
                          />
                          <Stat
                            subject='Body condition'
                            value={shapes[this.props.dog.shape]}
                          />
                        </div>
                        <div className='eh-text'>
                          <OrangeTextHeading text='Eating Habits' fontSize={30} />
                          <Stat
                            subject='Allergies'
                            value={this.props.dog.is_allergic ? 'Yes' : 'No'}
                          />
                          <Stat
                            subject='Titbits'
                            value={this.props.dog.titbits ? 'Yes' : 'No'}
                          />
                          <Stat subject='Ingredients left out' value={allergens} />
                          <Stat subject='Diet preference' value={diets} />
                          <Stat subject='Favourite flavours' value={flavours} />

                          <button
                            onClick={() => {
                              scrollTo(0, 0);
                              window.location.href = './dog-profile';
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
              </Row>
            </FullColumn>
          </Row>

          <Row>
            <FullColumn noPaddingBottom>
              <Row>
                <div className='col-12 col-sm-6 col-md-7 col-lg-8 padding-right padding-bottom small full-height'>
                  {this.state.isMobile ? (
                    <BackgroundContainer equalPadding orangeBorder>
                      <div className='daily-menu-title'>
                        <img src="../../assets/images/dog-bowl.png" />
                        <div className='no-padding padding-bottom'>
                          <p className='dashboard-text icon'>{this.props.dog.name}'s Bespoke Daily Menu</p>
                          <p className='dashboard-text gray'>{dailyCalories} kcal per day</p>
                        </div>
                      </div>
                      <Row>
                        <table className="dashboard-daily-menu-table">
                          <tbody>
                            <tr>
                              <th>Title</th>
                              <th>Today's portion</th>
                            </tr>
                            <tr>
                              <td className="label">Bespoke dry food</td>
                              <td className="information">{portionsToday}g</td>
                            </tr>
                          </tbody>
                        </table>
                      </Row>
                    </BackgroundContainer>
                  ) : (
                      <MonthlyMenu
                        title='Bespoke Daily Menu'
                        dog={this.props.dog}
                        items={[
                          {
                            name: 'Bespoke dry food',
                            portion: `${portionsToday}g`,
                            calories: `${
                              (bespokeCalories / maxCalories).toFixed(2) * 100
                              }%`,
                          },
                        ]}
                        calories
                        imageWidth={this.state.imageWidth}
                        portionTitle="Today's portions"
                        caloriesTitle='Calories breakdown'
                      />
                    )}
                </div>

                <div className='col-12 col-sm-6 col-md-5 col-lg-4 padding-left padding-bottom small full-height'>
                  {this.state.isMobile ? (
                    <BackgroundContainer equalPadding orangeBorder>
                      <div className='order-menu-title'>
                        <img src="../../assets/images/checkout-van-new.png" />
                        <p className='dashboard-text icon'>Order & Delivery</p>
                      </div>
                      <Row>
                        <NextOrderInfo
                          isMobile={this.state.isMobile}
                          orders={this.state.orders}
                        />
                      </Row>
                    </BackgroundContainer>
                  ) : (
                      <BackgroundContainer equalPadding>
                        <Row>
                          <div className='col-12 no-padding padding-bottom'>
                            <GreyText
                              text={`${this.props.dog.name}'s Next Delivery`}
                              fontSize='22'
                              centered
                              marginBottom
                              bold
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className='col-12 col-lg-8 order-2 order-lg-1 no-padding'>
                            <OrangeTextHeading
                              text='Order &amp; Delivery'
                              fontSize={20}
                              bold
                            />
                          </div>
                          <div className='col-6 col-lg-4 order-1 order-lg-2 no-padding'>
                            <img
                              className='dashboard-delivery-image'
                              src='/assets/images/checkout-van.png'
                              alt='van'
                            />
                          </div>
                        </Row>
                        <Row>
                          <NextOrderInfo
                            user={this.props.user}
                            orders={this.state.orders}
                          />
                        </Row>
                      </BackgroundContainer>
                    )}
                </div>
              </Row>
            </FullColumn>
          </Row>
        </div >
        <Footer isMobile={this.state.isMobile} />
      </div >
    );
  }
}

export default connect(
  (state) => ({
    dogs: state.data.dogs.dogs,
    dog: dogsSelectors.getCurrentDog(),
    user: sessionSelectors.getUser(),
  }),
  (dispatch) => ({
    actions: {
      dogs: bindActionCreators(dogsActionCreators, dispatch),
      orders: bindActionCreators(ordersActionCreators, dispatch),
    },
  }),
  null,
  { withRef: true }
)(DashboardScreen);
