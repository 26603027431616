import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as selectors from "../../data/dogs/selectors";
import * as actionTypes from '../../data/exports';
import WizardLayout from '../../components/wizard/WizardLayout';
import RadioInput from '../../components/wizard/RadioInput';
import Tip from '../../components/wizard/Tip';
import PopUpIcon from '../../assets/images/wizard/health-option-icon.png';
import NeuteredTip from '../../assets/images/wizard/tips/neutered.svg';
import Button from '../../components/Button';
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import RadioGroup from "@material-ui/core/es/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/es/Radio/Radio";

class Neutered extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dog: this.props.currentDog,
        };

        this.handleStep = (step, e) => {
            e.preventDefault();
            this.props.history.push(step);
        };

        this.handleRadioInput = (e) => {
            const dogIndex = this.props.getIndex;
            this.props.updateCurrentDog('neutered', dogIndex, Boolean(Number(e.target.value)));
            this.props.history.push('/age');
        };

        this.handleModal = () => {
            $('#neutered').modal('show');
        };
    }

    componentDidMount() {
        const dogIndex = this.props.getIndex;

        if(this.props.currentDog.neutered === null){
            this.props.updateCurrentDog('progress', dogIndex, 8);
        }

        const {pathname} = this.props.location;
        this.props.updateCurrentDog('step', dogIndex, pathname);
        this.props.toggleDogNav(true);
    }

    render() {
        const radioStyles = {
            flexDirection: 'row',
            justifyContent: 'space-evenly'
        };
        return (
            <WizardLayout
                title=""
                wizardProgress={this.props.progress}
            >
                <form className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 row pb-4 justify-content-center px-3">
                    <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                        is {this.props.currentDog.name} neutered?
                        <span className="pop-up-link" onClick={this.handleModal}>
                            <a><img className="ml-2 mb-1" src={PopUpIcon}/></a>
                        </span>
                    </h5>
                    <FormControl component="fieldset" className={"material-radio-buttons"} style={{width: '65%'}}>
                        <RadioGroup
                            style={radioStyles}
                            aria-label="neutered"
                            name="neutered"
                            value={this.props.currentDog.neutered !== null ? this.props.currentDog.neutered ? '1' : '0' : null }
                            onChange={this.handleRadioInput}
                        >
                            <FormControlLabel
                                value="1"
                                control={<Radio color="primary"  />}
                                label="Yes"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="0"
                                control={<Radio color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </form>
                <div style={{maxWidth:'390px'}} className="col-xl-10 col-lg-11 col-md-12 col-12 px-2 row justify-content-center">
                    <div className="col-6 pl-xl-4 pl-lg-4 pr-1 text-center">
                        <Button

                            onClick={e => this.handleStep('/gender', e)}
                            classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                            label="BACK"
                        />
                    </div>
                    <div className="col-6 pr-xl-4 pr-lg-4 pl-1 text-center">
                        <Button
                            disabled={this.props.currentDog.neutered === null}
                            onClick={e => this.handleStep('/age', e)}
                            classes="button-primary fill orange animated fadeInRight delay-_2s"
                            label="NEXT"
                        />
                    </div>
                </div>
                <Tip
                    isButton={false}
                    title=""
                    description={`Neutering will change your ${this.props.currentDog.name}'s natural metabolism. As she may be at more risk to putting more weight on, we adapt the blend to ensure the calories are appropriate.`}
                    target="neutered"
                    isImage={true}
                    image={NeuteredTip}
                    btnLabel=""
                />
            </WizardLayout>
        );
    }
}

const mapStateToProps = state => ({
    storeDogs: state.data.dogs.storeDogs,
    currentDog: state.data.dogs.currentDog,
    getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
    updateCurrentDog: (key, index, value) =>
        dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
    toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Neutered));
