import React from 'react';

export default class WizardInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTip: true,
      addDogInput: this.props.addDogInput,
    };

    this.handleInput = (e) => {
      this.setState({ showTip: e.target.value === '' });
      this.props.onChangeText(e);
    };

    this.handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        this.props.onKeyPress(e);
      }
    };

  }
  render() {
    const {
      type,
      max,
      value,
      placeholder,
      tip,
      keyTip,
      disabled,
      reference,
      name,
      autofocus,
      pattern,
      animation,
      error,
      errorMessage
    } = this.props;

    let customValue = value;
    if (name === 'dob' && value !== '') {
      customValue = customValue.split('/').reverse().join('-')
    }

    return (
      <div className={`wizard-input-container ${error ? 'error' : ''}`}>
        <input
          className={`wizard-input w-100 px-3 my-3 animated ${animation} ${error ? 'error' : ''} ${name === 'dob' ? 'unstyled' : ''}`}
          min="0"
          maxLength={max !== undefined ? max : '100'}
          autoFocus={autofocus}
          type={type}
          name={name}
          value={customValue}
          onChange={this.handleInput}
          onSelect={() => name === 'dob' ? this.handleInput() : ''}
          placeholder={placeholder}
          pattern={pattern !== undefined ? pattern : null}
          disabled={disabled}
          ref={reference}
          onKeyPress={this.handleKeyPress}
          onBlur={this.handleBlur}
          required
        />
        {this.state.showTip ? <span className="tip animated fadeIn">{tip}</span> : ''}
        {this.state.addDogInput && !this.state.showTip ? <span className="tip keyevent animated fadeIn">{keyTip}</span> : ''}
        {error ? <span className="error-message">{errorMessage}</span> : ''}
      </div>
    );
  }
}
