import React from 'react';

export default class Row extends React.Component {
  render() {
    const { reference } = this.props;
    let { className } = this.props;
    if (className === undefined) {
      className = '';
    }

    return (
      <div
        className={`col-12 px-0 row mx-0 justify-content-center ${className}`}
        ref={reference}
      >
        {this.props.children}
      </div>
    );
  }
}
