import { createMuiTheme } from '@material-ui/core/styles';

/*
 * Breakpoints similar to custom breakpoints defined in _variables.scss
 * which will override default materialUI breakpoints.
 */
const customTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      main: '#fab522',
    },
    secondary: {
      main: '#007853',
    },
  },
});
export default customTheme;
