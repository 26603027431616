import { store } from '../../store';

export const getAll = () => {
  const items = store.getState().data.dogs.dogs;
  const itemsArray = Object.keys(items).map(itemKey => items[itemKey]);
  itemsArray.sort((item1, item2) => parseInt(item1.ID, 10) > parseInt(item2.ID, 10));
  return itemsArray;
};

export const get = id => store.getState().data.dogs.dogs[id];

export const count = () => getAll().length;

export const getAllBreeds = () => {
  const items = store.getState().data.dogs.breeds;
  const itemsArray = Object.keys(items).map(itemKey => items[itemKey]);
  itemsArray.sort((item1, item2) => item1.name.localeCompare(item2.name));
  return itemsArray;
};

export const getAllAllergens = () => {
  const items = store.getState().data.dogs.allergens;
  const itemsArray = Object.keys(items).map(itemKey => items[itemKey]);
  itemsArray.sort((item1, item2) => item1.name.localeCompare(item2.name));
  return itemsArray;
};

export const getAllFlavours = () => {
  const items = store.getState().data.dogs.flavours;
  const itemsArray = Object.keys(items).map(itemKey => items[itemKey]);
  itemsArray.sort((item1, item2) => item1.name.localeCompare(item2.name));
  return itemsArray;
};

export const getAllConditions = () => {
  const items = store.getState().data.dogs.conditions;
  const itemsArray = Object.keys(items).map(itemKey => items[itemKey]);
  itemsArray.sort((item1, item2) => item1.name.localeCompare(item2.name));
  return itemsArray;
};

export const getAllDiets = () => {
  const items = store.getState().data.dogs.diets;
  if (items === undefined) {
    return [];
  }

  const itemsArray = Object.keys(items).map(itemKey => items[itemKey]);
  itemsArray.sort((item1, item2) => item1.name.localeCompare(item2.name));
  return itemsArray;
};

export const getCurrentDog = () => {
  const current = store.getState().data.dogs.currentDog;

  return get(current);
};

export const getCurrentDogIndex = () => {
  let dogIndex = null;
  const storeDogs = store.getState().data.dogs.storeDogs;
  const currentDog = store.getState().data.dogs.currentDog;
  storeDogs.map((dog, index) => {
    if (dog.name === currentDog.name) {
      dogIndex = index;
      return true;
    }
  });
    return dogIndex;
};
