import React from 'react';

export default class OrangeTextHeading extends React.Component {
  render() {
    const {
      text, centered, marginBottom, fontSize,
    } = this.props;
    return (
      <p
        className={`${(centered ? ' center ' : '') + (marginBottom ? ' margin-bottom ' : '')}orange-title-text`}
        style={fontSize ? { fontSize } : undefined}
      >
        {text}
      </p>
    );
  }
}
