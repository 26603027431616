import React from 'react';

export default function FullColumn(props) {
  const { noPaddingBottom } = props;

  return (
    <div className={`${noPaddingBottom ? ' no-padding-bottom ' : ''}col-12 align-items-center padding`}>
      {props.children}
    </div>
  );
}
