import { combineReducers } from 'redux';
import dogsReducer from './dogs/reducer';
import ordersReducer from './orders/reducer';
import productsReducer from './products/reducer';
import wizardReducer from './wizard/reducer';
import cartReducer from './cart/reducer';
import deliveryReducer from './checkout/deliveryReducer';
import billingAddressReducer from './checkout/billingAddressReducer';
import promocodeReducer from './checkout/promocodeReducer';

export default combineReducers({
  dogs: dogsReducer,
  orders: ordersReducer,
  products: productsReducer,
  wizard: wizardReducer,
  cart: cartReducer,
  delivery: deliveryReducer,
  promocode: promocodeReducer,
  billingAddress: billingAddressReducer,
});
