import * as actionTypes from './actionTypes';
import * as api from './api';

export const update = state => ({
  type: actionTypes.UPDATE,
  state,
});

export const clear = () => ({
  type: actionTypes.CLEAR,
});

export const updatePromocode = state => ({
  type: actionTypes.UPDATE_PROMOCODE,
  state,
})

export const updateQuestionnaire = state => {
  return ({
    type: actionTypes.UPDATE_QUESTIONNAIRE,
    state,
  })
}


export const updateIncompleteOrder = state => {
  return ({
    type: actionTypes.UPDATE_INCOMPLETE_ORDER,
    state
  })
}

/**
 * Get orders
 */
export const getOrders = () => dispatch => (
  new Promise((resolve) => {
    dispatch(clear());
    api.orders().then((orders) => {
      dispatch(update({ orders }));
      resolve(orders);
    });
  })
);

/**
 * Create order
 */
export const createOrder = order => dispatch => (
  new Promise((resolve) => {
    api.ordernew(order).then((response) => {
      if (response.success) {
        resolve(response.order_id);
      } else {
        reject(response);
      }
    });
  })
);

/**
 * Pay order
 */
export const orderpay = payload => dispatch => (
  new Promise((resolve, reject) => {
    api.orderpay(payload)
      .then(resp => resolve(resp))
      .catch(() => reject());
  })
);


/**
 * Update card details
 */
export const updateCard = payload => dispatch => (
  new Promise((resolve, reject) => {
    api.updateCard(payload)
      .then(resp => resolve(resp))
      .catch(() => reject());
  })
);


/**
 * Get promocode
 */
export const getPromocode = code => dispatch => (
  new Promise((resolve) => {
    api.promocode(code)
      .then((promo) => {
        dispatch(update({ promo }));
        resolve(promo);
      });
  })
);

/**
 * Get refer a friend promocode
 */
export const getReferPromocode = () => dispatch => (
  new Promise((resolve) => {
    api.referPromo()
      .then((referralPromos) => {
        dispatch(update({ referralPromos }));
        resolve(referralPromos);
      });
  })
);

/**
 * Apply with promocode
 */
export const applyReferPromo = (oderID, code) => dispatch => {
  new Promise((resolve) => {
    api.applyReferPromo(oderID, code)
      .then((res) => {
        dispatch(updatePromocode(res.UsedPromo))
        resolve(res);
      })
  })
}

export const getIncompleteOrder = (dogID) => dispatch => (
  new Promise((resolve) => {
    api.getIncompleteOrder(dogID).then((order) => {
      dispatch(updateIncompleteOrder(order));
      resolve(order);
    });
  })
);
