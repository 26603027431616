import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import * as session from '../../services/session';

import Navigation from '../../components/Navigation';
import ImageBanner from '../../components/ImageBanner';
import SubNavigation from '../../components/SubNavigation';
import TitleBox from '../../components/TitleBox';
import Footer from '../../components/Footer';
import FullColumn from '../../components/FullColumn';
import HalfColumn from '../../components/HalfColumn';
import Row from '../../components/Row';
import InputField from '../../components/InputField';
import SubmitButton from '../../components/SubmitButton';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import Input from '../../components/Input';
import FormErrors from '../../components/FormErrors';
import Button from '../../components/Button';
import ForgotPasswordCard from '../../components/ForgotPasswordCard';
import dogImage from '../../assets/images/forgot_pass_dog.png';
import askImage from '../../assets/images/ask.png';
import askImageTablet from '../../assets/images/ask-tablet.png';

export default class ForgottenPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      redirect: false,
      errors: [],
    };

    this.handleChange = (state, event) => {
      this.setState({ [state]: event.target.value });
    };

    this.attemptReset = (e) => {
      e.preventDefault();
      this.setState({ errors: [] });
      const { email } = this.state;

      if (email.length > 0) {
        session.forgottenPassword( {email} ).then(() => {
          localStorage.setItem('reset_email', email);
          this.setState({ redirect: true });
        })
          .catch(() => console.log('Error occurred'));
      } else {
        setTimeout(() => {
          this.setState({ errors: ['Enter your email address to reset password.'] });
        });
      }
    };
  }

  render() {
    return (
      <div className="container-fluid forgotten-password-container">
        <div className="row justify-content-center h-100">
          <div className="col-xl-5 col-lg-7 col-md-12 col-12 row justify-content-center align-items-center">
            <div className="col-xl-9 col-lg-9 col-md-7">
              <div className={"forgot-card-container"}>
                  <ForgotPasswordCard
                      title="Forgot password?"
                      description={"Let's get you a new one :)" + '\n' + 'Enter your email you used for Kasper & Kitty sign up, and we will help you to create a new password.'}
                  >
                      <div className="row justify-content-center py-3">
                          <div className="col-xl-9 col-lg-9 col-md-9">
                              <FormErrors errors={this.state.errors} />
                              <div className="input-container">

                                  <Input
                                      type="email"
                                      value={this.state.email}
                                      placeholder="Email Address*"
                                      onChangeText={event => this.handleChange('email', event)}
                                  />
                                  <span className="icon fa fa-envelope" />

                              </div>
                              <p className="text-left input-tip w-100 pb-3">* Required field</p>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-8">
                              <Button
                                  onClick={event => this.attemptReset(event)}
                                  classes="button-primary fill red mb-3 animated fadeInUp"
                                  label="SEND"
                              />
                              <a href="https://www.kasperandkitty.com/support/" target="_blank" title="Support" className="forgot-link">Forgot your email?</a>
                          </div>
                      </div>

                      {this.state.redirect && (<Redirect to="/forgotten-password" />)}
                  </ForgotPasswordCard>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-5 col-md-6 forgotten-password-banner">
            <div className={"forgotten-password-image-container"}>
                <img className={"animated fadeInRight dog-image"} src={dogImage} alt="" />
                <img className={"ask-image animated fadeIn delay-_5s"} src={askImage} alt="" />
                <img className={"ask-image animated fadeIn delay-_5s"} src={askImage} alt="" />
                <img className={"ask-image tablet animated fadeIn delay-_5s"} src={askImageTablet} alt="" />
                <img className={"ask-image tablet animated bounceIn delay-_7s"} src={askImageTablet} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
