import React from 'react';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

export default class NotFood extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
      activeCircleButton: 'Your Pet',
      howItWorks: 'Register',
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // //Adds a span around each letter in the string
    $('.how-it-works-text').lettering();

    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <div className="landing-banner not-food">
          <Navigation textColor="white-text" />
          <div className="text-container">
            <p className="landing-header">Our food</p>
            <p className="landing-subtext">Unleash their pawsomness!</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
