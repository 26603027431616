import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import RadioGroup from "@material-ui/core/es/RadioGroup/RadioGroup";

import Button from '../../components/Button';
import WizardLayout from '../../components/wizard/WizardLayout';
import Dropdown from '../../components/wizard/Dropdown';
import BreedUnknownOption from '../../components/wizard/BreedUnknownOption';
import Tip from '../../components/wizard/Tip';

import breedToyImage from '../../assets/images/wizard/toy.png';
import breedSmallImage from '../../assets/images/wizard/small.png';
import breedMediumImage from '../../assets/images/wizard/medium.png';
import breedLargeImage from '../../assets/images/wizard/large.png';
import breedGiantImage from '../../assets/images/wizard/giant.png';
import TooOld from '../../assets/images/wizard/tips/age_too_old.png';
import PopUpIcon from '../../assets/images/wizard/health-option-icon.png';

import * as selectors from '../../data/dogs/selectors';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as actionTypes from '../../data/exports';
import { fetchApi } from '../../services/api';


class Breed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breeds: [],
      dog: this.props.currentDog,
      selectedBreed: null,
      activeTab: this.props.currentDog.breedTab,
      unknownBreeds: {
        breedToy: false,
        breedSmall: false,
        breedMedium: false,
        breedLarge: false,
        breedGiant: false,
      },
    };
    this.handleStep = (step, e) => {
      e.preventDefault();

      const _crossbreed = this.props.currentDog.crossBreed.filter(item => item !== null);

      if (this.props.currentDog.breed === null && _crossbreed.length === 0) {
        this.setState({ isError: true })
      } else {
        this.props.history.push(step);
      }
    };

    this.handleBackStep = (step, e) => {
      e.preventDefault();
      this.props.history.push(step);
    };

    this.addSelectedProperty = (...arr) => arr.map(item => item.selected = false);

    this.handleRadioInput = (e) => {
      const index = this.props.getIndex;
      this.props.updateCurrentDog('breedTab', index, e.target.value);
    };

    this.toggleUnknownBreeds = breeds => Object.keys(breeds).map(key => breeds[key] = false);

    this.handleCheckbox = (state) => {
      this.toggleUnknownBreeds(this.state.unknownBreeds);
      this.setState({
        unknownBreeds: {
          ...this.state.unknownBreeds,
          [state]: !this.state.unknownBreeds[state],
        },
      });

      let breedName = {
        "breedToy": "Unknown-toy",
        "breedSmall": "Unknown-small",
        "breedMedium": "Unknown-med",
        "breedLarge": "Unknown-large",
        "breedGiant": "Unknown-giant",
      }[state];

      const index = this.props.getIndex;
      let unknownBreed = this.state.breeds.filter(breed => breed.name === breedName)[0];
      if (unknownBreed === undefined) {
        console.error("No " + breedName + " breed configured in backend. Make breed with name " + breedName);
      } else {
        this.props.updateCurrentDog('breed', index, unknownBreed);
      }
    };

    this.toggleSelectedBreed = (breed) => {
      const index = this.props.getIndex;
      this.props.updateCurrentDog('breed', index, breed);
      this.setState({ isError: false })
    };

    this.setCrossBreed = (breed, index) => {
      const dogIndex = this.props.getIndex;
      const dogCrossBreed = this.props.currentDog.crossBreed;
      dogCrossBreed[index] = breed;
      this.props.updateCurrentDog('crossBreed', dogIndex, [...dogCrossBreed]);
      this.setState({ isError: false })
    };

    this.clearCrossBreed = (index) => {
      const dogIndex = this.props.getIndex;
      const dogCrossBreed = this.props.currentDog.crossBreed;
      dogCrossBreed[index] = null;
      this.props.updateCurrentDog('crossBreed', dogIndex, [...dogCrossBreed]);
    };

    this.handleModal = () => {
      $('#breed').modal('show');
    };
  }

  componentDidMount() {
    dogsActionCreators.getBreeds()
      .then((res) => {
        this.setState({ breeds: res });
      })
      .catch(err => console.log(err));

    this.addSelectedProperty(...this.state.breeds);
    const dogIndex = this.props.getIndex;
    const { pathname } = this.props.location;
    if (this.props.currentDog.breedTab === "unknown" && this.props.currentDog.breed != undefined) {
      let currentBreed = 'breedMedium'
      switch (this.props.currentDog.breed.name) {
        case "Unknown-giant":
          currentBreed = 'breedGiant'
          break;
        case "Unknown-large":
          currentBreed = 'breedLarge'
          break;
        case "Unknown-medium":
          currentBreed = 'breedMedium'
          break;
        case "Unknown-small":
          currentBreed = 'breedSmall'
          break;
        case "Unknown-toy":
          currentBreed = 'breedToy'
          break;
        default:
          currentBreed = 'breedMedium'
          break;
      }
      this.toggleUnknownBreeds(this.state.unknownBreeds);
      this.setState({
        unknownBreeds: {
          ...this.state.unknownBreeds,
          [currentBreed]: !this.state.unknownBreeds[currentBreed],
        },
      });

    }


    if (this.props.currentDog.breed === null && this.props.currentDog.crossBreed[0] === null && this.props.currentDog.crossBreed[1] === null) {
      this.props.updateCurrentDog('progress', dogIndex, 25);
    }

    this.props.updateCurrentDog('step', dogIndex, pathname);
    this.props.toggleDogNav(true);
  }

  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      slidesToShow: 8,
      slidesToScroll: 1,
      autoplay: false,
      centerMode: false,
      isError: false,
      speed: 500,
      autoplaySpeed: 3500,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1366,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { selectedBreed } = this.state;
    const radioStyles = {
      flexDirection: 'row',
      justifyContent: 'space-evenly'
    };

    let createBreed = (breedName) => {
      // create breed in db
      return fetchApi("/dogs/breeds", { breed_name: breedName }, "post")
        .then(newBreed => {
          // breed created successfully:
          // update list of breeds in state (which is passed to dropdowns)
          dogsActionCreators.getBreeds()
            .then((res) => {
              this.setState({ breeds: res });
            })
            .catch(err => console.log(err));

          // return the new breed db object
          return newBreed;
        })
        .catch(err => console.log("Could not create breed, check /breeds request: ", err));
    }

    return (
      <div className="container-fluid">
        <WizardLayout
          title=""
          wizardProgress={this.props.progress}
        >
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12 px-0 row justify-content-center">
            <h5 className="w-100 pb-4 pl-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
              What is {this.props.currentDog.name}'s breed?
                        <span className="pop-up-link" onClick={this.handleModal}>
                <a><img className="ml-2 mb-1" src={PopUpIcon} /></a>
              </span>
            </h5>
            <div className="col-12 px-0 row justify-content-center breed-tabs">
              <FormControl component="fieldset" className={"material-radio-buttons"} style={{ width: '100%' }}>
                <RadioGroup
                  style={radioStyles}
                  aria-label="breed"
                  name="breed"
                  value={this.props.currentDog.breedTab}
                  onChange={this.handleRadioInput}
                >
                  <FormControlLabel
                    value="purebred"
                    control={<Radio color="primary" />}
                    label="Purebred"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="crossbreed"
                    control={<Radio color="primary" />}
                    label="Crossbreed"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="unknown"
                    control={<Radio color="primary" />}
                    label="Unknown"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
              <div
                className={`col-12 py-3 tab-view animated fadeIn ${this.props.currentDog.breedTab === 'purebred' ? 'active' : ''}`}>
                <Dropdown
                  value={this.props.currentDog.breed !== null ? this.props.currentDog.breed.name : null}
                  placeholder="Choose a breed"
                  options={this.state.breeds}
                  toggleItem={this.toggleSelectedBreed}
                  onCreateOption={createBreed}
                />
              </div>
              <div
                className={`col-12 pt-2 tab-view animated crossbreed-items fadeIn ${this.props.currentDog.breedTab === 'crossbreed' ? 'active' : ''}`}>
                <div className={"crossbreed-dropdown"}>
                  <Dropdown
                    value={this.props.currentDog.crossBreed[0] !== null ? this.props.currentDog.crossBreed[0].value : null}
                    placeholder="+Add Primary Breed"
                    options={this.state.breeds}
                    toggleItem={(breed) => this.setCrossBreed(breed, 0)}
                    onCreateOption={createBreed}
                  />
                  <span onClick={() => this.clearCrossBreed(0)} className="clear-breed fa fa-trash-o" />
                </div>
                <div className={"crossbreed-dropdown"}>
                  <Dropdown
                    value={this.props.currentDog.crossBreed[1] !== null ? this.props.currentDog.crossBreed[1].value : null}
                    placeholder="+Add Breed"
                    options={this.state.breeds}
                    toggleItem={(breed) => this.setCrossBreed(breed, 1)}
                    onCreateOption={createBreed}
                  />
                  <span onClick={() => this.clearCrossBreed(1)} className="clear-breed fa fa-trash-o" />
                </div>
                <div className={"crossbreed-dropdown"}>
                  <Dropdown
                    value={this.props.currentDog.crossBreed[2] !== null ? this.props.currentDog.crossBreed[2].value : null}
                    placeholder="+Add Breed"
                    options={this.state.breeds}
                    toggleItem={(breed) => this.setCrossBreed(breed, 2)}
                    onCreateOption={createBreed}
                  />
                  <span onClick={() => this.clearCrossBreed(2)} className="clear-breed fa fa-trash-o" />
                </div>
              </div>
            </div>
          </div>
          <Tip
            title={""}
            description={`By knowing your ${this.props.currentDog.name}'s breed, we can create a bespoke diet using average parameters such as height and weight. This allows  us to keep them in top condition.`}
            target="breed"
            isImage={true}
            image={TooOld}
            isButton={false}
            btnLabel=""
          />
        </WizardLayout>
        <div className="row justify-content-center breed-tabs">
          <div className={`col-12 py-3 tab-view animated fadeIn ${this.props.currentDog.breedTab === 'unknown' ? 'active' : ''}`}>
            <div className="row justify-content-center unknown-breed-list">
              <BreedUnknownOption
                animation="fadeIn"
                onClick={() => this.handleCheckbox('breedToy')}
                active={this.state.unknownBreeds.breedToy}
                image={breedToyImage}
                inputLabel="toy"
                weight="under 5kg"
                breeds="Ex. Chihuahua, Pomeranian, Maltese, Yorkshire Terrier"
              />
              <BreedUnknownOption
                animation="fadeIn delay-_2s"
                onClick={() => this.handleCheckbox('breedSmall')}
                active={this.state.unknownBreeds.breedSmall}
                image={breedSmallImage}
                inputLabel="small"
                weight="5 - 10kg"
                breeds="Ex. Jack Russel Terrier, Dachshund, Pug, West Highland White, Bichon Frise"
              />
              <BreedUnknownOption
                animation="fadeIn delay-_3s"
                onClick={() => this.handleCheckbox('breedMedium')}
                active={this.state.unknownBreeds.breedMedium}
                image={breedMediumImage}
                inputLabel="medium"
                weight="10 - 20kg"
                breeds="Ex. Staffordshire Bull Terrier, Cocker Spaniel, Border Collie, Siberian Husky"
              />
              <BreedUnknownOption
                animation="fadeIn delay-_2s"
                onClick={() => this.handleCheckbox('breedLarge')}
                active={this.state.unknownBreeds.breedLarge}
                image={breedLargeImage}
                inputLabel="large"
                weight="20 - 40kg"
                breeds="Ex. Labrador Retriever, Boxer, German Shepherd, Greyhound, Golden Retriever"
              />
              <BreedUnknownOption
                animation="fadeIn"
                onClick={() => this.handleCheckbox('breedGiant')}
                active={this.state.unknownBreeds.breedGiant}
                image={breedGiantImage}
                inputLabel="giant"
                weight="over 40kg"
                breeds="Ex. Great Dane, Mastiff, Newfoundland, St.Bernard"
              />
            </div>
            <Slider {...settings}>
              <BreedUnknownOption
                animation="fadeIn"
                onClick={() => this.handleCheckbox('breedToy')}
                active={this.state.unknownBreeds.breedToy}
                image={breedToyImage}
                inputLabel="toy"
                weight="under 5kg"
                breeds="Ex. Chihuahua, Pomeranian, Maltese, Yorkshire Terrier"
              />
              <BreedUnknownOption
                animation="fadeIn delay-_2s"
                onClick={() => this.handleCheckbox('breedSmall')}
                active={this.state.unknownBreeds.breedSmall}
                image={breedSmallImage}
                inputLabel="small"
                weight="5 - 10kg"
                breeds="Ex. Jack Russel Terrier, Dachshund, Pug, West Highland White, Bichon Frise"
              />
              <BreedUnknownOption
                animation="fadeIn delay-_3s"
                onClick={() => this.handleCheckbox('breedMedium')}
                active={this.state.unknownBreeds.breedMedium}
                image={breedMediumImage}
                inputLabel="medium"
                weight="10 - 20kg"
                breeds="Ex. Staffordshire Bull Terrier, Cocker Spaniel, Border Collie, Siberian Husky"
              />
              <BreedUnknownOption
                animation="fadeIn delay-_2s"
                onClick={() => this.handleCheckbox('breedLarge')}
                active={this.state.unknownBreeds.breedLarge}
                image={breedLargeImage}
                inputLabel="large"
                weight="20 - 40kg"
                breeds="Ex. Labrador Retriever, Boxer, German Shepherd, Greuhound, Golden Retriever"
              />
              <BreedUnknownOption
                animation="fadeIn"
                onClick={() => this.handleCheckbox('breedGiant')}
                active={this.state.unknownBreeds.breedGiant}
                image={breedGiantImage}
                inputLabel="giant"
                weight="over 40kg"
                breeds="Ex. Great Dane, Mastiff, Newfoundland, St.Bernard"
              />
            </Slider>
          </div>
          <div style={{ maxWidth: '390px' }} className="сol-12 px-lg-4 row justify-content-center wizard-form">
            {this.state.isError
              ?
              <div className="animated fadeIn col-12 py-1 text-center error-message">
                Please select {this.props.currentDog.name} breed.
              </div>
              :
              ''
            }
            <div className="col-xl-6 col-lg-6 col-md-6 col-6 text-center pl-xl-2 pl-lg-1 pl-md-0 pt-2">
              <Button
                onClick={e => this.handleBackStep('/age', e)}
                classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                label="BACK"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-6 text-center pr-xl-2 pr-lg-1 pr-md-0 pt-2">
              <Button
                onClick={e => this.handleStep('/weight', e)}
                classes="button-primary fill orange animated fadeInRight delay-_2s"
                label="NEXT"
              />
            </div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => ({
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});

const mapDispatchToProps = dispatch => ({
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Breed));
