import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Carousel from 'react-slick';
import Product from '../../Product';
import theme from '../../../theme';
import SliderPrevIcon from '../../icons/SliderPrevIcon';
import SliderNextIcon from '../../icons/SliderNextIcon';

const carouselSettings = {
  slidesToShow: 3,
  prevArrow: <SliderPrevIcon />,
  nextArrow: <SliderNextIcon />,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: theme.breakpoints.values.md,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const AlsoLikeBlock = (props) => {
  const isInCart = (id) => {
    for (let i = 0; i < props.cart.length; i += 1) {
      const item = props.cart[i];
      if (item.productId === id && item.dogId === props.currentDog) {
        return true;
      }
    }
    return false;
  };
  const products = Object.values(props.products).filter(product => product.ID != undefined && !isInCart(product.ID));

  return (
    <div className="also-like-block">
      <Grid container>
        <Grid item className="checkout-header mx-sm-2" xs={12}>
          <h2>You might also like</h2>
        </Grid>
        <Grid item xs={12}>
          <Carousel {...carouselSettings}>
            {products
              .map(product => (<Product key={`product${product.ID}`} product={product}/>))}
          </Carousel>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  products: state.data.products,
  cart: state.data.cart,
  currentDog: state.data.dogs.currentDog,
});

export default connect(mapStateToProps)(AlsoLikeBlock);
