import React from 'react';
import { connect } from 'react-redux';
import Slider from 'react-rangeslider';

import Navigation from '../../components/Navigation';
import DogNavigation from '../../components/DogNavigation';
import SubNavigation from '../../components/SubNavigation';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import Footer from '../../components/Footer';

import GreyText from '../../components/GreyText';
import Row from '../../components/Row';
import HalfColumn from '../../components/HalfColumn';
import BackgroundContainer from '../../components/BackgroundContainer';

import * as dogsSelectors from '../../data/dogs/selectors';
import * as dogs from '../../data/dogs/actions';
import { updateDog } from '../../data/dogs/index';

const getCarouselItemClass = (key, state) => {
  if (state === 1 || state === 9) {
    if (state === (key - 2) || state === (key + 2)) {
      return ' displayed ';
    }
  }

  if (state === (key - 1) || state === (key + 1)) {
    return ' expanded ';
  }

  return '';
};

const GoalsClass = ({ dog }) => (
  <YourDogsGoals dog={dog} />
);

class YourDogsGoals extends React.Component {
  constructor(props) {
    super(props);

    const newDate = new Date();
    const today = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());

    this.state = {
      today,
      activityLevel: props.dog.activity_goal,
      goals: [],
      days: [],
      dog: props.dog,
      clicked: false,
      selectedDate: null,
    };

    this.updateActivity = this.updateActivity.bind(this);
  }

  componentDidMount() {
    const {
      dog,
    } = this.state;

    dogs.getGoals()
      .then((response) => {
        const g = [];

        for (let r in response) {
          r = response[r];

          g.push({
            key: r.ID,
            label: r.name,
            text: r.text,
            steps: r.steps,
            imageSource: (r.image.Files) ? `http:${r.image.Files[0].Url}` : '/assets/images/dog-2.png',
            activeImageSource: (r.imageActive.Files) ? `http:${r.imageActive.Files[0].Url}` : '/assets/images/dog-2.png',
          });
        }

        if (window.activity !== undefined) this.setState({ activityLevel: window.activity });
        this.setState({ goals: g.sort((a, b) => a.steps - b.steps) });
      })
      .catch((err) => {
        console.log(err);
      });

    dogs.getDays(dog.ID)
      .then(days => this.setState({ days }));
  }

  updateActivity() {
    if (this.state.clicked) {
      return;
    }

    this.setState({ clicked: !this.state.clicked });

    setTimeout(() => {
      this.setState({ clicked: !this.state.clicked });
    }, 2500);

    this.state.dog.activity_goal = this.state.activityLevel;

    updateDog(this.state.dog)
      .then((response) => {
        console.log('updateDog resp: ', response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const {
      selectedDate,
      activityLevel,
      days,
      today,
    } = this.state;

    const isToday = new Date(selectedDate).getTime() - today.getTime() === 0;
    let dogActivityLevel = activityLevel;

    // leftButton
    const leftButton = {
      float: 'left',
      marginLeft: '50px',
    };

    // save-message
    const textLeft = {
      textAlign: 'left',
    };

    // if selected date, find the closest day to display the activity level
    if (selectedDate !== null && days.length > 0 && !isToday) {
      let closest = null;
      let diff = 999;
      let past = false;

      for (const d in days) {
        let newDiff = new Date(selectedDate).getTime() - new Date(days[d].day).getTime();
        const newPast = newDiff > 0;

        newDiff = Math.ceil(Math.abs(newDiff) / (1000 * 3600 * 24));

        if (newDiff < diff) {
          diff = newDiff;
          past = newPast;
          closest = days[d];
        }
      }

      if (closest) {
        if (past) {
          dogActivityLevel = closest.new_activity;
        } else {
          dogActivityLevel = closest.old_activity;
        }
      }
    }

    let selectedDateString = '';
    let isEditable = true;

    if (selectedDate !== null) {
      const sd = new Date(selectedDate);

      selectedDateString = `${sd.getDate()}/${sd.getMonth()}/${sd.getFullYear()}`;

      if (today.getTime() !== sd.getTime()) {
        isEditable = false;
      }
    }

    return (
      <div className="container">
        <Navigation />

        {this.props.dogs && Object.keys(this.props.dogs).length >= 2 &&
          <DogNavigation />
        }

        <SubNavigation />

        <div className="inner-container">
          <KaushanScriptHeading title={`${this.props.dog.name}'s goals`} />

          <OrangeTextHeading
            text={`Keep ${this.props.dog.name}'s goals up to date`}
            fontSize={30}
            centered
          />

          <GreyText
            text={`Let us help you set up some goals to keep ${this.props.dog.name} happy and healthy! Select the answer that best describes how active your four-legged friend is. There is no right or wrong answer! Don't forget to revisit their goals.`}
            fontSize={20}
            centered
            marginBottom
          />

          <div className="grey-line" />

          <KaushanScriptHeading title={`${(!isToday && selectedDate !== null) ? selectedDateString : 'Goal'} activity level`} />

          <div className="activity-carousel-container">
            <div className="line" />
            {this.state.goals.sort((a, b) => a.key - b.key).map((item, index) => (
              <a
                key={item.key}
                className={`${(this.state.activityLevel === item.key ? ' active ' : '') + (getCarouselItemClass(item.key, this.state.activityLevel))}activity-carousel-item`}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ activityLevel: item.key });
                }}
              >
                <img src={item.imageSource} />
                <p className="key">{item.key}</p>
              </a>
            ))}
          </div>

          <div className="activity-text-container">
            {this.state.goals.map((item, index) => (
              <div
                key={item.key}
                className={`${(this.state.activityLevel === item.key ? ' active ' : '') + (getCarouselItemClass(item.key, this.state.activityLevel))}activity-text-item`}
              >
                <p>
                  {item.label}
                </p>
              </div>
            ))}
          </div>

          <div className="button-space">
            <Slider
              min={1}
              max={9}
              step={1}
              value={dogActivityLevel}
              orientation="horizontal"
              reverse={false}
              tooltip={false}
              handleLabel={dogActivityLevel.toString()}
              onChange={val => this.setState({ activityLevel: val })}
            />

            {isEditable && (
              <button onClick={this.updateActivity} className="saveButton" style={leftButton}>Save</button>
            )}

            <div className="height">
              {this.state.clicked && (
                <div className="save-message" style={textLeft}>
                  <p>
                    <br />Saved successfully!
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="carousel-text-container">
            {this.state.goals.map(item => (
              <div
                key={item.key}
                className={`${this.state.activityLevel === item.key ? ' active ' : ''}carousel-text`}
              >
                <GreyText
                  text={item.text}
                  fontSize={26}
                  centered
                  bold
                />
              </div>
            ))}
          </div>

          <Row>
            <HalfColumn>
              <BackgroundContainer>
                <GreyText
                  text="Is your dog?"
                  fontSize={26}
                  centered
                  bold
                />

                {this.state.goals.map((item, index) => (
                  <div key={index}>
                    <GreyText
                      text={item.label}
                      fontSize={20}
                      centered
                      bold
                    />
                    <GreyText
                      text={item.text}
                      fontSize={14}
                      centered
                      marginBottom
                    />
                  </div>
                ))}
              </BackgroundContainer>
            </HalfColumn>
          </Row>
        </div>

        <Footer />
      </div>
    );
  }
}

export default connect((state) => ({
  dogs: state.data.dogs.dogs,
  dog: dogsSelectors.getCurrentDog(),
}))(GoalsClass);
