import React from 'react';
import classNames from 'classnames';

const SubmitButton = ({
  value,
  centered,
  marginBottom,
  children,
  disabled,
}) => (
  <button
    className={classNames('submit-button', {
      center: centered,
      'margin-bottom': marginBottom,
    })}
    disabled={disabled}
    type="submit"
  >
    {children !== undefined ? children : value}
  </button>
);

export default SubmitButton;
