import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import Dropdown from 'react-dropdown';

import CheckboxNew from '../../components/CheckboxNew';
import * as dogsSelectors from '../../data/dogs/selectors';

import CloseIcon from '../../assets/images/wizard/review-close.png';

const medicalConditions = [
  'Skin & Coat',
  'Pancreatitis',
  'Joints & Movement',
  'Digestion'
];

class GeneralInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titbit: 'yes',
      diet: ''
    };
  }

  titbitChange = (e) => {
    this.setState({
      titbit: e.target.value === 'yes' ? true : false
    })
  }

  styledLabel = (value) => {
    return (
      <span
        style={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 14,
        }}>
        {value}
      </span>
    );
  };

  setDietaryPreferences = () => {

  }

  render() {
    const dogName = dogsSelectors.getCurrentDog().name;
    const diet = this.props.dog.diets && this.props.dog.diets.length > 0 && this.props.dog.diets[0].name

    return (
      <div
        className="modal animated fadeIn general-information-modal"
        id="general-information"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered row justify-content-center"
          role="document">
          <div className="modal-content">
            <div className="modal-header px-0 py-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Health Details
              </h5>
              <button
                type="button"
                className="close text-right"
                onClick={this.props.cancelStep}
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">
                  <img src={CloseIcon} alt="" />
                </span>
              </button>
            </div>

            <div className="modal-body px-0 py-0">
              <div className="health-issues">
                <div className="label">Health Issues</div>
                <div className="row health-issues--list">
                  {medicalConditions.map((i) => {
                    var filter = this.props.dog.medical_conditions && this.props.dog.medical_conditions.filter((a) => a.name === i)
                    var isChecked = filter && filter.length > 0
                    return (
                      <div className="col-6" key={i}>
                        <CheckboxNew
                          onClick={() => { }}
                          label={i}
                          isChecked={isChecked}
                          white
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="allergies">
                <div className="label">Allergies</div>
                <RadioGroup
                  aria-label="titbit"
                  name="titbit"
                  className="titbit--radio"
                  value={this.state.titbit}
                  onChange={this.titbitChange}
                  row>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label={this.styledLabel('Yes')}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label={this.styledLabel('No')}
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="modal-footer">
              <button className="cancel-btn" onClick={this.props.cancel}>
                Cancel
              </button>
              <button className="next-btn" onClick={this.save}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  dog: dogsSelectors.getCurrentDog(),
});

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralInformation));
