import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button';

import CloseIcon from '../../assets/images/wizard/review-close.png';

import * as actionTypes from '../../data/exports';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as selectors from '../../data/dogs/selectors';

class WrongWeightTip extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    const { image, target, btnLabel } = this.props;

    return (
      <div className="modal custom-modal animated fadeIn wrong-weight-tip" id={target} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <img src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid px-0">
                <div className="row justify-content-center">
                  <div className={`col-sm-7 mb-4 mb-sm-0 row align-items-center`}>
                    <p className="modal-description text-left text-justify py-1">
                      It seems you might have made a mistake! You have put the weight over 100kg.
                    </p>

                    <p className="modal-description text-left text-justify py-1 mb-3">
                      If you are sure this is correct, you can contact customer services at 0333 006 8266, or you can go back and change it now!
                    </p>

                    <div className="w-100 text-center text-sm-left">
                      <Button
                        data-toggle="modal"
                        data-target={`#${target}`}
                        classes="button-primary fill red"
                        label={btnLabel}
                      />
                    </div>
                  </div>

                  <div className="col-sm-5 pl-0 text-center text-sm-left d-md-flex align-items-center">
                    <img src={image} alt="tip-dog-image" className="mw-100" />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer row mx-0 justify-content-start">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dogs: state.data.dogs,
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});

const mapDispatchToProps = dispatch => ({
  setDog: dog => dispatch(dogsActionCreators.set(dog)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WrongWeightTip);
