import React from 'react';
import { Link } from 'react-router-dom';

import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import Row from '../../components/Row';
import FullColumn from '../../components/FullColumn';
import BackgroundContainer from '../../components/BackgroundContainer';
import Checkbox from '../../components/Checkbox';

import * as session from '../../services/session';
import { getUser } from '../../services/session/selectors';

export default class Welcome extends React.Component {
  constructor(props) {
    super(props);

    const user = getUser();

    this.state = {
      email: user.contact_email,
      mobile: user.contact_mobile,
      post: user.contact_post,
      sms: user.contact_sms,
    };
  }

  onNext() {
    return new Promise((resolve, reject) => {
      const {
        email,
        mobile,
        post,
        sms,
      } = this.state;

      session.updateUser({
        contact_mobile: mobile,
        contact_email: email,
        contact_post: post,
        contact_sms: sms,
      }).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
  }

  render() {
    return (
      <Row>
        <FullColumn>
          <OrangeTextHeading text="Kasper &amp; Kitty are really excited to have your pet as a new friend!" fontSize={30} centered />
          <GreyText text="So your four-legged friend can start enjoying a truly bespoke calorie controlled diet, simply click next to join our monthly subscription meal plan!" fontSize={20} centered marginBottom />
          <GreyText text="You can of course choose to cancel or pause your deliveries at any time - but we are confident that once you and your dog have joined us, you won't look back." fontSize={20} centered marginBottom />
          <GreyText text="Our easy to use dashboard and app is packed full of great features to help keep your dog in tip top shape." fontSize={20} centered marginBottom />
          <GreyText text="By continuously monitoring their activity levels with Fitkolar, our unique tracker, we dynamically adjust their bespoke K&amp;K calorie controlled daily menu for every delivery, so you can be confident their nutritional requirements are met every time!" fontSize={20} centered marginBottom />
          <GreyText text="Let's get you up and running so we can get these premium, nutritious bespoke meals delivered straight to their bowl!" fontSize={20} centered bold />
          <img alt="" src="/assets/images/community-image.png" className="community-image" />
          <div style={{ marginTop: '-2%' }}>
            <BackgroundContainer marginBottom>
              <GreyText text="You and your pet can start enjoying many privileges including exclusive K&amp;K offers and codes, free samples, news, competitions and more! Please choose how you would like us to stay in touch:" fontSize={20} marginBottom centered bold />
              <div className="col-12 col-md-10 offset-0 offset-md-1">
                <Row>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <Checkbox
                      active={this.state.email}
                      onClick={() => this.setState({ email: !this.state.email })}
                      label="Email"
                      white
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <Checkbox
                      active={this.state.mobile}
                      onClick={() => this.setState({ mobile: !this.state.mobile })}
                      label="App"
                      white
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <Checkbox
                      active={this.state.sms}
                      onClick={() => this.setState({ sms: !this.state.sms })}
                      label="SMS"
                      white
                    />
                  </div>
                </Row>
              </div>
              <GreyText text="We fully respect your privacy and you can opt-out at any time." fontSize={20} centered />
              <p className="grey-text center">For further details on how your data is used and stored: <Link to="/privacy-policy" className="underline">Privacy Policy</Link></p>
            </BackgroundContainer>
          </div>
        </FullColumn>
      </Row>
    );
  }
}
