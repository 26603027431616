import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Slider from 'react-slick';
import * as actionTypes from '../../data/exports';
import * as dogsActionCreators from '../../data/dogs/actions';
import * as selectors from '../../data/dogs/selectors';
import WizardLayout from '../../components/wizard/WizardLayout';
import ActivityOption from '../../components/wizard/ActivityOption';
import Button from '../../components/Button';
import Tip from '../../components/wizard/Tip';
import FintessPlan from '../../assets/images/wizard/tips/fintess-plan.svg';
import SedentaryImage from '../../assets/images/wizard/activity/1.png';
import ChillaxedImage from '../../assets/images/wizard/activity/2.png';
import LazyImage from '../../assets/images/wizard/activity/3.png';
import HesitantImage from '../../assets/images/wizard/activity/4.png';
import PlayfulImage from '../../assets/images/wizard/activity/5.png';
import ActiveImage from '../../assets/images/wizard/activity/6.png';
import TurboImage from '../../assets/images/wizard/activity/7.png';
import BonkersImage from '../../assets/images/wizard/activity/8.png';
import OlympicImage from '../../assets/images/wizard/activity/9.png';
import * as session from '../../services/session';

class Activity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activities: {
                sedentary: {
                    ID: 1,
                    label: 'Sedentary',
                    description: '1x 15 min. Medium activity or less a day',
                    image: SedentaryImage,
                    selected: false,
                    key: 'sedentary',
                },
                chillaxed: {
                    ID: 2,
                    label: 'Chillaxed',
                    description: '1x 25 min. Medium activity a day',
                    image: ChillaxedImage,
                    selected: false,
                    key: 'chillaxed',
                },
                lazy: {
                    ID: 3,
                    label: 'Lazy',
                    description: '1x 30 min. Medium activity a day',
                    image: LazyImage,
                    selected: false,
                    key: 'lazy',
                },
                hesitant: {
                    ID: 4,
                    label: 'Hesitant',
                    description: '2x 25 min. Medium activity a day',
                    image: HesitantImage,
                    selected: false,
                    key: 'hesitant',
                },
                playful: {
                    ID: 5,
                    label: 'Playful',
                    description: '2x 30 min. Medium activity a day',
                    image: PlayfulImage,
                    selected: false,
                    key: 'playful',
                },
                active: {
                    ID: 6,
                    label: 'Active',
                    description: '2x 45 min. Medium activity a day',
                    image: ActiveImage,
                    selected: false,
                    key: 'active',
                },
                turbo: {
                    ID: 7,
                    label: 'Turbo paws',
                    description: 'In excess of 2 hours medium activity a day',
                    image: TurboImage,
                    selected: false,
                    key: 'turbo',
                },
                bonkers: {
                    ID: 8,
                    label: 'Bonkers',
                    description: 'In excess of 2.5 hours medium to intense activity a day',
                    image: BonkersImage,
                    selected: false,
                    key: 'bonkers',
                },
                olympic: {
                    ID: 9,
                    label: 'Olympic',
                    description: 'In excess of 3 hours medium to intense activity a day',
                    image: OlympicImage,
                    selected: false,
                    key: 'olympic',
                },
            },
        };

        this.handleCheckbox = (key) => {
            const dogIndex = this.props.getIndex;
            Object.keys(this.state.activities).map(item => this.state.activities[item].selected = false);
            const selectedCond = this.state.activities[key];
            selectedCond.selected = !selectedCond.selected;
            this.setState({
                activities: {
                    ...this.state.activities,
                },
            });
            this.props.updateCurrentDog('activity', dogIndex, selectedCond.ID);
            this.props.updateCurrentDog('activityName', dogIndex, selectedCond.key);

            this.checkActivity();
        };

        this.checkActivity = () => {
            const dogIndex = this.props.getIndex;
            this.props.updateCurrentDog('error', dogIndex, '');
            return true;
        };

        this.handleStep = (step, e) => {
            e.preventDefault();
            if (this.checkActivity()) {
                this.props.history.push(step);
            }
        };

        this.handleBackStep = (step, e) => {
            e.preventDefault();
            this.props.history.push(step);
        };

        this.setErrorToDog = () => {
            session.updateUser({
                working_dog: true,
            }).then(() => {
                const dogIndex = this.props.getIndex;
                this.props.updateCurrentDog('error', dogIndex, 'activity');
                this.props.history.push('/add-dog');
            })
            .catch(() => {
                alert("Unable to save, please try again!");
            });            
        }
    }

    componentDidMount() {
        const dogIndex = this.props.getIndex;
        const {pathname} = this.props.location;
        this.props.updateCurrentDog('step', dogIndex, pathname);

        if(this.props.currentDog.activityName === ''){
            this.props.updateCurrentDog('progress', dogIndex, 65);
            this.props.updateCurrentDog('activityName', dogIndex, 'Playful');
        }

        this.props.toggleDogNav(true);
    }

    render() {
        const settings = {
            dots: false,
            arrows: true,
            infinite: false,
            slidesToShow: 8,
            slidesToScroll: 1,
            autoplay: false,
            centerMode: false,
            speed: 500,
            autoplaySpeed: 3500,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 6.3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 6.3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 1366,
                    settings: {
                        slidesToShow: 5.3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 3.3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 3.1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        centerMode: true,
                        arrows: false,
                        dots: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 468,
                    settings: {
                        centerMode: true,
                        arrows: false,
                        dots: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };
        const {activities} = this.state;
        const linkStyle = {
            'font-size': '12px',
            'width': '210px',
            display: 'flex',
            justifyContent: 'center',
        };
        const activityList = Object.keys(this.state.activities).map(activity => (
            <div>
            <ActivityOption
                label={activities[activity].label}
                description={activities[activity].description}
                image={activities[activity].image}
                active={activities[activity].selected || activities[activity].ID === this.props.currentDog.activity}
                key={activities[activity].ID}
                onClick={() => this.handleCheckbox(activities[activity].key)}
            />
            {activities[activity].ID === 9
                ?
            <a
                style={linkStyle}
                className={"text-center underline pop-up-link"}
                data-toggle="modal"
                href={"#working-dog"}
                >Working dog?
            </a>
            :
                ''
            }
            </div>

        ));

        return (
            <div className="container-fluid">

                <WizardLayout>

                    <div className="col-12 row justify-content-center">
                        <div className="col-12 row justify-content-center">
                            <h5 className="w-100 pb-4 text-center font-weight-bold text-uppercase animated fadeInDown delay-_1s">
                                how active is {this.props.currentDog.name}?
                            </h5>
                        </div>
                    </div>

                    <Tip
                        title={"We are sorry, we don't cater for working dogs yet"}
                        description={`Let us know if you want us to update you when we have worked out the perfect blend for working dog!`}
                        target="working-dog"
                        isImage={true}
                        image={FintessPlan}
                        isButton={true}
                        btnFn={() => this.setErrorToDog()}
                        btnLabel="UPDATE ME"
                    />

                </WizardLayout>

                <div className="row justify-content-center">

                    <div className="col-12 px-xl-0 px-lg-0 px-md-3 px-sm-2 px-0 px-sm-3 activity-container mb-5">
                        <Slider {...settings}>
                            {activityList}
                        </Slider>
                    </div>

                    <div style={{maxWidth:'390px'}} className="col-xl-4 col-lg-5 col-md-5 col-sm-6 px-xl-3 pb-4 row justify-content-center wizard-form">
                        <div className="col-6 pl-xl-3 pl-lg-2 pl-md-0 pt-4 text-center pr-1">
                            <Button
                                onClick={e => this.handleBackStep('/body-condition', e)}
                                classes="button-primary fill transparent orange animated fadeInLeft delay-_2s"
                                label="BACK"
                            />
                        </div>

                        <div className="col-6 pr-xl-3 pr-lg-2 pr-md-0 pt-4 text-center pl-1">
                            <Button
                                onClick={e => this.handleStep('/health-details', e)}
                                classes="button-primary fill orange animated fadeInRight delay-_2s"
                                label="NEXT"
                            />
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    storeDogs: state.data.dogs.storeDogs,
    currentDog: state.data.dogs.currentDog,
    getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
    updateCurrentDog: (key, index, value) =>
        dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
    toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activity));

