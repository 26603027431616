import React from 'react';
import ReactLoading from 'react-loading';
import { Redirect, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement, Elements } from 'react-stripe-elements';

import * as ordersActionCreators from '../data/orders/actions';
import * as sessionSelectors from '../services/session/selectors';
import * as orderApi from '../data/orders/index';

import Navigation from '../components/Navigation';
import MainImageBanner from '../components/MainImageBanner';
import SubNavigation from '../components/SubNavigation';
import Row from '../components/Row';
import FullColumn from '../components/FullColumn';
import Footer from '../components/Footer';
import GreyText from '../components/GreyText';
import HalfColumn from '../components/HalfColumn';
import Dropdown from '../components/Dropdown';
import Checkbox from '../components/Checkbox';
import KaushanScriptHeading from '../components/KaushanScriptHeading';
import FormErrors from '../components/FormErrors';

const cards = [
  {
    name: 'Visa',
  },
  {
    name: 'Mastercard',
  },
  {
    name: 'American Express',
  },
];

const Stat = ({ label, value }) => (
  <p className="grey-text"><span className="bold">{label}:</span> {value}</p>
);

const Radio = ({
  currentState, activeWhenStateIs, label, onClick,
}) => (
  <Checkbox
    active={currentState === activeWhenStateIs}
    onClick={onClick}
    label={label}
  />
);

class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order_id: '',
      paymentMethod: '',
      cardType: '',
      cardName: '',
      cardNumber: '',
      cardMonth: '',
      cardYear: '',
      card_csv: '',
      tandc: false,
      paid: false,
      errors: [],

      paymentLoading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCardMonth = this.handleChangeCardMonth.bind(this);
  }

  componentDidMount() {
    this.setState({ order_id: this.props.match.params.id }, () => this.checkOrder(this.state.order_id));
  }

  handleChangeCardMonth(e) {
    const month = e.target.value;
    if (month > 12) {
      const errors = ['Please input valid Exp month.'];
      this.setState({ errors });
    } else {
      this.setState({ errors: [], cardMonth: month });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ paymentLoading: true });

    const payload = {
      order_id: parseInt(this.state.order_id, 10),
    };

    const { user } = this.props;

    // Generate card token
    this.props.stripe.createToken({ name: `${user.first_name} ${user.surname}` })
      .then((r) => {
        if (r.error) {
          window.alert(r.error.message);
          this.setState({ paymentLoading: false });
        } else {
          payload.stripe_token = r.token.id;

          this.props.actions.orders.orderpay(payload)
            .then(() => {
              this.setState({ paid: true, paymentLoading: false });
            })
            .catch(() => {
              window.alert('An error occured while processing your payment.');
              this.setState({ paymentLoading: false });
            });
        }
      });
  }

  checkOrder(id) {
    orderApi.getOrder(id)
      .then((order) => {
        if (order.awaiting_payment === false) {
          this.setState({ paid: true });
        }
      })
      .catch((err) => {
        console.log('An error occured. ', err);
      });
  }

  render() {
    if (this.state.paid) {
      return <Redirect to="/live-orders" />;
    }

    const { errors, paymentLoading } = this.state;

    return (
      <div className="container">
        <Navigation
          absolute
          white
          textColor="white-text"
        />
        <MainImageBanner
          image="url(/assets/images/dog-in-field.jpg)"
          heading="Shop"
          subtext="Everything your pet needs"
        />
        <SubNavigation />
        <div className="shop-payment">
          <div className="inner-container">
            <KaushanScriptHeading title={`Payment - Order #${this.state.order_id}`} />
            {this.state.paid !== true ? (
              <Row>
                <HalfColumn>
                  <Row noMarginBoottom>
                    <FullColumn noPaddingBottom>
                      <GreyText
                        text="Your Details"
                        fontSize={20}
                        marginBottom
                      />
                      <Stat label="Name" value={`${this.props.user.first_name} ${this.props.user.surname}`} />
                      <Stat label="Email" value={this.props.user.email} />
                      <Stat label="Mobile" value={this.props.user.mobile_number} />
                      <Row><FullColumn /></Row>
                      <Row>
                        <div className="col-12 col-sm-9 col-md-7 order-2 order-sm-1 no-padding align-self-center">
                          <p className="piggy-bank-text">You can of course choose to cancel or "paws" your deliveries at any time! But Kasper and Kitty are confident that your dog won't be looking back.</p>
                        </div>
                        <div className="col-12 col-sm-3 col-md-5 order-1 order-sm-2 no-padding align-self-center">
                          <img
                            className="piggy-bank-image"
                            src="/assets/images/pig-icon.png"
                            alt=""
                          />
                        </div>
                      </Row>
                    </FullColumn>
                  </Row>
                </HalfColumn>
                <HalfColumn>
                  <form onSubmit={this.handleSubmit}>
                    <Row noMarginBoottom>
                      <FullColumn noPaddingBottom>
                        <GreyText
                          text="Payment method"
                          fontSize={20}
                          marginBottom
                        />
                      </FullColumn>
                    </Row>
                    <Row>
                      <div className="col-12 no-padding">
                        <Row>
                          <div className="col-12 col-lg-4">
                            <Radio
                              currentState={this.state.paymentMethod}
                              activeWhenStateIs="Credit card"
                              label="Credit card"
                              onClick={() => this.setState({ paymentMethod: 'Credit card' })}
                            />
                          </div>
                          <div className="col-12 col-lg-4">
                            <Radio
                              currentState={this.state.paymentMethod}
                              activeWhenStateIs="Debit card"
                              label="Debit card"
                              onClick={() => this.setState({ paymentMethod: 'Debit card' })}
                            />
                          </div>
                          <div className="col-12 col-lg-4" />
                        </Row>
                      </div>
                    </Row>
                    <FormErrors errors={errors} />
                    <Row>
                      <FullColumn noPaddingBottom>
                        <Dropdown
                          activeOption={this.state.cardType}
                          onChangeOption={event => this.setState({ cardType: event.value })}
                          options={cards}
                          placeholder="Card type"
                        />
                      </FullColumn>
                    </Row>
                    {/* <Row> */}
                    {/*  <FullColumn noPaddingBottom> */}
                    {/*    <InputField */}
                    {/*      type="text" */}
                    {/*      placeholder="Card number" */}
                    {/*      value={this.state.cardNumber} */}
                    {/*      onChangeText={event => this.setState({ cardNumber: event.target.value })} */}
                    {/*      noMarginBoottom */}
                    {/*    /> */}
                    {/*  </FullColumn> */}
                    {/* </Row> */}
                    <Row>
                      <FullColumn noPaddingBottom>
                        <CardNumberElement
                          className="input-field"
                          placeholder="Card number"
                          style={{
                            base: {
                              fontSize: '18px',
                              fontWeight: 700,
                            },
                          }}
                        />
                      </FullColumn>
                    </Row>
                    {/* <Row> */}
                    {/*  <FullColumn noPaddingBottom> */}
                    {/*    <Row> */}
                    {/*      <div className="col-6 no-padding"> */}
                    {/*        <InputField */}
                    {/*          type="number" */}
                    {/*          placeholder="Exp month" */}
                    {/*          value={this.state.cardMonth} */}
                    {/*          onChangeText={this.handleChangeCardMonth} */}
                    {/*          noMarginBoottom */}
                    {/*        /> */}
                    {/*      </div> */}
                    {/*      <div className="col-6 no-padding"> */}
                    {/*        <InputField */}
                    {/*          type="number" */}
                    {/*          placeholder="Exp year" */}
                    {/*          value={this.state.cardYear} */}
                    {/*          onChangeText={event => this.setState({ cardYear: event.target.value })} */}
                    {/*          noMarginBoottom */}
                    {/*        /> */}
                    {/*      </div> */}
                    {/*    </Row> */}
                    {/*  </FullColumn> */}
                    {/* </Row> */}
                    <Row>
                      <FullColumn noPaddingBottom>
                        <CardExpiryElement
                          className="input-field"
                          placeholder="MM/YY"
                          style={{
                            base: {
                              fontSize: '18px',
                              fontWeight: 700,
                            },
                          }}
                        />
                      </FullColumn>
                    </Row>
                    {/* <Row> */}
                    {/*  <FullColumn noPaddingBottom> */}
                    {/*    <InputField */}
                    {/*      type="text" */}
                    {/*      placeholder="CSV" */}
                    {/*      value={this.state.csv} */}
                    {/*      onChangeText={event => this.setState({ csv: event.target.value })} */}
                    {/*      noMarginBoottom */}
                    {/*    /> */}
                    {/*  </FullColumn> */}
                    {/* </Row> */}
                    <Row>
                      <FullColumn noPaddingBottom>
                        <CardCVCElement
                          className="input-field"
                          placeholder="CVC"
                          style={{
                            base: {
                              fontSize: '18px',
                              fontWeight: 700,
                            },
                          }}
                        />
                      </FullColumn>
                    </Row>
                    <Row>
                      <div className="col" />
                    </Row>
                    <Row>
                      <FullColumn noPaddingBottom>
                        <Checkbox
                          active={this.state.tandc}
                          onClick={() => this.setState({ tandc: !this.state.tandc })}
                          label="I agree to the Kasper &amp; Kitty Terms &amp; Conditions"
                        />
                      </FullColumn>
                    </Row>
                    {paymentLoading ?
                      (
                        <Row>
                          <ReactLoading type="spinningBubbles" color="grey" height={100} width={100} />
                        </Row>
                      )
                      :
                      (
                        <Row>
                          <FullColumn>
                            <button
                              type="submit"
                              className="sign-up-button"
                              disabled={paymentLoading}
                            >
                              Pay
                            </button>
                          </FullColumn>
                        </Row>
                      )
                    }
                    <Row>
                      <FullColumn>
                        <p className="grey-text"><span className="bold">Note:</span> When paying by credit or debit card, no payment will be taken from your account until we have dispatched your order.</p>
                      </FullColumn>
                    </Row>
                  </form>
                </HalfColumn>
              </Row>
            ) : (
              <div>
                <GreyText
                  text="This order has already been paid. To view this order click the following link."
                  fontSize={18}
                  centered
                  marginBottom
                />
                <a className="centered" href={`/payment-history/${this.state.order_id}`}>Order #{this.state.order_id}</a>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const PaymentWrapped = connect(() => ({
  user: sessionSelectors.getUser(),
}), dispatch => ({
  actions: {
    orders: bindActionCreators(ordersActionCreators, dispatch),
  },
}), null, { forwardRef: true })(withRouter(injectStripe(Payment)));

export default ({ props }) => (
  <Elements>
    <PaymentWrapped {...props} />
  </Elements>
);
