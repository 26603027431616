import * as actionTypes from './actionTypes';
import * as api from './api';

export const add = product => ({
  type: actionTypes.ADD,
  product,
});

export const update = products => ({
  type: actionTypes.UPDATE,
  products,
});

export const clear = () => ({
  type: actionTypes.CLEAR,
});

/**
 * Get products
 */
export const getProducts = () => dispatch => (
  new Promise((resolve) => {
    api.products().then((products) => {
      dispatch(update(products));
      resolve(products);
    });
  })
);

/**
 * Get product fitkorlar
 */
export const getProductFitkolar = () => (
  new Promise((resolve) => {
    api.productFitkolar().then((product) => {
      resolve(product);
    });
  })
);

/**
 * Get data for bespoke food
 */
export const getBespoke = id => (
  new Promise((resolve) => {
    api.bespoke(id).then((products) => {
      resolve(products);
    });
  })
);
