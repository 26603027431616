/* eslint-disable arrow-parens */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { isTablet } from "react-device-detect";
import { referralLink, kibbleOrder } from '../../data/orders/api';

class ThankYouPage extends React.Component {

  state = {
    isSubmitted: false,
    refLink: '',
    didCopied: false,
    subscription: {},
  };

  componentDidMount() {
    ReactPixel.track('track', 'CompleteRegistration');
    // generate referral link
    referralLink().then(res => this.setState({ refLink: res.link }));

    this.loadSubscriptionOrder();
  }

  loadSubscriptionOrder = () => {

    this.props.dog && kibbleOrder(this.props.dog)
      .then((order) => {
        let items = [];

        if (order.Order.items && order.Order.items.length > 0) {
          items = order.Order.items.map((item => ({
            product_id: item.product_id,
            name: item.product.name,
            quantity: item.quantity,
            price: Math.round(item.price * 100) / 100,
            isKibble: item.product.is_kibble,
            image: item.product.image,
          })));
        }

        const subscription = {
          orderID: order.Order.ID,
          orderStatus: order.Order.status,
          dateRequired: order.Order.date_required,
          total: Math.round(order.Order.total * 100) / 100,
          totalNoDiscount: order.Order.total_no_discount,
          isTrial: order.Order.is_trial,
          items,
        }

        const promoCode = order.Order.promo_id;

        this.setState({ subscription, promoCode });
        this.saveToLocalStorage(this.props.dog, subscription, promoCode);
      })
  }

  saveToLocalStorage = (dogID, subscription, promoCode) => {
    let subOrders = JSON.parse(localStorage.getItem('subscription'));

    // if subscription doesn't exist in localstorage, initialize subOrders as empty array
    if (!subOrders) subOrders = [];

    subOrders.push({ dogID, subscription, promoCode })

    localStorage.setItem('subscription', JSON.stringify(subOrders))
  }

  handleButtonClick = (url) => {
    this.props.history.push(url);
  }

  copyLink = () => {
    const copyToClipboard = str => {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    };
    copyToClipboard(this.state.refLink);
    this.setState({ didCopied: true }, () => setTimeout(() => {
      this.setState({ didCopied: false });
    }, 2000));
  }

  openShareFacebook = () => {
    const { refLink } = this.state;
    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${refLink}`;
    window.open(facebookLink, 'popupWindow', 'height=555,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }

  openShareTwitter = () => {
    const { refLink } = this.state;
    const twitterLink = `https://twitter.com/home?status=${refLink}`;
    window.open(twitterLink, 'popupWindow', 'height=555,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
  }

  openShareWhatsapp = () => {
    const { refLink } = this.state;
    const whatsappLink = `https://api.whatsapp.com/send?phone=+&text=%20${refLink}`;
    window.open(whatsappLink, 'popupWindow', 'height=555,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
  }

  openShareEmail = () => {
    const { refLink } = this.state;
    const link = `mailto:?subject=I wanted you to see this site&amp;body=Check out this site ${refLink}.`;
    window.location.open(link, '_blank');
  }

  render() {
    const { subscription } = this.state;

    const deliveryDate = (() => {
      if (Object.keys(subscription).length === 0) return '';
      const foo = new Date(subscription.dateRequired);
      const lastDateToChange = new Date(foo.getFullYear(), foo.getMonth(), foo.getDate());
      return lastDateToChange.toDateString();
    })();

    return (
      <div className="thankyou-layout">
        {!isTablet &&
          <div className="thankyou__bg">
            <div className="thankyou__bg__bubble" />
          </div>
        }
        <div className="thankyou__content">
          <div className="thankyou__content__delivery flex-middle flex-column">
            <img className="delivery-car" src="/assets/images/thankyou-delivery-large.png" alt="Delivery" />
            <h3>Get ready for your<br /> delivery on:  <span class="d-block">{deliveryDate}</span> <div className="yay" /></h3>

            <button className="button-solid" onClick={() => this.handleButtonClick('/add-dog')}>Add another dog</button>
            <button className="button-outline" onClick={() => this.handleButtonClick('/dashboard')}>Go to dashboard</button>

            <div className="refer-a-friend">
              <h3>Refer a friend</h3>
              <h5>Like what you see so far! Recommend us to your friends and get £10 off your next delivery (and give them 1-month FREE food).</h5>

              <div className="refer-a-friend__link">
                <div className="link-container">
                  {this.state.refLink}
                </div>
                <div className="copy-button" onClick={this.copyLink}>
                  <img src="/assets/images/thankyou-copy.png" alt="Copy" /> {this.state.didCopied ? 'Copied' : 'Copy'}
                </div>
              </div>

              <h4>Share with</h4>
              <div className="social-buttons">
                <button className="button-outline" onClick={this.openShareFacebook}>
                  <img src="/assets/images/share-facebook.png" alt="Share Facebook" />
                  Facebook
                </button>
                <button className="button-outline" onClick={this.openShareTwitter}>
                  <img src="/assets/images/share-twitter.png" alt="Share Facebook" />
                  Twitter
                </button>
                <button className="button-outline" onClick={this.openShareWhatsapp}>
                  <img src="/assets/images/share-whatsapp.png" alt="Share Facebook" />
                  Whatsapp
                </button>
                <button className="button-outline">
                  <a target="_blank" href={`mailto:?subject=I wanted you to see this site ${this.state.refLink}&amp;body=Check out this site ${this.state.refLink}.`}>
                    <img src="/assets/images/share-email.png" alt="Share Facebook" />
                    Email
                  </a>
                </button>
              </div>

              <h4 className="learn-more"><Link to="/refer-a-friend">Learn more</Link></h4>

              <img className="discount-img" src="/assets/images/thankyou-off-10-percent.png" alt="Off £10" />
            </div>

            <p>By participating in this program, you agree to our <a href="https://www.kasperandkitty.com/terms-conditions/">Terms & Conditions.</a></p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dog: state.data.dogs.currentDog
});

export default withRouter(connect(mapStateToProps)(ThankYouPage));
