import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as dogsSelectors from '../../data/dogs/selectors';
import * as productsActions from '../../data/products/actions';

import BackgroundContainer from '../../components/BackgroundContainer';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import Row from '../../components/Row';
import Dropdown from '../../components/Dropdown';
import InputField from '../../components/InputField';
import FullColumn from '../../components/FullColumn';
import Calories from '../../components/Calories';
import * as dogsActions from '../../data/dogs/actions';
import {FITKOLAR_PRICE_PRE_VAT} from '../../utils.js'

const Item = ({
  product, quantity, onChangeQuantity, onDelete, price,
}) => (
  <Row>
    <div className="col no-padding no-margin">
      <p className="product-text">{product} - £{price}</p>
    </div>
    <div className="input-field-container">
      <InputField
        type="number"
        value={quantity}
        onChangeText={onChangeQuantity}
        noMarginBottom
      />
    </div>
    <div className="delete-icon-container">
      <a
        className="delete-icon"
        onClick={onDelete}
      >
        <img src="/assets/images/delete.png" />
      </a>
    </div>
  </Row>
);

class DailyMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      personalisedShop: [],
      fitkolar: 0,
      products: [],
      calories: 0,
      shape: '',
      food: {},

      shares: [0, 0, 0, 0],
      knames: ['', '', '', ''],
      ingredients: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
      nutrients: ['', ''],
      vitamins: ['', ''],
      keyIngredients: ['', '', ''],
      short: true,
    };
  }

  componentDidMount() {
    if (this.props.stepsState) {
      if (this.props.stepsState.personalisedShop) {
        this.setState({
          personalisedShop: this.props.stepsState.personalisedShop,
        });
      }

      if (this.props.stepsState.fitkolar) {
        this.setState({
          fitkolar: isNaN(this.props.stepsState.fitkolar) ? 0 : this.props.stepsState.fitkolar,
        });
      }
    }

    this.props.actions.products.getProducts()
      .then((prod) => {
        this.setState({ products: prod });
      });

    dogsActions.getCalories(this.props.dog.ID)
      .then((resp) => {
        console.log(resp.food);
        this.setState({
          calories: resp.calories,
          shape: resp.shape,
          food: resp.food,
        });
      });

    dogsActions.getKibbleShares(this.props.dog.ID)
      .then((resp) => {
        // prepare ingredients. server sends 10
        const ings = resp.ingredients.map(s => s.trim());
        const nuts = resp.nutrients.map(s => s.trim());
        const vits = resp.vitamins.map(s => s.trim());
        this.setState({
          shares: resp.shares,
          knames: resp.knames,
          ingredients: ings,
          nutrients: nuts,
          vitamins: vits,
          keyIngredients: resp.key_ingredients,
        });
      });
  }

  onNext() {
    return new Promise((resolve) => {
      console.log(this.state.personalisedShop);
      this.props.onUpdateState({
        personalisedShop: this.state.personalisedShop,
        fitkolar: this.state.fitkolar,
      });
      resolve();
    });
  }

  isVariableAlredyInState(variable, state) {
    return this.state[state].filter(item => item.product === variable).length > 0;
  }

  render() {
    const { products } = this.state;
    const productsList = [];

    for (let p in products) {
      // console.log(p);
      p = products[p];
      productsList.push({
        value: p.ID,
        code: p.code,
        price: p.price,
        label: p.name,
      });
    }

    // Fitkolar price ~ product code is 000101
    let fitkolarDescription = `Fitkolar - £${((Math.floor(FITKOLAR_PRICE_PRE_VAT * 1.2 * 100) / 100) * this.state.fitkolar).toFixed(2)}`;


    // total food needed
    // not sure about these calculations
    // const portions = parseInt((((this.state.calories * 0.85) / 3) / this.state.food.calories) * 100, 10) / 100;
    const foodCalPercentage = Math.round(this.state.food.calories / this.state.calories * 100) 
    const portion = parseInt(this.state.food.portion, 10);

    return (
      <div>
        <OrangeTextHeading
          text={`We have crunched the numbers. Here's our recommended complete, healthy, calorie controlled diet specifically for ${this.props.dog.name}.`}
          fontSize={30}
          centered
          marginBottom
        />

        <Calories
          portion={portion}
          calories={this.state.calories}
          shape={this.state.shape}
          dog={this.props.dog}
          percentage={foodCalPercentage}

          shares={this.state.shares}
          knames={this.state.knames}
          ingredients={this.state.ingredients}
          nutrients={this.state.nutrients}
          vitamins={this.state.vitamins}
          keyIngredients={this.state.keyIngredients}
          short={this.state.short}
        />

        <BackgroundContainer marginBottom>
          <Row>
            <FullColumn>
              <OrangeTextHeading
                text={`${this.props.dog.name}'s personalised shop`}
                fontSize={30}
                centered
                marginBottom
              />
              <GreyText
                text="Here's our cherry-picked selection of additional treats and wet food to complement your dog's diet."
                fontSize={20}
                centered
                marginBottom
              />
              {this.state.personalisedShop.map(item => (
                <Item
                  key={item.key}
                  product={item.product}
                  quantity={item.quantity}
                  price={item.price}
                  onChangeQuantity={(event) => {
                    for (let i = 0; i < this.state.personalisedShop.length; i++) {
                      if (this.state.personalisedShop[i].key === item.key) {
                        const list = this.state.personalisedShop;

                        if (event.target.value > 1) {
                          if (event.target.value > 99) {
                            list[i].quantity = 99;
                          } else {
                            list[i].quantity = event.target.value;
                          }
                        } else {
                          list[i].quantity = 1;
                        }

                        this.setState({ personalisedShop: list });
                      }
                    }
                  }}
                  onDelete={() => {
                    const selection = this.state.personalisedShop.map(product => product.key).indexOf(item.key);

                    const list = this.state.personalisedShop;

                    list.splice(selection, 1);

                    this.setState({ personalisedShop: list });
                  }}
                />
              ))}
              <Dropdown
                placeholder="Add an item"
                activeOption={-1}
                onChangeOption={(event) => {
                  if (this.state.personalisedShop.length > 2) {
                    window.alert('Personalised shop should be no more than three products.');
                    return;
                  }
                  const list = [];
                  for (let i = 0; i < productsList.length; i += 1) {
                    if (productsList[i].label === event.value) {
                      list.push({
                        key: productsList[i].value,
                        product: event.value,
                        quantity: 1,
                        price: productsList[i].price,
                      });
                    }
                  }
                  const isInState = this.isVariableAlredyInState(event.value, 'personalisedShop');
                  if (isInState) {
                    window.alert('This item is already in your personalised shop. If you would like more please try increasing the quantity of this item.');
                  } else {
                    this.setState({ personalisedShop: [...this.state.personalisedShop, ...list] });
                  }
                }}
                options={productsList.map(item => [item.label, `£${item.price}`])}
              />
            </FullColumn>
          </Row>
        </BackgroundContainer>
        <BackgroundContainer>
          <Row>
            <div className="col-12 col-md-8 no-padding">
              <OrangeTextHeading
                text={`${this.props.dog.name}'s activity tracker`}
                fontSize={30}
                centered
                marginBottom
              />
              <GreyText
                text="To keep your dog even healthier, order our unique Fitkolar, download our free K&amp;K app and monitor their activity levels 24/7!"
                fontSize={20}
                centered
                marginBottom
              />
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-8 no-padding">
              <Row>
                <div className="col-3 align-self-center">
                  <img className="fitkolar-image" src="/assets/images/collar.png" alt="Fitkolar Icon" />
                </div>
                <div className="col no-padding no-margin">
                  <p className="product-text">{fitkolarDescription}</p>
                </div>
                <div className="input-field-container">
                  <InputField
                    type="number"
                    value={this.state.fitkolar}
                    onChangeText={(event) => {
                      if (event.target.value > 0) {
                        if (event.target.value > 99) {
                          this.setState({ fitkolar: 99 });
                        } else {
                          this.setState({ fitkolar: event.target.value });
                        }
                      } else {
                        this.setState({ fitkolar: 0 });
                      }
                    }}
                    noMarginBottom
                  />
                </div>
                <div className="delete-icon-container">
                  <a
                    className="delete-icon"
                    onClick={() => this.setState({ fitkolar: 0 })}
                  >
                    <img src="/assets/images/delete.png" />
                  </a>
                </div>
              </Row>
            </div>
          </Row>
        </BackgroundContainer>
        <p>
          Take a look at our <a className="link" href="/intro-offer" target="_blank">Intro Offer </a>
        </p>
      </div>
    );
  }
}

export default connect(() => ({
  dog: dogsSelectors.getCurrentDog(),
}), dispatch => ({
  actions: {
    products: bindActionCreators(productsActions, dispatch),
  },
}), null, { withRef: true })(DailyMenu);
