import React from 'react';
import PopUpIcon from '../../assets/images/wizard/health-option-icon.png';

export default class HealthOption extends React.Component {
  constructor(props) {
    super(props);
    this.handleInput = () => {

    };

    this.toggleOption = (value) => {
      this.props.toggle(value);
    };

  }
  render() {
    const { label, value, active } = this.props;
    return (
      <div
        className={`health-option position-relative ${active ? 'active' : ''}`}
        onClick={() => this.toggleOption(value)}
      >
        <span className={"label"}>{label}</span>
      </div>
    );
  }
}
