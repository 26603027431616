import React from 'react';
import { Link } from 'react-router-dom';

import Navigation from '../components/Navigation';
import CommonImageBanner from '../components/CommonImageBanner';
import SimplyPowesome from '../components/SimplyPowesome';
import InstagramCarousel from '../components/InstagramCarousel';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';
import FoodCard from '../components/FoodCard';

const key_features = [
  {
    image: '/assets/images/cost-the-app.png',
    subj: 'Kaspermeter',
    text: 'Keeping on top of your dog\'s activities 24/7 is child\'s play with our free app which works in conjunction with our activity tracking device, the Fitkolar. A quick glance to the Kaspermeter, will let you know exactly how many steps your dog has taken, how far they have been and how many calories they have burnt while you were at work!',
  },
  {
    image: '/assets/images/calories-icon.png',
    subj: 'Healthier pet',
    text: 'By continuously tracking your dog\'s activities, their bespoke K&K calorie-controlled diet can be dynamically adjusted at any time, meaning you can be certain each delivery we send you fully meets his nutritional requirements.',
  },
  {
    image: '/assets/images/cost-activity-trackers.png',
    subj: 'Keep fit',
    text: 'Set and track your dog\'s goals - from activity goals to distance travelled so that you can make sure your dog is packing enough fun into each day.',
  },
  {
    image: '/assets/images/cost-the-app.png',
    subj: 'Kalorometer',
    text: 'K&K innovative Kalorometer means you can monitor your dog\'s calories intake day after day. From their K&K bespoke meals, wet food, and treats we\'ve got it covered to fulfil your dog nutritional needs.',
  },
  {
    image: '/assets/images/monitor-all-your-pets.png',
    subj: 'Monitor all your pets',
    text: 'Manage multiple dog\'s collars by setting individual profiles and goals on the App.',
  },
  {
    image: '/assets/images/community-image-icon.png',
    subj: 'Community',
    text: 'Share photos of your pet with other members of Club K&K and get lots of tips and advice from our Blog.',
  },
];

export default class MoreThanFood8 extends React.Component {
  constructor(props) {
    super(props);

    this.state = { windowWidth: window.innerWidth };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <CommonImageBanner image="url(/assets/images/mtf8-banner.jpg)" heading="App" subtext="a diet at unique as your pet" />
        <div className="row app-container">
          <div className="col-md-2" />
          <div className="col-md-4">
            <h1 className="lh-2 mb-2">Your dog's personal app</h1>
            <h6 className="color-orange lh-2 fw-600 mb-4">Used with Fitkolar, our easy to use K&amp;K App helps you ensure your four-legged friend packs in enough activities every day.</h6>
            <p className="lh-5">It also helps us ensure your dog gets just the right food blend and portions - every day!</p>
          </div>
          <div className="col-md-5">
            <img src="/assets/images/apps.png" />
          </div>
          <div className="col-md-1" />
        </div>
        <div className="kf-container">
          <div className="inner-container">
            <h1 className="text-center mb-5">Key features</h1>
            <div className="row">
              {key_features.map((f, cindex) => (
                <div key={cindex} className="col-6 col-sm-4">
                  <FoodCard image={f.image} subj={f.subj} text={f.text} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="free-app-container">
          <h3 className="color-orange fw-600 mb-3">Free App</h3>
          <h6 className="color-white fw-600 mb-2">The data from your Fitkolar will automatically update when it's near your phone.</h6>
          <p className="mb-3">Please ensure your Bluetooth is enabled.</p>
          <div className="row">
            <div className="col-6">
              <Link to="/404" onClick={() => scrollTo(0,0)}>
                <img className="left" src="/assets/images/app-store-icon.png" />
              </Link>
            </div>
            <div className="col-6">
              <Link to="/404" onClick={() => scrollTo(0,0)}>
                <img className="right" src="/assets/images/google-play-icon.png" />
              </Link>
            </div>
          </div>
        </div>
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
