import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import * as dogsSelectors from '../data/dogs/selectors';

const navigation = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    state: 'dashboard',
  },
  {
    name: 'Pet Profile',
    link: '/dog-profile',
  },
  {
    name: 'Daily Menu Plan',
    link: '/your-dogs-food',
  },
  {
    name: 'Shop',
    link: '/shop',
  },
  {
    name: 'Subscription',
    link: '/live-orders',
  },
];

class SubNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      visibleDropdown: '',
    };
  }

  render() {
    return (
      <div className="sub-navigation">
        <div className="sub-nav-inner">
          {navigation.map((item, index) => (
            <a
              key={index}
              className={`${window.location.href.indexOf(item.link) !== -1 ? ' active ' : ''} sub-navigation-option`}
              href={item.link}
            >
              <div className="link-container">
                <p className={`${window.location.href.indexOf(item.link) !== -1 ? ' active ' : ''} sub-navigation-link`}>{item.name}</p>
              </div>

              {item.dropdowns !== undefined && (
                <div className="sub-navigation-dropdown">
                  {item.dropdowns.map((dropdown, i) => (
                    <Link
                      key={i}
                      to={dropdown.link}
                      className={`${dropdown.link.indexOf(`/${window.location.pathname.split('/')[1]}`) !== -1 ? ' active ' : ''} sub-navigation-dropdown-option`}
                    >
                      {dropdown.text}
                    </Link>
                  ))}
                </div>
              )}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

const SubNav = ({ dog }) => (
  <SubNavigation dog={dog} />
);

export default connect(() => ({
  dog: dogsSelectors.getCurrentDog(),
}))(SubNav);
