import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import Dropdown from 'react-dropdown';

import CheckboxNew from '../../components/CheckboxNew';
import * as dogsSelectors from '../../data/dogs/selectors';

import CloseIcon from '../../assets/images/wizard/review-close.png';

const ingredients = [
  'Barley',
  'Lamb',
  'Rice',
  'Beef',
  'Maize',
  'Turkey',
  'Dairy',
  'Pork',
  'Wheat',
  'Chicken',
  'Rabbit'
];

const flavours = [
  'Chicken',
  'Lamb',
  'Salmon',
  'Turkey'
];

const dietaryPreferences = [
  'Cereal free',
  'Grain free',
  'Hypoallergenic'
];

class FoodDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titbit: 'yes',
      diet: ''
    };
  }

  titbitChange = (e) => {
    this.setState({
      titbit: e.target.value === 'yes' ? true : false
    })
  }

  styledLabel = (value) => {
    return (
      <span
        style={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 14,
        }}>
        {value}
      </span>
    );
  };

  setDietaryPreferences = () => {

  }

  render() {
    const dogName = dogsSelectors.getCurrentDog().name;
    const diet = this.props.dog.diets && this.props.dog.diets.length > 0 && this.props.dog.diets[0].name

    return (
      <div
        className="modal animated fadeIn food-details-modal"
        id="food-details"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered row justify-content-center"
          role="document">
          <div className="modal-content">
            <div className="modal-header px-0 py-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Food Details
              </h5>
              <button
                type="button"
                className="close text-right"
                onClick={this.props.cancelStep}
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">
                  <img src={CloseIcon} alt="" />
                </span>
              </button>
            </div>

            <div className="modal-body px-0 py-0">
              <div className="ingredients">
                <div className="label">Ingredients to be left out</div>
                <div className="row ingredients--list">
                  {ingredients.map((i) => {
                    var filter = this.props.dog.allergens && this.props.dog.allergens.filter((a) => a.name === i)
                    var isChecked = filter && filter.length > 0
                    return (
                      <div className="col-md-4 col-sm-6" key={i}>
                        <CheckboxNew
                          onClick={() => { }}
                          label={i}
                          isChecked={isChecked}
                          white
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="dietary-titbit">
                <div className="dietary">
                  <div className="label">Dietary Preferences</div>
                  <Dropdown
                    defaultOption={dietaryPreferences[0]}
                    value={diet}
                    options={dietaryPreferences}
                    onChange={() => this.setDietaryPreferences}
                  />
                </div>
                <div className="titbit">
                  <div className="label">Leftovers or titbits</div>
                  <RadioGroup
                    aria-label="titbit"
                    name="titbit"
                    className="titbit--radio"
                    value={this.state.titbit}
                    onChange={this.titbitChange}
                    row>
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label={this.styledLabel('Yes')}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label={this.styledLabel('No')}
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="flavours">
                <div className="label">Farourite Flavours</div>
                <div className="row flavours--list">
                  {flavours.map((i) => {
                    var filter = this.props.dog.flavours.filter((a) => a.name === i)
                    return (
                      <div className="col-md-4 col-sm-6" key={i}>
                        <CheckboxNew
                          onClick={() => { }}
                          label={i}
                          isChecked={filter.length > 0}
                          white
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button className="cancel-btn" onClick={this.props.cancel}>
                Cancel
              </button>
              <button className="next-btn" onClick={this.save}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dog: dogsSelectors.getCurrentDog(),
});

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FoodDetails));
