import { store } from '../../store';

export const getCartItemsByDog = (dogs, products, cart) => {
  const items = [];
  let dog = null;
  cart.forEach((item) => {
    if (products[item.productId] !== undefined && dogs[item.dogId] !== undefined) {
      const tempProduct = products[item.productId];
      tempProduct.quantity = item.quantity;
      items.push(tempProduct);
      dog = dogs[item.dogId];
    }
  });
  return {
    items, dog,
  };
};

export const isCartEmpty = () => {
  let isEmpty = true;
  const { products, cart } = store.getState().data;
  cart.forEach((item) => {
    if (products[item.productId] !== undefined) {
      isEmpty = false;
    }
  });
  return isEmpty;
};

export const getFitkolar = () => {
  const { products } = store.getState().data;
  return Object.values(products).find(product => product.is_fitkolar);
};
