import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Grid, Hidden } from '@material-ui/core';
import Carousel from 'react-slick';
import ContainedButton from '../../ContainedButton';
import DogCheckbox from './DogCheckbox';
import { getFitkolar } from '../../../data/cart/utils';
import * as cartActionCreators from '../../../data/cart/actions';

class ChooseDog extends React.Component {
  constructor(props) {
    super(props);
    const dogs = [];
    Object.values(props.dogs).forEach((dog) => {
      dogs.push({
        dog,
        selected: false,
      });
    });
    if (dogs.length === 1) {
      dogs[0].selected = true;
    }
    this.state = {
      dogs,
    };
  }

  changeSelection = changedItem => () => {
    this.setState(prevState => ({
      dogs: prevState.dogs.map((item) => {
        const newItem = item;
        if (newItem.dog.ID === changedItem.dog.ID) {
          newItem.selected = !item.selected;
        }
        return newItem;
      }),
    }));
  };

  addToCart = () => {
    const selectedDogs = this.state.dogs.filter(item => item.selected);
    const fitkolar = getFitkolar();
    this.props.addToCartForMultiple(
      selectedDogs.map(dog => dog.dog.ID),
      [fitkolar.ID],
    );
    this.props.closeModal();
  };

  render() {
    const { dogs } = this.state;
    const sliderContent = dogs.map(item => (<DogCheckbox
      key={item.dog.ID}
      item={item}
      changeSelection={this.changeSelection}
    />));

    return (
      <Grid container className="choose-dog-container h-100" alignContent="flex-start">
        <Grid item xs={12}>
          <h2 className="extra-header">Which of your pets will get a fitkolar?</h2>
        </Grid>
        <Grid item xs={12}>
          <p className="pb-3">
            Please choose one or several pets who will get a Fitkolar. Items will
            <Hidden smDown><br /></Hidden> be automatically added to Trial Boxes.
          </p>
        </Grid>
        <Grid item xs={12}>
          <Hidden smDown>
            <Carousel
              slidesToShow={dogs.length >= 2 ? 2 : 1}
              arrows={false}
              infinite={false}
            >
              {sliderContent}
            </Carousel>
          </Hidden>
          <Hidden mdUp>
            <Carousel
              slidesToShow={dogs.length >= 2 ? 2 : 1}
              arrows={false}
              infinite={false}
              vertical
              verticalSwiping
            >
              {sliderContent}
            </Carousel>
          </Hidden>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <ContainedButton title="add to cart" onClick={this.addToCart} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  dogs: state.data.dogs.dogs,
});
const mapDispatchToProps = dispatch => ({
  addToCartForMultiple: (dogIds, productIds) => dispatch(cartActionCreators.addToCartForMultiple(dogIds, productIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseDog);
