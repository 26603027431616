import React from 'react';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import CloseIcon from '../../assets/images/wizard/review-close.png';
import Button from '../../components/Button';
import * as actionTypes from '../../data/exports';
import * as selectors from '../../data/dogs/selectors';
import * as dogsActionCreators from '../../data/dogs/actions';

import Calendar from './Calendar';
import CalendarIcon from '../../assets/images/calendar-icon.png';
import WizardInput from './WizardInput';


class NursingOrPregnantTip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDatepicker: false,
      nursingDate: null,
      name: '',
    };

    this.toggleDatepicker = () => {
      this.setState({ isDatepicker: !this.state.isDatepicker });
    };

    this.handleDateClick = (date) => {
      this.setState({
        nursingDate: date,
        isDatepicker: !this.state.isDatepicker,
      });
    };

    this.clearAll = () => {
      this.setState({ nursingDate: null });
    };
  }
  render() {
    const congrantsStyle = {
      color: '#007853',
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '18px',
    };
    const {
      title, description, target, isButton, btnLabel,
    } = this.props;
    return (
      <div className="modal custom-modal animated fadeIn" id={target} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <img src={CloseIcon} alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid px-0">
                <div className="row justify-content-center">
                  <div className="col-12 px-4 pb-2 tip-datepicker">
                    {title !== '' ?
                      <h5 className="modal-title text-left text-justify py-1">{title}</h5>
                      :
                      ''
                    }
                    <p className="modal-description text-left text-justify py-1">{description}</p>

                    <p style={congrantsStyle}>
                      When are the puppies due? Or if they have been born, when was their birthday?
                    </p>

                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 pl-xl-0 pl-lg-0 pl-md-0 pl-0 pr-0 dog-field">
                      <WizardInput
                        autofocus
                        animation=""
                        value={this.state.name}
                        max="10"
                        type="text"
                        placeholder="Dog's Name*"
                        tip="Dog's name"
                        keyTip=""
                        handleBlur={false}
                        onChangeText={e => this.setState({ name: e.target.value })}
                      />
                    </div>

                    <div
                      role="button"
                      onKeyPress={() => {}}
                      tabIndex={0}
                      className={`col-12 px-0 datepicker ${this.state.isDatepicker ? 'enabled' : ''}`}
                      onClick={this.toggleDatepicker}
                    >
                      <WizardInput
                        disabled
                        animation="fadeInTop delay-_2s"
                        type="text"
                        name="dob"
                        value={this.state.nursingDate !== null ? dateFns.format(this.state.nursingDate, 'DD/MM/YYYY') : ''}
                        placeholder="DD/MM/YYYY"
                        onChangeText={e => this.handleAgeChange(e)}
                      />

                      <span className="calendar-icon">
                        <img src={CalendarIcon} alt="" />
                      </span>
                    </div>
                    {this.state.isDatepicker ?
                      <Calendar
                        onChange={date => this.handleDateClick(date)}
                        clearDob={() => this.clearAll()}
                        dogDate={this.props.currentDog.dob}
                      />
                      :
                      ''
                    }

                    {isButton ?
                      <Button
                        onClick={() => this.props.btnFn(this.state.nursingDate, this.state.name)}
                        data-toggle="modal"
                        data-target={`#${target}`}
                        classes="button-primary fill red mt-4"
                        label={btnLabel}
                      />
                      :
                      ''
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer row mx-4 px-0 border-top justify-content-start">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  dogs: state.data.dogs,
  storeDogs: state.data.dogs.storeDogs,
  currentDog: state.data.dogs.currentDog,
  getIndex: selectors.getCurrentDogIndex(),
});
const mapDispatchToProps = dispatch => ({
  setDog: dog => dispatch(dogsActionCreators.set(dog)),
  toggleDogNav: value => dispatch(actionTypes.toggleDogNavigation(value)),
  updateCurrentDog: (key, index, value) =>
    dispatch(dogsActionCreators.updateStoreCurrentDog(key, index, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NursingOrPregnantTip);
