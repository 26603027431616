import React, { Component } from 'react';

export default class SimplyPowesome extends Component {
  render() {
    return (
      <div className="row simply-powesome">
        <div className="col-12 col-md-3">
          <h3>Simply pawesome</h3>
        </div>
        <div className="col-12 col-md-9">
          <img src="/assets/images/simply-pawesome.png" />
        </div>
      </div>
    );
  }
}
