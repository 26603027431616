import * as actionTypes from './actionTypes';

export const initialState = {
  orders: [],
  promo: [],
  referralPromos: [],
  questionnaire: {},
  incompleteOrder: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      const newState = Object.assign({}, state);
      if (action.state.orders) {
        newState.orders = {
          ...state.orders,
          ...action.state.orders.reduce((prev, curr) => ({
            ...prev,
            [curr.ID]: curr,
          }), {}),
        };
      }

      if (action.state.referralPromos) {
        newState.referralPromos = [];
        newState.referralPromos =  action.state.referralPromos.promos.slice();
      }

      return newState;

    case actionTypes.UPDATE_PROMOCODE:
      const oldState = Object.assign({}, state);
      if (action.state) {
        const newReferralPromos = oldState.referralPromos;
        const updatedPromoIndex = newReferralPromos.findIndex(promo => promo.ID === action.state.ID)
        newReferralPromos[updatedPromoIndex] = action.state;

        return {
          ...oldState,
          referralPromos: newReferralPromos,
        }
      }

    case actionTypes.UPDATE_QUESTIONNAIRE:
      let questionnaireState = Object.assign({}, state)
      if (action.state) {
        questionnaireState.questionnaire = {
          ...state.questionnaire,
          ...action.state
        };
      }

      return questionnaireState;

    case actionTypes.UPDATE_INCOMPLETE_ORDER:
      let incompleteOrderState = Object.assign({}, state)
      if (!action.state) {
        incompleteOrderState.incompleteOrder = [];
      } else {
        if (action.state.items && action.state.items.length > 0) {
          incompleteOrderState.incompleteOrder = action.state.items.filter(i => i.quantity > 0).map(i => {
            i.order_id = action.state.ID
            i.key = i.product.ID;
            let name = i.product.name;
            i.imageUrl = i.product.image.Url
            i.product = name;
            return i
          })
        } else {
          incompleteOrderState.incompleteOrder = [];
        }
      }

      return incompleteOrderState;

    default:
      return state;
  }
};
