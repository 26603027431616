import React from 'react';

import Navigation from '../components/Navigation';
import RightSideImageBanner from '../components/RightSideImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import SimplyPowesome from '../components/SimplyPowesome';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';

const teamInfo = [
  {
    image: '/assets/images/calories-container.png',
    subj: 'Scott the vet',
    desc: 'Morbi pharetra in enim non dignissim. Pellentesque posuere pretium nisl, eget interdum dui lobortis non.',
  },
  {
    image: '/assets/images/calories-container.png',
    subj: 'Scott the vet',
    desc: 'Morbi pharetra in enim non dignissim. Pellentesque posuere pretium nisl, eget interdum dui lobortis non.',
  },
  {
    image: '/assets/images/calories-container.png',
    subj: 'Scott the vet',
    desc: 'Morbi pharetra in enim non dignissim. Pellentesque posuere pretium nisl, eget interdum dui lobortis non.',
  },
  {
    image: '/assets/images/calories-container.png',
    subj: 'Scott the vet',
    desc: 'Morbi pharetra in enim non dignissim. Pellentesque posuere pretium nisl, eget interdum dui lobortis non.',
  },
  {
    image: '/assets/images/calories-container.png',
    subj: 'Scott the vet',
    desc: 'Morbi pharetra in enim non dignissim. Pellentesque posuere pretium nisl, eget interdum dui lobortis non.',
  },
];

export default class MeetKasper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // //Adds a span around each letter in the string
    $('.how-it-works-text').lettering();

    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation />
        <RightSideImageBanner image="url(/assets/images/dog-with-food.png)" heading="About us" subtext="" />
        <div className="row bare-bones">
          <div className="col-md-1" />
          <div className="col-md-5">
            <p className="heading">The bare bones of our idea</p>
            <p className="subj">Kasper &amp; Kitty are inspired by many pets, and the ones our founder has seen on his travels around the world. Like their busy owners, it's often hard to find the time to lead a healthy lifestyle, or eat the right food, and weight can creep on.</p>
            <p className="desc">That's why Kasper &amp; Kitty's totally bespoke complete dry foods can help. Combined with our smart activity tracker - the innovative Fitkolar, specifically designed to work with our app - we can not only help bring your four-legged friend back his old self, but give them even more woofs, courtesy of those little healthy K&amp;K treats!</p>
            <p className="desc">We can make sure your pet has the right healthy diet for his lifestyle - and with Club K&amp;K we can also help you find other ways to enjoy having your dog and looking after both of you.</p>
            <p className="desc"><strong>It's really quite simple.</strong></p>
          </div>
          <div className="col-md-5">
            <img src="/assets/images/meet-kk-bare-bones.png" />
          </div>
          <div className="col-md-1" />
        </div>
        <div className="row healthy-lead">
          <div className="col-12 col-md-6 offset-md-6">
            <p className="heading">Go on! Give your pet a healthy lead</p>
            <p className="desc">Register for our deliciously bespoke subscription-based food delivery service. And don't forget to order your K&amp;K activity tracker, so your dog can start living his best life!</p>
          </div>
        </div>
        <div className="kk-story">
          <div className="inner-container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-8">
                <p className="heading">Kasper &amp; Kitty's story</p>
                <p className="subj">Our Kasper &amp; Kitty live just on the outskirts of a big city near a big park. They are used to spending long hours home alone. Kitty is streetwise of course, and comes and goes as she pleases. But it's trickier for Kasper. It often feels like a long day for him until his owner returnes.</p>
                <p className="desc">Kasper is naturally a high-energy dog - he loves nothing more than running around, chasing balls and sticks, and getting muddy! Nothing unusual there - according to vets, a Golden Retriever should take 30,000 steps a day!</p>
                <p className="desc">But when he doesn't, just like any of us, he doesn't enjoy himself so much and his weight can creep up quickly.</p>
                <p className="desc">It was Kitty's idea to introduce their owners to K&amp;K by &quot;borrowing&quot; their neighbour's dog's Fitkolar. Oscar is a wise Poodle and was really happy to help his friends. And he's seen the difference it has made to them. Kasper has definitely got his woofness back, and Kitty loves him being enthusiastic again.</p>
                <p className="desc"><strong>K&amp;K's bespoke pet wellbeing offer has made the world of difference to Kasper. Keeping him at his best. All the time.</strong></p>
              </div>
            </div>
          </div>
        </div>
        <div className="kk-team">
          <div className="container-fluid">
            <div className="inner-container">
              <div className="row justify-content-center">
                <p className="heading">The K&amp;K team</p>
                {teamInfo.map((item, index) => (<div className="col-lg col-md-4 col-6 team-card">
                  <img src={item.image} />
                  <p className="subj">{item.subj}</p>
                  <p className="desc">{item.desc}</p>
                </div>))}
              </div>
            </div>
          </div>
        </div>
        <SimplyPowesome />
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
