import React from 'react';

import Row from '../components/Row';

const RadioButtons = ({
  context,
  leftLabel,
  leftValue,
  rightLabel,
  rightValue,
  activeValue,
  onChange,
  white,
}) => (
  <div className={`${white ? ' white ' : ''}radio-container`}>
    <Row>
      <div className="col-12 col-lg-auto align-self-center no-padding">
        <p className="context">{context}</p>
      </div>
      <div className="col-auto ml-auto align-items-center">
        <Row>
          <div className="col-12 col-md-6 align-self-center">
            <a
              className="button-container"
              onClick={() => onChange(leftValue)}
            >
              <div className="radio-button">
                <img
                  className={`${activeValue === leftValue ? ' active ' : ''}tick`}
                  src="/assets/images/tick.png"
                  alt="Tick Icon"
                />
              </div>
              <p className="label">{leftLabel || leftValue}</p>
            </a>
          </div>
          <div className="col-12 col-md-6 align-self-center">
            <a
              className="button-container"
              onClick={() => onChange(rightValue)}
            >
              <div className="radio-button">
                <img
                  className={`${activeValue === rightValue ? ' active ' : ''}tick`}
                  src="/assets/images/tick.png"
                  alt="Tick Icon"
                />
              </div>
              <p className="label">{rightLabel || rightValue}</p>
            </a>
          </div>
        </Row>
      </div>
    </Row>
  </div>
);

export default RadioButtons;
