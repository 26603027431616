import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import Navigation from '../components/Navigation';
import DogNavigation from '../components/DogNavigation';
import SubNavigation from '../components/SubNavigation';
import Row from '../components/Row';

import Footer from '../components/Footer';
import SubscriptionBox from '../components/SubscriptionBox';
import ShoppingCart from '../components/ShoppingCart';
import Pagination from '../components/Pagination';

import * as dogsActionCreators from '../data/dogs/actions';
import * as dogsSelectors from '../data/dogs/selectors';
import * as productsActions from '../data/products/actions';
import * as ordersActionCreators from '../data/orders/actions';
import * as ordersSelectors from '../data/orders/selectors';

// tracker <  wet food < dental < treats < etc
function productSorter(p1, p2) {
  if (p1.name.includes('Activity Tracker')) {
    return -1
  }
  if (p2.name.includes('Activity Tracker')) {
    return 1
  }

  if (p1.categories && !p2.categories) {
    return -1
  }
  if (!p1.categories && p2.categories) {
    return 1
  }
  if (p1.categories && p2.categories) {
    if (p1.categories.find(cat => cat.name.includes("Dentals"))) {
      return -1
    }
    if (p2.categories.find(cat => cat.name.includes("Dentals"))) {
      return 1
    }
    if (p1.categories.find(cat => cat.name.includes("Treats"))) {
      return -1
    }
    if (p2.categories.find(cat => cat.name.includes("Treats"))) {
      return 1
    }
    if (p1.categories.find(cat => cat.name.includes("Wet Food"))) {
      return -1
    }
    if (p2.categories.find(cat => cat.name.includes("Wet Food"))) {
      return 1
    }
    if (p1.categories.find(cat => cat.name.includes("Collars"))) {
      return -1
    }
    if (p2.categories.find(cat => cat.name.includes("Collars"))) {
      return 1
    }
  }
  return 0
}

class Shop extends React.Component {
  constructor(props) {
    super(props);

    const storage = localStorage.getItem('subscription');
    const pageNum = this.props.location.search.includes('page') ? parseInt(this.props.location.search.substring(6, this.props.location.search.length)) : 1;

    this.state = {
      products: [],
      incompleteOrder: [],
      storageSubscription: storage !== null ? JSON.parse(storage) : [],
      subscription: {},

      currentPage: pageNum,
      totalPage: 1,
      itemsCountPerPage: 22,
      totalItemsCount: 1,
    };
  }

  componentDidMount() {
    const { itemsCountPerPage } = this.state;

    this.loadSubscriptionOrder();

    this.props.actions.dogs.list();
    this.props.actions.products.getProducts()
      .then((prod) => {
        if (Array.isArray(prod)) {
          this.setState({
            products: prod.sort(productSorter),
            totalPage: Math.ceil(prod.length / itemsCountPerPage),
            totalItemsCount: prod.length,
          });
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.dog && prevProps.dog !== this.props.dog) {

      // filter subscription when switching dog
      const { storageSubscription } = this.state;

      let index = storageSubscription.findIndex(sub => sub.dogID === this.props.dog.ID)
      if (index !== -1) {
        this.setState({
          subscription: storageSubscription[index].subscription
        })
      }
    }
  }

  loadSubscriptionOrder = () => {
    const { storageSubscription } = this.state;

    // check if subscription already exist in local storage
    // if exist, load subscription from localstorage
    if (this.props.dog && this.props.dog.ID !== '' && storageSubscription.length > 0) {
      let index = storageSubscription.findIndex(sub => sub.dogID === this.props.dog.ID)
      if (index !== -1) {
        this.setState({
          subscription: storageSubscription[index].subscription
        })
      }
    }
  }

  handlePageChange = pageNumber => {
    if (pageNumber === 1) {
      this.props.history.push('/shop')
    } else {
      this.props.history.push('/shop?page=' + pageNumber)
    }
  };

  // get subscription when localstorage is updated
  updatedLocalStorage = () => {

    const subscriptionStorage = localStorage.getItem('subscription');
    const allSubscription = subscriptionStorage !== null ? JSON.parse(subscriptionStorage) : [];

    if (allSubscription && allSubscription.length > 0 && this.props.dog) {

      const filtered = allSubscription.filter(sub => sub.dogID === this.props.dog.ID)

      if (filtered.length > 0) this.setState({ subscription: filtered[0].subscription });
    }
  }

  render() {

    const {
      subscription, currentPage, itemsCountPerPage, totalPage, totalItemsCount,
    } = this.state;

    // Logic for displaying products per page
    const indexOfLastProduct = currentPage * itemsCountPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsCountPerPage;
    let currentPageItems = [];

    if (Array.isArray(this.state.products)) {
      currentPageItems = this.state.products.slice(indexOfFirstProduct, indexOfLastProduct);
    }

    return (
      <div className="shop-container container">
        <Navigation
          white
          textColor="white-text"
          productCount={this.props.incompleteOrder && this.props.incompleteOrder.reduce((acc, cur) => acc + cur.quantity, 0)}
        />

        {this.props.dogs && Object.keys(this.props.dogs).length >= 2 &&
          <DogNavigation />
        }

        <SubNavigation />

        <div className="inner-container">
          <div className="menu-and-cart-shop">
            <div className="row">
              <div className="col-12">
                <div className="menu-title">
                  <img className="title-image" src="../assets/images/dashboard-nav-icon.png" alt="#" />
                  {this.props.dog !== undefined && this.props.dog.name.toUpperCase()}&apos;S SHOP
                </div>

                <Row className="d-block d-sm-none">
                  <Link to="/subscription">
                    <button className="d-block w-100 btn-view-subs-box">VIEW CURRENT SUBSCRIPTION BOX</button>
                  </Link>
                </Row>

                <Row className="menu-and-cart">
                  <div className="col-12 col-sm-6 col-md-8 col-lg-8 padding-right padding-bottom small full-height">
                    <div className="shop-items-container">
                      {currentPageItems.map((item) => {
                        let alreadyInSubscription;
                        let itemInSubscription;
                        if (subscription.items !== undefined && subscription.items.length > 0) {
                          itemInSubscription = subscription.items.find(product => product.product_id === item.ID);
                          alreadyInSubscription = itemInSubscription !== undefined;
                        }

                        const quantity = itemInSubscription !== undefined ? itemInSubscription.quantity : 0;
                        let prodName = item.name.replace(/[(,)`)]/g, '').split(' ').join('-').toLowerCase();
                        let newURL = item.ID + '-' + prodName

                        return (
                          <div
                            key={item.ID}
                            className={`shop-item-container ${alreadyInSubscription ? 'shop-item-container-cart' : ''}`}
                          >
                            <Link to={{ pathname: `/product/${newURL}` }}>
                              <div className="shop-item">
                                <div className={`shop-item-image ${alreadyInSubscription ? 'shop-item-image-cart' : ''}`}>
                                  {alreadyInSubscription && (<span className="shop-item-count">{quantity}</span>)}
                                  <div
                                    style={item.image.Url !== '' ? { backgroundImage: `url(${item.image.Url})` } : { backgroundImage: 'url(/assets/images/cereal.png)' }}
                                    className="item-image"
                                  />
                                </div>

                                <div className="shop-item-information">
                                  <div className="product-detail">
                                    <div className="product-name">{item.name}</div>
                                    <div className="product-description">{item.description}</div>

                                    {quantity !== 0 &&
                                      <div className="counts-hint">
                                        You currently have {quantity} {quantity === 1 ? 'item' : 'items'} in your monthly subscription box.
                                      </div>
                                    }

                                    {quantity === 0 &&
                                      <div className="counts-hint" />
                                    }

                                    <div className="product-price">
                                      £{item.price % 1 < 0.01 ? `${item.price}.00` : item.price}
                                    </div>
                                  </div>
                                  <div className="subscription-icon">
                                    {quantity !== 0 && <img className="checked-img" src="../assets/images/large-checked.png" alt="" />}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>

                    <div className="pagination-container">
                      <Pagination
                        activePage={currentPage}
                        onChange={this.handlePageChange}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={totalPage}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 padding-left padding-bottom small full-height">
                    <ShoppingCart
                      history={this.props.history}
                      checkoutURL="/checkout"
                    />

                    <SubscriptionBox dogID={this.props.dog ? this.props.dog.ID : ''} updatedLocalStorage={this.updatedLocalStorage} />
                  </div>

                </Row>
              </div>
            </div>
          </div>

        </div>

        <Footer />

      </div>
    );
  }
}

export default connect((state) => ({
  incompleteOrder: state.data.orders.incompleteOrder,
  dogs: state.data.dogs.dogs,
  dog: dogsSelectors.getCurrentDog(),
  orders: ordersSelectors.getOrders(),
}), dispatch => ({
  actions: {
    dogs: bindActionCreators(dogsActionCreators, dispatch),
    products: bindActionCreators(productsActions, dispatch),
    orders: bindActionCreators(ordersActionCreators, dispatch),
  },
}), null, { withRef: true })(Shop);
