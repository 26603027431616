import { store } from '../../store';

// eslint-disable-next-line import/prefer-default-export
export const getOrders = () => {
  const items = store.getState().data.orders.orders;
  const itemsArray = Object.keys(items).map(itemKey => items[itemKey]);
  itemsArray.sort((item1, item2) => parseInt(item1.ID, 10) > parseInt(item2.ID, 10));
  return itemsArray;
};

export const getReferralPromos = () => store.getState().data.orders.referralPromos;

export const getQuestionnaire = () => store.getState().data.orders.questionnaire;