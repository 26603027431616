import React from 'react';
import CheckboxInput from '../../components/CheckboxInput';

export default class BreedUnknownOption extends React.Component {
  render() {
    const {
      image, inputLabel, weight, breeds, animation, active, onClick,
    } = this.props;
    return (
      <div className={`variant-container position-relative text-center ${inputLabel} animated ${animation} ${active ? 'active' : ''}`} onClick={this.props.onClick}>
        <div className={"image-container"}>
          <img className="unknown-variant_image" src={image} alt="" />
        </div>
        <div className="unknown-variant_checkbox pt-3">
          <CheckboxInput
            color="orange"
            active
            onClick={onClick}
          />
          <span className="unknown-variant_label text-uppercase">{ inputLabel }</span>
        </div>

        <div className="unknown-variant_weight py-2">{weight}</div>
        <div className="unknown-variant_breeds">{breeds}</div>
      </div>
    );
  }
}
