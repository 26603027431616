import React from 'react';

import { Link } from 'react-router-dom';

import Navigation from '../components/Navigation';
import MainImageBanner from '../components/MainImageBanner';
import InstagramCarousel from '../components/InstagramCarousel';
import KaushanScriptHeading from '../components/KaushanScriptHeading';
import GreyText from '../components/GreyText';
import Pdsa from '../components/Pdsa';
import Footer from '../components/Footer';

const howItWorks = [
  {
    imagePath: '/assets/images/distance-icon.png',
    extraClass: 'one',
  },
  {
    imagePath: '/assets/images/forward-arrow-full.png',
    extraClass: 'two icon',
  },
  {
    imagePath: '/assets/images/cost-activity-trackers.png',
    extraClass: 'three',
  },
  {
    imagePath: '/assets/images/forward-arrow-full.png',
    extraClass: 'four icon',
  },
  {
    imagePath: '/assets/images/cost-the-app.png',
    extraClass: 'five',
  },
  {
    imagePath: '/assets/images/forward-arrow-full.png',
    extraClass: 'six icon',
  },
  {
    imagePath: '/assets/images/cost-bespoke-meals.png',
    extraClass: 'seven',
  },
  {
    imagePath: '/assets/images/forward-arrow-full.png',
    extraClass: 'eight icon',
  },
  {
    imagePath: '/assets/images/cost-treats.png',
    extraClass: 'nine',
  },
  {
    imagePath: '/assets/images/forward-arrow-full.png',
    extraClass: 'ten icon',
  },
  {
    imagePath: '/assets/images/calories-icon.png',
    extraClass: 'eleven',
  },
  {
    imagePath: '/assets/images/forward-arrow-full.png',
    extraClass: 'twelve icon',
  },
];

const howMuchCost = [
  {
    image: '/assets/images/cost-bespoke-meals.png',
    title: 'Bespoke Meals',
    sub_title: 'Monthly plan from £18',
    desc: 'Through our fully calorie-controlled diet, your dog gets the exact amount of food needed to keep him full of beans every day! So the cost will depend upoon their varying requirements. Let your pet try it for a month. If they don\'t like it, we will refund it. No questions asked.',
    link: '/dry-food',
  },
  {
    image: '/assets/images/cost-treats.png',
    title: 'Treats',
    sub_title: 'Included in your monthly plan',
    desc: 'Our treats aren\'t like other pet treats, Our recommended treats for your dog will fully complement ingredients found in their K&K\'s bespoke meals to minimise any risk of allergies and address specific dietary issues.',
    link: '/other-food',
  },
  {
    image: '/assets/images/cost-activity-trackers.png',
    title: 'Activity Trackers',
    sub_title: 'From £29.99',
    desc: 'Our entry price activity tracker, the Fitkolar, is only £29.99 or it\'s free for anyone taking a six month\'s subscription to our bespoke pet food plan. Simply attach it to your four-legged friend\'s collar, download the App and take control of their fitness.',
    link: '/shop',
  },
  {
    image: '/assets/images/cost-the-app.png',
    title: 'The App',
    sub_title: 'FREE',
    desc: 'Download your FREE K&K App from the App store or Android to get on with tracking your pet\'s activities and it won\'t be long before uniquely tailored, nutritious meals get delivered straight to their bowl-well at least to your front door!',
    link: '/app',
  },
  {
    image: '/assets/images/cost-no-need-the-paw.png',
    title: 'No need to paws',
    sub_title: 'FREE 48 hour delivery',
    desc: 'As soon as the food is delivered to your door, usually within 48 hours from placing your order, your pet can start living their best life!',
    link: '/shop',
  },
];

const spendJustWhatYouNeed = [
  {
    image: '/assets/images/wyn-fred.jpg',
    title: 'Fred The Terrier',
    link: '/404',
    contents: [
      {
        subj: 'Age: ',
        desc: '2 years old',
      },
      {
        subj: 'Build: ',
        desc: 'Small',
      },
      {
        subj: 'A little lazy',
        desc: '',
      },
      {
        subj: 'Weight is creeping in!',
        desc: '',
      },
      {
        subj: 'Solution: ',
        desc: 'A bespoke complete cereal-free calorie controlled diet! With a little help from the Fitkolar to set challenging goals and help Fred fit in these much-needed extra steps a day as he does like his snuggles by the fire on those cold days or to bask on the lawn on the warm days!',
      },
      {
        subj: 'Cost: ',
        desc: '£29.99 per month',
      },
      {
        subj: 'Extra: ',
        desc: '£29.99 Fitkolar (one-off)',
      },
    ],
  },
  {
    image: '/assets/images/wyn-spaniel.jpg',
    title: 'Bella the Spaniel',
    link: '/404',
    contents: [
      {
        subj: 'Age: ',
        desc: '12 months old',
      },
      {
        subj: 'Build: ',
        desc: 'Medium',
      },
      {
        subj: 'Turbo Paws',
        desc: '',
      },
      {
        subj: 'Active doggy',
        desc: '',
      },
      {
        subj: 'Solution: ',
        desc: 'Bella loves her chicken so her diet preference was set with this in mind. With her young age she has added nutrients and vitamins in her bespoke blend to help her bones and teeth grow strong. Her daily walkies are monitored by her owners Fitkolar data – as she has a dog walker that takes Bella out in the day!',
      },
      {
        subj: 'Cost: ',
        desc: '£29.99 per month',
      },
      {
        subj: 'Extra: ',
        desc: '£29.99 Fitkolar (one-off)',
      },
    ],
  },
  {
    image: '/assets/images/wyn-retriever.jpg',
    title: 'Kasper The Retriever',
    link: '/404',
    contents: [
      {
        subj: 'Age: ',
        desc: '8 years old',
      },
      {
        subj: 'Build: ',
        desc: 'Large',
      },
      {
        subj: 'Never stops',
        desc: '',
      },
      {
        subj: 'Likes his treats!',
        desc: '',
      },
      {
        subj: 'Solution: ',
        desc: 'Kasper responds well to treats but too many can let the weight creep in, with his bespoke K&K diet we ensure that he is given the right daily amount of treats along with his nutritious food. On his walks he likes to play in puddles and his owner is very happy that the Fitkolar is waterproof too!',
      },
      {
        subj: 'Cost: ',
        desc: '£29.99 per month',
      },
      {
        subj: 'Extra: ',
        desc: '£29.99 Fitkolar (one-off) & Treats',
      },
    ],
  },
];

export default class TakeLead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
      activeCircleButton: 'Your Pet',
      howItWorks: 'Register',
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    // //Adds a span around each letter in the string
    $('.how-it-works-text').lettering();

    // Updates the state of the window width when the window is resized
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const width = window.innerWidth;

    this.setState({ windowWidth: width });
  }

  render() {
    return (
      <div className="container">
        <Navigation
          absolute
          white
          textColor="white-text"
        />
        <MainImageBanner
          image="url(/assets/images/take-the-lead.jpg)"
          heading="How it works"
          subtext="Unleash their pawsomeness!"
        />
        <div className="how-it-works hide-on-mobile">
          <KaushanScriptHeading title="How it works..." />
          <div className="content-wrapper">
            <div className="text-container">
              <p className="main font-40">A diet as unique as your pet, straight to their bowl</p>
              <p className="subtext">Your trimmer, fitter, happier dog</p>
            </div>
            <div className="circle-component-container">
              <div className="circle-component">
                <div className="inner-circle">
                  {howItWorks.map((item, index) => (
                    <img
                      key={index}
                      className={`${item.extraClass} how-it-works-image`}
                      src={item.imagePath}
                      alt="Icon"
                    />
                  ))}
                  <div className="active content-container">
                    <p className="heading">Register</p>
                    <div className="line" />
                    <p className="information">Enrol your pet for free to join K&K and get the ball rolling! And sign up for bowl-side home deliveries to keep your best friend healthy.</p>
                    <div className="active link">
                      <a href="/login">Register now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dog-image">
              <img src="/assets/images/kasper-and-ball.png" alt="Kasper Image" />
            </div>
          </div>
        </div>
        <div className="tailored-section">
          <div className="ts-gradient">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-6 offset-md-6">
                  <KaushanScriptHeading title="Tailored, calorie-controlled food, for EVERY stage of YOUR dog's life" />
                  <p className="white bold">Of course, you can just buy your own premium, super healthy, nutritious bespoke pet food. But it isn't just your pet's age and weight that can impact their nutritional needs. Their lifestyle does too.</p>
                  <p className="white">Simply attach our smart, lightweight activity-tracking Fitkolar onto your dog's collar and download our free K&amp;K App to make sure they are packing in enough fun everyday.</p>
                  <p className="white">You might be at work, out and about or even asleep - our Fitkolar never stops working, so we can create a unique diet that fulfils your pet's exact nutritional requirements.</p>
                  <p className="white">Forget the evening trip at rush hour to the local supermarket for that packet of treats - because those too are delivered straight to your door!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="how-much-does-it-cost">
          <KaushanScriptHeading title="How much does it cost?" />
          <div className="container">
            <div className="row cost-card-group">
              {howMuchCost.map((item, index) => (<div key={index} className="col-xl col-lg-4 col-md-6 pl-0 pr-2">
                <div className="cost-card">
                  <img className="img-responsive img-circle" src={item.image} />
                  <p className="heading">{item.heading}</p>
                  <p className="subj">{item.sub_title}</p>
                  <p className="desc">{item.desc}</p>
                  <a href={item.link}>More</a>
                </div>
                                                 </div>))}
            </div>
          </div>
        </div>
        <div className="spend-just-what-you-need">
          <KaushanScriptHeading title="Spend just what you need" />
          <div className="row">
            {spendJustWhatYouNeed.map((item, index) => (
              <div key={index} className="col-lg col-md-6 no-padding">
                <div className="dog-card">
                  <img src={item.image} />
                  <div className="content">
                    <p className="heading">{item.title}</p>
                    {item.contents.map((c, index) => (<p className="desc">
                      <strong>{c.subj}</strong>{c.desc}
                    </p>))}
                    <a href={item.link} className="sample-menu">Sample Menu</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*<InstagramCarousel />*/}
        <Pdsa />
        <Footer />
      </div>
    );
  }
}
