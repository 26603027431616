import React from 'react';

const ProgressBar = props => (
  <div className="progress">
    <div
      className={`progress-bar ${props.isTransparent ? 'transparent' : props.color}`}
      role="progressbar"
      style={{ width: `${props.percentage < props.max ? props.value : 100}%` }}
      aria-valuenow={props.value}
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <span>{props.percentage < props.max ? props.percentage : props.max }%</span>
    </div>
  </div>
);
export default ProgressBar;
