import React from 'react';
import { Link } from 'react-router-dom';

import Row from '../components/Row';

export default class AccountSidebar extends React.Component {

  render() {

    const sidebar = [
      {
        image: '/assets/images/dashboard-nav-icon.png',
        title: 'Your pets',
        link: '/your-pets'
      },

      {
        image: '/assets/images/my-account-nav-icon.png',
        title: 'Your details',
        link: '/your-details'
      },

      {
        image: '/assets/images/billing-icon.png',
        title: 'Card details',
        link: '/card-details'
      },

      {
        image: '/assets/images/password-icon.png',
        title: 'Password',
        link: '/password-reset'
      },

      {
        image: '/assets/images/billing-icon.png',
        title: 'Billing address',
        link: '/billing-details'
      },

      {
        image: '/assets/images/delivery-icon.png',
        title: 'Delivery address',
        link: '/delivery-details'
      },
    ];

    return (
      <div className="sidebar">
        {sidebar.map((option, index) => (
          <Link to={option.link} onClick={() => { scrollTo(0, 0) }} key={index}>
            <Row className="option" >
              <div className="col-md-1">
                <img src={option.image} />
              </div>

              <div className="col-md-9" >
                <p>{option.title}</p>
              </div>
            </Row>
          </Link>
        ))}
      </div>
    );
  }
}
