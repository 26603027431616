import React from 'react';
import { Link } from 'react-router-dom';

import * as session from '../../services/session';

import Navigation from '../../components/Navigation';
import ImageBanner from '../../components/ImageBanner';
import TitleBox from '../../components/TitleBox';
import Footer from '../../components/Footer';
import FullColumn from '../../components/FullColumn';
import Row from '../../components/Row';
import LinkButton from '../../components/LinkButton';
import OrangeTextHeading from '../../components/OrangeTextHeading';
import GreyText from '../../components/GreyText';
import Button from '../../components/Button';
import ForgotPasswordCard from '../../components/ForgotPasswordCard';
import dogImage from '../../assets/images/reset_password_dog.png';

export default class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
  }
  onGotoEmail = () => {
    location.href = 'mailto:hello@email.com';
  }
  render() {
    return (
      <div className="container-fluid forgotten-password-container">
        <div className="row justify-content-center h-100">
          <div className="col-xl-5 col-lg-7 col-md-8 row justify-content-center align-items-xl-center align-items-lg-center align-items-start">
            <div className="col-xl-9 col-lg-9 col-md-12">
              <div className="forgot-card-container">
                <ForgotPasswordCard
                  title="Password Link sent"
                  description="Check your inbox and click on the link in the email to reset your password"
                >
                  <div className="row justify-content-center py-3">
                    <div className="col-xl-6 col-lg-6 col-md-8">
                      <Button
                        classes="button-primary fill red mb-3 animated fadeInUp delay-_5s"
                        label="GO TO E-MAIL"
                        onClick={this.onGotoEmail}
                      />
                      <div className="row justify-content-around">
                        <a href="https://www.kasperandkitty.com/support/" target="_blank" title="Support" className="animated fadeInUp delay-_75s forgot-link">Still need help?</a>
                        <Link to="/login" className="animated fadeInUp delay-_75s forgot-link">Back to Log In</Link>
                      </div>

                    </div>
                  </div>
                </ForgotPasswordCard>
              </div>

            </div>

          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 forgotten-password-banner password-reset">
            <div className="forgotten-password-image-container password-reset">
              <img className="dog-image reset-pass animated fadeInRight" src={dogImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
