import React from 'react';
import PaginationComponent from 'react-js-pagination';

export default class Pagination extends React.Component {
  handlePageChange(pageNumber) {
    this.props.onChange(pageNumber);
  }

  render() {
    const {
      activePage,
      onChange,
      itemsCountPerPage,
      totalItemsCount,
      pageRangeDisplayed,
    } = this.props;
    return (
      <PaginationComponent
        prevPageText={<div><i className="fa fa-angle-left arrow" /> <i>Previous</i></div>}
        nextPageText={<div><i>Next</i> <i className="fa fa-angle-right arrow" /></div>}
        hideFirstLastPages
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={onChange}
      />
    );
  }
}
