import React from 'react';
import {
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getOrders } from '../data/dogs/api';
import { getUserInfo } from '../services/session/index';

import * as dogsSelectors from '../data/dogs/selectors';
import * as dogsActionCreators from '../data/dogs/actions';

class LoggedInRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      numDogs: 0,
      dogPaid: true,
    };
  }

  componentDidMount() {
    getUserInfo();
    this.props.actions.dogs.list().then((dogs) => {
      if (dogs.length !== 0) {
        const currentDog = dogsSelectors.getCurrentDog();

        if (currentDog === undefined) {
          this.props.actions.dogs.select(dogs[0].ID);
        }
        // if no orders for current dog, redirect to checkout
        getOrders()
          .then((orders) => {
            if (orders.length === 0) {
              this.setState({ dogPaid: false });
            }
            this.setState({
              numDogs: dogs.length,
              loading: false,
            });
          });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  render() {
    const Component = this.props.component;

    if (this.state.loading) {
      return null;
    }
    if (this.props.location.pathname.indexOf('/welcome') === -1 &&
      this.props.location.pathname.indexOf('/logout') === -1) {
      if (!this.state.dogPaid &&
        this.props.location.pathname.indexOf('/cart') === -1 &&
        this.props.location.pathname.indexOf('/delivery') === -1 &&
        this.props.location.pathname.indexOf('/payment') === -1) {
        return (
          <Route
            render={props => (
              <Redirect
                to={{
                  pathname: '/cart',
                  state: { from: props.location },
                }}
              />
            )}
          />
        );
      } else if (this.state.numDogs === 0) {
        return (
          <Route
            render={props => (
              <Redirect
                to={{
                  pathname: '/add-dog',
                  state: { from: props.location },
                }}
              />
            )}
          />
        );
      }
    }

    return (
      <Route
        render={props => <Component {...props} />}
      />
    );
  }
}

export default withRouter(connect(null, dispach => ({
  actions: {
    dogs: bindActionCreators(dogsActionCreators, dispach),
  },
}))(LoggedInRoute));
