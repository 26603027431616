import React from 'react';
import { Link } from 'react-router-dom';

import Row from './Row';
import BackgroundContainer from './BackgroundContainer';
import GreyText from './GreyText';
import OrangeTextHeading from './OrangeTextHeading';
import FullColumn from './FullColumn';
import InputField from './InputField';
import Dropdown from './Dropdown';


const titbitoptions = [
  {
    name: 'Chicken drumstick',
    portion: 200,
    kcalg: 5,
  },
  {
    name: 'Beef jerky',
    portion: 100,
    kcalg: 3,
  },
  {
    name: 'Sausage',
    portion: 20,
    kcalg: 15,
  },
  {
    name: 'Chips',
    portion: 100,
    kcalg: 3,
  },
  {
    name: 'Bread',
    portion: 15,
    kcalg: 18,
  },
];

const calorieIntake = [
  {
    desc: 'K&K bespoke dry food',
    qty: '[300g]',
    kcal: '400',
    perc: '85',
  },
  {
    desc: 'K&K wet food',
    qty: '[100g]',
    kcal: '130',
    perc: '5',
  },
  {
    desc: 'K&K treats',
    qty: '[1]',
    kcal: '60',
    perc: '6',
  },
  {
    desc: 'K&K dental treat',
    qty: '[2]',
    kcal: '120',
    perc: '4',
  },
  {
    desc: 'Titbits',
    qty: '1',
    kcal: '1000',
    perc: '4',
  },
];

const Item = ({
  food, portionvalue, kcalvalue, onDelete, onChangeQuantityPlus, onChangeQuantityMinus,
}) => (
  <Row className="rowpadding">
      <div className="col-4 cell">
        <div className="calheader">Titbit</div>
        <div className="treatname titbits">{food}</div>

      </div>
      <div className="col-2 cell">
        <div className="calheader">Portion</div>
        <div className="treatname titbits">{portionvalue}g</div>
      </div>

      <div className="col-2 cell">
        <div className="calheader">kcal/g</div>
        <div className="treatname titbits">{kcalvalue}</div>
      </div>

      <div className="col-2 cell">
        <div className="calheader">Total kcal</div>
        <div className="treatname titbits">{portionvalue * kcalvalue}</div>
      </div>

      <div className="col-2 cell">
        <div className="calheader"><br /></div>
        <div>
          <a
            className="plus-icon"
            onClick={onChangeQuantityPlus}
          >
            <img src="/assets/images/plus-circle.png" className="plusminus" />
          </a>
          <a
            className="minus-icon"
            onClick={onChangeQuantityMinus}
          >
            <img src="/assets/images/minus-circle.png" className="plusminus" />
          </a>
          <a
            className="bin-icon"
            onClick={onDelete}
          >
            <img src="/assets/images/delete.png" className="plusminus" />
          </a>
        </div>
      </div>
    </Row>

);

export default class Calories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTitbits: [],
      titbitTotal: 0,
      percentageTotal: 0,
    };

    this.isVariableAlreadyInState = this.isVariableAlreadyInState.bind(this);
  }

  isVariableAlreadyInState(variable, state) {
    return this.state[state].filter(item => item.name === variable).length > 0;
  }


  render() {
    const {
      portion,
      calories,
      // shape,
      dog,
      counter,
      percentage,

      shares,
      knames,
      ingredients,
      nutrients,
      vitamins,
      keyIngredients,
      // short,
      imgStyle = {
        maxWidth: '100%',
        maxHeight: '100%',
      },
      imgDiv = {
        height: '100%',
        width: '100%',
      },
    } = this.props;

    // if the dog has pancreatitis and is allergic to chicken, display message saying we can't cater atm
    var isPanAndChickenAllergen = false;
    if (dog.medical_conditions !== null) {
      isPanAndChickenAllergen =
        dog.medical_conditions.map(c => c.name).includes("Pancreatitis") &&
        dog.allergens != undefined &&
        dog.allergens.map(a => a.name).includes("Chicken");
    }

    const formattedShares = shares.map(s => parseFloat(s).toFixed(1));
    if (this.props.short) {
      // Allows 2 different usage scenarios
      return (
        <Row className="bespoke-calories">
          {counter && <div className="col-12 col-md-4 no-padding">
              <Row>
                <div className="col-12 col-sm-6 col-md-12 padding-right small padding-bottom">
                  <BackgroundContainer equalPadding>
                    <div className="dial-container">
                      <img
                        className="dial"
                        src="/assets/images/kaspermeter.png"
                      />
                      <img
                        className="dial-pointer"
                        src="/assets/images/dial-pointer.png"
                      />
                    </div>
                    <p className="dashboard-text dial">750 Calories</p>
                    <p className="dashboard-text orange">Left to eat</p>
                  </BackgroundContainer>
                </div>
              </Row>
            </div>
          }

          <div className={`col-12 ${counter ? 'col-md-8 padding-left' : 'col-md-12 no-padding'} padding-bottom medium`}>
            <BackgroundContainer equalPadding>
              <div>
                <OrangeTextHeading
                  text={`${dog.name}'s bespoke daily menu`}
                  fontSize={30}
                  centered
                  marginBottom
                />
                {/* calories per day */}
                <Row>
                  <div className="col-8 col-md-3 align-self-center">
                    <div className="dialy-menu-image">
                      <img
                        src="/assets/images/calories-container.png"
                        alt="Calories"
                      />
                      <div className="calories-text">
                        <p className="calories">{calories}</p>
                        <p className="text"><span>calories</span><br />per day</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-8 col-md-5 align-self-center">
                    <div className="dialy-menu-image">
                      <img
                        src="/assets/images/dog-bowl.png"
                        alt="Dog bowl"
                      />
                    </div>
                  </div>
                  <div className="col-8 col-md-4 align-self-center">
                    <div className="dialy-menu-image">
                      <img
                        src="/assets/images/treat-bag.png"
                        alt="Dog Treats"
                      />
                    </div>
                  </div>
                </Row>

                {/*/!* dog shape warning *!/ */}
                {/* {shape !== '' &&*/}
                {/* <Row>*/}
                {/* <div className="shape-warning">Looks like your dog is <b>{shape}</b>! Please contact a vet as soon as possible!</div>*/}
                {/* </Row>*/}
                {/*} */}

                {/* dry food serving suggestion */}
                {isPanAndChickenAllergen &&
                  <div class="grey-text">
                    Unfortunately due to your selected allergens we cannot currently supply you with a suitable pancreatitis diet. We will keep your details on file and inform you of any changes due to range extensions.
                  </div>
                }
                {!isPanAndChickenAllergen && <Row>
                  <FullColumn noPadding>
                    <OrangeTextHeading
                      text={`${dog.name}'s bespoke dry food`}
                      fontSize={26}
                      marginBottom
                    />
                    <div className="orange-border-bottom" />
                    <Row className="dry-food">
                      <div className="col-8 col-md-4 align-self-center">
                        <GreyText
                          text="Bespoke dry food daily serving"
                          fontSize={22}
                          marginBottom
                          bold
                        />
                        <GreyText
                          text={`Caters for ${percentage}% of daily needs`}
                          fontSize={16}
                          marginBottom
                          bold
                        />
                        <div className="portion-box">
                          {portion}g per portion
                        </div>
                      </div>
                      <div className="col-8 col-md-4 align-self-center">
                        <div className="dry-food-image">
                          <img
                            src="/assets/images/dry-food-serving.png"
                            alt="Dry Food Serving"
                          />
                        </div>
                      </div>
                      <div className="col-8 col-md-4 align-self-center">
                        {formattedShares.map((share, i) =>
                          (parseFloat(share) !== 0 ? <div className="serving"><span>{formattedShares[i]}%</span> {knames[i]}</div> : ""),)}
                      </div>
                    </Row>
                    <div>
                      <br />
                      <p className="margin-bottom grey-text">{ingredients.join(', ')}</p>
                      <br />
                      <p className="margin-bottom grey-text">{nutrients.join(', ')}</p>
                      <br />
                      <p className="margin-bottom grey-text">{vitamins.join(', ')}</p>
                    </div>

                  </FullColumn>
                </Row>}
              </div>
            </BackgroundContainer>
          </div>
        </Row>
      );
    } return (
      <Row className="bespoke-calories">
        {/* {counter &&  */}
        {/* <div className="col-12 col-md-4 no-padding">
          <Row>
            <div className="col-12 col-sm-6 col-md-12 padding-right small padding-bottom">
              <BackgroundContainer equalPadding>
                <div className="dial-container">
                  <img
                    className="dial"
                    src="/assets/images/kaspermeter.png"
                  />
                  <img
                    className="dial-pointer"
                    src="/assets/images/dial-pointer.png"
                  />
                </div>
                <p className="dashboard-text dial">750 Calories</p>
                <p className="dashboard-text orange">Left to eat<br /><br /></p>
                <div className="orange-border-bottom" />
                <GreyText
                  text="K&K daily calorie intake"
                  fontSize={18}
                  marginBottom
                  bold
                />
                <Row>
                  <div className="col-6 caltable">
                    <p className="calheader">Description</p>
                    {calorieIntake.map((item, index) => (<p>{item.desc}</p>))}
                  </div>
                  <div className="col-2 caltable">
                    <p className="calheader">Qty</p>
                    {calorieIntake.map((item, index) => (<p>{item.qty}</p>))}
                  </div>
                  <div className="col-2 caltable">
                    <p className="calheader">Kcal</p>
                    {calorieIntake.map((item, index) => (<p>{item.kcal}</p>))}
                  </div>
                  <div className="col-2 caltable">
                    <p className="calheader">%</p>
                    {calorieIntake.map((item, index) => (<p>{item.perc}</p>))}
                  </div>
                </Row>
                <Row>
                  <div className="col-8" />
                  <div className="col-2 caltotal">
                    <p>Total</p>
                  </div>
                  <div className="col-2 caltotal">
                    <p>90%</p>
                  </div>
                </Row>
                <Row>
                  <button className="editbutton" onClick={() => {
                    scrollTo(0, 0);
                  }}>Edit</button>
                </Row>
              </BackgroundContainer>
            </div>
          </Row>
        </div> */}
        {/* } */}

        <div className={`col-12 ${counter ? 'col-md-8 padding-left' : 'col-md-12 no-padding'} padding-bottom medium`}>
          <BackgroundContainer equalPadding>
            <div>
              <OrangeTextHeading
                text={`${dog.name}'s bespoke daily menu`}
                fontSize={30}
                centered
                marginBottom
              />
              {/* calories per day */}
              <row>
                <div className="col-md-6 offset-3">
                  <div className="dialy-menu-image">
                    <img
                      src="/assets/images/calories-container.png"
                      alt="Calories"
                    />
                    <div className="calories-text">
                      <p className="calories">{calories}</p>
                      <p className="text"><span>calories</span><br />per day</p>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="col-md-6 offset-3">
                  <div className="dialy-menu-image">
                    <img
                      src="/assets/images/dog-bowl.png"
                      alt="Dog bowl"
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="col-md-6 offset-3">
                  <div className="dialy-menu-image">
                    <img
                      src="/assets/images/treat-bag.png"
                      alt="Dog Treats"
                    />
                  </div>
                </div>
              </row>

              {/* /!* dog shape warning *!/ */}
              {/* {shape !== '' &&*/}
              {/* <Row>*/}
              {/* <div className="shape-warning">Looks like your dog is <b>{shape}</b>! Please contact a vet as soon as possible!</div>*/}
              {/*</Row> */}
              {/*} */}

              {/* dry food serving suggestion */}
              <Row>
                <FullColumn noPadding>
                  <OrangeTextHeading
                    text={`${dog.name}'s bespoke dry food`}
                    fontSize={30}
                    marginBottom
                  />
                  <div className="orange-border-bottom" />
                  <Row className="dry-food">
                    <div className="col-8 col-md-4 align-self-center">
                      <GreyText
                        text="Bespoke dry food daily serving"
                        fontSize={22}
                        marginBottom
                        bold
                      />
                      <GreyText
                        text={`Caters for ${percentage}% of daily needs`}
                        fontSize={16}
                        marginBottom
                        bold
                      />
                      <div className="portion-box">
                        {portion}g per portion
                      </div>
                    </div>
                    <div className="col-8 col-md-4 align-self-center">
                      <div className="dry-food-image" style={imgDiv}>
                        <img
                          style={imgStyle}
                          src="/assets/images/dry-food-serving.png"
                          alt="Dry Food Serving"
                        />
                      </div>
                    </div>
                    <div className="col-8 col-md-4 align-self-center">
                      {formattedShares.map((share, i) =>
                        (parseFloat(share) !== 0 ? <div className="serving"><span>{formattedShares[i]}%</span> {knames[i]}</div> : ''))}
                    </div>
                  </Row>
                  <GreyText
                    text="Featured Ingredients"
                    fontSize={22}
                    marginBottom
                    bold
                  />
                  <Row>
                    <Row className="col-12 featuredIngredients main">
                      <div className="col-2">
                        {keyIngredients[0].image && keyIngredients[0].image.Url && (
                          <div className="ingredient-image-container">
                            <div
                              className="ingredient-icon"
                              style={{
                                backgroundImage: `url(${keyIngredients[0].image.Url})`,
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-10">
                        <p className="orangeSubTitle">{keyIngredients[0].name}</p>
                        <p className="hide-on-mobile">{keyIngredients[0].description}</p>
                      </div>
                    </Row>

                    <div className="col-12 featuredIngredients">
                      <Row>
                        <div className="col-2">
                          {keyIngredients[1].image && keyIngredients[1].image.Url && (
                            <div className="ingredient-image-container">
                              <div
                                className="ingredient-icon"
                                style={{
                                  backgroundImage: `url(${keyIngredients[1].image.Url})`,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-10">
                          <p className="orangeSubTitle">{keyIngredients[1].name}</p>
                        </div>
                      </Row>
                    </div>
                    <div className="col-12 featuredIngredients">
                      <Row>
                        <div className="col-2">
                          {keyIngredients[2].image && keyIngredients[2].image.Url && (
                            <div className="ingredient-image-container">
                              <div
                                className="ingredient-icon"
                                style={{
                                  backgroundImage: `url(${keyIngredients[2].image.Url})`,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-10">
                          <p className="orangeSubTitle">{keyIngredients[2].name}</p>
                        </div>
                      </Row>
                    </div>
                  </Row>
                  <br />
                  <GreyText
                    text="Blend ingredients"
                    fontSize={26}
                    bold
                  />
                  <div>
                    <p className="margin-bottom grey-text">{ingredients.join(', ')}</p>
                    <br />
                    <p className="margin-bottom grey-text">{nutrients.join(', ')}</p>
                    <br />
                    <p className="margin-bottom grey-text">{vitamins.join(', ')}</p>
                  </div>

                </FullColumn>
              </Row>

              {/* Food management page: Delete sections recommended treats and wet AND titbits */}

              {/* <OrangeTextHeading
                text={`${dog.name}'s Recommended Treats and Wet Food`}
                fontSize={30}
                marginBottom
                centered
              /> */}

              {/* <div className="orange-border-bottom" /> */}
              {/* <p>Our recommended treats and wet food match {dog.name}'s nutritional needs and complement their bespoke diet, minimising the risk of allergies and keeping calories under control.<br /><br /></p> */}

              {/* <Row className="rowpadding">
                <div className="col-2 hide-on-mobile">
                  <img
                    className="treatimg"
                    src="/assets/images/treat-icon.png"
                    alt="Treat icon"
                  />
                </div>
                <div className="col-6 cell">
                  <p className="calheader">Treats</p>
                  <p className="treatname">TREAT NAME</p>
                </div>
                <div className="col-2 cell">
                  <p className="calheader">Portions</p>
                  <p className="treatname">10</p>
                </div>
                <div className="col-2 cell">
                  <p className="calheader">Calories</p>
                  <p className="treatname">10</p>
                </div>
              </Row> */}

              {/* <Row className="rowpadding">
                <div className="col-2 hide-on-mobile">
                  <img
                    className="treatimg"
                    src="/assets/images/dental-treat-icon.png"
                    alt="Treat icon"
                  />
                </div>
                <div className="col-6 cell">
                  <p className="calheader">Dental treat</p>
                  <p className="treatname">DENTAL TREAT NAME</p>
                </div>
                <div className="col-2 cell">
                  <p className="calheader"><br /></p>
                  <p className="treatname">10</p>
                </div>
                <div className="col-2 cell">
                  <p className="calheader"><br /></p>
                  <p className="treatname">10</p>
                </div>
              </Row> */}
              {/* <Row className="rowpadding">
                <div className="col-2 hide-on-mobile">
                  <img
                    className="treatimg"
                    src="/assets/images/wet-food-icon.png"
                    alt="Wet food icon"
                  />
                </div>
                <div className="col-6 cell">
                  <p className="calheader">Wet Food</p>
                  <p className="treatname">WET FOOD NAME</p>
                </div>
                <div className="col-2 cell">
                  <p className="calheader"><br /></p>
                  <p className="treatname">10</p>
                </div>
                <div className="col-2 cell">
                  <p className="calheader"><br /></p>
                  <p className="treatname">10</p>
                </div>
              </Row> */}

              {/* <button className="shopbutton" onClick={() => {
                scrollTo(0, 0);
                this.props.history.push('/shop');
              }}>Shop</button> */}
            </div>
          </BackgroundContainer>

          {/* Food management page: Delete section titbits */}
          {/* <div className="hide-on-mobile">
            <BackgroundContainer>
              <OrangeTextHeading
                text={`Titbits`}
                fontSize={30}
                marginBottom
              />
              <div className="orange-border-bottom" />
              <p>Use our dropdown below to keep {dog.name}'s titbits calorie intake under control.<br /><br /></p>

              {this.state.selectedTitbits.map(item => (
                <Item
                  food={item.name}
                  portionvalue={item.portion}
                  kcalvalue={item.kcalg}
                  onDelete={() => {
                    const selection =
                      this.state.selectedTitbits.map(product => product.name).indexOf(item.name);

                    const list = this.state.selectedTitbits;

                    list.splice(selection, 1);

                    this.setState({ selectedTitbits: list });
                  }}
                  onChangeQuantityPlus={(event) => {
                    for (let i = 0; i < this.state.selectedTitbits.length; i++) {
                      if (this.state.selectedTitbits[i].name === item.name) {
                        const list = this.state.selectedTitbits;

                        if (list[i].portion >= 0) {
                          list[i].portion += 10;
                        }

                        this.setState({ selectedTitbits: list });
                      }
                    }
                  }}
                  onChangeQuantityMinus={(event) => {
                    for (let i = 0; i < this.state.selectedTitbits.length; i++) {
                      if (this.state.selectedTitbits[i].name === item.name) {
                        const list = this.state.selectedTitbits;

                        if (list[i].portion >= 10) {
                          list[i].portion -= 10;
                        }

                        this.setState({ selectedTitbits: list });
                      }
                    }
                  }}
                />
              ))}
              <Dropdown
                activeOption={-1}
                onChangeOption={(event) => {
                  const list = [];

                  for (let i = 0; i < titbitoptions.length; i++) {
                    if (titbitoptions[i].name === event.value) {
                      list.push({
                        name: titbitoptions[i].name,
                        portion: titbitoptions[i].portion,
                        kcalg: titbitoptions[i].kcalg,
                      });
                    }
                  }

                  const isInState = this.isVariableAlreadyInState(event.value, 'selectedTitbits');

                  if (isInState) {
                    window.alert('This titbit is already in your list. If you would like more please try increasing the quantity of this item.');
                  } else {
                    this.setState({ selectedTitbits: [...this.state.selectedTitbits, ...list] });
                  }

                }}
                options={titbitoptions.map(item => [item.name, ''])}
              />

              <div className="autofill underline">Autofill from previous day</div>
              <button className="shopbutton" onClick={() => {
                scrollTo(0, 0);
              }}>Save</button>
              <div>
                <p><br />Kasper & Kitty's bespoke dry food meal plan caters for 90% of your dog's daily requirements. If you have indicated that you are feeding them titbits, their bespoke plan will automatically be adjusted to 85%.</p>
                <p>Don't worry if you have forgotten to let us know you are feeding your dog "titbits" during the registration process, simply click this <a href="/dog-profile" className="underline">link</a></p>
              </div>
            </BackgroundContainer>
          </div> */}

        </div>
      </Row>
    );
  }
}
