import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as dogsSelectors from '../../data/dogs/selectors';
import * as dogsActionCreators from '../../data/dogs/actions';
import { getOrders } from '../../data/dogs/api';

import Layout from '../../components/Layout';
import SubNavigation from '../../components/SubNavigation';
import KaushanScriptHeading from '../../components/KaushanScriptHeading';
import BackGroundContainer from '../../components/BackgroundContainer';
import Row from '../../components/Row';
import FullColumn from '../../components/FullColumn';

class YourPets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageWidth: undefined,
      orders: [],
    };
  }

  componentDidMount() {
    getOrders()
      .then((orders) => {
        this.setState({ orders });
      });
  }

  hasPaidOrders(id) {
    let bespokeOrders = this.state.orders.filter(
      item => item.dog_id === id && item.order_items && item.order_items.filter(orderItem => orderItem.is_kibble).length >= 1
    );
    let paidOrders = bespokeOrders.filter(item => item.awaiting_payment === false);
    return paidOrders.length > 0;
  }

  render() {
    return (
      <Layout>
        {/* <MainImageBanner
          image="url(/assets/images/dog-in-field.jpg)"
          heading="Your account"
          subtext="Your pets"
        /> */}
        <SubNavigation />
        <div className="inner-container">
          <KaushanScriptHeading title="Your pets" />
          <Row>
            <FullColumn>
              <BackGroundContainer>
                <div className="your-pets-container">
                  {this.props.dogs.map((dog, index) => (
                    <div className={`your-pet-item ${this.props.dog.ID === dog.ID ? ' active ' : ''}`} key={index}>
                      <div className="row justify-content-center">
                        <div className="col-10">
                          <div style={{ zIndex: 0 }}>
                            <div className="gi-content">
                              <div style={{ zIndex: 1 }} className="dog-image-border small">
                                <img
                                  className="dog-image-frame"
                                  src="assets/images/dog-image-frame.png"
                                  alt=""
                                />
                                <div
                                  ref={(image) => {
                                    this.image = image;
                                  }}
                                  className="dog-image-container"
                                  style={{
                                    height: this.state.imageWidth,
                                    backgroundImage: dog.image.Url !== '' ? `url(http://${dog.image.Url})` : 'url(/assets/images/kasper-image-white.png)',
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="dog-name">{dog.name}</div>
                        </div>
                      </div>
                      <div className="your-pet-text">
                        <div className="actions">
                          <div className="top">
                            <button onClick={() => {
                              if (this.props.dog.ID === dog.ID) {
                                this.props.history.push('/dog-profile');
                              } else {
                                this.props.actions.dogs.select(dog.ID);
                                this.props.history.push('/dog-profile');
                              }
                            }}
                            >Edit
                            </button>
                          </div>
                          {this.hasPaidOrders(dog.ID) ? (
                            this.props.dog.ID !== dog.ID ? (
                              <div className="bottom">
                                <button
                                  onClick={() => {
                                    this.props.actions.dogs.select(dog.ID);
                                    this.props.history.push('/dashboard');
                                  }}
                                >Select
                                </button>
                              </div>
                            ) : (
                                <div className="bottom">
                                  <button
                                    onClick={() => {
                                      window.location.href = '/add-dog';
                                    }}
                                  >Add another dog
                                </button>
                                </div>
                              )
                          ) : (
                              <div className="bottom">
                                <button
                                  onClick={() => {
                                    this.props.history.push('/cart?id=' + dog.ID);
                                  }}
                                >Finish registration
                              </button>
                              </div>
                            )}

                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </BackGroundContainer>
            </FullColumn>
          </Row>
        </div>
      </Layout>
    );
  }
}

export default connect(() => ({
  dogs: dogsSelectors.getAll(),
  dog: dogsSelectors.getCurrentDog(),
}), dispatch => ({
  actions: {
    dogs: bindActionCreators(dogsActionCreators, dispatch),
  },
}))(YourPets);
