import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { updateSubscriptionOrder, kibbleOrder } from '../data/orders/api'
import { getOriginalAndDiscountedTotal } from '../utils';

class SubscriptionBox extends React.Component {
  constructor(props) {
    super(props);

    const storage = localStorage.getItem('subscription');
    const isEdited = localStorage.getItem('isEdited');

    this.state = {
      storageSubscription: storage !== null ? JSON.parse(storage) : [],
      subscription: {},
      promoCode: '',

      changeConfirmed: false,
      isEdited: isEdited === 'true' ? true : false,
      isUpdating: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.loadSubscriptionOrder(false);

    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  componentDidUpdate(prevProps) {
    if (this.props.dogID && (prevProps.dogID !== this.props.dogID)) {
      this.loadSubscriptionOrder(false);
    }

    if (prevProps.subscription !== this.props.subscription) {

      let oldScriptions = this.state.subscription;
      let newSubscription = this.props.subscription;

      let filtered = oldScriptions.items.findIndex(item => item.product_id === newSubscription.product_id)

      if (filtered === -1) {

        // when add new subscription
        if (newSubscription.quantity <= 0) return;

        newSubscription.price = Math.round((newSubscription.quantity * newSubscription.price) * 100) / 100
        oldScriptions.items = [newSubscription].concat(oldScriptions.items);
      } else {

        // when increase / decrease quantity
        if (newSubscription.quantity === 0) {
          oldScriptions.items.splice(filtered, 1)
        } else {
          newSubscription.price = Math.round((newSubscription.quantity * newSubscription.price) * 100) / 100
          oldScriptions.items[filtered] = newSubscription;
        }
      }

      // calculate total price newly
      let total = oldScriptions.items.reduce((t, item) =>
        item.isKibble ? t + 1.2 * parseFloat(item.price) : t + parseFloat(item.price), 0);
      total = Math.round((total + 7.2) * 100) / 100
      oldScriptions.total = total;

      // set updated subscription to localstorage
      let subOrders = JSON.parse(localStorage.getItem('subscription'));

      let index = subOrders.findIndex(order => order.dogID === this.props.dogID);

      subOrders[index].subscription = oldScriptions;

      this.setState({ subscription: oldScriptions })
      localStorage.setItem('subscription', JSON.stringify(subOrders))

      // enable change confirm button
      this.setState({ isEdited: true });
      localStorage.setItem('isEdited', 'true');
    }
  }

  loadSubscriptionOrder = (revert) => {
    const { storageSubscription } = this.state;
    const { dogID } = this.props;

    dogID !== '' && kibbleOrder(dogID)
      .then((order) => {

        // check if subscription already exist in local storage
        if (storageSubscription.length > 0) {
          let index = storageSubscription.findIndex(sub => sub.dogID === dogID)

          // check if that order was updated on QOR
          // if not updated, load subscription from localstorage
          if (index !== -1 && order.Order.ID === storageSubscription[index].subscription.orderID) {
            this.setState({
              subscription: storageSubscription[index].subscription
            })

            return;
          }
        }

        let items = [];

        if (order.Order.items && order.Order.items.length > 0) {
          items = order.Order.items.map((item => ({
            product_id: item.product_id,
            name: item.product.name,
            quantity: item.quantity,
            price: Math.round(item.price * 100) / 100,
            isKibble: item.product.is_kibble,
            image: item.product.image,
          })));
        }

        let total = items.map(i => i.isKibble ? i.price * 1.2 : i.price).reduce((prev, next) => prev + next, 0) + 7.2;

        const subscription = {
          orderID: order.Order.ID,
          orderStatus: order.Order.status,
          dateRequired: order.Order.date_required,
          total: total.toFixed(2),
          totalNoDiscount: order.Order.total_no_discount,
          subCycle: order.Order.subscription_cycle,
          isTrial: order.Order.is_trial,
          items,
        }

        const promoCode = order.Order.promo_id;

        this.setState({ subscription, promoCode });
        this.saveToLocalStorage(dogID, subscription, promoCode, revert);
      })
  }

  saveToLocalStorage = (dogID, subscription, promoCode, revert) => {
    let subOrders = JSON.parse(localStorage.getItem('subscription'));

    if (subOrders && subOrders.length > 0) {

      // check if there is the specific subscription with current dogID in localstorage
      // if exist, skip and if no exist, add to localstorage
      let index = subOrders.findIndex(sub => sub.dogID === dogID)

      if (index === -1) {
        subOrders.push({
          dogID,
          subscription,
          promoCode
        })
      } else {
        if (subscription.orderID !== subOrders[index].subscription.orderID) {
          subOrders[index].subscription = subscription;
        }

        if (revert === true) {
          subOrders[index].subscription = subscription;
        }
      }
    } else {

      // in case 'subscription' doesn't exist in localstorage, add
      subOrders = [];
      subOrders.push({
        dogID,
        subscription,
        promoCode
      })
    }

    localStorage.setItem('subscription', JSON.stringify(subOrders))
    this.props.updatedLocalStorage();
  }

  updateSubscription = () => {
    updateSubscriptionOrder(this.state.subscription.orderID, this.state.subscription.items)
      .then((res) => {
        if (res.success) {
          this.setState({ isEdited: false });
          localStorage.setItem('isEdited', 'false');
          setTimeout(() => { this.setState({ isUpdating: false, changeConfirmed: true }); }, 3000);
        } else {
          window.alert(res.errors[0]);

          // in case of updateSubscription is failed, revert subscription box
          this.loadSubscriptionOrder(true);
          this.setState({ isUpdating: false, changeConfirmed: false });
        }
      })
  };

  getThreeWorkDaysBefore = (day) => {
    let numWorking = 0, targetDay = day;

    while (numWorking < 3) {
      targetDay -= 1;

      if (targetDay === 0) {
        targetDay = 5;
        continue;
      }

      numWorking += 1;
    }

    return targetDay;
  }

  totalKibblePrice = () => this.state.subscription.items &&
    this.state.subscription.items
      .filter(i => i.isKibble)
      .map(i => i.price * 1.2)
      .reduce((prev, next) => prev + next, 0)
    + 7.20;

  resize() {
    this.setState({ isMobile: window.innerWidth <= 480 });
  }

  render() {
    const {
      subscription,
      promoCode,
      changeConfirmed,
      isEdited,
      isUpdating,
    } = this.state;

    const deliveryDate = new Date(subscription.dateRequired);
    const lastDateToChange = new Date(deliveryDate.getFullYear(), deliveryDate.getMonth(), this.getThreeWorkDaysBefore(deliveryDate.getDate()));

    let buttonToBeDisplayed;
    let buttonToBeDisplayedMobile;

    if (changeConfirmed) {
      buttonToBeDisplayed = <button className="changes-confirmed-button">CHANGES CONFIRMED</button>;
    } else if (isUpdating) {
      buttonToBeDisplayed = (
        <div className="loading-wrapper" style={{ margin: 'auto' }}>
          <ReactLoading
            type="spinningBubbles"
            style={{ margin: 'auto', height: 45, width: 45, color: 'grey', }}
          />
        </div>
      );
    } else {
      buttonToBeDisplayed = <button className="confirm-changes-button" onClick={this.updateSubscription}>CONFIRM CHANGES</button>;
      buttonToBeDisplayedMobile = <button className="d-block d-sm-none confirm-changes-button-mobile" onClick={this.updateSubscription}><img className="subscription-refresh" src="../assets/images/refresh-white.png" alt="" /><span>CONFIRM SUBSCRIPTION BOX CHANGES</span></button>;
    }

    return (
      <div className="subscription-box-summary">
        <div className="subscription-box-note">Your order is currently scheduled to be delivered on {deliveryDate.toDateString()}. Forgot anything? Add by {lastDateToChange.toDateString()}.</div>

        <div className="subscription-box-title">
          SUBSCRIPTION BOX SUMMARY
          <img className="refresh-image" src="../assets/images/big-refresh.png" alt="" />
        </div>

        <div className="subscription-products">
          {
            Array.isArray(subscription.items) &&
            [...subscription.items, { name: 'Bespoke Dry Food', price: subscription.subCycle === 1 ? 3.95 : this.totalKibblePrice(), quantity: 1 }]
              .filter(item => !item.isKibble)
              .map((item, i) => {
                return (
                  <div key={i} className="subscription-each-product">
                    <span className="subscription-product-counts">{item.isKibble ? 1 : item.quantity}</span>
                    <img className="product-image" src={item.image !== undefined ? item.image.Url : '../assets/images/premium-food.png'} alt="" />
                    <div className="each-product-detail">
                      <p className="each-product-name">{item.name}</p>
                      <p className="each-product-price">£{item.price.toFixed(2)}</p>
                    </div>
                  </div>
                )
              })
          }
        </div>

        {!Number.isNaN(deliveryDate.getTime()) &&
          <div className="delivery-date">
            <p className="delivery-text">
              <span className="date-title">Delivery Date</span>
              <span className="date-detail">{deliveryDate.toDateString()}</span>
            </p>

            <p className="delivery-text">
              <span className="date-title">Last Date to change</span>
              <span className="date-detail">{lastDateToChange.toDateString()}</span>
            </p>
          </div>
        }

        <div className="items-price">
          <p className="item-price-text">
            <span className="price-title">Items</span>
            <span className="price-detail">{subscription.subCycle === 1 ? "FREE" : "£" + subscription.total}</span>
          </p>

          <p className="item-price-text">
            <span className="price-title">Promo code</span>
            <span className="price-detail">
              {promoCode}
            </span>
          </p>

          <p className="item-price-text">
            <span className="price-title">Shipping</span>
            <span className="price-detail">{subscription.subCycle === 1 ? "£3.95" : "FREE"}</span>
          </p>

          <p className="item-price-text total-price">
            <span className="price-title total-price-title">Total</span>
            <span className="price-detail">{subscription.subCycle === 1 ? "£3.95" : getOriginalAndDiscountedTotal(subscription.order, this.props.user)}</span>
          </p>

          {(isEdited || isUpdating || changeConfirmed) && buttonToBeDisplayed}

          {isEdited && buttonToBeDisplayedMobile}

          <Link to="/shop" className="d-block d-sm-none">
            <button className="confirm-changes-button">GO BACK</button>
          </Link>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  user: state.services.session.user,
}))(SubscriptionBox);

// export default SubscriptionBox;
